const EVENTS = {
  entered_business_shop_name: "entered_business_shop_name",
  entered_owner_name: "entered_owner_name",
  entered_business_email_id: "entered_business_email_id",
  entered_address_line_one: "entered_address_line_one",
  entered_address_line_two: "entered_address_line_two",
  entered_landmark: "entered_landmark",
  entered_pincode: "entered_pincode",
  entered_city: "entered_city",
  locate_me_tap: "locate_me_tap",
  business_details_tap: "business_details_tap",
  store_location_tap: "store_location_tap",
  upload_documents_tap: "upload_documents_tap",
  locate_store_step_2_tap: "locate_store_step_2_tap",
  upload_documents_step_3_tap: "upload_documents_step_3_tap",
  send_for_verification_tap: "send_for_verification_tap",
  remove_image_tap: "remove_image_tap",
  add_image_tap: "add_image_tap",
  documents_submitted: "documents_submitted",
  auto_fetch_location_complete: "auto_fetch_location_complete",
  add_location_tap: "add_location_tap",
};

export default EVENTS;
