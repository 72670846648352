import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Card,
  Container,
  Form,
  FormControl,
  InputGroup,
  Spinner,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import ErrorModal from "../Modals/ErrorModal";
import { useLocation, useParams } from "react-router-dom";
import OrderMessageModal from "../Modals/orderMessageModal";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router";
import uploadImage from "../../Images/svg/noun-image.svg";
import { setOnboardingData } from "../../Actions/Onboarding";
import { refreshToken } from "../../Actions/Auth";
import { usersData } from "../../App";
import { createTicket } from "../../Actions/Support";
import { Common_Event_Hit } from "../../Actions/Events";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosMail, IoMdCall } from "react-icons/io";

function ReportIssueForm() {
  const IssueScreenshots = {
    screen0: null,
    screen1: null,
    screen2: null,
    screen3: null,
    screen4: null,
  };
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [imageCount, setImageCount] = useState(1);
  const [issueScreenshots, setIssueScreenshots] = useState(IssueScreenshots);
  const [issueScreenshotURL, setIssueScreenshotURL] =
    useState(IssueScreenshots);
  const [uploadedFileLinks, setUploadedFileLinks] = useState(IssueScreenshots);
  const uploadedFileLinksRef = useRef();
  const descriptionRef = useRef();
  const [showSpinner, setShowSpinner] = useState(false);
  const { userData, setShowMaintenance } = useContext(usersData);
  const [newTicketId, setNewTicketId] = useState(null);
  const [descriptionError, setDescriptionError] = useState(false);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    header: "",
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { id: reportID, orderId } = useParams();

  const generalIssueData = location.state;

  //update upload file links ref
  // uploadedFileLinksRef.current = finalLinks

  useEffect(() => {
    console.log("order id---", orderId);
  }, [orderId]);

  useEffect(() => {
    console.log("generalIssueData from form", generalIssueData);
  }, [generalIssueData]);

  const incrementImageCount = () => {
    if (imageCount === 5) return;
    setImageCount((prevImageCount) => prevImageCount + 1);
  };

  const decrementImageCount = () => {
    if (imageCount === 1) return;
    setImageCount((prevImageCount) => prevImageCount - 1);
  };

  //file input checks
  const handleFileInput = (e) => {
    //call image_tap event
    callApiEvents("attach_image_tap");

    if (e.target.files[0].size >= 700 * 1024) {
      setInfoMessage({
        show: true,
        content: "file size cannot be greater than 700KB",
        header: "File Size Exceeds Limit",
      });
      e.target.value = "";
      return;
    } else if (
      !(
        e.target.files[0].type.includes("jpeg") ||
        e.target.files[0].type.includes("jpg") ||
        e.target.files[0].type.includes("png")
      )
    ) {
      setInfoMessage({
        show: true,
        content: "file type should be jpeg, jpg or png",
        header: "Invalid File Type",
      });
      e.target.value = "";
      return;
    }

    setIssueScreenshots({
      ...issueScreenshots,
      [e.target.name]: e.target.files[0],
    });
    setIssueScreenshotURL({
      ...issueScreenshotURL,
      [e.target.name]: URL.createObjectURL(e.target.files[0]),
    });
    e.target.value = "";

    incrementImageCount();
  };

  //reroute to the 'report issue' screen
  const handleNavigateBack = () => {
    //check if coming from the orders screen
    if (orderId && reportID) {
      //call issue_type_click event
      callApiEvents("issue_type_click");
    } else {
      //call back_tap if coming from general issue sedtion
      callApiEvents("back_tap");
    }
    navigate(-1);
  };

  //remove attached images to the ticket before posting them
  const removeIssueImages = (index) => {
    let key = Object.keys(issueScreenshots)[index];
    setIssueScreenshots({ ...issueScreenshots, [key]: null });
    setIssueScreenshotURL({ ...issueScreenshotURL, [key]: null });
    decrementImageCount();
  };

  //check if description is an empty string
  const checkDescription = () => {
    let flag = false;
    if (descriptionRef.current.value.length === 0) {
      flag = true;
      setDescriptionError(true);
    }
    return flag;
  };
  //final api call to create the ticket
  const handleCreateTicket = () => {
    //call submit_tap events
    callApiEvents("submit_tap");

    if (!checkDescription()) {
      console.log(
        "uploadedFileLinksRef.current -----------------",
        uploadedFileLinksRef.current
      );
      let data = localStorage.getItem("userData");
      data = JSON.parse(data);
      handleFileUpload()
        .then(() => {
          let attachments = Object.values(uploadedFileLinksRef.current).filter(
            (item) => item?.length > 0
          );

          const params = {
            ticketAttachmentUrl: attachments,
            category: generalIssueData[0].categoryId.toString(),
            issue: generalIssueData[0].id.toString(),
            subIssue: reportID,
            description: descriptionRef.current.value,
            retailerCode: data.retailerCode,
            retailerMobileNo: data.mobileNo,
            retailerShopName: data.shopName,
            retailerId: data.id,
          };

          if (orderId) params.orderId = orderId;

          console.log("params++++++", params);
          createTicket({}, params)
            .then((res) => {
              setNewTicketId(parseInt(res.data.data));
              console.log("inside then after creating ticket", res.data.data);
              //call ticket_raised event
              callApiEvents("ticket_raised", parseInt(res?.data?.data));
              // navigate('/')
              navigate("/help-support/ReportIssue/status/" + res.data.data);
            })
            .catch((error) => {
              console.log(error.response);
              if (error?.response?.status === 401) {
                refreshToken().then((res) => {
                  handleCreateTicket();
                });
              } else if (
                error?.response?.status === 502 ||
                error?.response?.status === 503 ||
                error?.response?.status === 504
              ) {
                setShowMaintenance({ status: true, apiData: error?.response });
              } else if (error?.response?.status === 500) {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: "Oops something went wrong!",
                  tryAgain: function () {
                    handleCreateTicket();
                  },
                });
              } else {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: error?.response?.data?.error?.error
                    ? error.response.data.error.error
                    : "Something went wrong!",
                  tryAgain: function () {
                    handleCreateTicket();
                  },
                });
              }
            });
        })
        .catch((error) => {
          console.log(error.response);
          if (error?.response?.status === 401) {
            refreshToken().then((res) => {
              handleCreateTicket();
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error.response.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong!",
              tryAgain: function () {
                handleCreateTicket();
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data?.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                handleCreateTicket();
              },
            });
          }
        });
    }
  };

  //file upload to 'uploadSupports'
  const fileUpload = async (item, key) => {
    let body = new FormData();
    body.append("file", item);
    return setOnboardingData("uploadsSupportImage", {}, body)
      .then((res) => {
        console.log(res, "links");
        setUploadedFileLinks((prevUpdatedFileLinks) => ({
          ...prevUpdatedFileLinks,
          [key]: res?.data?.data,
        }));
      })
      .catch((error) => {
        console.log(error.response);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            fileUpload();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong!",
            tryAgain: function () {
              fileUpload();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              fileUpload();
            },
          });
        }
      });
  };

  const handleFileUpload = async () => {
    for (let key in issueScreenshots) {
      // console.log(key)
      if (!issueScreenshots[key] && !uploadedFileLinks[key]) {
        setUploadedFileLinks((prevUpdatedFileLinks) => ({
          ...prevUpdatedFileLinks,
          [key]: "",
        }));
      } else {
        await fileUpload(issueScreenshots[key], key);
      }
    }
  };

  const callApiEvents = (eventName, ticketId) => {
    //trigger when some image upload is hit
    let props = {
      eventName: eventName,
    };
    if (eventName === "attach_image_tap") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    } else if (eventName === "submit_tap") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    } else if (eventName === "ticket_raised") {
      let props = { ...props }; //deep copy the global props
      props.ticket_id = ticketId;
      Common_Event_Hit("DYNAMIC_EVENT", props);
    } else if (eventName === "issue_type_click") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    } else if (eventName === "back_tap") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    } else if (eventName === "call_us_tap") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    }
  };

  //update uploadFileLinksRef for between renders
  useEffect(() => {
    uploadedFileLinksRef.current = uploadedFileLinks;
  }, [uploadedFileLinks]);

  //onMount set respective screen name for events hit
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("currentScreen", "submit_issue_screen");
  }, []);

  return (
    <>
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiDat={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Container
        fluid
        className="all-container-wrap"
        style={{ color: "#FFFFFF" }}
      >
        <Container
          fluid
          className="q-and-a-parent-container qa-parent-alternative"
        >
          <div className="nav-container-form">
            <div className="nav-main">
              <BiArrowBack
                size="1.5rem"
                color="#4b4495"
                className="navigate-back"
                onClick={handleNavigateBack}
              />
              {generalIssueData[0].subIssue.map((sIssue, _) => {
                if (`${sIssue.id}` === `${reportID}`) {
                  return (
                    <span className="page-title">{sIssue.subIssue.trim()}</span>
                  );
                }
              })}
            </div>
            <div className="call-support-container">
              <OverlayTrigger
                trigger="click"
                key={"bottom"}
                placement={"bottom"}
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Header as="h3">
                      <a
                        href="tel:+918035018025"
                        className="text_decoration_none color_primary"
                      >
                        <IoMdCall className="color_secondary" /> &nbsp;&nbsp;
                        8035018025
                      </a>
                    </Popover.Header>
                    <Popover.Body>
                      <a
                        href="mailTo:support@saveo.in"
                        className="text_decoration_none color_primary"
                      >
                        <IoIosMail className="color_secondary" /> &nbsp;&nbsp;
                        support@saveo.in
                      </a>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div onClick={() => callApiEvents("call_us_tap")}>
                  <BsTelephoneFill color="48D1CC"></BsTelephoneFill>
                  <span>Call Support</span>
                </div>
              </OverlayTrigger>
            </div>
          </div>

          <Container fluid className="main-container issue-form-main-container">
            <Card className="instructions-card">
              <Card.Header className="instructions-header">
                Instructions box
              </Card.Header>
              <Card.Body className="instructions-body">
                {generalIssueData[0].subIssue.map((sIssue, _) => {
                  return (
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          `${sIssue.id}` === `${reportID}`
                            ? sIssue.subIssueDescDto.instructionHtml
                            : "",
                      }}
                    ></div>
                  );
                })}
              </Card.Body>
            </Card>
            <div className="explain-issue-container">
              <Form.Label
                htmlFor="describe-issue"
                className="describe-issue-label"
              >
                Describe your Issue
              </Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  as="textarea"
                  id="describe-issue"
                  placeholder="Explain Your Issue"
                  className={
                    descriptionError
                      ? "describe-issue-input is-invalid"
                      : "describe-issue-input"
                  }
                  ref={descriptionRef}
                />
                <Form.Control.Feedback type="invalid">
                  Please describe your issue
                </Form.Control.Feedback>
              </InputGroup>
            </div>

            <div className="attach-iamges-container">
              <Form.Label className="describe-issue-label">
                Attach screenshot/img
              </Form.Label>

              <div className="issue-images-wrapper">
                {[...Array(imageCount)].map((_, index) => {
                  return !Object.values(issueScreenshotURL)[index] ? (
                    <div className="issue-image-input-container">
                      <input
                        className="issue-image-input"
                        id={`screenshot${index}`}
                        name={`screen${index}`}
                        accept="image/jpeg,image/jpg,image/png"
                        type="file"
                        onChange={handleFileInput}
                      />
                      <label
                        className="issue-image-container"
                        htmlFor={`screenshot${index}`}
                      >
                        <img src={uploadImage} className="issue-image-upload" alt="upload image" />
                        + Upload
                      </label>
                    </div>
                  ) : (
                    <div className="issue-image-preview-container">
                      <div
                        className="preview-close-container"
                        onClick={() => removeIssueImages(index)}
                      >
                        <AiFillCloseCircle className="preview-close" />
                      </div>
                      <img
                        src={
                          issueScreenshotURL[
                            Object.keys(issueScreenshotURL)[index]
                          ]
                        }
                        alt="issue screenshot"
                        className="issue-preview-image"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </Container>
          <div className="submit-button-container">
            <button
              className="submit-issue-button"
              onClick={handleCreateTicket}
            >
              {showSpinner ? (
                <Spinner
                  animation="border"
                  className="color_ice spinner"
                  size="sm"
                />
              ) : (
                "SUBMIT"
              )}
            </button>
          </div>
        </Container>
      </Container>
    </>
  );
}

export default ReportIssueForm;
