import React, { useContext, useEffect, useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import { IoMdNotifications } from "react-icons/io";
import { useNavigate } from "react-router";
import {
  MdExpandLess,
  MdOutlineNotificationsActive,
  MdPlayArrow,
} from "react-icons/md";
import { BannerData } from "../../App";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";

function NotificationsComponent({ userLoggedIn }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationData, setNotificationData] = useState([]);
  const [expandedIndices, setExpandedIndices] = useState([]);
  const { setBannerData } = useContext(BannerData);
  const [bellShake, setBellShake] = useState(false);
  const [notificationPermissionStatus, setNotificationPermissionStatus] =
    useState(false);

  const popOverRef = useRef();

  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(popOverRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // update the notification data in local storage marking the status of all notifications as read
    let data = JSON.parse(JSON.stringify(notificationData));
    data.map((item) => (item.status = "read"));
    setNotificationData(data);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notifyPopover" : undefined;

  const notificationBroadcast = new BroadcastChannel("notify");

  const handleExpand = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(index);
    let data = JSON.parse(JSON.stringify(expandedIndices));
    // if index already present, remove it from the expansion array
    if (expandedIndices.includes(index)) {
      data.splice(expandedIndices.indexOf(index), 1);
    }
    // if index not present, add it into the expansion array
    else {
      data.push(index);
    }
    // update the expansion array state
    setExpandedIndices(data);
  };

  //   handler to navigate the notification deep links
  const handleNavigate = (deepLink) => {
    handleClose();
    setBannerData({
      deepLink: deepLink,
    });
    navigate("/bannerRedirect");
  };

  // handler to ask for user notification
  const handleNotificationTrigger = () => {
    // return if ios device (iPhone / iPad)
    if (isIOS()) return;

    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification?.permission === "denied") {
      alert("Please enable notitications manually");
    } else if (Notification?.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          console.log("permission granted");
          // reset the notification permission status state
          setNotificationPermissionStatus(true);
          // …
        }
      });
    }
  };

  // function to check if the platform is iOS
  const isIOS = () => {
    const browserInfo = navigator.userAgent.toLowerCase();
    // iPhone / iPad
    if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
      return true;
    }
    // also check for sims/ems
    if (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform)
    ) {
      return true;
    }
    // default
    return false;
  };

  // public function to reset the components notify data as the header never unmounts if the user logsout
  const resetNotificationData = () => {
    setNotificationData([]);
  };

  useEffect(() => {
    // on broadcast, update the notification data state
    notificationBroadcast.onmessage = (event) => {
      let data = { ...event.data };
      data.status = "unread";
      setNotificationData((prevVal) => [data, ...prevVal]);
    };
    return () => {
      notificationBroadcast.close();
    };
  }, [notificationBroadcast]);

  useEffect(() => {
    // return if no notification data is present
    // this happens when the state is created for the first time
    if (notificationData.length === 0) return;
    // every time the state changes, update the local storage.
    // state change happens when broadcast message is received
    localStorage.setItem("notificationData", JSON.stringify(notificationData));
    // add the bell shake animation
    if (
      notificationData &&
      notificationData.filter(
        (notification) => notification.status === "unread"
      ).length > 0
    ) {
      setBellShake(true);
      setTimeout(() => {
        setBellShake(false);
      }, [1100]);
    }
  }, [notificationData]);

  //onMount
  useEffect(() => {
    // check if the notification permission is enabled by the user
    if ("Notification" in window) {
      if (Notification?.permission !== "granted") {
        setNotificationPermissionStatus(false);
      } else {
        setNotificationPermissionStatus(true);
      }
    }
    // update the notificationData state with the notificationData from the local storage
    let data = JSON.parse(localStorage.getItem("notificationData"));
    if (data) setNotificationData(data);
    // this prevents data merge between multiple users on successive logins
    else setNotificationData([]);
  }, []);

  // on logout reset the notification data state
  // this is necessary as the notification data might persist between succesive logins without the page reload as the notification component is in header, it doesn't unmount causing the state to persist between users
  useEffect(() => {
    // login status can be false on page reload as the checkAuth api hasn't resolved yet
    // check the userData as well for this case
    if(!userLoggedIn && !localStorage.getItem('userData')){
      setNotificationData([])
    }
  },[userLoggedIn])

  return (
    <div>
      {notificationData?.length > 0 &&
        notificationData.filter(
          (notification) => notification.status === "unread"
        ).length > 0 && (
          <div className="notification_count">
            {
              notificationData.filter(
                (notification) => notification.status === "unread"
              ).length
            }
          </div>
        )}
      <div
        className={"flex-align-center-column notification-wrapper"}
        style={{
          justifyContent: "flex-start",
          marginTop: "3px",
          height: "30px",
          overflow: "hidden",
        }}
        ref={popOverRef}
      >
        <IoMdNotifications
          size="22px"
          color="#fff"
          aria-describedby={id}
          onClick={handleClick}
          variant="contained"
          className={`notification-icon cursor_pointer ${
            bellShake && "bell-shake"
          }`}
        />
        {/* <MdPlayArrow
            color="#1C183F"
            className={`popover-arrow ${!open && 'bg-hidden'}`}
            size="1.3rem"
          /> */}
      </div>

      {/* <span className="notification-count">10</span> */}
      <Popover
        sx={{ marginTop: ".3rem" }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className="notif-popover"
      >
        <div
          className="notification-po-header flex-align-center-row"
          style={{
            justifyContent: "space-between",
            position: "sticky",
            top: "0",
          }}
        >
          <h6 className="font_weight_500 color_white" style={{ margin: "0" }}>
            NOTIFICATIONS
          </h6>
          <AiOutlineCloseCircle
            color="#fff"
            size="22px"
            onClick={handleClose}
            className="cursor_pointer"
          />
        </div>

        {!notificationPermissionStatus && !isIOS() && (
          <div
            className="notif-permission-panel flex-align-center-row cursor_pointer "
            // style={{ gap: "rem" }}
            onClick={handleNotificationTrigger}
          >
            <MdOutlineNotificationsActive color="#000" size="1.3rem" />
            <p className="margin-none">Click to enable notifications</p>
          </div>
        )}

        {!notificationPermissionStatus && isIOS() && (
          <div
            className="notif-permission-panel flex-align-center-row cursor_pointer "
            // style={{ gap: "rem" }}
            onClick={handleNotificationTrigger}
          >
            <Typography sx={{ fontSize: 14 }} color="text.primary">
              This browser does not support notifications{" "}
            </Typography>
          </div>
        )}

        {notificationData?.length > 0
          ? notificationData.map((notification, index) => {
              return (
                <div
                  className="notification-card"
                  onClick={() =>
                    handleNavigate(notification.payload?.urlToOpen)
                  }
                  key={index}
                  style={{ paddingTop: index === 0 && "1rem" }}
                >
                  <Card sx={{ minWidth: 290 }} gutterBottom>
                    <CardHeader
                      action={
                        notification?.payload?.image && (
                          <IconButton
                            aria-label="expand more"
                            onClick={(e) => handleExpand(e, index)}
                          >
                            <MdExpandLess
                              size="1.2rem"
                              className={
                                expandedIndices.includes(index)
                                  ? "rotate down"
                                  : "rotate"
                              }
                            />
                          </IconButton>
                        )
                      }
                      title={
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: 600,
                            letterSpacing: ".6px",
                          }}
                          color="#4b4495"
                        >
                          {notification.payload?.title}
                        </Typography>
                      }
                      subheader={
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                        >
                          {notification.payload?.message}
                        </Typography>
                      }
                    />
                    {notification?.payload?.image && (
                      <Collapse
                        in={!expandedIndices.includes(index)}
                        timeout="auto"
                        unmountOnExit
                      >
                        <CardMedia
                          component="img"
                          width="275"
                          image={notification.payload.image}
                          alt={notification.payload.message}
                        />
                      </Collapse>
                    )}
                  </Card>
                </div>
              );
            })
          : notificationPermissionStatus && (
              <div
                className="notification-card"
                style={{
                  color: "#000",
                  justifyContent: "center",
                  paddingTop: "1rem",
                }}
              >
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 16 }} color="text.primary">
                      No new notifications!
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            )}
      </Popover>
    </div>
  );
}

export default React.memo(NotificationsComponent);
