import React from 'react'

function ButtonLoading2() {
    return (
        <div className="loaderRectangle loaderRectangleSecondary">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
}

export default ButtonLoading2