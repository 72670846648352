import { Modal, Button, NavItem } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import "../../App.css";
import "./dialog.css";
import loader from "../../Images/gifs/loader1.gif";

const DeleteCartItems = (props) => {
  
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="del_modal"
      backdrop="static"
    >
      <Modal.Header className="inProgressHeader">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="padding_10 text_center "
        >
          <img src={loader} className="loader_modal_svg" />
          <br></br>
          <p className="font_ubuntu color_primary font_weight_md font_18">
            Deleting Cart Items...
          </p>
          {/* <div className="text_center">
            <Button
              className="bg_white width_100 brdr_none bg_secondary go_to_home  color_white font_weight_md"
              onClick={async () => {
                navigate("/home");
              }}
            >
              GO TO HOME
            </Button>
          </div> */}
        </Modal.Title>
      </Modal.Header>
      {/* <Modal.Body>
       
      </Modal.Body> */}
    </Modal>
  );
};

export default React.memo(DeleteCartItems);
