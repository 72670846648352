import React, { useEffect, useState } from "react";
import { WHOLESALE_IMAGES } from "../../../Images/svg";

function WholeSaleCompanyCard({ data }) {
    const [cardData, setCardData] = useState({})
  useEffect(() => {
      setCardData(data)
  }, [data]);
  return (
    <div className="ws-company-card-wrapper">
      {cardData && (
        <>
          <div className="ws-company-card">
            <img
              src={cardData.manufacturerLogo || WHOLESALE_IMAGES.image1}
              alt={cardData.manufacturerName}
            />
          </div>
          <p>{cardData.shortName}</p>
        </>
      )}
    </div>
  );
}

export default WholeSaleCompanyCard;
