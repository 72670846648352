import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { refreshToken } from "../../../Actions/Auth";
import { getAllWidgets, getSpecificWidget } from "../../../Actions/WholeSale/HomePage";
import ErrorModal from "../../Modals/ErrorModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import AppCategories from "../Header/AppCategories";
import { appCategoriesDataContext, networkStatusContext, usersData, WSWidgetDataContext } from "../../../App";
import "./Products.css";
import { Col, Row } from "react-bootstrap";
import { BsArrowLeft, BsSearch } from "react-icons/bs";
import WholeSaleProductCard from "../WholeSaleProductCard/WholeSaleProductCard";
import InfiniteScroll from "react-infinite-scroll-component";
import ButtonLoading2 from "../../Spinners/ButtonLoading2";
import Timer from "../Timer/Timer";
import WholeSaleCompanyCard from "../WholeSaleProductCard/WholeSaleCompanyCard";
import ButtonLoading1 from "../../Spinners/ButtonLoading1";
import { useServiceWorker } from "../../../useServiceWorker";

function ProductsPrimary() {
  const location = useLocation();
  const [pageCount, setPageCount] = useState(0);
  let baseUrl = "wholesale/home/search/medicine/";
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const searchBarRef = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const pageType = location?.pathname.split("/")[2];
  const [productsData, setProductsData] = useState([]);
  const [hasCategoryChanged, setHasCategoryChanged] = useState(false);
  const [activeCD, setActiveCD] = useState(null);
  // const CDTabs = ["More Than 20%", "10% to 20%", "Less Than 10%"];
  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(true);
  const [expandedSearch, setExpandedSearch] = useState(false);
  const [shimmer, setShimmer] = useState(false);
  const {activeTab, setActiveTab, appCategoriesData, setAppCategoriesData} = useContext(appCategoriesDataContext)
  const {WSWidgetData : widgetData, setWSWidgetData: setWidgetData} = useContext(WSWidgetDataContext)
  const [cdData, setCdData] = useState([])
  const [CDTabs, setCDTabs] = useState([])
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const {networkStatus, showNetworkStatus} = useContext(networkStatusContext);

  //filtering happens at the route level rather tahan the tab level
  const handleActiveCDTab = (val) => {
    setPageCount(0);
    setProductsData([]);
    //update the hasMore for the infinite scroll
    setHasMore(true)
    let filter = "";
    if (val === 0) filter = "20-100";
    else if (val === 1) filter = "10-20";
    else if (val === 2) filter = "0-10";
    navigate("/wholesale/shopByCashDiscount/" + filter);
    // getProductsData();
    setActiveCD(val);
  };

  //api to get the product data
  const getProductsData = async () => {
    //get the category from the appCategoryDataContext
    let category = appCategoriesData[activeTab]?.name
    let min, max;
    if (activeCD == 0) {
      min = "20.0";
      max = "100.0";
    } else if (activeCD === 1) {
      min = "10";
      max = "20";
    } else if (activeCD === 2) {
      min = "0";
      max = "9";
    }
    let params = {
      medCategory: pageType === "shopByCashDiscount" ? "ALL" : category,
      page: pageCount,
      minDiscount: pageType === "shopByCashDiscount" ? min : "",
      maxDiscount: pageType === "shopByCashDiscount" ? max : "",
      medSubSectionType: pageType === "DoD" ? "DEAL OF THE DAY" : "",
    };
    let url =
      baseUrl +
      (pageType === "topSellingProducts"
        ? "topSellingProd"
        : pageType === "shopByCashDiscount"
        ? "cashDiscountPercentage"
        : pageType === "DoD"
        ? "bySubSectionType"
        : pageType);

    //guardian check if medCategory is unavailable
    if (category === "undefined") return;

    Object.keys(params).map((item, _) => {
      if (params[item] === "") delete params[item];
    });

    //update the shimmer state
    if (pageCount === 0) setShimmer(true);

    try {
      const res = await getSpecificWidget(url, params);
      if (pageType === "topCompanyOffers" && res?.data?.data?.length === 0)
        setHasMore(false);
      if (pageType === "topCompanyOffers") {
        setProductsData([...productsData, ...res?.data?.data]);
        shortCompanyNames(res?.data?.data);
      }
      if (
        pageType === "shopByCashDiscount" &&
        !res?.data?.data?.medicineResponseList &&
        pageCount === 0
      )
        setProductsData([]);
      if (res?.data?.data?.medicineResponseList) {
        setProductsData([
          ...productsData,
          ...res?.data?.data?.medicineResponseList,
        ]);
      }
      if (res?.data?.data?.size == 0) setHasMore(false);

      let productListLength = productsData?.length + res?.data?.data?.medicineResponseList?.length

      if(res?.data?.data?.size > 0 && productListLength < 10){
        fetchMoreData()
      } 
      //update the shimmer state
      setShimmer(false);
    } catch (error) {
      // update the shimmer state if any error
      setShimmer(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getProductsData();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getProductsData();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getProductsData();
          },
        });
      }
    }
  };

  //update search query state on change
  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchMoreData = () => {
    setPageCount((prevVal) => prevVal + 1);
  };

  const handleSearch = () => {
    setExpandedSearch(!expandedSearch);
    if (!expandedSearch) searchBarRef.current.focus();
  };

  const shortCompanyNames = (data) => {
    // let data = [...productsData]
    data?.forEach((company, index) => {
      let nameArray = company.manufacturerName.split(" ");
      let name = nameArray[0];
      for (let i = 1; i < nameArray.length; i++) {
        if (name.length + nameArray[i].length + 1 < 18) {
          if (name.length === 0) name += nameArray[i];
          else name += " " + nameArray[i];
        } else {
          break;
        }
      }
      company.shortName = name;
      // data[index] = company
    });
    setProductsData([...productsData, ...data]);
  };

  //api to fetch home screen widgets
  //if screen name is unavailable, default all medicine catedgory will be used
  const getHomeScreenWidgets = async (screenName) => {
    //build query params
    let params ={
      screenName : screenName,
    }

    //update the shimmer state
    //TODO: check the sueCase, UX
    setShimmer(true)

    try {
      const res = await getAllWidgets(params, {})
      //uodate the widgetData context =
      setWidgetData(res?.data?.data)
      //also update the cdData
      widgetData?.forEach((item, index) => {
        if(item?.widgetName === 'ws_discount_widget'){
          setCdData(item?.data?.discountTypes)
        }
      })
    } catch (error) {
      //update the shimmer state
      setShimmer(false)
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getHomeScreenWidgets();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getHomeScreenWidgets();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getHomeScreenWidgets();
          },
        });
      }
    }



  }

  useEffect(() => {
    if (
      pageType === "topSellingProducts" ||
      pageType === "DoD" ||
      pageType === "fastMovingProducts" ||
      pageType === "topCompanyOffers"
    )
      getProductsData();
  }, [pageType, hasCategoryChanged]);

  //onMount scroll
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!location) return;
    let tab = location.pathname.split("/")[3];
    if (tab === "20-100") setActiveCD(0);
    else if (tab === "10-20") setActiveCD(1);
    else if (tab === "0-10") setActiveCD(2);
    // setCdTabs(location?.state?.data)
  }, [location]);

  useEffect(() => {
    if (activeCD === null) return;
    // setProductsData([])
    getProductsData();
  }, [activeCD]);

  //get products data everytime pageCount updates to enable infinity scroll
  useEffect(() => {
    if (pageCount === 0) return;
    getProductsData();
  }, [pageCount]);

  //scroll to the top everytime category is changed
  useEffect(() => {
    window.scrollTo(0,0)
  },[activeTab])

  //get widgetData if unavailable
  useEffect(() => {
    let screenName = appCategoriesData[activeTab]?.tag || 'all_home_screen'
    if(widgetData.length === 0){
      //hit the getHomeScreenWidgets api and update the widgetData context
      getHomeScreenWidgets(screenName)
    } else{
      //update the cdData
      widgetData.forEach((item, index) => {
        if(item?.widgetName === 'ws_discount_widget'){
          setCdData(item?.data?.discountTypes)
        }
      })
    }
  },[widgetData])

  //update the CDTabs whenever the csData is available
  useEffect(() => {
    if(cdData.length === 0) return
    let tabs =[]
    cdData?.map((item, index) => {
      let keys = Object.keys(item)
      if(keys.includes('minDiscount')){
        if(keys.includes('maxDiscount')){
          tabs.push(`${item.minDiscount}% - ${item.maxDiscount}%`)
        } else {
          tabs.push(`More Than ${item.minDiscount}%`)
        }
      } else {
        tabs.push(`Less Than ${item.maxDiscount}%`)
      }
    })
    setCDTabs(tabs)
  },[cdData])


  //onMount check if the user is logged in
  useEffect(() => {
    let userData = localStorage.getItem('userData')
    if(userData){
      userData = JSON.parse(userData)
      userData.businessType && userData.businessType[0] === 'PHARMACY' && navigate('/home')
    } else {
      navigate('/login')
    }
  },[])


  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      {pageType === "shopByCashDiscount" && (
        <div className={(!networkStatus || showNetworkStatus || isUpdateAvailable) ? "wholesale-sub-header ws-cd-header ws-cd-header-update" : 'wholesale-sub-header ws-cd-header'}>
          {CDTabs.map((item, index) => {
            return (
              <Col key={index}>
              <div
                className={
                  activeCD === index
                    ? "ws-cd-tab ws-cd-tab-active"
                    : "ws-cd-tab"
                }
                key={index}
                onClick={() => handleActiveCDTab(index)}
              >
                <p>{item}</p>
                {activeCD === index && <div className="active-indicator"></div>}
              </div>
              </Col>
            );
          })}
        </div>
      )}
      <div className={(!networkStatus || showNetworkStatus || isUpdateAvailable) ? "ws-products-primary-container ws-products-primary-container-update" : 'ws-products-primary-container'}>
        <div className={pageType === "shopByCashDiscount" ? (!networkStatus || showNetworkStatus || isUpdateAvailable) ?  'ws-products-header ws-products-primary-header ws-products-sbc-primary-header ws-products-sbc-primary-header-update' : 'ws-products-header ws-products-primary-header ws-products-sbc-primary-header' :  (!networkStatus || showNetworkStatus || isUpdateAvailable) ? "ws-products-header ws-products-primary-header ws-products-primary-header-update" : 'ws-products-header ws-products-primary-header'}>
          <BsArrowLeft
            size="1.5rem"
            className="ws-nav-back"
            onClick={() => navigate("/wholesale/home")}
          />
          <p>
            {pageType === "topSellingProducts"
              ? "Top Selling Products"
              : pageType === "shopByCashDiscount"
              ? "Shop By Cash Discount"
              : pageType === "DoD"
              ? "Deal of the day"
              : pageType === "fastMovingProducts"
              ? "Fast Moving Products"
              : pageType === "topCompanyOffers"
              ? "Top Company Offers"
              : ""}
          </p>
          {pageType === "DoD" && (
            <div className="ws-dod-timer">
              <Timer timerEpoch={productsData[0]?.batchInfo?.dealEndTime} />
            </div>
          )}
        </div>
        {pageType === "topCompanyOffers" && (
          <div className="ws-companies-main-container">
            <div
              className="ws-companies-list-container"
              id="wsCompaniesScrollableDiv"
            >
              <div className="ws-companies-header">
                <p>Top Company Offers</p>
                <div className="ws-companies-scroll">
                  {productsData?.map((item, index) => {
                    return <WholeSaleCompanyCard data={item} key={index} />;
                  })}
                </div>
              </div>
              <InfiniteScroll
                className="ws-infy-s-component"
                dataLength={productsData?.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<ButtonLoading2 />}
                scrollableTarget="wsCompaniesScrollableDiv"
                scrollThreshold="90%"
              >
                {productsData?.map((item, index) => {
                  return (
                    <div className="ws-company-list-card" key={index}>
                      <p>{item?.manufacturerName}</p>
                      <p>{item?.skuCount || "unavailable"}&nbsp;SKUs</p>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          </div>
        )}
        {!(pageType === "topCompanyOffers") && (
          <div className={pageType === 'shopByCashDiscount' ? "ws-products-main-container ws-products-sbc-main-container" :  "ws-products-main-container"} id="scrollableDiv" style={{height:(!networkStatus || showNetworkStatus || isUpdateAvailable) && '81vh'}}>
            {/* <p>ALL PRODUCTS</p> */}
            <InfiniteScroll
              className="ws-infy-s-component"
              dataLength={productsData?.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<div style={{width: '100%', height: '3rem'}}><ButtonLoading1 color='#03C3B0' /></div>}
              scrollableTarget="scrollableDiv"
              scrollThreshold="90%"
              endMessage={ <div style={{width: '100%'}} className='flex-align-center-row'><p style={{width: '60%'}} className="listEnd-msg">⁌ end of products list ⁍</p></div>}
            >
              {!shimmer &&
                productsData &&
                productsData?.map((item, index) => {
                  return (
                    <WholeSaleProductCard
                      data={item}
                      variant="white"
                      key={index}
                    />
                  );
                })}
              {!shimmer && productsData?.length === 0 && (
                <h5 style={{paddingTop: '10rem'}}>No Medicines Available</h5>
              )}
              {shimmer && (
                <>
                  {[...Array(5)].map((_, index) => {
                    return (
                      <WholeSaleProductCard
                        variant="white"
                        shimmer={shimmer}
                        key={index}
                      />
                    );
                  })}
                </>
              )}
            </InfiniteScroll>
          </div>
        )}
      </div>
    </>
  );
}

export default ProductsPrimary;
