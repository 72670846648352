import axios from "axios";
import { variables } from "../environments";

let config = {
  headers: {
    "Content-Type": "application/json",
    "source":"pharmacyWeb",
    appVersionCode: variables.appVersionCode,

  },
};
const BASE = variables.ADMIN_URL;

const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  
  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  config.headers.Authorization = "Bearer " + token;
  return true;
};

export const getRetailerCodesOnBehalf = async (params) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/admin/communityLeader/v2/getRetailerCodesOnBehalf`, {
      params: params,
      headers: config.headers,
    });
  });

  return data;
};

export const trackVisitType = async (body) => {
  const data = getToken().then(() => {
    return axios.post(`https://eagle.saveo.in/api/rt6`, body, {
      headers: config.headers,
    });
  });

  return data;
};


