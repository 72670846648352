import React, { Fragment, useState, useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";
import PlayStore from "../../Images/png/play_store.png";
import SaveoLogo from "../../Images/svg/footer_saveo_logo.svg";
import { ImFacebook } from "react-icons/im";
import { AiOutlineInstagram } from "react-icons/ai";
import { GrLinkedinOption } from "react-icons/gr";
import { BsTwitter } from "react-icons/bs";
import { usersData } from "../../App";
import { useLocation } from "react-router";
import WtsAppQr from "../../Images/svg/footer_wtsapp.svg";
import {BsWhatsapp} from "react-icons/bs";
import whatspp_logo from "../../Images/svg/wtsapp_logo.svg";
import {FiPhoneCall} from "react-icons/fi"
const Footer = ({ bgColor, userLoggedIn }) => {
  let location = useLocation();
  const { userData } = useContext(usersData);
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    let route = location.pathname.split("/")[1];
    let route2 = location.pathname.split("/")[2];
    if (
      route === "onboarding" ||
      route === "login" ||
      route === "categories" ||
      route === "search" ||
      route === "products" ||
      route === "companies" ||
      route === "bannerRedirect" ||
      route === "homeProducts" ||
      route === 'wholesale' ||
      route === "account" ||
      route === 'yourExpiry'||
      route === 'silvercross'||
      route === 'campaign-gifts' ||
      route2 === 'composition'||
      route2==='substitute'
    ) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location]);
  return (
    <>
      {
        <Container
          fluid
          className={userData && showHeader ? "bg_white" : "bg-hidden"}
          style={{
            background: bgColor,
            borderTop: "1px solid rgba(128, 128, 128, 0.308)",
          }}
        >
          <Row className="display_flex space_btw footer_parent">
            <Col
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className="font_ubuntu footer_first_sec"
            >
              <p className="color_primary font_weight_md ">
                Join the fastest growing digital pharmaceutical distribution
                platform.
              </p>
              <div>
                <a href='https://play.google.com/store/apps/details?id=com.saveo.saveomedical&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank"
                  rel="noreferrer">
                  <img className="play_store_img" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
              </div>
            </Col>

            <Col
              lg={3}
              md={2}
              sm={12}
              xs={12}
              className="font_ubuntu contact_footer"
            >
              <div className="line_height_8">
                <p className="color_primary  font_weight_md">Email</p>
                <a href="mailTo:support@saveo.in">support@saveo.in</a>
              </div>
              <br />
              <div className="line_height_8">
                <p className="color_primary font_weight_md">Contact</p>
                {/* <a href="tel:08035018025">08035018025</a>, */}
                <a className="wtsp_cntct_link" style={{marginLeft:'2%'}} href="https://api.whatsapp.com/send?phone=8390088888" target="_blank"><FiPhoneCall style={{fontSize:'16px'}}/> <img src={whatspp_logo} className="menu_wtsapp" alt="WhatsApp"/> 83900 88888</a>
              </div>
              <br />
              <div className="line_height_8">
                <span>
                  <a
                    href="privacy-policy.html"
                    target="_blank"
                    rel="noreferrer"
                    className="font_12 text_decoration_none color_primary font_weight_lg"
                  >
                    Privacy Policy
                  </a>
                </span>
                &nbsp; &nbsp; &nbsp;
                <span>
                  <a
                    href="terms-and-conditions.html"
                    target="_blank"
                    rel="noreferrer"
                    className="font_12 text_decoration_none color_primary font_weight_lg"
                  >
                    Terms and Conditions
                  </a>
                </span>
              </div>
            </Col>

            <Col lg={3} md={3} sm={12} xs={12} className="social_sec">
              <img src={SaveoLogo} alt="saveo logo" className="footer_saveo_logo" />
              <div className="display_flex social_logos">
                <br />
                <a href="https://www.facebook.com/HeySaveo/" target="_blank" rel="noreferrer">
                  <ImFacebook className="color_primary" />
                </a>
                <a
                  href="https://www.instagram.com/heysaveo/"
                  target="_blank"
                  rel="noreferrer"
                  className="color_primary"
                >
                  <AiOutlineInstagram />
                </a>
                <a
                  href="https://www.linkedin.com/company/heysaveo"
                  target="_blank"
                  rel="noreferrer"
                  className="color_primary"
                >
                  <GrLinkedinOption />
                </a>
                <a
                  href="https://twitter.com/heysaveo"
                  target="_blank"
                  rel="noreferrer"
                  className="color_primary"
                >
                  <BsTwitter />
                </a>
              </div>
            </Col>
            <Col style={{texAlign:'center'}} md={2}>
                 <a href="https://api.whatsapp.com/send?phone=8390088888" target="_blank" style={{margin:'0px auto'}}>
                 <img className="footer_wtsapp" src={WtsAppQr} alt-="wtsapp" />
                 </a>
            </Col>
          </Row>
          <Row>
            <Col className="text_center font_12 color_primary bg_light_blue padding_10">
              Saveo is a trademark of Saveo Healthtech Pvt. Ltd. &copy;2023
              Saveo. All rights reserved.
            </Col>
          </Row>
        </Container>
      }
    </>
  );
};

export default Footer;
