import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { WHOLESALE_IMAGES } from "../../../Images/svg";
import AddToCartButton from "../Buttons/AddToCartButton";
import "./WholeSaleProductCard.css";

function WholeSaleProductCard({ data, variant, shimmer }) {
  const navigate = useNavigate();
  // const VARIANTS = ['light', 'white', 'default']

  //handler to navigate to product page
  const handleProductView = () => {
    navigate("/wholesale/product/" + data?.medicineId);
  };

  return (
    <>
      {data && (
        <div
          className={
            variant === "white"
              ? "wholesale-product-card ws-product-card-max"
              : "wholesale-product-card"
          }
          onClick={handleProductView}
          style={{ cursor: "pointer" }}
        >
          <div className="wholesale-product-header ">
            <div className="image-wrapper">
              <img
                src={data?.medicineImage || WHOLESALE_IMAGES.image1}
                alt="some random image"
                className="product-header"
              />
            </div>
            <div className="wholesale-product-header-content font_ubuntu">
              <p className="dod-badge flex-align-center-row font_ubuntu">
                {data?.batchInfo?.dealType}
              </p>
              <h6 className="font_weight_md">{data?.name}</h6>
              <div className="ws-product-med-details">
                <p>
                  {data?.pack} {` Tablets`}
                </p>
                <p>{data?.manufacturer}</p>
              </div>
            </div>
          </div>
          {variant === "white" && (
            <div style={{ width: "100%" }} className="flex-align-center-row">
              <div
                style={{
                  width: "90%",
                  height: "1px",
                  backgroundColor: "#EAECEE",
                }}
              ></div>
            </div>
          )}
          <div
            className={
              variant === "light"
                ? "wholesale-product-body wholesale-product-body-light"
                : "wholesale-product-body"
            }
          >
            <div
              className={
                variant === "light"
                  ? "stock-details font_ubuntu stock-details-light"
                  : "stock-details font_ubuntu"
              }
            >
              <div style={{ width: "49%" }}>
                {variant === "white" && <p>MRP</p>}
                <p>Scheme</p>
                <p>Available Stock</p>
                <p>Expire by</p>
              </div>
              <div>
                {variant === "white" && <p>:</p>}
                <p> : </p>
                <p> : </p>
                <p> : </p>
              </div>
              <div style={{ width: "49%" }}>
                {variant === "white" && (
                  <div className="ws-product-margin-container">
                    <p>₹{data?.batchInfo?.mrp.toFixed(2)} </p>
                    <p>
                      {data?.batchInfo?.margin}%<span>MARGIN</span>
                    </p>
                  </div>
                )}
                <p>
                  {data.batchInfo.schemes.length > 0
                    ? data.batchInfo.schemes.map((item, index) => (
                        <span
                          className={"ws-product-scheme-item"}
                          style={{
                            border:
                              variant === "light" || variant === "white"
                                ? "1px solid #1A4059"
                                : "",
                          }}
                          key={index}
                        >
                          {item}
                        </span>
                      ))
                    : "unavailable"}
                </p>
                <p>{data?.batchInfo?.quantity || "unavailable"}</p>
                <p>{data?.batchInfo?.expiry || "unavailable"}</p>
              </div>
            </div>
            <div
              className={
                variant === "light" ? "seperator seperator-light" : "seperator"
              }
            ></div>
            <div className="product-card-footer">
              <div
                className={
                  variant === "light"
                    ? "price-details price-details-light"
                    : variant === "white"
                    ? "price-details price-details-white"
                    : "price-details"
                }
              >
                {variant !== "white" && (
                  <>
                    <p className="ws-card-ptr">PTR <span>₹ {data?.batchInfo?.ptr.toFixed(2)}</span> </p>
                  </>
                )}

                {variant === "white" && (
                  <div className="ws-cd-light" style={{ fontSize: "0.7rem" }}>
                    <p>₹ {data?.batchInfo?.mrp.toFixed(2)}</p>
                    {data.batchInfo.cashDiscount > 0 && (
                      <span style={{ fontWeight: "500" }}>
                        <span style={{ fontWeight: "bold" }}>
                          {data?.batchInfo?.cashDiscount}%&nbsp;
                        </span>
                        CD
                      </span>
                    )}
                  </div>
                )}
                <p style={{ fontSize: "0.9rem" }}>
                  ₹ {data?.batchInfo?.netRate.toFixed(2)}
                </p>
                <p>
                  Net Rate{" "}
                  {!(variant === "white") && data.batchInfo.cashDiscount > 0 && (
                    <span style={{ fontWeight: "500" }}>
                      <span style={{ fontWeight: "bold" }}>
                        {data?.batchInfo?.cashDiscount}%&nbsp;
                      </span>
                      CD
                    </span>
                  )}
                </p>
              </div>
              <AddToCartButton
                moq={data?.batchInfo?.minOrderQuantity}
                type="margin"
              />
            </div>
          </div>
        </div>
      )}

      {shimmer && (
        <div
          className={
            variant === "white"
              ? "wholesale-product-card ws-product-card-max"
              : "wholesale-product-card"
          }
        >
          <div className="wholesale-product-header ">
            <div
              className="image-wrapper animateShimmer"
              style={{ height: "4rem", width: "5rem", borderRadius: ".25rem" }}
            ></div>
            <div className="wholesale-product-header-content font_ubuntu">
              <div
                className="font_weight_md animateShimmer"
                style={{ width: "5rem", height: ".5rem" }}
              ></div>
              <div
                className="font_weight_md animateShimmer "
                style={{ width: "8rem", height: ".5rem", marginTop: ".5rem" }}
              ></div>
            </div>
          </div>
          <div
            className={
              variant === "light"
                ? "wholesale-product-body wholesale-product-body-light"
                : "wholesale-product-body"
            }
          >
            <div style={{ width: "100%", height: "9rem", padding: "1rem" }}>
              <div
                className="font_weight_md animateShimmer2"
                style={{ height: ".5rem" }}
              ></div>
              <div
                className="font_weight_md animateShimmer2"
                style={{ height: ".5rem", marginTop: "1rem" }}
              ></div>
              <div
                style={{
                  width: "100%",
                  marginTop: "1.5rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  className="animateShimmer2"
                  style={{
                    height: "3rem",
                    width: "50%",
                    borderRadius: ".25rem",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default WholeSaleProductCard;
