import { Modal, Button, NavItem } from "react-bootstrap";
import React, { Fragment } from "react";
import '../../App.css';
import './dialog.css'
import Success from '../../Images/gifs/success2.gif'
import { useNavigate } from "react-router";

const ReturnSuccessModal = (props) => {
   let navigate=useNavigate() 
    return (
        <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="del_modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="padding_10 text_center" style={{margin:'0px auto'}}>
        <img src={Success} className="delete_modal_svg"/>
        <br></br>
        <p className="font_ubuntu color_primary font_weight_md font_18">
           {props.content}
        </p>
        {
            props.sub_content && <>
            <br/>
            <div className="font_ubuntu font_weight_md color_secondary text_center font_14" style={{marginBottom:'5px'}}>{props.sub_content}</div>
            <div className="font_ubuntu color_primary_lt font_weight_md font_14 modal_content">
        {props.sub_content1 && props.sub_content1}
        </div>
            </>
        }
        <div className="text_center">
            <Button
              
              className="bg_white width_100 brdr_none bg_secondary go_to_home  color_white font_weight_md"
              onClick={()=>navigate('/home')}
            >
              GO TO HOME
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      {/* <Modal.Body>
       
      </Modal.Body> */}
     
    </Modal>
    )
}

export default ReturnSuccessModal
