import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useContext,
} from "react";
import "./Orders.css";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { Button, Spinner, Container } from "react-bootstrap";
import Delete from "../../Images/svg/delete.svg";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import DeleteProductModal from "../Modals/DeleteProductModal";
import { useNavigate } from "react-router";
import { My_Account } from "../../Actions/MyAccount";
import { refreshToken } from "../../Actions/Auth";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import EditOrderModal from "../Modals/EditOrder";
import ReturnSuccessModal from "../Modals/ReturnSuccessModal";
import moment from "moment";

const ReturnCard = ({ medData, refresh, filter }) => {
  let navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState();
  const [maxQtyError, setMaxQtyError] = useState(false);
  const [minQtyError, setMinQtyError] = useState(false);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [returnPlaced, setReturnPlaced] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    item: "",
  });
  const [showSuccessDialog, setSuccessDialog] = useState({
    show: false,
    content: "",
  });
  const [openAccordion, setAccordion] = useState(false);
  const [cancelOrderSpinner, setCancelOrderSpinner] = useState(false);
  const [saveOrderSpinner, setSaveOrderSpinner] = useState(false);

  const minusReturnQuantity = (e, item, i) => {
    e.stopPropagation();

    let updatedQty;

    item.updatedQuantity
      ? (updatedQty = +item.updatedQuantity - 1)
      : (updatedQty = +item.returnQuantity - 1);
    if (updatedQty > 0) {
      let obj = Object.assign({}, data);
      obj.orderReturnDetailList[i].updatedQuantity = updatedQty;
      setData(obj);
    } else {
      setShowDeleteModal({
        show: true,
        item: item,
      });
    }
  };

  const addReturnQuantity = (e, item, i) => {
    e.stopPropagation();

    let updatedQty;
    item.updatedQuantity
      ? (updatedQty = +item.updatedQuantity + 1)
      : (updatedQty = +item.returnQuantity + 1);
    if (updatedQty <= item.maxReturnableQuantity) {
      let obj = Object.assign({}, data);
      obj.orderReturnDetailList[i].updatedQuantity = updatedQty;
      setData(obj);
    }
  };

  const editReturnQty = (e, item, i) => {
    e.stopPropagation();
    if (e.target.value.length) {
      if (e.target.value == 0) {
        setShowDeleteModal({
          show: true,
          item: item,
        });
      } else if (+e.target.value <= item.maxReturnableQuantity) {
        let obj = Object.assign({}, data);
        obj.orderReturnDetailList[i].updatedQuantity = e.target.value;
        setData(obj);
      }
    } else {
      let obj = Object.assign({}, data);
      obj.orderReturnDetailList[i].updatedQuantity = e.target.value;
      setData(obj);
    }
  };

  const onFocusOutReturn = (e, item, i) => {
    e.stopPropagation();
    console.log(e, e.target.value.length);
    if (!e.target.value.length) {
      let obj = Object.assign({}, data);
      obj.orderReturnDetailList[i].updatedQuantity = item.returnQuantity;
      setData(obj);
    }
  };

  const returnEdit = () => {
    setAccordion(!openAccordion);
    setEdit(true);
  };

  const openItems = () => {
    setAccordion(!openAccordion);
    setEdit(false);
  };

  const removeFromOrder = (e, i) => {
    let obj = Object.assign({}, data);
    obj.orderReturnDetailList[i].isDeleted = true;
    setData(obj);
  };

  const cancelOrder = () => {
    setCancelOrderSpinner(true);
    My_Account("CANCEL_RETURN", { returnOrderId: data.returnOrderId }, {})
      .then((res) => {
        setCancelOrderSpinner(false);
        setSuccessDialog({
          show: true,
          content: "Return Order Cancelled Succesfully",
        });
      })
      .catch((error) => {
        setCancelOrderSpinner(false);
        if (error.response) {
          if (error.response.status == 401) {
            refreshToken().then((res) => {
              cancelOrder();
            });
          } else if (
            error.response.status == 502 ||
            error.response.status == 503 ||
            error.response.status == 504
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error.response.status == 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
              tryAgain: function () {
                cancelOrder();
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error.response.data.error.error,
              tryAgain: function () {
                cancelOrder();
              },
            });
          }
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Something went wrong !",
            tryAgain: function () {
              cancelOrder();
            },
          });
        }
      });
  };

  const saveOrder = () => {
    let body = {
      keyQuantityMap: {},
      returnOrderId: data.returnOrderId,
    };
    setSaveOrderSpinner(true);
    data.orderReturnDetailList.forEach((item) => {
      if (!item.isDeleted) {
        body.keyQuantityMap[item.key] = item.updatedQuantity
          ? item.updatedQuantity
          : item.returnQuantity;
      } else {
        body.keyQuantityMap[item.key] = 0;
      }
    });

    My_Account("EDIT_RETURN", {}, body)
      .then((res) => {
        setSaveOrderSpinner(false);
        setSuccessDialog({
          show: true,
          content: "Return Order Edited Succesfully",
        });
      })
      .catch((error) => {
        setCancelOrderSpinner(false);
        if (error.response) {
          if (error.response.status == 401) {
            refreshToken().then((res) => {
              saveOrder();
            });
          } else if (
            error.response.status == 502 ||
            error.response.status == 503 ||
            error.response.status == 504
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error.response.status == 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
              tryAgain: function () {
                saveOrder();
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error.response.data.error.error,
              tryAgain: function () {
                saveOrder();
              },
            });
          }
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Something went wrong !",
            tryAgain: function () {
              saveOrder();
            },
          });
        }
      });
  };

  useEffect(() => {
    let obj = JSON.parse(JSON.stringify(medData));
    setData(Object.assign({}, obj));
  }, [medData, openAccordion]);

  return (
    <>
      <EditOrderModal
        show={showSuccessDialog.show}
        content={showSuccessDialog.content}
        sub_content={showSuccessDialog.sub_content}
        onHide={() => {
          setSuccessDialog({ show: false, content: "" });
          refresh();
        }}
      />

      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
          refresh();
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <ReturnSuccessModal
        show={returnPlaced}
        content={"Yay! Return requested"}
        sub_content={"We have received your request"}
        sub_content1={"Our team make sure to call you soon"}
        onHide={() => {
          setReturnPlaced(false);
          refresh();
        }}
      />

      {data && (
        <Container>
          <div
            className="order_card brdr_none return_card"
            style={{ backgroundColor: "#F5F5F5" }}
          >
            <div className="return_date font_ubuntu font_weight_md">
              {" "}
              {moment(data.epochTimestamp).format("ll")}
            </div>
            <div className="bg_white card_brdr">
              <div className="order_card_header display_flex space_btw color_black font_ubuntu font_weight_md">
                <div>
                  {data.orderReturnDetailList[0].returnQuantity} x{" "}
                  {data.orderReturnDetailList[0].medicineName}{" "}
                  {data.orderReturnDetailList &&
                    data.orderReturnDetailList.length > 1 && (
                      <span
                        className="color_primary cursor_pointer"
                        onClick={openItems}
                      >
                        {" "}
                        + {data.orderReturnDetailList.length - 1} items{" "}
                        {openAccordion ? (
                          <AiOutlineCaretUp />
                        ) : (
                          <AiOutlineCaretDown />
                        )}
                      </span>
                    )}
                </div>
                <div className="order_header_right text_right">
                  <div className="color_grey">Return Value</div>
                  <br />
                  <div>₹ {data?.totalRefundAmount?.toFixed(2)}</div>
                </div>
              </div>

              <Container className="return_stepper_sec">
                <div className="display_flex space_btw padding_10">
                  <div>
                    {data.stateTrackList && data.stateTrackList.length > 0 && (
                      <div className="display_flex">
                        {data.stateTrackList.map((track, i) => {
                          return (
                            <span key={i}>
                              <div className="display_flex">
                                <div
                                  className={
                                    i == data.stateTrackList.length - 1
                                      ? "stepper bg_primary"
                                      : "stepper bg_secondary"
                                  }
                                ></div>
                                {i < data.stateTrackList.length - 1 && (
                                  <div className="v_line"></div>
                                )}
                              </div>
                              <div className="display_flex">
                                <div className="stepper_cont font_ubuntu font_weight_md font_14">
                                  <span className="color_grey font_12 no_wrap">
                                    {" "}
                                    {moment(track.epochTimestamp).format("lll")}
                                  </span>{" "}
                                  <br />
                                  <span>{track.description}</span>
                                </div>
                              </div>
                            </span>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  {data.editable ? (
                    <Button
                      variant="outline-primary"
                      className="edit_btn_return font_ubuntu"
                      onClick={returnEdit}
                    >
                      Edit
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Container>
              <div className="display_flex space_btw return_footer">
                <div className="font_ubuntu font_weight_md">
                  <span className="color_grey font_14">OTP</span>
                  <br />
                  <span className="color_primary font_16">{data.otp}</span>
                </div>
                <div className="font_ubuntu font_weight_md text_right">
                  <span className="color_grey font_14">Order ID</span>
                  <br />
                  <span className="color_primary font_16">
                    {data.orderRetrieveId}
                  </span>
                </div>
              </div>

              {openAccordion && !edit && data.orderReturnDetailList && (
                <div>
                  {data.orderReturnDetailList.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="order_meds return_items font_ubuntu display_flex space_btw"
                      >
                        <div>
                          <span className="color_primary font_weight_md">
                            ({item.returnQuantity}) &nbsp;
                          </span>
                          <span className="color_black">
                            {item.medicineName}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {openAccordion && edit && data.orderReturnDetailList && (
                <div>
                  {data.orderReturnDetailList.map((item, i) => {
                    return (
                      <>
                        {!item.isDeleted && (
                          <div
                            key={i}
                            className="order_meds return_items edit font_ubuntu display_flex space_btw bg_grey cursor_pointer"
                          >
                            <DeleteProductModal
                              show={
                                showDeleteModal.show &&
                                item.itemCode === showDeleteModal.item.itemCode
                              }
                              itemName={item.medicineName}
                              return={true}
                              removeFromCart={(e) => {
                                e.stopPropagation();
                                removeFromOrder(e, i);
                              }}
                              onHide={(e) => {
                                e.stopPropagation();
                                setShowDeleteModal({ show: false, item: "" });
                              }}
                            />
                            <div
                              className="display_flex"
                              style={{ marginTop: "3px" }}
                            >
                              <img
                                src={Delete}
                                alt="Delete"
                                className="return_del"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowDeleteModal({
                                    show: true,
                                    item: item,
                                  });
                                }}
                                style={{
                                  filter:
                                    "invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%)",
                                }}
                              />
                              &nbsp; &nbsp;
                              <span className="color_primary font_weight_md">
                                ({item.returnQuantity}) &nbsp;
                              </span>
                              <span className="color_black">
                                {item.medicineName}
                              </span>
                            </div>
                            <div className="display_flex">
                              <Fragment>
                                <div
                                  className="minusQty color_primary"
                                  onClick={(e) =>
                                    minusReturnQuantity(e, item, i)
                                  }
                                >
                                  <AiOutlineMinus />
                                </div>
                                <input
                                  className="qty_input"
                                  type="number"
                                  value={
                                    item.hasOwnProperty("updatedQuantity")
                                      ? item.updatedQuantity
                                      : item.returnQuantity
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                  onBlur={(e) => onFocusOutReturn(e, item, i)}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    editReturnQty(e, item, i);
                                  }}
                                />
                                <div
                                  className="addQty bg_primary color_white"
                                  onClick={(e) => addReturnQuantity(e, item, i)}
                                >
                                  <AiOutlinePlus />
                                </div>
                              </Fragment>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                  <div className="display_flex space_around save_section margin_10 padding_10">
                    <Button
                      disabled={cancelOrderSpinner}
                      variant="outline-danger"
                      onClick={cancelOrder}
                      className="cancel_btn_order"
                    >
                      {cancelOrderSpinner ? (
                        <Spinner
                          animation="border"
                          className="color_secondary spinner"
                          size="sm"
                        />
                      ) : (
                        "Cancel Return"
                      )}
                    </Button>
                    <Button
                      disabled={saveOrderSpinner}
                      variant="outline-primary"
                      className="save_btn font_ubuntu"
                      onClick={saveOrder}
                    >
                      {saveOrderSpinner ? (
                        <Spinner
                          animation="border"
                          className="color_secondary spinner"
                          size="sm"
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default ReturnCard;
