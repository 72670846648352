import React, { useContext, useEffect, useState } from "react";
import { GiReceiveMoney } from "react-icons/gi";
import { refreshToken } from "../../../Actions/Auth";
import { getSpecificWidget } from "../../../Actions/WholeSale/HomePage";
import SeeAllButton from "../Buttons/SeeAllButton";
import WholeSaleProductCard from "../WholeSaleProductCard/WholeSaleProductCard";
import { usersData } from "../../../App";
import ErrorModal from "../../Modals/ErrorModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import Timer from "../Timer/Timer";
import { useNavigate } from "react-router";

function DoD({ data, ...props }) {
  const [dodData, setDodData] = useState([]);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const [shimmer, setShimmer] = useState(false)
  const navigate = useNavigate()

  //initial api fetch for the dod data and update the respective state
  const getDodData = async () => {
      let url = data?.data?.apiEndPoint
      //update the shimmer state
      setShimmer(true)
    try {
      const res = await getSpecificWidget(url);
      setDodData(res?.data?.data?.medicineResponseList);
      //update the shimmer state
      setShimmer(false)
    } catch (error) {
      //update the shimmer state if error
      setShimmer(false)
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getDodData();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getDodData();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getDodData();
          },
        });
      }
    }
  };

  const handleSeeAllProducts = () => {
    navigate('/wholesale/DoD');
  }

  //onMount
  useEffect(() => {
    if (!data) return;
    getDodData();
  }, [data]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
     
     {!shimmer && dodData && (
      <div className="tsp-main-container dod-main-container" {...props}>
        <div className="tsp-header-wrapper">
          <div className="tsp-header font_ubuntu">
            <GiReceiveMoney size="1.5rem" color="#1A4059" />
            <span className="font_weight_md" style={{ fontSize: ".9rem" }}>
              {data?.data?.title}
            </span>
            {dodData && (
              <Timer timerEpoch = {dodData[0]?.batchInfo?.dealEndTime} />
            )}
          </div>
          <SeeAllButton onClick = {handleSeeAllProducts} />
        </div>
        <div className="wholesale-product-card-wrapper">
          {!shimmer &&  dodData?.map((item, index) => {
            return (
              <WholeSaleProductCard data={item} key={index} variant="light" />
            );
          })}
        </div>
      </div>
     )}

     {shimmer && (
      <div className="tsp-main-container dod-main-container">
      <div className="tsp-header-wrapper">
        <div className="tsp-header font_ubuntu">
          <GiReceiveMoney size="1.5rem" color="#1A4059" />
          <span className="font_weight_md" style={{ fontSize: ".9rem" }}>
            {data?.data?.title}
          </span>
          {dodData && (
            <Timer timerEpoch = {dodData[0]?.batchInfo?.dealEndTime} />
          )}
        </div>
        <SeeAllButton onClick = {handleSeeAllProducts} />
      </div>
      <div className="wholesale-product-card-wrapper">
          <>
          {[...Array(5)].map((_, index) => {
            return (
              <WholeSaleProductCard shimmer={shimmer} key={index} variant='light'/>
            )
          })}
          </>
      </div>
    </div>
     )}

    </>
  );
}

export default DoD;
