import React from "react";
import "./GamificationBanner.css";
import GiftIcon from "../../Images/png/present.png";
import SaveoLogo from "../../Images/svg/Group 21986.svg";
import { useNavigate } from "react-router";

export const GamificationBanner = () => {
  let navigate = useNavigate();

  return (
    <div
      className="gamification_banner"
      onClick={() => navigate("/campaign-gifts")}
    >
      <img
        className="gamification_gift_icon"
        src={GiftIcon}
        alt="Gamification Gift Icon"
      />

      <div className="gamification_banner_content">
        <div className="game_sub_cont">
          <div className="gamification_banner_head">
            <h3>Win Exciting Gifts</h3>
          </div>

          <div className="game_offer_cont">
            <p>on</p>

            <img
              className="gamification_saveo_icon"
              src={SaveoLogo}
              alt="Saveo Icon"
            />
          </div>
        </div>

        <button className="gamification_cta_btn">
          <p>
            <span>See all Offers</span>
          </p>
        </button>
      </div>
    </div>
  );
};
