import React, { useRef } from "react";
import { useEffect, useContext, useState } from "react";
import {
  Accordion,
  Container,
  Row,
} from "react-bootstrap";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router";
import { refreshToken } from "../../Actions/Auth";
import { Common_Event_Hit } from "../../Actions/Events";
import { getFaqData } from "../../Actions/Support";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import "./help-section.css";

function QandA() {
  const [faqData, setFaqData] = useState([]);
  const [modifiedFaqData, setModifiedFaqData] = useState([]);
  const { userData, setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [searchQuery, setSearchQuery] = useState("");
  const [searchHitKeys, setSearchHitKeys] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [shimmer, setshimmer] = useState(false);
  const [subIssueActiveId, setSubIssueActiveId] = useState(null);
  const navigate = useNavigate();
  const searchBarRef = useRef();
  const activeIdRef = useRef();
  const subIssueActiveIdRef = useRef();

  const highlightSearchText = (text, highlight) => {
    let data = JSON.parse(text);
    let indices = [];
    data.map((item, index) => {
      if (item.issue.toLowerCase().includes(highlight.toLowerCase())) {
        item.issue = item.issue.replace(
          new RegExp(highlight, "gi"),
          (match) => `<mark style="background:yellow">${match}</mark>`
        );
        indices.push(`${index}`);
      }
      item.subIssue.map((sIssue, idx) => {
        if (sIssue.subIssue.toLowerCase().includes(highlight.toLowerCase())) {
          sIssue.subIssue = sIssue.subIssue.replace(
            new RegExp(highlight, "gi"),
            (match) => `<mark style="background:yellow">${match}</mark>`
          );
          if (!indices.includes(sIssue.tag[0].charCodeAt(0) - 97)) {
            indices.push(`${sIssue.tag[0].charCodeAt(0) - 97}`); //gives the character code with a staring at 97 which should give the index of the parent 'item' here
          }
        }
      });
    });
    setSearchHitKeys([...indices]);

    console.log("updated data", data);
    setModifiedFaqData(data);
  };

  // useEffect(() => {
  //   console.log("faqData", faqData);
  //   console.log("searchHitKeys", searchHitKeys);
  // }, [faqData, searchHitKeys]);

  const findSearchData = () => {
    highlightSearchText(JSON.stringify(faqData), searchQuery);
    // faqData.forEach((item, idx) => {
    //   let head = item.issue
    //   if(head.includes(`${searchQuery}`)){
    //     setFaqData({...item, issue : head.replace(`${searchQuery}`, `<mark>${searchQuery}</mark>`)})
    //   }
    //   setSearchHitKeys([...new Set([...searchHitKeys, `${idx}`])]);

    //   setSearchHitKeys([...searchHitKeys , `${idx}`])
    //   item.subIssue.map((sIssue, index) => {
    //     if (sIssue.subIssue.includes(`${searchQuery}`)) {
    //         searchHitKeys.push(sIssue.id)
    //       setFaqData([ ...faqData, [item.issue] = item.issue.replace(`${searchQuery}`, `<mark>${searchQuery}</mark>`) ])
    //       sIssue.subIssue.replace(`${searchQuery}`, `<mark>${searchQuery}</mark>`)
    //       setSearchHitKeys([...new Set([...searchHitKeys, `${idx}`])]);
    //         setSearchHitKeys([sIssue.id]);
    //     }
    //   });
    // });
  };

  useEffect(() => {
    if (searchQuery === "") {
      setModifiedFaqData(faqData);
      setSearchHitKeys([]);
      setActiveId(null);
      return;
    }
    findSearchData();
  }, [searchQuery]);

  const handleSearchQuery = (e) => {
    const val = e.target.value;
    // if(val.length < searchQuery.length){
    //   setResetSearch(true)
    // }
    setSearchQuery(val);
    callEventsApi("faq_search");
    // findSearchData();
  };

  const fetchFaqData = async () => {
    setshimmer(true);
    getFaqData("fetchFaqs", {})
      .then((res) => {
        setFaqData(res.data.data);
        setModifiedFaqData(res.data.data);
        setshimmer(false);
      })
      .catch((error) => {
        console.log(error.response);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            fetchFaqData();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong!",
            tryAgain: function () {
              fetchFaqData();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              fetchFaqData();
            },
          });
        }
      });
  };

  //

  const toggleActive = (id) => {
    setSearchHitKeys(() => []); //should enable the user to toggle the accordions after searching for some data
    if (activeId === id) {
      setActiveId(null);
      activeIdRef.current = null;
      callEventsApi("faq_collapse");
    } else {
      setActiveId(id);
      activeIdRef.current = id;
      callEventsApi("faq_expand");
    }
  };

  const handleSubIssueActiveId = (e) => {
    // console.log('handleSubIssueActiveId', e)
    if (subIssueActiveId === e || subIssueActiveId) {
      setSubIssueActiveId(null);
      subIssueActiveIdRef.current = null;
      callEventsApi("qna_collapse");
      return;
    }
    subIssueActiveIdRef.current = e;
    callEventsApi("qna_expand");
    // console.log('e',e)
    setSubIssueActiveId(e);
  };

  const callEventsApi = (eventName) => {
    let props = {
      eventName: eventName,
    };
    if (eventName === "faq_search_tap") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    } else if (eventName === "faq_collapse") {
      console.log("faq_collapse");
      let properties = props;
      console.log("collapse item", faqData[parseInt(activeId)]?.issue);
      properties.expand_issue = faqData[parseInt(activeId)]?.issue;
      Common_Event_Hit("DYNAMIC_EVENT", props);
    } else if (eventName === "faq_expand") {
      console.log("faq_expand");
      let properties = props;
      console.log("expand item", faqData[parseInt(activeIdRef.current)]?.issue);
      // console.log('activeId', activeIdRef.current)
      properties.expand_issue = faqData[parseInt(activeIdRef.current)]?.issue;
      Common_Event_Hit("DYNAMIC_EVENT", props);
    } else if (eventName === "qna_expand") {
      console.log("qna_expand");
      let properties = props;
      properties.scree_name = "faq_answer_screen";
      // console.log('subIssueActiveId',subIssueActiveId)
      faqData[parseInt(activeId)]?.subIssue.forEach((item) => {
        if (item?.id === subIssueActiveIdRef.current) {
          console.log("expand item", item?.subIssue);
          properties.expand_issue = item?.subIssue;
        }
      });
      // console.log('faqData[parseInt(activeId)]?.issue?.subIssue[subIssueActiveId]?.subIssue', faqData[parseInt(activeId)]?.subIssue[subIssueActiveId]?.subIssue)
      // properties.expand_issue = faqData[parseInt(activeId)]?.subIssue[subIssueActiveId]?.subIssue
      Common_Event_Hit("DYNAMIC_EVENT", properties);
    } else if (eventName === "qna_collapse") {
      console.log("qna_collapse");
      let properties = props;
      properties.scree_name = "faq_answer_screen";
      // console.log('subIssueActiveId',subIssueActiveId)
      faqData[parseInt(activeId)]?.subIssue.forEach((item) => {
        if (item?.id === subIssueActiveId) {
          console.log("collapse item", item?.subIssue);
          properties.expand_issue = item?.subIssue;
        }
      });
      Common_Event_Hit("DYNAMIC_EVENT", properties);
    }
  };

  //onMount
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("currentScreen", "qna_screen");
    fetchFaqData();
  }, []);

  // useEffect(() => {
  //   if (subIssueActiveId) {
  //     callEventsApi("qna_collapse");
  //   }
  //   callEventsApi("qna_expand");
  // }, [subIssueActiveId]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Container fluid style={{ margin: "0px", padding: "0px" }}>
        <Container
          fluid
          className="q-and-a-parent-container qa-parent-alternative"
        >
          <div className="nav-container cursor_pointer" onClick={() => navigate(-1)}>
            <BiArrowBack size="1.5rem" color="#4b4495" />
            <span className="page-title">Question and Answers</span>
          </div>
          <Container fluid className="main-container" style={{marginTop:'0'}}>
            <Row className="search-container">
              <div className="qa-search-wrapper">
                <div className="qa-search-icon">
                  <BsSearch
                    color="#97ABB8"
                    onClick={() => {
                      searchBarRef.current.focus();
                      callEventsApi("faq_search_tap");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="qa-input-container">
                  <input
                    className="qa-search"
                    placeholder="Search FAQs"
                    value={searchQuery}
                    onChange={handleSearchQuery}
                    ref={searchBarRef}
                  />
                </div>
              </div>
            </Row>
            <Row className="faq-accordion-container">
              {shimmer &&
                [...Array(7)].map((item, index) => {
                  return (
                    <div className="qa-panel-wrap animateShimmer">
                      <div className="qa-panel-header animateShimmer">
                        <div className="qa-header-items-wrapper animateShimmer"></div>
                      </div>
                    </div>
                  );
                })}
              {!shimmer &&
                modifiedFaqData.map((item, index) => {
                  return (
                    <div className="qa-panel-wrap">
                      <div
                        className={
                          activeId === `${index}` ||
                          searchHitKeys.includes(`${index}`)
                            ? "qa-panel-header accordion-expanded"
                            : "qa-panel-header"
                        }
                        onClick={() => toggleActive(`${index}`)}
                      >
                        <div className="qa-header-items-wrapper">
                          <p
                            className="accordion-header-text"
                            style={
                              activeId === `${index}` ||
                              searchHitKeys.includes(`${index}`)
                                ? { color: "#116361", fontWeight: "500" }
                                : { color: "#217F78" }
                            }
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${item.issue}`,
                              }}
                            ></div>
                            {/* {item.issue.includes(searchQuery) && searchQuery.length > 0 
                          ? <div dangerouslySetInnerHTML={{__html:`${item.issue.replace(searchQuery , <mark>{searchQuery}</mark>)}`}}></div> 
                          : <div dangerouslySetInnerHTML={{__html:`${item.issue}` }}></div> } */}
                          </p>
                          {activeId === `${index}` ? (
                            <div>
                              <AiOutlineMinus color="#116361" size="1rem" />
                            </div>
                          ) : (
                            <div>
                              <AiOutlinePlus color="#48D1CC" size="1rem" />
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          activeId === `${index}` ||
                          searchHitKeys.includes(`${index}`)
                            ? "collapse.show"
                            : "collapse"
                        }
                      >
                        {item?.subIssue.map((sIssue, idx) => {
                          return (
                            <Accordion
                              flush
                              className="question-accordion"
                              onSelect={handleSubIssueActiveId}
                            >
                              <Accordion.Item eventKey={sIssue.id}>
                                <Accordion.Header>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: `${sIssue.subIssue}`,
                                    }}
                                  ></div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: `${sIssue?.answer}`,
                                    }}
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </Row>
          </Container>
        </Container>
      </Container>
    </>
  );
}

export default QandA;
