import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import "../../App.css";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate, useLocation } from "react-router-dom";
import { refreshToken } from "../../Actions/Auth";
import InfiniteScroll from "react-infinite-scroll-component";
import NoData from "../../Images/png/no_data.png";
import { My_Account } from "../../Actions/MyAccount";
import { usersData } from "../../App";
import WalletHistoryCard from "./WalletHistoryCard";
import ErrorModal from "../Modals/ErrorModal";

const WalletHistory = (props) => {
  const [screenData, setScreenData] = useState();
  // const { userLoggedIn} = React.useContext(userStatus);
  const [productRes, setProductRes] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const { userData, setShowMaintenance } = useContext(usersData);
  const [homeShimmer, setHomeShimmer] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });

  let navigate = useNavigate();

  let location = useLocation();

  const getData = () => {
    page === 0 && setHomeShimmer(true);
    const params = {
      page: page,
      retailerId: userData?.id,
    };
    setScreenData("Wallet History");
    let api = My_Account("WALLET_HISTORY", params);
    api
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          let data = [...productRes];
          data.push(...res.data.data);
          console.log(data);
          setProductRes(data);
          setDataLength(data.length);
        } else {
          setHasMore(false);
        }
        setHomeShimmer(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken().then((res) => {
            getData();
          });
        } else if (
          error.response.status === 502 ||
          error.response.status === 503
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error.response.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
          });
        }
      });
  };

  let fetchMoreData = () => {
    setPage(+page + 1);
  };

  useEffect(() => {
    let data = localStorage.getItem("userData");
    localStorage.setItem("currentScreen", "wallet_history_screen");
    localStorage.removeItem("currentScreenBannerData");

    if (data && data.length) {
      userData?.id && getData();
    } else {
      navigate("/login");
    }
  }, [location, page, userData]);

  return (
    <Fragment>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Container fluid className="productList">
        <Container fluid className="productListHeader font_ubuntu">
          {" "}
          <HiOutlineArrowNarrowLeft
            className="cursor_pointer goBack"
            onClick={() => navigate(-1)}
          />{" "}
          &nbsp;&nbsp;&nbsp; {screenData}
        </Container>

        {homeShimmer && (
          <>
            <Container>
              <div className="skeletal_div_sm animateShimmer"></div>
            </Container>
            <Container>
              <div className="skeletal_div_sm animateShimmer"></div>
            </Container>
            <Container>
              <div className="skeletal_div_sm animateShimmer"></div>
            </Container>
            <Container>
              <div className="skeletal_div_sm animateShimmer"></div>
            </Container>
          </>
        )}
        {productRes && productRes.length > 0 && !homeShimmer && (
          <div id="scrollableDiv" className="productList" style={{paddingTop: '0rem'}}>
            <InfiniteScroll
              dataLength={dataLength}
              next={fetchMoreData}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
              scrollThreshold="90%"
              className="infiniteScroll"
              loader={
                <Spinner
                  animation="border"
                  className="color_secondary spinner"
                  size="lg"
                />
              }
            >
              {productRes &&
                productRes.map((history, index) => (
                  <WalletHistoryCard data={history} key={index} />
                ))}
            </InfiniteScroll>
          </div>
        )}

        {productRes && productRes.length === 0 && !homeShimmer && (
          <div
            id="scrollableDiv"
            className="productList "
            style={{ paddingTop: "50px" }}
          >
            <div className="no_data_parent">
              <img src={NoData} alt="No Data" />
              <br /> <br />
              <p className="font_ubuntu font_16 font_weight_md">
                No transactions yet !
              </p>
            </div>
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default React.memo(WalletHistory);

// {
  /* <InfiniteScroll
    dataLength={productRes.medicinesResponsesList.length}
    next={fetchMoreData}
    //To put endMessage and loader to the top.
    inverse={true} //
    hasMore={true}
    height={'52vh'}
    loader={<h4>Loading...</h4>}
    scrollableTarget="scrollableDiv"
  endMessage={
    <p style={{ textAlign: 'center' }}>
      <b>Yay! You have seen it all</b>
    </p>
  }
  // below props only if you need pull down functionality
  
  >
    {productRes.medicinesResponsesList.map((med, index) => (
        <ProductCard2 key={index} medData={med}/>
    ))}
  </InfiniteScroll> */
// }
