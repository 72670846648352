import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Skeleton,
  Stack,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { BsCircleFill } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import { refreshToken } from "../../Actions/Auth";
import { getLiveOrders, getPastOrders } from "../../Actions/Expiry";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import OrderMessageModal from "../Modals/orderMessageModal";
import ButtonLoading1 from "../Spinners/ButtonLoading1";

function AllExpiry() {
  const [allExpiryData, setAllExpiryData] = useState([]);
  const [page, setPage] = useState(0);
  const { userData, setShowMaintenance } = useContext(usersData);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [hasMore, setHasMore] = useState(true);
  const [orderType, setOrderType] = useState("live");
  const [isFetching, setIsFetching] = useState(false);

  //get live orders api call
  const getAllLiveOrders = async () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let params = {
      retailerCode: data.retailerCode,
      page: page,
    };
    // updating the api loading state
    setIsFetching(true);
    try {
      const res = await getLiveOrders(params);
      if (page === 0) setAllExpiryData(res?.data?.data);
      else setAllExpiryData([...allExpiryData, ...res.data.data]);
      if (res.data.data.length === 0) setHasMore(false);
      else setHasMore(true);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getAllLiveOrders();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getAllLiveOrders();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getAllLiveOrders();
          },
        });
      }
    }
  };

  // get past orders api call
  const getAllPastOrders = async () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let params = {
      retailerCode: data.retailerCode,
      page: page,
    };
    setIsFetching(true);
    try {
      const res = await getPastOrders(params);
      if (page === 0) setAllExpiryData(res?.data?.data);
      else setAllExpiryData([...allExpiryData, ...res.data.data]);
      if (res.data.data.length === 0) setHasMore(false);
      else setHasMore(true);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getAllPastOrders();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getAllPastOrders();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getAllPastOrders();
          },
        });
      }
    }
  };

  const fetchMoreData = () => {
    setPage((prevVal) => prevVal + 1);
  };

  const handleOrderTypeChange = (e) => {
    // reset the all expiry data
    setAllExpiryData([]);
    setPage(0);
    setOrderType(e.target.value);
  };

  //get products data everytime pageCount updates to enable infinity scroll
  useEffect(() => {
    if (page === 0) return;
    if (orderType === "live") getAllLiveOrders();
    else getAllPastOrders();
  }, [page]);

  //onMount
  useEffect(() => {
    // resrt the page when order changes
    console.log("resetting page...");
    if (orderType === "live") getAllLiveOrders();
    else getAllPastOrders();
  }, [orderType]);
  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      <div className="all-expiry-main-container" id="expiryScrollableDiv">
        <div className="flex-align-center-row expiry-type-container">
          {/* <button
            className={`order-type-btn ${orderType === "live" && "active"}`}
            onClick={() => handleOrderTypeChange('live')}
          >
            Live Expiry
          </button>
          <button
            className={`order-type-btn ${orderType === "past" && "active"}`}
            onClick={() => handleOrderTypeChange('past')}
          >
            Past Expiry
          </button> */}
          <FormControl
            variant="standard"
            size="small"
            sx={{ m: 1, minWidth: 130 }}
          >
            <Select
              value={orderType}
              onChange={handleOrderTypeChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              style={{ backgroundColor: "#fff", fontSize: ".9rem" }}
              className="expiry-type-select"
              onKeyDown={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
            >
              <MenuItem sx={{ fontSize: ".8rem" }} value="live">
                Live Expiry
              </MenuItem>
              <MenuItem sx={{ fontSize: ".8rem" }} value="past">
                Past Expiry
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <InfiniteScroll
          className="expiry-infinite-scroll"
          dataLength={allExpiryData?.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <>
              <Skeleton
                className="expiry-card"
                variant="rectangular"
                sx={{ height: "10rem", bgcolor: "grey.100" }}
              />
              <Skeleton
                className="expiry-card"
                variant="rectangular"
                sx={{ height: "10rem", bgcolor: "grey.100" }}
              />
              <Skeleton
                className="expiry-card"
                variant="rectangular"
                sx={{ height: "10rem", bgcolor: "grey.100" }}
              />
            </>
          }
          scrollableTarget="expiryScrollableDiv"
          scrollThreshold="90%"
          endMessage={
            <div style={{ width: "100%" }} className="flex-align-center-row">
              <p style={{ width: "60%" }} className="listEnd-msg">
                ⁌ end of expiry items ⁍
              </p>
            </div>
          }
        >
          {allExpiryData.length > 0 &&
            allExpiryData.map((item, index) => {
              return (
                <Card className="expiry-card shadow-sm" key={index}>
                  <Card.Body>
                    <ListGroup.Item>
                      <div>
                        <p>
                          <span className="oreder-list-item-header-text">
                            Expiry Number :
                          </span>{" "}
                          {item?.id}
                        </p>
                        <p style={{ fontSize: ".8rem" }}>
                          <BsCircleFill
                            className="color_secondary"
                            style={{ marginRight: ".5rem" }}
                            size=".6rem"
                          />
                          {item?.status}
                        </p>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <p>
                        {item?.itemList.length}{" "}
                        {item?.itemList.length > 1 ? "items" : "item"}
                      </p>
                      {item?.itemList.map((listItem, idx) => {
                        return (
                          <Card
                            className="expiry-inner-card shadow-sm"
                            key={idx}
                            style={{ width: "100%" }}
                          >
                            <Card.Body>
                              <ListGroup.Item>
                                <div>
                                  <p style={{ fontWeight: "bold" }}>
                                    {listItem?.itemName}
                                  </p>
                                  <p>
                                    <span className="oreder-list-item-header-text">
                                      Expiry
                                    </span>{" "}
                                    {listItem?.expiryDate}
                                  </p>
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <div>
                                  <p>
                                    <span className="oreder-list-item-header-text">
                                      Quantity :{" "}
                                    </span>
                                    {listItem?.quantity}
                                  </p>
                                  <p>
                                    <span className="oreder-list-item-header-text">
                                      MRP :{" "}
                                    </span>
                                    &#8377; {listItem?.mrp}
                                  </p>
                                  <p>
                                    <span className="oreder-list-item-header-text">
                                      Batch :{" "}
                                    </span>
                                    {listItem?.batchNumber}
                                  </p>
                                  <p>
                                    <span className="oreder-list-item-header-text">
                                      Total :{" "}
                                    </span>
                                    &#8377; {listItem?.netValue.toFixed(2)}
                                  </p>
                                </div>
                              </ListGroup.Item>
                            </Card.Body>
                          </Card>
                        );
                      })}
                    </ListGroup.Item>
                  </Card.Body>
                </Card>
              );
            })}

          {allExpiryData.length === 0 && !isFetching && <p>No items found!</p>}

          {/* shimmers */}
          {allExpiryData.length === 0 && isFetching && (
            <>
              <Skeleton
                className="expiry-card"
                variant="rectangular"
                sx={{ height: "10rem", bgcolor: "grey.100" }}
              />
              <Skeleton
                className="expiry-card"
                variant="rectangular"
                sx={{ height: "10rem", bgcolor: "grey.100" }}
              />
              <Skeleton
                className="expiry-card"
                variant="rectangular"
                sx={{ height: "10rem", bgcolor: "grey.100" }}
              />
            </>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
}

export default AllExpiry;
