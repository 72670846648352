import { Modal, Button, NavItem } from "react-bootstrap";
import React, { Fragment } from "react";
import '../../App.css';
import './dialog.css'
import Order from '../../Images/png/order.jpg'
const EditOrderModal = (props) => {
    return (
        <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="del_modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="padding_10 text_center">
        <img src={Order} className="delete_modal_svg"/>
        <br></br>
        <p className="font_ubuntu color_primary font_weight_md font_18">
           {props.content}
        </p>
        {
            props.sub_content && <>
            <br/>
            <div className="font_ubuntu font_weight_md color_secondary text_center font_14" style={{marginBottom:'20px'}}>Thanks for ordering</div>
            <div className="font_ubuntu color_primary_lt font_weight_md font_14 modal_content">
        {props.sub_content}
        </div>
            </>
        }
       
        </Modal.Title>
      </Modal.Header>
      {/* <Modal.Body>
       
      </Modal.Body> */}
     
    </Modal>
    )
}

export default EditOrderModal
