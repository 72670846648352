import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useContext,
  useRef,
} from "react";
import "./Orders.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
  Button,
  Spinner,
  DropdownButton,
  Dropdown,
  Form,
} from "react-bootstrap";
import Delete from "../../Images/svg/delete.svg";
import { AiFillGift, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import DeleteProductModal from "../Modals/DeleteProductModal";
import { useNavigate } from "react-router";
import { My_Account } from "../../Actions/MyAccount";
import { refreshToken } from "../../Actions/Auth";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import EditOrderModal from "../Modals/EditOrder";
import ReasonInput from "../Modals/ReasonInput";
import OrderMessageModal from "../Modals/orderMessageModal";
import ReturnSuccessModal from "../Modals/ReturnSuccessModal";
import { Common_Event_Hit } from "../../Actions/Events";
import { DigitalPaymentModal } from "../Modals/DigitalPaymentModal";
import { Digital_Payment_Actions } from "../../Actions/DigitalPayment";
import InfoModal from "../Modals/InfoModal";
import Overlay from "react-bootstrap/Overlay";

const OrderCard = ({
  medData,
  refresh,
  filter,
  resetOrderData,
  paymentCardScroll,
}) => {
  let navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState();
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [returnScreen, setReturnScreen] = useState(false);
  const [returnPlaced, setReturnPlaced] = useState(false);
  const [showPayTargetMsg,setPayTargetMsg]=useState(false);
  const [returnRefundData, setReturnRefundData] = useState({
    loader: false,
    data: null,
  });
  const [orderMessage, setOrderMessage] = useState({
    show: false,
    content: "",
    header: "",
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    item: "",
  });
  const [showSuccessDialog, setSuccessDialog] = useState({
    show: false,
    content: "",
  });
  const [saveOrderSpinner, setSaveOrderSpinner] = useState(false);
  const [cancelOrderSpinner, setCancelOrderSpinner] = useState(false);
  const [openAccordion, setAccordion] = useState(false);
  const [returnSubmit, setReturnSubmit] = useState(false);
  const [showInputReason, setInputReason] = useState({
    show: false,
    item: "",
    index: null,
  });
  const [returnReasons, setReturnReasons] = useState([
    "Not required",
    "Near expiry",
    "Extra Items",
    "Damaged",
    "Wrong Items",
    "Other",
  ]);
  const [returnInvoice, setReturnIvoice] = useState();
  const [openPayment, setOpenPayment] = useState(false);
  const [activePaymentModes, setActivePaymentModes] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState(null);
  const [resetPastOrder, setResetPastOrder] = useState(false);
  const payTargetBtnRef=useRef(null)
  const addQuantity = (e, item, i) => {
    e.stopPropagation();
    let updatedQuantity;
    let multiplyFactorForOrderQuantity =
      item.multiplyFactorForOrderQuantity > 0
        ? +item.multiplyFactorForOrderQuantity
        : 1;

    updatedQuantity = item.updatedQuantity
      ? +item.updatedQuantity + multiplyFactorForOrderQuantity
      : +item.medsToDeliver + multiplyFactorForOrderQuantity;
    if (item.maxOrderQuantity > 0 && updatedQuantity <= item.maxOrderQuantity) {
      item.updatedQuantity = updatedQuantity;
      item.enteredQty = updatedQuantity;
      let obj = Object.assign({}, data);
      obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
      setData(obj);
    } else if (item.maxOrderQuantity === 0) {
      item.updatedQuantity = updatedQuantity;
      item.enteredQty = updatedQuantity;
      let obj = Object.assign({}, data);
      obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
      setData(obj);
    }

    if (
      item.maxOrderQuantity > 0 &&
      updatedQuantity > 0 &&
      updatedQuantity > item.maxOrderQuantity
    ) {
      item.maxQtyError = true;
      let obj = Object.assign({}, data);
      obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
      setData(obj);
      setTimeout(() => {
        item.maxQtyError = false;
        let obj = Object.assign({}, data);
        obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
        setData(obj);
      }, 2000);
    }
  };
  const minusQuantity = (e, item, i) => {
    e.stopPropagation();

    let multiplyFactorForOrderQuantity =
      +item.multiplyFactorForOrderQuantity > 0
        ? +item.multiplyFactorForOrderQuantity
        : 1;
    let updatedQuantity = item.updatedQuantity
      ? +item.updatedQuantity - multiplyFactorForOrderQuantity
      : +item.medsToDeliver - multiplyFactorForOrderQuantity;
    if (updatedQuantity > 0 && updatedQuantity >= item.minOrderQuantity) {
      item.updatedQuantity = updatedQuantity;
      item.enteredQty = updatedQuantity;
      let obj = Object.assign({}, data);
      obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
      setData(obj);
    }

    if (updatedQuantity < item.minOrderQuantity) {
      item.minQtyError = true;
      let obj = Object.assign({}, data);
      obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
      setData(obj);
      setTimeout(() => {
        item.minQtyError = false;
        let obj = Object.assign({}, data);
        obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
        setData(obj);
      }, 2000);
    }
  };

  const minusReturnQuantity = (e, item, i) => {
    e.stopPropagation();
    setReturnSubmit(false);

    let updatedQty;

    item.updatedQuantity
      ? (updatedQty = +item.updatedQuantity - 1)
      : (updatedQty = +item.quantity - 1);
    if (updatedQty > 0) {
      let obj = Object.assign({}, returnInvoice);
      obj.buyerInvoiceDetailList[i].updatedQuantity = updatedQty;
      setReturnIvoice(obj);
    }
  };

  const addReturnQuantity = (e, item, i) => {
    e.stopPropagation();
    setReturnSubmit(false);

    let updatedQty;
    item.updatedQuantity
      ? (updatedQty = +item.updatedQuantity + 1)
      : (updatedQty = +item.quantity + 1);
    if (updatedQty <= item.quantity) {
      let obj = Object.assign({}, returnInvoice);
      obj.buyerInvoiceDetailList[i].updatedQuantity = updatedQty;
      setReturnIvoice(obj);
    }
  };

  const editReturnQty = (e, item, i) => {
    e.stopPropagation();
    setReturnSubmit(false);

    if (e.target.value.length) {
      if (+e.target.value <= item.quantity) {
        let obj = Object.assign({}, returnInvoice);
        obj.buyerInvoiceDetailList[i].updatedQuantity = e.target.value;
        setReturnIvoice(obj);
      }
    } else {
      let obj = Object.assign({}, returnInvoice);
      obj.buyerInvoiceDetailList[i].updatedQuantity = e.target.value;
      setReturnIvoice(obj);
    }
  };

  const onFocusOutReturn = (e, item, i) => {
    e.stopPropagation();
    if (!e.target.value.length) {
      let obj = Object.assign({}, returnInvoice);
      obj.buyerInvoiceDetailList[i].updatedQuantity = item.quantity;
      setReturnIvoice(obj);
    }
  };

  const removeFromOrder = (e, i) => {
    let obj = Object.assign({}, data);
    obj.orderDetailList[i].isDeleted = true;
    obj.orderDetailList[i].enteredQty = 0;
    obj.orderDetailList[i].updatedQuantity = 0;
    setData(obj);
    setShowDeleteModal(false);
  };

  const editCart = (e, item, i) => {
    if (e.target.value.length) {
      if (+e.target.value > 0) {
        let condition1 = true;
        let error = "";
        if (
          item.maxOrderQuantity > 0 &&
          +e.target.value > item.maxOrderQuantity
        ) {
          condition1 = false;
          item.maxQtyError = true;
          let obj = Object.assign({}, data);
          obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
          setData(obj);
          setTimeout(() => {
            item.maxQtyError = false;
            let obj = Object.assign({}, data);
            obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
            setData(obj);
          }, 2000);
        }
        let condition2 = true;
        if (
          item.minOrderQuantity > 0 &&
          +e.target.value > 0 &&
          +e.target.value < item.minOrderQuantity
        ) {
          condition2 = false;
          item.minQtyError = true;
          let obj = Object.assign({}, data);
          obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
          setData(obj);
          setTimeout(() => {
            item.minQtyError = false;
            let obj = Object.assign({}, data);
            obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
            setData(obj);
          }, 2000);
        }

        let condition3 = true;
        if (
          item.multiplyFactorForOrderQuantity > 0 &&
          +e.target.value % item.multiplyFactorForOrderQuantity !== 0
        ) {
          condition3 = false;
          item.multipleQtyError = true;
          let obj = Object.assign({}, data);
          obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
          setData(obj);
          setTimeout(() => {
            item.multipleQtyError = false;
            let obj = Object.assign({}, data);
            obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
            setData(obj);
          }, 500);
        }

        if (condition1 && condition2 && condition3) {
          item.updatedQuantity = +e.target.value;
          let obj = Object.assign({}, data);
          obj && obj.orderDetailList && (obj.orderDetailList[i] = item);
          setData(obj);
        } else {
          let obj = Object.assign({}, data);
          if (obj.orderDetailList[i].updatedQuantity) {
            obj.orderDetailList[i].enteredQty =
              obj.orderDetailList[i].updatedQuantity;
          } else {
            obj.orderDetailList[i].enteredQty =
              obj.orderDetailList[i].medsToDeliver;
          }
          setData(obj);
        }
      } else {
        let obj = Object.assign({}, data);
        if (obj.orderDetailList[i].updatedQuantity) {
          obj.orderDetailList[i].enteredQty =
            obj.orderDetailList[i].updatedQuantity;
        } else {
          obj.orderDetailList[i].enteredQty =
            obj.orderDetailList[i].medsToDeliver;
        }
        obj.orderDetailList[i].minQtyError = true;
        setData(obj);
        setTimeout(() => {
          let obj = Object.assign({}, data);
          obj.orderDetailList[i].minQtyError = false;
          setData(obj);
        }, 2000);
      }
    }
  };

  const setQty = (e, i) => {
    let obj = Object.assign({}, data);
    obj.orderDetailList[i].enteredQty = e.target.value;
    setData(obj);
  };

  const onFocusOutLive = (e, item, i) => {
    if (!e.target.value.length) {
      let obj = Object.assign({}, data);
      obj.orderDetailList[i].enteredQty = item.medsToDeliver;
      obj.orderDetailList[i].updatedQuantity = item.medsToDeliver;
      setData(obj);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 800);
    };
  };

  const editCartOpt = useCallback(debounce(editCart), [data]);

  const saveOrder = () => {
    setSaveOrderSpinner(true);
    let body = {
      editOrderList: [],
      orderId: data.orderId,
      slotName: data.deliverySlot,
    };

    body.editOrderList = data.orderDetailList.map((item, i) => {
      let obj = {
        dealType: "string",
        medicineId: "string",
        quantity: 0,
        selectedSlotDate: data.expectedDeliveryDate,
        selectedSlotName: data.deliverySlot,
      };
      obj.dealType = item.dealType;
      obj.medicineId = item.medicineId;
      obj.quantity = item.hasOwnProperty("enteredQty")
        ? item.enteredQty
        : item.medsToCountPrice;
      return obj;
    });

    My_Account("EDIT_ORDER", {}, body)
      .then((res) => {
        setSaveOrderSpinner(false);
        setSuccessDialog({
          show: true,
          content: "Yay Order Edited",
          sub_content:
            "Once our team confirms the details within the next 24 working hours, we will allocate the delivery slot for your order",
        });
      })
      .catch((error) => {
        setSaveOrderSpinner(false);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            saveOrder();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              saveOrder();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              saveOrder();
            },
          });
        }
      });
  };

  const cancelOrder = () => {
    setCancelOrderSpinner(true);

    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "cancel_all_tap",
      orderId: data.orderId,
    });

    My_Account("CANCEL_ORDER", { orderId: data.orderId }, {})
      .then((res) => {
        setCancelOrderSpinner(false);
        setSuccessDialog({
          show: true,
          content: "Order Cancelled Succesfully",
        });
        Common_Event_Hit("DYNAMIC_EVENT", {
          eventName: "order_cancelled",
          orderId: data.orderId,
        });
      })
      .catch((error) => {
        setCancelOrderSpinner(false);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            cancelOrder();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              cancelOrder();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              cancelOrder();
            },
          });
        }
      });
  };

  const openItems = async (Return) => {
    if (Return) {
      await Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "return_tap",
        orderId: data.orderId,
      });

      setReturnSubmit(false);
      const params = {
        retailerId: data.retailerId,
        orderRetrieveId: data.orderId,
      };
      const body = {};
      My_Account("GET_RETURN_INVOICES", params, body)
        .then((res) => {
          if (res.data.data.length) {
            let invoice = JSON.parse(JSON.stringify(res.data.data[0]));
            res.data.data.forEach((item, i) => {
              if (i > 0) {
                invoice.buyerInvoiceDetailList.push(
                  ...item.buyerInvoiceDetailList
                );
              }
            });

            setReturnIvoice(invoice);
            setReturnScreen(true);
            setEdit(true);
            setAccordion(true);
            let obj = Object.assign({}, data);
            let returnData = obj.orderDetailList.map((item) => {
              item.maxOrderQuantity = item.medsToDeliver;
              return item;
            });

            obj.orderDetailList = returnData;
            setData(obj);
          } else {
            setOrderMessage({
              show: true,
              content: "Sorry, return is unavailable for this order",
              header: `Order ID: ${data.orderId}`,
            });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            refreshToken().then((res) => {
              openItems(true);
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
              tryAgain: function () {
                openItems(true);
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                openItems(true);
              },
            });
          }
        });
    } else {
      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "order_tap",
        orderId: data.orderId,
      });

      setEdit(false);
      setReturnScreen(false);
      setAccordion(!openAccordion);
    }
  };

  const returnContinue = async () => {
    //     returnReason: "Near expiry"
    // returnSelected: true
    await Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "continue_tap",
      orderId: returnInvoice.orderRetrieveId,
    });

    let count = 0;
    let status = true;
    let obj = Object.assign({}, returnInvoice);

    obj.buyerInvoiceDetailList.forEach((item) => {
      if (item.returnSelected) {
        count++;
        if (item.returnReason && item.returnReason.length === 0) {
          status = false;
          item.returnReasonError = true;
        } else if (!item.returnReason) {
          status = false;
          item.returnReasonError = true;
        } else {
          item.returnReasonError = false;
        }
      }
    });

    if (count > 0 && !status) {
      setReturnIvoice(obj);
    } else if (count === 0) {
      obj.returnError = true;
      setReturnIvoice(obj);
    }

    if (count > 0) {
      obj.returnError = false;
      setReturnIvoice(obj);
    }

    if (count > 0 && status) {
      setReturnRefundData({ loader: true, data: null });
      const params = { retailerId: data.retailerId };
      let medicineReturnList = returnInvoice.buyerInvoiceDetailList
        .map((item) => {
          if (item.returnSelected) {
            let obj = {};
            obj.batchId = item.batchId;
            obj.distributorId = item.distributorId;
            obj.itemCode = item.itemCode;
            obj.itemName = item.itemName;
            obj.medicineId = null;
            obj.medicineName = item.itemName;
            obj.packaging = item.packaging;
            obj.quantity = item.updatedQuantity
              ? item.updatedQuantity
              : item.quantity;
            obj.returnReason = item.returnReason;
            obj.sellerInvoiceNumber = item.sellerInvoiceNumber;
            obj.uniqueCode = item.uniqueCode;

            return obj;
          }
        })
        .filter((item) => item);

      const body = {
        orderReturnRequestList: [
          {
            medicineReturnRequestList: medicineReturnList,
            retrieveOrderId: returnInvoice.orderRetrieveId,
          },
        ],
      };

      My_Account("RETURN_PRODUCT_DETAILS", params, body)
        .then((res) => {
          setReturnSubmit(true);
          setReturnRefundData({ loader: false, data: res.data.data });
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            refreshToken().then((res) => {
              returnContinue(true);
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
              tryAgain: function () {
                returnContinue(true);
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                returnContinue(true);
              },
            });
          }
        });
    }
  };

  const returnOrder = async () => {
    setReturnRefundData({ loader: true, data: "" });
    await Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "return_now_tap",
      orderId: returnInvoice.orderRetrieveId,
    });

    const params = { retailerId: data.retailerId };
    let medicineReturnList = returnInvoice.buyerInvoiceDetailList
      .map((item) => {
        if (item.returnSelected) {
          let obj = {};
          obj.batchId = item.batchId;
          obj.distributorId = item.distributorId;
          obj.itemCode = item.itemCode;
          obj.itemName = item.itemName;
          obj.medicineId = null;
          obj.medicineName = item.itemName;
          obj.packaging = item.packaging;
          obj.quantity = item.updatedQuantity
            ? item.updatedQuantity
            : item.quantity;
          obj.returnReason = item.returnReason;
          obj.sellerInvoiceNumber = item.sellerInvoiceNumber;
          obj.uniqueCode = item.uniqueCode;

          return obj;
        }
      })
      .filter((item) => item);

    const body = {
      orderReturnRequestList: [
        {
          medicineReturnRequestList: medicineReturnList,
          retrieveOrderId: returnInvoice.orderRetrieveId,
        },
      ],
    };

    My_Account("PLACE_RETURN", params, body)
      .then((res) => {
        setReturnRefundData({ loader: false, data: "" });
        setReturnPlaced(true);
        Common_Event_Hit("DYNAMIC_EVENT", {
          eventName: "return_requested",
          orderId: returnInvoice.orderRetrieveId,
        });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            returnOrder(true);
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              returnOrder(true);
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              returnOrder(true);
            },
          });
        }
      });
  };

  const selectReturnItem = (e, item, i) => {
    setReturnSubmit(false);

    if (item.returnSelected) {
      item.returnSelected = !item.returnSelected;
    } else {
      item.returnSelected = true;
    }
    let obj = Object.assign({}, returnInvoice);
    obj.buyerInvoiceDetailList[i] = item;
    setReturnIvoice(obj);
  };

  const reasonSelect = (reason, item, i) => {
    setReturnSubmit(false);
    setInputReason({ show: false, item: null, index: null });
    if (reason === "Other") {
      setInputReason({ show: true, item: item, index: i });
    } else if (reason.length > 0) {
      item.returnReason = reason;
      let obj = Object.assign({}, returnInvoice);
      obj.buyerInvoiceDetailList[i] = item;
      setReturnIvoice(obj);
    }
  };

  // Get active payment modes & status
  const getActivePaymentModes = () => {
    const params = {
      invoiceNumber: data.invoiceNumber,
      source: "WEB",
    };
    Digital_Payment_Actions("ACTIVE_PAYMENT_MODES", params)
      .then((res) => {
        if (res.data.data.status === "ERROR") {
          setInfoMsg(res.data.data.message);
          setShowInfo(true);
        } else {
          setActivePaymentModes(res.data.data);
          document.body.className = "overflow-hidden";
          setOpenPayment(!openPayment);
        }

        document.body.className = "overflow-hidden";
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getActivePaymentModes();
          });
        } else if (
          error?.response?.status >= 400 &&
          error?.response?.status < 500
        ) {
          setOrderMessage({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error,
            header: `Info`,
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance(true);
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getActivePaymentModes();
            },
          });
        } else {
          setErrorModal({
            show: true,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getActivePaymentModes();
            },
          });
        }
      });
  };

  // Handle Digital Payment Modal
  const handleDigitalPayment = (resetData) => {
    if (!openPayment) {
      getActivePaymentModes();
    } else {
      document.body.className = "";
      setOpenPayment(!openPayment);
      if (resetData) {
        resetOrderData();
      }
    }
  };

  const handleInfoReset = () => {
    setShowInfo(false);
    if (resetPastOrder) {
      setResetPastOrder(false);
      resetOrderData();
    }
  };

  useEffect(() => {
    let obj = JSON.parse(JSON.stringify(medData));
    setData(Object.assign({}, obj));
  }, [medData, openAccordion]);

  // useEffect(() => {
  //   console.log("order type from card", filter);
  // }, [filter]);
  useEffect(() => {
    let targetDiv = document.getElementById("scrollTarget");
    targetDiv && paymentCardScroll(targetDiv.offsetTop);
    targetDiv && data?.scrollTarget && setPayTargetMsg(true)
  }, [data]);
  return (
    <>
      <EditOrderModal
        show={showSuccessDialog.show}
        content={showSuccessDialog.content}
        sub_content={showSuccessDialog.sub_content}
        onHide={() => {
          setSuccessDialog({ show: false, content: "" });
          refresh();
        }}
      />
      <OrderMessageModal
        show={orderMessage.show}
        content={orderMessage.content}
        header={orderMessage.header}
        onHide={() => {
          setOrderMessage({ show: false, content: "", header: "" });
        }}
      />
      <ReasonInput
        show={showInputReason.show}
        content={"Please Type Reason"}
        data={showInputReason}
        reasonSelect={reasonSelect}
        onHide={() => {
          setInputReason({ show: false, item: null, index: null });
        }}
      />
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
          refresh();
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <ReturnSuccessModal
        show={returnPlaced}
        content={"Yay! Return requested"}
        sub_content={"We have received your request"}
        sub_content1={"Our team make sure to call you soon"}
        onHide={() => {
          setReturnPlaced(false);
          refresh();
        }}
      />
      <InfoModal
        show={showInfo}
        handleInfoReset={handleInfoReset}
        message={infoMsg}
      />
      {openPayment && (
        <DigitalPaymentModal
          show={openPayment}
          orderData={data}
          handleDigitalPayment={handleDigitalPayment}
          activePaymentModes={activePaymentModes}
          setInfoMsg={setInfoMsg}
          setShowInfo={setShowInfo}
          setResetPastOrder={setResetPastOrder}
        />
      )}
      {data && (
        <div
          className="order_card"
          id={data?.scrollTarget ? "scrollTarget" : ""}
        >
          <div className="order_card_header display_flex space_btw color_black font_ubuntu font_weight_md">
            {data.orderDetailList && data.orderDetailList.length} items
            <div className="order_header_right">
              <div className="color_grey">Expected Delivery</div>
              <br />
              <div>
                {data?.expectedDeliveryDate} &nbsp; {data.deliverySlot}
              </div>
            </div>
            {filter === "past" && (
              <div className="order_header_right font_ubuntu">
                <div className="color_primary font_12">Total Value</div>
                <br />
                <div className="color_primary font_weight_md">
                  ₹ {data?.subtotal?.toFixed(2)}
                </div>
              </div>
            )}
          </div>

          <div className="order_card_body display_flex space_btw">
            <div className="order_header_right">
              <div className="color_grey">Order ID</div>
              <br />
              <div id="ordercard_id">{data.orderId}</div>
            </div>
            <div className="order_header_right text_center">
              <div className="color_grey">Order Date</div>
              <br />
              {
                data.orderTimeStamp ?   <div>
                {" "}
                {new Date(data.orderTimeStamp).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year:"numeric"
                })}
                 &nbsp;&nbsp;
                {new Date(data.orderTimeStamp).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>:"NA"
              }
             
            </div>

            <div className="orders_c2a_cntr">
              {/* Digital Payment Option */}
              {filter === "past" && data.onlinePaymentEnabled && (
                <>
                  <Overlay
                    target={payTargetBtnRef.current}
                    show={showPayTargetMsg}
                    placement="top"
                  >
                    {({
                      placement: _placement,
                      arrowProps: _arrowProps,
                      show: _show,
                      popper: _popper,
                      hasDoneInitialMeasure: _hasDoneInitialMeasure,
                      ...props
                    }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                        }}
                        className="paymentTriggerMsg"
                      >
                        Clear your pending invoices through UPI
                        <span className="arrow_cut"></span>
                      </div>
                    )}
                  </Overlay>
                  <Button ref={payTargetBtnRef} className="payNow_btn" onMouseEnter={()=>setPayTargetMsg(true)} onMouseLeave={()=>setPayTargetMsg(false)}  onClick={handleDigitalPayment}>
                    Pay Now
                  </Button>
                </>
              )}
              {filter === "past" && data.canReturn ? (
                <Button
                  variant="outline-primary"
                  className="return_btn font_ubuntu"
                  onClick={() => openItems(true)}
                >
                  Return
                </Button>
              ) : filter !== "past" ? (
                <div className="order_header_right font_ubuntu">
                  <div className="color_primary font_12">Total Value</div>
                  <br />
                  <div className="color_primary font_weight_md">
                    ₹ {data?.subtotal?.toFixed(2)}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className={
              openAccordion ? "showBody order_card_body" : "order_card_body"
            }
          >
            {/* <div className="padding_10 font_14 color_secondary font_ubuntu font_weight_md">
           
          </div> */}
            <div
              className="display_flex space_btw cursor_pointer"
              style={{ width: "100%" }}
              id="open_order_items"
              onClick={() => openItems(false)}
            >
              <div className="color_secondary  line_height_10 font_12">
                &nbsp;&nbsp; ● &nbsp;
                {data.orderTrack
                  ? data.orderTrack[0].status
                  : data.pastOrderStatus
                  ? data.pastOrderStatus
                  : ""}
                <br />
                <span className="color_grey">
                  &nbsp;&nbsp; &nbsp;
                  {data.orderTrack && data.orderTrack[0].description}
                </span>
              </div>
              <div>
                {openAccordion ? (
                  <FaChevronUp className="color_secondary" />
                ) : (
                  <FaChevronDown className="color_secondary" />
                )}
                &nbsp;&nbsp;
              </div>
            </div>

            {openAccordion && (
              <div className="margin_10 padding_10">
                {data.canEdit && !edit && (
                  <div className="display_flex space_btw">
                    <p></p>
                    <Button
                      style={{ border: "none" }}
                      id="edit_order"
                      className="bg_secondary order_edit_btn"
                      onClick={(e) => {
                        setEdit(true);
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                )}
                {data.canEdit && edit && (
                  <div className="display_flex space_btw">
                    <span></span>
                    <span>
                      {/* <HiOutlineArrowNarrowLeft
                      className="cursor_pointer goBack"
                      onClick={() => setEdit(false)}
                    />{" "}
                    <span className="font_14">Back</span> */}
                      <Button
                        variant="outline-secondary"
                        className="font_12 order_edit_btn"
                        id="edit_order_back"
                        onClick={() => {
                          setEdit(false);
                        }}
                      >
                        Back
                      </Button>{" "}
                    </span>
                    {/* <Button variant="outline-danger" className="order_edit_btn cancel_btn">
                    Cancel Order
                  </Button> */}
                  </div>
                )}

                {data.orderDetailList &&
                  !edit &&
                  data.orderDetailList.map((item) => {
                    return (
                      <div
                        className="order_meds font_ubuntu display_flex space_btw cursor_pointer"
                        onClick={() => navigate(`/product/${item.medicineId}`)}
                        style={{
                          pointerEvents: `${
                            item?.actualPrice === 0.01 ||
                            item?.actualPrice === 0
                              ? "none"
                              : "all"
                          }`,
                        }}
                      >
                        <div>
                          <span className="color_primary font_weight_md">
                            ({item.medsToDeliver}) &nbsp;
                          </span>
                          <span className="color_black">
                            {item.medicineName}
                          </span>
                          {item?.actualPrice !== 0.01 &&
                            item?.actualPrice !== 0 && (
                              <>
                                <br />
                                {item.cashDiscountValue && (
                                  <>
                                    {" "}
                                    <span className="higlight">
                                      {item?.cashDiscountValue}
                                    </span>
                                  </>
                                )}
                                {item.scheme && (
                                  <span className="higlight_blue">
                                    {item.scheme}
                                  </span>
                                )}
                              </>
                            )}
                        </div>

                        {!item?.giftItem ? (
                          <span>₹ {item?.actualPrice?.toFixed(2)}</span>
                        ) : (
                          <span className="gift_panel gift_panel_size_2">
                            <AiFillGift /> <p>Gift Item</p>
                          </span>
                        )}
                      </div>
                    );
                  })}
                {returnScreen && (
                  <div className="font_ubuntu font_weight_md">
                    Select Items to Return
                  </div>
                )}

                {data.orderDetailList &&
                  edit &&
                  !returnScreen &&
                  data.orderDetailList.map((item, i) => {
                    return (
                      <>
                        {!item.isDeleted && (
                          <div className="order_meds_edit font_ubuntu cursor_pointer">
                            <DeleteProductModal
                              show={
                                showDeleteModal.show &&
                                item.medicineId ===
                                  showDeleteModal.item.medicineId
                              }
                              itemName={item.medicineName}
                              removeFromCart={(e) => {
                                e.stopPropagation();
                                removeFromOrder(e, i);
                              }}
                              onHide={(e) => {
                                e.stopPropagation();
                                setShowDeleteModal(false);
                              }}
                            />

                            <div className="display_flex space_btw">
                              <div className="display_flex space_btw">
                                <div>
                                  <span className="color_black">
                                    {item.medicineName}
                                  </span>

                                  {!item?.giftItem && (
                                    <>
                                      {" "}
                                      <br />{" "}
                                      <span className="color_primary font_ubunut font_weight_md">
                                        Total Value :{" "}
                                        {item?.actualPrice?.toFixed(2)}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>

                              <span className="font_12 text_right">
                                <span>
                                  {" "}
                                  Qty: <b>{item.medsToDeliver}</b>
                                </span>{" "}
                                {!item?.giftItem && (
                                  <>
                                    <br />
                                    PTR : <b>₹ {item?.unitPrice?.toFixed(2)}</b>
                                  </>
                                )}
                              </span>
                            </div>

                            {!item?.giftItem ? (
                              <>
                                <br />
                                <div className="display_flex space_btw margin_10">
                                  {
                                    <img
                                      src={Delete}
                                      alt="Delete"
                                      id="edit_order_delete_btn"
                                      className="delete_product_svg"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setShowDeleteModal({
                                          show: true,
                                          item: item,
                                        });
                                      }}
                                      style={{
                                        filter:
                                          "invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%)",
                                      }}
                                    />
                                  }
                                  <div>
                                    <div className="display_flex space_btw">
                                      <Fragment>
                                        <div
                                          className="minusQty color_primary"
                                          id="edit_order_minus_qty"
                                          onClick={(e) =>
                                            minusQuantity(e, item, i)
                                          }
                                        >
                                          <AiOutlineMinus />
                                        </div>
                                        <input
                                          className="qty_input"
                                          id="edit_order_qty_edit"
                                          type="number"
                                          value={
                                            item.hasOwnProperty("enteredQty")
                                              ? item.enteredQty
                                              : item.medsToDeliver
                                          }
                                          onClick={(e) => e.stopPropagation()}
                                          onBlur={(e) =>
                                            onFocusOutLive(e, item, i)
                                          }
                                          onChange={(e) => {
                                            e.stopPropagation();
                                            setQty(e, i);

                                            editCartOpt(e, item, i);
                                          }}
                                        />
                                        <div
                                          className="addQty bg_primary color_white"
                                          id="edit_order_plus_qty"
                                          onClick={(e) =>
                                            addQuantity(e, item, i)
                                          }
                                        >
                                          <AiOutlinePlus />
                                        </div>
                                      </Fragment>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="gift_panel gift_panel_size_1">
                                <AiFillGift />
                                <p>Gift Item</p>
                              </div>
                            )}

                            <div style={{ textAlign: "right" }}>
                              {item.maxQtyError && (
                                <div className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                                  {" "}
                                  Maximum Order Quantity is{" "}
                                  {item.maxOrderQuantity}{" "}
                                </div>
                              )}
                              {item.minQtyError && (
                                <div className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                                  {" "}
                                  Minimum Order Quantity is{" "}
                                  {item.minOrderQuantity}{" "}
                                </div>
                              )}
                              {item.multipleQtyError && (
                                <div className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                                  {" "}
                                  Quantity Should be multiple of $
                                  {item.multiplyFactorForOrderQuantity};
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}

                {returnInvoice &&
                  edit &&
                  returnScreen &&
                  returnInvoice.buyerInvoiceDetailList.map((item, i) => {
                    return (
                      <>
                        {
                          <>
                            {!returnSubmit ? (
                              <div
                                className={
                                  returnInvoice.returnError
                                    ? "errorAnime order_meds_edit font_ubuntu cursor_pointer"
                                    : "order_meds_edit font_ubuntu cursor_pointer"
                                }
                                onClick={(e) => selectReturnItem(e, item, i)}
                                key={i}
                              >
                                <div className="display_flex space_btw">
                                  <div className="display_flex space_btw">
                                    {returnScreen && (
                                      <Form.Check
                                        checked={item.returnSelected}
                                        onChange={(e) =>
                                          selectReturnItem(e, item, i)
                                        }
                                        className="return_item_select cursor_pointer mb-3"
                                      />
                                    )}
                                    <div>
                                      <span className="color_black">
                                        {item.itemName}
                                      </span>

                                      <>
                                        {" "}
                                        <br />{" "}
                                        <span className="color_primary font_ubunut font_weight_md">
                                          Total Savings : ₹{" "}
                                          {item?.totalDiscountAmount?.toFixed(
                                            2
                                          )}
                                        </span>
                                      </>
                                    </div>
                                  </div>

                                  <span className="font_12 text_right">
                                    <span>
                                      {" "}
                                      Qty: <b>{item.quantity}</b>
                                    </span>{" "}
                                    <br />
                                    PTR : <b>₹ {item?.ptr?.toFixed(2)}</b>
                                  </span>
                                </div>
                                <br></br>
                                <div className="display_flex space_btw margin_10">
                                  <>
                                    {/* <Form.Select  size="sm" aria-label="Default select example" className="return_reason_select cursor_pointer" onClick={(e)=>e.stopPropagation()}>
                                    <option>Select Reason</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </Form.Select> */}
                                    {item.returnSelected ? (
                                      <>
                                        <DropdownButton
                                          variant="outline-secondary"
                                          title={
                                            item.returnReason
                                              ? item.returnReason
                                              : "Select Reason"
                                          }
                                          id="input-group-dropdown-1"
                                          className={
                                            item.returnReasonError &&
                                            "errorAnime reason_highlight"
                                          }
                                          onClick={(e) => {
                                            Common_Event_Hit("DYNAMIC_EVENT", {
                                              eventName: "select_reason_tap",
                                              orderId: data.orderId,
                                            });
                                            e.stopPropagation();
                                          }}
                                        >
                                          {returnReasons &&
                                            returnReasons.map((reason) => {
                                              return (
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    reasonSelect(
                                                      reason,
                                                      item,
                                                      i
                                                    )
                                                  }
                                                >
                                                  {reason}
                                                </Dropdown.Item>
                                              );
                                            })}
                                        </DropdownButton>
                                      </>
                                    ) : (
                                      <div></div>
                                    )}
                                  </>
                                  <div>
                                    <div className="display_flex space_btw">
                                      <Fragment>
                                        <div
                                          className="minusQty color_primary"
                                          onClick={(e) =>
                                            minusReturnQuantity(e, item, i)
                                          }
                                        >
                                          <AiOutlineMinus />
                                        </div>
                                        <input
                                          className="qty_input"
                                          type="number"
                                          style={{ textAlign: "center" }}
                                          value={
                                            item.hasOwnProperty(
                                              "updatedQuantity"
                                            )
                                              ? item.updatedQuantity
                                              : item.quantity
                                          }
                                          onClick={(e) => e.stopPropagation()}
                                          onBlur={(e) =>
                                            onFocusOutReturn(e, item, i)
                                          }
                                          onChange={(e) => {
                                            e.stopPropagation();
                                            editReturnQty(e, item, i);
                                          }}
                                        />
                                        <div
                                          className="addQty bg_primary color_white"
                                          onClick={(e) =>
                                            addReturnQuantity(e, item, i)
                                          }
                                        >
                                          <AiOutlinePlus />
                                        </div>
                                      </Fragment>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ textAlign: "right" }}>
                                  {item.maxQtyError && (
                                    <div className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                                      {" "}
                                      Maximum Order Quantity is{" "}
                                      {item.maxOrderQuantity}{" "}
                                    </div>
                                  )}
                                  {item.minQtyError && (
                                    <div className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                                      {" "}
                                      Minimum Order Quantity is{" "}
                                      {item.minOrderQuantity}{" "}
                                    </div>
                                  )}
                                  {item.multipleQtyError && (
                                    <div className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                                      {" "}
                                      Quantity Should be multiple of $
                                      {item.multiplyFactorForOrderQuantity};
                                    </div>
                                  )}
                                </div>
                                {returnScreen && item.returnReasonError && (
                                  <div className="max_qty_error color_warn font_ubuntu font_weight_md">
                                    {" "}
                                    Please select reason to return !
                                  </div>
                                )}
                              </div>
                            ) : item.returnSelected ? (
                              <div
                                className={
                                  returnInvoice.returnError
                                    ? "errorAnime order_meds_edit font_ubuntu cursor_pointer"
                                    : "order_meds_edit font_ubuntu cursor_pointer"
                                }
                                onClick={(e) => selectReturnItem(e, item, i)}
                                key={i}
                              >
                                <div className="display_flex space_btw">
                                  <div className="display_flex space_btw">
                                    {returnScreen && (
                                      <Form.Check
                                        checked={item.returnSelected}
                                        onChange={(e) =>
                                          selectReturnItem(e, item, i)
                                        }
                                        className="return_item_select cursor_pointer mb-3"
                                      />
                                    )}
                                    <div>
                                      <span className="color_black">
                                        {item.itemName}
                                      </span>

                                      <>
                                        {" "}
                                        <br />{" "}
                                        <span className="color_primary font_ubunut font_weight_md">
                                          Total Savings : ₹{" "}
                                          {item?.totalDiscountAmount?.toFixed(
                                            2
                                          )}
                                        </span>
                                      </>
                                    </div>
                                  </div>

                                  <span className="font_12 text_right">
                                    <span>
                                      {" "}
                                      Qty: <b>{item.quantity}</b>
                                    </span>{" "}
                                    <br />
                                    PTR : <b>₹&nbsp;{item?.ptr?.toFixed(2)}</b>
                                  </span>
                                </div>
                                <br></br>
                                <div className="display_flex space_btw margin_10">
                                  <>
                                    {/* <Form.Select  size="sm" aria-label="Default select example" className="return_reason_select cursor_pointer" onClick={(e)=>e.stopPropagation()}>
                                    <option>Select Reason</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </Form.Select> */}
                                    {item.returnSelected ? (
                                      <>
                                        <DropdownButton
                                          variant="outline-secondary"
                                          title={
                                            item.returnReason
                                              ? item.returnReason
                                              : "Select Reason"
                                          }
                                          id="input-group-dropdown-1"
                                          className={
                                            item.returnReasonError &&
                                            "errorAnime reason_highlight"
                                          }
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          {returnReasons &&
                                            returnReasons.map((reason) => {
                                              return (
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    reasonSelect(
                                                      reason,
                                                      item,
                                                      i
                                                    )
                                                  }
                                                >
                                                  {reason}
                                                </Dropdown.Item>
                                              );
                                            })}
                                        </DropdownButton>
                                      </>
                                    ) : (
                                      <div></div>
                                    )}
                                  </>
                                  <div>
                                    <div className="display_flex space_btw">
                                      <div className="font_ubuntu font_weight_md">
                                        Return Quantity :{" "}
                                        {item.updatedQuantity
                                          ? item.updatedQuantity
                                          : item.quantity}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ textAlign: "right" }}>
                                  {item.maxQtyError && (
                                    <div className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                                      {" "}
                                      Maximum Order Quantity is{" "}
                                      {item.maxOrderQuantity}{" "}
                                    </div>
                                  )}
                                  {item.minQtyError && (
                                    <div className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                                      {" "}
                                      Minimum Order Quantity is{" "}
                                      {item.minOrderQuantity}{" "}
                                    </div>
                                  )}
                                  {item.multipleQtyError && (
                                    <div className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                                      {" "}
                                      Quantity Should be multiple of $
                                      {item.multiplyFactorForOrderQuantity};
                                    </div>
                                  )}
                                </div>
                                {returnScreen && item.returnReasonError && (
                                  <div className="max_qty_error color_warn font_ubuntu font_weight_md">
                                    {" "}
                                    Please select reason to return !
                                  </div>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        }
                      </>
                    );
                  })}

                {data.orderDetailList && edit && !returnScreen && (
                  <div className="display_flex space_around save_section">
                    <Button
                      disabled={cancelOrderSpinner}
                      id="cancel_order"
                      variant="outline-danger"
                      onClick={cancelOrder}
                      className="cancel_btn_order"
                    >
                      {cancelOrderSpinner ? (
                        <Spinner
                          animation="border"
                          className="color_secondary spinner"
                          size="sm"
                        />
                      ) : (
                        "Cancel Order"
                      )}
                    </Button>
                    <Button
                      id="save_order"
                      disabled={saveOrderSpinner}
                      variant="outline-primary"
                      className="save_btn font_ubuntu"
                      onClick={saveOrder}
                    >
                      {saveOrderSpinner ? (
                        <Spinner
                          animation="border"
                          className="color_secondary spinner"
                          size="sm"
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                )}

                {data.amountMap &&
                  !returnScreen &&
                  data.amountMap.map((item) => {
                    return (
                      <div className="display_flex space_btw font_ubuntu font_12 amount_map_sec">
                        <span className="color_grey">{item.key}</span>
                        <span className="color_black font_weight_md">
                          ₹ {item?.value?.toFixed(2)}
                        </span>
                      </div>
                    );
                  })}

                {!returnScreen && (
                  <div className="order_card_total_sec display_flex space_btw">
                    <span className="color_grey font_weight_md">
                      Sub Total :
                    </span>
                    <span className="color_black font_weight_md">
                      {" "}
                      ₹ {data?.subtotal?.toFixed(2)}
                    </span>
                  </div>
                )}
                {returnScreen && returnInvoice.returnError && (
                  <div className="max_qty_error color_warn font_ubuntu font_weight_md">
                    {" "}
                    Please select items to return !
                  </div>
                )}

                {returnScreen && !returnSubmit && (
                  <div className="order_card_total_sec display_flex space_around">
                    <Button
                      className="bg_secondary brdr_none continue_btn"
                      onClick={returnContinue}
                      disabled={returnRefundData.loader}
                    >
                      {returnRefundData.loader ? (
                        <Spinner
                          animation="border"
                          className="color_primary"
                          size="sm"
                          style={{ margin: "0px auto" }}
                        />
                      ) : (
                        "Continue"
                      )}
                    </Button>
                  </div>
                )}
                {returnScreen && returnSubmit && (
                  <div className="order_card_total_sec display_flex">
                    <div
                      className="color_grey font_ubuntu font_weight_md padding_10"
                      style={{ width: "50%" }}
                    >
                      Total Refund Amount :{" "}
                      <span className="color_black">
                        ₹{" "}
                        {returnRefundData.data &&
                          returnRefundData.data?.totalRefundAmount?.toFixed(2)}
                      </span>
                    </div>
                    <Button
                      className="color_white font_weight_md bg_secondary brdr_none"
                      style={{ width: "50%" }}
                      onClick={returnOrder}
                    >
                      {returnRefundData.loader ? (
                        <Spinner
                          animation="border"
                          className="color_primary"
                          size="sm"
                          style={{ margin: "0px auto" }}
                        />
                      ) : (
                        "RETURN NOW"
                      )}
                    </Button>
                  </div>
                )}

                <div className="display_flex space_btw font_ubuntu padding_10">
                  <span className="color_warn font_weight_md font_12">
                    Issues with Order?{" "}
                  </span>
                  {/* <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={
                      <Popover id="popover-basic">
                        <Popover.Header as="h3">
                          <a
                            href="tel:+918035018025"
                            className="text_decoration_none color_primary"
                          >
                            <IoMdCall className="color_secondary" />{" "}
                            &nbsp;&nbsp; 8035018025
                          </a>
                        </Popover.Header>
                        <Popover.Body>
                          <a
                            href="mailTo:support@saveo.in"
                            className="text_decoration_none color_primary"
                          >
                            <IoIosMail className="color_secondary" />{" "}
                            &nbsp;&nbsp; support@saveo.in
                          </a>
                        </Popover.Body>
                      </Popover>
                    }
                  > */}
                  {/* replace the order Type below to update the navigate */}
                  <Button
                    variant="outline-primary"
                    className="font_12"
                    onClick={() =>
                      navigate(
                        `/help-support/ReportIssue/OrdersWithIssue/${
                          filter === "live" ? "LIVE_ORDERS" : "PAST_ORDERS"
                        }/raiseIssue/liveOrderIssue/${data?.orderId}`,
                        { replace: true }
                      )
                    }
                  >
                    {" "}
                    Raise an issue
                  </Button>
                  {/* </OverlayTrigger> */}
                  {/* <Button variant="outline-primary" className="font_12">
                    Raise an issue
                  </Button> */}
                </div>
                <div className="font_ubuntu font_12 color_grey padding_10 font_weight_md">
                  NOTE: Invoice value may differ what is shown above due to
                  different batches and schemes.&#x2a;
                </div>
              </div>
            )}

            {/* <Accordion className="order_card_accordian" defaultActiveKey="0">
              <Accordion.Item eventKey={openAccordion}>
                <Accordion.Header> </Accordion.Header>
                <Accordion.Body>
                 
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
          </div>
        </div>
      )}
    </>
  );
};

export default OrderCard;
