import { Modal, Button, NavItem } from "react-bootstrap";
import React, { Fragment ,useState,useEffect} from "react";

import "../../App.css";
import "./dialog.css";
import Maint from "../../Images/png/maintenance.jpg";
import { useNavigate, useLocation } from "react-router";
import { variables } from "../../environments";
import {IoIosCopy} from "react-icons/io"

const MaintenanceModal = (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  const [onBehalfStatus, setOnBehalfStatus] = useState(false);
  
  const copyData=()=>{
    navigator.clipboard.writeText(JSON.stringify({clientMessage:props.content,error:props?.apiData?.request?.responseText,url:props?.apiData?.config?.url,params:props?.apiData?.config?.params,screen:localStorage.getItem('currentScreen'),statusCode:props?.apiData?.status,retailerCode:onBehalfStatus?localStorage.getItem('onBehalfRetCode'):JSON.parse(localStorage.getItem('userData'))?.retailerCode,version:variables.version}));
  }

  useEffect(() => {
    let onBehalfCode = localStorage.getItem("onBehalfRetCode");
    if (onBehalfCode?.length) {
      setOnBehalfStatus(true);
    }
  }, []);
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="del_modal"
    >
      <Modal.Header className="bg_primary maintance-modal-header">
        <Modal.Title id="contained-modal-title-vcenter" className="">
          <img
            src={Maint}
            className="delete_modal_svg"
            style={{ marginLeft: "10%" }}
          />
          <p
            className="font_ubuntu color_white font_weight_md font_18"
            style={{ marginLeft: "10%" }}
          >
            Website under Maintenance!
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer className="bg_primary modal_footer" style={{border:'none'}}>
      <div className="display_flex   bg_primary" style={{width:'100%',justifyContent:'center'}}>
          <Button
            onClick={() => {
              // navigate(
              //   `${location.pathname}`,
              //   console.log(location.pathname)
              // );
              window.location.reload();
            }}
            className="bg_white brdr_grey try_again color_primary font_weight_md"
            style={{width: "150px" }}
          >
            Try&nbsp;Again
          </Button>
        </div>
        <div className="display_flex space_btw admin_data" style={{width:'100%'}}>
            <p className="retCode" style={{color:'aliceblue'}}>{onBehalfStatus?localStorage.getItem('onBehalfRetCode'):JSON.parse(localStorage.getItem('userData'))?.retailerCode} </p>
            <p className="version" style={{color:'aliceblue'}}>v{variables.version}  {onBehalfStatus && <span onClick={copyData}>&nbsp;&nbsp; <IoIosCopy className="copy_icon_maint"/></span>}</p>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default MaintenanceModal;
