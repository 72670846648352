import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container, Carousel, Spinner } from "react-bootstrap";
import ProductCard2 from "../ProductCard/ProductCard2";
import "../../App.css";
import "../ProductList/ProductList.css";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import "./categories.css";
import DOD from "../../Images/png/dod.png";
import FlashSale from "../../Images/png/flash_sale.png";
import BulkRate from "../../Images/png/bulkRate.png";
import { FaChevronRight } from "react-icons/fa";
import Slider from "../Sliders/Slider";
import { getScreen } from "../../Actions/Screens";
import { refreshToken } from "../../Actions/Auth";
import { common_product_widget } from "../../Actions/Widgets";
import InfiniteScroll from "react-infinite-scroll-component";
import { getCatgoriesData } from "../../Actions/Categories";
import { BannerData, usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import { Common_Event_Hit } from "../../Actions/Events";
import BannerSlider from "../BannerRedirects/BannerSlider";

const SpecialOffersHomePage = ({}) => {
  let navigate = useNavigate();
  const [widgetsData, setWidgetsData] = useState();
  const [offersWidget, setOffersWidget] = useState();
  const [bannerWidget, setBannerWidget] = useState();
  const [productList, setProductList] = useState();
  const [allProductList, setAllProductList] = useState();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [dataLength, setDataLength] = useState(0);
  const [skuData, setSkuData] = useState();
  const [screenShimmer, setScreenShimmer] = useState(false);
  const [productListShimmer, setProductListShimmer] = useState(false);
  const [bannerWidgetShimmer, setBannerWidgetShimmer] = useState(false);
  const [allProductShimmer, setAllProductShimmer] = useState(false);
  const { setBannerData } = useContext(BannerData);
  const { setShowMaintenance } = useContext(usersData);
  const [bannerImages, setBannerImages] = useState([]);

  const [errorModal, setErrorModal] = useState(false);
  const fetchMoreData = () => {
    setPage(+page + 1);
  };

  const getAllProducts = () => {
    const params = {
      category: "SPECIAL_OFFERS",
      filterCondition: "all",
      page: page,
    };
    page === 0 && setAllProductShimmer(true);
    let api = common_product_widget("home/v2/getMedicineByCategory", params);
    api
      .then((res) => {
        let obj =
          allProductList && allProductList.medicinesResponsesList
            ? allProductList
            : { medicinesResponsesList: [] };
        if (res.data.data.medicinesResponsesList.length > 0) {
          obj.medicinesResponsesList.push(
            ...res.data.data.medicinesResponsesList
          );
          setDataLength(obj.medicinesResponsesList.length);
        }

        setAllProductList(obj);

        if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
          fetchMoreData();
        } else if (
          res.data.data.size > 0 &&
          res.data.data.medicinesResponsesList.length === 0
        ) {
          fetchMoreData();
        }

        if (res.data.data.size === 0) {
          setHasMore(false);
        }

        setAllProductShimmer(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getAllProducts();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getAllProducts();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getAllProducts();
            },
          });
        }
      });
  };

  const getSkuCount = () => {
    getCatgoriesData("SPECIAL_OFFERS_SKU_COUNT", {})
      .then((res) => {
        setSkuData(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getSkuCount();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getSkuCount();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getSkuCount();
            },
          });
        }
      });
  };
  const homeBannerSlide = (event) => {
    localStorage.setItem(
      "currentScreenBannerData",
      JSON.stringify({ index: +event + 1, data: bannerWidget[event] })
    );
  };
  useEffect(() => {
    let userData = localStorage.getItem("userData");
    localStorage.setItem("currentScreen", "special_offer_screen");
    localStorage.removeItem("currentScreenBannerData");

    if (userData) {
      setScreenShimmer(true);
      const params = {
        retailerId: JSON.parse(userData).id,
        screenName: "special_offer_screen",
      };

      let cat_screen = () => {
        getScreen("HOME_SCREEN", params, {})
          .then((res) => {
            setWidgetsData(res.data.data);
            setScreenShimmer(false);
            res.data.data.forEach((item) => {
              let getWidgetData = () => {
                let params;
                if (item.widgetName === "offer_category_widget") {
                  params = {};
                } else if (item.widgetName === "banner_widget") {
                  params = {
                    pageId: "specialOffers",
                  };
                  setBannerWidgetShimmer(true);
                } else if (item.widgetName === "product_list_widget") {
                  params = {
                    category: "SPECIAL_OFFERS",
                    filterCondition: "popular",
                    page: 0,
                  };
                  setProductListShimmer(true);
                }

                if (
                  item &&
                  item.widgetName !== "vertical_product_list_widget"
                ) {
                  let api = common_product_widget(
                    item.data.apiEndPoint,
                    params
                  );
                  api
                    .then((res) => {
                      if (item.widgetName === "offer_category_widget") {
                        setOffersWidget(res.data.data);
                      } else if (item.widgetName === "banner_widget") {
                        setBannerWidget(res.data.data);
                        setBannerWidgetShimmer(false);
                        localStorage.setItem(
                          "currentScreenBannerData",
                          JSON.stringify({ index: 1, data: res.data.data[0] })
                        );
                        let arr = [];
                        res.data.data.forEach((ele, i) => {
                          arr.push(
                            <img
                              onClick={async () => {
                                Common_Event_Hit("DYNAMIC_EVENT", {
                                  eventName: "banner_tapped",
                                  screen_name:
                                    localStorage.getItem("currentScreen"),
                                });
                                setBannerData(ele);
                                navigate("/bannerRedirect");
                              }}
                              className="banner_img"
                              src={ele.url}
                              alt={i}
                            />
                          );
                        });
                        setBannerImages(arr);
                      } else if (item.widgetName === "product_list_widget") {
                        setProductList(res.data.data);
                        setProductListShimmer(false);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      if (error?.response?.status === 401) {
                        refreshToken().then((res) => {
                          getWidgetData();
                        });
                      } else if (
                        error?.response?.status === 502 ||
                        error?.response?.status === 503
                      ) {
                        setShowMaintenance({
                          status: true,
                          apiData: error?.response,
                        });
                      } else if (error?.response?.status === 500) {
                        setErrorModal({
                          show: true,
                          apiData: error?.response,
                          content: "Oops something went wrong !",
                          tryAgain: function () {
                            getWidgetData();
                          },
                        });
                      } else {
                        setErrorModal({
                          show: true,
                          apiData: error?.response,
                          content: error?.response?.data?.error?.error
                            ? error.response.data.error.error
                            : "Something went wrong!",
                          tryAgain: function () {
                            getWidgetData();
                          },
                        });
                      }
                    });
                }
              };

              return getWidgetData();
            });
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshToken()
                .then((res) => {
                  cat_screen();
                })
                .catch((error) => {
                  localStorage.removeItem("userData");
                  localStorage.removeItem("userAuth");
                  navigate("/login");
                });
            } else if (
              error?.response?.status === 502 ||
              error?.response?.status === 503 ||
              error?.response?.status === 504
            ) {
              setShowMaintenance({ status: true, apiData: error?.response });
            } else if (error?.response?.status === 500) {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: "Oops something went wrong !",
                tryAgain: function () {
                  cat_screen();
                },
              });
            } else {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: error?.response?.data?.error?.error
                  ? error.response.data.error.error
                  : "Something went wrong!",
                tryAgain: function () {
                  cat_screen();
                },
              });
            }
          });
      };
      cat_screen();
    } else {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    widgetsData && getSkuCount();
  }, [widgetsData]);
  useEffect(() => {
    widgetsData && getAllProducts();
  }, [page, widgetsData]);

  return (
    <Fragment>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      {screenShimmer ? (
        <div className="productList">
          {[...Array(5)].map((_, index) => {
            return (
              <div className="skeletal_div animateShimmer" key={index}></div>
            );
          })}
        </div>
      ) : (
        <Container fluid className="productList">
          <Container
            fluid
            className="productListHeader font_ubuntu display_flex"
          >
            {" "}
            <HiOutlineArrowNarrowLeft
              className="cursor_pointer goBack"
              onClick={() => navigate("/home")}
            />{" "}
            &nbsp;&nbsp;&nbsp;{" "}
            <div className="head_specialOffers">
              Special Offers
              {skuData && (
                <span className="sub_head">
                  {skuData.companyCount} Companies • {skuData.skuCount} SKU'S
                </span>
              )}
            </div>{" "}
          </Container>
          {/* <div style={{textAlign:'start'}}>
      <p className='color_grey font_14 font_weight_md font_ubuntu'>{widgetsData && widgetsData[0].data.title}</p>
      </div> */}
          {widgetsData &&
            widgetsData.map((widget) => {
              if (widget.widgetName === "offer_category_widget") {
                return (
                  <Container
                    fluid
                    className="display_flex space_btw special_offers_tiles_parent"
                  >
                    {offersWidget &&
                      offersWidget.map((item) => {
                        if (
                          item.sectionName === "Deal of the day" &&
                          item.active
                        ) {
                          return (
                            <div className="specialOffer_tile">
                              <div
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "offer_tap",
                                    sectionId: item.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate(
                                    "/categories/specialOffers/DealOfTheDay"
                                  );
                                }}
                                className="text_decoration_none"
                              >
                                <div className="tile_image_div">
                                  <img className="tile_image" src={DOD} />
                                </div>
                                <div className="display_flex space_btw">
                                  <div className="font_ubuntu tile_header color_white">
                                    {item.sectionName}
                                  </div>
                                  <p className="specialOffer_arrow">
                                    <FaChevronRight className="color_white" />
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (
                          item.sectionName === "Flash Sale" &&
                          item.active
                        ) {
                          return (
                            <div
                              className="specialOffer_tile"
                              style={{ background: "#706B00" }}
                            >
                              <div
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "offer_tap",
                                    sectionId: item.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate(
                                    "/categories/specialOffers/FlashSale"
                                  );
                                }}
                                className="text_decoration_none"
                              >
                                <div className="tile_image_div">
                                  <img className="tile_image" src={FlashSale} />
                                </div>
                                <div className="display_flex space_btw">
                                  <div className="font_ubuntu tile_header color_white">
                                    {item.sectionName}
                                  </div>
                                  <p className="specialOffer_arrow">
                                    <FaChevronRight className="color_white" />
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (
                          item.sectionName === "Special Rate" &&
                          item.active
                        ) {
                          return (
                            <div
                              className="specialOffer_tile"
                              style={{ background: "green" }}
                            >
                              <div
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "offer_tap",
                                    sectionId: item.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate(
                                    "/categories/specialOffers/SpecialRate"
                                  );
                                }}
                                className="text_decoration_none"
                              >
                                <div className="tile_image_div">
                                  <div className="tile_image">
                                    <span
                                      style={{
                                        background: "white",
                                        color: "black",
                                        padding: "1px 2px",
                                        borderRadius: "2px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      5 + 1
                                    </span>
                                  </div>
                                </div>
                                <div className="display_flex space_btw">
                                  <div className="font_ubuntu tile_header color_white">
                                    {item.sectionName}
                                  </div>
                                  <p className="specialOffer_arrow">
                                    <FaChevronRight className="color_white" />
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (
                          item.sectionName === "Bulk Rate" &&
                          item.active
                        ) {
                          return (
                            <div
                              className="specialOffer_tile"
                              style={{ background: "#1CBDB7" }}
                            >
                              <div
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "offer_tap",
                                    sectionId: item.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate(
                                    "/categories/specialOffers/BulkByProducts"
                                  );
                                }}
                                className="text_decoration_none"
                              >
                                <div className="tile_image_div">
                                  <img className="tile_image" src={BulkRate} />
                                </div>
                                <div className="display_flex space_btw">
                                  <div className="font_ubuntu tile_header color_white">
                                    {item.sectionName}
                                  </div>
                                  <p className="specialOffer_arrow">
                                    <FaChevronRight className="color_white" />
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </Container>
                );
              } else if (widget.widgetName === "banner_widget") {
                return (
                  <>
                    {bannerWidgetShimmer ? (
                      <Container>
                        <div className="skeletal_div animateShimmer"></div>
                      </Container>
                    ) : (
                      bannerWidget &&
                      bannerWidget.length > 0 && (
                        <>
                          <Container fluid className="web_carousel">
                            <div className="banner_section">
                              <BannerSlider
                                slides={bannerImages}
                                slideChange={homeBannerSlide}
                              />
                            </div>
                          </Container>

                          <Carousel
                            className="banner_section mobile_carousel"
                            onSlide={homeBannerSlide}
                          >
                            {bannerWidget.map((ele, ind) => (
                              <Carousel.Item key={ind}>
                                <div
                                  onClick={async () => {
                                    Common_Event_Hit("DYNAMIC_EVENT", {
                                      eventName: "banner_tapped",
                                      screen_name:
                                        localStorage.getItem("currentScreen"),
                                    });
                                    setBannerData(ele);
                                    navigate("/bannerRedirect");
                                  }}
                                >
                                  <img
                                    className="d-block banner_img"
                                    src={ele.url}
                                    alt={ele.fileName}
                                  />
                                </div>
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        </>
                      )
                    )}
                  </>
                );
              } else if (widget.widgetName === "product_list_widget") {
                return (
                  <>
                    {productListShimmer ? (
                      <Container>
                        <br></br>
                        <p
                          className=" font_18 font_weight_md font_ubuntu"
                          style={{ color: widget.data.titleColor }}
                        >
                          {widget.data.title}
                        </p>
                        <Slider
                          screen={"special_offer_screen"}
                          shimmer={productListShimmer}
                          footerColor={"#F4F6F8"}
                          id={"trendingProducts"}
                          darkColor={"#4B4495"}
                          lightColor={"#a49cf598"}
                        />
                      </Container>
                    ) : (
                      productList?.length && (
                        <Container>
                          <br></br>
                          <p
                            className=" font_18 font_weight_md font_ubuntu"
                            style={{ color: widget.data.titleColor }}
                          >
                            {widget.data.title}
                          </p>
                          <Slider
                            screen={"special_offer_screen"}
                            widgetData={widget}
                            ProductRes={productList}
                            footerColor={"#F4F6F8"}
                            id={"trendingProducts"}
                            darkColor={"#4B4495"}
                            lightColor={"#a49cf598"}
                          />
                        </Container>
                      )
                    )}
                  </>
                );
              } else if (widget.widgetName === "vertical_product_list_widget") {
                return (
                  <>
                    {allProductShimmer ? (
                      <Fragment>
                        <Container>
                          <br></br>

                          <p className="font_18 font_weight_md font_ubuntu">
                            {" "}
                            All Products
                          </p>
                        </Container>
                        <div id="scrollableDiv" className="productList">
                          <ProductCard2 shimmer={allProductShimmer} />{" "}
                          <ProductCard2 shimmer={allProductShimmer} />{" "}
                          <ProductCard2 shimmer={allProductShimmer} />{" "}
                          <ProductCard2 shimmer={allProductShimmer} />
                        </div>
                      </Fragment>
                    ) : (
                      allProductList && (
                        <Fragment>
                          <Container>
                            <br></br>

                            <p
                              className="font_18 font_weight_md font_ubuntu"
                              style={{ color: widget.data.titleColor }}
                            >
                              {widget.data.title}
                            </p>
                          </Container>
                          <div id="scrollableDiv" className="productList" style={{paddingTop: '0rem'}}>
                            <InfiniteScroll
                              dataLength={dataLength}
                              next={fetchMoreData}
                              hasMore={hasMore}
                              scrollableTarget="scrollableDiv"
                              scrollThreshold="90%"
                              className="infiniteScroll"
                              loader={
                                <Spinner
                                  animation="border"
                                  className="color_secondary spinner"
                                  size="lg"
                                />
                              }
                            >
                              {allProductList &&
                                allProductList.medicinesResponsesList.map(
                                  (med, index) => (
                                    <ProductCard2
                                      position={index}
                                      screen={"special_offer_screen"}
                                      key={index}
                                      medData={med}
                                    />
                                  )
                                )}
                            </InfiniteScroll>
                          </div>
                        </Fragment>
                      )
                    )}
                  </>
                );
              }
            })}
        </Container>
      )}
    </Fragment>
  );
};

export default React.memo(SpecialOffersHomePage);
