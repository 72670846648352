import axios from "axios";
import { variables } from "../environments";

let config = {
  headers: {
    "Content-Type": "application/json",
    "source":"pharmacyWeb"
  },
};
const ADMIN_URL = variables.ADMIN_URL;

export const SignUp = async (params) => {
  return axios({
    method: "post",
    url: ADMIN_URL + "/authenticate/verifyPassword",
    params: params,
  });
};

export const getOTP = async (params) => {
  return axios({
    method: "post",
    url: ADMIN_URL + "/authenticate/sendOtp",
    params: params,
  });
};

export const verifyOTP = async (params) => {
  return axios({
    method: "post",
    url: ADMIN_URL + "/authenticate/verifyOtp",
    params: params,
  });
};

export const checkAuth = async (body) => {
  let token;
  let authData = localStorage.getItem("userAuth");
  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.post(`${ADMIN_URL}/auth/checkAuth`, body, config);
};

export const refreshToken = async () => {
  let token;
  let refreshToken;
  let authData = localStorage.getItem("userAuth");
  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
    refreshToken = JSON.parse(authData).data.refreshToken;
  }
  config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  const params = {
    refreshToken: refreshToken,
  };
  return axios
    .post(
      `${ADMIN_URL}/authenticate/refreshToken`,
      {},
      {
        params: params,
        headers: config.headers,
      }
    )
    .then((response) => {
      // let auth = JSON.parse(localStorage.getItem("authToken"))
      // auth.jwtToken = refreshAuthData.jwtToken;
      // localStorage.setItem("authToken", JSON.stringify(auth));
      let auth = JSON.parse(authData);
      auth.data.jwtToken = response.data.data.jwtToken;
      localStorage.setItem("userAuth", JSON.stringify(auth));
    });
};
