import React, { useCallback, useContext, useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router";
import { useNavigate, useParams } from "react-router";
import { refreshToken } from "../../../Actions/Auth";
import { getWSSearchMedicines } from "../../../Actions/WholeSale/Search";
import { usersData } from "../../../App";
import ErrorModal from "../../Modals/ErrorModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import ButtonLoading1 from "../../Spinners/ButtonLoading1";
import ButtonLoading2 from "../../Spinners/ButtonLoading2";
import WholeSaleProductCard from "../WholeSaleProductCard/WholeSaleProductCard";
import "./WSSearch.css";

function WSSearch() {
  const [page, setPage] = useState(0);
  const [shimmer, setShimmer] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const [productsData, setProductsData] = useState([]);
  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState(null);
  const location = useLocation();

  //api to fetch the search products
  const getSearchProducts = async (keyup, string, pageNo) => {
    // build the payload
    let params = {
      searchString: string,
      page: pageNo,
    };
    if (keyup) {
      setShimmer(true);
      setPage(0);
      params.page = 0;
      setProductsData([]);
    }

    let body = {};
    try {
      const res = await getWSSearchMedicines(params, body);

      //update the products state
      if (res?.data?.data?.medicineResponseList && page > 0 && !keyup) {
        setProductsData([
          ...productsData,
          ...res?.data?.data?.medicineResponseList,
        ]);
      } else if (res?.data?.data?.medicineResponseList && page === 0 && keyup) {
        setProductsData(res?.data?.data?.medicineResponseList);
      }

      if (
        productsData.length + res?.data?.data?.medicineResponseList?.length <
        5
      )
        fetchMoreData();

      if (res?.data?.data?.size == 0) setHasMore(false);

      //update the shimmer state only for page 0
      setShimmer(false);
    } catch (error) {
      if (error?.response?.status == 401) {
        refreshToken().then((res) => {
          getSearchProducts(keyup, string, pageNo);
        });
      } else if (
        error?.response?.status == 502 ||
        error?.response?.status == 503 ||
        error?.response?.status == 504
      ) {
        setShowMaintenance({ status: true, apiData: error?.response });
      } else if (error?.response?.status == 500) {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: "Oops something went wrong !",
          tryAgain: function () {
            getSearchProducts(keyup, string, pageNo);
          },
        });
      } else {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getSearchProducts(keyup, string, pageNo);
          },
        });
      }
    }
  };

  const fetchMoreData = () => {
    setPage((prevVal) => +prevVal + 1);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };

  const search = useCallback(debounce(getSearchProducts), []);

  //side effect on search query update from the location
  useEffect(() => {
    let locationBlocks = location.pathname.split("/");
    let string = locationBlocks[4].split("%20").join(" ");
    if (string.length > 0) {
      setShimmer(true);
      setProductsData([]);
      setSearchQuery(string);
      setHasMore(true);
      search(true, string, page);
    } else {
      setSearchQuery(null);
    }
  }, [location]);

  useEffect(() => {
    let locationBlocks = location.pathname.split("/");
    let string = locationBlocks[4].split("%20").join(" ");
    if (string.length > 0) {
      setSearchQuery(string);
      getSearchProducts(false, string, page);
    } else {
      setSearchQuery(null);
    }
  }, [page]);

  //onMount logic
  useEffect(() => {
    let userData = localStorage.getItem("userData");
    if (userData) {
      userData = JSON.parse(userData);
      userData.businessType &&
        userData.businessType[0] === "PHARMACY" &&
        navigate("/home");
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />

      <div className="ws-search-main-container" id="scrollableDiv">
        <InfiniteScroll
          style={{ overflowX: "hidden" }}
          className="ws-infy-s-component"
          dataLength={productsData.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <div style={{ width: "100%", height: "3rem" }}>
              <ButtonLoading1 color="#03C3B0" />
            </div>
          }
          scrollableTarget="scrollableDiv"
          scrollThreshold="90%"
          endMessage={
            <div style={{ width: "100%" }} className="flex-align-center-row">
              <p style={{ width: "60%" }} className="listEnd-msg">
                ⁌ end of search items ⁍
              </p>
            </div>
          }
        >
          {shimmer &&
            [...Array(5)].map((_, index) => {
              return (
                <WholeSaleProductCard
                  key={index}
                  variant={"white"}
                  shimmer={true}
                />
              );
            })}

          {!shimmer &&
            productsData?.length === 0 &&
            searchQuery?.length > 0 && (
              <p style={{ marginTop: "2rem" }}>No Medicines Found!</p>
            )}

          {!shimmer && !searchQuery && (
            <p style={{ marginTop: "2rem" }}>Enter keywords to search</p>
          )}

          {!shimmer &&
            productsData?.length > 0 &&
            productsData?.map((item, index) => {
              return (
                <WholeSaleProductCard
                  variant={"white"}
                  shimmer={false}
                  data={item}
                  key={index}
                />
              );
            })}
        </InfiniteScroll>
      </div>
    </>
  );
}

export default WSSearch;
