import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import ProductCard2 from "../ProductCard/ProductCard2";
import "./ProductList.css";
import "../../App.css";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate, useLocation } from "react-router-dom";
import { refreshToken } from "../../Actions/Auth";
import InfiniteScroll from "react-infinite-scroll-component";
import { getData } from "../../Actions/Product";
import NoData from "../../Images/png/no_data.png";
import { forceBackNavContext } from "../../App";

const ProductList = (props) => {
  let obj = null;
  const [screenData, setScreenData] = useState();
  // const { userLoggedIn} = React.useContext(userStatus);
  const [productRes, setProductRes] = useState(obj);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [homeShimmer, setHomerShimmer] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  const getProducts = () => {
    page === 0 && setHomerShimmer(true);
    if (location.pathname.split("/")[2] === "regularScheme") {
      const manf = location.pathname.split("/")[3].split("%20").join(" ");
      const params = {
        manufacturer: manf,
        page: page,
      };
      setScreenData(manf);

      getData("PRODUCTS_BY_COMPANIES_REGULARSCHEMES", params)
        .then((res) => {
          setHomerShimmer(false);
          let obj =
            productRes && productRes.medicinesResponsesList
              ? productRes
              : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(
              ...res.data.data.medicinesResponsesList
            );
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (
            res.data.data.size > 0 &&
            obj.medicinesResponsesList.length < 10
          ) {
            fetchMoreData();
          } else if (
            res.data.data.size > 0 &&
            res.data.data.medicinesResponsesList.length === 0
          ) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getProducts();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "surgical") {
      // PRODUCTS_BY_COMPANIES_CATEGORY
      const manf = location.pathname.split("/")[3].split("%20").join(" ");
      const params = {
        category: "SURGICAL",
        manufacturer: manf,
        page: page,
      };
      setScreenData(manf);

      getData("PRODUCTS_BY_COMPANIES_CATEGORY", params)
        .then((res) => {
          setHomerShimmer(false);
          let obj =
            productRes && productRes.medicinesResponsesList
              ? productRes
              : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(
              ...res.data.data.medicinesResponsesList
            );
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (
            res.data.data.size > 0 &&
            obj.medicinesResponsesList.length < 10
          ) {
            fetchMoreData();
          } else if (
            res.data.data.size > 0 &&
            res.data.data.medicinesResponsesList.length === 0
          ) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getProducts();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "generic") {
      // PRODUCTS_BY_COMPANIES_CATEGORY
      const manf = location.pathname.split("/")[3].split("%20").join(" ");
      const params = {
        category: "GENERIC",
        manufacturer: manf,
        page: page,
      };
      setScreenData(manf);

      getData("PRODUCTS_BY_COMPANIES_CATEGORY", params)
        .then((res) => {
          setHomerShimmer(false);
          let obj =
            productRes && productRes.medicinesResponsesList
              ? productRes
              : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(
              ...res.data.data.medicinesResponsesList
            );
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (
            res.data.data.size > 0 &&
            obj.medicinesResponsesList.length < 10
          ) {
            fetchMoreData();
          } else if (
            res.data.data.size > 0 &&
            res.data.data.medicinesResponsesList.length === 0
          ) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getProducts();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "ayurvedic") {
      // PRODUCTS_BY_COMPANIES_CATEGORY
      const manf = location.pathname.split("/")[3].split("%20").join(" ");
      const params = {
        category: "AYURVEDIC",
        manufacturer: manf,
        page: page,
      };
      setScreenData(manf);

      getData("PRODUCTS_BY_COMPANIES_CATEGORY", params)
        .then((res) => {
          setHomerShimmer(false);
          let obj =
            productRes && productRes.medicinesResponsesList
              ? productRes
              : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(
              ...res.data.data.medicinesResponsesList
            );
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (
            res.data.data.size > 0 &&
            obj.medicinesResponsesList.length < 10
          ) {
            fetchMoreData();
          } else if (
            res.data.data.size > 0 &&
            res.data.data.medicinesResponsesList.length === 0
          ) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getProducts();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "allopathy") {
      // PRODUCTS_BY_COMPANIES_CATEGORY
      const manf = location.pathname.split("/")[3].split("%20").join(" ");
      const params = {
        category: "ALLOPATHY",
        manufacturer: manf,
        page: page,
      };
      setScreenData(manf);

      getData("PRODUCTS_BY_COMPANIES_CATEGORY", params)
        .then((res) => {
          setHomerShimmer(false);
          let obj =
            productRes && productRes.medicinesResponsesList
              ? productRes
              : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(
              ...res.data.data.medicinesResponsesList
            );
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (
            res.data.data.size > 0 &&
            obj.medicinesResponsesList.length < 10
          ) {
            fetchMoreData();
          } else if (
            res.data.data.size > 0 &&
            res.data.data.medicinesResponsesList.length === 0
          ) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getProducts();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "speciality") {
      // PRODUCTS_BY_COMPANIES_CATEGORY
      const manf = location.pathname.split("/")[3].split("%20").join(" ");
      const params = {
        category: "SPECIALTY",
        manufacturer: manf,
        page: page,
      };
      setScreenData(manf);

      getData("PRODUCTS_BY_COMPANIES_CATEGORY", params)
        .then((res) => {
          setHomerShimmer(false);
          let obj =
            productRes && productRes.medicinesResponsesList
              ? productRes
              : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(
              ...res.data.data.medicinesResponsesList
            );
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (
            res.data.data.size > 0 &&
            obj.medicinesResponsesList.length < 10
          ) {
            fetchMoreData();
          } else if (
            res.data.data.size > 0 &&
            res.data.data.medicinesResponsesList.length === 0
          ) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getProducts();
            });
          }
        });
    } else {
      const manf = location.pathname.split("/")[2].split("%20").join(" ");
      const params = {
        manufacturerName: manf,
        page: page,
      };
      setScreenData(manf);

      getData("PRODUCTS_BY_COMPANIES", params)
        .then((res) => {
          setHomerShimmer(false);
          let obj =
            productRes && productRes.medicinesResponsesList
              ? productRes
              : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(
              ...res.data.data.medicinesResponsesList
            );
            setDataLength(obj.medicinesResponsesList.length);
          }

          setProductRes(obj);
          if (
            res.data.data.size > 0 &&
            obj.medicinesResponsesList.length < 10
          ) {
            fetchMoreData();
          } else if (
            res.data.data.size > 0 &&
            res.data.data.medicinesResponsesList.length === 0
          ) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getProducts();
            });
          }
        });
    }
  };

  const { setForceBackNav } = useContext(forceBackNavContext);

  let fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in .5 secs
    setPage(+page + 1);
    console.log("sdxzc");
  };

  // handler to force navigate from banner redirect
  const handleForceBackNav = () => {
    if (location?.state?.from === "/bannerRedirect") setForceBackNav(true);
    navigate(-1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("currentScreen", "manufacturer_screen");
    localStorage.removeItem("currentScreenBannerData");

    let userData = localStorage.getItem("userData");

    if (userData && userData.length) {
      getProducts();

      console.log(page);
    } else {
      navigate("/home");
    }

    console.log(window.history);
  }, [location, page]);

  // intercept browser back button click with custom logic to avoid banner redirect
  const onPageBackEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (location?.state?.from === "/bannerRedirect") setForceBackNav(true);
    navigate(-1);
  };

  // onMount register a brower back button click listener
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onPageBackEvent);
    return () => {
      window.removeEventListener("popstate", onPageBackEvent);
    };
  }, []);

  return (
    <Fragment>
      <Container fluid className="productList_cont">
        <Container fluid className="productListHeader font_ubuntu">
          {" "}
          <HiOutlineArrowNarrowLeft
            className="cursor_pointer goBack"
            onClick={handleForceBackNav}
          />{" "}
          &nbsp;&nbsp;&nbsp;{" "}
          {props.productListHeader && props.productListHeader.length > 0
            ? props.productListHeader
            : screenData}
        </Container>

        {!homeShimmer &&
          productRes &&
          productRes.medicinesResponsesList.length > 0 && (
            <div
              id="scrollableDiv"
              className="productListDiv"
              style={{ paddingTop: "0rem" }}
            >
              <InfiniteScroll
                dataLength={dataLength}
                next={fetchMoreData}
                hasMore={hasMore}
                scrollableTarget="scrollableDiv"
                scrollThreshold="90%"
                className="infiniteScroll"
                loader={
                  <Spinner
                    animation="border"
                    className="color_secondary spinner"
                    size="lg"
                  />
                }
              >
                {productRes &&
                  productRes.medicinesResponsesList.map((med, index) => (
                    <ProductCard2 key={index} medData={med} position={index} />
                  ))}
              </InfiniteScroll>
            </div>
          )}

        {!homeShimmer &&
          productRes &&
          productRes.medicinesResponsesList.length === 0 && (
            <div
              id="scrollableDiv"
              className="productList "
              style={{ paddingTop: "50px" }}
            >
              <div className="no_data_parent">
                <img src={NoData} alt="No Data" />
                <br /> <br />
                <p className="font_ubuntu font_16 font_weight_md">
                  No Products Found !
                </p>
              </div>
            </div>
          )}
        {homeShimmer && (
          <div className="productList">
            {[...Array(5)].map((_, index) => {
              return (
                <div className="padding_5" key={index}>
                  <ProductCard2 shimmer={homeShimmer} />
                </div>
              );
            })}
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default React.memo(ProductList);

// {
  /* <InfiniteScroll
    dataLength={productRes.medicinesResponsesList.length}
    next={fetchMoreData}
    //To put endMessage and loader to the top.
    inverse={true} //
    hasMore={true}
    height={'52vh'}
    loader={<h4>Loading...</h4>}
    scrollableTarget="scrollableDiv"
  endMessage={
    <p style={{ textAlign: 'center' }}>
      <b>Yay! You have seen it all</b>
    </p>
  }
  // below props only if you need pull down functionality
  
  >
    {productRes.medicinesResponsesList.map((med, index) => (
        <ProductCard2 key={index} medData={med}/>
    ))}
  </InfiniteScroll> */
// }
