import { Modal, Button } from "react-bootstrap";
import React from "react";
import "../../App.css";
import "./dialog.css";
import { useNavigate } from "react-router";
import { Common_Event_Hit } from "../../Actions/Events";
import Lottie from "react-lottie-player";
import generalOrderPlacedbackground from "../../Images/json/generalOrderPlacedBackground.json";
const OrderSuccessModal = (props) => {
  let navigate = useNavigate();
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="del_modal order-success-modal"
    >
      <Modal.Header style={{ padding: "0px" }} >
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text_center"
          style={{ padding: "0px" }}
        >
          <div style={{overflow: 'hidden', borderRadius : '15px'}}>
            <Lottie
              loop
              animationData={generalOrderPlacedbackground}
              play
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="padding_15 text-overlay" style={{ position: "absolute", top: "1rem", width: '18.5rem'}}>
            <br />
            <p className="font_ubuntu font_weight_md font_18" style={{marginTop: '9.75rem', marginBottom: '1rem', color: '#84EFE8' }}>
              Yay! Your order is placed.
            </p>
            {
              <>
                <div className="font_ubuntu color_white font_weight_500 font_12 text_center" style={{marginTop: '0px'}}>
                Once the order is processed, your delivery slot will be allocated within 24 hours 
                </div>
              </>
            }

            <div className="text_center">
              <Button
                className="width_100 brdr_none bg_secondary go_to_home color_white font_weight_md padding_15" style={{borderRadius: '7.5px', marginTop: '40px'}}
                onClick={async () => {
                  await Common_Event_Hit("DYNAMIC_EVENT", {
                    eventName: "goto_live_orders_tap",
                  });
                  navigate("/account/yourOrders?type=live");
                }}
              >
                Go to live orders
              </Button>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      {/* <Modal.Body>
       
      </Modal.Body> */}
    </Modal>
  );
};

export default OrderSuccessModal;
