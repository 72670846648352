import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'



const Bounce = () => {
let navigate=useNavigate()


    useEffect(()=>{
            navigate('/products/yourBounces')
    },[])
  return (
    <></>
  )
}

export default Bounce