import axios from "axios";
import { variables } from "../environments";

let config = {
  headers: {
    "Content-Type": "application/json",
    "source":"pharmacyWeb",
    appVersionCode: variables.appVersionCode,
  },
};
const ADMIN_URL = variables.ADMIN_URL;

const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);
  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config.headers.Authorization = "Bearer " + token;

  if (
    onBehalfRetCode &&
    onBehalfRetCode.length > 0 &&
    onBehalfRetCode !== "undefined" &&
    userData.admin
  ) {
    config.headers.adminRetailerCode = onBehalfRetCode;
    config.headers.source="pharmacyWebOnbehalf"
  }
  return true;
};

export const My_Account = async (type, params, body) => {
  const widgets = getToken().then(() => {
    switch (type) {
      case "LIVE_ORDERS":
        return axios.get(`${ADMIN_URL}/home/v2/viewLiveOrders`, {
          params: params,
          headers: config.headers,
        });

      case "PAST_ORDERS":
        return axios.get(`${ADMIN_URL}/home/v2/viewPastOrders`, {
          params: params,
          headers: config.headers,
        });

      case "EDIT_ORDER":
        return axios.put(`${ADMIN_URL}/home/V2/editOrder`, body, {
          params: params,
          headers: config.headers,
        });
      case "CANCEL_ORDER":
        return axios.put(`${ADMIN_URL}/home/retailer/cancelOrder`, body, {
          params: params,
          headers: config.headers,
        });

      case "RETURN_PRODUCT_DETAILS":
        return axios.post(`${ADMIN_URL}/home/v2/getReturnOrderDetails`, body, {
          params: params,
          headers: config.headers,
        });

      case "GET_RETURN_INVOICES":
        return axios.get(`${ADMIN_URL}/home/v2/getBuyerInvoicesForReturns`, {
          params: params,
          headers: config.headers,
        });

      case "PLACE_RETURN":
        return axios.post(`${ADMIN_URL}/home/v2/placeReturnLatest`, body, {
          params: params,
          headers: config.headers,
        });

      case "LIVE_RETURNS":
        return axios.get(`${ADMIN_URL}/home/v2/viewLiveAppReturns`, {
          params: params,
          headers: config.headers,
        });
      case "PAST_RETURNS":
        return axios.get(`${ADMIN_URL}/home/v2/viewPastAppReturns`, {
          params: params,
          headers: config.headers,
        });

      case "CANCEL_RETURN":
        return axios.post(`${ADMIN_URL}/home/v2/cancelAppReturnOrder`, body, {
          params: params,
          headers: config.headers,
        });

      case "EDIT_RETURN":
        return axios.post(`${ADMIN_URL}/home/v2/editAppReturnItems`, body, {
          params: params,
          headers: config.headers,
        });

      case "BOUNCE_ITEMS":
        return axios.get(`${ADMIN_URL}/home/v2/getUserBounceItems`, {
          params: params,
          headers: config.headers,
        });

      case "WALLET_HISTORY":
        return axios.get(`${ADMIN_URL}/home/v2/getUserWalletTransactions`, {
          params: params,
          headers: config.headers,
        });
    }
  });
  return widgets;
};
