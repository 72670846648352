import React, { useContext, useEffect, useState } from "react";
import {
  DropdownButton,
  Dropdown,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./UploadToCartModal.css";
import {
  FaWindowClose,
  FaFileUpload,
  FaDownload,
  FaRegSave,
} from "react-icons/fa";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import {
  MdOutlineDelete,
  MdOutlineRefresh,
  MdModeEditOutline,
  MdDownload,
  MdClose,
} from "react-icons/md";
import Sample_MedicineId_List from "../../Files/Sample_MedicineId_List.csv";
import Sample_MedicineName_List from "../../Files/Sample_MedicineName_List.csv";
import { Cart_Actions } from "../../Actions/Cart";
import { refreshToken } from "../../Actions/Auth";
import DeleteProductModal from "./DeleteProductModal";
import ErrorModal from "./ErrorModal";
import { cartDataContext, usersData } from "../../App";
import { useNavigate } from "react-router";
import { getData } from "../../Actions/Product";
import { Common_Event_Hit } from "../../Actions/Events";

export default function UploadToCartModal({
  onBehalfExist,
  uploadToCart,
  setUploadToCart,
  userData,
}) {
  const { setCartData } = useContext(cartDataContext);
  const { setShowMaintenance } = useContext(usersData);
  const [medicineObj, setMedicineObj] = useState([]);
  const [uploadType, setUploadType] = useState("");
  const [showMedicineList, setShowMedicineList] = useState(false);
  const [uploadCheck,setUploadCheck]=useState(false)
  const [filteredMedicineObj, setFilteredMedicineObj] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [filteredErrorList, setFilteredErrorList] = useState([]);
  const [edit, setEdit] = useState(null);
  const [medicine, setMedicine] = useState("");
  const [medQty, setMedQty] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [shake, setShake] = useState(false);
  const [errorHighlight, setErrorHighlight] = useState();
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [showDeleteCart, setShowDeleteCart] = useState({
    show: false,
    item_name: "",
    handleRemove: "",
  });
  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  let navigate = useNavigate();

  const handleMedicineSelect = async (event, selectUploadType) => {
    setSuccessCount(0);
    setErrorCount(0);

    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName:`upload_${selectUploadType}_cart`,
    });
    setSpinner(true);
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      let csv = event.target.result;
      var lines = csv.split("\n");
      lines=lines.filter(n=>{return n})

      let result = [];

      var headers = lines[0].split(",");
      headers[headers.length] = "id";

      for (let i = 0; i < headers.length; i++) {
        let headerIndx = headers[i].search(/\r/);

        if (headerIndx > -1) {
          headers[i] = headers[i].substring(headerIndx, 0);
        }
      }

      for (var i = 1; i <= lines.length - 1; i++) {
        var obj = {};

        //Comma split
        var currentline = lines[i].split(",");

        for (var j = 0; j < headers.length; j++) {
          if (headers[j] === "id") {
            obj[headers[j]] = i;
          } else {
            let indx = currentline[j].search(/\r/);

            if (indx > -1) {
              let repl = currentline[j].substring(indx, 0);
              obj[headers[j]] = repl;
            } else {
              obj[headers[j]] = currentline[j];
            }
          }
        }

        result.push(obj);
      }

      if (selectUploadType === "medicineName") {
        setUploadType("medicineName");
      } else if (selectUploadType === "medicineId") {
        setUploadType("medicineId");
      }

      setMedicineObj(result);
      setFilteredMedicineObj(result);
      setShowMedicineList(true);
      setSpinner(false);
    };
    reader.readAsText(file);
  };

  const handleSearch = (event) => {
    const searchString = event.target.value.toLowerCase();

    setSearchVal(searchString);

    if (medicineObj.length > 0) {
      if (searchString === "") {
        setFilteredMedicineObj(
          medicineObj.filter((ele) => {
            return ele;
          })
        );
      } else {
        setFilteredMedicineObj(
          medicineObj.filter((ele) => {
            return uploadType === "medicineName"
              ? ele.MedicineName.toLowerCase().includes(searchString)
              : ele.MedicineId.toLowerCase().includes(searchString);
          })
        );
      }
    } else if (errorList.length > 0) {
      if (searchString === "") {
        setFilteredErrorList(
          errorList.filter((ele) => {
            return ele;
          })
        );
      } else {
        let newFilteredList = [];

        if (uploadType === "medicineName") {
          let newList = errorList.filter(
            (el) => el.isSelected === true || el.isSelected === undefined
          );

          newFilteredList = newList.filter((ele) => {
            return ele.selectedMedicine
              ? ele.selectedMedicine.name.toLowerCase().includes(searchString)
              : ele.MedicineName.toLowerCase().includes(searchString);
          });
        } else {
          newFilteredList = errorList.filter((ele) => {
            return ele.MedicineId.toLowerCase().includes(searchString);
          });
        }

        setFilteredErrorList(newFilteredList);
      }
    }
  };

  const handleSubmit = async () => {
    let count = 0;
    setProgressBar(true);

    if (uploadType === "medicineId") {
      for (let i = 0; i < medicineObj.length; i++) {
        const params = {};
        const body = {
          androidMap: {
            locale: "en",
            source: "pharmacyWeb",
            deviceId: null,
            appVersion: navigator.userAgent,
            deviceName: null,
            screen_name: "Upload CSV",
            appVersionCode: "156",
          },
          medicineId: medicineObj[i].MedicineId,
          quantity: +medicineObj[i].Qty,
          retailerCode: userData.retailerCode,
          retailerId: userData.id,
        };

        Cart_Actions("ADD_TO_CART", params, body)
          .then((res) => {
            setCartData(res.data.data);
            setSuccessCount((prevVal) => prevVal + 1);

            count++;
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshToken().then((res) => {
                handleSubmit();
              });
            } else {
              let errorObj = {
                ...medicineObj[i],
                error: error?.response?.data?.error?.error
                  ? error.response.data.error.error
                  : "Bad Request",
              };

              errorList.push(errorObj);
              filteredErrorList.push(errorObj);
              setErrorCount((prevVal) => prevVal + 1);
              count++;
            }
          })
          .finally(() => {
            if (count === medicineObj.length) {
              setMedicineObj([]);
              setFilteredMedicineObj([]);
              setSearchVal("");
              setProgressBar(false);
            }
          });
      }
    } else if (uploadType === "medicineName") {
      let params = {
        page: 0,
      };

      for (let i = 0; i < medicineObj.length; i++) {
        const res = await getData(
          "PRODUCTS_BY_SEARCH",
          params,
          medicineObj[i].MedicineName.substring(0,5)
        );

        if (
          !res.data.medicinesResponsesList.length ||
          res.data.medicinesResponsesList.length > 1
        ) {
          let errorObj;
          if (!res.data.medicinesResponsesList.length) {
            errorObj = {
              ...medicineObj[i],
              isEdited: false,
              error:
                !res.data && !res.error
                  ? "Something went wrong."
                  : "Medicine doesn't exist.",
            };
          } else {
            errorObj = {
              ...medicineObj[i],
              MedicineList: res.data.medicinesResponsesList,
              isSelected: false,
              isEdited: false,
              error: "Multiple medicines exist. Please select one.",
            };
          }

          errorList.push(errorObj);
          filteredErrorList.push(errorObj);
          setErrorCount((prevVal) => prevVal + 1);
          count++;

          if (count === medicineObj.length) {
            setMedicineObj([]);
            setFilteredMedicineObj([]);
            setSearchVal("");
            setProgressBar(false);
          }
        } else {
          const params = {};
          const body = {
            androidMap: {
              locale: "en",
              source: "pharmacyWeb",
              deviceId: null,
              appVersion: navigator.userAgent,
              deviceName: null,
              screen_name: "Upload CSV",
              appVersionCode: "156",
            },
            medicineId: res.data.medicinesResponsesList[0].medicineId,
            quantity: +medicineObj[i].Qty,
            retailerCode: userData.retailerCode,
            retailerId: userData.id,
          };

          Cart_Actions("ADD_TO_CART", params, body)
            .then((resp) => {
              setCartData(resp.data.data);
              setSuccessCount((prevVal) => prevVal + 1);

              count++;
            })
            .catch((error) => {
              if (error?.response?.status === 401) {
                refreshToken().then((resp) => {
                  handleSubmit();
                });
              } else {
                let errorObj = {
                  ...medicineObj[i],
                  medicineId: res.data.medicinesResponsesList[0].medicineId,
                  isEdited: false,
                  error: error?.response?.data?.error?.error
                    ? error.response.data.error.error
                    : "Bad Request",
                };

                errorList.push(errorObj);
                filteredErrorList.push(errorObj);
                setErrorCount((prevVal) => prevVal + 1);
                count++;
              }
            })
            .finally(() => {
              if (count === medicineObj.length) {
                setMedicineObj([]);
                setFilteredMedicineObj([]);
                setSearchVal("");
                setProgressBar(false);
              }
            });
        }
      }
    }
  };

  const handleRetry = async (med) => {
    setSpinner(true);
    if (
      uploadType === "medicineId" ||
      (uploadType === "medicineName" && med.isSelected) ||
      (med.medicineId && med.isEdited === false)
    ) {
      const params = {};
      const body = {
        androidMap: {
          locale: "en",
          source: "pharmacyWeb",
          deviceId: null,
          appVersion: navigator.userAgent,
          deviceName: null,
          screen_name: "Upload CSV",
          appVersionCode: "156",
        },
        medicineId:
          uploadType === "medicineName" && med.isSelected
            ? med.selectedMedicine.medicineId
            : med.MedicineId,
        quantity: +med.Qty,
        retailerCode: userData.retailerCode,
        retailerId: userData.id,
      };

      Cart_Actions("ADD_TO_CART", params, body)
        .then((res) => {
          setCartData(res.data.data);
          const findIndex = errorList.findIndex((error) => med.id === error.id);
          const deleteObj = [...errorList];

          deleteObj.splice(findIndex, 1);
          setErrorList(deleteObj);
          setFilteredErrorList(deleteObj);
          setSuccessCount((prevVal) => prevVal + 1);
          setErrorCount((prevVal) => prevVal - 1);
          setSpinner(false);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((resp) => {
              handleRetry(med);
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance(true);
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              content: "Oops something went wrong !",
              tryAgain: function () {
                handleRetry(med);
              },
            });
          } else {
            const findIndex = errorList.findIndex(
              (error) => med.id === error.id
            );

            errorList[findIndex].error = error.response.data.error.error;

            setErrorModal({
              show: true,
              content: error?.response?.data.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
            });

            setErrorHighlight(med.id);
          }
          setSpinner(false);
        });
    } else if (!med.medicineId && med.isEdited) {
      let params = {
        page: 0,
      };

      const res = await getData("PRODUCTS_BY_SEARCH", params, med.MedicineName);

      if (
        !res.data.medicinesResponsesList.length ||
        res.data.medicinesResponsesList.length > 1
      ) {
        const findIndex = errorList.findIndex((error) => med.id === error.id);

        if (!res.data.medicinesResponsesList.length) {
          filteredErrorList[findIndex].isEdited = false;
          filteredErrorList[findIndex].error =
            !res.data && !res.error
              ? "Something went wrong."
              : "Medicine doesn't exist.";

          setErrorHighlight(med.id);

          setSpinner(false);
        } else {
          filteredErrorList[findIndex].MedicineList =
            res.data.medicinesResponsesList;
          filteredErrorList[findIndex].isSelected = false;
          filteredErrorList[findIndex].isEdited = false;
          filteredErrorList[findIndex].error =
            "Multiple medicines exist. Please select one.";

          setErrorHighlight(med.id);
          setSpinner(false);
        }
      } else {
        const params = {};
        const body = {
          androidMap: {
            locale: "en",
            source: "pharmacyWeb",
            deviceId: null,
            appVersion: navigator.userAgent,
            deviceName: null,
            screen_name: "Upload CSV",
            appVersionCode: "156",
          },
          medicineId: res.data.medicinesResponsesList[0].medicineId,
          quantity: med.Qty,
          retailerCode: userData.retailerCode,
          retailerId: userData.id,
        };
        Cart_Actions("ADD_TO_CART", params, body)
          .then((res) => {
            setCartData(res.data.data);
            const findIndex = errorList.findIndex(
              (error) => med.id === error.id
            );
            const deleteObj = [...errorList];

            deleteObj.splice(findIndex, 1);
            setErrorList(deleteObj);
            setFilteredErrorList(deleteObj);
            setSuccessCount((prevVal) => prevVal + 1);
            setErrorCount((prevVal) => prevVal - 1);
            setSpinner(false);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              refreshToken().then((resp) => {
                handleSubmit();
              });
            } else {
              const findIndex = errorList.findIndex(
                (error) => med.id === error.id
              );

              setErrorHighlight(med.id);

              filteredErrorList[findIndex].error = error?.response?.data?.error
                ?.error
                ? error.response.data.error.error
                : "Bad Request";
              setSpinner(false);
            }
          });
      }
    }
  };

  const handleEdit = (med) => {
    setEdit(med.id);
    setMedQty(med.Qty);

    if (uploadType === "medicineId") {
      setMedicine(med.MedicineId);
    } else if (uploadType === "medicineName") {
      setMedicine(med.MedicineName);
    }
  };

  const handleUpdateEdit = (event) => {
    event.preventDefault();

    const type = event.target.name;

    if (type === "medicine" || type === "medicineId") {
      setMedicine(event.target.value);
    } else {
      setMedQty(event.target.value);
    }
  };

  const handleSave = (oldIndex, handleType) => {
    const editedFile = {
      Medicine: medicine,
      Qty: medQty,
    };

    if (handleType === "retry") {
      const findIndex = errorList.findIndex((med) => med.id === edit);

      if (uploadType === "medicineName") {
        errorList[findIndex].MedicineName = editedFile.Medicine;
        delete errorList[findIndex].medicineId;
        errorList[findIndex].isEdited = true;
      } else {
        errorList[findIndex].MedicineId = editedFile.Medicine;
      }

      errorList[findIndex].Qty = editedFile.Qty;
    } else {
      const findIndex = medicineObj.findIndex((med) => med.id === edit);

      if (uploadType === "medicineName") {
        medicineObj[findIndex].MedicineName = editedFile.Medicine;
      } else {
        medicineObj[findIndex].MedicineId = editedFile.Medicine;
      }

      medicineObj[findIndex].Qty = editedFile.Qty;
    }

    setEdit(null);
  };

  const openRemoveModal = (id, med, handleType) => {
    setShowDeleteCart({
      show: true,
      handleRemove: function () {
        handleRemove(id, handleType);
      },
      item_name: med.MedicineId,
    });
  };

  const handleRemove = (id, handleType) => {
    if (handleType === "errorType") {
      const findIndex = errorList.findIndex((error, index) => id === error.id);
      const deleteObj = [...errorList];

      deleteObj.splice(findIndex, 1);
      setErrorList(deleteObj);
      setFilteredErrorList(deleteObj);

      if (errorList.length === 1) {
        setShowMedicineList(false);
      }
    } else {
      const findIndex = medicineObj.findIndex(
        (error, index) => id === error.id
      );
      const deleteObj = [...medicineObj];

      deleteObj.splice(findIndex, 1);
      setMedicineObj(deleteObj);

      if (medicineObj.length === 1) {
        setShowMedicineList(false);
      }
    }
    setShowDeleteCart({ show: false, item_name: "", handleRemove: "" });
  };

  const selectMedicine = (list, medList, index) => {
    let obj = [...errorList];

    obj[index].selectedMedicine = list;
    obj[index].isSelected = true;
    obj[index].isEdited = true;
    setErrorList(obj);
  };

  const downloadCSV = () => {
    const fields = [
      uploadType === "medicineName" ? "MedicineName" : "MedicineId",
      "Qty",
      "error",
    ];
    const header = [
      uploadType === "medicineName" ? "MedicineName" : "MedicineId",
      "Qty",
      "Error",
    ];
    const replacer = (key, value) => (value === null ? null : value);

    const csv = errorList.map((row) =>
      fields
        .map((fieldName) =>
          typeof fieldName !== "string"
            ? JSON.stringify(row[fieldName], replacer)
            : row[fieldName]
        )
        .join(",")
    );

    // add headers
    csv.unshift(header.join(","));
    const csvArray = csv.join("\n");

    // generate file
    const a = document.createElement("a");
    const blob = new Blob([csvArray], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // download file
    a.href = url;
    a.download = `${userData.retailerCode}_ERROR_LIST_CSV`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  useEffect(() => {
    setShake(true);
    setTimeout(() => {
      setShake(false);
    }, [5000]);
  }, [successCount, errorCount]);

  useEffect(() => {
    if (errorHighlight) {
      setTimeout(() => {
        setErrorHighlight();
      }, [3000]);
    }
  }, [errorHighlight]);

  return (
    <div className="upload_cart_container">
      <div className="upload_cart_sub_container">
        <ErrorModal
          uploadToCart={true}
          show={errorModal.show}
          content={errorModal.content}
          apiData={errorModal?.apiData}
          tryAgain={() => {
            errorModal?.tryAgain();
            setErrorModal({ show: false, content: "" });
          }}
          onHide={() => {
            setErrorModal({ show: false, content: "" });
          }}
        />

        <DeleteProductModal
          show={showDeleteCart.show}
          itemName={showDeleteCart.item_name}
          removeFromCart={() => showDeleteCart.handleRemove()}
          uploadToCart={true}
          onHide={() =>
            setShowDeleteCart({ show: false, item_name: "", handleRemove: "" })
          }
        />

        {spinner ? (
          <div className="loader_cntr">
            <Spinner animation="border" className="color_primary" />
          </div>
        ) : progressBar ? (
          <div className="loader_cntr">
            <div className="progress_bar_cntr">
              <p>
                {Math.round(
                  ((successCount + errorCount) / medicineObj.length) * 100
                )}
                %
              </p>
              <div className="progress_bar_sub_cntr">
                <div
                  className="progress_bar"
                  style={{
                    width: `${Math.round(
                      ((successCount + errorCount) / medicineObj.length) * 100
                    )}%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="upload_title">
              <p>Upload Medicines to Cart</p>

              <FaWindowClose
                onClick={() => {

                  setUploadToCart(!uploadToCart);
                  document.body.className = "";
                }}
              />
            </div>

            {uploadCheck && <p className="upload_check">Oops! Based on your selection the required filed{'(s)'} are missing.</p>}

            {!showMedicineList && (
              <section className="upload_selection">
                {onBehalfExist ? (
                  <div className="upload_selection_sub_cntr">
                    <div className="upload_cart_btn">
                      <label htmlFor="uploadMedicineId">
                        <FaFileUpload />
                        <p>
                          Upload a <b>CSV</b> file with Medicine Id
                        </p>
                      </label>

                      <input
                        type="file"
                        id="uploadMedicineId"
                        accept=".csv"
                        onChange={(event) =>
                          handleMedicineSelect(event, "medicineId")
                        }
                      />
                    </div>

                    <a
                      className="download_sample_csv"
                      href={Sample_MedicineId_List}
                    >
                      <button>
                        <FaDownload />
                        Download Sample CSV
                      </button>
                    </a>
                  </div>
                ) : (
                  <></>
                )}

                <div className="upload_selection_sub_cntr">
                  <div className="upload_cart_btn">
                    <label htmlFor="uploadMedicineName">
                      <FaFileUpload />
                      <p>
                        Upload a <b>CSV</b> file with Medicine Name
                      </p>
                    </label>

                    <input
                      type="file"
                      id="uploadMedicineName"
                      accept=".csv"
                      onChange={(event) =>
                        handleMedicineSelect(event, "medicineName")
                      }
                    />
                  </div>

                  <a
                    className="download_sample_csv"
                    href={Sample_MedicineName_List}
                  >
                    <button>
                      <FaDownload />
                      Download Sample CSV
                    </button>
                  </a>
                </div>
              </section>
            )}

            {medicineObj.length > 0 && (
              <section className="medicine_list_tbl confirm_list">
                <div className="search_cntr">
                  <input
                    type="text"
                    placeholder={
                      uploadType === "medicineName"
                        ? "Search by Medicine Name"
                        : "Search by Medicine Id"
                    }
                    value={searchVal}
                    onChange={handleSearch}
                  />
                  <button>
                    <BiSearch
                      style={{ fontSize: "20px" }}
                      className="color_grey search_icon"
                    />
                  </button>
                </div>
                <div className="tbl_cntr">
                  <table>
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        {uploadType === "medicineName" ? (
                          <th>Medicine&nbsp;Name</th>
                        ) : (
                          <th>Medicine&nbsp;Id</th>
                        )}
                        <th>Quantity</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {filteredMedicineObj.map((med, index) =>
                        edit === med.id ? (
                          <tr key={"editMeds" + index}>
                            <td>{index + 1}</td>
                            <td>
                              {uploadType === "medicineName" ? (
                                <input
                                  type="text"
                                  name="medicine"
                                  value={medicine}
                                  onChange={handleUpdateEdit}
                                />
                              ) : (
                                <input
                                  type="text"
                                  name="medicineId"
                                  value={medicine}
                                  onChange={handleUpdateEdit}
                                />
                              )}
                            </td>
                            <td>
                              <input
                                type="number"
                                name="qty"
                                value={medQty}
                                onChange={handleUpdateEdit}
                              />
                            </td>
                            <td>
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>Close</Tooltip>
                                }
                              >
                                <button
                                  className="edit_btn"
                                  onClick={() => setEdit(null)}
                                >
                                  <MdClose />
                                </button>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>Save</Tooltip>
                                }
                              >
                                <button
                                  className="dlt_btn"
                                  onClick={() => handleSave(med.id)}
                                >
                                  <FaRegSave />
                                </button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ) : (
                          <tr key={"meds" + index}>
                            <td>{index + 1}</td>

                            {uploadType === "medicineName" ? (
                              <td>{med.MedicineName}</td>
                            ) : (
                              <td>{med.MedicineId}</td>
                            )}
                            <td>{med.Qty}</td>
                            <td>
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                                }
                              >
                                <button
                                  className="edit_btn"
                                  onClick={() => handleEdit(med)}
                                >
                                  <MdModeEditOutline />
                                </button>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <button
                                  className="dlt_btn"
                                  onClick={() => {
                                    openRemoveModal(
                                      med.id,
                                      med,
                                      uploadType === "medicineName"
                                        ? "medicineName"
                                        : "medicineId"
                                    );
                                  }}
                                >
                                  <MdOutlineDelete />
                                </button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="submit_cntr">
                  <button onClick={handleSubmit}>Submit</button>
                </div>
              </section>
            )}

            {errorList.length > 0 && (
              <section className="medicine_list_tbl error_list">
                <div className="count_cont">
                  <div className="search_cntr" style={{ marginBottom: "1rem" }}>
                    <input
                      type="text"
                      value={searchVal}
                      placeholder={
                        uploadType === "medicineName"
                          ? "Search by Medicine Name"
                          : "Search by Medicine Id"
                      }
                      onChange={handleSearch}
                    />
                    <button>
                      <BiSearch
                        style={{ fontSize: "20px" }}
                        className="color_grey search_icon"
                      />
                    </button>
                  </div>

                  {successCount > 0 && (
                    <p className="success_count">
                      <div>
                        <span className={shake ? "countAnime" : ""}>
                          {successCount}{" "}
                        </span>
                        {successCount === 1 ? "Medicine" : "Medicines"} added
                        successfully
                      </div>
                    </p>
                  )}

                  {errorCount > 0 && (
                    <p className="error_count">
                      <div>
                        <span>{errorCount} </span>
                        {errorCount === 1
                          ? "Medicine was"
                          : "Medicines were"}{" "}
                        not added to cart
                      </div>

                      <div>
                        <OverlayTrigger
                          placement={"bottom"}
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              Download CSV
                            </Tooltip>
                          }
                        >
                          <button onClick={downloadCSV}>
                            <MdDownload />
                          </button>
                        </OverlayTrigger>
                      </div>
                    </p>
                  )}
                </div>

                <div className="tbl_cntr">
                  <table>
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        {uploadType === "medicineName" ? (
                          <th>Medicine&nbsp;Name</th>
                        ) : (
                          <th>Medicine&nbsp;Id</th>
                        )}
                        <th>Quantity</th>
                        <th>Actions</th>
                        <th>Error</th>
                      </tr>
                    </thead>

                    <tbody>
                      {filteredErrorList.map((med, index) =>
                        edit === med.id ? (
                          <tr key={"errorEdit" + index}>
                            <td>{index + 1}</td>
                            <td
                              className={med.MedicineList ? "disableEvent" : ""}
                            >
                              <input
                                type="text"
                                name="medicine"
                                value={
                                  med.MedicineList && med.isSelected
                                    ? med.selectedMedicine.name
                                    : medicine
                                }
                                onChange={handleUpdateEdit}
                                style={{ width: "fit-content !important" }}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="qty"
                                value={medQty}
                                onChange={handleUpdateEdit}
                              />
                            </td>
                            <td>
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>Close</Tooltip>
                                }
                              >
                                <button
                                  className="edit_btn"
                                  onClick={() => setEdit(null)}
                                >
                                  <MdClose />
                                </button>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>Save</Tooltip>
                                }
                              >
                                <button
                                  className="dlt_btn"
                                  onClick={() => handleSave(med.id, "retry")}
                                >
                                  <FaRegSave />
                                </button>
                              </OverlayTrigger>
                            </td>
                            <td
                              className={
                                errorHighlight === med.id
                                  ? "error_highlight"
                                  : ""
                              }
                            >
                              {med.error}
                            </td>
                          </tr>
                        ) : (
                          <tr key={"error" + index}>
                            <td>{index + 1}</td>
                            {uploadType === "medicineName" ? (
                              med.MedicineList ? (
                                <td style={{display: "flex", alignItems: "center",justifyContent: "space-between"}}>
                                  <p style={{marginBottom: "0"}}>{med.MedicineName}</p>
                                  <DropdownButton
                                    title={
                                      med.selectedMedicine
                                        ? med.selectedMedicine.name
                                        : "Select Medicine"
                                    }
                                    className="medDropDown"
                                  >
                                    <div className="dropdown_main_cntr">
                                      {med.MedicineList.map((list, ind) => {
                                        return (
                                          <Dropdown.Item
                                            key={ind + "Medi"}
                                            onClick={() =>
                                              selectMedicine(list, med, index)
                                            }
                                            className="dropdown_cntr"
                                          >
                                            <div className="medList_cntr">
                                              <p>
                                                {list.name}
                                                <span>{list.manufacturer}</span>
                                              </p>

                                              <div className="sub_medList_cntr">
                                                {list.cashDiscountInPercentage >
                                                  0 && (
                                                  <p>
                                                    <span>CD</span>
                                                    {
                                                      list.cashDiscountInPercentage
                                                    }
                                                    %
                                                  </p>
                                                )}
                                                <p>
                                                  <span>PACK</span>
                                                  {list.packaging}
                                                </p>
                                                {list.scheme.map((schm, i) => {
                                                  return (
                                                    <p key={"scheme" + i}>
                                                      <span>SCHEME</span>
                                                      {schm}
                                                    </p>
                                                  );
                                                })}
                                                <p>
                                                  <span>PTR</span>₹{list.ptr}
                                                </p>
                                                <p>
                                                  <span>MRP</span>₹{list.mrp}
                                                </p>
                                                <p>
                                                  <span>STOCK</span>
                                                  {list.availableQuantity}
                                                </p>
                                              </div>
                                            </div>
                                          </Dropdown.Item>
                                        );
                                      })}
                                    </div>
                                  </DropdownButton>
                                </td>
                              ) : (
                                <td style={{textAlign: "left"}}>{med.MedicineName}</td>
                              )
                            ) : (
                              <td>{med.MedicineId}</td>
                            )}
                            <td>{med.Qty}</td>
                            <td>
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>Retry</Tooltip>
                                }
                              >
                                <button
                                  className={
                                    (med.MedicineList &&
                                      med.isSelected === false) ||
                                    (!med.medicineId && med.isEdited === false)
                                      ? "disableEvent retry_btn"
                                      : "retry_btn"
                                  }
                                  onClick={() => handleRetry(med)}
                                >
                                  <MdOutlineRefresh />
                                </button>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                                }
                              >
                                <button
                                  className={
                                    med.MedicineList && !med.selectedMedicine
                                      ? "disableEvent edit_btn"
                                      : "edit_btn"
                                  }
                                  onClick={() => handleEdit(med)}
                                >
                                  <MdModeEditOutline />
                                </button>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <button
                                  className="dlt_btn"
                                  onClick={() => {
                                    openRemoveModal(med.id, med, "errorType");
                                  }}
                                >
                                  <MdOutlineDelete />
                                </button>
                              </OverlayTrigger>
                            </td>
                            <td
                              className={
                                errorHighlight === med.id
                                  ? "error_highlight"
                                  : ""
                              }
                            >
                              {med.error}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </section>
            )}

            {showMedicineList &&
              medicineObj.length < 1 &&
              errorList.length < 1 && (
                <section className="success_cntr">
                  <h4>
                    <span>
                      <BsFillPatchCheckFill />
                    </span>
                    All medicines are successfully added to cart
                  </h4>

                  <button
                    className="cart_btn"
                    onClick={async () => {
                      navigate("/cart");
                      setUploadToCart(!uploadToCart);
                      document.body.className = "";
                    }}
                  >
                    Go to Cart
                  </button>
                </section>
              )}
          </>
        )}
      </div>
    </div>
  );
}
