import React from "react";
import "./AlertModal.css";

const AlertModal = ({ show, setShowAlert, message, closeAlert }) => {
  return (
    <>
      {show && (
        <div className="alertCntr">
          <div className="alertModal">
            <div className="alert_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                class="feather feather-alert-triangle"
              >
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                <line x1="12" y1="9" x2="12" y2="13" />
                <line x1="12" y1="17" x2="12.01" y2="17" />
              </svg>
            </div>
            <div className="alert_header">
              <div className="alert_message">
                {message
                  ?.split(".")
                  ?.filter((el) => el !== "")
                  ?.map((msg, indx) => {
                    return <p key={indx + "alert_message"}>{msg}</p>;
                  })}
              </div>
            </div>

            <div className="alertActions">
              <button className="alert_confirm_btn" onClick={closeAlert}>
                Yes
              </button>
              <button
                className="alert_cancel_btn"
                onClick={() => setShowAlert(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AlertModal;
