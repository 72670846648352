import React, { useEffect, useState, useContext } from "react";
import { Card, Container, ListGroup } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { refreshToken } from "../../Actions/Auth";
import { getScreen } from "../../Actions/Screens";
import ErrorModal from "../Modals/ErrorModal";
import { usersData } from "../../App";
import { general_issue_widget } from "../../Actions/Widgets";
import supportLiveOrders from "../../Images/svg/noun_help_1334455.svg";
import supportPastOrders from "../../Images/svg/Group 19964.svg";
import walletIcon from "../../Images/svg/Asset 1.svg";
import { MdNavigateNext } from "react-icons/md";
import { useNavigate } from "react-router";
import { Common_Event_Hit } from "../../Actions/Events";

function ReportIssue() {
  const [reportIssueScreenData, setReportIssueScreenData] = useState([]);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [generalIssueData, setGeneralIssueData] = useState([]);
  const [shimmer, setShimmer] = useState(false);

  const navigate = useNavigate();

  const getReportScreenData = async () => {
    setShimmer(true);
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    const params = {
      retailerId: `${userData.id}`,
      screenName: "report_issue_screen",
    };

    getScreen("HELP_SUPPORT_SCREEN", params, {})
      .then((res) => {
        console.log("screen response", res.data.data);
        setReportIssueScreenData(res.data.data);
        // getReportScreenData(res.data.data[res.data.data.length - 1].data.apiEndPoint,`${userData.id}`)
        general_issue_widget(
          `${res.data.data[res.data.data.length - 1].data.apiEndPoint}`
        )
          .then((res) => {
            console.log("general issue repsonse", res.data.data);
            setGeneralIssueData(res.data.data);
            setShimmer(false);
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshToken().then((res) => {
                getReportScreenData();
              });
            } else if (
              error?.response?.status === 502 ||
              error?.response?.status === 503 ||
              error?.response?.status === 504
            ) {
              setShowMaintenance({ status: true, apiData: error?.response });
            } else if (error?.response?.status === 500) {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: "Oops something went wrong!",
                tryAgain: function () {
                  getReportScreenData();
                },
              });
            } else {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: error?.response?.data?.error?.error
                  ? error.response.data.error.error
                  : "Something went wrong!",
                tryAgain: function () {
                  getReportScreenData();
                },
              });
            }
          });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getReportScreenData();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong!",
            tryAgain: function () {
              getReportScreenData();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getReportScreenData();
            },
          });
        }
      });
  };

  const handleReportForm = (e) => {
    //donot call the issue tap when general issue is tapped
    callApiEvents("sub_issue_tap"); //call the common sub issue event tap
    console.log("hitting form screen", e.target.id);
    navigate("/help-support/ReportIssue/" + e.target.id, {
      state: generalIssueData,
    });
  };

  const handleOrders = (sectionName) => {
    let orderType;
    console.log("sectionName", sectionName);
    if (sectionName.toLowerCase().includes("live orders")) {
      orderType = "LIVE_ORDERS";
      callApiEvents("issue_tap", "live_orders_tap"); //issue tap is called on each click
      callApiEvents("live_orders_tap"); //call live order tap
    } else if (sectionName.toLowerCase().includes("past orders")) {
      orderType = "PAST_ORDERS";
      callApiEvents("issue_tap", "past_orders_tap"); //issue tap is called on each click
      callApiEvents("past_orders_tap"); //call past order tap
    } else if (sectionName.toLowerCase().includes("past tickets")) {
      callApiEvents("issue_tap", "past_tickets_tap"); //issue tap is called on each click
      callApiEvents("past_tickets_tap"); //call past tickets tap
      navigate("/help-support/ReportIssue/pastTickets");
    }

    console.log("help-support/ReportIssue/OrdersWithIssue/" + orderType);
    if (orderType) navigate("OrdersWithIssue/" + orderType);

    console.log("hitting ", orderType);
  };

  const callApiEvents = (eventName, issueType) => {
    if (eventName === "issue_tap") {
      //call issue tap for every issue type tap
      let props = {
        eventName: eventName,
        issue_type: issueType,
      };
      Common_Event_Hit("DYNAMIC_EVENT", props);
    } else if (
      eventName === "live_orders_tap" ||
      eventName === "past_orders_tap" ||
      eventName === "past_tickets_tap"
    ) {
      let props = {
        eventName: eventName,
      };
      // when live orders is clicked
      Common_Event_Hit("DYNAMIC_EVENT", props);
    } else if (eventName === "sub_issue_tap") {
      let props = {
        eventName: eventName,
        issue_type: "General Issue",
      };
    }
  };

  //onMount
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("currentScreen", "report_issue_screen");
    getReportScreenData();
  }, []);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Container className="background-container" fluid>
        <Container fluid className="q-and-a-parent-container">
          <div
            className="nav-container"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <BiArrowBack size="1.5rem" color="#4b4495" />
            <span className="page-title">Report An Issue</span>
          </div>
        </Container>
        <Container fluid className="main-container">
          {shimmer && (
            <>
              <Card className="section-card animateShimmer">
                <ListGroup className="list-group animateShimmer">
                  {[...Array(3)].map((item, idx) => {
                    return (
                      <ListGroup.Item
                        key={idx}
                        className="list-group-item animateShimmer"
                      >
                        <span className="report-issue-text"></span>
                        <MdNavigateNext
                          color="#48D1CC"
                          size="1.5rem"
                          className="next-icon-report"
                        />
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Card>
              <Card className="section-card animateShimmer">
                <Card.Header className="general-issue-header ">
                  <img
                    src={walletIcon}
                    style={{
                      width: "1.2rem",
                      height: "1.2rem",
                      marginRight: "0.5rem",
                    }}
                    alt="wallet"
                  />
                  <span className="report-issue-text">General Issue</span>
                </Card.Header>
                <ListGroup className="list-group">
                  {[...Array(6)].map((item, idx) => {
                    return (
                      <ListGroup.Item
                        key={idx}
                        className="list-group-item-general animateShimmer"
                      >
                        <MdNavigateNext
                          color="#48D1CC"
                          size="1.5rem"
                          className="next-icon-report"
                        />
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Card>
            </>
          )}
          {!shimmer &&
            reportIssueScreenData &&
            reportIssueScreenData.map((section, index) => {
              return (
                <Card key={index} className="section-card">
                  <ListGroup className="list-group">
                    {section.data.length > 0 &&
                      section?.data.map((item, idx) => {
                        return (
                          <ListGroup.Item
                            key={idx}
                            className="list-group-item"
                            onClick={() => handleOrders(item.sectionName)}
                          >
                            <img
                              style={{
                                width: "1.2rem",
                                height: "1.2rem",
                                marginRight: "0.5rem",
                              }}
                              src={
                                item.iconName === "supportLiveOrders"
                                  ? supportLiveOrders
                                  : item.iconName === "supportPastOrders"
                                  ? supportPastOrders
                                  : walletIcon
                              }
                              alt="live order"
                            />
                            <span className="report-issue-text">
                              {item.sectionName}
                            </span>
                            <MdNavigateNext
                              color="#48D1CC"
                              size="1.5rem"
                              className="next-icon-report"
                            />
                          </ListGroup.Item>
                        );
                      })}
                  </ListGroup>
                </Card>
              );
            })}
          {!shimmer &&
            generalIssueData.map((item, _) => {
              return (
                <Card key={item.id} className="section-card">
                  <Card.Header className="general-issue-header">
                    <img
                      src={walletIcon}
                      style={{
                        width: "1.2rem",
                        height: "1.2rem",
                        marginRight: "0.5rem",
                      }}
                      alt="wallet"
                    />
                    <span className="report-issue-text">{item.issue}</span>
                  </Card.Header>
                  <ListGroup className="list-group">
                    {item.subIssue.map((subIssue, index) => {
                      return (
                        <ListGroup.Item
                          key={subIssue.id}
                          id={subIssue.id}
                          onClick={handleReportForm}
                          className="list-group-item-general"
                        >
                          {subIssue.subIssue}
                          <MdNavigateNext
                            color="#48D1CC"
                            size="1.5rem"
                            className="next-icon-report"
                          />
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Card>
              );
            })}
        </Container>
      </Container>
    </>
  );
}

export default ReportIssue;
