import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import "../../App.css";
import "./dialog.css";
import { Common_Event_Hit } from "../../Actions/Events";
import { HiOutlineChevronRight } from "react-icons/hi";
import { high_margin_substitute_products } from "../../Actions/Widgets";
import { usersData } from "../../App";
import CartGenericProduct from "../ProductCard/CartGenericProductCard";
import { FaChevronRight,FaChevronLeft } from "react-icons/fa";
import Like from "../../Images/svg/like_emoji.svg";

const CartGenericsModal = (props) => {
  const [data, setData] = useState([]);
  const { userData, setErrorHandler } = useContext(usersData);
  const [item, setItem] = useState(null);
  const getGenericsData = () => {
    let url = "/home/getHighMarginAlternative/productPage";
    let params = {
      medicineId: item?.medicineId,
    };
    high_margin_substitute_products(url, params)
      .then((res) => {
        let data = res?.data?.data?.medicinesResponsesList;
        setData(data);
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "account_screen",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            getGenericsData();
          },
        });
      });
  };

  const rightSroll = () => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "generics_cart_right_scroll",
      medicineId:item?.medicineId
    });
    let scrollStep = 330;
    let divId = "cart_generics_scroll";
    let scrollDiv = document.getElementById(divId);
    let sl = scrollDiv.scrollLeft;
    let cw = scrollDiv.scrollWidth;

    if (sl + scrollStep >= cw) {
      scrollDiv.scrollTo(cw, 0);
    } else {
      scrollDiv.scrollTo(sl + scrollStep, 0);
    }
  };

  const leftScroll = (e) => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "generics_cart_left_scroll",
      medicineId:item?.medicineId
    });
    let scrollStep = 330;
    let divId = "cart_generics_scroll";
    let scrollDiv = document.getElementById(divId);
    let sl = scrollDiv.scrollLeft;

    if (sl - scrollStep <= 0) {
      scrollDiv.scrollTo(0, 0);
    } else {
      scrollDiv.scrollTo(sl - scrollStep, 0);
    }
  };

  const openCompositionPage = (e) => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "generics_salt_composition_click",
      medicineId:item?.medicineId
    });
    window.open(
      `${window.location.origin}/product/composition?compId=${data[0]?.compositionId}&compHead=${data[0]?.composition}`
    );
    // navigate(`/product/composition?compId=${data[0]?.compositionId}&compHead=${data[0]?.composition}`)
  };

  const openHighMarginSubstitues = (e) => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "generics_see_all_click",
      medicineId:item?.medicineId
    });
    window.open(
      `${window.location.origin}/product/substitute?medId=${item?.medicineId}&medName=${item?.medicineName}`
    );
  };

  useEffect(() => {
    props.show && setItem(props.item);
  }, [props.show]);

  useEffect(() => {
    item && props.show && getGenericsData();
    item && props.show &&  Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "dialog_open",
      dialog_name: "generics_cart_popup_open",
      medicineId:item?.medicineId
    });
  }, [item]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="cart_generics_modal"
    >
      <div className="cart_generics_header">
        <div>
          <div className="font_12">HIGH MARGIN VARIANTS</div>
          <div className="font_14">
            <b>{item?.medicineName}</b>
          </div>
        </div>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="button-tooltip-2">See more</Tooltip>
          }
        >
          <div onClick={openHighMarginSubstitues} className="cursor_pointer">
            <HiOutlineChevronRight />
          </div>
        </OverlayTrigger>
      </div>
      {
        data[0]?.composition &&  <div className="cart_generics_comp">
        <div className="font_12">SALT COMPOSITION</div>
        {data?.length > 0 && (
          <div className="font_12 cursor_pointer" onClick={openCompositionPage}>
            {" "}
            <u>{data[0]?.composition}</u>{" "}
          </div>
        )}
      </div>
      }
     
      {!data.length && (
        <Spinner animation="border" className="cart_gn_spinner" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
     
      {data?.length > 0 && (
        <div className="cart_generics_list" id="cart_generics_scroll">
        {data?.length > 1 && (
            <div
              className="cart_gen_scroll_click_left elevation_3"
              onClick={leftScroll}
            >
              {" "}
              <FaChevronLeft />
            </div>
          )}
          {data.map((subMed, index) => {
            return (
              <CartGenericProduct
                key={index + "substituteMed_cart"}
                userData={userData}
                medData={subMed}
                position={index}
                screen="cart_screen"
                pageId="generic_cart_promotion_section"
              />
            );
          })}
         

          {data?.length > 1 && (
            <div
              className="cart_gen_scroll_click elevation_3"
              onClick={rightSroll}
            >
              {" "}
              <FaChevronRight />
            </div>
          )}

         
        </div>
      )}

      <div className="cart_generics_footer">
        <div style={{ marginLeft: "15px" }}>
          <img src={Like} alt="" />
        </div>
        <div className="cart_generics_footer_data">
          <div className="font_12">Has the same composition as</div>
          <div className="font_14">
            <b>{item?.medicineName}</b>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CartGenericsModal;
