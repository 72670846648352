import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import ProductCard2 from "../ProductCard/ProductCard2";
import "../../App.css";
import "../ProductList/ProductList.css";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import "./categories.css";
import Slider from "../Sliders/Slider";
import { refreshToken } from "../../Actions/Auth";
import { getScreen } from "../../Actions/Screens";
import { common_product_widget } from "../../Actions/Widgets";
import InfiniteScroll from "react-infinite-scroll-component";
import { getCatgoriesData } from "../../Actions/Categories";
import { BannerData, usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import { Common_Event_Hit } from "../../Actions/Events";
import BannerSlider from "../BannerRedirects/BannerSlider";

const CoronaEssentials = ({ getProductListHeader }) => {
  let navigate = useNavigate();

  const [widgetsData, setWidgetsData] = useState();
  const [categoryWidget, setCategoryWidget] = useState();
  const [bannerWidget, setBannerWidget] = useState();
  const [productListTrend, setProductListTrend] = useState();
  const [productListNew, setProductListNew] = useState();
  const [allProductList, setAllProductList] = useState();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [dataLength, setDataLength] = useState(0);
  const [skuData, setSkuData] = useState();
  const [screenShimmer, setScreenShimmer] = useState(false);
  const [productListNewShimmer, setProductListNewShimmer] = useState(false);
  const [productListTrendShimmer, setProductListTrendShimmer] = useState(false);
  const [bannerWidgetShimmer, setBannerWidgetShimmer] = useState(false);
  const [allProductShimmer, setAllProductShimmer] = useState(false);
  const [categoryShimmer, setCategoryShimmer] = useState(false);
  const [manufacturerShimmer, setManufacturerShimmer] = useState(false);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState(false);
  const [bannerImages, setBannerImages] = useState([]);

  const { setBannerData } = useContext(BannerData);
  const getAllProducts = () => {
    const params = {
      supergroupId: 11,
      mappingIds: [95, 96, 97, 98, 99, 100],
      filterCondition: "COVID_ESSENTIALS",
      page: page,
    };

    let api = common_product_widget(
      "home/getMedicinesBySupergroupGroup",
      params
    );
    page === 0 && setAllProductShimmer(true);
    api
      .then((res) => {
        let obj =
          allProductList && allProductList.medicinesResponsesList
            ? allProductList
            : { medicinesResponsesList: [] };
        if (res.data.data.medicinesResponsesList.length > 0) {
          obj.medicinesResponsesList.push(
            ...res.data.data.medicinesResponsesList
          );
          setDataLength(obj.medicinesResponsesList.length);
        }
        setAllProductList(obj);

        if (
          res.data.data.size > 0 &&
          res.data.data.medicinesResponsesList.length === 0 &&
          page !== 0
        ) {
          fetchMoreData();
        }

        if (
          page === 0 &&
          res.data.data.size > 0 &&
          res.data.data.medicinesResponsesList.length < 6
        ) {
          fetchMoreData();
        }

        if (res.data.data.size === 0) {
          setHasMore(false);
        }

        setAllProductShimmer(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getAllProducts();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getAllProducts();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getAllProducts();
            },
          });
        }
      });
  };

  const fetchMoreData = () => {
    setPage(+page + 1);
  };

  const getSkuCount = () => {
    getCatgoriesData("CORONA_SKU_COUNT", {})
      .then((res) => {
        setSkuData(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getSkuCount();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getSkuCount();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getSkuCount();
            },
          });
        }
      });
  };
  const homeBannerSlide = (event) => {
    localStorage.setItem(
      "currentScreenBannerData",
      JSON.stringify({ index: +event + 1, data: bannerWidget[event] })
    );
  };
  useEffect(() => {
    let userData = localStorage.getItem("userData");
    localStorage.setItem("currentScreen", "covid_essentials_screen");
    localStorage.removeItem("currentScreenBannerData");

    if (userData) {
      const params = {
        retailerId: JSON.parse(userData).id,
        screenName: "covid_essentials_screen",
      };
      setScreenShimmer(true);
      let schemes_screen = () =>
        getScreen("HOME_SCREEN", params, {})
          .then((res) => {
            setScreenShimmer(false);
            setWidgetsData(res.data.data);
            console.log(res.data.data);
            res.data.data.forEach((item) => {
              let getWidgetData = () => {
                let params;
                if (item.widgetName === "dynamic_category_widget") {
                  params = {};
                  setCategoryWidget(item);
                } else if (item.widgetName === "banner_widget") {
                  params = {
                    pageId: "covidEssentials",
                  };
                  setBannerWidgetShimmer(true);
                } else if (
                  item.widgetName === "product_list_widget" &&
                  item.data.pageId === "covidTrending"
                ) {
                  params = {
                    filterCondition: "popular",
                    page: 0,
                  };
                  setProductListTrendShimmer(true);
                } else if (
                  item.widgetName === "product_list_widget" &&
                  item.data.pageId === "covidNewlyAdded"
                ) {
                  params = {
                    filterCondition: "new",
                    page: 0,
                  };
                  setProductListNewShimmer(true);
                } else if (item.widgetName === "manufacturer_list_widget") {
                  params = {};
                  setManufacturerShimmer(true);
                }

                if (
                  item.widgetName !== "vertical_product_list_widget" &&
                  item.widgetName !== "dynamic_category_widget" &&
                  item.widgetName !== "category_widget"
                ) {
                  let api = common_product_widget(
                    item.data.apiEndPoint,
                    params
                  );
                  api
                    .then((res) => {
                      if (item.widgetName === "banner_widget") {
                        setBannerWidget(res.data.data);
                        setBannerWidgetShimmer(false);
                        localStorage.setItem(
                          "currentScreenBannerData",
                          JSON.stringify({ index: 1, data: res.data.data[0] })
                        );
                        let arr = [];
                        res.data.data.forEach((ele, i) => {
                          arr.push(
                            <img
                              onClick={async () => {
                                await Common_Event_Hit("DYNAMIC_EVENT", {
                                  eventName: "banner_tapped",
                                });
                                setBannerData(ele);
                                navigate("/bannerRedirect");
                              }}
                              className="banner_img"
                              src={ele.url}
                              alt={i}
                            />
                          );
                        });
                        setBannerImages(arr);
                      } else if (
                        item.widgetName === "product_list_widget" &&
                        item.data.pageId === "covidTrending"
                      ) {
                        setProductListTrend(res.data.data);
                        setProductListTrendShimmer(false);
                      } else if (
                        item.widgetName === "product_list_widget" &&
                        item.data.pageId === "covidNewlyAdded"
                      ) {
                        setProductListNew(res.data.data);
                        setProductListNewShimmer(false);
                      }
                    })
                    .catch((error) => {
                      console.log(error, item);
                      if (error?.response?.status === 401) {
                        refreshToken().then((res) => {
                          getWidgetData();
                        });
                      } else if (
                        error?.response?.status === 502 ||
                        error?.response?.status === 503
                      ) {
                        setShowMaintenance({
                          status: true,
                          apiData: error?.response,
                        });
                      } else if (error?.response?.status === 500) {
                        setErrorModal({
                          show: true,
                          apiData: error?.response,
                          content: "Oops something went wrong !",
                          tryAgain: function () {
                            getWidgetData();
                          },
                        });
                      } else {
                        setErrorModal({
                          show: true,
                          apiData: error?.response,
                          content: error?.response?.data?.error?.error
                            ? error.response.data.error.error
                            : "Something went wrong!",
                          tryAgain: function () {
                            getWidgetData();
                          },
                        });
                      }
                    });
                }
              };

              return getWidgetData();
            });
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshToken()
                .then((res) => {
                  schemes_screen();
                })
                .catch((error) => {
                  localStorage.removeItem("userData");
                  localStorage.removeItem("userAuth");
                  navigate("/login");
                });
            } else if (
              error?.response?.status === 502 ||
              error?.response?.status === 503 ||
              error?.response?.status === 504
            ) {
              setShowMaintenance({ status: true, apiData: error?.response });
            } else if (error?.response?.status === 500) {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: "Oops something went wrong !",
                tryAgain: function () {
                  schemes_screen();
                },
              });
            } else {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: error?.response?.data?.error?.error
                  ? error.response.data.error.error
                  : "Something went wrong!",
                tryAgain: function () {
                  schemes_screen();
                },
              });
            }
          });
      schemes_screen();
    } else {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    widgetsData && getSkuCount();
  }, [widgetsData]);

  useEffect(() => {
    getAllProducts();
  }, [page, widgetsData]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      {screenShimmer ? (
        <Container fluid className="productList">
          {[...Array(4)].map((_, index) => {
            return (
              <div className="skeletal_div animateShimmer" key={index}></div>
            );
          })}
        </Container>
      ) : (
        <Fragment>
          <Container fluid className="productList">
            <Container
              fluid
              className="productListHeader font_ubuntu display_flex"
            >
              {" "}
              <HiOutlineArrowNarrowLeft
                className="cursor_pointer goBack"
                onClick={() => navigate("/home")}
              />{" "}
              &nbsp;&nbsp;&nbsp;{" "}
              <div className="head_specialOffers">
                Corona Essentials
                {skuData && (
                  <span className="sub_head">
                    {skuData.companyCount} Companies • {skuData.skuCount} SKU'S
                  </span>
                )}
              </div>{" "}
            </Container>

            {widgetsData &&
              widgetsData.map((widget, i) => {
                if (widget.widgetName === "dynamic_category_widget") {
                  return (
                    <>
                      {categoryShimmer ? (
                        <Container>
                          <div className="skeletal_div animateShimmer"></div>
                        </Container>
                      ) : (
                        <>
                          {categoryWidget && (
                            <Container fluid className="categories_section">
                              {categoryWidget && (
                                <Fragment>
                                  <header className="font_ubuntu font_weight_md container">
                                    {categoryWidget.data.title}
                                  </header>

                                  <Container
                                    className="myAccount_list"
                                    style={{
                                      background:
                                        categoryWidget &&
                                        categoryWidget.data.backgroundColor,
                                    }}
                                  >
                                    {categoryWidget &&
                                      categoryWidget.data.sections.map(
                                        (cat, i) => {
                                          return (
                                            <div
                                              className="cursor_pointer"
                                              style={{ margin: "0% 3%" }}
                                              key={i}
                                            >
                                              <Link
                                                to={{
                                                  pathname: `/categories/coronaEssentials/${cat.pageId}`,
                                                }}
                                                className="text_decoration_none"
                                              >
                                                <div className="coronat_cat ">
                                                  <img
                                                    src={cat.imageUrl}
                                                    className="corona_cat_img"
                                                    alt={cat.sectionName}
                                                  />{" "}
                                                </div>
                                                {cat.tagName ? (
                                                  <span className="new_tag corona_cat_tag font_ubuntu">
                                                    {cat.tagName}
                                                  </span>
                                                ) : (
                                                  <br />
                                                )}
                                                <div className="txt_center cat_name">
                                                  {cat.sectionName}
                                                </div>
                                              </Link>
                                            </div>
                                          );
                                        }
                                      )}
                                  </Container>
                                </Fragment>
                              )}
                            </Container>
                          )}
                        </>
                      )}
                    </>
                  );
                } else if (widget.widgetName === "banner_widget") {
                  return (
                    <>
                      {bannerWidgetShimmer ? (
                        <Container>
                          <div className="skeletal_div animateShimmer"></div>
                        </Container>
                      ) : (
                        <>
                          {bannerWidget && bannerWidget.length > 0 && (
                            <Container>
                              <div className="banner_section">
                                <BannerSlider
                                  slides={bannerImages}
                                  slideChange={homeBannerSlide}
                                />
                              </div>
                            </Container>
                          )}
                        </>
                      )}
                    </>
                  );
                } else if (
                  widget.widgetName === "product_list_widget" &&
                  widget.data.pageId === "covidTrending"
                ) {
                  return (
                    <>
                      {productListTrendShimmer ? (
                        <Container>
                          <br></br>
                          <p className="font_18 font_weight_md font_ubuntu">
                            Trending Products
                          </p>
                          <Slider
                            screen={"covid_essentials_screen"}
                            shimmer={productListTrendShimmer}
                            footerColor={"#F4F6F8"}
                            id={"regularSchemePopular"}
                            darkColor={"#4B4495"}
                            lightColor={"#a49cf598"}
                          />
                        </Container>
                      ) : (
                        <>
                          {productListTrend &&
                            productListTrend.medicinesResponsesList.length >
                              0 && (
                              <Container>
                                <br></br>
                                <p
                                  className="font_18 font_weight_md font_ubuntu"
                                  style={{ color: widget.data.titleColor }}
                                >
                                  {widget.data.title}
                                </p>
                                <Slider
                                  screen={"covid_essentials_screen"}
                                  widgetData={widget}
                                  ProductRes={productListTrend}
                                  footerColor={"#F4F6F8"}
                                  id={"regularSchemePopular"}
                                  darkColor={"#4B4495"}
                                  lightColor={"#a49cf598"}
                                />
                              </Container>
                            )}
                        </>
                      )}
                    </>
                  );
                } else if (
                  widget.widgetName === "product_list_widget" &&
                  widget.data.pageId === "covidNewlyAdded"
                ) {
                  return (
                    <>
                      {productListNewShimmer ? (
                        <Container>
                          <br></br>
                          <p className="font_18 font_weight_md font_ubuntu">
                            New Products
                          </p>
                          <Slider
                            screen={"covid_essentials_screen"}
                            shimmer={productListNewShimmer}
                            footerColor={"#F4F6F8"}
                            id={"regularSchemePopular"}
                            darkColor={"#4B4495"}
                            lightColor={"#a49cf598"}
                          />
                        </Container>
                      ) : (
                        <>
                          {productListNew &&
                            productListNew.medicinesResponsesList.length >
                              0 && (
                              <Container>
                                <br></br>
                                <p
                                  className="font_18 font_weight_md font_ubuntu"
                                  style={{ color: widget.data.titleColor }}
                                >
                                  {widget.data.title}
                                </p>
                                <Slider
                                  screen={"covid_essentials_screen"}
                                  widgetData={widget}
                                  ProductRes={productListNew}
                                  footerColor={"#F4F6F8"}
                                  id={"regularSchemeNew"}
                                  darkColor={"#4B4495"}
                                  lightColor={"#a49cf598"}
                                />
                              </Container>
                            )}
                        </>
                      )}
                    </>
                  );
                } else if (
                  widget.widgetName === "vertical_product_list_widget"
                ) {
                  return (
                    <>
                      {allProductShimmer ? (
                        <Fragment>
                          <Container>
                            <br></br>

                            <p className="font_18 font_weight_md font_ubuntu">
                              {" "}
                              All Products
                            </p>
                          </Container>
                          <div id="scrollableDiv" className="productList">
                            <ProductCard2 shimmer={allProductShimmer} />{" "}
                            <ProductCard2 shimmer={allProductShimmer} />{" "}
                            <ProductCard2 shimmer={allProductShimmer} />{" "}
                            <ProductCard2 shimmer={allProductShimmer} />
                          </div>
                        </Fragment>
                      ) : (
                        allProductList &&
                        allProductList.medicinesResponsesList.length > 0 && (
                          <Fragment>
                            <Container>
                              <br></br>

                              <p
                                className="font_18 font_weight_md font_ubuntu"
                                style={{ color: widget.data.titleColor }}
                              >
                                {widget.data.title}
                              </p>
                            </Container>
                            <div
                              id="scrollableDiv"
                              className="productList"
                              style={{ paddingTop: "0rem" }}
                            >
                              <InfiniteScroll
                                dataLength={dataLength}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                scrollableTarget="scrollableDiv"
                                scrollThreshold="90%"
                                className="infiniteScroll"
                                loader={
                                  <Spinner
                                    animation="border"
                                    className="color_secondary spinner"
                                    size="lg"
                                  />
                                }
                              >
                                {allProductList &&
                                  allProductList.medicinesResponsesList.map(
                                    (med, index) => (
                                      <ProductCard2
                                        position={index}
                                        screen={"covid_essentials_screen"}
                                        key={index}
                                        medData={med}
                                      />
                                    )
                                  )}
                              </InfiniteScroll>
                            </div>
                          </Fragment>
                        )
                      )}
                    </>
                  );
                }
              })}
          </Container>
        </Fragment>
      )}
    </>
  );
};

export default React.memo(CoronaEssentials);
