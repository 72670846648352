import axios from "axios";
import { variables } from "../environments";

let config = {
  headers: {
    "Content-Type": "application/json",
    appVersionCode: variables.appVersionCode,
  },
};
const ADMIN_URL = variables.ADMIN_URL;
let onBehalfStatus = false;
const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);

  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config.headers.Authorization = "Bearer " + token;

  if (
    onBehalfRetCode &&
    onBehalfRetCode.length > 0 &&
    onBehalfRetCode !== "undefined" &&
    userData.admin
  ) {
    config.headers.adminRetailerCode = onBehalfRetCode;
    onBehalfStatus = true;
  }
  return true;
};

export const Digital_Payment_Actions = async (type, params, body) => {
  const widgets = getToken().then(() => {
    switch (type) {
      case "ACTIVE_PAYMENT_MODES":
        onBehalfStatus
          ? (config.headers.source = "pharmacyWebOnbehalf")
          : (config.headers.source = "pharmacyWeb");
        return axios.get(
          `${ADMIN_URL}/home/online-payment/activePaymentModes`,
          {
            params: params,
            headers: config.headers,
          }
        );

      case "INITIATE_PAYMENT":
        onBehalfStatus
          ? (config.headers.source = "pharmacyWebOnbehalf")
          : (config.headers.source = "pharmacyWeb");
        return axios.post(
          `${ADMIN_URL}/home/online-payment/initPayment`,
          body,
          {
            params: params,
            headers: config.headers,
          }
        );

      case "CANCEL_PAYMENT":
        onBehalfStatus
          ? (config.headers.source = "pharmacyWebOnbehalf")
          : (config.headers.source = "pharmacyWeb");
        return axios.post(
          `${ADMIN_URL}/home/online-payment/cancelPayment`,
          {},
          {
            params: params,
            headers: config.headers,
          }
        );

      case "PAYMENT_STATUS":
        onBehalfStatus
          ? (config.headers.source = "pharmacyWebOnbehalf")
          : (config.headers.source = "pharmacyWeb");
        return axios.get(`${ADMIN_URL}/home/online-payment/getPaymentStatus`, {
          params: params,
          headers: config.headers,
        });
    }
  });
  return widgets;
};
