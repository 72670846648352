import React, { useContext, useEffect, useState } from "react";
import { searchContext } from "../../../App";
import "./Timer.css";

function Timer({ timerEpoch }) {
  const [formattedTime, setFormattedTime] = useState("");
  const { showRecent, setShowRecent } = useContext(searchContext);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());



  //timer logic
  useEffect(() => {
    //disable the timer when search is active
    if (showRecent) return;
    let timer;
    if (currentTime < timerEpoch) {
      timer = setInterval(() => {
        setCurrentTime(new Date().getTime());
      }, 1000);
      function msToTime(s) {
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;

        if (mins < 10) {
          mins = "0" + mins;
        }
        if (secs < 10) {
          secs = "0" + secs;
        }
        if (hrs < 10) {
          hrs = "0" + hrs;
        }

        setFormattedTime(hrs + ":" + mins + ":" + secs);
      }
      msToTime(timerEpoch - currentTime);
    } else {
      return () => {
        clearInterval(timer);
      };
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [currentTime, timerEpoch, showRecent]);

  return (
    <>
      <div className="timer-container">
        <p>
          {formattedTime}
          <span>hrs left</span>
        </p>
      </div>
    </>
  );
}

export default React.memo(Timer);
