import { Spinner } from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import "../../App.css";
import "./dialog.css";
import "../ProductCard/ProductCard.css";
import { BiArrowBack, BiStopwatch } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { AiOutlineShareAlt, AiFillQuestionCircle } from "react-icons/ai";
import { getData } from "../../Actions/Product";
import { useLocation, useNavigate } from "react-router";
import { refreshToken } from "../../Actions/Auth";
import { cartDataContext, forceBackNavContext, usersData } from "../../App";
import { Cart_Actions } from "../../Actions/Cart";
import ErrorModal from "./ErrorModal";
import Thumb from "../../Images/svg/Thumb.svg";
import { Common_Event_Hit } from "../../Actions/Events";
import OrderMessageModal from "./orderMessageModal";
import "./ProductNew.css";
import ProductCard3 from "../ProductCard/ProductCard3";
import Slider4 from "../Sliders/Slider4";
import Slider5 from "../Sliders/Slider5";
import CartToolBar from "../Cart/CartToolBar";
import { high_margin_substitute_products } from "../../Actions/Widgets";

const Product = () => {
  const [pageAlreadyOpened, setAlreadyPageOpened] = useState(false);
  const [medData, setMedData] = useState();
  const [substitute, setSubstitute] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [updateQty, setUpdateQty] = useState(false);
  const [priceBreakup, setPriceBreakup] = useState(null);
  const [qtySpinner, setQtySpinner] = useState(false);
  const [priceData, setPriceData] = useState();
  const { setShowMaintenance } = useContext(usersData);
  const [userData, setUserData] = useState();
  const [shimmer, setShimmer] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [orderMessage, setOrderMessage] = useState({
    show: false,
    content: "",
    header: "",
  });
  const [timerProductTime, setTimerProductTime] = useState({
    endTime: 1,
    stopWatch: "00:00:00",
  });
  const [cartSpinner, setCartSpinner] = useState(false);
  const [maxStockError, setMaxStockError] = useState(false);
  const { cartData, setCartData } = useContext(cartDataContext);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { setForceBackNav } = useContext(forceBackNavContext);
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  const [showZoomImg, setShowZoomImg] = useState(false);
  const [isCartExist, setCartExist] = useState(false);
  let onHold = true;

  const inputCheck = (e) => {
    if (e.keyCode === 13) {
      e.stopPropagation();
      quantity > 0 &&
        !maxStockError &&
        !cartSpinner &&
        addToCart(quantity, medData.medicineId);
    }
  };

  const qtyChange = (e, medId) => {
    setQtySpinner(true);
    if (e.target.value && +e.target.value !== 0) {
      setAlreadyPageOpened(true);
      setUpdateQty(true);
      setQuantity(+e.target.value);
      setQtySpinner(false);
    } else {
      setAlreadyPageOpened(false);
      setPriceBreakup({
        netPrice: medData.effectivePrice,
        margin: medData.retailerMargin,
        ptr: medData.ptr,
        freeQuantity: medData.freeQuantity,
        subTotal: medData.subTotal,
        cd: medData.cashDiscount,
        gst: medData.gst,
      });
      setQuantity(e.target.value);
      setQtySpinner(false);
    }
  };

  let location = useLocation();
  let navigate = useNavigate();

  const getProductPrice = () => {
    const params = {
      medicineId: location.pathname.split("/")[2],
    };
    if (quantity === 0 || quantity > 0) {
      params.quantity = quantity ? quantity : 0;
    } else {
      params.quantity = 0;
    }
    getData("PRODUCTPRICE_BY_ID", params)
      .then((res) => {
        setPriceData(res.data.data);

        let schemeDisc = res.data.data?.breakup?.filter((ele) =>
          ele.key.includes("Scheme Discount")
        );

        let cashDisc = res.data.data?.breakup?.filter((ele) =>
          ele.key.includes("Cash Discount")
        );

        let gst = res.data.data?.breakup?.filter((ele) =>
          ele.key.includes("Gst")
        );

        let priceBkpObj = {
          netPrice: res.data.data.netPrice,
          margin: res.data.data.retailerMargin,
          ptr: res.data.data?.breakup?.filter((ele) => ele.key === "PTR")[0]
            .value,
          freeQuantity: res.data.data.freeQuantity,
          subTotal: res.data.data.subTotal,
        };

        if (cashDisc.length > 0) {
          priceBkpObj.cd = cashDisc[0].value;
        }

        if (gst.length > 0) {
          priceBkpObj.gst = gst[0].value;
        }

        if (schemeDisc.length > 0) {
          priceBkpObj.schemeDiscount = schemeDisc;
        }

        setPriceBreakup(priceBkpObj);

        if (
          quantity + res.data.data?.freeQuantity >
          medData?.availableQuantity
        ) {
          setMaxStockError(true);
        } else {
          setMaxStockError(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getProductPrice();
          });
        } else if (
          error?.response?.status >= 400 &&
          error?.response?.status < 500
        ) {
          setOrderMessage({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.data?.message,
            header: `Info`,
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance(true);
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getProductPrice();
            },
          });
        } else {
          setErrorModal({
            show: true,
            content: error?.response?.data?.message
              ? error.response.data?.message
              : "Something went wrong!",
            tryAgain: function () {
              getProductPrice();
            },
          });
        }
      });
  };

  const addToCart = async (qty, medId) => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "confirm_add_to_cart",
      medicineId: medId,
      medicineName: medData.name,
      price: medData.effectivePrice.toFixed(2),
      count: qty,
    });

    if (medData.deliverySlot) {
      setCartSpinner(true);
      const params = {};
      const body = {
        androidMap: {
          locale: "en",
          source: "pharmacyWeb",
          deviceId: null,
          appVersion: navigator.userAgent,
          deviceName: null,
          screen_name: 'product_screen',
          appVersionCode: "156",
        },
        medicineId: medId,
        quantity: qty,
        retailerCode: userData.retailerCode,
        retailerId: userData.id,
      };
      Cart_Actions("ADD_TO_CART", params, body)
        .then((res) => {
          setCartSpinner(false);
          setUpdateQty(false);
          setQuantity(qty);
          setCartData(res.data.data);
          setCartExist(true);
          setAlreadyPageOpened(true);
        })
        .catch((error) => {
          setCartSpinner(false);
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              addToCart(qty, medId);
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance(true);
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              content: "Oops something went wrong !",
              tryAgain: function () {
                addToCart(qty, medId);
              },
            });
          } else {
            setErrorModal({
              show: true,
              content: error?.response?.data.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                addToCart(qty, medId);
              },
            });
          }
        });
    } else {
      window.alert("You cannot add product to cart without Delivery Slot");
    }
  };

  // handler to force navigate from banner redirect
  const handleForceBackNav = () => {
    if (location?.state?.from === "/bannerRedirect") setForceBackNav(true);
    navigate(-1);
  };

  // intercept browser back button click with custom logic to avoid banner redirect
  const onPageBackEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (location?.state?.from === "/bannerRedirect") setForceBackNav(true);
    navigate(-1);
  };

  const handleImageHover = (el) => {
    setShowZoomImg(true);
    setPosX(
      ((el.clientX - el.target.getBoundingClientRect().left) / 200).toFixed(4) *
        100
    );
    setPosY(
      ((el.clientY - el.target.getBoundingClientRect().top) / 200).toFixed(4) *
        100
    );
  };

  const marginSubstitute = async () => {
    try {
      let res = await high_margin_substitute_products(
        "/home/getHighMarginAlternative/productPage",
        { medicineId: location.pathname.split("/")[2] }
      );
      let obj =
        substitute && substitute.medicinesResponsesList
          ? substitute
          : { medicinesResponsesList: [] };
      if (
        res.data.data.size > 0 &&
        res.data.data.medicinesResponsesList.length > 0
      ) {
        obj.medicinesResponsesList.push(
          ...res.data.data.medicinesResponsesList
        );
      }

      setSubstitute(obj.medicinesResponsesList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let data = localStorage.getItem("userData");
    localStorage.setItem("currentScreen", "product_screen");
    localStorage.removeItem("currentScreenBannerData");

    setShimmer(true);
    if (data && data.length) {
      setUserData(JSON.parse(data));
      const params = {
        medicineId: location.pathname.split("/")[2],
        dealType: null,
      };

      const getProduct = () => {
        getData("PRODUCT_BY_ID", params)
          .then((res) => {
            setShimmer(false);
            setMedData(res.data.data);
            setPriceBreakup({
              netPrice: res.data.data.effectivePrice,
              margin: res.data.data.retailerMargin,
              ptr: res.data.data.ptr,
              freeQuantity: res.data.data.freeQuantity,
              subTotal: res.data.data.subTotal,
              cd: res.data.data.cashDiscount,
              gst: res.data.data.gst,
            });
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshToken().then((res) => {
                getProduct();
              });
            } else if (
              error?.response?.status >= 400 &&
              error?.response?.status < 500
            ) {
              setOrderMessage({
                show: true,
                apiData: error?.response,
                content: error?.response?.data?.error?.error,
                header: `Info`,
              });
            } else if (
              error?.response?.status === 502 ||
              error?.response?.status === 503 ||
              error?.response?.status === 504
            ) {
              setShowMaintenance(true);
            } else if (error?.response?.status === 500) {
              setErrorModal({
                show: true,
                content: "Oops something went wrong !",
                tryAgain: function () {
                  getProduct();
                },
              });
            } else {
              setErrorModal({
                show: true,
                content: error?.response?.data.error?.error
                  ? error.response.data.error.error
                  : "Something went wrong!",
                tryAgain: function () {
                  getProduct();
                },
              });
            }
          });
      };

      getProduct();
    } else {
      navigate("/login");
    }
  }, [location]);

  useEffect(() => {
    if (pageAlreadyOpened) {
      getProductPrice();
    }
  }, [quantity]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (cartData && medData) {
      if (cartData.cartMedicineDetailResponseList) {
        let existingCartItem = cartData.cartMedicineDetailResponseList.filter(
          (item) => item.medicineId === +medData.medicineId
        );
        if (existingCartItem && existingCartItem.length) {
          setQuantity(existingCartItem[0].quantity);
        } else {
          if (medData.minOrderQuantity > 0) {
            setQuantity(medData.minOrderQuantity);
          } else if (medData.multiplyFactorForOrderQuantity > 0) {
            setQuantity(medData.multiplyFactorForOrderQuantity);
          } else {
            setQuantity(1);
          }
        }
      }
    } else if (medData) {
      if (medData.minOrderQuantity > 0) {
        setQuantity(medData.minOrderQuantity);
      } else if (medData.multiplyFactorForOrderQuantity > 0) {
        setQuantity(medData.multiplyFactorForOrderQuantity);
      } else {
        setQuantity(1);
      }
    }
  }, [cartData, medData]);

  useEffect(() => {
    if (medData?.dealEndTime) {
      let timer;
      if (currentTime < medData.dealEndTime) {
        timer = setInterval(() => {
          setCurrentTime(new Date().getTime());
        }, 1000);
        function msToTime(s) {
          var ms = s % 1000;
          s = (s - ms) / 1000;
          var secs = s % 60;
          s = (s - secs) / 60;
          var mins = s % 60;
          var hrs = (s - mins) / 60;
          if (hrs < 10) {
            hrs = "0" + hrs;
          }
          if (mins < 10) {
            mins = "0" + mins;
          }
          if (secs < 10) {
            secs = "0" + secs;
          }

          let obj = { ...timerProductTime };
          obj.stopWatch = hrs + ":" + mins + ":" + secs;
          setTimerProductTime(obj);
        }
        msToTime(medData.dealEndTime - currentTime);
      } else {
        return () => {
          clearInterval(timer);
        };
      }
    }
  }, [currentTime, medData]);

  useEffect(() => {
    marginSubstitute();
  }, [medData]);

  // onMount register a brower back button click listener
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onPageBackEvent);
    return () => {
      window.removeEventListener("popstate", onPageBackEvent);
    };
  }, []);
  return (
    <div>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />

      <OrderMessageModal
        show={orderMessage.show}
        content={orderMessage.content}
        header={orderMessage.header}
        onHide={() => {
          setOrderMessage({ show: false, content: "", header: "" });
          handleForceBackNav();
        }}
      />
      {shimmer && (
        <div className="productShimmer">
          <div className="productShimmer_subcntr" centered>
            <div className="productShimmer_top">
              <div className="productShimmer_img animateShimmer2"></div>
              <div>
                <div className="productShimmer_content animateShimmer2"></div>
                <div className="productShimmer_content animateShimmer2"></div>
                <div className="productShimmer_content animateShimmer2"></div>
              </div>
            </div>
            <div className="productShimmer_subdiv animateShimmer2"></div>
          </div>
        </div>
      )}

      {/* NEW DESIGN */}
      <div className="product_section_cont">
        {medData && (
          <section>
            <div className="l-section"></div>

            <div className="m-section">
              <p className="back-nav">
                <span onClick={handleForceBackNav}>
                  <BiArrowBack />
                </span>
                &nbsp;&nbsp;Home{" > "}
                <span>{medData.name}</span>
              </p>

              <div className="prod_cont bg_white border_rad_7">
                <div className="prod_data_top border_rad_7 p_16">
                  <div className="pd_t_l">
                    <div
                      className="pd_img"
                      onMouseMove={handleImageHover}
                      onMouseLeave={() => setShowZoomImg(false)}
                      style={{ cursor: medData?.medicineImage?.highResUrl ? 'zoom-in' : 'default' }}
                    >
                      <img
                        src={
                          medData?.medicineImage?.highResUrl
                            ? medData.medicineImage.highResUrl
                            : Thumb
                        }
                        alt="Medicine"
                      />
                    </div>

                    <div>
                      {!onHold && (
                        <div className="pd_sale">
                          <p>
                            <BiStopwatch /> 23:23:32 <span>hrs left</span>
                          </p>

                          <p>Go To Flash Sale</p>
                        </div>
                      )}

                      <div className="p_data">
                        <h4>{medData.name}</h4>
                        <p>
                          <span>{medData.packaging}</span>
                          {medData.manufacturer}
                        </p>
                      </div>
                    </div>
                  </div>

                  {medData?.medicineImage?.highResUrl && showZoomImg && (
                    <div className="pd_img_zoom">
                      <div
                        style={{
                          backgroundImage: `url(${
                            medData?.medicineImage?.highResUrl
                              ? medData.medicineImage.highResUrl
                              : Thumb
                          })`,
                          backgroundPosition: `${posX}% ${posY}%`,
                        }}
                      ></div>
                    </div>
                  )}

                  <div className="pd_t_r">
                    <div>
                      {medData.medicineSubSectionType && <p className="prod_SubSectionType">{medData.medicineSubSectionType}</p>}
                      {medData.dpco === 1 && <p>DPCO</p>}
                      {medData.narcotic === 1 && <p>NARCOTIC</p>}
                      <p>{medData.medicineCategory}</p>
                    </div>
                  </div>
                </div>

                <div className="prod_data_bottom p_16">
                  <div className="pd_b_l">
                    <div className="pd_pricing">
                      {medData.cashDiscountInPercentage ? (
                        <p className="pd_pricing_cd">
                          {medData.cashDiscountInPercentage}% <span>CD</span>
                        </p>
                      ) : (
                        <></>
                      )}
                      <p className="pd_pricing_ptr">
                        PTR <span>₹ {priceBreakup?.ptr.toFixed(2)}</span>
                      </p>

                      {/* Price Break-Up PopUp */}
                      <div>
                        <span>
                          SEE PRICE BREAKUP
                          <div className="price_breakup bg_white">
                            <p>
                              Net Price*
                              <span>₹ {priceBreakup?.netPrice}</span>
                            </p>
                            <p>
                              MRP<span>₹ {medData.mrp}</span>
                            </p>
                            <p className="price_breakup_margin">
                              Margin
                              <span>{Math.round(priceBreakup?.margin)}%</span>
                            </p>
                            <p>
                              PTR
                              <span>₹ {priceBreakup?.ptr.toFixed(2)}</span>
                            </p>

                            <span>
                              {priceBreakup?.schemeDiscount ? (
                                <p>
                                  {priceBreakup?.schemeDiscount[0]?.key}{" "}
                                  {priceBreakup?.schemeDiscount[0]?.asterisk
                                    ? " *"
                                    : ""}
                                  <span>
                                    ₹{" "}
                                    {priceBreakup?.schemeDiscount[0]?.value.toFixed(
                                      2
                                    )}
                                  </span>
                                </p>
                              ) : (
                                <></>
                              )}

                              {medData.cashDiscount ? (
                                <p className="pb_cd">
                                  Cash Discount (
                                  {medData?.cashDiscountInPercentage}%)
                                  <span>₹ {priceBreakup?.cd.toFixed(2)}</span>
                                </p>
                              ) : (
                                <></>
                              )}

                              <p className="pb_gst">
                                GST ({medData?.gstPercentage}%)
                                <span>₹ {priceBreakup?.gst.toFixed(2)}</span>
                              </p>
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>

                    <div className="pd_b_l_metadata mt_16">
                      <div>
                        <p>MRP</p> <span className="meta_colon">: </span>
                        <span>₹ {medData.mrp}</span>{" "}
                        <span className="pd_margin">
                          {Math.round(priceBreakup?.margin)}% Margin
                        </span>
                      </div>
                      <div>
                        <p>In-Stock</p> <span className="meta_colon">: </span>
                        <span className="pd_inStock">
                          {medData.availableQuantity}
                        </span>
                      </div>
                      <div>
                        <p>Delivery by</p>{" "}
                        <span className="meta_colon">: </span>
                        <span>
                          {medData.deliveryDate}, {medData.deliverySlot}
                        </span>
                      </div>
                      {medData.scheme.length > 0 && (
                        <div className="pd_schemes">
                          <p>Schemes</p> <span className="meta_colon">: </span>
                          {medData.scheme.map((schm, indx) => {
                            return (
                              <span
                                className="pd_schemes_span"
                                key={indx + "_scheme"}
                              >
                                {schm}
                              </span>
                            );
                          })}
                        </div>
                      )}
                      <div>
                        <p>Expiry</p> <span className="meta_colon">: </span>
                        <span>{medData.expiryDate}</span>
                      </div>
                      <div>
                        <p>Return</p> <span className="meta_colon">: </span>
                        <span>{medData.tags[0].message}</span>
                      </div>
                    </div>

                    {!onHold && (
                      <p className="hyperlink">
                        <span>
                          <AiOutlineShareAlt />
                        </span>
                        Share Details
                      </p>
                    )}
                  </div>

                  <div className="pd_b_r p_16">
                    <div>
                      <div className="pd_qty">
                        QTY:{" "}
                        <input
                          type="number"
                          onKeyDown={(e) => inputCheck(e, medData.medicineId)}
                          onChange={(e) => qtyChange(e)}
                          value={quantity}
                          autoFocus
                        />
                      </div>

                      {maxStockError && (
                        <p
                          className="pd_offer"
                          style={{
                            backgroundColor: "orangered",
                            color: "white",
                          }}
                        >
                          Maximum quantity should be{" "}
                          <b>{medData?.availableQuantity}</b>
                        </p>
                      )}

                      <p
                        className="pd_offer"
                        style={{
                          backgroundColor: `${priceData?.appMessageDto?.colorCode}`,
                          color: `${priceData?.appMessageDto?.textColor}`,
                        }}
                      >
                        {priceData?.appMessageDto?.appMessage}
                      </p>

                      <button
                        className={
                          cartSpinner || maxStockError
                            ? "animateShimmer add_cart p_16"
                            : "add_cart p_16"
                        }
                        onClick={() => {
                          quantity > 0 &&
                            !maxStockError &&
                            addToCart(quantity, medData.medicineId);
                        }}
                        disabled={(cartSpinner || maxStockError) && true}
                      >
                        <div>
                          {isCartExist && !updateQty
                            ? "ADDED "
                            : !isCartExist || !updateQty
                            ? "ADD "
                            : "UPDATE "}{" "}
                          TO CART
                          {qtySpinner ? (
                            <Spinner
                              animation="border"
                              className="color_secondary spinner"
                              size="sm"
                              style={{ marginLeft: "-2px" }}
                            />
                          ) : (
                            <p>
                              ₹ {priceBreakup?.subTotal}{" "}
                              <span>
                                {"(" + (quantity > 0 ? quantity : 0) + " ITEMS"}
                                {(priceBreakup?.freeQuantity ? " + " + priceBreakup?.freeQuantity + " FREE ITEMS)" : ")")}
                              </span>
                            </p>
                          )}
                        </div>

                        <div className="ac_r_arrow">
                          <BsArrowRight />
                        </div>
                      </button>
                    </div>

                    {!onHold && (
                      <p className="hyperlink">
                        <span>!</span>Issue with price?
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* Salt Composition */}
              {medData.composition && (
                <div className="salt_comp">
                  <h4>SALT COMPOSITION</h4>
                  <p onClick={() =>navigate(`/product/composition?compId=${medData.compositionId}&compHead=${medData.composition}`)}>
                    {medData.composition}
                  </p>
                </div>
              )}

              {/* High Margin Substitutes */}
              {substitute && substitute.length > 0 && (
                <div className="prod_substitutes border_rad_7 p_16">
                  <div className="prod_header">
                    <h6>
                      <span>High Margin Substitutes</span> FOR {medData.name}
                    </h6>
                    {substitute.length ? (
                      <p
                        onClick={() =>
                          navigate(
                            `/product/substitute?medId=${medData.medicineId}&medName=${medData.name}`
                          )
                        }
                      >
                        SEE ALL <span>{"▶︎"}</span>
                        {"▶︎"}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>

                  <p className="ps_info">
                    Drugs with same composition & strength
                  </p>

                  <div className="substitute_cntr">
                    {substitute.length > 0 &&
                      substitute?.map((subMed, index) => (
                        <ProductCard3
                          key={index + "substituteMed"}
                          userData={userData}
                          medData={subMed}
                          position={index}
                          screen="product_screen"
                          pageId="generic_promotion_section"
                        />
                      ))}
                  </div>
                </div>
              )}

              {/* Product Details */}
              {!onHold && (
                <div className="prod_details bg_white border_rad_7 p_16">
                  <h6>Product Details</h6>

                  <p>
                    <span>Therapy</span> <span>: </span>Miscellaneous
                  </p>
                  <p className="p_dtl_salt">
                    <span>Salt</span> <span>: </span>Miscellaneous
                  </p>
                  <p>
                    <span>Packaging</span> <span>: </span>Miscellaneous
                  </p>
                  <p>
                    <span>Usage</span> <span>: </span>Miscellaneous
                  </p>
                  <p>
                    <span>Dosage</span> <span>: </span>Miscellaneous
                  </p>

                  <h6>About the Manufacturer</h6>
                  <p>
                    Sed in magna a sem ornare sodales. Nunc venenatis lectus sed
                    lorem imperdiet, eu maximus lorem bibendum. Pendisse sit
                    amet orci ut magna pulvinar consequat vestibum sed in magna
                    a sem ornare sodales. Nunc venenatis lectus sed lorem
                    imperdiet, eu maximus lorem bibendum. Pendisse sit amet orci
                    ut magna pulvinar consequat vestibum …less
                  </p>

                  <div className="med_group_cont">
                    <h6>Medicine Group</h6>
                    <span className="med_group_query">
                      <AiFillQuestionCircle />

                      <div className="med_group_modal bg_white">
                        <p>
                          Medicine Group is a provider of locum and permanent
                          medicine doctors to the NHS, CCG’s, prison services,
                          out-of-hours services and private sector clients. We
                          have extensive opportunities across the UK through our
                          exclusive supplier agreements with national and
                          regional Trusts
                        </p>
                      </div>
                    </span>
                  </div>
                  <div className="med_group">
                    <p>BLOOD GLUCOSE MONITOR/STRIPS</p>
                    <p>HOME CARE & CLEANING</p>
                  </div>
                </div>
              )}

              {/* Same Medicine Family */}
              {!onHold && (
                <div className="med_family bg_white border_rad_7 p_16 mt_16">
                  <div className="prod_header">
                    <h6>Same Medicine Family</h6>
                    <p>
                      SEE ALL <span>{"▶︎"}</span>
                      {"▶︎"}
                    </p>
                  </div>

                  <Slider4
                    screen={"same_medicine_family"}
                    medData={medData}
                    footerColor={"#F4F6F8"}
                    id={"regularSchemePopular"}
                    darkColor={"#4B4495"}
                    lightColor={"#a49cf598"}
                  />
                </div>
              )}

              {/* Frequently Bought Together */}
              {!onHold && (
                <div className="freq_bought bg_white border_rad_7 p_16 mt_16">
                  <div className="prod_header">
                    <h6>Frequently bought Together</h6>
                    <p>
                      SEE ALL <span>{"▶︎"}</span>
                      {"▶︎"}
                    </p>
                  </div>

                  <Slider5
                    screen={"frequently_brought_together"}
                    medData={medData}
                    footerColor={"#F4F6F8"}
                    id={"regularSchemePopular"}
                    darkColor={"#4B4495"}
                    lightColor={"#a49cf598"}
                  />
                </div>
              )}
            </div>

            <div className="m-r-section"></div>

            {/* Right Cart */}
            <div className="r-section">
              <div className="r_cart_cont">
                <CartToolBar
                  setCartExist={setCartExist}
                  medId={location.pathname.split("/")[2]}
                />
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default React.memo(Product);
