import React, { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import Lottie from "react-lottie-player";
import WSDeleteSuccess from "../../../Images/json/WSDeleteItemSuccess.json";

function WSDeleteItemModal({ show, data, ...props }) {
  const [playAnimation, setPlayAnimation] = useState(false);

  //handler for confirm button
  const handleConfirm = () => {
    setPlayAnimation((prevVal) => !prevVal);
    // this setTimeout will be executed first
    setTimeout(() => {
      props.onHide();
      setPlayAnimation(false);
    }, [2200]);

    // this will be executed seconds
    setTimeout(() => {
      props.onConfirm();
    }, [2200]);
   
  };

  //reset the animation state on load
  useEffect(() => {
    setPlayAnimation(false);
    return () => {
      setPlayAnimation(false);
    };
  }, []);
  return (
    <>
      <Modal show={show} centered className="ws-delete-item-modal" {...props}>
        <Modal.Body>
          <div className="ws-delete-item-main-container flex-align-center-row">
            <Lottie
              loop={false}
              play={playAnimation}
              autoPlay={false}
              animationData={WSDeleteSuccess}
              style={{ width: "30%", height: "40%" }}
            />
          </div>
          <div className="ws-delete-item-message">
            Do you wish to delete{" "}
            <span style={{ color: "crimson", fontWeight: "bold" }}>
              {data.medicineName}
            </span>{" "}
            from your cart ?
          </div>
        </Modal.Body>
        <Modal.Footer className="flex-align-center-row ws-delete-item-footer">
          <button
            className="ws-delete-item-cancel"
            onClick={() => props.onHide()}
          >
            CANCEL
          </button>
          <button className="ws-delete-item-confirm" onClick={handleConfirm}>
            DELETE
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WSDeleteItemModal;
