import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import "./AddMedicineForm.css";
import { addExpiryItem, getMedicineSuggestions } from "../../Actions/Expiry";
import { refreshToken } from "../../Actions/Auth";
import { usersData } from "../../App";
import ErrorModal from "./ErrorModal";
import { FaSearch } from "react-icons/fa";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useDebounce from "../../CustomHooks/useDebounce";
import { MenuItem } from "@mui/material";
import ButtonLoading1 from "../Spinners/ButtonLoading1";
import OrderMessageModal from "./orderMessageModal";

function AddMedicineForm(props) {
  const FORMDATA = {
    medicineName: "",
    batchNumber: "",
    expiryDate: "",
    quantity: "",
    mrp: "",
  };

  const INVALID_FEEDBACK = "invalid input";

  const [formData, setFormData] = useState(() => FORMDATA);
  const [errorData, setErrorData] = useState(() => FORMDATA);
  const [recommendationData, setRecommendationData] = useState([]);
  const [searchQuery, setsearchQuery] = useState("");
  const { userData, setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [loading, setLoading] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [selectedMedicineId, setSelectedMedicineId] = useState(null);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    header: "",
  });

  //update search query state with debouncing
  const handleSearchData = (e) => {
    if (errorData.medicineName)
      setErrorData({ ...errorData, medicineName: "" });
    setFormData({ ...formData, medicineName: e.target.value });
    //update search query state with debounced logic
    debouncedSearch(e.target.value);
  };

  //debouncing logic
  const debouncedSearch = useDebounce(
    (newValue) => setsearchQuery(newValue),
    500
  );

  //update form data state
  const handleFormData = (key, val, id) => {
    console.log(key, val);
    if (key === "medicineName") {
      setSelectedMedicineId(id);
      setErrorData({ ...errorData, medicineName: "" });
      setFormData({ ...formData, medicineName: val }); //update the medicine name
      setRecommendationData([]); //reset recommmendations data on select option
    } else if (key === "batchNumber") {
      //update batch number state
      setFormData({ ...formData, batchNumber: val });
      setErrorData({ ...errorData, batchNumber: "" });
    } else if (key === "quantity") {
      //update quantity state
      setFormData({ ...formData, quantity: val });
      setErrorData({ ...errorData, quantity: "" });
    } else if (key === "mrp") {
      //update mrp state
      setFormData({ ...formData, mrp: val });
      setErrorData({ ...errorData, mrp: "" });
    }
  };

  //update expiry data state
  const handleExpiryDate = (newValue) => {
    setErrorData({ ...errorData, expiryDate: "" });
    // console.log(newValue);
    // console.log(newValue.getFullYear(), newValue.getMonth() + 1);
    let date = "";
    let month = newValue?.getMonth() + 1;
    let year = newValue?.getFullYear();
    month < 10
      ? (date += "0" + month.toString() + "/" + year.toString())
      : (date += month.toString() + "/" + year.toString());
    setFormData({ ...formData, expiryDate: date });
    setExpiryDate(newValue);
  };

  //get medicine recommendations and update the suggestion box
  const getMedicineRecommendations = async () => {
    let params = {
      searchString: searchQuery,
    };
    return getMedicineSuggestions(params)
      .then((res) => {
        setRecommendationData(res?.data?.data);
        console.log(res?.data?.data);
      })
      .catch((error) => {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getMedicineRecommendations();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance(true);
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            content: "Oops something went wrong!",
            tryAgain: function () {
              getMedicineRecommendations();
            },
          });
        } else {
          setErrorModal({
            show: true,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getMedicineRecommendations();
            },
          });
        }
      });
  };

  //check if the form contains valid entries
  const handleFormCheck = () => {
    let data = { ...errorData };
    Object.keys(formData).map((key, _) => {
      if (formData[key].length === 0) data[key] = true;
      else data[key] = false;
    });
    console.table({ data });
    //update error data state
    setErrorData(data);
    return Object.values(data).some((item) => item === true);
  };

  //final add medicine submit
  //also used to update the expiry item
  const handleAddMedicine = async () => {
    //guard check to avoid unwanted api calls
    if (handleFormCheck()) return;

    //handle loading state
    setLoading(true);
    //make the final api call
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);

    let body = {
      expiryCartRequestList: [
        {
          batchNumber: formData.batchNumber,
          expiryDate: formData.expiryDate,
          itemName: formData.medicineName,
          medicineId: selectedMedicineId,
          mrp: parseInt(formData.mrp).toFixed(1),
          quantity: formData.quantity,
        },
      ],
      retailerCode: data.retailerCode,
      retailerId: data.id,
      size: 1,
    };
    console.log({ body });
    try {
      const res = await addExpiryItem({}, body);
      console.log("posted add medicine response", res?.data?.data);
      props.setExpiryCartResponseList(res?.data?.data?.expiryCartResponseList);
      setLoading(false);
      //close the form modal
      props.onHide();
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          handleAddMedicine();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            handleAddMedicine();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            handleAddMedicine();
          },
        });
      }
    }
  };

  //get recommendations everytime medicineName changes on user input
  useEffect(() => {
    console.log("search query state update");
    //return if query string lenght < 3
    if (searchQuery?.length < 3) {
      setRecommendationData([]);
      return;
    }
    getMedicineRecommendations();
  }, [searchQuery]);

  //set the formData when coming from edit medicine
  useEffect(() => {
    if (props.editForm && props.expiryCartResponseList) {
      console.log(props.expiryCartResponseList);
      setFormData({
        ...formData,
        quantity: props.expiryCartResponseList?.quantity,
        mrp: props.expiryCartResponseList?.mrp,
        medicineName: props.expiryCartResponseList?.itemName,
        batchNumber: props.expiryCartResponseList?.batchNumber,
        expiryDate: props.expiryCartResponseList?.expiryDate,
      });
      setSelectedMedicineId(props.expiryCartResponseList?.medicineId);
    }
  }, [props.editForm && props.expiryCartResponseList]);

  //cleanup for the initial state so next time component mounts with clean state
  useEffect(() => {
    return () => {
      setFormData(FORMDATA);
      setExpiryDate(null);
      setErrorData(FORMDATA);
      setRecommendationData([]);
      setsearchQuery("");
    };
  }, [props.show]);

  return (
    <>
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />

      <Modal
        {...props}
        centered
        className="add-medicine-form-modal"
        contentClassName="add-medicine-form-content"
      >
        <Modal.Body>
          <div className="add-medicine-form-container flex-align-center-column">
            <TextField
              required
              disabled={props.editForm}
              value={formData.medicineName}
              name="medicineName"
              label={"Medicine Name"}
              margin="normal"
              fullWidth
              onChange={handleSearchData}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FaSearch color="#4B4495" />
                  </InputAdornment>
                ),
              }}
              error={errorData.medicineName}
              autoComplete="off"
              type="text"
            />

            <div
              className={
                recommendationData?.length > 0
                  ? "suggestion-container"
                  : "suggestion-container bg-hidden"
              }
            >
              {recommendationData &&
                recommendationData?.map((option, index) => {
                  return (
                    <MenuItem
                      key={option?.medicineId}
                      value={option?.itemName}
                      name={option?.itemName}
                      onClick={() =>
                        handleFormData(
                          "medicineName",
                          option.itemName,
                          option.medicineId
                        )
                      }
                    >
                      {option.itemName}
                    </MenuItem>
                  );
                })}
            </div>

            <TextField
              required
              disabled={props.editForm}
              name="batchNumber"
              label="Batch Number"
              value={formData.batchNumber}
              margin="normal"
              onChange={(e) => handleFormData("batchNumber", e.target.value)}
              fullWidth
              error={errorData.batchNumber}
              autoComplete="off"
            />

            {props.editForm ? (
              <TextField
                disabled
                value={props.expiryCartResponseList?.expiryDate}
                fullWidth
                margin="normal"
                label="Expiry Date"
              />
            ) : (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  autoComplete="off"
                  reduceAnimations="true"
                  openTo="month"
                  inputFormat="MM-yyyy"
                  views={["year", "month"]}
                  minDate={new Date("1970-01-01")}
                  maxDate={new Date("2028-01-01")}
                  name="expiryDate"
                  required
                  label="Expiry Date"
                  value={expiryDate}
                  onChange={handleExpiryDate}
                  error={errorData.expiryDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      margin="normal"
                      error={errorData.expiryDate}
                      onChange={() =>
                        setErrorData({ ...errorData, expiryDate: "" })
                      }
                      //helperText={errorData.expiryDate && INVALID_FEEDBACK}
                    />
                  )}
                />
              </LocalizationProvider>
            )}

            <TextField
              required
              name="quantity"
              label="Number Of Quantity"
              margin="normal"
              value={formData.quantity}
              onChange={(e) => handleFormData("quantity", e.target.value)}
              fullWidth
              error={errorData.quantity}
              autoComplete="off"
            />

            <TextField
              required
              name="mrp"
              label="MRP(in Rs)"
              margin="normal"
              value={formData.mrp}
              onChange={(e) => handleFormData("mrp", e.target.value)}
              fullWidth
              error={errorData.mrp}
              autoComplete="off"
            />

            <button
              type="submit"
              className="medicine-form-submit-button"
              onClick={handleAddMedicine}
            >
              {loading ? (
                <ButtonLoading1 />
              ) : props.editForm ? (
                "update medicine"
              ) : (
                "add medicine"
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddMedicineForm;
