import React, { useEffect } from "react";

function AddToCartButton({ moq, type }) {
  return (
    <div className="add-to-cart-button">
      {parseInt(moq) > 0 && (
        <div className="moq-badge">
          <p>{moq + ` `} MOQ</p>
        </div>
      )}
      <button type="submit" style={{ marginTop: type === 'margin' ? '1.1rem' : ''}}>Add to Cart</button>
    </div>
  );
}

export default AddToCartButton;
