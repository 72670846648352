import React, {
  useState,
  Fragment,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  Container,
  InputGroup,
  FormControl,
  Button,
  Spinner,
} from "react-bootstrap";
import "../Onboard/Login.css";
import "../../App.css";
import Saveo from "../../Images/png/login_saveo_logo.png";
import Store from "../../Images/svg/store.svg";
import Onboard from "../../Images/svg/Onboarding.svg";
import { SignUp, getOTP, verifyOTP, checkAuth } from "../../Actions/Auth";
import { userStatus, usersData, userObjectContext } from "../../App";
import { useNavigate } from "react-router";
import ErrorModal from "../Modals/ErrorModal";
import "./Onboarding.css";
import { getOnboardingData } from "../../Actions/Onboarding";
import { Common_Event_Hit } from "../../Actions/Events";
import { Moengage } from "../../environments";

const Login = () => {
  const OTP = {
    val1: "",
    val2: "",
    val3: "",
    val4: "",
    val5: "",
    val6: "",
  };

  const { userObject, setUserObject } = useContext(userObjectContext);

  const navigate = useNavigate();
  const [loginOTP, setLoginOTP] = useState({
    status: false,
    value: null,
    expiryTime: 0,
  });
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(OTP);
  const { userLoggedIn, setUserLoggedIn, setIsUserEnabled } =
    React.useContext(userStatus);
  const { setShowMaintenance, setUserData } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [setShowOtpInputField, setSetShowOtpInputField] = useState(false);
  const [singUpSpinner, setSignUpSpinner] = useState();
  const loginOtpRef = useRef();
  // loginOtpRef.current = loginOTP

  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();
  const inputRef6 = useRef();

  const arr = [
    inputRef1,
    inputRef2,
    inputRef3,
    inputRef4,
    inputRef5,
    inputRef6,
  ];

  //input field refs
  const mobileNumberInputRef = useRef();
  const passwordInputRef = useRef();

  //on key down handler for the mobile number input field
  const handleTabNavigation = (e) => {
    // console.log("tab navigation hit from the login screen");
    //handle on key down for the input field
    //mac -> tab -> keycode : 9
    if (
      e.keyCode === 9 &&
      document.activeElement === mobileNumberInputRef.current
    ) {
      //orevent event bubbling when ref is focussed
      e.preventDefault();
      e.stopPropagation();
      //only focus if the length of the number is equal to 10 digits
      if (e.target.value.length === 10) {
        passwordInputRef.current.focus();
        passwordInputRef.current.select();
      } else if (e.target.value.length < 10) {
        //handle error handshake when the number is less than 10 charater\s
      }
    }
  };

  useEffect(() => {
    mobileNo.length === 10
      ? setShowPasswordField(true)
      : setShowPasswordField(false);
  }, [mobileNo]);

  //converts the otp expiry time from getOTP Api to seconds and updates the corresponding state
  useEffect(() => {
    // console.log('loginOTP',loginOTP)
    const time = Math.floor(loginOTP.expiryTime / 1000);
    let min = Math.floor(time / 60);
    let sec = Math.floor(time % 60);
    setMinutes(min);
    setSeconds(sec);
  }, [loginOTP]);

  // otp timer logic using setInterval
  useEffect(() => {
    const OTPTimer = setInterval(() => {
      if (seconds > 0) setSeconds((prevSeconds) => prevSeconds - 1);
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(OTPTimer);
          setLoginOTP({ ...loginOTP, expiryTime: 0 });
        } else {
          setMinutes((prevMin) => prevMin - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(OTPTimer);
    };
  });

  const mobileInput = (e) => {
    let val = e.target.value;
    val = val.toString();
    if (val.length > 10) {
      val = val.substr(0, 10);
    }
    setMobileNo(val);
  };
  const passInput = (e) => {
    if (e.keyCode === 13) {
      userSignUp();
    }
    setPassword(e.target.value);
  };
  const otpInput = (e) => {
    setLoginOTP({ status: true, value: e.target.value, expiryTime: 0 });
  };

  const setFocus = (inp) => {
    inp.current.focus();
  };

  const handleOtpNew = (e) => {
    if (e.keyCode === 13) {
      userSignUp();
    }
    let maxlen = 1;
    const { value, id } = e.target;
    if (value.length > 1) {
      let x = 0;
      for (let i = +id; i < 6; i++) {
        let temp = value.substr(x, 1);
        arr[i].current.value = temp;
        setFocus(arr[i]);
        x++;
      }
    }
    if (e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37) {
      if (+id > 0) {
        setFocus(arr[+id - 1]);
      }
    } else {
      if (arr.length - 1 > +id) {
        setFocus(arr[+id + 1]);
      }
    }
    constructOtp();
  };

  const handleOtp = (e) => {
    const { value, name, id } = e.target;
    if (value.length === 1) setOtp({ ...otp, [name]: value });
    if (value.length === 1) {
      if (id === "6") return;
      arr[id].current.focus();
    } else if (value.length < 1) {
      if (id === "1") {
        setOtp({ ...otp, [name]: "" });
      }
      setOtp({ ...otp, [name]: "" });
      arr[id - 2].current.focus();
    } else if (value.length > 1) {
      let temp = value.split("");
      temp.map((val, index) => {
        arr[index].current.focus();
        arr[index].current.value = val;
      });
    }
  };

  useEffect(() => {
    let arr = Object.values(otp).some((val) => val === "");
    console.log("arr", arr);
  }, [otp]);

  const getotp = () => {
    const params = {
      mobileNo: mobileNo,
    };
    getOTP(params)
      .then((res) => {
        // console.log(res)
        setLoginOTP({
          status: true,
          value: null,
          expiryTime: res.data.data.otpExpirationTime,
        });
        setSetShowOtpInputField(true);
      })
      .catch((error) => {
        console.log(error);
        if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getotp();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getotp();
            },
          });
        }
      });
  };

  const constructOtp = () => {
    let str1 = "";
    arr.map((ref, index) => {
      if (ref.current) {
        str1 += ref.current.value;
      }
    });
    console.log("str1", str1);
    loginOtpRef.current = str1;
  };

  //get userObject and update the local storage and context
  const getUserObject = async () => {
    setSignUpSpinner(true);
    try {
      const res = await getOnboardingData("getUserData", {});
      console.log("res from verify otp api userObject", res?.data?.data);
      localStorage.setItem("userObject", JSON.stringify(res?.data?.data));
      //update the userObject context state
      setUserObject(res?.data?.data);
    } catch (error) {
      console.log(error);
      setSignUpSpinner(false);
      if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance({ status: true, apiData: error?.response });
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: "Oops something went wrong !",
          tryAgain: function () {
            getUserObject();
          },
          onHide: function () {
            localStorage.removeItem("userData");
            localStorage.removeItem("userAuth");
            // navigate('/login')

            window.location.reload();
          },
        });
      } else {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getUserObject();
          },
        });
      }
    }
  };

  const userSignUp = async () => {
    constructOtp();
    setSignUpSpinner(true);
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "signup_tap",
      mobileNo: mobileNo,
      screen_name: localStorage.getItem("currentScreen"),
    });
    if (loginOTP.status) {
      const params = {
        mobileNo: mobileNo,
        OTP: loginOtpRef.current,
      };
      await verifyOTP(params)
        .then((res) => {
          console.log("res from verify otp api userAuth", res);
          localStorage.setItem("userAuth", JSON.stringify(res.data));
          localStorage.setItem(
            "isPasswordSet",
            JSON.stringify(res.data.data.isPasswordSet)
          );
          //updates the userObject in the local storag
          getUserObject();
          const body = {
            mobileNumber: mobileNo,
          };
          checkAuth(body)
            .then(async (res) => {
              localStorage.setItem(
                "userData",
                JSON.stringify(res.data.data.user)
              );
              // save empty notification data into the local storage
              localStorage.setItem("notificationData", JSON.stringify([]));
              setUserData(res.data.data.user);
              setIsUserEnabled(res.data.data.user.enabled);
              setUserLoggedIn(true);
              setSignUpSpinner(false);
              Common_Event_Hit("DYNAMIC_EVENT", {
                eventName: "authenticated",
                screen_name: localStorage.getItem("currentScreen"),
              });
              let sessionCount = localStorage.getItem("sessionCount");
              localStorage.setItem("sessionCount", +sessionCount + 1);
              if (
                res?.data?.data?.user?.businessType &&
                (res.data.data.user.enabled ||
                  res.data.data.user.businessType[0] === "PHARMACY")
              ) {
                // update the user attributes for moengage
                let userData = res.data.data.user;
                Moengage.add_first_name(userData?.vshopName || userData?.shopName);
                Moengage.add_email(userData?.vemailId || userData?.emailId);
                Moengage.add_mobile(userData?.vmobile || userData?.mobileNo);
                Moengage.add_unique_user_id(userData?.vmobile || userData?.mobileNo);
                navigate("/home");
              } else {
                if (
                  res.data.data.user.enabled &&
                  res.data.data.user.businessType[0] !== "PHARMACY"
                )
                  navigate("/wholesale/home");
                else navigate("/onboarding");
              }
            })
            .catch((error) => {
              console.log(error);
              setSignUpSpinner(false);
              if (
                error?.response?.status === 502 ||
                error?.response?.status === 503 ||
                error?.response?.status === 504
              ) {
                setShowMaintenance({ status: true, apiData: error?.response });
              } else if (error?.response?.status === 500) {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: "Oops something went wrong !",
                  tryAgain: function () {
                    userSignUp();
                  },
                });
              } else {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: error?.response?.data.error?.error
                    ? error.response.data.error.error
                    : "Something went wrong!",
                  tryAgain: function () {
                    userSignUp();
                  },
                });
              }
            });
        })
        .catch((error) => {
          console.log(error);
          setSignUpSpinner(false);
          if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
              tryAgain: function () {
                userSignUp();
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data.error?.localizedMessage
                ? error.response.data.error.localizedMessage
                : "Something went wrong!",
              tryAgain: function () {
                userSignUp();
              },
            });
          }
        });
    } else {
      const params = {
        mobileNo: mobileNo,
        password: password,
      };
      await SignUp(params)
        .then(async (res) => {
          localStorage.setItem("userAuth", JSON.stringify(res.data));
          localStorage.setItem(
            "isPasswordSet",
            JSON.stringify(res.data.data.isPasswordSet)
          );

          //update the userObject in local storage
          getUserObject();

          const body = {
            mobileNumber: mobileNo,
          };
          checkAuth(body).then(async (res) => {
            localStorage.setItem(
              "userData",
              JSON.stringify(res.data.data.user)
            );
            // save empty notification data into the local storage
            localStorage.setItem("notificationData", JSON.stringify([]));

            // update the user attributes for moengage
            let userData = res.data.data.user;
            setUserData(res.data.data.user);
            Moengage.add_first_name(userData?.vshopName || userData?.shopName);
            Moengage.add_email(userData?.vemailId || userData?.emailId);
            Moengage.add_mobile(userData?.vmobile || userData?.mobileNo);
            Moengage.add_unique_user_id(userData?.vmobile || userData?.mobileNo);
            // console.log('from signup' , res.data.data.user.enabled)
            setUserLoggedIn(true);
            // get userObject - pharmacy when redirects from cart needs access to userObject
            // getOnboardingData("getUserData", {})
            //   .then((res) => {
            //     localStorage.setItem(
            //       "userObject",
            //       JSON.stringify(res.data.data)
            //     );
            //     setUserObject(res.data.data);
            //   })
            //   .catch((error) => {
            //     setSignUpSpinner(false);
            //     if (
            //       error?.response?.status == 502 ||
            //       error?.response?.status == 503 ||
            //       error?.response?.status == 504
            //     ) {
            //       setShowMaintenance({
            //         status: true,
            //         apiData: error?.response,
            //       });
            //     } else if (error?.response?.status == 500) {
            //       setErrorModal({
            //         show: true,
            //         apiData: error?.response,
            //         content: "Oops something went wrong !",
            //         tryAgain: function () {
            //           userSignUp();
            //         },
            //       });
            //     } else {
            //       setErrorModal({
            //         show: true,
            //         apiData: error?.response,
            //         content: error?.response?.data.error?.localizedMessage
            //           ? error.response.data.error.localizedMessage
            //           : "Something went wrong!",
            //         tryAgain: function () {
            //           userSignUp();
            //         },
            //       });
            //     }
            //   });

            Common_Event_Hit("DYNAMIC_EVENT", {
              eventName: "authenticated",
              screen_name: localStorage.getItem("currentScreen"),
            });
            let sessionCount = localStorage.getItem("sessionCount");
            localStorage.setItem("sessionCount", +sessionCount + 1);
            if (
              res?.data?.data?.user?.enabled ||
              (res?.data?.data?.user?.businessType &&
                res?.data?.data?.user?.businessType[0] === "PHARMACY")
            ) {
              navigate("/home");
            } else {
              //update the userObject in the localStorage
              getUserObject();
              navigate("/onboarding");
            }
          });
        })
        .catch((error) => {
          setSignUpSpinner(false);
          if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
              tryAgain: function () {
                userSignUp();
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data.error?.localizedMessage
                ? error.response.data.error.localizedMessage
                : "Something went wrong!",
              tryAgain: function () {
                userSignUp();
              },
            });
          }
        });
    }
  };

  useEffect(() => {
    let authData = localStorage.getItem("userAuth");
    localStorage.setItem("currentScreen", "login_screen");
    localStorage.removeItem("currentScreenBannerData");
    let userData = localStorage.getItem("userData");
    let parsedData = JSON.parse(userData);
    if (
      authData &&
      JSON.parse(authData).data.jwtToken &&
      userData &&
      "businessType" in parsedData
    ) {
      setUserLoggedIn(true);
    } else {
      setUserLoggedIn(false);
    }

    if (userData && userData.length && "businessType" in parsedData) {
      navigate("/home");
    }
  }, []);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          errorModal?.onHide && errorModal?.onHide();
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      {!userLoggedIn && (
        <Container fluid className="login">
          <Container fluid className="parentDiv_login">
            <div className="logo-container">
              <img src={Saveo} className="saveoLogo" alt="saveoLogo" />
            </div>
            <div className="login_section_2">
              <img src={Store} className="storeLogo" alt="storeLogo" />
              <img src={Onboard} className="storeLogo2" alt="storeLogo2" />
              <Container className="login_section">
                <div className="inputs_div">
                  <p className="co0lor_secondary font_weight_md">
                    Enter Phone Number
                  </p>

                  <InputGroup className="input_phone">
                    <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
                    <FormControl
                      autoFocus
                      placeholder="10 Digit Phone Number"
                      aria-label="Phone NUMBER"
                      aria-describedby="basic-addon1"
                      type="number"
                      onChange={mobileInput}
                      onKeyDown={handleTabNavigation}
                      // max = '10'
                      size={10}
                      maxLength={10}
                      // maxLength = '10'
                      className="padding_10"
                      value={mobileNo}
                      ref={mobileNumberInputRef}
                    />
                  </InputGroup>
                  {!loginOTP.status && (
                    <Fragment>
                      <InputGroup className="input_phone">
                        <FormControl
                          placeholder="Enter Password"
                          aria-label="Password"
                          aria-describedby="basic-addon1"
                          type="password"
                          onKeyUp={passInput}
                          className="padding_10"
                          disabled={!showPasswordField}
                          ref={passwordInputRef}
                        />
                      </InputGroup>
                      {/* {minutes === 0 && seconds === 0 ? null : <p>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>} */}
                      <div className="pass-link-wrapper">
                        <p
                          onClick={getotp}
                          className={
                            showPasswordField
                              ? "pass_link"
                              : "pass_link disabled"
                          }
                        >
                          Login with OTP
                        </p>
                      </div>
                    </Fragment>
                  )}
                  {loginOTP.status && (
                    <Fragment>
                      {/* add multiple input fields below */}
                      <div className="otp-inputs-container">
                        {[...Array(6)].map((_, index) => {
                          return (
                            <InputGroup>
                              <FormControl
                                autoFocus={index === 0}
                                ref={arr[index]}
                                id={`${index}`}
                                name={`val${index + 1}`}
                                // placeholder="Enter OTP"
                                aria-label="OTP"
                                // aria-describedby="basic-addon1"
                                type="number"
                                // max='1'
                                maxLength={1}
                                onKeyUp={handleOtpNew}
                                className="padding_10 zip-item"
                              />
                            </InputGroup>
                          );
                        })}
                      </div>
                      {/* <InputGroup className="input_phone">
                        <FormControl
                          placeholder="Enter OTP"
                          aria-label="OTP"
                          aria-describedby="basic-addon1"
                          type="number"
                          maxLength={6}
                          onKeyUp={otpInput}
                          className="padding_10"
                        />
                      </InputGroup> */}
                      <div className="otp-timer-container">
                        <p className="otp_timer">
                          {minutes === 0 && seconds === 0 ? (
                            <p className="resend-otp" onClick={getotp}>
                              Resend OTP
                            </p>
                          ) : (
                            <p className="resend-otp-disabled">
                              Resend OTP in{" "}
                              <span
                                style={{
                                  color: "rgb(73, 88, 170)",
                                  fontSize: "14px",
                                }}
                              >
                                0{minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                              </span>
                            </p>
                          )}
                        </p>
                        <p
                          onClick={() =>
                            setLoginOTP({
                              status: false,
                              value: null,
                              expiryTime: 0,
                            })
                          }
                          className="otp_link"
                        >
                          Login with Password
                        </p>
                      </div>
                    </Fragment>
                  )}
                  <br></br>
                  <Button
                    className="bg_secondary button border_none "
                    onClick={userSignUp}
                    disabled={
                      (mobileNo?.length < 10 && password?.length === 0) ||
                      (mobileNo?.length < 10 &&
                        loginOtpRef.current?.length < 6) ||
                      loginOtpRef?.current === null
                    }
                  >
                    {singUpSpinner ? (
                      <Spinner
                        animation="border"
                        className="color_primary spinner"
                        size="sm"
                        style={{
                          margin: "0px auto",
                        }}
                      />
                    ) : (
                      "SignUp"
                    )}
                  </Button>
                </div>
              </Container>
            </div>
          </Container>
        </Container>
      )}
      <></>
    </>
  );
};

export default Login;
