import React, { useState, useContext, useRef } from "react";
import {
  Container,
  Form,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import "./Onboarding.css";
import "./OnboardingScreen4.css";
import "./OnboardingScreen3.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import {
  AiFillCloseCircle,
  AiOutlineCamera,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { ImCircleUp } from "react-icons/im";
import { useEffect } from "react";
import { getOnboardingData, setOnboardingData } from "../../Actions/Onboarding";
import { refreshToken } from "../../Actions/Auth";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import { IoIosMail, IoMdCall } from "react-icons/io";
import OrderMessageModal from "../Modals/orderMessageModal";
import MapModal from "../Modals/MapModal";
import { Common_Event_Hit } from "../../Actions/Events";
import ButtonLoading1 from "../Spinners/ButtonLoading1";
import EVENTS from "./OnboardingEvents";

function OnboardingScreen4({
  handlePrev,
  handleNext,
  selectBusinessTypeProp,
  setAllowForceBackNav,
}) {
  const { userData, setShowMaintenance } = useContext(usersData);
  const { mobileNo, zip } = userData;
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    header: "",
  });
  const [businessDocResponse, setBusinessDocResponse] = useState(null);
  const [showBusinessDocScreen, setShowBusinessDocScreen] = useState(false);
  //sets business documents screen on mount
  const [businessType, setBusinessType] = useState("");
  const [address, setAddress] = useState({});

  const BusinessDetails = {
    businessName: "",
    ownersName: "",
    email: "",
  };

  const StoreLocation = {
    line1: "",
    line2: "",
    landmark: "",
    pincode: "",
    city: "",
    retailerType: "",
  };

  const BusinessDocuments = {
    drugLicence1: null,
    drugLicence2: null,
    drugLicence3: null,
    sheduleXLicence1: null,
    scheduleXLicence2: null,
    scheduleXLicence3: null,
    gstNumber1: null,
    gstNumber2: null,
    gstNumber3: null,
    panCard: null,
    bankAccountDetails: null,
  };
  const [activeId, setActiveId] = useState("0");
  const [businessDetails, setBusinessDetails] = useState(BusinessDetails);
  const [storeLocation, setStoreLocation] = useState(StoreLocation);
  const [businessDocuments, setBusinessDocuments] = useState(BusinessDocuments);
  const [currentStep, setCurrentStep] = useState(null);
  const [sendVerificationCheck, setSendVerificationCheck] = useState(false);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [showScheduleX, setShowScheduleX] = useState(false);
  const [uploadedFileLinks, setUploadedFileLinks] = useState(BusinessDocuments);
  const uploadedFileLinksRef = useRef();
  const [imageURL, setImageURL] = useState(BusinessDocuments);
  const [showSpinner, setShowSpinner] = useState(false);
  const [businessDetailsCheck, setBusinessDetailsCheck] = useState(false);
  const [storeLocationCheck, setStoreLocationCheck] = useState(false);
  const [landmarkCheck, setLandmarkCheck] = useState(false);
  const [businessDocumentsCheck, setBusinessDocumentsCheck] = useState({
    drugLicence: null,
    gstNumber: null,
    panCard: null,
  });
  const [finalCheck, setFinalCheck] = useState(false);
  const [businessDetailsErrorData, setbusinessDetailsErrorData] =
    useState(BusinessDetails);
  const [storeLocationErrorData, setStoreLocationErrorData] =
    useState(StoreLocation);
  const [showMapModal, setShowMapModal] = useState(false);
  const [finalLatLng, setFinalLatLng] = useState({ lat: null, lng: null });
  const [textOnStart, setTextOnStart] = useState({ value: "", startTime: "" });
  const [submitAddressClick, setSubmitAddressClick] = useState(false);
  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const [agreementError, setAgreementError] = useState(false);
  const [locationFetchInProgress, setLocationFetchInProgress] = useState(false);
  const [geocodeShimmer, setGeocodeShimmer] = useState(false);

  const checkObjValues = (obj) => {
    return Object.values(obj).every((item) => item.length > 0);
  };

  const checkStoreLocationObject = (obj) => {
    let verified = true;
    Object.keys(obj).forEach((item, _) => {
      //exclude landmark form the check
      if (item === "landmark") return;
      //retailerType check
      if (
        item === "retailerType" &&
        (obj[item] === "select store category" || obj[item].length === 0)
      )
        verified = false;
      //proceed the check for other keys
      if (item !== "retailerType" && obj[item]?.length === 0) verified = false;
    });
    return verified;
  };

  const checkBusinessDocObj = (obj) => {
    return Object.values(obj).some((item) => item === null);
  };

  //contains event call logic + state update
  const toggleActive = (id, eventName) => {
    console.log("toggle active hit", id);
    //if eventName, make the respective screen tap event call
    if (eventName) {
      console.log("inside tap event");
      let screen_name;
      if (id === "0") {
        screen_name = "basic_details_screen";
      } else if (id === "1") {
        screen_name = "pharmacy_details_screen";
      } else if (id === "2") {
        screen_name = "kyc_details_screen";
      }
      callEventsApi(eventName, screen_name);
    }

    //handle screen close before the active state update
    //activeId holds the id from previous state update
    // useful to call screen_close event tap
    if (eventName) {
      console.log("inside screen_close event");
      let screen_name;
      if (activeId === "0") {
        screen_name = "basic_details_screen";
      } else if (activeId === "1") {
        screen_name = "pharmacy_details_screen";
      } else if (activeId === "2") {
        screen_name = "kyc_details_screen";
      }
      //make the screen close event call based on the current existing state
      callEventsApi("screen_close", screen_name);
    }

    //update the state post close screen call
    //enforce restrictions on active id update
    //donot let the user move to the next step when previous step is disabled
    let userObject = localStorage.getItem("userObject");
    userObject = JSON.parse(userObject);

    if (+id > +activeId) {
      if (activeId === "0" && !checkBusinessDetails()) {
        //check if the user has actually submitted the details to the api by checking localStorage userObject
        let { businessName, ownerName, emailId } = userObject;
        if (businessName && ownerName && emailId) setActiveId(id);
      } else if (activeId === "1" && !checkStoreLocation()) {
        //check if the user has actually submitted the details to the api by checking localStorage userObject
        let { addressLine1, addressLine2, city, pincode } = userObject;
        if (addressLine1 && addressLine2 && city && pincode) setActiveId(id);
      } else if (activeId === "2") {
        if (
          businessDocuments.drugLicence1 &&
          businessDocuments.gstNumber1 &&
          businessDocuments.panCard
        ) {
          setActiveId(id);
        }
      }
    } else {
      setActiveId(id);
      if (id === "0" && !checkBusinessDetails()) setBusinessDetailsCheck(true);
      else if (id === "1" && !checkStoreLocation()) setStoreLocationCheck(true);
    }
  };

  //handler to update business details state
  const handleBusinessDetails = (e) => {
    setBusinessDetails({ ...businessDetails, [e.target.name]: e.target.value });
    // console.log(businessDetails);
  };

  //handler to update store location state
  const handleStoreLocation = (e) => {
    setStoreLocation({ ...storeLocation, [e.target.name]: e.target.value });
  };

  //handler to inout files
  const handleFileInput = (e) => {
    console.log(e.target.files[0]);
    console.log(e.target.files[0].size);
    console.log(e.target.files[0].type);
    console.log(
      !(
        e.target.files[0].type.includes("jpeg") ||
        e.target.files[0].type.includes("jpg") ||
        e.target.files[0].type.includes("png")
      )
    );
    if (e.target.files[0].size >= 700 * 1024) {
      setInfoMessage({
        show: true,
        content: "file size cannot be greater than 700KB",
        header: "File Size Exceeds Limit",
      });
      e.target.value = "";
      return;
    } else if (
      !(
        e.target.files[0].type.includes("jpeg") ||
        e.target.files[0].type.includes("jpg") ||
        e.target.files[0].type.includes("png")
      )
    ) {
      setInfoMessage({
        show: true,
        content: "file type should be jpeg, jpg or png",
        header: "Invalid File Type",
      });
      e.target.value = "";
      return;
    }

    setBusinessDocuments({
      ...businessDocuments,
      [e.target.name]: e.target.files[0],
    });
    setImageURL({
      ...imageURL,
      [e.target.name]: URL.createObjectURL(e.target.files[0]),
    });
    e.target.value = "";
  };

  const removeFileInput = (key) => {
    setBusinessDocuments({
      ...businessDocuments,
      [key]: null,
    });
    setImageURL({
      ...imageURL,
      [key]: null,
    });
  };

  //enforces restrictions on post business details
  const checkBusinessDetails = () => {
    let data = JSON.parse(JSON.stringify(businessDetails));
    let flag = false;
    if (businessDetails.businessName.length > 0) {
      data.businessName = false;
    } else {
      data.businessName = true;
      flag = true;
    }

    if (businessDetails.ownersName.length > 0) {
      data.ownersName = false;
    } else {
      data.ownersName = true;
      flag = true;
    }

    let emailRegex =
      /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    console.log(businessDetails.email);
    console.log("email test", emailRegex.test(businessDetails.email));
    if (emailRegex.test(businessDetails.email)) {
      console.log("email true");
      data.email = false;
    } else {
      console.log("email false");
      data.email = true;
      flag = true;
    }
    setbusinessDetailsErrorData(data);
    return flag;
  };

  //API call to post business details.
  const postBusinessDetails = () => {
    //donot hit the api if post is already in process
    if (showSpinner) return;

    callEventsApi("submit_tap", "business_details_screen");
    if (!checkBusinessDetails()) {
      setShowSpinner(true);
      const params = {
        businessName: businessDetails.businessName,
        ownerName: businessDetails.ownersName,
        emailId: businessDetails.email,
      };
      setOnboardingData("setBusinessDetails", params, {})
        .then((res) => {
          console.log(res);
          setShowSpinner(false);
          //current step state update is handled in a useEffect below
          // setCurrentStep(2);
          //update user object in local storage
          localStorage.setItem("userObject", JSON.stringify(res?.data?.data));
          toggleActive("1");
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              postBusinessDetails();
            });
          } else if (
            error.response.status === 502 ||
            error.response.status === 503
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error.response.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong!",
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data?.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
            });
          }
        });
    }
  };

  //enforces restrictions on posting store location
  const checkStoreLocation = () => {
    let flag = false;
    let data = JSON.parse(JSON.stringify(storeLocation));
    if (storeLocation.line1.length > 0) {
      data.line1 = false;
    } else {
      data.line1 = true;
      flag = true;
    }

    if (storeLocation.line2.length > 0) {
      data.line2 = false;
    } else {
      data.line2 = true;
      flag = true;
    }

    if (storeLocation.pincode.length === 6) {
      data.pincode = false;
    } else {
      data.pincode = true;
      flag = true;
    }

    if (storeLocation.city.length > 0) {
      data.city = false;
    } else {
      data.city = true;
      flag = true;
    }

    if (
      storeLocation.retailerType === "select store location" ||
      storeLocation.retailerType.length === 0
    ) {
      data.retailerType = true;
      flag = true;
    } else {
      data.retailerType = false;
    }

    setStoreLocationErrorData(data);

    if (
      storeLocation.landmark.length === 0 ||
      storeLocation.landmark.length > 0
    ) {
      setLandmarkCheck(true);
    }
    return flag;
  };

  //api to post store location
  const postStoreLocation = () => {
    //donot hit the api if post is already in process
    if (showSpinner) return;
    callEventsApi("submit_tap", "pharmacy_details_screen");
    if (!checkStoreLocation()) {
      setShowSpinner(true);
      const body = {
        addressLine1: storeLocation.line1,
        addressLine2: storeLocation.line2,
        city: storeLocation.city,
        landmark: storeLocation.landmark,
        latitude: coordinates.lat || 0,
        longitude: coordinates.lng || 0,
        pincode: storeLocation.pincode,
        retailerType: storeLocation.retailerType || "pharmacy",
      };
      setOnboardingData("setStoreLocation", {}, body)
        .then((res) => {
          console.log(res);
          setShowSpinner(false);
          //current step state update is handled in a useEffect below
          // setCurrentStep(3);
          //update user object in local storage
          localStorage.setItem("userObject", JSON.stringify(res?.data?.data));
          toggleActive("2");
        })
        .catch((error) => {
          // console.error(error)
          setShowSpinner(false);
          console.error(error);
          if (error?.response?.status === 401) {
            refreshToken().then((res) => {
              postStoreLocation();
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error.response.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong!",
              tryAgain: function () {
                postStoreLocation();
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data?.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                postStoreLocation();
              },
            });
          }
        });
    }
  };

  //enforce restriction on post business documents
  const checkBusinessDocuments = () => {
    let data = JSON.parse(JSON.stringify(businessDocumentsCheck));
    let flag = false;
    console.log(
      "businessDocuments.drugLicence1",
      businessDocuments.drugLicence1 ||
        businessDocuments.drugLicence2 ||
        businessDocuments.drugLicence3
    );
    if (
      businessDocuments.drugLicence1 !== null ||
      businessDocuments.drugLicence2 !== null ||
      businessDocuments.drugLicence3 !== null
    ) {
      data.drugLicence = true;
      flag = true;
    } else {
      data.drugLicence = false;
    }

    if (
      businessDocuments.gstNumber1 !== null ||
      businessDocuments.gstNumber2 !== null ||
      businessDocuments.gstNumber3 !== null
    ) {
      data.gstNumber = true;
      flag = true;
    } else {
      data.gstNumber = false;
      flag = false;
    }

    if (businessDocuments.panCard !== null) {
      data.panCard = true;
      flag = true;
    } else {
      data.panCard = false;
    }
    setBusinessDocumentsCheck(data);
    // setFinalCheck(flag)
    return flag;
  };

  //api to post business documents
  const postBusinessDocuments = async () => {
    console.log("inside postBusinessDocuments");
    //get userData object
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);

    //get userObject
    let userObject = localStorage.getItem("userObject");
    userObject = JSON.parse(userObject);

    if (finalCheck) {
      console.log(
        "updatedLinksRef before setting params",
        uploadedFileLinksRef.current
      );

      //delete the unnecessary keys
      Object.keys(uploadedFileLinksRef.current).forEach((key) => {
        uploadedFileLinksRef.current[key] === "" &&
          delete uploadedFileLinksRef.current[key];
      });

      const scheduleHList = [
        uploadedFileLinksRef.current.drugLicence1,
        // uploadedFileLinksRef.current.drugLicence2,
        // uploadedFileLinksRef.current.drugLicence3,
      ];
      const scheduleXList = [
        uploadedFileLinksRef.current.sheduleXLicence1,
        // uploadedFileLinksRef.current.scheduleXLicence2,
        // uploadedFileLinksRef.current.scheduleXLicence3,
      ];
      const gstList = [
        uploadedFileLinksRef.current.gstNumber1,
        // uploadedFileLinksRef.current.gstNumber2,
        // uploadedFileLinksRef.current.gstNumber3,
      ];

      const params = {
        scheduleHList: scheduleHList,
        scheduleXList: scheduleXList,
        gstList: gstList,
        panImage: uploadedFileLinksRef.current.panCard,
        bankAccountImage: uploadedFileLinksRef.current.bankAccountDetails,
        mobileNo: mobileNo,
        pincode: userObject?.pincode,
      };

      //delete unnecessary keys from params if null or ''
      Object.keys(params).forEach((key) => {
        (params[key] === "" || params[key] === null) && delete params[key];
      });

      console.log("params", params);
      console.log("scheduleHList", scheduleHList);
      // setShowSpinner(true);
      return setOnboardingData("setBusinessDocuments", params, {})
        .then((res) => {
          setShowSpinner(false);
          setBusinessDocResponse(res?.data?.data);
          const body = {
            mobileNumber: mobileNo,
          };
          getOnboardingData("getUserData", {}).then((res) => {
            localStorage.setItem("userObject", JSON.stringify(res.data.data));
          });
          handleNext();
        })
        .catch((error) => {
          // console.error(error)
          setShowSpinner(false);
          console.error(error);
          if (error?.response?.status === 401) {
            refreshToken().then((res) => {
              postBusinessDocuments();
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "oops something went wrong!",
              tryAgain: function () {
                postBusinessDocuments();
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                postBusinessDocuments();
              },
            });
          }
        });
    }
  };

  //get coordinates api call to update the map on load
  const getLocation = () => {
    //add_location_tap event api call
    callEventsApi("add_location_tap", "location_pointer_screen");

    console.log("location clicked");

    //get the coords from the user and update the respective state
    if (navigator.geolocation) {
      //update location fetch
      setLocationFetchInProgress(true);
      //geolocation options
      let options = {
        maximumAge: Infinity,
        enableHighAccuracy: true,
      };
      //actual coordinates fetch
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position.coords.latitude, position.coords.longitude);
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          //update the fetching location state
          setLocationFetchInProgress(false);
        },
        (error) => {
          if (error?.code === 1) {
            console.log(error?.message);
            setLocationFetchInProgress(false);
          }
        },
        options
      );
    } else {
      console.log("location is not supported");
    }

    setShowMapModal(true);
  };

  const fileUpload = async (item, key) => {
    let body = new FormData();
    body.append("file", item);
    //update the loading state
    // setShowSpinner(true);
    return setOnboardingData("uploadsSupportImage", {}, body)
      .then((res) => {
        setUploadedFileLinks((prevUpdatedFileLinks) => ({
          ...prevUpdatedFileLinks,
          [key]: res?.data?.data,
        }));
        //update the loading state
        // setShowSpinner(false);
      })
      .catch((error) => {
        //update the loading state in case any error
        // setShowSpinner(false);
        console.log(error.response);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            fileUpload();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong!",
            tryAgain: function () {
              fileUpload();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              fileUpload();
            },
          });
        }
      });
  };

  const handleFileUpload = async () => {
    for (let key in businessDocuments) {
      // console.log(key)
      if (!businessDocuments[key] && !uploadedFileLinks[key]) {
        setUploadedFileLinks((prevUpdatedFileLinks) => ({
          ...prevUpdatedFileLinks,
          [key]: "",
        }));
      } else {
        const res = await fileUpload(businessDocuments[key], key);
        console.log("fileUpload", res);
        // setUploadedFileLinks({...uploadedFileLinks , [key] : res.toString()})
      }
    }
    console.log("fileLinks", uploadedFileLinks);
  };

  //handler to upload business documents and post the uploaded links back into the API
  const postAllData = async () => {
    callEventsApi("submit_tap", "kyc_details_screen");

    //donot proceed if the agreement is not accepted
    if (!agreementAccepted) {
      setAgreementError(true);
      return;
    }

    //donot hit the api if post is already in process
    if (showSpinner) return;

    //update the spinner state
    setShowSpinner(true);

    try {
      await handleFileUpload();
    } catch (error) {
      //update the spinner state
      setShowSpinner(false);
      console.log("error from upload image", error);
    }
    try {
      await postBusinessDocuments();
    } catch (error) {
      //update the spinner state
      setShowSpinner(false);
      console.error("error from postBusinessDocuments", error);
    }
  };

  //events handler accross the component
  const callEventsApi = (eventName, screen_name) => {
    //common props for all the events
    let properties = {
      eventName: eventName,
      screen_name: screen_name,
    };
    if (!(coordinates.lat === 0 && coordinates.lng === 0)) {
      properties.latitude = coordinates.lat;
      properties.longitude = coordinates.lng;
    }
    //screen open event call
    if (eventName === "screen_open") {
      console.log(eventName + "event props", properties);
      Common_Event_Hit("DYNAMIC_EVENT", properties);
    } else if (eventName === "screen_close") {
      //screen close event call
      console.log("screen_close event props", properties);
      Common_Event_Hit("DYNAMIC_EVENT", properties);
    } else if (eventName === "submit_tap") {
      //submit tap event call
      console.log(eventName + "event props", properties);
      Common_Event_Hit("DYNAMIC_EVENT", properties);
    } else if (eventName === "basic_details_tap") {
      properties.expanded = true;
      console.log(eventName + "event props", properties);
      Common_Event_Hit("DYNAMIC_EVENT", properties);
    }
    //basic details tap event call
    else if (eventName === "pharmacy_details_tap") {
      //pharmacy details tap event call
      properties.expanded = true;
      console.log(eventName + "event props", properties);
      Common_Event_Hit("DYNAMIC_EVENT", properties);
    } else if (eventName === "kyc_details_tap") {
      //kys details tap event call
      properties.expanded = true;
      console.log(eventName + "event props", properties);
      Common_Event_Hit("DYNAMIC_EVENT", properties);
    } else if (eventName === "add_location_tap") {
      //add location tap event call
      console.log(eventName + "event props", properties);
      Common_Event_Hit("DYNAMIC_EVENT", properties);
    } else {
      console.log(eventName + "event props", properties);
      Common_Event_Hit("DYNAMIC_EVENT", properties);
    }
  };

  //force allow back nav when some logic in prev screen auto pushes to next screen
  const handleForceBackNav = () => {
    setAllowForceBackNav(true);
    handlePrev();
  };

  //change text-on-start state
  const handleOnFocus = (e) => {
    console.log("handle on focus hit");
    setTextOnStart(e.target.value);
  };

  const handleOnBlur = (e) => {
    console.log("handle on blur hit");
    let eventName = "";
    if (e.target.name === "businessName") {
      eventName = EVENTS.entered_business_shop_name;
      //trim the values
      setBusinessDetails({
        ...businessDetails,
        [e.target.name]: e.target.value.trim(),
      });
    } else if (e.target.name === "ownersName") {
      //trim the values
      setBusinessDetails({
        ...businessDetails,
        [e.target.name]: e.target.value.trim(),
      });
      eventName = EVENTS.entered_owner_name;
    } else if (e.target.name === "email") {
      eventName = EVENTS.entered_business_email_id;
      //trim the values
      setBusinessDetails({
        ...businessDetails,
        [e.target.name]: e.target.value.trim(),
      });
    }
  };

  //onMount -> update the screen names
  useEffect(() => {
    // callEventsApi("screen_open","basic_details_screen");
    let screen_name;
    //set current screen name
    if (activeId === "0") {
      screen_name = "basic_details_screen";
    } else if (activeId === "1") {
      screen_name = "pharmacy_details_screen";
    } else if (activeId === "2") {
      screen_name = "kyc_details_screen";
    }
    // make the events api call
    callEventsApi("screen_open", screen_name);
  }, []);

  //initial useEffect logic to navigate screens and set the form data if already pre-filled
  useEffect(() => {
    let userObject = localStorage.getItem("userObject");
    userObject = JSON.parse(userObject);
    console.log("userObject DOCSCREEN", userObject);
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);

    //check if the form has been touched previously and navigate to the necessary step
    if (userData && userObject) {
      // console.log('ownerName', userObject)
      let businessDetailsData = {
        businessName: userObject?.businessName || "",
        ownersName: userObject?.ownerName || "",
        email: userObject?.emailId || "",
      };
      //check if all values exist and then move to step 2
      console.log(
        "checkObjValues(businessDetailsData",
        checkObjValues(businessDetailsData)
      );
      if (checkObjValues(businessDetailsData)) {
        // setActiveId("1");
        let storeLocationData = {
          line1: userObject?.addressLine1 || "",
          line2: userObject?.addressLine2 || "",
          landmark: userObject?.landmark || "",
          pincode: userObject?.pincode || "",
          city: userObject?.city || "",
          retailerType: "",
        };

        setStoreLocation(storeLocationData);

        if (checkStoreLocationObject(storeLocationData)) {
          setActiveId("2");
        } else {
          setActiveId("1");
          //donot proceed to checking the business documents when store location details are absent
          // return;
        }
        setLandmarkCheck(true);
        setBusinessDetails(businessDetailsData);
      } else {
        console.log("active id change to 0");
        setActiveId("0");
        //do not proceed to checking the store location details when business details is not complete
        // return;
      }
    }

    if (userObject && userData) {
      console.log("userObject from DocScreen", userObject);
      const { scheduleHUrls, gstUrls, panUrl } = userObject;

      const businessType = userObject.businessType || userData.businessType;

      console.log("businessType[0]", businessType);
      setBusinessType(businessType);
      //if pharmacy check for only DL
      if (businessType[0].toLowerCase() === "pharmacy") {
        if (scheduleHUrls?.length === 0) {
          setShowBusinessDocScreen(true);
        } else if (!scheduleHUrls) {
          setShowBusinessDocScreen(true);
        } else {
          setShowBusinessDocScreen(false);
          handleNext();
        }
      } else if (businessType[0].toLowerCase() !== "pharmacy") {
        if (
          scheduleHUrls?.length === 0 &&
          gstUrls?.length === 0 &&
          panUrl === null
        ) {
          setShowBusinessDocScreen(true);
        } else if (!scheduleHUrls || !gstUrls || !panUrl) {
          setShowBusinessDocScreen(true);
        } else {
          setShowBusinessDocScreen(false);
          handleNext();
        }
      }
    }
  }, []);

  //update address details when the map reverse address lookup returns a valid address
  useEffect(() => {
    console.log("address from OS4", address);
    if (address.address_components) {
      let data = { ...storeLocation };
      let size = address?.address_components.length;
      let addressArray = address?.address_components;
      //update the pincode in the form
      data.pincode = addressArray[size - 1]?.long_name;
      //update the city in the form
      data.city = addressArray[size - 5]?.long_name;
      let addLine1 = "";
      let addLine2 = "";
      let formattedAddressArr = address?.formatted_address
        .toString()
        .split(",");
      console.log("formatted address araay", formattedAddressArr);
      formattedAddressArr.map((item, index) => {
        let formattedAddressArrSize = formattedAddressArr.length - 3;
        if (index < formattedAddressArrSize) {
          if (index < Math.floor(formattedAddressArrSize / 2)) {
            addLine1 += formattedAddressArr[index] + ", ";
          } else {
            addLine2 += formattedAddressArr[index] + ", ";
          }
        }
      });
      console.log("addLine1, addLine2", addLine1, addLine2);
      //trim the last additional comma
      addLine1 = addLine1.slice(0, addLine1.length - 2);
      addLine2 = addLine2.slice(0, addLine2.length - 2);
      //update line1 and line2 data in the form
      data.line1 = addLine1;
      data.line2 = addLine2;

      console.log("data before updating location state", data);

      setStoreLocation(data);
    }
  }, [address]);

  //uodate the current step everytime active id changes
  useEffect(() => {
    console.log(activeId, "current step update");
    setCurrentStep(+activeId + 1);
  }, [activeId]);

  useEffect(() => {
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);

    //pharmacy -> dl, gst , pan are mandatory
    if (userData?.businessType[0].toLowerCase() !== "pharmacy") {
      if (
        (businessDocuments.drugLicence1 ||
          businessDocuments.drugLicence2 ||
          businessDocuments.drugLicence3) &&
        (businessDocuments.gstNumber1 ||
          businessDocuments.gstNumber2 ||
          businessDocuments.gstNumber3) &&
        businessDocuments.panCard
      ) {
        setFinalCheck(true);
      } else {
        setFinalCheck(false);
      }
    } else if (userData?.businessType[0].toLowerCase() === "pharmacy") {
      if (
        businessDocuments.drugLicence1 ||
        businessDocuments.drugLicence2 ||
        businessDocuments.drugLicence3
      ) {
        setFinalCheck(true);
      } else {
        setFinalCheck(false);
      }
    }
  }, [businessDocuments]);

  //update the submit state everytime state changes
  useEffect(() => {
    // console.log({ businessDetails, storeLocation, businessDetailsCheck });
    if (currentStep === 1) {
      setbusinessDetailsErrorData(BusinessDetails);
      if (checkObjValues(businessDetails)) {
        setBusinessDetailsCheck(true);
      } else {
        setBusinessDetailsCheck(false);
      }
    } else if (currentStep === 2) {
      console.log(
        "checkObjValues(storeLocation)",
        checkStoreLocationObject(storeLocation),
        storeLocation
      );
      if (checkStoreLocationObject(storeLocation)) {
        setStoreLocationCheck(true);
      } else {
        //there may be a case where other fields can also be empty
        setStoreLocationCheck(false);
      }
    } else if (currentStep === 3) {
      if (checkBusinessDocObj(businessDocumentsCheck)) {
        // setFinalCheck(false)
      } else {
        // setFinalCheck(true)
      }
    }
  }, [businessDetails, storeLocation, businessDocumentsCheck]);

  //logic to enable final send verification button
  useEffect(() => {
    if (checkObjValues(businessDetails)) {
      if (checkObjValues(storeLocation)) {
        if (
          businessDocuments.drugLicence1 &&
          businessDocuments.gstNumber1 &&
          businessDocuments.panCard
        ) {
          setSendVerificationCheck(true);
        } else {
          // setCurrentStep(3);
          setSendVerificationCheck(false);
        }
      } else {
        // setCurrentStep(2);
        setSendVerificationCheck(false);
      }
    } else {
      // setCurrentStep(1);
      setSendVerificationCheck(false);
    }
  }, [businessDetails, storeLocation, businessDocuments]);

  //update ref onChange
  useEffect(() => {
    uploadedFileLinksRef.current = uploadedFileLinks;
  }, [uploadedFileLinks]);

  //reset agreement error
  useEffect(() => {
    let timer;
    if (agreementError) {
      timer = setTimeout(() => {
        setAgreementError((prevVal) => !prevVal);
      }, [5000]);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [agreementError]);

  useEffect(() => {
    console.table(storeLocationErrorData);
  }, [storeLocationErrorData]);

  if (showBusinessDocScreen) {
    return (
      <>
        <MapModal
          show={showMapModal}
          onHide={(latLng) => {
            setFinalLatLng(latLng);
            setShowMapModal(false);
            setSubmitAddressClick(true);
          }}
          address={address}
          setAddress={setAddress}
          coordinates={coordinates}
          locationFetchInProgress={locationFetchInProgress}
          geocodeShimmer={geocodeShimmer}
          setGeocodeShimmer={setGeocodeShimmer}
        />
        <OrderMessageModal
          show={infoMessage.show}
          content={infoMessage.content}
          header={infoMessage.header}
          onHide={() => {
            setInfoMessage({ show: false, content: "", header: "" });
          }}
        />
        <ErrorModal
          show={errorModal.show}
          content={errorModal.content}
          apiData={errorModal?.apiData}
          onHide={() => {
            setErrorModal({
              show: false,
              content: "",
            });
          }}
          tryAgain={() => {
            errorModal?.tryAgain();
            setErrorModal({ show: false, content: "" });
          }}
        />
        {showBusinessDocScreen && (
          <Container fluid className="onboarding">
            <Container fluid className="parentDiv_onboarding">
              <div className="header-container">
                <BiArrowBack
                  size="2rem"
                  color="white"
                  className="header-back-icon"
                  onClick={handleForceBackNav}
                />
                <p className="header-title">
                  SAVEO{" "}
                  {businessType.length > 0
                    ? businessType[0]
                    : selectBusinessTypeProp}
                </p>
                {/* <p className="help-link">HELP</p> */}
                <OverlayTrigger
                  trigger="click"
                  key={"bottom"}
                  placement={"bottom"}
                  overlay={
                    <Popover id={`popover-positioned-bottom`}>
                      <Popover.Header as="h3">
                        <a
                          href="tel:+918035018025"
                          className="text_decoration_none color_primary"
                        >
                          <IoMdCall className="color_secondary" /> &nbsp;&nbsp;
                          8035018025
                        </a>
                      </Popover.Header>
                      <Popover.Body>
                        <a
                          href="mailTo:support@saveo.in"
                          className="text_decoration_none color_primary"
                        >
                          <IoIosMail className="color_secondary" /> &nbsp;&nbsp;
                          support@saveo.in
                        </a>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div className="help-link">HELP</div>
                </OverlayTrigger>
              </div>
              <div className="welcome-hero">
                WELCOME{" "}
                {businessType.length > 0
                  ? businessType[0]
                  : selectBusinessTypeProp}
              </div>
              <Container fluid className="onboard-form-section">
                {/* Accordion starts here */}
                {/* Panel 1 -- Business Details */}

                <div fluid className="accordion-form-section">
                  <div className="panel-wrap">
                    <div
                      className={
                        activeId === "0"
                          ? "panel-header accordion-expanded"
                          : "panel-header"
                      }
                      onClick={() => toggleActive("0", "basic_details_tap")}
                    >
                      <div className="head-items-wrapper">
                        <p className="accordion-head">1. Business Details</p>
                        {activeId === "0" ? (
                          <ImCircleUp color="#B3B6BC" size="2rem" />
                        ) : (
                          <AiOutlinePlusCircle size="2rem" color="#4D6495" />
                        )}
                      </div>

                      <p
                        className={
                          activeId === "0"
                            ? "accordion-subhead"
                            : "accordion-subhead-disabled"
                        }
                      >
                        Enter your basic business details to start wholesale
                        buying/selling
                      </p>
                    </div>
                    <div
                      className={
                        activeId === "0" ? "collapse.show" : "collapse"
                      }
                    >
                      <div className="business-details-form-wrapper">
                        <InputGroup className="business-details">
                          <div>
                            <p className="input-title">
                              Business Name{" "}
                              <span
                                style={{ color: "crimson", fontSize: "1rem" }}
                              >
                                *
                              </span>
                            </p>
                          </div>
                          <FormControl
                            name="businessName"
                            placeholder="Business name"
                            aria-label="Business Name"
                            aria-describedby="basic-addon1"
                            type="text"
                            className={
                              businessDetailsErrorData.businessName
                                ? "form-input is-invalid"
                                : "form-input"
                            }
                            onChange={handleBusinessDetails}
                            value={businessDetails.businessName}
                            onFocus={handleOnFocus}
                            onBlur={handleOnBlur}
                            // size="lg"
                          ></FormControl>
                        </InputGroup>
                        <span
                          className={
                            businessDetailsErrorData.businessName
                              ? ""
                              : "bg-hidden"
                          }
                          style={{
                            marginTop: "0rem",
                            color: "red",
                            fontSize: "0.8rem",
                          }}
                        >
                          Invalid Business Name
                        </span>
                        <InputGroup className="business-details">
                          <p className="input-title">
                            Owner's Name{" "}
                            <span
                              style={{ color: "crimson", fontSize: "1rem" }}
                            >
                              *
                            </span>
                          </p>
                          <FormControl
                            name="ownersName"
                            placeholder="Owner's Name"
                            aria-label="Owner's Name"
                            aria-describedby="basic-addon1"
                            type="text"
                            className={
                              businessDetailsErrorData.ownersName
                                ? "form-input is-invalid"
                                : "form-input"
                            }
                            onChange={handleBusinessDetails}
                            value={businessDetails.ownersName}
                            onBlur={handleOnBlur}
                          ></FormControl>
                        </InputGroup>
                        <span
                          className={
                            businessDetailsErrorData.ownersName
                              ? ""
                              : "bg-hidden"
                          }
                          style={{
                            marginTop: "0rem",
                            color: "red",
                            fontSize: "0.8rem",
                          }}
                        >
                          Invalid Owners Name
                        </span>

                        <InputGroup className="business-details">
                          <p className="input-title">
                            Business E-mail ID{" "}
                            <span
                              style={{ color: "crimson", fontSize: "1rem" }}
                            >
                              *
                            </span>
                          </p>
                          <FormControl
                            name="email"
                            placeholder="business@email.com"
                            aria-label="Business E-mail ID"
                            aria-describedby="basic-addon1"
                            type="text"
                            className={
                              businessDetailsErrorData.email
                                ? "form-input is-invalid"
                                : "form-input"
                            }
                            onChange={handleBusinessDetails}
                            value={businessDetails.email}
                            onBlur={handleOnBlur}
                          ></FormControl>
                        </InputGroup>
                        <span
                          className={
                            businessDetailsErrorData.email ? "" : "bg-hidden"
                          }
                          style={{
                            marginTop: "0rem",
                            color: "red",
                            fontSize: "0.8rem",
                          }}
                        >
                          Invalid Email Format
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Panel 2 -- 2. Store location */}

                  <div className="panel-wrap">
                    <div
                      className={
                        activeId === "1"
                          ? "panel-header accordion-expanded"
                          : "panel-header"
                      }
                      onClick={() => toggleActive("1", "pharmacy_details_tap")}
                    >
                      <div className="head-items-wrapper">
                        <p className="accordion-head">2. Store location</p>
                        {activeId === "1" ? (
                          <ImCircleUp color="#B3B6BC" size="2rem" />
                        ) : (
                          <AiOutlinePlusCircle size="2rem" color="#4D6495" />
                        )}
                      </div>

                      <p
                        className={
                          activeId === "1"
                            ? "accordion-subhead"
                            : "accordion-subhead-disabled"
                        }
                      >
                        Add your location to help us serve you better
                      </p>
                    </div>
                    <div
                      className={
                        activeId === "1" ? "collapse.show" : "collapse"
                      }
                    >
                      <div className="business-details-form-wrapper">
                        <InputGroup className="business-details">
                          <div>
                            <p className="input-title">
                              Address line 1{" "}
                              <span
                                style={{ color: "crimson", fontSize: "1rem" }}
                              >
                                *
                              </span>
                            </p>
                          </div>
                          <div className="line1-wrapper">
                            <FormControl
                              name="line1"
                              placeholder="Address line 1"
                              aria-label="Address line 1"
                              aria-describedby="basic-addon5"
                              type="text"
                              className={
                                storeLocationErrorData.line1
                                  ? "form-input form-input-line1 is-invalid"
                                  : "form-input form-input-line1"
                              }
                              onChange={handleStoreLocation}
                              value={storeLocation.line1}
                              // size="lg"
                            ></FormControl>
                            <div
                              className="location-container"
                              onClick={getLocation}
                              style={{ cursor: "pointer" }}
                            >
                              <FaMapMarkerAlt />
                              Locate Me
                            </div>
                          </div>
                        </InputGroup>
                        <span
                          className={
                            storeLocationErrorData.line1 ? "" : "bg-hidden"
                          }
                          style={{
                            margin: "0rem",
                            color: "red",
                            fontSize: "0.8rem",
                          }}
                        >
                          Invalid Address
                        </span>

                        <div className="multiple-input-wrapper">
                          <InputGroup className="business-details line2-wrapper">
                            <p className="input-title">
                              Address line 2{" "}
                              <span
                                style={{ color: "crimson", fontSize: "1rem" }}
                              >
                                *
                              </span>
                            </p>
                            {/* <p className="input-title-2">Landmark</p> */}
                            <FormControl
                              name="line2"
                              placeholder="Address line 2"
                              aria-label="Address line 2"
                              // aria-describedby="basic-addon1"
                              type="text"
                              className={
                                storeLocationErrorData.line2
                                  ? "form-input form-input-line2 is-invalid"
                                  : "form-input form-input-line2"
                              }
                              onChange={handleStoreLocation}
                              value={storeLocation.line2}
                            ></FormControl>
                            <div class="invalid-feedback">Invalid Address</div>
                          </InputGroup>

                          <InputGroup className="business-details landmark-wrapper">
                            <p className="input-title">Landmark</p>
                            <FormControl
                              name="landmark"
                              placeholder="Landmark"
                              aria-label="Landmark"
                              // aria-describedby="basic-addon1"
                              type="text"
                              className="form-input form-input-line2"
                              onChange={handleStoreLocation}
                              value={storeLocation.landmark}
                            ></FormControl>
                          </InputGroup>
                        </div>

                        <div className="multiple-input-wrapper">
                          <InputGroup className="business-details line2-wrapper">
                            <p className="input-title">
                              Pincode{" "}
                              <span
                                style={{ color: "crimson", fontSize: "1rem" }}
                              >
                                *
                              </span>
                            </p>
                            {/* <p className="input-title-2">Landmark</p> */}
                            <FormControl
                              name="pincode"
                              placeholder="Pincode"
                              aria-label="Pincode"
                              // aria-describedby="basic-addon1"
                              type="text"
                              className={
                                storeLocationErrorData.pincode
                                  ? "form-input form-input-line2 is-invalid"
                                  : "form-input form-input-line2"
                              }
                              onChange={handleStoreLocation}
                              value={storeLocation.pincode}
                            ></FormControl>
                            <div class="invalid-feedback">Invalid Pincode</div>
                          </InputGroup>

                          <InputGroup className="business-details landmark-wrapper">
                            <p className="input-title">
                              City{" "}
                              <span
                                style={{ color: "crimson", fontSize: "1rem" }}
                              >
                                *
                              </span>
                            </p>
                            <FormControl
                              name="city"
                              placeholder="City"
                              aria-label="City"
                              // aria-describedby="basic-addon1"
                              type="text"
                              className={
                                storeLocationErrorData.city
                                  ? "form-input form-input-line2 is-invalid"
                                  : "form-input form-input-line2"
                              }
                              onChange={handleStoreLocation}
                              value={storeLocation.city}
                              required
                            ></FormControl>
                            <div class="invalid-feedback">Invalid City</div>
                          </InputGroup>
                        </div>
                        <div className="multiple-input-wrapper">
                          <InputGroup className="store-category-wrapper">
                            <p
                              style={{ width: "100%" }}
                              className="input-title"
                            >
                              Store Category{" "}
                              <span
                                style={{ color: "crimson", fontSize: "1rem" }}
                              >
                                *
                              </span>
                            </p>
                            <br />
                            <Form.Select
                              aria-label="select shop category"
                              onChange={handleStoreLocation}
                              name="retailerType"
                              className={
                                storeLocationErrorData.retailerType
                                  ? "form-input form-input-line2 is-invalid"
                                  : "form-input form-input-line2"
                              }
                              
                            >
                              <option value="select store category">
                                Select Category
                              </option>
                              <option value="pharmacy">Pharmacy</option>
                              <option value="hospital">Hospital</option>
                              <option value="others">Others</option>
                            </Form.Select>
                            <div class="invalid-feedback">
                              please select store category
                            </div>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* panel 3 starts below */}

                  <div className="panel-wrap">
                    <div
                      className={
                        activeId === "2"
                          ? "panel-header accordion-expanded"
                          : "panel-header"
                      }
                      onClick={() => toggleActive("2", "kyc_details_tap")}
                    >
                      <div className="head-items-wrapper">
                        <p className="accordion-head">
                          3. Upload Business Documents
                        </p>
                        {activeId === "2" ? (
                          <ImCircleUp color="#B3B6BC" size="2rem" />
                        ) : (
                          <AiOutlinePlusCircle size="2rem" color="#4D6495" />
                        )}
                      </div>

                      <p
                        className={
                          activeId === "2"
                            ? "accordion-subhead"
                            : "accordion-subhead-disabled"
                        }
                      >
                        Enter your basic business details to start wholesale
                        buying/selling
                      </p>
                    </div>
                    <div
                      className={
                        activeId === "2" ? "collapse.show" : "collapse"
                      }
                    >
                      <div className="business-details-form-wrapper">
                        <InputGroup className="business-details">
                          <div>
                            <p className="input-title">
                              Drug License (Schedule H){" "}
                              <span
                                style={{ color: "crimson", fontSize: "1rem" }}
                              >
                                *
                              </span>
                              <p style={{ fontSize: ".7rem" }}>
                                only 1 is mandatory
                              </p>
                            </p>
                          </div>
                          <div className="input-files-wrapper">
                            {!imageURL.drugLicence1 ? (
                              <div className="input-document-container">
                                <input
                                  className="file-input"
                                  id="drug-licence-1"
                                  type="file"
                                  name="drugLicence1"
                                  data-max-size="700"
                                  onChange={handleFileInput}
                                  accept="image/jpeg,image/jpg,image/png"
                                />
                                <label
                                  className="image-container"
                                  htmlFor="drug-licence-1"
                                >
                                  <AiOutlineCamera size="2rem" />
                                  ADD IMAGE
                                </label>
                              </div>
                            ) : (
                              <div className="input-image-preview-container">
                                <div
                                  className="preview-close-container"
                                  onClick={() =>
                                    removeFileInput("drugLicence1")
                                  }
                                >
                                  <AiFillCloseCircle className="preview-close" />
                                </div>

                                <img
                                  src={imageURL.drugLicence1}
                                  alt="drug licence 1"
                                  className="preview-image"
                                />
                              </div>
                            )}
                            {/* {!imageURL.drugLicence2 ? (
                              <div className="input-document-container">
                                <input
                                  className="file-input"
                                  id="drug-licence-2"
                                  type="file"
                                  name="drugLicence2"
                                  onChange={handleFileInput}
                                  accept="image/jpeg,image/jpg,image/png"
                                />
                                <label
                                  className="image-container"
                                  htmlFor="drug-licence-2"
                                >
                                  <AiOutlineCamera size="2rem" />
                                  ADD ANOTHER
                                </label>
                              </div>
                            ) : (
                              <div className="input-image-preview-container">
                                <div
                                  className="preview-close-container"
                                  onClick={() =>
                                    removeFileInput("drugLicence2")
                                  }
                                >
                                  <AiFillCloseCircle className="preview-close" />
                                </div>

                                <img
                                  src={imageURL.drugLicence2}
                                  alt="drug licence 2"
                                  className="preview-image"
                                />
                              </div>
                            )}
                            {!imageURL.drugLicence3 ? (
                              <div className="input-document-container">
                                <input
                                  className="file-input"
                                  id="drug-licence-3"
                                  type="file"
                                  name="drugLicence3"
                                  onChange={handleFileInput}
                                  accept="image/jpeg,image/jpg,image/png"
                                />
                                <label
                                  className="image-container"
                                  htmlFor="drug-licence-3"
                                >
                                  <AiOutlineCamera size="2rem" />
                                  ADD ANOTHER
                                </label>
                              </div>
                            ) : (
                              <div className="input-image-preview-container">
                                <div
                                  className="preview-close-container"
                                  onClick={() =>
                                    removeFileInput("drugLicence3")
                                  }
                                >
                                  <AiFillCloseCircle className="preview-close" />
                                </div>

                                <img
                                  src={imageURL.drugLicence3}
                                  alt="drug licence 3"
                                  className="preview-image"
                                />
                              </div>
                            )} */}
                          </div>
                        </InputGroup>
                        <InputGroup className="scheduleX-section">
                          <label htmlFor="schedule-x">
                            Do you have Schedule X license ?
                          </label>
                          <input
                            type="checkbox"
                            id="schedule-x"
                            onClick={() => setShowScheduleX((val) => !val)}
                          />
                        </InputGroup>
                        <InputGroup className="business-details">
                          {showScheduleX && (
                            <>
                              <div>
                                <p className="input-title">
                                  Drug License (Schedule X)
                                </p>
                              </div>
                              <div className="input-files-wrapper">
                                {!imageURL.sheduleXLicence1 ? (
                                  <div className="input-document-container">
                                    <input
                                      className="file-input"
                                      id="schedule-x-licence1"
                                      type="file"
                                      name="sheduleXLicence1"
                                      onChange={handleFileInput}
                                      accept="image/jpeg,image/jpg,image/png"
                                    />
                                    <label
                                      className="image-container"
                                      htmlFor="schedule-x-licence1"
                                    >
                                      <AiOutlineCamera size="2rem" />
                                      ADD IMAGE
                                    </label>
                                  </div>
                                ) : (
                                  <div className="input-image-preview-container">
                                    <div
                                      className="preview-close-container"
                                      onClick={() =>
                                        removeFileInput("sheduleXLicence1")
                                      }
                                    >
                                      <AiFillCloseCircle className="preview-close" />
                                    </div>

                                    <img
                                      src={imageURL.sheduleXLicence1}
                                      alt="Schedule X Licence 1"
                                      className="preview-image"
                                    />
                                  </div>
                                )}
                                {/* {!imageURL.scheduleXLicence2 ? (
                                  <div className="input-document-container">
                                    <input
                                      className="file-input"
                                      id="schedule-x-licence-2"
                                      type="file"
                                      name="scheduleXLicence2"
                                      onChange={handleFileInput}
                                      accept="image/jpeg,image/jpg,image/png"
                                    />
                                    <label
                                      className="image-container"
                                      htmlFor="schedule-x-licence-2"
                                    >
                                      <AiOutlineCamera size="2rem" />
                                      ADD ANOTHER
                                    </label>
                                  </div>
                                ) : (
                                  <div className="input-image-preview-container">
                                    <div
                                      className="preview-close-container"
                                      onClick={() =>
                                        removeFileInput("scheduleXLicence2")
                                      }
                                    >
                                      <AiFillCloseCircle className="preview-close" />
                                    </div>

                                    <img
                                      src={imageURL.scheduleXLicence2}
                                      alt="schedule x licence 2"
                                      className="preview-image"
                                    />
                                  </div>
                                )}
                                {!imageURL.scheduleXLicence3 ? (
                                  <div className="input-document-container">
                                    <input
                                      className="file-input"
                                      id="schedule-x-licence3"
                                      type="file"
                                      name="scheduleXLicence3"
                                      onChange={handleFileInput}
                                      accept="image/jpeg,image/jpg,image/png"
                                    />
                                    <label
                                      className="image-container"
                                      htmlFor="schedule-x-licence3"
                                    >
                                      <AiOutlineCamera size="2rem" />
                                      ADD ANOTHER
                                    </label>
                                  </div>
                                ) : (
                                  <div className="input-image-preview-container">
                                    <div
                                      className="preview-close-container"
                                      onClick={() =>
                                        removeFileInput("scheduleXLicence3")
                                      }
                                    >
                                      <AiFillCloseCircle className="preview-close" />
                                    </div>

                                    <img
                                      src={imageURL.scheduleXLicence3}
                                      alt="Schedule X Licence 3"
                                      className="preview-image"
                                    />
                                  </div>
                                )} */}
                              </div>
                            </>
                          )}
                        </InputGroup>
                        <InputGroup className="business-details">
                          <div>
                            <p className="input-title">
                              GST Number{" "}
                              <span
                                style={{ color: "crimson", fontSize: "1rem" }}
                              >
                                {businessType &&
                                  businessType[0] !== "PHARMACY" &&
                                  "*"}
                              </span>
                              <p style={{ fontSize: ".7rem" }}>
                                only 1 is mandatory
                              </p>
                            </p>
                          </div>
                          <div className="input-files-wrapper">
                            {/* enter below */}

                            {!imageURL.gstNumber1 ? (
                              <div className="input-document-container">
                                <input
                                  className="file-input"
                                  id="gst-number-1"
                                  type="file"
                                  name="gstNumber1"
                                  onChange={handleFileInput}
                                  accept="image/jpeg,image/jpg,image/png"
                                />
                                <label
                                  className="image-container"
                                  htmlFor="gst-number-1"
                                >
                                  <AiOutlineCamera size="2rem" />
                                  ADD IMAGE
                                </label>
                              </div>
                            ) : (
                              <div className="input-image-preview-container">
                                <div
                                  className="preview-close-container"
                                  onClick={() => removeFileInput("gstNumber1")}
                                >
                                  <AiFillCloseCircle className="preview-close" />
                                </div>

                                <img
                                  src={imageURL.gstNumber1}
                                  alt="gst number 1"
                                  className="preview-image"
                                />
                              </div>
                            )}
                            {/* {!imageURL.gstNumber2 ? (
                              <div className="input-document-container">
                                <input
                                  className="file-input"
                                  id="gst-number-2"
                                  type="file"
                                  name="gstNumber2"
                                  onChange={handleFileInput}
                                  accept="image/jpeg,image/jpg,image/png"
                                />
                                <label
                                  className="image-container"
                                  htmlFor="gst-number-2"
                                >
                                  <AiOutlineCamera size="2rem" />
                                  ADD ANOTHER
                                </label>
                              </div>
                            ) : (
                              <div className="input-image-preview-container">
                                <div
                                  className="preview-close-container"
                                  onClick={() => removeFileInput("gstNumber2")}
                                >
                                  <AiFillCloseCircle className="preview-close" />
                                </div>

                                <img
                                  src={imageURL.gstNumber2}
                                  alt="gst number 2"
                                  className="preview-image"
                                />
                              </div>
                            )}
                            {!imageURL.gstNumber3 ? (
                              <div className="input-document-container">
                                <input
                                  className="file-input"
                                  id="gst-number-3"
                                  type="file"
                                  name="gstNumber3"
                                  onChange={handleFileInput}
                                  accept="image/jpeg,image/jpg,image/png"
                                />
                                <label
                                  className="image-container"
                                  htmlFor="gst-number-3"
                                >
                                  <AiOutlineCamera size="2rem" />
                                  ADD ANOTHER
                                </label>
                              </div>
                            ) : (
                              <div className="input-image-preview-container">
                                <div
                                  className="preview-close-container"
                                  onClick={() => removeFileInput("gstNumber3")}
                                >
                                  <AiFillCloseCircle className="preview-close" />
                                </div>

                                <img
                                  src={imageURL.gstNumber3}
                                  alt="gst number 3"
                                  className="preview-image"
                                />
                              </div>
                            )} */}
                          </div>
                        </InputGroup>
                        <InputGroup className="business-details">
                          <div>
                            <p className="input-title">
                              PAN Card{" "}
                              <span
                                style={{ color: "crimson", fontSize: "1rem" }}
                              >
                                {businessType &&
                                  businessType[0] !== "PHARMACY" &&
                                  "*"}
                              </span>
                              <p style={{ fontSize: ".7rem" }}>
                                only 1 is mandatory
                              </p>
                            </p>
                          </div>
                          <div className="input-files-wrapper">
                            {!imageURL.panCard ? (
                              <div className="input-document-container">
                                <input
                                  className="file-input"
                                  id="pan-card"
                                  type="file"
                                  name="panCard"
                                  onChange={handleFileInput}
                                  accept="image/jpeg,image/jpg,image/png"
                                />
                                <label
                                  className="image-container"
                                  htmlFor="pan-card"
                                >
                                  <AiOutlineCamera size="2rem" />
                                  ADD IMAGE
                                </label>
                              </div>
                            ) : (
                              <div className="input-image-preview-container">
                                <div
                                  className="preview-close-container"
                                  onClick={() => removeFileInput("panCard")}
                                >
                                  <AiFillCloseCircle className="preview-close" />
                                </div>

                                <img
                                  src={imageURL.panCard}
                                  alt="pan card"
                                  className="preview-image"
                                />
                              </div>
                            )}
                          </div>
                        </InputGroup>

                        {businessType[0] !== "PHARMACY" && (
                          <InputGroup className="business-details">
                            <div>
                              <p className="input-title">
                                Bank account details (optional)
                              </p>
                            </div>
                            <div className="input-files-wrapper">
                              {!imageURL.bankAccountDetails ? (
                                <div className="input-document-container">
                                  <input
                                    className="file-input"
                                    id="bank-account-details"
                                    type="file"
                                    name="bankAccountDetails"
                                    onChange={handleFileInput}
                                    accept="image/jpeg,image/jpg,image/png"
                                  />
                                  <label
                                    className="image-container"
                                    htmlFor="bank-account-details"
                                  >
                                    <AiOutlineCamera size="2rem" />
                                    ADD IMAGE
                                  </label>
                                </div>
                              ) : (
                                <div className="input-image-preview-container">
                                  <div
                                    className="preview-close-container"
                                    onClick={() =>
                                      removeFileInput("bankAccountDetails")
                                    }
                                  >
                                    <AiFillCloseCircle className="preview-close" />
                                  </div>

                                  <img
                                    src={imageURL.bankAccountDetails}
                                    alt="bank account details"
                                    className="preview-image"
                                  />
                                </div>
                              )}
                            </div>
                          </InputGroup>
                        )}

                        <InputGroup className="agreement-check">
                          <label className="inp-container">
                            <input
                              checked={agreementAccepted}
                              type="checkbox"
                              onClick={() =>
                                setAgreementAccepted((prevVal) => !prevVal)
                              }
                            />
                            <div className="checkmark"></div>
                          </label>

                          {/* <input type="checkbox" id="agreement" onClick={() =>setAgreementAccepted((prevVal) =>  !prevVal) } /> */}
                          <label for="agreement" className="agreement-message">
                            By proceeding, you are agreeing to share your
                            information with Saveo. It will remain confidential
                            and used for business transaction purposes.
                          </label>
                        </InputGroup>
                        {agreementError && (
                          <div className="agreement-error-container">
                            <p className={"agreement-error errorAnime"}>
                              please accept the agreement to proceed further
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {currentStep === 1 && (
                  <div className="footer-button-container">
                    <button
                      onClick={postBusinessDetails}
                      // disabled={!sendVerificationCheck}
                      className={
                        businessDetailsCheck
                          ? "footer-button footer-button-enabled"
                          : "footer-button footer-button-disabled"
                      }
                    >
                      {showSpinner && <ButtonLoading1 />}
                      {!showSpinner && "STEP 2/3 - Locate your Store"}
                    </button>
                  </div>
                )}

                {currentStep === 2 && (
                  <div className="footer-button-container">
                    <button
                      onClick={postStoreLocation}
                      // disabled={!sendVerificationCheck}
                      className={
                        storeLocationCheck
                          ? "footer-button footer-button-enabled"
                          : // : landmarkCheck
                            // ? "footer-button footer-button-enabled"
                            "footer-button footer-button-disabled"
                      }
                    >
                      {showSpinner && <ButtonLoading1 />}
                      {!showSpinner && "STEP 3/3 - Upload KYC documents"}
                    </button>
                  </div>
                )}

                {currentStep === 3 && (
                  <div className="footer-button-container">
                    <button
                      onClick={postAllData}
                      // disabled={!sendVerificationCheck}
                      className={
                        finalCheck
                          ? "footer-button footer-button-enabled"
                          : "footer-button footer-button-disabled"
                      }
                    >
                      {showSpinner && <ButtonLoading1 />}
                      {!showSpinner && "Send For Verification"}
                    </button>
                  </div>
                )}
              </Container>
            </Container>
          </Container>
        )}
      </>
    );
  } else {
    return <></>;
  }
}

export default OnboardingScreen4;
