import { Modal, Button, NavItem } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import "../../App.css";
import "./dialog.css";
import loader from "../../Images/gifs/loader1.gif";
import { useNavigate } from "react-router";
import { useTimer } from 'react-timer-hook';
const OrderInProgress = (props) => {
  let navigate = useNavigate();
  const [timer, setTimer] = useState(0);
  const [currTime, setCurrTime] = useState(new Date().getTime());
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({currTime , onExpire: () => {console.warn('onExpire called');localStorage.removeItem('orderInProgressTime');props.onHide()} });
  //    120000
  let timeInternval;

  useEffect(() => {
    console.log(localStorage.getItem("orderInProgressTime"));
  
    restart(+localStorage.getItem("orderInProgressTime")+ 120000)
  },[localStorage.getItem("orderInProgressTime")]);

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="del_modal"
      backdrop="static"
    >
      <Modal.Header className="inProgressHeader">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="padding_10 text_center "
        >
          <img src={loader} className="loader_modal_svg" />
          <br></br>
          <p className="font_ubuntu color_primary font_weight_md font_18">
            Order In Progress...
          </p>
          <p className="timer_inprogress">{minutes}:{seconds}</p>
          {
            <>
              <div
                className="font_ubuntu font_weight_md color_secondary text_center font_14"
                style={{ marginBottom: "20px" }}
              >
                Please try again after 2 mins
              </div>
              {/* <div className="font_ubuntu color_primary_lt font_weight_md font_12 modal_content">
                Once our team confirms the details within the next 24 working
                hours, we will allocate the delivery slot for your order.
              </div> */}
            </>
          }

          <div className="text_center">
            <Button
              className="bg_white width_100 brdr_none bg_secondary go_to_home  color_white font_weight_md"
              onClick={async () => {
                navigate("/home");
              }}
            >
              GO TO HOME
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      {/* <Modal.Body>
       
      </Modal.Body> */}
    </Modal>
  );
};

export default React.memo(OrderInProgress);
