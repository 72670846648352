import React, { useContext, useEffect, useState } from "react";
import {
  appCategoriesDataContext,
  searchContext,
  usersData,
  WSWidgetDataContext,
} from "../../../App";
import { refreshToken } from "../../../Actions/Auth";
import ErrorModal from "../../Modals/ErrorModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import {
  getAllWidgets,
  getCategories,
} from "../../../Actions/WholeSale/HomePage";
import AppCategories from "../Header/AppCategories";
import Banner from "./Banner";
import { Container } from "react-bootstrap";
import TopSellingProducts from "./TopSellingProducts";
import ShopByCD from "./ShopByCD";
import TopCompanyOffers from "./TopCompanyOffers";
import DoD from "./DoD";
import FastMovingProducts from "./FastMovingProducts";
import RecentlyViewed from "./RecentlyViewed";
import PastOrders from "./PastOrders";
import FlashSale from "./FlashSale";

function Home() {
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  // const [appCategoriesData, setAppCategoriesData] = useState([]);
  // const [activeTab, setActiveTab] = useState(0);
  const { WSWidgetData: widgetData, setWSWidgetData: setWidgetData } =
    useContext(WSWidgetDataContext);
  const [widgetShimmer, setWidgetShimmer] = useState(false);
  const { showRecent, setShowRecent } = useContext(searchContext);
  const { appCategoriesData, setAppCategoriesData, activeTab, setActiveTab } =
    useContext(appCategoriesDataContext);

  //api call to get the widget data and update the respective state
  const getHomeScreenWidgets = async (screenName) => {
    let params = {
      screenName: screenName,
    };

    //update the shimmer state
    setWidgetShimmer(true);

    try {
      const res = await getAllWidgets(params, {});
      setWidgetData(res?.data?.data);
      //update the shimmer state
      setWidgetShimmer(false);
    } catch (error) {
      //update the shimmer state if any error
      setWidgetShimmer(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getHomeScreenWidgets();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getHomeScreenWidgets();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getHomeScreenWidgets();
          },
        });
      }
    }
  };

  //app categories data api
  //also hitting app categories in the AppCategories component //remove the duplicate api hit post analysis
  const getAppCategories = async () => {
    setWidgetShimmer(true)
    try {
      const res = await getCategories();
      setAppCategoriesData(res?.data?.data);
      //update the medCategory in the local storage
      localStorage.setItem("medCategory", res?.data?.data[activeTab]?.name);
      if(widgetShimmer) setWidgetShimmer(false)
    } catch (error) {
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getAppCategories();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getAppCategories();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getAppCategories();
          },
        });
      }
    }
  };

  //onMount
  useEffect(() => {
    //gives screen names
    //only fetchAppCategories once
    if (appCategoriesData.length === 0) getAppCategories();
    //set default screen name
    localStorage.setItem("currentScreen", "all_home_screen");
    //scroll to the top
    window.scrollTo(0,0)
  }, []);

  useEffect(() => {
    if (appCategoriesData.length === 0) return; //guardian check to prevent unwanted fn calls
    //fetch the widgets for current screen name
    getHomeScreenWidgets(appCategoriesData[activeTab]?.tag);
    //everytime activeTab changes, update the key in localStorage
  }, [appCategoriesData, activeTab]);

  return (
    // <div>Home</div>
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      <div
        className="wholesale-parent-container"
        style={{ backgroundColor: widgetShimmer && "#ffffff" }}
      >
        {!showRecent && (
          <AppCategories
            data={appCategoriesData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            appCategoriesData={appCategoriesData}
            setAppCategoriesData={setAppCategoriesData}
          />
        )}
        {!widgetShimmer && widgetData?.length &&
          widgetData?.map((item, index) => {
            if (item?.widgetName === "ws_banner_widget")
              return <Banner data={item} key={index} />;
            else if (item?.widgetName === "ws_discount_widget")
              return <ShopByCD data={item} key={index} />;
            else if (item?.widgetName === "ws_product_list_four")
              return <TopSellingProducts data={item} key={index} />;
            else if (item?.widgetName === "ws_product_list_six")
              return <DoD data={item} key={index} />;
            else if (item?.widgetName === "ws_product_list_five")
              return <FastMovingProducts data={item} key={index} />;
          })}

        {widgetShimmer && (
          <div>
            {[...Array(3)].map((_, index) => {
              return (
                <div
                  key={index}
                  className={"animateShimmer"}
                  style={{ height: "15rem", margin: window.innerWidth < 750 ? '1rem .5rem' : "2rem" }}
                ></div>
              );
            })}
          </div>
        )}
        {/* <TopCompanyOffers />  */}
        {/* <RecentlyViewed />   */}
        {/* <PastOrders />  */}
        {/* <FlashSale /> */}
      </div>
    </>
  );
}

export default Home;
