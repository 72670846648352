import React, { useState, useEffect, useContext, useLayoutEffect, useRef } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import "./Orders.css";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { usersData } from "../../App";
import { refreshToken } from "../../Actions/Auth";
import { My_Account } from "../../Actions/MyAccount";
import OrderCard from "./OrderCard";
import EmptyOrders from "../../Images/png/empty_orders.jpg";
import ErrorModal from "../Modals/ErrorModal";
import { Common_Event_Hit } from "../../Actions/Events";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSearchParams } from "react-router-dom";

const Orders = () => {
  const [selectedFilter, setFilter] = useState("live");
  const [ordersData, setOrdersData] = useState([]);
  const [homeShimmer, setHomeShimmer] = useState(false);
  const {userData, setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [pageNo, setPageNo] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentScrollRef = useRef(null)

  let navigate = useNavigate();
  let location=useLocation()
  const fetchMoreData = () => {
    setPageNo(+pageNo + 1);
  };

  const getOrdersList = async (filter,reset=false) => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    localStorage.removeItem("currentScreenBannerData");

    if (ordersData?.length === 0) {
      setHomeShimmer(true);
    }

    if (filter === "live") {
      localStorage.setItem("currentScreen", "live_order_screen");
      setOrdersData();
      const params = {
        retailerId: data.id,
      };

      My_Account("LIVE_ORDERS", params)
        .then((res) => {
          if (res?.data?.data) {
            setOrdersData([...ordersData, ...res.data.data]);
            setHomeShimmer(false);
          }
        })
        .catch((error) => {
          setHomeShimmer(false);
          if (error?.response?.status === 401) {
            refreshToken().then((res) => {
              getOrdersList(filter);
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
              tryAgain: function () {
                getOrdersList(filter);
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                getOrdersList(filter);
              },
            });
          }
        });
    } else {
      localStorage.setItem("currentScreen", "past_order_screen");
      const params = {
        retailerId: data.id,
        page:reset?0:pageNo,
      };

      My_Account("PAST_ORDERS", params)
        .then((res) => {
          if (res?.data?.data.length > 0) {
            (pageNo===0 || reset) && res.data.data.every((item,ind)=>{
                 if(item.onlinePaymentEnabled){
                  
                     item.scrollTarget=true;
                     return false
                 }
                 return true
             })
           
           !reset && setOrdersData([...ordersData, ...res.data.data]);
           reset && setOrdersData([...res.data.data]);

          } else {
            setHasMore(false);
          }
          setHomeShimmer(false);
        })
        .catch((error) => {
          
          setHomeShimmer(false);
          if (error?.response?.status === 401) {
            refreshToken().then((res) => {
              getOrdersList(filter);
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
              tryAgain: function () {
                getOrdersList(filter);
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                getOrdersList(filter);
              },
            });
          }
        });
    }
  };

  const onHandleCategory = async (categType) => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: categType === "live" ? "live_orders_tap" : "past_orders_tap",
    });
    setOrdersData([]);
    setPageNo(0);

    if (categType === "live") {
      navigate('/account/yourOrders?type=live')
    } else {
      navigate('/account/yourOrders?type=past')
    }
  };

  const refresh = () => {
    getOrdersList(selectedFilter);
  };

  const resetOrderData = () => {
    let pastInvoiceDiv = document.getElementById("ordersData");
    pastInvoiceDiv.scrollTop = 0;
    window.scrollTo(0, 0);
    setOrdersData([]);
    setPageNo(0);
    setHasMore(true);
    getOrdersList(selectedFilter,true);
  };

 const paymentScroll=(offsetTop)=>{
  let scrollDiv=document.getElementById('ordersData') 
  pageNo===0 && scrollDiv.scrollTo({
    top:offsetTop-200,
    behavior:"smooth"
});
 }

  useEffect(() => {
    let data = localStorage.getItem("userData");
    setHasMore(true)
    if (data && data.length) {
       let type=searchParams.get('type')
        if(type){
          setFilter(type)
          getOrdersList(type);
        }else{
          getOrdersList(selectedFilter);
        }

    } else {
      navigate("/login");
    }
  
  }, [location]);

  useEffect(()=>{
    if(pageNo){
      getOrdersList(selectedFilter);
    }
  },[pageNo])

  



  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
          // refresh();
        }}
      />

      <Container fluid className="orders_parent">
        <div className="orders_filter display_flex font_ubuntu">
          <div className="padding_5">
            <HiOutlineArrowNarrowLeft
              className="cursor_pointer goBack"
              onClick={() => navigate(-1)}
              style={{ marginTop: "0.5rem" }}
            />
          </div>

          <div
            className="go_back color_primary font_ubuntu font_weight_md no_wrap  padding_10"
            onClick={() => navigate(-1)}
          >
            Go&nbsp;Back
          </div>

          <div
            className={
              selectedFilter === "live"
                ? "live_menu color_primary font_weight_md activeClass"
                : "live_menu color_primary font_weight_md"
            }
            onClick={() => onHandleCategory("live")}
          >
            Live&nbsp;Orders
          </div>

          <div
            className={
              selectedFilter === "past"
                ? "past_menu color_primary font_weight_md activeClass"
                : "past_menu color_primary font_weight_md"
            }
            onClick={() => onHandleCategory("past")}
          >
            Past&nbsp;Orders  {userData?.onlinePaymentEnabled && <div className="target_dot_past_orders"></div>}
          </div>
        </div>

        <div id="ordersData" className="orders_data_div">
          {homeShimmer ? (
            <>
              <Container>
                <div className="skeletal_div_sm animateShimmer"></div>
              </Container>
              <Container>
                <div className="skeletal_div_sm animateShimmer"></div>
              </Container>
              <Container>
                <div className="skeletal_div_sm animateShimmer"></div>
              </Container>
              <Container>
                <div className="skeletal_div_sm animateShimmer"></div>
              </Container>
            </>
          ) : (
            ordersData &&
            ordersData.length > 0 && (
              <InfiniteScroll
                dataLength={ordersData.length}
                next={selectedFilter === "past" ? fetchMoreData : ""}
                hasMore={selectedFilter === "past" ? hasMore : false}
                scrollThreshold="90%"
                scrollableTarget="ordersData"
                loader={
                  <Spinner
                    animation="border"
                    className="color_secondary spinner"
                    size="lg"
                  />
                }
               
              >
                {ordersData.map((order) => {
                  return (
                    <OrderCard
                      medData={order}
                      refresh={refresh}
                      paymentCardScroll={paymentScroll}
                      filter={selectedFilter}
                      resetOrderData={resetOrderData}
                    />
                  );
                })}
              </InfiniteScroll>
            )
          )}

          {ordersData && ordersData.length === 0 && (
            <div className="empty_list_sec">
              <img src={EmptyOrders} alt="Empty Orders" />
              <br />
              <span className="font_ubuntu font_weight_md color_grey">
                There are no orders yet
              </span>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default Orders;
