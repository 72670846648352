import React, { useContext, useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { BsArrowLeft, BsDashLg, BsPlusLg, BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router";
import { refreshToken } from "../../../Actions/Auth";
import {
  deleteItemFromCart,
  getCartDetails,
} from "../../../Actions/WholeSale/WSCart";
import {
  networkStatusContext,
  searchContext,
  usersData,
  WSCartDataContext,
} from "../../../App";
import ErrorModal from "../../Modals/ErrorModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import "./WSCart.css";
import WSCartCard from "./WSCartCard";
import { CART_IMAGES } from "../../../Images/svg";
import Lottie from "react-lottie-player";
import WSCartEmpty from "../../../Images/json/WSCartEmpty.json";
import { useServiceWorker } from "../../../useServiceWorker";

function WSCart() {
  const { userData, setShowMaintenance } = useContext(usersData);
  const navigate = useNavigate();
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [expandedSearch, setExpandedSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const searchBarRef = useRef();
  const { WSCartData: cartData, setWSCartData: setCartData } =
    useContext(WSCartDataContext);
  const [shimmer, setShimmer] = useState(false);
  const [filteredItems, setfilteredItems] = useState([]);
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const { networkStatus, showNetworkStatus } = useContext(networkStatusContext);

  //api to ferch the cart details
  const fetchCartDetails = async () => {
    //update the shimmer state
    setShimmer(true);
    try {
      const res = await getCartDetails();
      //update the cart details state
      setCartData(res?.data?.data);
      //update the shimmer state
      setShimmer(false);
    } catch (error) {
      //update the shimmer state if any error
      setShimmer(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          fetchCartDetails();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            fetchCartDetails();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            fetchCartDetails();
          },
        });
      }
    }
  };

  //handle expanded search state
  const handleSearch = () => {
    setExpandedSearch(!expandedSearch);

    if (!expandedSearch) searchBarRef.current.focus();
  };

  //handle search query state update
  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  //function to filter the cart items
  const filterCartItems = () => {
    //empty the filteredItems state when the query is empty
    if (searchQuery.length === 0) {
      setfilteredItems([]);
      return;
    }
    let data = [...cartData.cartMedicineDetailResponseList];
    data = data.filter((item, index) => {
      return item.medicineName
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    });
    //update the filtered items state
    setfilteredItems(data);
  };

  useEffect(() => {
    fetchCartDetails();
  }, []);

  //filter items whenever searchQuery changes
  useEffect(() => {
    filterCartItems();
  }, [searchQuery]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      <div className="ws-cart-main-container">
        <div
          className={
            !networkStatus || showNetworkStatus || isUpdateAvailable
              ? "ws-products-header ws-products-header-cart ws-cart-header ws-cart-header-update"
              : "ws-products-header ws-products-header-cart ws-cart-header"
          }
        >
          <BsArrowLeft
            size="1.5rem"
            className="ws-nav-back"
            onClick={() => navigate(-1)}
          />
          <p>Your Cart</p>
          {cartData?.cartMedicineDetailResponseList?.length > 0 && (
            <div className="ws-search-container">
              <Row
                className={
                  expandedSearch
                    ? "search-container ws-product-search-container ws-product-search-container-expand"
                    : "search-container ws-product-search-container"
                }
              >
                <div className="qa-search-wrapper">
                  <div className="qa-search-icon">
                    <BsSearch
                      color="#97ABB8"
                      onClick={handleSearch}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="qa-input-container ws-input-container">
                    <input
                      className={
                        expandedSearch
                          ? "qa-search ws-search-expand"
                          : "qa-search ws-search"
                      }
                      placeholder="Search Your Cart"
                      value={searchQuery}
                      onChange={handleSearchQuery}
                      ref={searchBarRef}
                      onBlur={handleSearch}
                    />
                  </div>
                </div>
              </Row>
            </div>
          )}
        </div>
        {!shimmer && cartData?.cartMedicineDetailResponseList && (
          <div
            className={
              !networkStatus || showNetworkStatus || isUpdateAvailable
                ? "ws-cart-main-content-wrapper ws-cart-main-content-wrapper-update"
                : "ws-cart-main-content-wrapper"
            }
          >
            <div className="ws-cart-items-list">
              {filteredItems.length === 0 &&
                !searchQuery &&
                cartData?.cartMedicineDetailResponseList?.map((item, index) => {
                  return <WSCartCard data={item} key={index} />;
                })}
              {filteredItems.length === 0 && searchQuery && (
                <p>No items in cart match the search string </p>
              )}
              {filteredItems.length > 0 &&
                searchQuery &&
                filteredItems?.map((item, index) => {
                  return <WSCartCard data={item} key={index} />;
                })}
            </div>

            <div className="ws-cart-price-details-container">
              <div className="ws-product-price-details-header">
                <p>{cartData?.size} items</p>
                <p>Price Detail</p>
              </div>

              <div className="ws-product-price-details-content">
                {cartData?.amountBreakUpObjectList?.map((item, index) => {
                  return (
                    <>
                      <div className="ws-product-price-detail-item" key={index}>
                        <p>{item.key}</p>
                        <p>
                          ₹{" "}
                          {item.value.toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                      </div>
                      {item.subItems &&
                        item.subItems.map((subItem, index) => {
                          return (
                            <div
                              className={
                                subItem.highlight
                                  ? "ws-product-price-detail-subitem-highlight"
                                  : "ws-product-price-detail-subitem"
                              }
                              style={{
                                borderBottom:
                                  index === 0 && "5px solid #ffffff",
                              }}
                            >
                              <p>{subItem.key}</p>
                              <p>
                                {subItem.value.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </div>
                          );
                        })}
                    </>
                  );
                })}
              </div>
              <div className="ws-product-price-details-total-container">
                <p>TOTAL</p>
                <p>
                  ₹{" "}
                  {cartData?.total?.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
              <div className="ws-product-price-details-disclaimer-container">
                <p>
                  NOTE:{" "}
                  <span>
                    Bill Amount may differ from what is shown above (in app) due
                    to different Batch and Schemes
                  </span>
                </p>
              </div>
              <div
                style={{
                  backgroundColor: "#ffffff",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  className="ws-product-price-details-confirm-button"
                  onClick={() => navigate("/wholesale/home/cart/placeOrder")}
                >
                  CONFIRM
                </button>
              </div>
            </div>
          </div>
        )}

        {!shimmer && cartData?.cartMedicineDetailResponseList === null && (
          <div
            className="flex-align-center-column"
            style={{ height: "calc(100vh - 4rem)", backgroundColor: "#EAECEE" }}
          >
            <Lottie
              loop={false}
              animationData={WSCartEmpty}
              style={{ width: "60%", height: "60%" }}
              play
            />
            <p className="empty-cart-message">Cart is Empty</p>
          </div>
        )}

        {shimmer && (
          <div className="ws-cart-main-content-wrapper">
            <div className="ws-cart-items-list">
              {[...Array(3)].map((_, index) => {
                return (
                  <div
                    className="ws-cart-card-main-container"
                    style={{ height: "8rem" }}
                  >
                    <p
                      className="animateShimmer"
                      style={{
                        height: ".5rem",
                        width: "80%",
                        marginTop: ".5rem",
                      }}
                    ></p>
                    <p
                      className="animateShimmer"
                      style={{
                        height: ".5rem",
                        width: "40%",
                        marginTop: ".5rem",
                      }}
                    ></p>
                    <p
                      className="animateShimmer"
                      style={{
                        height: ".5rem",
                        width: "30%",
                        marginTop: ".5rem",
                      }}
                    ></p>
                    <p
                      className="animateShimmer"
                      style={{
                        height: ".5rem",
                        width: "30%",
                        marginTop: ".5rem",
                      }}
                    ></p>
                    <p
                      className="animateShimmer"
                      style={{
                        height: "2rem",
                        width: "20%",
                        marginTop: "0rem",
                        marginLeft: "auto",
                        borderRadius: ".25rem",
                      }}
                    ></p>
                  </div>
                );
              })}
            </div>
            <div className="ws-cart-price-details-container">
              <div className="ws-product-price-details-header">
                <p
                  className="animateShimmer"
                  style={{
                    height: "1rem",
                    width: "50%",
                    marginTop: ".5rem",
                    borderRadius: ".2rem",
                  }}
                ></p>
                <p
                  className="animateShimmer"
                  style={{
                    height: ".5rem",
                    width: "30%",
                    marginTop: ".5rem",
                    borderRadius: ".2rem",
                  }}
                ></p>
                <p
                  className="animateShimmer"
                  style={{
                    height: "15rem",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: ".2rem",
                  }}
                ></p>
                <p
                  className="animateShimmer"
                  style={{
                    height: "3rem",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: ".2rem",
                  }}
                ></p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default WSCart;
