const CAROUSEL_IMAGES = {
    carousel1 : require('./10cdnew.svg').default,
    carousel2 : require('./4999new.svg').default,
    carousel3: require('./codnew.svg').default,
    carousel4 : require('./28000new.svg').default
}

const MY_ACCOUNT_IMAGES = {
    bounce : require('./Bounce.svg').default,
    changeLanguage : require('./Change Language.svg').default,
    csv : require('./CSV.svg').default,
    expiry : require('./Expiry.svg').default,
    orders : require('./Orders.svg').default,
    profile : require('./Profile.svg').default,
    returns : require('./Returns.svg').default,
    credit : require('./credit.svg').default,
    issue: require('./issue.svg').default,
    qna : require('./qna.svg').default,
}

const YOUR_EXPIRY_IMAGES = {
    noOrders : require('./No orders.svg').default,
}

const WHOLESALE_HEADER = {
    notification : require('./NotificationLogo.svg',).default,
    surgical: require('./SurgicalLogo.svg').default,
    generics : require('./GenericsLogo.svg').default,
    helpSupport : require('./HelpSupportIcon.svg').default,
    wholesaleLogo: require('./wholesale_logo.svg').default,
    wholesaleLogoPNG : require('./wholesaleHeader.png'),
}

const WHOLESALE_FOOTER = {
    wsHomeDisabled : require('./wsHomeFooterDisabled.svg').default,
    wsHomeEnabled : require('./wsHomeFooterEnabled.svg').default,
    wsOrdersDisabled : require('./wsOrdersFooterDisabled.svg').default,
    wsOrdersEnabled : require('./wsOrdersFooterEnabled.svg').default,
    wsCartDisabled : require('./wsCartFooterDisabled.svg').default,
    wsCartEnabled : require('./wsCartFooterEnabled.svg').default,    
}


const WHOLESALE_IMAGES = {
    image1: require('./WholeSale/group-34-1.svg').default,
    image2: require('./WholeSale/group-34-2.svg').default,
    image3: require('./WholeSale/group-34-3.svg').default,
    image4: require('./WholeSale/group-34-4.svg').default,
    image5: require('./WholeSale/group-34-5.svg').default,
    image6: require('./WholeSale/group-34-6.svg').default,
    image7: require('./WholeSale/group-34-7.svg').default,
    image8: require('./WholeSale/group-34-8.svg').default,
    image9: require('./WholeSale/group-34-9.svg').default,
    image10: require('./WholeSale/group-34-10.svg').default,
    itemDelete : require('./WholeSale/ws-prod-delete.svg').default,
}

const CART_IMAGES = {
    emptyCart : require('./EmptyCart.svg').default
}

export {
    CAROUSEL_IMAGES,
    MY_ACCOUNT_IMAGES,
    YOUR_EXPIRY_IMAGES,
    WHOLESALE_HEADER,
    WHOLESALE_IMAGES,
    CART_IMAGES,
    WHOLESALE_FOOTER,
}