import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./Components/HomePage/HomePage";
import Login from "./Components/Onboard/Login";
import SearchPage from "./Components/Search/SearchPage";
import ProductList from "./Components/ProductList/ProductList";
import Companies from "./Components/Categories/Companies";
import SpecialOffersHomePage from "./Components/Categories/SpecialOffersHomePage";
import SpecialOffersProducts from "./Components/Categories/SpecialOffersProducts";
import FactoryToPharmacy from "./Components/Categories/FactoryToPharmacy";
import CoronaEssentials from "./Components/Categories/CoronaEssentials";
import RegularSchemes from "./Components/Categories/RegularSchemes";
import SchemesByDiscount from "./Components/Categories/SchemesByDiscount";
import Generic from "./Components/Categories/Generic";
import OTC from "./Components/Categories/OTC";
import Ayurvedic from "./Components/Categories/Ayurvedic";
import Surgicals from "./Components/Categories/Surgical";
import Allopathy from "./Components/Categories/Allopathy";
import Product from "./Components/Modals/Product";
import CompanyProducts from "./Components/ProductList/CompanyProducts";
import CoronaCategories from "./Components/Categories/CoronaCategories";
import Speciality from "./Components/Categories/Speciality";
import Cart from "./Components/Cart/Cart";
import Footer from "./Components/Footer/Footer";
import Onboarding from "./Components/Onboard/Onboarding";
import { checkAuth, refreshToken } from "./Actions/Auth";
import { Cart_Actions } from "./Actions/Cart";
import Orders from "./Components/MyAccount/Orders";
import MaintenanceModal from "./Components/Modals/UnderMaintenance";
import Redirect_login from "./Components/Onboard/redirect_login";
import Returns from "./Components/MyAccount/Returns";
import Profile from "./Components/MyAccount/Profile";
import BannerRedirect from "./Components/BannerRedirects/BannerRedirect";
import Bounce from "./Components/MyAccount/Bounce";
import Store from "./Components/MyAccount/Store";
import WalletHistory from "./Components/MyAccount/WalletHistory";
import ErrorModal from "./Components/Modals/ErrorModal";
import QandA from "./Components/Help/QandA";
import ReportIssue from "./Components/Help/ReportIssue";
import ReportIssueForm from "./Components/Help/ReportIssueForm";
import TicketResultScreen from "./Components/Help/TicketResultScreen";
import OrderWithIssueScreen from "./Components/Help/OrderWithIssueScreen";
import LiveOrderIssueScreen from "./Components/Help/LiveOrderIssueScreen";
import PastTicketsScreen from "./Components/Help/PastTicketsScreen";
import OrderMessageModal from "./Components/Modals/orderMessageModal";
import ApplyCredit from "./Components/ApplyCredit/ApplyCredit";
import RoutesTrack from "./RoutesTrack";
import Expiry from "./Components/Expiry/Expiry";
import Csv from "./Components/DownloadCSV/Csv";
import Home from "./Components/Wholesale/Home/Home";
import ProductsPrimary from "./Components/Wholesale/Products/ProductsPrimary";
import WSProductPage from "./Components/Wholesale/Products/WSProductPage";
import WSCart from "./Components/Wholesale/Cart/WSCart";
import PlaceOrder from "./Components/Wholesale/Cart/PlaceOrder";
import HomeProductList from "./Components/ProductList/HomeProductList";
import WSSearch from "./Components/Wholesale/Search/WSSearch";
import WSOrders from "./Components/Wholesale/Orders/WSOrders";
import Menu from "./Components/Wholesale/Menu/Menu";
import WSProfile from "./Components/Wholesale/Menu/WSProfile";
import ErrorHandler from "./Components/ErrorHandler/ErrorHandler";
import ProductNew from "./Components/Modals/ProductNew";
import WSFooter from "./Components/Wholesale/Footer/WSFooter";
import WSHeader from "./Components/Wholesale/Header/WSHeader";
import SharedProductList from "./Components/ProductList/SharedProductList";
import Silvercross from "./Components/Categories/Silvercross";
import { Gamification } from "./Components/Modals/Gamification";

export const userStatus = React.createContext();
export const usersData = React.createContext();
export const cartDataContext = React.createContext();
export const onboardingDataContext = React.createContext();
export const userObjectContext = React.createContext();
export const WSCartDataContext = React.createContext();
export const searchContext = React.createContext();
export const widgetContext = React.createContext();
export const forceBackNavContext = React.createContext();
export const appCategoriesDataContext = React.createContext();
export const WSWidgetDataContext = React.createContext();
export const networkStatusContext = React.createContext();

export const BannerData = React.createContext();
function App() {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});
  const [searchString, setSearchString] = useState("");
  const [productListHeader, setProductListHeader] = useState("");
  const [categories, setCategories] = useState();
  const [isUserEnabled, setIsUserEnabled] = useState(false);
  const [cartData, setCartData] = useState();
  const [showRecent, setShowRecent] = useState(false);
  const [bannerData, setBannerData] = useState();
  const [showMaintenance, setShowMaintenance] = useState(false);
  const [showSetPasswordScreen, setShowSetPasswordScreen] = useState(true);
  const [showZipCodeScreen, setShowZipCodeScreen] = useState(true);
  const showPassZipRef = useRef();
  const [userObject, setUserObject] = useState({});
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });

  const [errorHandler, setErrorHandler] = useState({
    show: false,
    data: null,
    fn: null,
  });
  const [orderMessage, setOrderMessage] = useState({
    show: false,
    content: "",
    header: "",
  });
  const [WSCartData, setWSCartData] = useState({});
  const [widgetData, setWidgetData] = useState([]);
  const [appCategoriesData, setAppCategoriesData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [WSWidgetData, setWSWidgetData] = useState([]);
  const [forceBackNav, setForceBackNav] = useState(false);
  const [showNetworkStatus, setShowNetworkStatus] = useState(false);
  const [networkStatus, setNetworkStatus] = useState(true);
  // useEffect(() => {
  //   showPassZipRef.current = {
  //     'showSetPasswordScreen' : showSetPasswordScreen,
  //     'showZipCodeScreen' : showZipCodeScreen,
  //   }
  //   console.log('showPassZipRef.current', showPassZipRef.current)
  // },[showSetPasswordScreen, showZipCodeScreen])

  const getSearchString = (data) => {
    setSearchString(data);
  };

  const getProductListHeader = (data) => {
    setProductListHeader(data);
  };

  const getCatgories = (data) => {
    setCategories(data);
  };

  const cartView = (retailerCode, id) => {
    const params = {};
    const body = {
      androidMap: {
        locale: "en",
        source: "pharmacyWeb",
        deviceId: null,
        appVersion: navigator.userAgent,
        deviceName: null,
        screen_name: "cart_screen",
        appVersionCode: "156",
      },
      retailerCode: retailerCode,
      retailerId: id,
    };
    Cart_Actions("VIEW_CART", params, body)
      .then((res) => {
        setCartData(res.data.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken()
            .then((res) => {
              cartView(retailerCode, id);
            })
            .catch((error) => {
              setErrorModal({
                show: true,
                content: "Something went wrong !",
              });
            });
        } else if (
          error.response.status === 502 ||
          error.response.status === 503
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error.response.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
          });
        } else if (error.response.status === 400) {
          setOrderMessage({
            show: true,
            content: error?.response?.data?.error?.error,
            header: `Info`,
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
          });
        }
      });
  };

  useEffect(() => {
    let userData = localStorage.getItem("userData");
    userData && setUserData(JSON.parse(userData));
    if (userData) {
      let data = JSON.parse(userData);
      //hit the check auth api to refresh the data
      let body = {
        mobileNumber: data?.mobileNo,
      };
      checkAuth(body).then((res) => {
        // update userData in local storage
        localStorage.setItem("userData", JSON.stringify(res.data.data.user));
        // update userData context
        setUserData(res.data.data.user);
        //update user enabled context
        setIsUserEnabled(res.data.data.user);
        //update zipcode screen context
        setShowZipCodeScreen(!data.zip);
      });

      // cartView(data.retailerCode, data.id);
      // setIsUserEnabled(data.enabled);
      setUserLoggedIn(true);
      // setShowZipCodeScreen(!data.zip);
    }
    let userAuth = localStorage.getItem("userAuth");
    if (userAuth) {
      let authData = JSON.parse(userAuth);
      console.log("authData.isPasswordSet", authData.data.isPasswordSet);
      setShowSetPasswordScreen(!authData.data.isPasswordSet);
    }

    if (
      localStorage.getItem("onBehalfRetCode") &&
      localStorage.getItem("enableStatus") !== "true"
    ) {
      setOrderMessage({
        show: true,
        content: "User is disabled",
        header: `Info`,
      });
    }
  }, []);

  // network status listener
  useEffect(() => {
    window.addEventListener("offline", () => {
      setNetworkStatus(false);
    });

    window.addEventListener("online", () => {
      setNetworkStatus(true);

      setShowNetworkStatus(true);
      setTimeout(() => {
        setShowNetworkStatus(false);
      }, 8000);
    });

    return () => {
      window.removeEventListener("offline", () => {
        setNetworkStatus(false);
      });

      window.removeEventListener("online", () => {
        setNetworkStatus(true);
      });
    };
  }, []);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
      />

      <OrderMessageModal
        show={orderMessage.show}
        content={orderMessage.content}
        header={orderMessage.header}
        onHide={() => {
          setOrderMessage({ show: false, content: "", header: "" });
        }}
      />
      <networkStatusContext.Provider
        value={{ networkStatus, showNetworkStatus }}
      >
        <forceBackNavContext.Provider value={{ forceBackNav, setForceBackNav }}>
          <WSWidgetDataContext.Provider
            value={{ WSWidgetData, setWSWidgetData }}
          >
            <appCategoriesDataContext.Provider
              value={{
                appCategoriesData,
                setAppCategoriesData,
                activeTab,
                setActiveTab,
              }}
            >
              <WSCartDataContext.Provider value={{ WSCartData, setWSCartData }}>
                <widgetContext.Provider value={{ widgetData, setWidgetData }}>
                  <searchContext.Provider value={{ showRecent, setShowRecent }}>
                    <userObjectContext.Provider
                      value={{ userObject, setUserObject }}
                    >
                      <onboardingDataContext.Provider
                        value={{ showPassZipRef }}
                      >
                        <usersData.Provider
                          value={{
                            userData,
                            setShowMaintenance,
                            setUserData,
                            setErrorHandler,
                          }}
                        >
                          <userStatus.Provider
                            value={{
                              userLoggedIn,
                              setUserLoggedIn,
                              isUserEnabled,
                              setIsUserEnabled,
                            }}
                          >
                            {/* <userStatus.Provider value={{ isUserEnabled, setIsUserEnabled }}> */}
                            <cartDataContext.Provider
                              value={{ cartData, setCartData }}
                            >
                              <BannerData.Provider
                                value={{ bannerData, setBannerData }}
                              >
                                <BrowserRouter>
                                  <RoutesTrack />
                                  {window.location.pathname.split("/")[1] ===
                                  "wholesale" ? (
                                    <WSHeader
                                      getSearchString={getSearchString}
                                      userLoggedIn={userLoggedIn}
                                      categories={categories}
                                    />
                                  ) : (
                                    <Header
                                      getSearchString={getSearchString}
                                      userLoggedIn={userLoggedIn}
                                      categories={categories}
                                    />
                                  )}

                                  <MaintenanceModal
                                    show={showMaintenance?.status}
                                    apiData={showMaintenance?.apiData}
                                    onHide={() => setShowMaintenance(false)}
                                  />
                                  <ErrorHandler data={errorHandler} />
                                  <Routes>
                                    <Route
                                      exact
                                      path="/"
                                      element={<Redirect_login />}
                                    />
                                    <Route
                                      exact
                                      path="/home"
                                      element={
                                        <HomePage
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                          userLoggedIn={userLoggedIn}
                                          getCatgories={getCatgories}
                                          isUserEnabled={isUserEnabled}
                                        />
                                      }
                                    />

                                    <Route
                                      exact
                                      path="/bannerRedirect"
                                      element={
                                        <BannerRedirect
                                          bannerData={bannerData}
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="/search/"
                                      element={
                                        <SearchPage
                                          userLoggedIn={userLoggedIn}
                                          string={searchString}
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="/search/:searchString"
                                      element={
                                        <SearchPage
                                          userLoggedIn={userLoggedIn}
                                          string={searchString}
                                        />
                                      }
                                    />

                                    <Route
                                      exact
                                      path="categories/companies"
                                      element={
                                        <Companies
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/companies/:scheme"
                                      element={
                                        <Companies
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="companies/:id"
                                      element={
                                        <CompanyProducts
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="companies/:scheme/:id"
                                      element={
                                        <CompanyProducts
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/specialOffers"
                                      element={
                                        <SpecialOffersHomePage
                                          userLoggedIn={userLoggedIn}
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/specialOffers/:id"
                                      element={
                                        <SpecialOffersProducts
                                          userLoggedIn={userLoggedIn}
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/factorytopharmacy"
                                      element={
                                        <FactoryToPharmacy
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/coronaEssentials"
                                      element={
                                        <CoronaEssentials
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/coronaEssentials/:pageId"
                                      element={
                                        <CoronaCategories
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/regularSchemes"
                                      element={
                                        <RegularSchemes
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/schemesByDiscount/:id"
                                      element={
                                        <SchemesByDiscount
                                          userLoggedIn={userLoggedIn}
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/generic"
                                      element={
                                        <Generic
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/Specialty"
                                      element={
                                        <Speciality
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/OTC"
                                      element={
                                        <OTC
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/Ayurvedic"
                                      element={
                                        <Ayurvedic
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/Surgicals"
                                      element={
                                        <Surgicals
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="categories/Allopathy"
                                      element={
                                        <Allopathy
                                          userLoggedIn={userLoggedIn}
                                          getProductListHeader={
                                            getProductListHeader
                                          }
                                        />
                                      }
                                    />
                                    {/* Silvercross page route */}
                                    <Route exact path="silvercross" element={<Silvercross />} />
                                    <Route
                                      exact
                                      path="account/yourOrders"
                                      element={<Orders />}
                                    />
                                    <Route
                                      exact
                                      path="account/yourBounces"
                                      element={<Bounce />}
                                    />

                                    <Route
                                      exact
                                      path="/products/:id"
                                      element={
                                        <ProductList
                                          userLoggedIn={userLoggedIn}
                                          productListHeader={productListHeader}
                                        />
                                      }
                                    />

                                    <Route
                                      exact
                                      path="/homeProducts/:id"
                                      element={<HomeProductList />}
                                    />
                                    <Route
                                      exact
                                      path="/product-old/:id"
                                      element={
                                        <Product userLoggedIn={userLoggedIn} />
                                      }
                                    />

                                    <Route
                                      exact
                                      path="/product/:id"
                                      element={
                                        <ProductNew
                                          userLoggedIn={userLoggedIn}
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="/product/substitute"
                                      element={<SharedProductList />}
                                    />
                                    <Route
                                      exact
                                      path="/product/composition"
                                      element={<SharedProductList />}
                                    />
                                    <Route
                                      exact
                                      path="account/profile"
                                      element={<Profile />}
                                    />
                                    <Route
                                      exact
                                      path="account/yourReturns"
                                      element={<Returns />}
                                    />

                                    <Route
                                      exact
                                      path="cart"
                                      element={
                                        <Cart userLoggedIn={userLoggedIn} />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="login"
                                      element={
                                        <Login userLoggedIn={userLoggedIn} />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="onboarding"
                                      element={
                                        <Onboarding
                                          isUserEnabled={isUserEnabled}
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="store"
                                      element={<Store />}
                                    />
                                    <Route
                                      exact
                                      path="store/walletHistory"
                                      element={<WalletHistory />}
                                    />
                                    <Route
                                      path="*"
                                      element={
                                        <BannerRedirect bannerData={false} />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="/applyCredit"
                                      element={<ApplyCredit />}
                                    />
                                    <Route
                                      exact
                                      path="/help-support/QandA"
                                      element={<QandA />}
                                    />
                                    <Route
                                      exact
                                      path="/help-support/ReportIssue"
                                      element={<ReportIssue />}
                                    />
                                    <Route
                                      exact
                                      path="/help-support/ReportIssue/:id/:orderId"
                                      element={<ReportIssueForm />}
                                    />
                                    <Route
                                      exact
                                      path="/help-support/ReportIssue/:id"
                                      element={<ReportIssueForm />}
                                    />
                                    <Route
                                      exact
                                      path="/help-support/ReportIssue/status/:id"
                                      element={<TicketResultScreen />}
                                    />
                                    <Route
                                      exact
                                      path="help-support/ReportIssue/OrdersWithIssue/:orderType"
                                      element={<OrderWithIssueScreen />}
                                    />
                                    <Route
                                      exact
                                      path="help-support/ReportIssue/OrdersWithIssue/:orderType/raiseIssue/liveOrderIssue/:orderId"
                                      element={<LiveOrderIssueScreen />}
                                    />
                                    <Route
                                      exact
                                      path="/help-support/ReportIssue/pastTickets"
                                      element={<PastTicketsScreen />}
                                    />
                                    <Route
                                      exact
                                      path="/yourExpiry"
                                      element={<Expiry />}
                                    />
                                    <Route
                                      exact
                                      path="/downloadCsv"
                                      element={<Csv />}
                                    />
                                    <Route
                                      exact
                                      path="/campaign-gifts"
                                      element={<Gamification />}
                                    />
                                    <Route
                                      exact
                                      path="/wholesale/home"
                                      element={<Home />}
                                    />
                                    <Route
                                      exact
                                      path="/wholesale/:pageType"
                                      element={<ProductsPrimary />}
                                    />
                                    <Route
                                      exact
                                      path="/wholesale/:pageType/:margin"
                                      element={<ProductsPrimary />}
                                    />
                                    <Route
                                      exact
                                      path="/wholesale/product/:id"
                                      element={<WSProductPage />}
                                    />
                                    <Route
                                      exact
                                      path="/wholesale/home/cart"
                                      element={<WSCart />}
                                    />
                                    <Route
                                      exact
                                      path="/wholesale/home/cart/placeOrder"
                                      element={<PlaceOrder />}
                                    />
                                    <Route
                                      exact
                                      path="/wholesale/home/search/"
                                      element={<WSSearch />}
                                    />
                                    <Route
                                      exact
                                      path="/wholesale/home/search/:query"
                                      element={<WSSearch />}
                                    />
                                    <Route
                                      exact
                                      path="/wholesale/home/orders"
                                      element={<WSOrders />}
                                    />
                                    <Route
                                      exact
                                      path="/wholesale/home/menu"
                                      element={<Menu />}
                                    />
                                    <Route
                                      exact
                                      path="/wholesale/home/profile"
                                      element={<WSProfile />}
                                    />
                                  </Routes>
                                  <Footer userLoggedIn={userLoggedIn} />
                                  <WSFooter />
                                </BrowserRouter>
                              </BannerData.Provider>
                            </cartDataContext.Provider>
                          </userStatus.Provider>
                          {/* </userStatus.Provider> */}
                        </usersData.Provider>
                      </onboardingDataContext.Provider>
                    </userObjectContext.Provider>
                  </searchContext.Provider>
                </widgetContext.Provider>
              </WSCartDataContext.Provider>
            </appCategoriesDataContext.Provider>
          </WSWidgetDataContext.Provider>
        </forceBackNavContext.Provider>
      </networkStatusContext.Provider>
    </>
  );
}

export default App;
