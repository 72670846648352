import React, { Fragment, useEffect, useState, useContext } from "react";
import { Container, Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router";
import { banner_api } from "../../Actions/Widgets";
import NoData from "../../Images/png/no_data.png";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import ProductCard2 from "../ProductCard/ProductCard2";
import { refreshToken } from "../../Actions/Auth";
import { forceBackNavContext, usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";

const BannerRedirect = ({ bannerData }) => {
  let navigate = useNavigate();
  let obj = null;
  const [productRes, setProductRes] = useState(obj);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [apiData, setApiData] = useState({ show: false, data: "" });
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [urlData, setUrl] = useState();
  const { setShowMaintenance } = useContext(usersData);
  const fetchMoreData = () => {
    setPage(+page + 1);
  };
  const {forceBackNav, setForceBackNav} = useContext(forceBackNavContext)

  const location = useLocation();

  useEffect(() => {
    console.log(bannerData)
     // if coming from child components, redirect to the previous screen
     if(forceBackNav){
      // reset the context state
      setForceBackNav(false)
      // navigate to the previous screen
      navigate(-1)
     }

    let link = bannerData && bannerData.deepLink.split("/").slice(3);
    console.log({link})
    if (bannerData) {
      if (link[0] === "p") {
        navigate(`/product/${link[1]}`, {state : {from : location.pathname}});
      } else if (link[0] === "pdd") {
        navigate(`/categories/specialOffers/DealOfTheDay`);
      } else if (link[0] === "pbb") {
        navigate(`/categories/specialOffers/BulkByProducts`);
      } else if (link[0] === "pgd") {
        navigate(`/categories/specialOffers/SpecialRate`);
      } else if (link[0] === "c") {
        console.log(link[5]);
        if (link[1] === "companySection" && !link[2]) {
          navigate("/categories/companies");
        } else if (link[1] === "companySection" && link[2]) {
          navigate(`/companies/${link[2]}`, {
            state: { from: location.pathname },
          });
        } else if (link[1] === "coronaEssentials") {
          navigate(`/categories/coronaEssentials`);
        } else if (link[1] === "specialOffers" && !link[2]) {
          navigate(`/categories/specialOffers`);
        } else if (link[1] === "specialOffers" && link[2] === "dealOfTheDay") {
          navigate(`/categories/specialOffers/DealOfTheDay`);
        } else if (link[1] === "specialOffers" && link[2] === "specialDeal") {
          navigate(`/categories/specialOffers/SpecialRate`);
        } else if (link[1] === "specialOffers" && link[2]) {
          navigate(`/categories/specialOffers/${link[2]}`);
        } else if (link[1] === "regularSchemes") {
          navigate(`/categories/regularSchemes`);
        } else if (link[1] === "ayurvedic") {
          navigate(`/categories/ayurvedic`);
        } else if (link[1] === "allopathy") {
          navigate(`/categories/allopathy`);
        } else if (link[1] === "surgical") {
          navigate(`/categories/surgical`);
        } else if (link[1] === "speciality") {
          navigate(`/categories/speciality`);
        } else if (link[1] === "exclusive") {
          navigate(`/categories/exclusive`);
        } else if (link[1] === "generic") {
          navigate(`/categories/generic`);
        }
      } else if (link[0] === "forms") {
        window.open(bannerData.deepLink);
        navigate("/home");
      } else if (link[0] === "file") {
        window.open(bannerData.deepLink);
        navigate("/home");
      } else {
        let split = link[0].split("l?");
        function getParameterByName(name, url = bannerData.deepLink) {
          name = name.replace(/[\[\]]/g, "\\$&");
          var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
          if (!results) return null;
          if (!results[2]) return "";
          return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        let data = {};
        data.title = getParameterByName("title");
        data.paginated = getParameterByName("paginated");
        data.section = getParameterByName("section");
        data.endPoint = getParameterByName("endPoint");
        console.log(data);
        if (data.endPoint) {
          setApiData({ show: true, data: data });
        } else {
          navigate("/home");
        }
      }
    } else {
      navigate("/home");
    }
  }, [bannerData]);

  useEffect(() => {
    if (apiData.show) {
      let url = apiData.data.endPoint;
      let strPage = "page=" + page;
      let previousPage;
      if (page > 1) {
        previousPage = "page=" + (+page - 1);
      } else {
        previousPage = "page=0";
      }
      let reg = new RegExp(previousPage, "g");
      if (urlData) {
        url = urlData.replace(reg, strPage);
      } else {
        url = url.replace(reg, strPage);
      }
      setUrl(url);
      let apiCall = () => {
        banner_api(url)
          .then((res) => {
            let obj =
              productRes && productRes.medicinesResponsesList
                ? productRes
                : { medicinesResponsesList: [] };
            if (res.data.data.medicinesResponsesList.length > 0) {
              obj.medicinesResponsesList.push(
                ...res.data.data.medicinesResponsesList
              );
              setDataLength(obj.medicinesResponsesList.length);
            }

            setProductRes(obj);

            if (
              res.data.data.size > 0 &&
              obj.medicinesResponsesList.length < 15
            ) {
              fetchMoreData();
            } else if (
              res.data.data.size > 0 &&
              res.data.data.medicinesResponsesList.length === 0
            ) {
              fetchMoreData();
            }

            if (res.data.data.size === 0) {
              setHasMore(false);
            }
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshToken().then((res) => {
                apiCall();
              });
            } else if (
              error?.response?.status === 502 ||
              error?.response?.status === 503 ||
              error?.response?.status === 504
            ) {
              setShowMaintenance({ status: true, apiData: error?.response });
            } else if (error?.response?.status === 500) {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: "Oops something went wrong !",
                tryAgain: function () {
                  apiCall();
                },
              });
            } else {
              setErrorModal({
                show: true,
                apiData: error?.response,
                tryAgain: function () {
                  apiCall();
                },
                content: error?.response?.data?.error?.error
                  ? error.response.data.error.error
                  : "Something went wrong!",
              });
            }
          });
      };
      apiCall();
    }
  }, [apiData, page]);

  return (
    <Fragment>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        tryAgain={() => {
          errorModal.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Container fluid className="productList_cont">
        <Container fluid className="productListHeader font_ubuntu">
          {" "}
          <HiOutlineArrowNarrowLeft
            className="cursor_pointer goBack"
            onClick={() => navigate(-1)}
          />{" "}
          &nbsp;&nbsp;&nbsp; {apiData.data.title}
        </Container>

        {productRes && productRes.medicinesResponsesList.length > 0 && (
          <div
            id="scrollableDiv"
            className="productListDiv"
            style={{ paddingTop: "0rem" }}
          >
            <InfiniteScroll
              dataLength={dataLength}
              next={fetchMoreData}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
              scrollThreshold="90%"
              className="infiniteScroll"
              loader={
                <Spinner
                  animation="border"
                  className="color_secondary spinner"
                  size="lg"
                />
              }
            >
              {productRes &&
                productRes.medicinesResponsesList.map((med, index) => (
                  <ProductCard2 position={index} key={index} medData={med} />
                ))}
            </InfiniteScroll>
          </div>
        )}

        {productRes && productRes.medicinesResponsesList.length === 0 && (
          <div
            id="scrollableDiv"
            className="productList"
            style={{ paddingTop: "50px" }}
          >
            <div className="no_data_parent">
              <img src={NoData} alt="No Data" />
              <br /> <br />
              <p className="font_ubuntu font_16 font_weight_md">
                No Products Found !
              </p>
            </div>
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default BannerRedirect;
