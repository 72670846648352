import React from "react";
import { Button, Modal } from "react-bootstrap";
import MapComponent from "../Onboard/Maps/MapComponent";
import "./MapModal.css";

function MapModal(props) {
  // const [address, setAddress] = useState('')
  return (
        <Modal
          siz="lg"
          centered
          show={props.show}
          className="map-modal"
          // backdrop="static"
          keyboard={false}
          contentClassName="map-modal-content"
          {...props}
        >
          <Modal.Body className="map-modal-body">
            <MapComponent
              setAddress={props.setAddress}
              initialCoords={props.coordinates}
              locationFetchInProgress = {props.locationFetchInProgress}
              setGeocodeShimmer = {props.setGeocodeShimmer}
            />
          </Modal.Body>
          <Modal.Footer className="map-modal-footer">
            {props.address.formatted_address &&
              props.address?.formatted_address.length > 0 && !props.locationFetchInProgress && !props.geocodeShimmer &&(
                <span>{props.address?.formatted_address}</span>
              )}

              {props.geocodeShimmer && (
                <span>
                  <div className="animateShimmer" style={{width:'10rem', height: '.5rem'}}></div>
                  <div className="animateShimmer" style={{width:'13rem', height: '.5rem', marginTop: '.5rem'}}></div>
                </span>
              )}
            <Button onClick={props.onHide}>Submit</Button>
          </Modal.Footer>
        </Modal>
  );
}

export default MapModal;
