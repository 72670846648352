import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Common_Event_Hit } from "../../Actions/Events";
import { common_product_widget } from "../../Actions/Widgets";
import { getScreen } from "../../Actions/Screens";
import { refreshToken } from "../../Actions/Auth";
import {
  cartDataContext,
  networkStatusContext,
  searchContext,
  usersData,
  WSCartDataContext,
} from "../../App";
import { RiUpload2Fill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import { getData } from "../../Actions/Product";
import { Cart_Actions } from "../../Actions/Cart";
import ErrorModal from "../Modals/ErrorModal";
import { Card, Col, FormControl, InputGroup, Spinner } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import {
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdDelete,
  MdNavigateNext,
} from "react-icons/md";
import { GrHistory } from "react-icons/gr";
import { AiOutlineSearch } from "react-icons/ai";
import ButtonLoading1 from "../Spinners/ButtonLoading1";
import { useServiceWorker } from "../../useServiceWorker";
import { getWSSearchMedicines } from "../../Actions/WholeSale/Search";
import {
  addMedicineToCart,
  deleteItemFromCart,
  editCartMedicineQuantity,
} from "../../Actions/WholeSale/WSCart";
import { getWSProductPrice } from "../../Actions/WholeSale/ProductPage";
import cx from "classnames";

function SearchBarComponent({ userLoggedIn, headerType }) {
  const [promises, setPromises] = useState([]);
  const [widgetData, setWidgetData] = useState([]);
  const [showRecentShimmer, setShowRecentShimmer] = useState(false);
  const [promisesData, setPromisesData] = useState([]);
  const { showRecent, setShowRecent } = useContext(searchContext);
  const navigate = useNavigate();
  let location = useLocation();
  const activeItemRef = useRef();
  const searchRef = useRef();
  const quantityInputRef = useRef();
  const [searchString, setSearchString] = useState("");
  const [showProductsShimmer, setShowProductsShimmer] = useState(false);
  const [searchProducts, setSearchProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeItem, setActiveItem] = useState(0);

  const [activeSection, setActiveSection] = useState("product");
  const [isRemovingFromCart, setIsRemovingFromCart] = useState(false);
  const { cartData, setCartData } = useContext(cartDataContext);
  const [secondaryState, setSecondaryState] = useState(false);
  const [cartItemEditQuantity, setCartItemEditQuantity] = useState(false);
  const [currentCartItem, setCurrentCartItem] = useState(null);
  const [clearCartSpinner, setClearCartSpinner] = useState(false);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [isFetchingProductPrice, setIsFetchingProductPrice] = useState(false);
  const [productPrice, setProductPrice] = useState({});
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [editCartLoader, setEditCartLoader] = useState(false);
  const [quantityInputErrorMessage, setQuantityInputErrorMessage] =
    useState("");
  const [quantityErrorAnimation, setQuantityErrorAnimation] = useState(false);
  const [hideSearchCart, setHideSearchCart] = useState(false);
  const { networkStatus, showNetworkStatus } = useContext(networkStatusContext);
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const { WSCartData, setWSCartData } = useContext(WSCartDataContext);
  const [userData, setUserData] = useState();

  //get search screen widgets and update the respective states
  const getSearchWidgets = async () => {
    //donot hit the api for wholesale header
    if (headerType === "WHOLESALE") return;
    headerType === "RETAIL" &&
      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "search_bar_tap",
      });

    //update the shimmer before the api call
    setShowRecentShimmer(true);
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    let params = {
      retailerId: userData?.id,
      screenName: "search_screen",
    };
    try {
      const res = await getScreen("HOME_SCREEN", params, {});
      //donot update shimmer state here. only update after promise.all resolves
      //update widget state
      setWidgetData(res?.data?.data);
      //push the api calls to a promises array
      let promises = [];
      res?.data?.data?.map((item, index) => {
        let params = {};
        if (
          item.widgetName === "recent_widget" &&
          item.data.title === "Recent Search"
        ) {
          params = {
            size: 6,
          };
        } else if (
          item.widgetName === "recent_widget" &&
          item.data.title === "Top Searches"
        ) {
          params = {
            size: 6,
          };
        } else if (
          item.widgetName === "product_list_widget" &&
          (item.data.title === "Trending Products" ||
            item.data.title === "Recommended Products")
        ) {
          params = {
            size: 20,
            screenName: "searchPage",
          };
        } else if (
          item.widgetName === "product_list_widget" &&
          item.data.title === "Order Again"
        ) {
          params = {
            size: 6,
          };
        }
        // push all promises into the promise array
        promises.push({
          func: common_product_widget,
          endPoint: item?.data?.apiEndPoint,
          params: params,
          name: item?.widgetName,
          title: item?.data?.title,
        });
      });
      //update the promises state
      setPromises(promises);

      //make all widget calls async
      Promise.all(
        promises.map((item, _) => item.func(item.endPoint, item.params))
      )
        .then((res) => {
          setPromisesData(res);
          //update the shimmer state
          setShowRecentShimmer(false);
        })
        .catch((error) => {
          //update the shimmer state if any error
          setShowRecentShimmer(false);
        });
    } catch (error) {
      //update the shimmer if there's an error
      setShowRecentShimmer(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getSearchWidgets();
        });
      }
    }
  };

  //handler to close the search autocomplete
  const handleHideAutoComplete = (e) => {
    setShowRecent(false);
    //make the page scrollable after closing the search autocomplete
    //remove the classname
    document.body.className = "";
  };

  //   handler to select item with cursor from the autocomplete list
  const handleSelectAutoComplete = (e, search) => {
    //prevent event bubbling to the parent element
    e.nativeEvent.stopImmediatePropagation();
    //remove overlay layer
    document.body.className = "";
    setShowRecent(false);
    searchRef.current.blur();
    //if last character is space, pop it from the string and add encoded %20 -> equivalent to space
    let pop = search.charAt(search.length - 1);
    if (pop === " ") {
      let val = search.trim();
      navigate(`/search/${val}%20`);
    } else {
      navigate(`/search/${search}`);
    }
  };

  //   api to get search medicines
  const searchMeds = (event) => {
    if (window.innerWidth <= 1000) {
      setSearchString(event.target.value);
      // getSearchString(event.target.value);
      let pop = event.target.value.charAt(event.target.value.length - 1);
      if (pop === " ") {
        let val = event.target.value.trim();
        headerType === "RETAIL"
          ? navigate(`/search/${val}%20`)
          : navigate(`/wholesale/home/search/${val}%20`);
      } else {
        headerType === "RETAIL"
          ? navigate(`/search/${event.target.value}`)
          : navigate(`/wholesale/home/search/${event.target.value}`);
      }
    } else {
      if (location.pathname.split("/")[1] === "search") return;
      event.preventDefault();
      //prevent api hit when pressing down arrow, up arrow
      if (event.keyCode === 40 || event.keyCode === 38) return;
      //onfocus make the body non scrollable
      document.body.className = "overflow-hidden";
      event.preventDefault();
      //show the recent items panel on focus
      setShowRecent(true);
      //hit recent searches
      if (!event.target.value && event.keyCode !== 13) getSearchWidgets();
      return;
    }
  };

  //api to get the search products and updates the respective state
  const getSearchProducts = async (search) => {
    //update the shimmer state
    setShowProductsShimmer(true);
    //reset the search products
    setSearchProducts([]);

    let params = {
      page: 0,
    };

    if (headerType === "WHOLESALE") {
      params.searchString = search;
    }

    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "search_products",
      search_string: search,
    });

    try {
      let res;
      if (headerType === "RETAIL") {
        res = await getData("PRODUCTS_BY_SEARCH", params, search);
      } else if (headerType === "WHOLESALE") {
        res = await getWSSearchMedicines(params, {});
      }

      //only update the res if it matches the payload from the response to search string
      //this avoids async state update for the previous network calls showing wrong results
      if (
        res.config.data.toLowerCase() ===
          searchRef.current.value.toLowerCase() ||
        res.config.params.searchString.toLowerCase() ===
          searchRef.current.value.toLowerCase()
      ) {
        //response could be null, if null update the state with an empty array rather than null val
        //this prevents the no products found in the quick search
        //TODO: test the effects with the retail search
        setSearchProducts(
          headerType === "RETAIL"
            ? res?.data?.medicinesResponsesList === null
              ? []
              : res?.data?.medicinesResponsesList
            : res?.data?.data?.medicineResponseList === null
            ? []
            : res?.data?.data?.medicineResponseList
        );
        //update the shimmer state
        setShowProductsShimmer(false);
        //update the search string with the first name in the search list
        if (location.pathname.split("/")[1] !== "search") {
          setSearchQuery(searchRef.current.value);
        }
        // setSearchString(res?.data?.medicinesResponsesList[0].name);
      } else {
        setShowProductsShimmer(true);
      }

      if (searchRef.current.value.length === 0) setShowProductsShimmer(false);

      //reset the active item to 0 to enable navigation
      setActiveItem(0);
    } catch (error) {
      //update the shimer state if any error
      setShowProductsShimmer(false);
      if (error.response.status === 401) {
        refreshToken().then((res) => {
          getSearchProducts(search);
        });
      }
    }
  };

  //search string state update
  const handleSearchString = (e) => {
    //reset the searchProducts state when input is changed
    // setSearchProducts([])
    if (location.pathname.split("/")[1] === "search") {
      let pop = e.target.value.charAt(e.target.value.length - 1);
      if (pop === " ") {
        let val = e.target.value.trim();
        headerType === "RETAIL"
          ? navigate(`/search/${val}%20`)
          : navigate(`/wholesale/home/search/${val}%20`);
      } else {
        headerType === "RETAIL"
          ? navigate(`/search/${e.target.value}`)
          : navigate(`/wholesale/home/search/${e.target.value}`);
      }
      return;
    }
    //if down arrow, up arrow, enter is pressed return
    if (
      e.keyCode === 40 ||
      e.keyCode === 38 ||
      e.keyCode === 13 ||
      e.keyCode === 9
    )
      return;
    setSearchString(e.target.value);

    //guardian check to exit if the search string is empty
    if (!e.target.value) return;
    //call debouncing on search string state
    // debouncedSearch(e.target.value);
    setShowProductsShimmer(true);
    search(e.target.value);
  };

  //   debouncing logic
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };
  const search = useCallback(debounce(getSearchProducts), [headerType]);

  //   handler for all the keyboard shortcuts
  const handleKeyboardNav = (e) => {
    //if backspace(mac)/delete(windows) is hit -> check is cart item is active -> remove cart item
    // mac -> delete -> keycode : 8 //also adds backspace to delete on windows
    // windows -> delete -> keycode : 46
    if (
      ((e.keyCode === 8 && navigator.userAgent.indexOf("Mac") !== -1) ||
        e.keyCode === 46) &&
      e.shiftKey
    ) {
      if (activeSection === "cart") {
        //also prevent the key hit to trigger the onChange of the search string
        e.preventDefault();
        //return if already a delete operation is in process
        if (isRemovingFromCart) return;
        //call delete cart with the item here
        handleRemoveFromCart(
          headerType === "RETAIL"
            ? cartData?.cartMedicineDetailResponseList[activeItem]
            : WSCartData?.cartMedicineDetailResponseList[activeItem],
          activeItem
        );
      }
    }

    //handle down arrow navigation
    if (e.keyCode === 40) {
      e.preventDefault();
      e.stopPropagation();

      //donot handle navigation when clear cart in progrees
      // if(clearCartSpinner) return;

      //disable navigation on recents screen
      if (
        searchString?.length === 0 ||
        !searchProducts ||
        showProductsShimmer
      ) {
        // setActiveItem(0);
        // setActiveSection("product");
        return;
      }
      if (
        activeSection === "product" &&
        activeItem === searchProducts.length - 1
      )
        return;
      if (
        activeSection === "cart" && headerType === "RETAIL"
          ? activeItem === cartData?.cartMedicineDetailResponseList?.length - 1
          : activeSection === "cart" && headerType !== "RETAIL"
          ? activeItem ===
            WSCartData?.cartMedicineDetailResponseList?.length - 1
          : null
      )
        return;

      if (activeSection === "product")
        setSearchString(searchProducts[activeItem + 1]?.name);
      if (activeItem === null) setActiveItem(0);
      else setActiveItem((prevItem) => prevItem + 1);
    }

    if (e.keyCode === 27) {
      document.body.className = "";
      setShowRecent(false);
      searchRef.current.blur();
      setSearchString("");
      setSearchProducts([]);
    }

    //handle up arrow navigation
    if (e.keyCode === 38 && e.target.value) {
      e.preventDefault();
      e.stopPropagation();
      if (
        searchString?.length === 0 ||
        !searchProducts ||
        showProductsShimmer
      ) {
        setActiveItem(0);
        setActiveSection("product");
        return;
      }
      //if initial item, return
      if (activeItem === 0) return;

      if (activeSection === "product" && activeItem > 0)
        setSearchString(searchProducts[activeItem - 1]?.name);
      if (activeItem === null) setActiveItem(0);
      else setActiveItem((prevItem) => prevItem - 1);
    }

    //handle secndary item state on enter only when product section is active
    if (
      e.keyCode === 13 &&
      !e.ctrlKey &&
      activeSection === "product" &&
      activeItemRef.current
    ) {
      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "search_dropdown_product_enter",
        pageId: "search_dropdown",
      });
      //update the secondary state of item
      setSecondaryState((prevState) => !prevState);
      e.preventDefault();
      e.stopPropagation();
    } else if (
      e.keyCode === 13 &&
      !e.ctrlKey &&
      activeSection === "cart" &&
      activeItemRef.current
    ) {
      e.preventDefault();
      e.stopPropagation();
      setCartItemEditQuantity((prevState) => !prevState);
      quantityInputRef.current.value =
        headerType === "RETAIL"
          ? cartData.cartMedicineDetailResponseList[e.target.id]?.quantity
          : WSCartData?.cartMedicineDetailResponseList[e.target.id]
              ?.orderedQuantity;
    }

    //right arrow -> open cart if closed
    // if (e.key === "ArrowRight") {
    //   e.stopPropagation();
    //   e.preventDefault();
    //   //reset secondary state if active
    //   setSecondaryState(false);
    //   //return if cart data is unavailable
    //   // if (!cartData?.cartMedicineDetailResponseList) return;
    //   //expand the cart if hide cart is active and the user presses right arrow
    //   if (hideSearchCart) setHideSearchCart((prevVal) => !prevVal);
    //   //update the cart section active state
    //   setActiveSection("cart");
    //   //reset the active item
    //   setActiveItem(0);
    // }

    // if (e.ctrlKey && e.keyCode === 'Enter') {
    //   e.preventDefault();
    //   e.stopPropagation();
    //   document.body.className = "";
    //   searchRef.current.blur();
    //   setShowRecent(false);
    //   navigate("/cart");
    // }
  };

  //handler to remove item from cart
  const handleRemoveFromCart = (item, index) => {
    //call the delete from cart API
    removeFromCart(item, index);
    //update current cart loader item state
    setCurrentCartItem(index);
  };

  //api call to remove item from cart
  const removeFromCart = async (item, index) => {
    //donot make the api call if the item is unedefined
    if (!item) return;
    //update the loader state
    setIsRemovingFromCart(true);
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    const params = {};
    let body;
    if (headerType === "RETAIL") {
      body = {
        androidMap: {},
        medicineId: item.medicineId,
        retailerCode: data.retailerCode,
        retailerId: data.id,
      };
    } else if (headerType === "WHOLESALE") {
      body = {
        androidMap: {},
        cartId: WSCartData.id,
        cartMedicineId: item.id,
        retailerCode: data.retailerCode,
      };
    }

    //handle the active item in the cart
    if (
      index === 0 && headerType === "RETAIL"
        ? cartData?.cartMedicineDetailResponseList?.length === 1
        : WSCartData?.cartMedicineDetailResponseList?.length === 1
    ) {
      //removing the last element in cart -> set active item back to the product section
      setActiveItem(index);
      setActiveSection("product");
      //scroll to the top on product section
      document.getElementById("scrollableDiv").scrollTo(0, 0);
    } else if (
      index === 0 && headerType === "RETAIL"
        ? cartData?.cartMedicineDetailResponseList?.length > 0
        : WSCartData?.cartMedicineDetailResponseList?.length > 0
    ) {
      setActiveItem(0);
      setActiveSection("cart");
    } else {
      setActiveItem(index - 1);
    }

    try {
      let res;
      if (headerType === "RETAIL") {
        res = await Cart_Actions("REMOVE_FROM_CART", params, body);
      } else {
        res = await deleteItemFromCart(body);
      }
      //update the cart data
      if (headerType === "RETAIL") setCartData(res?.data?.data);
      else setWSCartData(res.data.data);
      //update the loader states
      setIsRemovingFromCart(false);
      setClearCartSpinner(false);
    } catch (error) {
      //update the loader states
      setIsRemovingFromCart(false);
      setClearCartSpinner(false);
      if (error.response.status === 401) {
        refreshToken().then((res) => {
          removeFromCart(item);
        });
      } else if (
        error.response.status === 502 ||
        error.response.status === 503 ||
        error.response.status === 504
      ) {
        setShowMaintenance({ status: true, apiData: error?.response });
      } else if (error.response.status === 500) {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: "Oops something went wrong !",
          tryAgain: function () {
            removeFromCart(item);
          },
        });
      } else {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            removeFromCart(item);
          },
        });
      }
    }
  };

  //api to fetch the individual product price
  const getProductPrice = async (id, quantity, batchId) => {
    //update the loader state
    setIsFetchingProductPrice(true);
    let params = {};
    if (headerType === "RETAIL") {
      params.medicineId = id;
      params.quantity = quantity;
    } else if (headerType === "WHOLESALE") {
      params.batchId = batchId;
      params.slabId = 0;
      params.requestedQuantity = quantity;
    }
    let res;
    try {
      if (headerType === "RETAIL") {
        res = await getData("PRODUCTPRICE_BY_ID", params, {});
      } else {
        res = await getWSProductPrice(params);
      }
      setProductPrice(res?.data?.data);
      //update the loader state
      setIsFetchingProductPrice(false);
    } catch (error) {
      //update the loader state
      setIsFetchingProductPrice(false);
      //update the loading state if error
      setIsAddingToCart(false);
      if (error.response.status === 401) {
        refreshToken().then((res) => {
          getProductPrice(id, quantity);
        });
      } else if (
        error.response.status === 502 ||
        error.response.status === 503 ||
        error.response.status === 504
      ) {
        setShowMaintenance({ status: true, apiData: error?.response });
      } else if (error.response.status === 500) {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: "Oops something went wrong !",
          tryAgain: function () {
            getProductPrice(id, quantity);
          },
        });
      } else {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getProductPrice(id, quantity);
          },
        });
      }
    }
  };

  //api to edit cart item quantity
  const editCartQuantity = async () => {
    if (quantityInputRef.current) {
      //update the loader state
      setEditCartLoader(true);

      let item =
        headerType === "RETAIL"
          ? cartData.cartMedicineDetailResponseList[quantityInputRef.current.id]
          : WSCartData.cartMedicineDetailResponseList[
              quantityInputRef.current.id
            ];
      let data = localStorage.getItem("userData");
      data = JSON.parse(data);
      const params = {};
      let body;
      if (headerType === "RETAIL") {
        body = {
          androidMap: {
            locale: "en",
            source: "pharmacyWeb",
            deviceId: null,
            appVersion: navigator.userAgent,
            deviceName: null,
            screen_name: "cart_screen",
            appVersionCode: "156",
          },
          medicineId: item.medicineId,
          quantity: quantityInputRef.current.value,
          retailerCode: data.retailerCode,
          retailerId: data.id,
          selectedSlotName: item.selectedSlotName,
        };
      } else if (headerType === "WHOLESALE") {
        //build the payload
        body = {
          androidMap: {},
          batchId: item.batchId,
          medicineId: item.medicineId,
          quantity: quantityInputRef.current.value,
          retailerCode: userData.retailerCode,
          slapId: 0,
        };
      }

      try {
        let res;
        if (headerType === "RETAIL") {
          res = await Cart_Actions("EDIT_CART", params, body);
        } else {
          res = await editCartMedicineQuantity(body);
        }
        //update the cart data
        if (headerType === "RETAIL") setCartData(res.data.data);
        else setWSCartData(res.data.data);
        //focus back on the main search ref
        searchRef.current.focus();
        searchRef.current.select();
        //reset the cart input quantity focus state
        setCartItemEditQuantity(false);
        //update the loader state
        setEditCartLoader(false);
      } catch (error) {
        //update the loader state if any error
        setEditCartLoader(false);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            editCartQuantity();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              editCartQuantity();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              editCartQuantity();
            },
          });
        }
      }
    }
  };

  //onChange handler for quantity input change
  const handleQuanityChange = (e, name, index) => {
    //reset error message if the item quantity has changed
    if (quantityInputErrorMessage) setQuantityInputErrorMessage("");
    //reset error animation if any
    if (quantityErrorAnimation) setQuantityErrorAnimation(false);

    //enter -> keycode : 13
    //escape -> keycode : 27
    if (e.keyCode === 13 || e.keyCode === 27) return;
    //handle add to cart when enter is pressed

    //if quanitity greater than available stock return and throw a message
    let message = "";
    if (e?.target?.value && quantityInputRef.current) {
      if (
        headerType === "RETAIL"
          ? e?.target?.value > searchProducts[index]?.availableQuantity
          : e?.target?.value > searchProducts[index]?.batchInfo?.quantity
      ) {
        message = `quantity entered [${
          quantityInputRef.current.value
        }] can't be greater than available stock ${
          headerType === "RETAIL"
            ? searchProducts[index]?.availableQuantity
            : searchProducts[index]?.batchInfo?.quantity
        }`;
      } else if (
        headerType === "RETAIL"
          ? searchProducts[index]?.minOrderQuantity > 0 &&
            e?.target?.value < searchProducts[index]?.minOrderQuantity
          : searchProducts[index]?.batchInfo?.minOrderQuantity > 0 &&
            e?.target?.value <
              searchProducts[index]?.batchInfo?.minOrderQuantity
      ) {
        message = `quantity entered [${
          quantityInputRef.current.value
        }] can't be less than minimum order quantity ${
          headerType === "RETAIL"
            ? searchProducts[index]?.minOrderQuantity
            : searchProducts[index]?.batchInfo?.minOrderQuantity
        }`;
      } else if (
        searchProducts[index]?.maxOrderQuantity > 0 &&
        e?.target?.value > searchProducts[index]?.maxOrderQuantity
      ) {
        message = `quantity entered [${quantityInputRef.current.value}] can't be greater than maximum order quantity ${searchProducts[index]?.maxOrderQuantity}`;
      } else if (
        searchProducts[index]?.multiplyFactorForOrderQuantity > 0 &&
        parseInt(e?.target?.value) %
          parseInt(searchProducts[index]?.multiplyFactorForOrderQuantity) >
          0
      ) {
        message = `quantity entered [${quantityInputRef.current.value}] should be a multiple of ${searchProducts[index]?.multiplyFactorForOrderQuantity}`;
        quantityInputRef.current.value =
          Math.ceil(
            parseFloat(e?.target?.value) /
              parseFloat(searchProducts[index]?.multiplyFactorForOrderQuantity)
          ) * parseInt(searchProducts[index]?.multiplyFactorForOrderQuantity);
      }
      if (message) {
        setQuantityErrorAnimation(true);
      }
      setQuantityInputErrorMessage(message);
    }

    //get the price for the product
    if (e?.target?.value && !quantityErrorAnimation && message.length === 0)
      getProductPrice(
        searchProducts[index].medicineId,
        e?.target?.value,
        searchProducts[index]?.batchInfo?.batchId
      );
    else setProductPrice({});
  };

  //keydown handler for quantity input
  const handleQuantityInputShortcut = (e, index) => {
    //hit add to cart when enter is clicked
    if (
      e.keyCode === 13 &&
      activeSection === "product" &&
      quantityInputRef.current
    ) {
      e.preventDefault();
      e.stopPropagation();
      if (e?.target?.value > searchProducts[index]?.availableQuantity) {
        setQuantityErrorAnimation(true);
        return;
      }
      if (e?.target?.value > searchProducts[index]?.availableQuantity) {
        let message = `quantity entered [${quantityInputRef.current.value}] can't be greater than available stock ${searchProducts[index]?.availableQuantity}`;
        setQuantityErrorAnimation(true);
        setQuantityErrorAnimation(message);
        return;
      } else if (
        searchProducts[index]?.minOrderQuantity > 0 &&
        e?.target?.value < searchProducts[index]?.minOrderQuantity
      ) {
        let message = `quantity entered [${quantityInputRef.current.value}] can't be less than minimum order quantity ${searchProducts[index]?.minOrderQuantity}`;
        setQuantityErrorAnimation(true);
        setQuantityErrorAnimation(message);
        return;
      } else if (
        searchProducts[index]?.maxOrderQuantity > 0 &&
        e?.target?.value > searchProducts[index]?.maxOrderQuantity
      ) {
        let message = `quantity entered [${quantityInputRef.current.value}] can't be greater than maximum order quantity ${searchProducts[index]?.maxOrderQuantity}`;
        setQuantityErrorAnimation(true);
        setQuantityErrorAnimation(message);
        return;
      } else if (
        searchProducts[index]?.multiplyFactorForOrderQuantity > 0 &&
        parseInt(e?.target?.value) %
          parseInt(searchProducts[index]?.multiplyFactorForOrderQuantity) >
          0
      ) {
        let message = `quantity entered [${quantityInputRef.current.value}] should be a multiple of ${searchProducts[index]?.multiplyFactorForOrderQuantity}`;
        setQuantityErrorAnimation(true);
        setQuantityErrorAnimation(message);
        quantityInputRef.current.value =
          Math.ceil(
            parseFloat(e?.target?.value) /
              parseFloat(searchProducts[index]?.multiplyFactorForOrderQuantity)
          ) *
          parseInt(
            searchProducts[index]?.multiplyFactorForOrderQuantity
          ).toString();
        return;
      }
      //if quantity entered is empty -> donot hit the add to cart apo
      if (!e?.target?.value) {
        //set error quantity animation
        setQuantityErrorAnimation(true);
        return;
      }
      //call add to cart api
      addToCart(e?.target?.value, index);
    } else if (
      e.keyCode === 13 &&
      activeSection === "cart" &&
      quantityInputRef.current
    ) {
      //handle edit cart quantity here
      if (!e?.target?.value) {
        //set error quantity animation
        setQuantityErrorAnimation(true);
        return;
      }
      editCartQuantity();
    }

    //focus back on the main search when escape is pressed
    if (e.keyCode === 27 && activeSection === "product") {
      //prevent event bubbling so escape doesn't trigger on searchRef when focussing back on it
      e.stopPropagation();
      //focus is not handled in a useEffect below
      setSecondaryState(false);
      setCartItemEditQuantity(false);
      searchRef.current.focus();
      searchRef.current.select();
      //reset quantity error if any
      if (quantityErrorAnimation) setQuantityErrorAnimation(false);
    } else if (e.keyCode === 27 && activeSection === "cart") {
      //prevent event bubbling so escape doesn't trigger on searchRef when focussing back on it
      e.stopPropagation();
      //focus is handled in a useEffect below
      setCartItemEditQuantity(false);
    }
  };

  //handler to call the add to cart api
  const handleAddToCart = (e, index) => {
    //stop event bubbling
    e.stopPropagation();
    //show error animation if quantity entered is empty
    if (!quantityInputRef.current.value) setQuantityErrorAnimation(true);

    if (quantityInputRef.current.value) {
      addToCart(quantityInputRef.current.value, index);
    }
  };

  //api to add to cart
  const addToCart = async (qty, index) => {
    //update the loading state
    setIsAddingToCart(true);
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);

    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "add_to_cart",
      pageId: "search_dropdown",
      medicineId: searchProducts[index].medicineId,
      medicineName: searchProducts[index].medicineName,
      position: index,
      price: searchProducts[index].effectivePrice.toFixed(2),
      count: qty,
    });

    if (
      (headerType === "RETAIL" && searchProducts[index].deliverySlot) ||
      headerType === "WHOLESALE"
    ) {
      const params = {};
      let body;
      if (headerType === "RETAIL") {
        body = {
          androidMap: {
            locale: "en",
            source: "pharmacyWeb",
            deviceId: null,
            appVersion: navigator.userAgent,
            deviceName: null,
            screen_name: "search_dropdown",
            appVersionCode: "156",
          },
          medicineId: searchProducts[index].medicineId,
          quantity: qty,
          retailerCode: data.retailerCode,
          retailerId: data.id,
        };
      } else if (headerType === "WHOLESALE") {
        body = {
          androidMap: {
            locale: "en",
            source: "pharmacyWeb",
            deviceId: null,
            appVersion: navigator.userAgent,
            deviceName: null,
            screen_name: "search_dropdown",
            appVersionCode: "156",
          },
          batchId: searchProducts[index]?.batchInfo?.batchId,
          medicineId: searchProducts[index]?.medicineId,
          quantity: qty,
          retailerCode: data.retailerCode,
          slapId: 0,
        };
      }

      //focus on main search bar after item added to cart
      searchRef.current.focus();
      //select the text that is already there in the search bar
      searchRef.current.select();

      try {
        let res;
        if (headerType === "RETAIL") {
          res = await Cart_Actions("ADD_TO_CART", params, body);
        } else {
          res = await addMedicineToCart(body);
        }
        //update the cart data context based on the headerType
        if (headerType === "RETAIL") setCartData(res?.data?.data);
        else if (headerType === "WHOLESALE") setWSCartData(res?.data?.data);
        //update the loading state
        setIsAddingToCart(false);

        //update the secondary state of the card
        setSecondaryState(false);

        //focus on main search bar after item added tox cart
        searchRef.current.focus();
        //select the text that is already there in the search bar
        searchRef.current.select();
      } catch (error) {
        //update the loading state if error
        setIsAddingToCart(false);
        //update the secondary state of the card
        setSecondaryState(false);
        if (error.response.status === 401) {
          refreshToken().then((res) => {
            addToCart(qty, index);
          });
        } else if (
          error.response.status === 502 ||
          error.response.status === 503 ||
          error.response.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error.response.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              addToCart(qty, index);
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              addToCart(qty, index);
            },
          });
        }
      }
    } else {
      window.alert("You cannot add product to cart without Delivery Slot");
    }
  };

  //maps through cart data and removes all the items
  const handleEmptyCart = (e) => {
    //prevent event bubbling
    e && e.stopPropagation();
    e && e.preventDefault();
    //update empty cart spinner
    setClearCartSpinner(true);
    //async calls to remove medicines from the cart
    let promises = [];
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let body;
    let params;

    headerType === "RETAIL"
      ? cartData?.cartMedicineDetailResponseList?.forEach((item, _) => {
          body = {
            androidMap: {},
            medicineId: item.medicineId,
            retailerCode: data.retailerCode,
            retailerId: data.id,
          };
          promises.push({
            func: Cart_Actions,
            type: "REMOVE_FROM_CART",
            params: params,
            body: body,
          });
        })
      : WSCartData?.cartMedicineDetailResponseList?.forEach((item, _) => {
          //build the payload
          body = {
            androidMap: {},
            cartId: WSCartData.id,
            cartMedicineId: item.id,
            retailerCode: null,
          };
          promises.push({
            func: deleteItemFromCart,
            type: "",
            params: params,
            body: body,
          });
        });
    //update the loader state
    setClearCartSpinner(true);
    //make all remove cart calls async
    Promise.all(
      promises.map((item, _) =>
        headerType === "RETAIL"
          ? item.func(item.type, item.params, item.body)
          : item.func(item.body)
      )
    )
      .then((res) => {
        //update the loader state
        setClearCartSpinner(false);
        res.map((item, _) => {
          if (item?.data?.data?.cartMedicineDetailResponseList?.length === 0) {
            headerType === "RETAIL"
              ? setCartData(item?.data?.data)
              : setWSCartData(item?.data?.data);
            return;
          }
        });
        // setCartData(res[res.length - 1]?.data?.data);
        //set the navigation back on the product section
        setActiveSection("product");
        setActiveItem(0);
        //scroll back to the top of the div
        document.getElementById("scrollableDiv").scrollTo(0, 0);
      })
      .catch((error) => {
        //update the loader state
        setClearCartSpinner(false);
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
        });
      });
    //set the focus back on main search after clearing cart
    searchRef.current.focus();
    searchRef.current.select();
  };

  //   handler to show/hide the cart section in the quick search
  const handleHideSearchCart = (e) => {
    //prevent event bubbling
    e.stopPropagation();
    //handle hide cart state
    setHideSearchCart((prevVal) => !prevVal);
    if (!hideSearchCart) {
      setActiveSection("product");
      setActiveItem(0);
      searchRef.current.focus();
      searchRef.current.select();
    } else if (hideSearchCart) {
      setActiveSection("cart");
      setActiveItem(0);
      searchRef.current.focus();
      searchRef.current.select();
    }
  };

  //view all items handler
  const handleViewAll = (e) => {
    //prevent event bubbling
    e.stopPropagation();
    //remover the overlay and hide the panel
    document.body.className = "";
    searchRef.current.blur();
    setShowRecent(false);
    //navigate to the search screen based on the businessType
    let data = JSON.parse(localStorage.getItem("userData"));
    data.businessType[0] === "WHOLESELLER"
      ? navigate(
          "/wholesale/home/search/" + searchString.split(" ").join("%20")
        )
      : navigate("/search/" + searchString.split(" ").join("%20"));
  };

  const handleKeyPress = useCallback(
    async (event) => {
      //enter -> keycode : 13
      if (event.keyCode === 13) {
        Common_Event_Hit("DYNAMIC_EVENT", {
          eventName: "search_dropdown_open",
          pageId: "search_dropdown",
          keypress:'enter'
        });
        event.preventDefault();
        event.stopPropagation();
        if (event.ctrlKey) {
          event.preventDefault();
          event.stopPropagation();
          document.body.className = "";
          setShowRecent(false);
          searchRef.current.blur();
          headerType === "RETAIL"
            ? navigate("/cart")
            : navigate("/wholesale/home/cart");
          // handleCheckOut(event);
          return;
        }
        // event.preventDefault();
        // event.stopPropagation();
        if (location.pathname.split("/")[1] === "search") {
          searchRef.current.focus();
          searchRef.current.select();
          return;
        }
        //return if search is already active
        if (document.activeElement === searchRef.current) {
          return;
        } else if (
          document.activeElement === quantityInputRef.current &&
          activeSection === "cart"
        ) {
          //hit the edit quantity api here
          editCartQuantity();
        } else if (document.querySelectorAll(".modal.show").length > 0) {
          // indicated another bootstrap modal is active on the page
          return;
        } else {
          searchRef.current.focus();
          searchRef.current.select();
        }
      }

      //escape -> keycode : 27
      else if (event.keyCode === 27) {
        Common_Event_Hit("DYNAMIC_EVENT", {
          eventName: "search_dropdown_close",
          pageId: "search_dropdown",
          keypress:'esc'
        });
        if (document.activeElement === searchRef.current) {
          document.body.className = "";
          setShowRecent(false);
          setSearchString("");
        } else if (
          document.activeElement === quantityInputRef.current &&
          activeSection === "cart"
        ) {
          event.preventDefault();
          event.stopPropagation();
          //handle escape press in quantity editor in cart
          setCartItemEditQuantity(false);
          searchRef.current.focus();
          searchRef.current.select();
        }
        //a -> keycode : 65
      } else if (event.ctrlKey && event.keyCode === 65) {
        // if (document.activeElement === searchRef.current) {
        // event.preventDefault();
        // event.stopPropagation();
        handleViewAll(event);
        // document.body.className = "";
        // searchRef.current.blur();
        // setShowRecent(false);
        // let data = JSON.parse(localStorage.getItem("usersData"));
        // data.businessType[0] === "WHOLESELLER"
        //   ? navigate(
        //       "/wholesale/home/search/" +
        //         `${searchString.length > 0 ? searchQuery : ""}`
        //     )
        //   : navigate(
        //       "/search/" + `${searchString.length > 0 ? searchQuery : ""}`
        //     );
        // }
        // tab -> keycode : 9 //unidentified -> keycode : 0
      } else if (event.keyCode === 9 || event.keyCode === 0) {
        event.preventDefault();
        event.stopPropagation();
        //d -> keycode : 68
      } else if (event.ctrlKey && event.keyCode === 68) {
        //call the clear cart handler
        handleEmptyCart(event);
      } else if (event.keyCode === 39) {
        if (document.activeElement !== searchRef.current) return;
        event.stopPropagation();
        event.preventDefault();
        //if cart data is not available -> return
        if (cartData?.cartMedicineDetailResponseList.length === 0) return;
        //return if cartData is not present
        //reset secondary states if active
        setSecondaryState(false);
        setCartItemEditQuantity(false);
        //return if cart data is unavailable
        // if (!cartData?.cartMedicineDetailResponseList) return;
        //expand the cart if hide cart is active and the user presses right arrow
        setHideSearchCart(false);
        //update the cart section active state
        setActiveSection("cart");
        //reset the active item
        setActiveItem(0);
        searchRef.current.focus();
        //arrowLeft -> keycode : 37
      } else if (event.keyCode === 37) {
        if (document.activeElement !== searchRef.current) return;
        //prevent onfocus search
        event.stopPropagation();
        event.preventDefault();
        //reset secondary states if active
        setSecondaryState(false);
        setCartItemEditQuantity(false);
        //update the product section active state
        setActiveSection("product");
        //reset the active item
        setActiveItem(0);

        document.getElementById("scrollableDiv").scrollTo(0, 0);
        searchRef.current.focus();
      }
    },
    [cartData, searchQuery, searchString, activeSection, headerType, searchRef]
  );

  //navigate to cart
  const handleCheckOut = (e) => {
    //prevent event bubbling
    e.stopPropagation();
    //make the page scrollable after closing the search autocomplete
    //remove the classname
    //disable the search overlay before going to cart screen
    document.body.className = "";
    searchRef.current.blur();
    setShowRecent(false);
    // navigate based on businessType
    let data = JSON.parse(localStorage.getItem("userData"));
    if (data.businessType[0] === "WHOLESELLER") {
      navigate("/wholesale/home/cart");
    } else {
      navigate("/cart");
    }
  };

  const handleProductClick = (e, index) => {
    //prevent event bubbling
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    //handle search layer
    document.body.className = "";
    setShowRecent(false);
    searchRef.current.blur();
    //navigate to product page
    headerType === "RETAIL"
      ? navigate("/product/" + searchProducts[index].medicineId)
      : navigate("/wholesale/product/" + searchProducts[index].medicineId);
  };

  useEffect(() => {
    //donont use event listener while certain routes are active to enable the default keyboard functionality
    let locationBlocks = location.pathname.split("/");
    console.log({ locationBlocks });

    if (
      locationBlocks[1] === "onboarding" ||
      locationBlocks[1] === "applyCredit"
    )
      return;
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  //   side effect on location change
  useEffect(() => {
    if (location.pathname.split("/")[1] !== "search") {
      setSearchString("");
    }

    if (
      location.pathname.split("/")[1] === "search" ||
      location.pathname.split("/")[3] === "search"
    ) {
      // setShowRecent(false)
      let string;
      let data = JSON.parse(localStorage.getItem("userData"));
      data.businessType[0] === "PHARMACY"
        ? location.pathname.split("/")[2].split("%20").join(" ")
        : location.pathname.split("/")[4].split("%20").join(" ");
      setSearchString(string);
    }
  }, [location, userLoggedIn]);

  useEffect(() => {
    if (activeItemRef && activeItemRef.current && searchString.length > 0) {
      if (
        activeSection === "product" &&
        document.getElementById("scrollableDiv")
      ) {
        if (searchProducts.length === 0) return;
        document.getElementById("scrollableDiv").scrollTop =
          activeItemRef.current.offsetTop - 200;
      } else if (
        activeSection === "cart" &&
        document.getElementById("scrollableSearchCart")
      ) {
        if (
          headerType === "RETAIL"
            ? !cartData ||
              hideSearchCart ||
              !cartData?.cartMedicineDetailResponseList
            : !WSCartData ||
              hideSearchCart ||
              !WSCartData?.cartMedicineDetailResponseList
        )
          return;
        document.getElementById("scrollableSearchCart").scrollTop =
          activeItemRef.current.offsetTop - 200;
      }
    }
  }, [activeItemRef.current]);

  //focus the quantity input fiels when secondary state is true
  useEffect(() => {
    if ((secondaryState || cartItemEditQuantity) && quantityInputRef.current) {
      if (activeSection === "cart") {
        quantityInputRef.current.value =
          headerType === "RETAIL"
            ? cartData.cartMedicineDetailResponseList[activeItem].quantity
            : WSCartData?.cartMedicineDetailResponseList[activeItem]
                ?.orderedQuantity;
      } else if (activeSection === "product") {
        //check if the product is already in the cart then edit the quanitity
        // if(!cartData) return
        //update the quantity input based on the headerType

        headerType === "RETAIL"
          ? cartData?.cartMedicineDetailResponseList?.forEach((item, index) => {
              if (item?.medicineId == searchProducts[activeItem]?.medicineId) {
                quantityInputRef.current.value = item?.quantity;
              }
            })
          : WSCartData?.cartMedicineDetailResponseList?.forEach(
              (item, index) => {
                if (
                  item?.medicineId === searchProducts[activeItem]?.medicineId
                ) {
                  quantityInputRef.current.value = item?.orderedQuantity;
                }
              }
            );
      }
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
      //check if the active item is the last one and scroll to the div bottom
      // if (activeItemRef.current.id == searchProducts.length - 1) {
      //   document
      //     .getElementById("scrollableDiv")
      //     .scrollTo(
      //       0,
      //       document.getElementById("scrollableDiv").scrollHeight - 100
      //     );
      // }
    } else if (!secondaryState) {
      // searchRef.current.focus();
      // searchRef.current.select();
    }
  }, [secondaryState, cartItemEditQuantity, cartData]);

  //quantity error animation reset
  useEffect(() => {
    if (quantityErrorAnimation) {
      setTimeout(() => {
        setQuantityErrorAnimation(false);
      }, [10000]);
    }
  }, [quantityErrorAnimation]);

  //reset the secondary state whenever search overlay is destroyed
  useEffect(() => {
    //reset secondary states when search is closed
    if (!showRecent) {
      setSecondaryState(false);
      setCartItemEditQuantity(false);
    }
  }, [showRecent]);

  //   reset search products when the search string is 0
  useEffect(() => {
    if (searchString?.length === 0) {
      setSearchProducts([]);
    }
  }, [searchString]);

  useEffect(()=>{
    setShowRecent(false)
  },[location])

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
      />

      <InputGroup
        className={cx(
          { inputGroupFullWidth: showRecent },
          { inputGroup: !showRecent }
        )}
      >
        <FormControl
          className="searchBar border_none font_weight_md "
          placeholder="Search 50,000+ medicines "
          value={searchString}
          onChange={handleSearchString}
          onFocus={searchMeds}
          onKeyUp={searchMeds}
          onKeyDown={handleKeyboardNav}
          ref={searchRef}
          spellcheck="false"
          id="searchBar"
          autoComplete="off"
        />
        {window.innerWidth > 1000 && (
          <InputGroup.Text
            style={{ cursor: "pointer" }}
            className="search_shortKey_tag"
            onClick={() => searchRef.current.focus()}
          >
            Press '&nbsp;
            <span>{showRecent ? " ESC " : "ENTER"}</span>
            &nbsp;' to &nbsp;
            {showRecent ? "close" : "search"}&nbsp;&nbsp;
          </InputGroup.Text>
        )}

        <InputGroup.Text className="border_none bg_grey cursor_pointer search_icon">
          <BiSearch
            style={{ fontSize: "20px" }}
            className="color_grey search_icon"
          />
        </InputGroup.Text>
      </InputGroup>

      {showRecent && (
        <div
          className={
            showRecent
              ? "search-autocomplete-container-wrapper add-backdrop"
              : "search-autocomplete-container-wrapper remove-backdrop"
          }
          onClick={handleHideAutoComplete}
          style={{
            top:
              // (!networkStatus || showNetworkStatus || isUpdateAvailable) &&
              headerType === "RETAIL" ? "5.75rem" : "4.07rem",
            paddingLeft: headerType === "RETAIL" ? `259px` : "17.3%",
          }}
        >
          {showRecent && showRecentShimmer && searchString?.length === 0 && (
            <Col className={"search-autocomplete-container"}>
              {[...Array(10)].map((item, _) => {
                return (
                  <div className="show-autocomplete-content">
                    <div
                      style={{
                        minHeight: "3rem",
                        minWidth: "3rem",
                        marginLeft: "1rem",
                      }}
                      className="animateShimmer"
                    ></div>
                    <div>
                      <div
                        style={{
                          minHeight: ".5rem",
                          minWidth: "6rem",
                          maxWidth: "6rem",
                          marginLeft: "1rem",
                        }}
                        className="animateShimmer"
                      ></div>
                      <div
                        style={{
                          minHeight: ".5rem",
                          minWidth: "8rem",
                          marginTop: ".5rem",
                          marginLeft: "1rem",
                        }}
                        className="animateShimmer"
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          minHeight: ".5rem",
                          maxHeight: ".5rem",
                          minWidth: "6rem",
                        }}
                        className="animateShimmer"
                      ></div>
                      <div>
                        <MdNavigateNext size="1.5rem" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Col>
          )}
          {showRecent &&
            promises &&
            promisesData?.length > 0 &&
            searchString?.length === 0 &&
            !showRecentShimmer && (
              <Col className="search-autocomplete-container">
                {promises?.map((prom, ind) => {
                  return (
                    <>
                      {promisesData[ind].data.data.length > 0 && (
                        <div className="show-autocomplete-header">
                          <p className="color_grey">{prom?.title}</p>
                          <p className="color_grey">See All</p>
                        </div>
                      )}
                      {promisesData[ind].data.data.length > 0 &&
                        promisesData[ind]?.data?.data?.map((item, idx) => {
                          return (
                            <>
                              {item && item.length > 0 && (
                                <div
                                  className="show-autocomplete-content"
                                  onClickCapture={(e) =>
                                    handleSelectAutoComplete(e, item)
                                  }
                                >
                                  <div>
                                    {prom.title === "Recent Search" && (
                                      <GrHistory
                                        size="1.2rem"
                                        color="#494949"
                                      />
                                    )}
                                    {(prom.title === "Top Searches" ||
                                      prom.title ===
                                        "Recommended Products") && (
                                      <AiOutlineSearch
                                        size="1.2rem"
                                        color="#494949"
                                      />
                                    )}
                                  </div>
                                  <div>
                                    <p>{item}</p>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                    </>
                  );
                })}
                <div className="view-all-container">
                  <button onClick={handleViewAll}>
                    view all <span>(ctrl + a)</span>
                  </button>
                </div>
              </Col>
            )}

          {/* product list suggestions below */}

          {showRecent && showProductsShimmer && (
            <Col className="search-autocomplete-container">
              {[...Array(10)].map((item, _) => {
                return (
                  <div className="show-autocomplete-content">
                    <div
                      style={{
                        minHeight: "3rem",
                        minWidth: "3rem",
                        marginLeft: "1rem",
                      }}
                      className="animateShimmer"
                    ></div>
                    <div>
                      <div
                        style={{
                          minHeight: ".5rem",
                          minWidth: "6rem",
                          maxWidth: "6rem",
                          marginLeft: "1rem",
                        }}
                        className="animateShimmer"
                      ></div>
                      <div
                        style={{
                          minHeight: ".5rem",
                          minWidth: "8rem",
                          marginTop: ".5rem",
                          marginLeft: "1rem",
                        }}
                        className="animateShimmer"
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          minHeight: ".5rem",
                          maxHeight: ".5rem",
                          minWidth: "6rem",
                        }}
                        className="animateShimmer"
                      ></div>
                      <div>
                        <MdNavigateNext size="1rem" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Col>
          )}

          {/* product list suggestions below */}

          {showRecent &&
            searchString?.length > 0 &&
            searchProducts?.length === 0 &&
            !showProductsShimmer && (
              <div
                className="search-autocomplete-container  font_ubuntu color_primary_lt"
                style={{ textAlign: "center" }}
              >
                <p>No products found. Try with a different keyword maybe?</p>
              </div>
            )}

          {showRecent &&
            searchString?.length > 0 &&
            searchProducts?.length > 0 &&
            !showProductsShimmer && (
              <>
                <Col
                  className="search-autocomplete-container"
                  id="scrollableDiv"
                >
                  <table className="product-autocomplete-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>pack</th>
                        <th>schemes</th>
                        <th>ptr</th>
                        <th>mrp</th>
                        <th>stock</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchProducts?.map((product, index) => {
                        return (
                          <>
                            <tr
                              style={{ cursor: "pointer" }}
                              key={index}
                              id={index}
                              className={
                                activeSection === "product" &&
                                activeItem === index
                                  ? secondaryState
                                    ? "product-autocomplete-item item-active secondary-state"
                                    : "product-autocomplete-item item-active"
                                  : "product-autocomplete-item"
                              }
                              ref={activeItem === index ? activeItemRef : null}
                              onClick={(e) => handleProductClick(e, index)}
                            >
                              <td>
                                <div className="display_flex space_btw search_dd_product_parent_div">
                                  <div className="">
                                    <div
                                      title={product.name}
                                      className={cx(
                                        "search_dd_product_div font_14",
                                        { font_14: product.name.length > 25 }
                                      )}
                                    >
                                      {product.name}
                                    </div>
                                    <div title={product.manufacturer}>
                                      {/* {product.manufacturer.length > 28
                                      ? product.manufacturer
                                          .slice(0, 25)
                                          .toLowerCase() + "..."
                                      : product.manufacturer.toLowerCase()} */}
                                      {product.manufacturer.toLowerCase()}
                                    </div>
                                  </div>
                                  {headerType === "RETAIL" ? (
                                    product.cashDiscountInPercentage > 0 ? (
                                      <div
                                        style={{
                                          display:
                                            product.cashDiscountInPercentage ==
                                              0 && "none !important",
                                        }}
                                        className="search_dd_cd_tag"
                                      >
                                        <p>
                                          {product.cashDiscountInPercentage}% CD
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="bg_white">
                                        <p></p>
                                      </div>
                                    )
                                  ) : product.batchInfo.cashDiscount > 0 ? (
                                    <div
                                      style={{
                                        display:
                                          product.batchInfo.cashDiscount == 0 &&
                                          "none !important",
                                      }}
                                      className="search_dd_cd_tag"
                                    >
                                      <p>
                                        {product.batchInfo.cashDiscount}% CD
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="bg_white">
                                      <p></p>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <div className="display_none"></div>
                              {/* {headerType === "RETAIL" ? (
                                product.cashDiscountInPercentage > 0 ? (
                                  <div
                                    style={{
                                      display:
                                        product.cashDiscountInPercentage == 0 &&
                                        "none !important",
                                        
                                    }}
                                  >
                                    <p>
                                      {product.cashDiscountInPercentage}% CD
                                    </p>
                                  </div>
                                ) : (
                                  <div className="bg_white">
                                    <p></p>
                                  </div>
                                )
                              ) : product.batchInfo.cashDiscount > 0 ? (
                                <div
                                  style={{
                                    display:
                                      product.batchInfo.cashDiscount == 0 &&
                                      "none !important",
                                  }}
                                >
                                  <p>{product.batchInfo.cashDiscount}% CD</p>
                                </div>
                              ) : (
                                <div className="bg_white">
                                  <p></p>
                                </div>
                              )} */}
                              <td>
                                {headerType === "RETAIL" ? (
                                  <div title={product.packaging}>
                                    {product.packaging.length > 8
                                      ? product.packaging.slice(0, 6) + ".."
                                      : product.packaging}
                                  </div>
                                ) : (
                                  <div title={product.pack}>
                                    {product.pack.length > 8
                                      ? product.pack.slice(0, 6) + ".."
                                      : product.pack}
                                  </div>
                                )}
                              </td>
                              <td>
                                {headerType === "RETAIL" ? (
                                  <div>
                                    {product.scheme.map((scheme, sIdx) => {
                                      return <div key={sIdx}>{scheme}</div>;
                                    })}
                                  </div>
                                ) : (
                                  <div>
                                    {product.batchInfo.schemes.map(
                                      (scheme, sIdx) => {
                                        return <div key={sIdx}>{scheme}</div>;
                                      }
                                    )}
                                  </div>
                                )}
                              </td>
                              <td>
                                {headerType === "RETAIL" ? (
                                  <div>₹{product.ptr.toFixed(2)}</div>
                                ) : (
                                  <div>₹{product.batchInfo.ptr.toFixed(2)}</div>
                                )}
                              </td>
                              <td>
                                {headerType === "RETAIL" ? (
                                  <div>₹{product.mrp.toFixed(2)}</div>
                                ) : (
                                  <div>₹{product.batchInfo.mrp.toFixed(2)}</div>
                                )}
                              </td>
                              <td>
                                {headerType === "RETAIL" ? (
                                  <div>{product.availableQuantity}</div>
                                ) : (
                                  <div>{product.batchInfo.quantity}</div>
                                )}
                              </td>
                              <div className= {product?.scheme?.length>1 ? 'search_dd_delivery_slot_2':'search_dd_delivery_slot'}>
                                {headerType === "RETAIL" ? (
                                  activeItem === index && secondaryState ? (
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "#848484",
                                      }}
                                    >
                                      Delivery by : {product.deliveryDate},
                                      {product.deliverySlot} 
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        color: "#848484",
                                      }}
                                    >
                                      Delivery by : {product.deliveryDate},
                                      {product.deliverySlot}
                                    </p>
                                  )
                                ) : activeItem === index && secondaryState ? (
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "#848484",
                                    }}
                                  >
                                    Delivery in :
                                    {product?.batchInfo?.deliverySlot}
                                  </p>
                                ) : (
                                  <p style={{
                                      color: "#848484",
                                    }}>
                                    Delivery in :
                                    {product?.batchInfo?.deliverySlot}
                                  </p>
                                )}
                              </div>
                            </tr>
                            {activeItem === index && secondaryState && (
                              <tr className="product-quantity-input-container">
                                <div>
                                  <p>QTY :&nbsp;</p>
                                  <input
                                    min="1"
                                    type="number"
                                    ref={
                                      secondaryState ? quantityInputRef : null
                                    }
                                    className={
                                      quantityErrorAnimation && "errorAnime"
                                    }
                                    style={{
                                      border: quantityErrorAnimation
                                        ? "1px solid crimson"
                                        : "",
                                    }}
                                    onChange={(e) =>
                                      handleQuanityChange(
                                        e,
                                        product.name,
                                        index
                                      )
                                    }
                                    onKeyDown={(e) =>
                                      handleQuantityInputShortcut(e, index)
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  {quantityErrorAnimation &&
                                    quantityInputRef?.current?.value && (
                                      <p className="quantity-input-error">
                                        {quantityInputErrorMessage}
                                      </p>
                                    )}

                                  {quantityErrorAnimation &&
                                    !quantityInputRef?.current?.value && (
                                      <p className="quantity-input-error">
                                        please enter quantity to proceed
                                      </p>
                                    )}

                                  {!quantityErrorAnimation && (
                                    <p className="available-quantity">
                                      Available Stock :{" "}
                                      <span>
                                        {headerType === "RETAIL"
                                          ? product.availableQuantity
                                          : product.batchInfo.quantity}
                                      </span>
                                    </p>
                                  )}

                                  {headerType === "RETAIL" &&
                                    productPrice &&
                                    quantityInputRef?.current?.value > 0 &&
                                    quantityInputRef?.current?.value <=
                                      product.availableQuantity && (
                                      <>
                                        {isFetchingProductPrice ? (
                                          <div
                                            style={{
                                              minWidth: "5rem",
                                              minHeight: "2.2rem",
                                              marginLeft: ".5rem",
                                              boxShadow:
                                                "0px 2px 3px 2px rgba(10, 10, 10, 8%)",
                                              display: "flex",
                                              alignItems: "flex-start",
                                              flexDirection: "column",
                                              borderRadius: ".25rem",
                                              padding: ".5rem",
                                              width: "8.1rem",
                                            }}
                                          >
                                            <div
                                              className="animateShimmer"
                                              style={{
                                                minWidth: "6rem",
                                                minHeight: ".4rem",
                                              }}
                                            ></div>
                                            <div
                                              className="animateShimmer"
                                              style={{
                                                minWidth: "5rem",
                                                minHeight: ".3rem",
                                                marginTop: ".5rem",
                                              }}
                                            ></div>
                                          </div>
                                        ) : (
                                          <>
                                            <div className="total-amount-container">
                                              <div>
                                                {productPrice?.breakup?.map(
                                                  (item, _) => {
                                                    if (
                                                      item?.key
                                                        .toLowerCase()
                                                        .includes("gst")
                                                    ) {
                                                      return (
                                                        <p>
                                                          {item?.key}
                                                          &nbsp;
                                                        </p>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {productPrice?.subTotal && (
                                                  <p>Total &nbsp;</p>
                                                )}
                                              </div>
                                              <div>
                                                {productPrice?.breakup?.map(
                                                  (item, _) => {
                                                    if (
                                                      item?.key
                                                        .toLowerCase()
                                                        .includes("gst")
                                                    ) {
                                                      return (
                                                        <p>
                                                          ₹
                                                          {item?.value.toFixed(
                                                            2
                                                          )}
                                                        </p>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {productPrice?.subTotal && (
                                                  <p>
                                                    ₹
                                                    {productPrice?.subTotal?.toFixed(
                                                      2
                                                    )}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                            <p
                                              style={{
                                                color:
                                                  productPrice?.appMessageDto
                                                    ?.textColor,
                                                backgroundColor:
                                                  productPrice?.appMessageDto
                                                    ?.colorCode,
                                                padding: ".2rem .5rem",
                                                borderRadius: ".25rem",
                                                marginLeft: ".5rem",
                                              }}
                                            >
                                              {
                                                productPrice?.appMessageDto
                                                  ?.appMessage
                                              }
                                            </p>
                                          </>
                                        )}
                                      </>
                                    )}

                                  {headerType === "WHOLESALE" &&
                                    productPrice &&
                                    quantityInputRef?.current?.value > 0 &&
                                    quantityInputRef?.current?.value <=
                                      product.batchInfo.quantity && (
                                      <>
                                        {isFetchingProductPrice ? (
                                          <div
                                            style={{
                                              minWidth: "5rem",
                                              minHeight: "2.2rem",
                                              marginLeft: ".5rem",
                                              boxShadow:
                                                "0px 2px 3px 2px rgba(10, 10, 10, 8%)",
                                              display: "flex",
                                              alignItems: "flex-start",
                                              flexDirection: "column",
                                              borderRadius: ".25rem",
                                              padding: ".5rem",
                                              width: "8.1rem",
                                            }}
                                          >
                                            <div
                                              className="animateShimmer"
                                              style={{
                                                minWidth: "6rem",
                                                minHeight: ".4rem",
                                              }}
                                            ></div>
                                            <div
                                              className="animateShimmer"
                                              style={{
                                                minWidth: "5rem",
                                                minHeight: ".3rem",
                                                marginTop: ".5rem",
                                              }}
                                            ></div>
                                          </div>
                                        ) : (
                                          <>
                                            <div className="total-amount-container">
                                              <div>
                                                {productPrice?.breakUpList?.map(
                                                  (item, _) => {
                                                    if (
                                                      item?.key
                                                        .toLowerCase()
                                                        .includes("gst")
                                                    ) {
                                                      return (
                                                        <p>
                                                          {item?.key}
                                                          &nbsp;
                                                        </p>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {productPrice?.totalNetValue && (
                                                  <p>Total &nbsp;</p>
                                                )}
                                              </div>
                                              <div>
                                                {productPrice?.breakUpList?.map(
                                                  (item, _) => {
                                                    if (
                                                      item?.key
                                                        .toLowerCase()
                                                        .includes("gst")
                                                    ) {
                                                      return (
                                                        <p>
                                                          ₹
                                                          {item?.value.toFixed(
                                                            2
                                                          )}
                                                        </p>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {productPrice?.totalNetValue && (
                                                  <p>
                                                    ₹
                                                    {productPrice?.totalNetValue.toFixed(
                                                      2
                                                    )}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                            <p
                                              style={{
                                                color:
                                                  productPrice?.appMessageDto
                                                    ?.textColor,
                                                backgroundColor:
                                                  productPrice?.appMessageDto
                                                    ?.colorCode,
                                                padding: ".2rem .5rem",
                                                borderRadius: ".25rem",
                                                marginLeft: ".5rem",
                                              }}
                                            >
                                              {
                                                productPrice?.appMessageDto
                                                  ?.appMessage
                                              }
                                            </p>
                                          </>
                                        )}
                                      </>
                                    )}
                                </div>
                                <div>
                                  <button
                                    className="add-to-cart-button"
                                    onClick={(e) => handleAddToCart(e, index)}
                                  >
                                    {isAddingToCart ? (
                                      <ButtonLoading1 />
                                    ) : (
                                      "+ ADD"
                                    )}
                                  </button>
                                </div>
                              </tr>
                            )}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="view-all-container">
                    <button onClick={handleViewAll}>
                      view all <span>(ctrl + a)</span>
                    </button>
                  </div>
                </Col>
                {hideSearchCart && (
                  <div
                    className="hide--container"
                    onClick={handleHideSearchCart}
                    style={{ marginLeft: "48.6%" }}
                  >
                    <div>
                      <MdArrowForwardIos size="1.5rem" />
                    </div>
                  </div>
                )}
              </>
            )}

          {/* search cart container below */}
          {headerType === "RETAIL" &&
            showRecent &&
            cartData?.cartMedicineDetailResponseList?.length > 0 &&
            !hideSearchCart &&
            searchString?.length > 0 && (
              <>
                <div
                  className="search-cart-container"
                  id="scrollableSearchCart"
                  style={{ marginLeft: "48.6%" }}
                >
                  <div className="search-cart-header">
                    <p>Cart Items List</p>
                    {clearCartSpinner ? (
                      <Spinner
                        animation="border"
                        className="spinner"
                        size="sm"
                        style={{
                          margin: "0px 5px",
                          color: "crimson",
                        }}
                      />
                    ) : (
                      <p onClick={handleEmptyCart}>
                        Clear<span>(ctrl + D)</span>
                      </p>
                    )}
                  </div>
                  <div className="search-cart-main-container">
                    {cartData?.cartMedicineDetailResponseList?.map(
                      (item, index) => {
                        return (
                          <Card
                            className={
                              activeSection === "cart" && activeItem === index
                                ? "search-cart-item-card item-active"
                                : "search-cart-item-card"
                            }
                            key={index}
                            onClick={(e) => e.stopPropagation()}
                            ref={activeItem === index ? activeItemRef : null}
                          >
                            <Card.Body className="search-cart-item-card-body">
                              <div className="search-cart-item-group1">
                                <div
                                  title={item.medicineName}
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "start",
                                  }}
                                >
                                  <p className="font_14">
                                    {" "}
                                    {item?.medicineName.length > 28
                                      ? item?.medicineName.slice(0, 25) + "..."
                                      : item?.medicineName}
                                  </p>
                                  {/* <p>{item?.medicineName}</p> */}
                                  <p className="font_12">
                                    {item?.manufacturer}
                                  </p>
                                </div>
                                <div>
                                  {!(
                                    activeItem === index && editCartLoader
                                  ) && <p className="font_12">QTY : &nbsp;</p>}
                                  {activeItem === index && editCartLoader && (
                                    <Spinner
                                      animation="border"
                                      className="spinner"
                                      size="sm"
                                      style={{
                                        margin: "0px .3rem",
                                        color: "#4B4495",
                                      }}
                                    />
                                  )}

                                  {activeItem === index &&
                                    cartItemEditQuantity && (
                                      <input
                                        type="number"
                                        ref={quantityInputRef}
                                        id={index}
                                        className="font_12"
                                      />
                                    )}

                                  {!(
                                    activeItem === index && cartItemEditQuantity
                                  ) && (
                                    <b className="font_12">{item?.quantity}</b>
                                  )}
                                  {!isRemovingFromCart && (
                                    <MdDelete
                                      size="1.2rem"
                                      className="delete-cart-icon"
                                      onClick={() =>
                                        handleRemoveFromCart(item, index)
                                      }
                                    />
                                  )}
                                  {isRemovingFromCart &&
                                    index === currentCartItem && (
                                      <Spinner
                                        animation="border"
                                        className="spinner"
                                        size="sm"
                                        style={{
                                          margin: "0px 5px",
                                          color: "crimson",
                                        }}
                                      />
                                    )}
                                </div>
                              </div>
                              <div className="search-cart-item-group2">
                                <div>
                                  <p className="font_14">Total &nbsp;</p>
                                  <p className="font_14">
                                    ₹{item.actualPrice.toFixed(2)}
                                  </p>
                                </div>
                                <div>
                                  <p className="font_12">
                                    {item?.selectedSlotTiming}
                                  </p>
                                </div>
                              </div>
                              {activeItem === index &&
                                activeSection === "cart" && (
                                  <div className="search-cart-item-group3 font_12">
                                    {!cartItemEditQuantity && (
                                      <p className="font_12">
                                        press "enter" to edit qty
                                      </p>
                                    )}
                                    <p>"shift + delete" to delete item</p>
                                  </div>
                                )}
                            </Card.Body>
                          </Card>
                        );
                      }
                    )}
                  </div>
                  <div className="proceed-to-checkout-container">
                    <div className="net-price-container">
                      <p>
                        TOTAL{" "}
                        <span style={{ marginLeft: ".5rem" }}>
                          ₹{cartData.totalWithGst.toFixed(2)}
                        </span>
                        <span>incl. GST</span>
                      </p>
                    </div>
                    <button
                      onClick={handleCheckOut}
                      style={{ marginLeft: "-28.4%" }}
                    >
                      proceed to checkout <br />
                      <span>(ctrl + enter)</span>
                    </button>
                  </div>
                </div>
                <div
                  className="hide-search-cart-container"
                  style={{ marginLeft: "77%" }}
                >
                  <div onClick={handleHideSearchCart}>
                    <MdArrowBackIosNew size="1.5rem" />
                  </div>
                </div>
              </>
            )}

          {headerType === "WHOLESALE" &&
            showRecent &&
            WSCartData?.cartMedicineDetailResponseList?.length > 0 &&
            !hideSearchCart &&
            searchString?.length > 0 && (
              <>
                <div
                  className="search-cart-container"
                  id="scrollableSearchCart"
                  style={{ left: "65.5%" }}
                >
                  <div className="search-cart-header">
                    <p>Cart Items List</p>
                    {clearCartSpinner ? (
                      <Spinner
                        animation="border"
                        className="spinner"
                        size="sm"
                        style={{
                          margin: "0px 5px",
                          color: "crimson",
                        }}
                      />
                    ) : (
                      <p onClick={handleEmptyCart}>
                        Clear<span>(ctrl + D)</span>
                      </p>
                    )}
                  </div>
                  <div className="search-cart-main-container">
                    {WSCartData?.cartMedicineDetailResponseList?.map(
                      (item, index) => {
                        return (
                          <Card
                            className={
                              activeSection === "cart" && activeItem === index
                                ? "search-cart-item-card item-active"
                                : "search-cart-item-card"
                            }
                            key={index}
                            onClick={(e) => e.stopPropagation()}
                            ref={activeItem === index ? activeItemRef : null}
                          >
                            <Card.Body className="search-cart-item-card-body">
                              <div className="search-cart-item-group1">
                                <div
                                  title={item.medicineName}
                                  style={{ cursor: "pointer" }}
                                >
                                  <p>
                                    {" "}
                                    {item?.medicineName.length > 28
                                      ? item?.medicineName.slice(0, 25) + "..."
                                      : item?.medicineName}
                                  </p>
                                  {/* <p>{item?.medicineName}</p> */}
                                  <p>{item?.manufacturer}</p>
                                </div>
                                <div>
                                  {!(
                                    activeItem === index && editCartLoader
                                  ) && <p>QTY : &nbsp;</p>}
                                  {activeItem === index && editCartLoader && (
                                    <Spinner
                                      animation="border"
                                      className="spinner"
                                      size="sm"
                                      style={{
                                        margin: "0px .3rem",
                                        color: "#4B4495",
                                      }}
                                    />
                                  )}

                                  {activeItem === index &&
                                    cartItemEditQuantity && (
                                      <input
                                        type="number"
                                        ref={quantityInputRef}
                                        id={index}
                                      />
                                    )}

                                  {!(
                                    activeItem === index && cartItemEditQuantity
                                  ) && <p>{item?.quantity}</p>}
                                  {!isRemovingFromCart && (
                                    <MdDelete
                                      size="1.5rem"
                                      className="delete-cart-icon"
                                      onClick={() =>
                                        handleRemoveFromCart(item, index)
                                      }
                                    />
                                  )}
                                  {isRemovingFromCart &&
                                    index === currentCartItem && (
                                      <Spinner
                                        animation="border"
                                        className="spinner"
                                        size="sm"
                                        style={{
                                          margin: "0px 5px",
                                          color: "crimson",
                                        }}
                                      />
                                    )}
                                </div>
                              </div>
                              <div className="search-cart-item-group2">
                                <div>
                                  <p>Total &nbsp;</p>
                                  <p>₹{item.netValue.toFixed(2)}</p>
                                </div>
                                <div>
                                  <p>{item?.selectedSlotName}</p>
                                </div>
                              </div>
                              {activeItem === index &&
                                activeSection === "cart" && (
                                  <div className="search-cart-item-group3">
                                    {!cartItemEditQuantity && (
                                      <p>press "enter" to edit quanitity</p>
                                    )}
                                    <p>"shift + delete" to delete item</p>
                                  </div>
                                )}
                            </Card.Body>
                          </Card>
                        );
                      }
                    )}
                  </div>
                  <div className="proceed-to-checkout-container">
                    <div className="net-price-container">
                      <p>
                        TOTAL{" "}
                        <span style={{ marginLeft: ".5rem" }}>
                          ₹{WSCartData.total.toFixed(2)}
                        </span>
                        <span>incl. GST</span>
                      </p>
                    </div>
                    <button onClick={handleCheckOut}>
                      proceed to checkout <br />
                      <span>(ctrl + enter)</span>
                    </button>
                  </div>
                </div>
                <div
                  className="hide-search-cart-container"
                  style={{ left: "94.5%" }}
                >
                  <div onClick={handleHideSearchCart}>
                    <MdArrowBackIosNew size="1.5rem" />
                  </div>
                </div>
              </>
            )}
        </div>
      )}
    </>
  );
}

export default SearchBarComponent;
