import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { refreshToken } from "../../Actions/Auth";
import { Cart_Actions } from "../../Actions/Cart";
import { Common_Event_Hit } from "../../Actions/Events";
import YellowRibbon from "../../Images/svg/yellow-ribbon.svg";
import DeleteProductModal from "../Modals/DeleteProductModal";
import ErrorModal from "../Modals/ErrorModal";
import { ImPlus, ImMinus } from "react-icons/im";
import info from "../../Images/svg/info-i.svg";
import ProductPtrDialog from "../Modals/ProductPtrDialog";
import { cartDataContext } from "../../App";

function CartGenericProduct({ userData, medData, pageId, position,screen }) {
  const { setShowMaintenance } = userData;
  const { setCartData } = useContext(cartDataContext);
  const [cartSpinner, setCartSpinner] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [productPtrShow, setProductPtrShow] = React.useState(false);
  const [maxQtyError, setMaxQtyError] = useState(false);
  const [maxStockError, setMaxStockError] = useState(false);
  const [minQtyError, setMinQtyError] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [multiplyFactorForOrderQuantity, setmMltiplyFactorForOrderQuantity] = useState(1);

  const addQuantity = (e, item,initialHit=false) => {
    e.stopPropagation();
    setCartSpinner(true);
    let updatedQuantity;
    if (item.maxOrderQuantity > 0 && quantity < item.maxOrderQuantity) {
    
      if (
        multiplyFactorForOrderQuantity &&
        multiplyFactorForOrderQuantity > 1
      ) {
        updatedQuantity = quantity + multiplyFactorForOrderQuantity;
      } else if (quantity >= item.minOrderQuantity) {
        updatedQuantity = quantity + multiplyFactorForOrderQuantity;
      } else if (quantity < item.minOrderQuantity) {
        updatedQuantity = quantity + item.minOrderQuantity;
      }

      if (updatedQuantity <= item.availableQuantity) {
        addToCart(updatedQuantity, item);
      } else {
        setMaxStockError(true);
        setTimeout(() => {
          setMaxStockError(false);
        }, 2000);
      }
    } else if (item.maxOrderQuantity === 0) {
      if (
        multiplyFactorForOrderQuantity &&
        multiplyFactorForOrderQuantity > 1
      ) {
        updatedQuantity = quantity + multiplyFactorForOrderQuantity;
      } else if (quantity >= item.minOrderQuantity) {
        updatedQuantity = quantity + multiplyFactorForOrderQuantity;
        console.log(updatedQuantity);
      } else if (quantity < item.minOrderQuantity) {
        updatedQuantity = quantity + item.minOrderQuantity;
      }
      if (updatedQuantity <= item.availableQuantity) {
        addToCart(updatedQuantity, item);
      } else {
        setMaxStockError(true);
        setTimeout(() => {
          setMaxStockError(false);
        }, 2000);
      }
    }

    if (
      quantity > 0 &&
      item.maxOrderQuantity > 0 &&
      quantity >= item.maxOrderQuantity
    ) {
      setMaxQtyError(true);
      setTimeout(() => {
        setMaxQtyError(false);
      }, 2000);
    }

    if (initialHit) {
      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "generics_item_add",
        medicineId: item.medicineId,
        price: item.effectivePrice.toFixed(2),
        count: updatedQuantity,
      });
    } else {
      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "generics_item_increment",
        medicineId: item.medicineId,
        price: item.effectivePrice.toFixed(2),
        count: updatedQuantity,
      });
    }
  };

  const minusQuantity = (e, item) => {
    setCartSpinner(true);
    e.stopPropagation();
    if (quantity > 0 && quantity > item.minOrderQuantity) {
      let updatedQuantity = quantity - multiplyFactorForOrderQuantity;
      updatedQuantity > 0 && addToCart(updatedQuantity, item);
      updatedQuantity === 0 && removeFromCart(item);

      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "generics_item_decrement",
        pageId: pageId,
        medicineId: item.medicineId,
        medicineName: item.name,
        price: item.ptr.toFixed(2),
        count: updatedQuantity,
      });
    }

    if (quantity <= item.minOrderQuantity) {
      removeFromCart(item);
      setMinQtyError(true);
      setTimeout(() => {
        setMinQtyError(false);
      }, 1000);
    }
  };

  const addToCart = async (qty, med) => {
    if (qty < 1) {
      qty = 1;
    }

    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "confirm_add_to_cart",
      medicineId: med.medicineId,
      medicineName: med.name,
      price: med?.effectivePrice?.toFixed(2),
      count: qty,
    });

    if (med.deliverySlot) {
      const params = {};
      const body = {
        androidMap: {
          locale: "en",
          source: "pharmacyWeb",
          deviceId: null,
          appVersion: navigator.userAgent,
          deviceName: null,
          page_id:pageId,
          screen_name: screen,
          appVersionCode: "156",
        },
        medicineId: med.medicineId,
        quantity: qty,
        retailerCode: userData.retailerCode,
        retailerId: userData.id,
      };
      Cart_Actions("ADD_TO_CART", params, body)
        .then((res) => {
          setCartSpinner(false);
          med.cartAdded = true;
          med.quantity = qty;
          setQuantity(qty);
          setCartData(res.data.data);
        })
        .catch((error) => {
          setCartSpinner(false);
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              addToCart(qty, med.medicineId);
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance(true);
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              content: "Oops something went wrong !",
              tryAgain: function () {
                addToCart(qty, med.medicineId);
              },
            });
          } else {
            setErrorModal({
              show: true,
              content: error?.response?.data.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                addToCart(qty, med.medicineId);
              },
            });
          }
        });
    } else {
      window.alert("You cannot add product to cart without Delivery Slot");
    }
  };

  const removeFromCart = (item) => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "delete_from_cart",
      medicineId: item.medicineId,
    });
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "generics_item_delete",
      medicineId: item.medicineId,
    });
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    setShowDeleteModal(false);
    const params = {};
    const body = {
      androidMap: {
        locale: "en",
        source: "pharmacyWeb",
        deviceId: null,
        appVersion: navigator.userAgent,
        deviceName: null,
        page_id:pageId,
        screen_name: screen,
        appVersionCode: "156",
      },
      medicineId: item.medicineId,
      retailerCode: data.retailerCode,
      retailerId: data.id,
    };

    Cart_Actions("REMOVE_FROM_CART", params, body)
      .then((res) => {
        setCartSpinner(false);
        delete item.cartAdded;
        setQuantity(0);
        setCartData(res.data.data);
        setDeleteItem(null);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            removeFromCart(item);
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              removeFromCart(item);
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error,
            tryAgain: function () {
              removeFromCart(item);
            },
          });
        }
      });
  };
  useEffect(() => {
    if (medData && medData.multiplyFactorForOrderQuantity > 0) {
      setmMltiplyFactorForOrderQuantity(medData.multiplyFactorForOrderQuantity);
    }
  }, []);
  useEffect(() => {}, [deleteItem]);
  return (
    <div className="ps_card_cont mt_16">
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <DeleteProductModal
        show={showDeleteModal}
        itemName={deleteItem?.medicineName}
        removeFromCart={() => removeFromCart(deleteItem)}
        onHide={() => setShowDeleteModal(false)}
      />
      <ProductPtrDialog
        show={productPtrShow}
        medData={medData}
        onHide={() => setProductPtrShow(false)}
      />
      <div
        className="ps_card bg_white"
        onClick={async (e) => {
          await Common_Event_Hit("CARD_TAP", {
            eventName: "Generics_Product_Click",
            pageId: pageId,
            medicineId: medData.id,
            position: position,
          });
          window.open(`/product/${medData.id}`, "_blank", "noreferrer");
        }}
      >
        <div className="ps_card_details">
          <div className="ps_card_left">
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip id={`tooltip-bottom`}>{medData.name}</Tooltip>}
            >
              <h6>{medData.name}</h6>
            </OverlayTrigger>
            <p>
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id={`tooltip-bottom`}>
                    {medData.manufacturer}
                  </Tooltip>
                }
              >
                <span>{medData.manufacturer}</span>
              </OverlayTrigger>{" "}
              {medData.packaging}
            </p>

            <div className="ps_card_left_1 cart_gen_card_left_1">
              {medData.cashDiscountInPercentage ? (
                <p>{medData.cashDiscountInPercentage}% CD</p>
              ) : (
                <></>
              )}
              {medData.scheme?.length ? (
                <p>{medData.scheme.join(" ")}</p>
              ) : (
                <></>
              )}
            </div>

            <div className="ps_card_left_2">
              <p>
                <span>MRP</span>₹ {medData.mrp}
              </p>
              <p>
                <span>
                  PTR{" "}
                  <img
                    src={info}
                    className="ptrInfo"
                    onClick={(e) => {
                      e.stopPropagation();
                      Common_Event_Hit("DYNAMIC_EVENT", {
                        eventName: "info_tap",
                        medicineId: medData.id,
                      });
                      setProductPtrShow(true);
                    }}
                    alt="ptr_img"
                  />
                </span>
                ₹ {medData.ptr}
              </p>
            </div>
          </div>

          <div className="ps_card_right p_16  cart_gen_pc_right">
            <img src={YellowRibbon} alt="margin" />
            <p>
              <span>{Math.round(medData.retailerMargin)}%</span>MARGIN
            </p>

            {medData.cartAdded && (
              <div className="add_cont">
                <div
                  className="product_add_btn product_add_btn_cart_gen_minus"
                  onClick={(e) => {
                    minusQuantity(e, medData);
                  }}
                >
                  <ImMinus />
                </div>

                <div
                  className="qty font_ubuntu"
                  style={{ textAlign: "center" }}
                >
                  {cartSpinner ? (
                    <Spinner
                      animation="border"
                      className="color_secondary spinner"
                      size="sm"
                      style={{ marginLeft: "-2px" }}
                    />
                  ) : (
                    quantity
                  )}
                </div>

                <div
                  className="product_add_btn product_add_btn_cart_gen"
                  onClick={(e) => {
                    addQuantity(e, medData);
                  }}
                >
                  <ImPlus />
                </div>
              </div>
            )}

            {!medData.cartAdded && !cartSpinner && (
              <button onClick={(e) => addQuantity(e, medData,true)}>+ ADD</button>
            )}

            {!medData.cartAdded && cartSpinner && (
              <button className="animateShimmer">+ ADD</button>
            )}
          </div>
        </div>

        {maxQtyError ? (
          <div className="ps_card_error">
            <p>Maximum Order Quantity is {medData.maxOrderQuantity}</p>
          </div>
        ) : (
          maxStockError && (
            <div className="ps_card_error">
              <p>Maximum Order Quantity is {medData.availableQuantity}</p>
            </div>
          )
        )}

        {minQtyError && (
          <div className="ps_card_error">
            <p>Minimum Order Quantity is {medData.minOrderQuantity}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default CartGenericProduct;
