import React, { Fragment, useEffect, useState, useContext } from "react";
import { Container, Spinner } from "react-bootstrap";
import ProductCard2 from "../ProductCard/ProductCard2";
import "../../App.css";
import "../ProductList/ProductList.css";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./categories.css";
import { common_product_widget } from "../../Actions/Widgets";
import { refreshToken } from "../../Actions/Auth";
import InfiniteScroll from "react-infinite-scroll-component";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import NoData from "../../Images/png/no_data.png";

const SpecialOffersProducts = () => {
  const [page, setPage] = useState(0);
  const [allProductList, setAllProductList] = useState();
  const [allProductListDealOfTheDay, setAllProductListDealOfTheDay] =
    useState();
  const [allProductListFlashSale, setAllProductListFlashSale] = useState();
  const [allProductListSpecialRate, setAllProductListSpecialRate] = useState();
  const [allProductListBulkByProducts, setAllProductListBulkByProducts] =
    useState();
  const [hasMore, setHasMore] = useState();
  const [dataLength, setDataLength] = useState(0);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState(false);
  const [homeShimmer, setHomerShimmer] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  const getAllProducts = (locationChange) => {
    let filter = location.pathname.split("/")[3];
    console.log(page, filter, locationChange);

    page === 0 && setHomerShimmer(true);
    locationChange && setHomerShimmer(true);
    let url;
    let params = {};
    if (filter === "DealOfTheDay") {
      url = "home/v2/getBulkByProducts";
      params = {
        dealType: "DealOfTheDay",
        page: page,
      };
    } else if (filter === "FlashSale") {
      url = "home/v2/getSpecialDiscountMedicines";
      params = {
        page: page,
      };
    } else if (filter === "SpecialRate") {
      url = "home/v2/getSpecialSchemeMedicines";
      params = {
        page: page,
      };
    } else if (filter === "BulkByProducts") {
      url = "home/v2/getBulkByProducts";
      params = {
        dealType: "BulkByProducts",
        page: page,
      };
    } else if (filter === "all") {
      url = "home/v2/getMedicineByCategory";
      params = {
        dealType: "BulkByProducts",
        page: page,
        category: "SPECIAL_OFFERS",
        filterCondition: "all",
      };
    }
    if (locationChange) {
      setPage(0);
      params.page = 0;
      setHasMore(true);
    }

    let api = common_product_widget(url, params);
    api
      .then((res) => {
        let DealOfTheDayObj;
        let FlashSaleObj;
        let SpecialRateObj;
        let BulkByProductsObj;
        let obj;
        if (filter === "DealOfTheDay") {
          DealOfTheDayObj =
            allProductListDealOfTheDay &&
            allProductListDealOfTheDay.medicinesResponsesList
              ? allProductListDealOfTheDay
              : { medicinesResponsesList: [] };

          if (locationChange) {
            DealOfTheDayObj = { medicinesResponsesList: [] };
          }
          if (res.data.data.medicinesResponsesList.length > 0) {
            DealOfTheDayObj.medicinesResponsesList.push(
              ...res.data.data.medicinesResponsesList
            );
            setDataLength(DealOfTheDayObj.medicinesResponsesList.length);
          }

          setAllProductListDealOfTheDay(DealOfTheDayObj);

          if (
            res.data.data.size > 0 &&
            DealOfTheDayObj.medicinesResponsesList.length < 10
          ) {
            if (params.page === 0) {
              console.log(true);
              fetchMoreData(true);
            } else {
              console.log(false);
              fetchMoreData(false);
            }
          } else if (
            res.data.data.size > 0 &&
            res.data.data.medicinesResponsesList.length === 0
          ) {
            if (params.page === 0) {
              console.log(true);
              fetchMoreData(true);
            } else {
              console.log(false);
              fetchMoreData(false);
            }
          }
        } else if (filter === "FlashSale") {
          FlashSaleObj =
            allProductListFlashSale &&
            allProductListFlashSale.medicinesResponsesList
              ? allProductListFlashSale
              : { medicinesResponsesList: [] };

          if (locationChange) {
            FlashSaleObj = { medicinesResponsesList: [] };
          }
          if (res.data.data.medicinesResponsesList.length > 0) {
            FlashSaleObj.medicinesResponsesList.push(
              ...res.data.data.medicinesResponsesList
            );
            setDataLength(FlashSaleObj.medicinesResponsesList.length);
          }
          setAllProductListFlashSale(FlashSaleObj);

          if (
            res.data.data.size > 0 &&
            FlashSaleObj.medicinesResponsesList.length < 10
          ) {
            if (params.page === 0) {
              fetchMoreData(true);
            } else {
              fetchMoreData(false);
            }
          } else if (
            res.data.data.size > 0 &&
            res.data.data.medicinesResponsesList.length === 0
          ) {
            if (params.page === 0) {
              fetchMoreData(true);
            } else {
              fetchMoreData(false);
            }
          }
        } else if (filter === "SpecialRate") {
          SpecialRateObj =
            allProductListSpecialRate &&
            allProductListSpecialRate.medicinesResponsesList
              ? allProductListSpecialRate
              : { medicinesResponsesList: [] };

          if (locationChange) {
            SpecialRateObj = { medicinesResponsesList: [] };
          }
          if (res.data.data.medicinesResponsesList.length > 0) {
            SpecialRateObj.medicinesResponsesList.push(
              ...res.data.data.medicinesResponsesList
            );
            setDataLength(SpecialRateObj.medicinesResponsesList.length);
          }

          setAllProductListSpecialRate(SpecialRateObj);
          if (
            res.data.data.size > 0 &&
            SpecialRateObj.medicinesResponsesList.length < 10
          ) {
            if (params.page === 0) {
              fetchMoreData(true);
            } else {
              fetchMoreData(false);
            }
          } else if (
            res.data.data.size > 0 &&
            res.data.data.medicinesResponsesList.length === 0
          ) {
            if (params.page === 0) {
              fetchMoreData(true);
            } else {
              fetchMoreData(false);
            }
          }
        } else if (filter === "BulkByProducts") {
          BulkByProductsObj =
            allProductListBulkByProducts &&
            allProductListBulkByProducts.medicinesResponsesList
              ? allProductListBulkByProducts
              : { medicinesResponsesList: [] };

          if (locationChange) {
            BulkByProductsObj = { medicinesResponsesList: [] };
          }
          if (res.data.data.medicinesResponsesList.length > 0) {
            BulkByProductsObj.medicinesResponsesList.push(
              ...res.data.data.medicinesResponsesList
            );
            setDataLength(BulkByProductsObj.medicinesResponsesList.length);
          }

          setAllProductListBulkByProducts(BulkByProductsObj);

          if (
            res.data.data.size > 0 &&
            BulkByProductsObj.medicinesResponsesList.length < 10
          ) {
            if (params.page === 0) {
              fetchMoreData(true);
            } else {
              fetchMoreData(false);
            }
          } else if (
            res.data.data.size > 0 &&
            res.data.data.medicinesResponsesList.length === 0
          ) {
            if (params.page === 0) {
              fetchMoreData(true);
            } else {
              fetchMoreData(false);
            }
          }
        } else if (filter === "all") {
          obj =
            allProductList && allProductList.medicinesResponsesList
              ? allProductList
              : { medicinesResponsesList: [] };

          if (locationChange) {
            obj = { medicinesResponsesList: [] };
          }
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(
              ...res.data.data.medicinesResponsesList
            );
            setDataLength(obj.medicinesResponsesList.length);
          }

          setAllProductList(obj);

          if (
            res.data.data.size > 0 &&
            obj.medicinesResponsesList.length < 10
          ) {
            if (params.page === 0) {
              fetchMoreData(true);
            } else {
              fetchMoreData(false);
            }
          } else if (
            res.data.data.size > 0 &&
            res.data.data.medicinesResponsesList.length === 0
          ) {
            if (params.page === 0) {
              fetchMoreData(true);
            } else {
              fetchMoreData(false);
            }
          }
        }

        // if (
        //   res.data.data.size > 0 &&
        //   res.data.data.medicinesResponsesList.length == 0 &&
        //   params.page !== 0
        // ) {

        //   fetchMoreData(false);
        // }

        // if (
        //   params.page == 0 &&
        //   res.data.data.size > 0 &&
        //   res.data.data.medicinesResponsesList.length < 10
        // ) {
        //   fetchMoreData(true);
        // }

        if (res.data.data.size === 0) {
          setHasMore(false);
        }
        setHomerShimmer(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getAllProducts();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getAllProducts();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getAllProducts();
            },
          });
        }
      });
  };

  const fetchMoreData = (status) => {
    let currScreen = window.location.href.split("/").pop();
    let funcScreen = location.pathname.split("/").pop();
    console.log(currScreen, funcScreen);
    if (currScreen === funcScreen) {
      if (status) {
        setPage(1);
      } else {
        setPage(+page + 1);
      }
    }
  };

  useEffect(() => {
    let userData = localStorage.getItem("userData");
    if (userData) {
      page !== 0 && getAllProducts(false);
    } else {
      navigate("/home");
    }
  }, [page]);

  useEffect(() => {
    localStorage.setItem("currentScreen", "special_offer_screen");
    setPage(0);
    setAllProductList();
    setAllProductListBulkByProducts();
    setAllProductListDealOfTheDay();
    setAllProductListFlashSale();
    setAllProductListSpecialRate();
    setHasMore(false);
    getAllProducts(true);
  }, [location]);

  return (
    <Fragment>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Container fluid className="productList">
        <Container fluid className="productListHeader font_ubuntu display_flex">
          <HiOutlineArrowNarrowLeft
            className="cursor_pointer goBack"
            onClick={() => navigate("/categories/specialOffers")}
          />
          &nbsp;&nbsp;&nbsp;
          <div className="head_specialOffers">
            Special Offers <br />{" "}
            <span className="sub_head">84 Companies • 753 SKU'S</span>
            <div className="specialOffers_navbar_mobile">
              <Link
                to="/categories/specialOffers/all"
                className="text_decoration_none cursor_pointer navLink"
              >
                <p
                  className={
                    ("font_14",
                    location.pathname === "/categories/specialOffers/all" &&
                      "activeClass")
                  }
                >
                  ALL
                </p>
              </Link>
              <Link
                to="/categories/specialOffers/DealOfTheDay"
                className="text_decoration_none cursor_pointer navLink"
              >
                <p
                  className={
                    ("font_14",
                    location.pathname ===
                      "/categories/specialOffers/DealOfTheDay" && "activeClass")
                  }
                >
                  Deal Of the day
                </p>
              </Link>

              <Link
                to="/categories/specialOffers/FlashSale"
                className="text_decoration_none cursor_pointer navLink"
              >
                <p
                  className={
                    ("font_14",
                    location.pathname ===
                      "/categories/specialOffers/FlashSale" && "activeClass")
                  }
                >
                  Flash Sale
                </p>
              </Link>

              <Link
                to="/categories/specialOffers/SpecialRate"
                className="text_decoration_none cursor_pointer navLink"
              >
                <p
                  className={
                    ("font_14",
                    location.pathname ===
                      "/categories/specialOffers/SpecialRate" && "activeClass")
                  }
                >
                  Special Rate
                </p>
              </Link>
              <Link
                to="/categories/specialOffers/BulkByProducts"
                className="text_decoration_none cursor_pointer navLink"
              >
                <p
                  className={
                    ("font_14",
                    location.pathname ===
                      "/categories/specialOffers/BulkByProducts" &&
                      "activeClass")
                  }
                >
                  Bulk Rate
                </p>
              </Link>
            </div>
          </div>
          <div className="specialOffers_navbar">
            <Link
              to="/categories/specialOffers/all"
              className="text_decoration_none cursor_pointer navLink"
            >
              <p
                className={
                  ("font_14",
                  location.pathname === "/categories/specialOffers/all" &&
                    "activeClass")
                }
              >
                ALL
              </p>
            </Link>
            <Link
              to="/categories/specialOffers/DealOfTheDay"
              className="text_decoration_none cursor_pointer navLink"
            >
              <p
                className={
                  ("font_14",
                  location.pathname ===
                    "/categories/specialOffers/DealOfTheDay" && "activeClass")
                }
              >
                Deal Of the day
              </p>
            </Link>

            <Link
              to="/categories/specialOffers/FlashSale"
              className="text_decoration_none cursor_pointer navLink"
            >
              <p
                className={
                  ("font_14",
                  location.pathname === "/categories/specialOffers/FlashSale" &&
                    "activeClass")
                }
              >
                Flash Sale
              </p>
            </Link>

            <Link
              to="/categories/specialOffers/SpecialRate"
              className="text_decoration_none cursor_pointer navLink"
            >
              <p
                className={
                  ("font_14",
                  location.pathname ===
                    "/categories/specialOffers/SpecialRate" && "activeClass")
                }
              >
                Special Rate
              </p>
            </Link>
            <Link
              to="/categories/specialOffers/BulkByProducts"
              className="text_decoration_none cursor_pointer navLink"
            >
              <p
                className={
                  ("font_14",
                  location.pathname ===
                    "/categories/specialOffers/BulkByProducts" && "activeClass")
                }
              >
                Bulk Rate
              </p>
            </Link>
          </div>
        </Container>
        {!homeShimmer && (
          <>
            {location.pathname.split("/")[3] === "all" &&
              allProductList &&
              allProductList.medicinesResponsesList.length > 0 && (
                <div id="scrollableDiv" className="productList" style={{paddingTop: '0rem'}}>
                  <InfiniteScroll
                    dataLength={dataLength}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    scrollableTarget="scrollableDiv"
                    scrollThreshold="90%"
                    className="infiniteScroll"
                    loader={
                      <Spinner
                        animation="border"
                        className="color_secondary spinner"
                        size="lg"
                      />
                    }
                  >
                    {allProductList &&
                      allProductList.medicinesResponsesList.map(
                        (med, index) => (
                          <ProductCard2
                            position={index}
                            screen={"special_offer_screen"}
                            key={index}
                            medData={med}
                          />
                        )
                      )}
                  </InfiniteScroll>
                </div>
              )}
            {location.pathname.split("/")[3] === "DealOfTheDay" &&
              allProductListDealOfTheDay &&
              allProductListDealOfTheDay.medicinesResponsesList.length > 0 && (
                <div id="scrollableDiv" className="productList" style={{paddingTop: '0rem'}}>
                  <InfiniteScroll
                    dataLength={dataLength}
                    next={() => {
                      fetchMoreData();
                    }}
                    hasMore={hasMore}
                    scrollableTarget="scrollableDiv"
                    scrollThreshold="90%"
                    className="infiniteScroll"
                    loader={
                      <Spinner
                        animation="border"
                        className="color_secondary spinner"
                        size="lg"
                      />
                    }
                  >
                    {allProductListDealOfTheDay &&
                      allProductListDealOfTheDay.medicinesResponsesList.map(
                        (med, index) => (
                          <ProductCard2
                            position={index}
                            screen={"special_offer_screen"}
                            key={index}
                            medData={med}
                          />
                        )
                      )}
                  </InfiniteScroll>
                </div>
              )}
            {location.pathname.split("/")[3] === "FlashSale" &&
              allProductListFlashSale &&
              allProductListFlashSale.medicinesResponsesList.length > 0 && (
                <div id="scrollableDiv" className="productList" style={{paddingTop: '0rem'}}>
                  <InfiniteScroll
                    dataLength={dataLength}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    scrollableTarget="scrollableDiv"
                    scrollThreshold="90%"
                    className="infiniteScroll"
                    loader={
                      <Spinner
                        animation="border"
                        className="color_secondary spinner"
                        size="lg"
                      />
                    }
                  >
                    {allProductListFlashSale &&
                      allProductListFlashSale.medicinesResponsesList.map(
                        (med, index) => (
                          <ProductCard2
                            position={index}
                            screen={"special_offer_screen"}
                            key={index}
                            medData={med}
                          />
                        )
                      )}
                  </InfiniteScroll>
                </div>
              )}
            {location.pathname.split("/")[3] === "SpecialRate" &&
              allProductListSpecialRate &&
              allProductListSpecialRate.medicinesResponsesList.length > 0 && (
                <div id="scrollableDiv" className="productList" style={{paddingTop: '0rem'}}>
                  <InfiniteScroll
                    dataLength={dataLength}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    scrollableTarget="scrollableDiv"
                    scrollThreshold="90%"
                    className="infiniteScroll"
                    loader={
                      <Spinner
                        animation="border"
                        className="color_secondary spinner"
                        size="lg"
                      />
                    }
                  >
                    {allProductListSpecialRate &&
                      allProductListSpecialRate.medicinesResponsesList.map(
                        (med, index) => (
                          <ProductCard2
                            position={index}
                            screen={"special_offer_screen"}
                            key={index}
                            medData={med}
                          />
                        )
                      )}
                  </InfiniteScroll>
                </div>
              )}
            {location.pathname.split("/")[3] === "BulkByProducts" &&
              allProductListBulkByProducts &&
              allProductListBulkByProducts.medicinesResponsesList.length >
                0 && (
                <div id="scrollableDiv" className="productList" style={{paddingTop: '0rem'}}>
                  <InfiniteScroll
                    dataLength={dataLength}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    scrollableTarget="scrollableDiv"
                    scrollThreshold="90%"
                    className="infiniteScroll"
                    loader={
                      <Spinner
                        animation="border"
                        className="color_secondary spinner"
                        size="lg"
                      />
                    }
                  >
                    {allProductListBulkByProducts &&
                      allProductListBulkByProducts.medicinesResponsesList.map(
                        (med, index) => (
                          <ProductCard2
                            position={index}
                            screen={"special_offer_screen"}
                            key={index}
                            medData={med}
                          />
                        )
                      )}
                  </InfiniteScroll>
                </div>
              )}
          </>
        )}
        {homeShimmer && (
          <Fragment>
            <div className="padding_5">
              <ProductCard2 shimmer={homeShimmer} />
            </div>
            <div className="padding_5">
              <ProductCard2 shimmer={homeShimmer} />
            </div>
            <div className="padding_5">
              <ProductCard2 shimmer={homeShimmer} />
            </div>
            <div className="padding_5">
              <ProductCard2 shimmer={homeShimmer} />
            </div>
            <div className="padding_5">
              <ProductCard2 shimmer={homeShimmer} />
            </div>
          </Fragment>
        )}
        {!homeShimmer &&
          location.pathname.split("/")[3] === "all" &&
          allProductList &&
          allProductList.medicinesResponsesList.length === 0 && (
            <div
              id="scrollableDiv"
              className="productList "
              style={{ paddingTop: "50px" }}
            >
              <div className="no_data_parent">
                <img src={NoData} alt="No Data" />
                <br /> <br />
                <p className="font_ubuntu font_16 font_weight_md">
                  No Products Found !
                </p>
              </div>
            </div>
          )}
        {!homeShimmer &&
          location.pathname.split("/")[3] === "DealOfTheDay" &&
          allProductListDealOfTheDay &&
          allProductListDealOfTheDay.medicinesResponsesList.length === 0 && (
            <div
              id="scrollableDiv"
              className="productList "
              style={{ paddingTop: "50px" }}
            >
              <div className="no_data_parent">
                <img src={NoData} alt="No Data" />
                <br /> <br />
                <p className="font_ubuntu font_16 font_weight_md">
                  No Products Found !
                </p>
              </div>
            </div>
          )}
        {!homeShimmer &&
          location.pathname.split("/")[3] === "FlashSale" &&
          allProductListFlashSale &&
          allProductListFlashSale.medicinesResponsesList.length === 0 && (
            <div
              id="scrollableDiv"
              className="productList "
              style={{ paddingTop: "50px" }}
            >
              <div className="no_data_parent">
                <img src={NoData} alt="No Data" />
                <br /> <br />
                <p className="font_ubuntu font_16 font_weight_md">
                  No Products Found !
                </p>
              </div>
            </div>
          )}
        {!homeShimmer &&
          location.pathname.split("/")[3] === "SpecialRate" &&
          allProductListSpecialRate &&
          allProductListSpecialRate.medicinesResponsesList.length === 0 && (
            <div
              id="scrollableDiv"
              className="productList "
              style={{ paddingTop: "50px" }}
            >
              <div className="no_data_parent">
                <img src={NoData} alt="No Data" />
                <br /> <br />
                <p className="font_ubuntu font_16 font_weight_md">
                  No Products Found !
                </p>
              </div>
            </div>
          )}
        {!homeShimmer &&
          location.pathname.split("/")[3] === "BulkByProducts" &&
          allProductListBulkByProducts &&
          allProductListBulkByProducts.medicinesResponsesList.length === 0 && (
            <div
              id="scrollableDiv"
              className="productList "
              style={{ paddingTop: "50px" }}
            >
              <div className="no_data_parent">
                <img src={NoData} alt="No Data" />
                <br /> <br />
                <p className="font_ubuntu font_16 font_weight_md">
                  No Products Found !
                </p>
              </div>
            </div>
          )}
      </Container>
    </Fragment>
  );
};

export default React.memo(SpecialOffersProducts);
