import React, { useContext, useEffect, useState, useRef } from "react";
import ErrorModal from "../../Modals/ErrorModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import { BannerData, searchContext, usersData } from "../../../App";
import { getSpecificWidget } from "../../../Actions/WholeSale/HomePage";
import { refreshToken } from "../../../Actions/Auth";
import BannerSlider from "../../BannerRedirects/BannerSlider";
import "./Home.css";
import { Carousel } from "react-bootstrap";
import { useNavigate } from "react-router";

function Banner(props) {
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [bannerData, setBannerData] = useState([]);
  const [wholesaleBannerImages, setWholesaleBannerImages] = useState([]);
  const { showRecent, setShowRecent } = useContext(searchContext);
  const navigate = useNavigate();
  const { setBannerData: setWSBannerData } = useContext(BannerData);

  const getBannerData = async () => {
    //guardian check to return if the props aren't available yet
    if (!props?.data) return;
    //api call to get the banner data
    try {
      const res = await getSpecificWidget(props?.data?.data?.apiEndPoint);
      setBannerData(res?.data?.data);
      let bannerImages = [];
      res?.data?.data?.map((item, _) => {
        bannerImages.push(
          <img
            className="banner_img"
            src={item?.imageUrl}
            alt={item?.caption}
          />
        );
      });
      setWholesaleBannerImages(bannerImages);
    } catch (error) {
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getBannerData();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getBannerData();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getBannerData();
          },
        });
      }
    }
  };

  const wholesaleHomeBannerSlide = (event) => {
    localStorage.setItem(
      "currentScreenBannerData",
      JSON.stringify({ index: +event + 1, data: bannerData[event] })
    );
  };

  //onMount
  useEffect(() => {
    //call the banner data fetch api
    getBannerData();
  }, []);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      {bannerData?.length > 0 && (
        <div
          className="wholesale-banner-wrapper"
          style={{ marginTop: showRecent && "0px" }}
        >
          <div className="web_carousel">
            <div className="banner_section">
              <BannerSlider
                slides={wholesaleBannerImages}
                slideChange={wholesaleHomeBannerSlide}
              />
            </div>
          </div>

          <Carousel
            className="banner_section mobile_carousel"
            onSlide={wholesaleHomeBannerSlide}
          >
            {bannerData.map((ele, ind) => (
              <Carousel.Item key={ind}>
                <div
                  // onClick={() => {
                  //   setWSBannerData(ele);
                  //   navigate("/bannerRedirect");
                  // }}
                >
                  <img
                    className="d-block banner_img"
                    src={ele.imageUrl}
                    alt={ele.caption}
                  />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
}

export default Banner;
