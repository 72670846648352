import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router";

function WSShipmentCard({ data, ...props }) {
  const navigate = useNavigate();
  return (
    <div className="ws-shipment-card" {...props}>
      <div className="ws-shipment-card-header">
        <p>{data?.shipmentNumber}</p>
        <p>Seller : {data?.sellerName}</p>
      </div>
      <div className="ws-shipment-card-body">
        <div className="ws-shipment-card-body-sub-section-1">
          <p>Total Amount</p>
          <p>Delivery</p>
        </div>
        <div className="ws-shipment-card-body-sub-section-2">
          <p>
            ₹{" "}
            {data?.totalAmount?.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
          <p>{data?.delivery}</p>
        </div>
      </div>
      <div className="ws-shipment-card-footer">
        <div className="ws-shipment-card-footer-items-container">
          {data?.cartMedicineDetailResponseList?.map((item, index) => {
            return (
              <div className="ws-shipment-card-footer-item">
                <p>
                  {item?.orderedQuantity} x {item?.medicineName}
                </p>
                <p>
                  ₹{" "}
                  {item?.netValue?.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
            );
          })}

          <Accordion flush className="ws-shipment-see-all-items">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                See all {data?.cartMedicineDetailResponseList?.length} items
              </Accordion.Header>
              <Accordion.Body className="ws-shipment-see-all-body">
                <p>Items ({data?.cartMedicineDetailResponseList?.length})</p>

                {data?.cartMedicineDetailResponseList?.map((item, index) => {
                  return (
                    <div
                      className="ws-shipment-medicine-card"
                      key={index}
                      style={{ marginTop: index === 0 && "1rem" }}
                    >
                      <div>
                        <p>{item?.medicineName}</p>
                        <button
                          onClick={() => navigate("/wholesale/home/cart")}
                        >
                          Edit
                        </button>
                      </div>
                      <div>
                        <div className="ws-net-rate-container">
                          <div>
                            <p>Net Rate</p>
                            <p>₹ {item?.ptr?.toFixed(2)}</p>
                          </div>
                          <div>
                            <p>Quantity</p>
                            <p>{item?.orderedQuantity}</p>
                          </div>
                        </div>
                        <div className="ws-total-container">
                          <p>TOTAL</p>
                          <p>
                            ₹{" "}
                            {item?.netValue?.toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default WSShipmentCard;
