import axios from "axios";
import qs from "qs";
import { variables } from "../environments";

let config = {
  headers: {
    "Content-Type": "application/json",
    "source":"pharmacyWeb"
  },
};
const BASE = variables.ADMIN_URL;

const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return true;
};

export const getCreditData = async (params, body) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/home/getCreditData`, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};

export const postCreditUserData = async (params, body) => {
  const data = getToken().then(() => {
    return axios.post(`${BASE}/home/saveCreditUserData`, body, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};

export const uploadSupportDocuments = async (params, body) => {
  const data = getToken().then(() => {
    return axios.post(`${BASE}/credit/user/apply/uploadImage`, body, {
      params: params,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: config.headers.Authorization,
      },
    });
  });
  return data;
};

export const creditOnboardingPopup = async (params, body) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/credit/user/creditOnboardingPopUp`, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};

export const updateCreditUserStatus = async (params, headers, body) => {
  const data = getToken().then(() => {
    return axios.put(`${BASE}/credit/user/updateCreditUserStatus`, body, {
      params: params,
      headers: { ...config.headers, ...headers},
    });
  });
  return data;
};
