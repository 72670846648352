import { Modal, Button, NavItem } from "react-bootstrap";
import React, { Fragment, useEffect } from "react";

import "../../App.css";
import "./dialog.css";
import { useNavigate, useLocation } from "react-router";
import { Common_Event_Hit } from "../../Actions/Events";

const LogoutModal = (props) => {
  useEffect(() => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "dialog_open",
      dialog_name: "logout_dialog",
    });
  }, []);
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className="del_modal"
    >
      <Modal.Header className="bg_primary">
        <Modal.Title id="contained-modal-title-vcenter" className="">
          <p className="font_ubuntu color_white font_weight_md font_18">
            <b>Logout</b>
          </p>
          <div className="font_ubuntu color_white font_14 ">
            Are you sure you want to logout
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer className="display_flex space_btw bg_primary logout_footer">
        <Button
          className=" brdr_grey bg_primary got_it c font_weight_md"
          onClick={() => {
            Common_Event_Hit("DYNAMIC_EVENT", {
              eventName: "cancel_tap",
             dialog_name:'logout_dialog'
            })
            props.onHide()
          }}
        >
          CANCEL
        </Button>
        <Button
          className="bg_white brdr_grey color_primary logout  font_weight_md"
          onClick={props.logout}
        >
          LOGOUT
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutModal;
