import React, {useEffect, useState } from "react";
import { Container, Carousel } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import "./OnboardingFinalScreenNew.css";
import saveo from "../../Images/svg/saveo-wholesale.svg";
import onboardingImage from "../../Images/svg/Onboarding- 2.svg";

import {CAROUSEL_IMAGES} from "../../Images/svg";
import { useNavigate } from "react-router";
import { usersData } from "../../App";

function OnboardingFinalScreenNew() {
  const [showFinalScreen, setShowFinalScreen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    const { businessType } = userData;
    if (businessType[0] === "PHARMACY") {
      navigate("/home");
    } else {
      setShowFinalScreen(true);
    }
  });

  return (
    <>
      {showFinalScreen && (
        <Container fluid className="final-onboard-parent-container">
          <div
            className="final-nav-container"
            //   onClick={navigateBackToOrders}
          >
            <BiArrowBack size="1.5rem" color="#FFFFFF" />
            <span className="final-onboard-page-title">Welcome</span>
          </div>
          <Container fluid className="final-onboard-main-container">
            <div className="header-logo-container">
              <img src={saveo} className="wholesale-img"></img>
            </div>
            <h3 className="welcome-header color_secondary">WELCOMES YOU</h3>
            <p style={{ color: "#CAD1DF" }}>
              Your digital Business is under construction
            </p>

            <div className="main-content-container">
              <img className="onboarding-img" src={onboardingImage}></img>
              <Carousel className="image-carousel" controls={false} fade={true}>
                <Carousel.Item>
                  <img
                    src={CAROUSEL_IMAGES.carousel1}
                    className="d-block w-100 carousel-image "
                    alt="minimum 10% CD on all medicines"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={CAROUSEL_IMAGES.carousel2}
                    className="d-block w-100 carousel-image"
                    alt="minimum order 4999"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={CAROUSEL_IMAGES.carousel3}
                    className="d-block w-100 carousel-image"
                    alt="cash on delivery available"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={CAROUSEL_IMAGES.carousel4}
                    className="d-block w-100 carousel-image"
                    alt="free shipping accross 28000 pincodes"
                  />
                </Carousel.Item>
              </Carousel>
              <p style={{ color: "#364668" }}>
                Visit us{" "}
                <a
                  className="redirect-link"
                  href="https://saveowholesale.in/"
                  target="_blank"
                >
                  @https://saveowholesale.in/
                </a>
              </p>
            </div>
          </Container>
        </Container>
      )}
    </>
  );
}

export default OnboardingFinalScreenNew;
