import axios from "axios";
import qs from "qs";
import { variables } from "../environments";

const ADMIN_URL = variables.ADMIN_URL;

let config = {
  headers: {
    "Content-Type": "application/json",
    "source":"pharmacyWeb",
    appVersionCode: variables.appVersionCode,
  },
};

const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);
  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config.headers.Authorization = "Bearer " + token;
  if (
    onBehalfRetCode &&
    onBehalfRetCode.length > 0 &&
    onBehalfRetCode !== "undefined" &&
    userData.admin
  ) {
    config.headers.adminRetailerCode = onBehalfRetCode;
  }
  return true;
};

export const banner_api = async (uri) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri}`, {
      headers: config.headers,
    });
  });

  return obj;
};
export const app_message_widget = async (uri) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri}`, {
      headers: config.headers,
    });
  });

  return obj;
};

export const banner_widget = async (uri) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri}`, {
      headers: config.headers,
    });
  });

  return obj;
};

export const timer_product_widget = async (uri) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri}`, {
      headers: config.headers,
    });
  });

  return obj;
};
export const common_product_widget = async (uri, params) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri.split("?")[0]}`, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
      headers: config.headers,
    });
  });

  return obj;
};



export const app_section_widget = async (uri) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri}`, {
      headers: config.headers,
    });
  });

  return obj;
};

export const day_deal = async (uri) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri}`, {
      headers: config.headers,
    });
  });

  return obj;
};

export const special_discount = async (uri) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri}`, {
      headers: config.headers,
    });
  });

  return obj;
};
export const common_sale = async (uri) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri}`, {
      headers: config.headers,
    });
  });

  return obj;
};
export const trending_products = async (uri) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri}`, {
      headers: config.headers,
    });
  });

  return obj;
};

export const covid_essentials = async (uri) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri}`, {
      headers: config.headers,
    });
  });

  return obj;
};

export const order_again = async (uri) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri}`, {
      headers: config.headers,
    });
  });

  return obj;
};

export const timer_product_widget_two = async (uri) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri}`, {
      headers: config.headers,
    });
  });

  return obj;
};

export const category_widget = async (uri, id) => {
  const params = { retailerId: id };

  const obj = getToken().then(() => {
    let headers = JSON.parse(JSON.stringify(config.headers))
    headers.adminRetailerCode = id;

    return axios.get(`${ADMIN_URL}/${uri.split("?")[0]}`, {
      params: params,
      headers: headers,
    });
  });

  return obj;
};

export const general_issue_widget = async (uri) => {
  const obj = getToken().then(() => {
    let headers = JSON.parse(JSON.stringify(config.headers))
    headers.locale = "en";
    return axios.get(`${ADMIN_URL}/${uri}`, {
      headers: headers,
    });
  });

  return obj;
};

export const high_margin_substitute_products = async (uri, params) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri.split("?")[0]}`, {
      params: params,
      headers: config.headers,
    });
  });

  return obj;
};

export const composition_products = async (uri, params) => {
  const obj = getToken().then(() => {
    return axios.get(`${ADMIN_URL}/${uri.split("?")[0]}`, {
      params: params,
      headers: config.headers,
    });
  });

  return obj;
};
