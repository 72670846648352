export const variables = {
  ADMIN_URL: "https://gatewayprod.saveo.in/api",
  version: "4",
  mixPanelKey: "5c3d71d8969d07eade7a841605f12ebb",
  WHOLESALE_URL : 'https://gatewayprod.saveo.in/api',
  appVersionCode: 187,
};

export const Moengage = window.moe({
  app_id: "3AR8DRLKA6RERFNQ9FN4SPL4",
  debug_logs: 0,
  swPath: '/service-worker.js'
});

export const Maps = {
  MAPS_API_KEY: "AIzaSyB4bv_dbewMr5lSjhJyWokBVWWgCvMs0uY",
};

export const updateDetails = {
  title: 'what\'s new 🎉',
  description:'just some random update. nothing important!',
}
