import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { Card, Container, ListGroup } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { GrStatusGoodSmall } from "react-icons/gr";
import { useNavigate } from "react-router";
import { getRetailerTickets } from "../../Actions/Support";
import ErrorModal from "../Modals/ErrorModal";
import { usersData } from "../../App";
import { refreshToken } from "../../Actions/Auth";

function PastTicketsScreen() {
  const navigate = useNavigate();
  const [pastTicketData, setPastTicketData] = useState([]);
  const [shimmer, setShimmer] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [showFallBack, setShowFallBack] = useState(false);

  const getPastTicketData = () => {
    setShimmer(true);

    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    const params = {
      retailerId: userData.id,
      retailerCode: userData.retailerCode,
    };
    getRetailerTickets({}, params)
      .then((res) => {
        console.log(res.data.data);
        setPastTicketData(res.data.data);
        setShimmer(false);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          refreshToken().then((res) => {
            getPastTicketData();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error.response.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong!",
            tryAgain: function () {
              getPastTicketData();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getPastTicketData();
            },
          });
        }
      });
  };

  //onMount
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("currentScreen", "current_tickets_screen");
    getPastTicketData();
  }, []);

  useEffect(() => {
    if (pastTicketData) {
      pastTicketData.length === 0
        ? setShowFallBack(true)
        : setShowFallBack(false);
    }
  }, [pastTicketData]);
  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Container fluid className="order-issue-parent-container">
        <div className="order-issue-nav-container nav-container">
          <BiArrowBack
            size="1.5rem"
            color="#4b4495"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span className="page-title">Report Issue</span>
        </div>
        <Container fluid className="order-issue-main-container">
          {shimmer &&
            [...Array(5)].map((item, index) => {
              return (
                <Card
                  className="order-list-item-card animateShimmer"
                  style={{ minHeight: "9rem" }}
                ></Card>
              );
            })}

          {!shimmer && showFallBack && (
            <Container fluid className="no-orders-container">
              <p>No tickets</p>
            </Container>
          )}

          {!shimmer &&
            pastTicketData.map((ticket, index) => {
              return (
                <Card key={index} className="order-list-item-card">
                  <ListGroup.Item className="order-list-group-item">
                    <div className="order-list-subitem-group-left">
                      <p className="oreder-list-item-header-text">Ticket Id</p>
                      <p>{ticket.ticketId}</p>
                    </div>
                    <div className="order-list-subitem-group-right">
                      <p className="oreder-list-item-header-text">
                        Ticket Data
                      </p>
                      <p>{moment(ticket.timestamp).format("lll")}</p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="order-list-group-item">
                    <div className="order-list-subitem-group-left">
                      <p style={{ fontWeight: "bold" }}>{ticket.issue}</p>
                      <p>{ticket.subIssue}</p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="order-list-group-item">
                    <div className="order-list-subitem-group-left">
                      <p>{ticket.description}</p>
                    </div>
                    <div className="order-list-subitem-group-right">
                      <div className="order-list-item-final-row">
                        <span style={{ marginRight: "0.2rem" }}>
                          <GrStatusGoodSmall
                            color={ticket.status === "Open" ? "#48D1CC" : "red"}
                          />
                        </span>
                        <p>{ticket.status}</p>
                      </div>
                    </div>
                  </ListGroup.Item>
                </Card>
              );
            })}
        </Container>
      </Container>
    </>
  );
}

export default PastTicketsScreen;
