import React, { useContext, useEffect, useRef, useState } from "react";
import { AiFillSetting, AiOutlineMenu } from "react-icons/ai";
import ErrorModal from "../../Modals/ErrorModal";
import "./WSHeader.css";
import { useServiceWorker } from "../../../useServiceWorker";
import { networkStatusContext, searchContext, WSCartDataContext } from "../../../App";
import OnBehalf from "../../Modals/OnBehalf";
import { useLocation, useNavigate } from "react-router";
import { WHOLESALE_HEADER, WHOLESALE_FOOTER } from "../../../Images/svg";
import {
  Row,
  Col,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaUserShield } from "react-icons/fa";
import { IoIosWifi, IoMdCart } from "react-icons/io";
import SearchBarComponent from "../../Header/SearchBarComponent";
import { BsFillExclamationTriangleFill } from "react-icons/bs";

function WSHeader({ getSearchString, userLoggedIn, categories }) {
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [userData, setUserData] = useState();
  const { WSCartData, setWSCartData } = useContext(WSCartDataContext);
  const [onBehalf, setShowOnBehalf] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [onBehalfExist, setOnBehalfExist] = useState(false);
  const searchRef = useRef();
  const { showRecent, setShowRecent } = useContext(searchContext);
  const [cartShake, setCartShake] = useState(false);
  const {networkStatus, showNetworkStatus} = useContext(networkStatusContext);
  const { isUpdateAvailable, updateAssets } = useServiceWorker();



  useEffect(() => {
    let userData = localStorage.getItem("userData");
    if (userData && userData.length) {
      setUserData(JSON.parse(userData));

      let retCode = localStorage.getItem("onBehalfRetCode");
      JSON.parse(userData)?.enabled &&
        retCode &&
        retCode.length > 0 &&
        retCode !== "undefined" &&
        setOnBehalfExist(true);
    } else {
      setOnBehalfExist(false);
    }
  }, [userLoggedIn]);

  useEffect(() => {
    if (
      location.pathname.split("/")[1] === "onboarding" ||
      location.pathname.split("/")[1] === "login" ||
      location.pathname.split('/')[1] !== 'wholesale'
    ) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location, userLoggedIn]);

  useEffect(() => {
    if (WSCartData && WSCartData.size) {
      setCartShake(true);
      setTimeout(() => {
        setCartShake(false);
      }, [1500]);
    }
  }, [WSCartData]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: true, content: "" });
        }}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
      />
        {!networkStatus ? (
        <div className="network-panel">
          <p className="no-network">
            <BsFillExclamationTriangleFill /> No Internet Connection
          </p>
        </div>
      ) : showNetworkStatus ? (
        <div className="network-panel">
          <p className="network">
            <IoIosWifi /> Internet Connection is back online
          </p>
        </div>
      ) :isUpdateAvailable? (
        isUpdateAvailable && (
          <div className="update-available-banner flex-align-center-row">
            <p>
              New update is available.&nbsp;&nbsp;
              <span
                style={{ marginRight: "0.4rem" }}
                onClick={() => updateAssets()}
              >
                click to update
              </span>
              <AiFillSetting
                className="settingIcon"
                size="1rem"
                color="#4b4495"
              />
            </p>
          </div>
        )
      ):showHeader && (
          <a
            className="contact_snackbar flex-align-center-row"
            href="https://api.whatsapp.com/send?phone=8390088888"
            target="_blank"
          >
            <div className="marquee">
              <div>
                <span className="firstSpan">
                  <span className="firstSpan_innerDiv">
                    Say <b>'Hi'</b> on Whats App: <b>83900 88888</b>
                  </span>
                  <span className="firstSpan_innerDiv2">
                    Connect with our support team{" "}
                    <b className="text_underline">Click here</b>
                  </span>
                </span>

                <span>
                  <span className="secondSpan_innerDiv">
                    Say <b>'Hi'</b> on Whats App: <b>83900 88888</b>
                  </span>
                  <span className="secondSpan_innerDiv2">
                    Connect with our support team{" "}
                    <b className="text_underline">Click here</b>
                  </span>
                </span>
              </div>
            </div>
          </a>)}
      <header className={!userData || !showHeader ? "bg-hidden" : ""} >
        <OnBehalf
          show={onBehalf}
          content={"Add Wholeseller Code"}
          onHide={() => {
            setShowOnBehalf(false);
          }}
        />
        {/* mobile footer navigation tabs */}
        <div className="mobile_footer" style={{ backgroundColor: "#1A4059" }}>
          {/* wholesale footer navigation tabs */}
          <div className="display_flex space_around font_ubuntu font_weight_md font_16">
            {/* home tab */}
            <div
              className="text_center footer_sec ws-footer-tab-container"
              onClick={() => navigate("/wholesale/home")}
            >
              {location.pathname.split("/")[2] === "home" &&
              location.pathname.split("/").length === 3 ? (
                <>
                  <div className="ws-footer-tab-active"></div>
                  <span style={{ color: "#02C3B0" }}>
                    <img
                      src={WHOLESALE_FOOTER.wsHomeEnabled}
                      className="footer_icons"
                      alt="home"
                    />{" "}
                    <br></br>
                    Home
                  </span>
                </>
              ) : (
                <>
                  <span style={{ color: "#97ABB8" }}>
                    <img
                      src={WHOLESALE_FOOTER.wsHomeDisabled}
                      className="footer_icons"
                      alt="home"
                    />{" "}
                    <br></br>
                    Home
                  </span>
                </>
              )}
            </div>

            {/* orders tab */}
            <div
              className="text_center footer_sec ws-footer-tab-container"
              onClick={() => navigate("/wholesale/home/orders")}
            >
              {location.pathname.split("/")[3] === "orders" ? (
                <>
                  <div className="ws-footer-tab-active"></div>
                  <span style={{ color: "#02C3B0" }}>
                    <img
                      src={WHOLESALE_FOOTER.wsOrdersEnabled}
                      className="footer_icons"
                      alt="orders"
                    />
                    <br></br>
                    Orders
                  </span>
                </>
              ) : (
                <>
                  <span style={{ color: "#97ABB8" }}>
                    <img
                      src={WHOLESALE_FOOTER.wsOrdersDisabled}
                      className="footer_icons"
                      alt="home"
                    />{" "}
                    <br></br>
                    Orders
                  </span>
                </>
              )}
            </div>

            {/* cart tab */}
            <div
              className="text_center footer_sec ws-footer-tab-container"
              onClick={() => navigate("/wholesale/home/cart")}
            >
              {location.pathname.split("/")[3] === "cart" ? (
                <>
                  <div className="ws-footer-tab-active"></div>
                  <span style={{ color: "#02C3B0" }}>
                    <img
                      src={WHOLESALE_FOOTER.wsCartEnabled}
                      className="footer_icons"
                      alt="cart"
                    />
                    <br></br>
                    Cart
                  </span>
                  {WSCartData && WSCartData.size > 0 && (
                    <span className="cart_notification ws-cart-notify">{WSCartData.size}</span>
                  )}
                </>
              ) : (
                <>
                  <span style={{ color: "#97ABB8" }}>
                    <img
                      src={WHOLESALE_FOOTER.wsCartDisabled}
                      className="footer_icons"
                      alt="cart"
                    />{" "}
                    <br></br>
                    Cart
                  </span>
                  {WSCartData && WSCartData.size > 0 && (
                    <span className="cart_notification ws-cart-notify">{WSCartData.size}</span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <Container
          fluid
          className={
            onBehalfExist
              ? "header xmas_header_fix font_ubuntu pos_fixed bg_behalf"
              : "header xmas_header_fix font_ubuntu pos_fixed"
          }
          style={{
            backgroundColor: "#1A4059",
          }}
        >
          <Row className="row mb-3 padding_top_12 flex-align-center-row">
            <Col lg={2} className={""}>
              <Row>
                <Col xs={5} md={7} lg={9}>
                  {" "}
                  <Link
                    to={"/wholesale/home"}
                    onClick={() => {
                      document.body.className = "";
                      setShowRecent(false);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                      }}
                    >
                      <img
                        src={WHOLESALE_HEADER.wholesaleLogoPNG}
                        alt="saveo wholesale logo"
                        className="wholesale-header-logo"
                      />
                    </div>{" "}
                  </Link>
                </Col>
                {/* disabling the upload to cart for the wholesale site */}
                {/* upload to cart section - mobile */}

                {/* onBehalf section - mobile */}
                <Col></Col>

                {/* help and support section - mobile */}
                <Col></Col>

                {/* cart section - mobile */}
                <Col></Col>

                {/* profile section - mobile */}
                {
                  <Col className=" menu_icons_min cart_icon_min">
                    <>
                      <div
                        onClick={() => navigate("wholesale/home/menu")}
                        id="profileIcon"
                      >
                        <AiOutlineMenu size="2rem" color="#fff" />
                      </div>
                    </>
                  </Col>
                }

                {/* orders section  */}
              </Row>
            </Col>
            {/* search bar */}
            <Col lg={6} className="WSearchHeader">
              {/* <InputGroup className="mb-3">
                <FormControl
                  className="searchBar border_none font_weight_md "
                  placeholder="Search 50,000+ medicines "
                  value={searchString}
                  onChange={handleSearchString}
                  onFocus={searchMeds}
                  onKeyUp={searchMeds}
                  onKeyDown={handleKeyboardNav}
                  ref={searchRef}
                  spellCheck="false"
                />
                {window.innerWidth > 1000 && (
                  <InputGroup.Text
                    style={{ cursor: "pointer" }}
                    className="search_shortKey_tag"
                    onClick={() => searchRef.current.focus()}
                  >
                    press '&nbsp;
                    <span style={{ fontSize: ".9rem" }}>
                      {showRecent ? " ESC " : "ENTER"}
                    </span>
                    &nbsp;' to &nbsp;
                    {showRecent ? "close" : "search"}
                  </InputGroup.Text>
                )}

                <InputGroup.Text className="border_none bg_grey cursor_pointer search_icon">
                  <BiSearch
                    style={{ fontSize: "20px" }}
                    className="color_grey search_icon"
                  />
                </InputGroup.Text>
              </InputGroup> */}
              <SearchBarComponent headerType={'WHOLESALE'}/>
            </Col>
            {/* header - large screen */}
            {/* empty col for spacing */}
            <Col className=" menu_icons_max"></Col>
            <Col className=" menu_icons_max"></Col>
            <Col className=" menu_icons_max"></Col>
            <Col className=" menu_icons_max"></Col>

            {/* upload to cart section- large screen */}

            {/* onBehalf section - large screen */}
            {/* disbale onbehalf on wholesale */}
            {/* {headerType === "retail" && ( */}
            {/* whoelsale on behalf */}
            {/* <Col className=" menu_icons_max">
              {userData && userData?.enabled && (
                <>
                  {userData?.admin || userData?.salesPerson ? (
                    <span onClick={() => setShowOnBehalf(true)}>
                      {" "}
                      <OverlayTrigger
                        key={"bottom"}
                        placement={"bottom"}
                        overlay={
                          <Tooltip
                            id={`tooltip-bottom`}
                            style={{ marginTop: "-10px" }}
                          >
                            On Behalf
                          </Tooltip>
                        }
                      >
                        <div>
                          {onBehalfExist ? (
                            <FaUserShield className="color_white header_icons cursor_pointer" />
                          ) : (
                            <FaUserShield className="color_white header_icons cursor_pointer" />
                          )}
                        </div>
                      </OverlayTrigger>{" "}
                    </span>
                  ) : (
                    ""
                  )}
                </>
              )}
            </Col> */}

            {/* orders section - wholesale - large screen */}
            {
              <Col className=" menu_icons_max">
                <>
                  <OverlayTrigger
                    key={"bottom"}
                    placement={"bottom"}
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom`}
                        style={{ marginTop: "0px" }}
                      >
                        Orders
                      </Tooltip>
                    }
                  >
                    <div>
                      <img
                        onClick={() => navigate("/wholesale/home/orders")}
                        alt="orders section image"
                        src={
                          location.pathname.split("/")[3] === "orders"
                            ? WHOLESALE_FOOTER.wsOrdersEnabled
                            : WHOLESALE_FOOTER.wsOrdersDisabled
                        }
                        className="color_white header_icons cursor_pointer"
                        style={{ height: "1.3em" }}
                      />
                    </div>
                  </OverlayTrigger>
                </>
              </Col>
            }

            {/* cart section - large screen*/}
            <Col className=" menu_icons_max">
              <>
                <OverlayTrigger
                  key={"bottom"}
                  placement={"bottom"}
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom`}
                      style={{ marginTop: "0px" }}
                    >
                      Cart
                    </Tooltip>
                  }
                >
                  <div
                    className={cartShake && "errorAnime"}
                    id="cartIcon"
                    onClick={async () => {
                      navigate("/wholesale/home/cart");
                    }}
                  >
                    {WSCartData?.size > 0 && (
                      <div
                        className={
                          onBehalfExist
                            ? "cart_notification font_ubuntu cart_notify_behalf"
                            : "cart_notification_whl font_ubuntu"
                        }
                      >
                        {WSCartData.size}
                      </div>
                    )}
                    <IoMdCart
                      color={
                        location.pathname.split("/")[3] === "cart"
                          ? "#48D1CC"
                          : "#97abb8"
                      }
                      className="header_icons cursor_pointer"
                    />{" "}
                  </div>
                </OverlayTrigger>
              </>
            </Col>

            {/* store section - large screen */}

            {/* menu section - wholesale - large screen */}
            <Col className=" menu_icons_max">
              <>
                <OverlayTrigger
                  key={"bottom"}
                  placement={"bottom"}
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom`}
                      style={{ marginTop: "-10px" }}
                    >
                      Menu
                    </Tooltip>
                  }
                >
                  <div>
                    <AiOutlineMenu
                      size="2rem"
                      color={
                        location.pathname.split("/")[3] === "menu"
                          ? "#48D1CC"
                          : "#97abb8"
                      }
                      onClick={() => navigate("/wholesale/home/menu")}
                      className="header_icons cursor_pointer"
                    />
                  </div>
                </OverlayTrigger>
              </>
            </Col>
          </Row>
        </Container>

        <div className="header_backLayer"></div>
      </header>
    </>
  );
}

export default WSHeader;
