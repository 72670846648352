import { Modal, Button } from "react-bootstrap";
import React,{useState,useEffect,Fragment} from "react";
import '../../App.css';
import './dialog.css';
import {BiBlock} from 'react-icons/bi';
import { AiOutlineMinus,AiOutlinePlus } from "react-icons/ai";
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'


const ProductModal = (props) => {
    const [quantity,setQuantity]=useState(0);

    const addQuantity=(e)=>{
        e.stopPropagation()
        setQuantity(quantity+1)
    }
    const minusQuantity=(e)=>{
        e.stopPropagation()
        if(quantity>0){
            setQuantity(quantity-1)
        }
    }
    useEffect(()=>{
     
    },[quantity])
    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
      >
        {props.medData && <Fragment> <Modal.Header closeButton className="bg_primary">
        <div>
        <div className="display_flex space_btw">
           <img src={props.medData.medicineImage.lowResUrl}  className="product_image" />

          
           <div className="header_data">
              <p className="med_name font_ubuntu">{props.medData.name}</p>
              <p className="font_weight_lt manf_name" style={{color:'rgba(255, 255, 255, 0.781)'}}>{props.medData.packaging} •  <span className="color_secondary font_weight_md"> {props.medData.manufacturer} </span>
             </p>
           </div>
          </div>
          <div className="display_flex">
          {props.medData.tags && props.medData.tags.map((tag, indx)=>{
              return (
                <div className="returns color_grey font_weight_md" key={indx+"pmodal"}>
           {tag.iconCode=='iconRestricted' && <BiBlock className="color_secondary"/>}  {tag.message}
          </div> 
              )
          })}
          
          </div>
         
          
        </div>
          
         
        </Modal.Header>
        <Modal.Body className="prd_dialog_body">
        <p className="font_12  font_ubuntu font_weight_md"><span className="color_grey">Delivery :</span>  {props.medData.deliveryDate},{props.medData.deliverySlot}</p>
        <div className="body_inner_div">
        <div style={{margin:'10px',padding:'10px 10px 0px 10px'}}   closeButton>
        <div className="display_flex space_btw header_ptr_modal border_bottom" id="contained-modal-title-vcenter">
            
        <div className="left_top_sec">
                     <p className="color_grey_3 font_ubuntu font_weight_md font_12">MRP</p> 
                     <p className="color_grey_1 font_ubuntu font_weight_lg">₹ {props.medData.mrp}</p>
                 </div>
                 <div  className="border_radius_5 bg_light_green color_primary text_center top_center_sec">
                    <span className="font_weight_md text_center font_12">{Math.round(props.medData.retailerMargin)}%</span> <br/>
                    <span style={{fontSize:'9px'}} className="color_primary">MARGIN</span>
                </div>
                <div className="left_top_sec">
                <p className="color_grey_3 font_ubuntu font_weight_md font_12">NET PRICE</p> 
                     <p className="color_grey_1 font_ubuntu font_weight_lg">  <span className="color_black font_weight_lg">₹ 00</span><span  style={{fontSize:'10px'}}>.00</span>
</p>
                </div>
                
          
        </div>
      </div>
      <div style={{padding:'0px'}} >
      <div style={{padding:'0px 20px'}} className="display_flex space_btw">
           <div className="font_14">PTR</div>
           <p className="color_grey_1 font_ubuntu font_weight_lg">  <span className="color_black font_weight_lg">+ ₹ {props.medData.effectivePrice.toFixed(2).toString().split('.')[0]}</span><span style={{fontSize:'10px'}}>.{props.medData.effectivePrice.toFixed(2).toString().split('.')[1]}</span></p>

         </div>
         <div className="dialog_cd_sec display_flex space_btw">
           <div className="font_ubuntu font_weight_lg">Cash Discount ({props.medData.cashDiscountInPercentage}%)</div>
           <div className=" font_ubuntu font_weight_lg">  <span className="font_weight_lg">- ₹ {props.medData.cashDiscount.toFixed(2).toString().split('.')[0]}</span><span style={{fontSize:'10px'}}>.{props.medData.cashDiscount.toFixed(2).toString().split('.')[1]}</span></div>

         </div>
         <div className=" display_flex space_btw" style={{padding:'10px 20px'}}>
           <div className="font_ubuntu font_weight_lg font_12">GST ({props.medData.gstPercentage}%)</div>
           <div className=" font_ubuntu font_weight_lg font_12">  <span className="font_weight_lg">+ ₹ {props.medData.gst.toFixed(2).toString().split('.')[0]}</span><span style={{fontSize:'10px'}}>.{props.medData.gst.toFixed(2).toString().split('.')[1]}</span></div>

         </div>
      </div>
      <div className="width_100 display_flex space_btw" style={{padding:'10px 20px'}}>
       
            <div className="font_ubuntu font_weight_lg font_14">Net Price</div>
            <div className=" font_ubuntu font_weight_lg font_14">  <span className="font_weight_lg">+ ₹ 00</span><span style={{fontSize:'10px'}}>.00</span></div>
       
      </div>
      </div>
        </Modal.Body>
        <Modal.Footer style={{padding:'0px'}}>
          <div className="footer_sec_1 display_flex space_btw font_ubuntu">
            <div>
                <span className="color_black font_weight_md"> {props.medData.availableQuantity} </span> <span className="color_grey font_weight_md font_12">&nbsp; &nbsp;IN STOCK </span>
            </div>
            <div>
                <span className="color_grey font_weight_md font_12"> Expiry </span> <span className="color_green font_weight_md font_12">&nbsp; {props.medData.expiryDate} </span>
            </div>
          </div>
          <div className="width_100 footer_sec_2 display_flex space_btw font_ubuntu">
             <div className="color_grey font_14 font_weight_md txt_center">
               <span>0</span> <br/>
               <span>Free Qty</span>
             </div>
             <div className="display_flex space_btw padding_10">
             <Fragment>
                 <div className='minusQty color_primary' onClick={minusQuantity}><AiOutlineMinus/></div>
                 <div className='qty font_ubuntu'>{quantity}</div>
                 <div className='addQty bg_primary color_white' onClick={addQuantity}><AiOutlinePlus/></div>
                 </Fragment>
             </div>
             <div className="color_grey font_14 font_weight_md txt_center">
               <span className="color_black">{quantity}</span> <br/>
               <span className="font_12">Total Qty</span>
             </div>
          </div>

        </Modal.Footer>
        <Modal.Footer style={{padding:'0px',margin:'0px'}}>
            <div className="add_to_cart_footer bg_primary color_white">
                  <span> ADD TO CART </span>  
                  <br/>
                  <span>₹ 2000</span>
            </div>
        </Modal.Footer></Fragment>}
      </Modal>
    )
}

export default React.memo(ProductModal)
