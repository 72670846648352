import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router";
import { refreshToken } from "../../../Actions/Auth";
import {
  getCartDetails,
  placeWSOrder,
  viewShipmentDetails,
} from "../../../Actions/WholeSale/WSCart";
import { searchContext, usersData, WSCartDataContext } from "../../../App";
import WSShipmentCard from "../../Cart/WSShipmentCard";
import ErrorModal from "../../Modals/ErrorModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import ButtonLoading1 from "../../Spinners/ButtonLoading1";
import WSOrderPlacedModal from "../Modals/WSOrderPlacedModal";
import WSCartEmpty from "../../../Images/json/WSCartEmpty.json";
import Lottie from "react-lottie-player";

function PlaceOrder() {
  const [shimmer, setShimmer] = useState(false);
  const { userData, setShowMaintenance } = useContext(usersData);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [shipmentData, setShipmentData] = useState({});
  const { WSCartData: cartData, setWSCartData: setCartData } =
    useContext(WSCartDataContext);
  const navigate = useNavigate();
  const [cartDataShimmer, setCartDataShimmer] = useState(false);
  const [WSPlaceOrderData, setWSPlaceOrderData] = useState({
    show: false,
    data: {
      orderStatus: "",
      orderAmount: cartData?.total,
    },
  });
  const [placeOrderSpinner, setPlaceOrderSpinner] = useState(false);
  const {showRecent, setShowRecent} = useContext(searchContext);


  const getShipmentDetails = async () => {
    //update the shimmer state
    setShimmer(true);
    try {
      const res = await viewShipmentDetails({});
      //update the shipment data state
      setShipmentData(res?.data?.data);
      //update the shimmer state
      setShimmer(false);
    } catch (error) {
      //update the shimmer state if any error
      setShimmer(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getShipmentDetails();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getShipmentDetails();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getShipmentDetails();
          },
        });
      }
    }
  };

  //get the view cart api
  const fetchCartDetails = async () => {
    //update the shimmer state
    setCartDataShimmer(true);
    try {
      const res = await getCartDetails();
      //update the cart details state
      setCartData(res?.data?.data);
      //update the shimmer state
      setCartDataShimmer(false);
    } catch (error) {
      //update the shimmer state if any error
      setCartDataShimmer(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          fetchCartDetails();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            fetchCartDetails();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            fetchCartDetails();
          },
        });
      }
    }
  };

  //place order api
  const placeOrder = async () => {
    //update the spinner state
    setPlaceOrderSpinner(true);
    try {
      const res = await placeWSOrder({});
      //update the spinner state
      setPlaceOrderSpinner(false);
      //triggers the modal on success
      setWSPlaceOrderData({
        show: true,
        data: {
          orderStatus: res?.data?.data,
          orderAmount: cartData?.total,
        },
      });
      //refresh the cart Data and shipmentData
      fetchCartDetails();
      getShipmentDetails();
    } catch (error) {
      //update the spinner state if any errpr
      setPlaceOrderSpinner(false);

      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          placeOrder();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            placeOrder();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            placeOrder();
          },
        });
      }
    }
  };

  //onMount - get the shipment details
  useEffect(() => {
    getShipmentDetails();
  }, []);

  //if cartData is unavailable hit the view cart api
  useEffect(() => {
    if (Object.keys(cartData).length === 0) {
      //hit the view cart api
      fetchCartDetails();
    }
  }, [cartData]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      <WSOrderPlacedModal
        show={WSPlaceOrderData.show}
        data={WSPlaceOrderData.data}
        onHide={() => {
          setWSPlaceOrderData({ show: false, data: {} });
        }}
      />

      <div className="ws-cart-main-container">
        <div className="ws-products-header ws-products-header-cart ws-cart-header">
          <BsArrowLeft
            size="1.5rem"
            className="ws-nav-back"
            onClick={() => navigate(-1)}
          />
          <p>Your Order</p>
        </div>

        <div className="ws-cart-main-content-wrapper">
          {shimmer && (
            <div className="ws-cart-items-list">
              {[...Array(3)].map((_, index) => {
                return (
                  <div
                    className="ws-cart-card-main-container"
                    style={{ height: "8rem" }}
                  >
                    <p
                      className="animateShimmer"
                      style={{
                        height: ".5rem",
                        width: "80%",
                        marginTop: ".5rem",
                      }}
                    ></p>
                    <p
                      className="animateShimmer"
                      style={{
                        height: ".5rem",
                        width: "40%",
                        marginTop: ".5rem",
                      }}
                    ></p>
                    <p
                      className="animateShimmer"
                      style={{
                        height: ".5rem",
                        width: "30%",
                        marginTop: ".5rem",
                      }}
                    ></p>
                    <p
                      className="animateShimmer"
                      style={{
                        height: ".5rem",
                        width: "30%",
                        marginTop: ".5rem",
                      }}
                    ></p>
                    <p
                      className="animateShimmer"
                      style={{
                        height: "2rem",
                        width: "20%",
                        marginTop: "0rem",
                        marginLeft: "auto",
                        borderRadius: ".25rem",
                      }}
                    ></p>
                  </div>
                );
              })}
            </div>
          )}

          {!shimmer && shipmentData?.cartShipmentResponseList && (
            <div className="ws-cart-items-list">
              {shipmentData?.cartShipmentResponseList?.map((item, index) => {
                return <WSShipmentCard data={item} key={index} />;
              })}
            </div>
          )}

          {cartDataShimmer && (
            <div className="ws-cart-price-details-container">
              <div className="ws-product-price-details-header">
                <p
                  className="animateShimmer"
                  style={{
                    height: "1rem",
                    width: "50%",
                    marginTop: ".5rem",
                    borderRadius: ".2rem",
                  }}
                ></p>
                <p
                  className="animateShimmer"
                  style={{
                    height: ".5rem",
                    width: "30%",
                    marginTop: ".5rem",
                    borderRadius: ".2rem",
                  }}
                ></p>
                <p
                  className="animateShimmer"
                  style={{
                    height: "15rem",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: ".2rem",
                  }}
                ></p>
                <p
                  className="animateShimmer"
                  style={{
                    height: "3rem",
                    width: "100%",
                    marginTop: "1rem",
                    borderRadius: ".2rem",
                  }}
                ></p>
              </div>
            </div>
          )}

          {!cartDataShimmer && cartData?.amountBreakUpObjectList && (
            <div className="ws-cart-price-details-container">
              <div className="ws-product-price-details-header">
                <p>{shipmentData?.numberOfShipments} shipments</p>
                <p>Price Detail</p>
              </div>

              <div className="ws-product-price-details-content">
                {cartData?.amountBreakUpObjectList?.map((item, index) => {
                  return (
                    <>
                      <div className="ws-product-price-detail-item" key={index}>
                        <p>{item.key}</p>
                        <p>
                          ₹{" "}
                          {item.value.toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                      </div>
                      {item.subItems &&
                        item.subItems.map((subItem, index) => {
                          return (
                            <div
                              className={
                                subItem.highlight
                                  ? "ws-product-price-detail-subitem-highlight"
                                  : "ws-product-price-detail-subitem"
                              }
                              style={{
                                borderBottom:
                                  index === 0 && "5px solid #ffffff",
                              }}
                            >
                              <p>{subItem.key}</p>
                              <p>
                                {subItem.value.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </div>
                          );
                        })}
                    </>
                  );
                })}
              </div>
              <div className="ws-product-price-details-total-container">
                <p>TOTAL</p>
                <p>
                  ₹{" "}
                  {cartData?.total?.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
              <div className="ws-product-price-details-disclaimer-container">
                <p>
                  NOTE:{" "}
                  <span>
                    Bill Amount may differ from what is shown above (in app) due
                    to different Batch and Schemes
                  </span>
                </p>
              </div>
              <div
                style={{
                  backgroundColor: "#ffffff",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  className="ws-product-price-details-confirm-button"
                  onClick={placeOrder}
                >
                  {placeOrderSpinner ? (
                    <ButtonLoading1 color="linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%)" />
                  ) : (
                    "PLACE ORDER - CASH ON DELIVERY"
                  )}
                </button>
              </div>
            </div>
          )}

          {!cartDataShimmer &&
            !shimmer &&
            shipmentData?.cartShipmentResponseList === null &&
            cartData?.cartMedicineDetailResponseList === null && (
              <div
                className="flex-align-center-column"
                style={{
                  height: showRecent ? 'calc(100vh - 4rem)': "calc(100vh - 8rem)",
                  backgroundColor: "#EAECEE",
                  width: "100%",
                }}
              >
                <Lottie
                  loop={false}
                  animationData={WSCartEmpty}
                  style={{ width: "60%", height: "60%" }}
                  play
                />
                <p className="empty-cart-message">Cart is Empty</p>
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default PlaceOrder;
