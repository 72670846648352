import { Modal, Button } from "react-bootstrap";
import React, { Fragment, useEffect, useContext, useState } from "react";
import "../../App.css";
import "./dialog.css";
import { usersData } from "../../App";
import ErrorModal from "./ErrorModal";
import { refreshToken } from "../../Actions/Auth";
import { getData } from "../../Actions/Product";

const ProductPtrDialog = (props) => {
  const [priceData, setPriceData] = useState();

  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });

  const getProductPrice = () => {
    setPriceData();
    const params = {
      medicineId: props?.medData?.medicineId,
      quantity:1,
    };

    getData("PRODUCTPRICE_BY_ID", params)
      .then((res) => {
        setPriceData(res.data.data);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          refreshToken().then((res) => {
            getProductPrice();
          });
        } else if (
          error.response.status == 502 ||
          error.response.status == 503
        ) {
          setShowMaintenance(true);
        } else if (error.response.status == 500) {
          setErrorModal({
            show: true,
            content: "Oops something went wrong !",
          });
        } else {
          setErrorModal({
            show: true,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
          });
        }
      });
  };

  useEffect(() => {
   props.show && getProductPrice()
  },[props.show]);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
      />
      {props.medData && (
        <Fragment>
          <Modal.Header
            style={{ margin: "10px", padding: "10px 10px 0px 10px" }}
            closeButton
          >
            <Modal.Title
              className="display_flex space_btw header_ptr_modal"
              id="contained-modal-title-vcenter"
            >
              <div className="left_top_sec">
                <p className="color_grey_3 font_ubuntu font_weight_md font_12">
                  MRP
                </p>
                <p className="color_grey_1 font_ubuntu font_weight_lg">
                  ₹ {props.medData.mrp}
                </p>
              </div>
              <div className="border_radius_5 bg_light_green color_primary text_center top_center_sec">
                <span className="font_weight_md text_center font_12">
                  {Math.round(props.medData.retailerMargin)}%
                </span>{" "}
                <br />
                <span style={{ fontSize: "9px" }} className="color_primary">
                  MARGIN
                </span>
              </div>
              <div className="left_top_sec">
                <p className="color_grey_3 font_ubuntu font_weight_md font_12">
                  NET PRICE
                </p>
                <p className="color_grey_1 font_ubuntu font_weight_lg">
                  {" "}
                  {
                    priceData? <span className="color_black font_weight_lg">₹ {priceData?.netPrice.toFixed(2)}</span>
                   :<span className="animateShimmer margin_10" style={{width:'60px',height:'20px'}}>&nbsp;&nbsp;&nbsp;</span>
                  }

                </p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "0px" }}>
            <div
              style={{ padding: "0px 20px" }}
              className="display_flex space_btw"
            >
              <div className="font_14">PTR</div>
              <p className="color_grey_1 font_ubuntu font_weight_lg">
                {" "}
                <span className="color_black font_weight_lg">
                  + ₹{" "}
                  {props.medData.ptr &&
                    props.medData.ptr
                      .toFixed(2)
                      .toString()
                      .split(".")[0]}
                </span>
                <span style={{ fontSize: "10px" }}>
                  .
                  {props.medData.ptr &&
                    props.medData.ptr
                      .toFixed(2)
                      .toString()
                      .split(".")[1]}
                </span>
              </p>
            </div>
            <div className="dialog_cd_sec display_flex space_btw">
              <div className="font_ubuntu font_weight_lg">
                Cash Discount ({props.medData.cashDiscountInPercentage}%)
              </div>
              <div className=" font_ubuntu font_weight_lg">
                {" "}
                <span className="font_weight_lg">
                  - ₹{" "}
                  {props.medData.cashDiscount &&
                    props.medData.cashDiscount
                      .toFixed(2)
                      .toString()
                      .split(".")[0]}
                </span>
                <span style={{ fontSize: "10px" }}>
                  .
                  {props.medData.cashDiscount &&
                    props.medData.cashDiscount
                      .toFixed(2)
                      .toString()
                      .split(".")[1]}
                </span>
              </div>
            </div>
            <div
              className=" display_flex space_btw"
              style={{ padding: "10px 20px" }}
            >
              <div className="font_ubuntu font_weight_lg font_12">
                GST ({props.medData.gstPercentage}%)
              </div>
              <div className=" font_ubuntu font_weight_lg font_12">
                {" "}
                <span className="font_weight_lg">
                  + ₹{" "}
                  {props.medData.gst &&
                    props.medData.gst.toFixed(2).toString().split(".")[0]}
                </span>
                <span style={{ fontSize: "10px" }}>
                  .
                  {props.medData.gst &&
                    props.medData.gst.toFixed(2).toString().split(".")[1]}
                </span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer
            className="width_100 display_flex space_btw"
            style={{ padding: "10px 20px" }}
          >
            <div className="font_ubuntu font_weight_lg font_14">Net Price</div>
            <div className=" font_ubuntu font_weight_lg font_14">
              {" "}
              <span className="font_weight_lg">+ ₹ {priceData?.netPrice.toFixed(2)}</span>
            </div>
          </Modal.Footer>
        </Fragment>
      )}
    </Modal>
  );
};

export default React.memo(ProductPtrDialog);
