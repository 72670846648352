import { Fragment, useContext, useEffect, useState } from "react";
import { Carousel, Container, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import ProductCard2 from "../ProductCard/ProductCard2";
import { FiArrowLeft, FiInfo } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import "./Silvercross.css";
import Slider3 from "../Sliders/Slider3";
import { common_product_widget } from "../../Actions/Widgets";
import InfiniteScroll from "react-infinite-scroll-component";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import SilvercrossLogo from "../../Images/svg/SilverCross_Main Primary_Tagline Logo.svg";
import RewardsFlyer from "../../Images/jpg/F_Box-pe-bangkok(Retailer-flyer).jpg";
import conditionalClass from "classnames";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { banner_widget } from "../../Actions/Widgets";

const Silvercross = () => {
  let navigate = useNavigate();
  let userData = [];
  const { setErrorHandler } = useContext(usersData);
  const [allProductList, setAllProductList] = useState();
  const [productListTrend, setProductListTrend] = useState();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [dataLength, setDataLength] = useState(0);
  const [errorModal, setErrorModal] = useState(false);
  const [topSellShimmer, setTopSellShimmer] = useState(false);
  const [allShimmer, setAllShimer] = useState(false);
  const [campaignData, setCampaignData] = useState();
  const [campIndex, setCampIndex] = useState(null);
  const [campShimmer, setCampShimmer] = useState(false);
  const [showRewards, setShowRewards] = useState(false);
  const [showTarget, setShowTarget] = useState();
  const [bannerImages, setBannerImages] = useState(null);
  let targetInterval;

  const getAllProducts = async (params) => {
    page === 0 && setAllShimer(true);

    try {
      let res = await common_product_widget("home/v2/getMedicineBymanufacturer", params);

      setAllShimer(false);

      if (params.filterCondition === "popular") {
        let obj = productListTrend && productListTrend.medicinesResponsesList ? productListTrend : { medicinesResponsesList: [] };

        if (res.data.data.medicinesResponsesList.length > 0) {
          obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
        }

        setProductListTrend(obj);
        setTopSellShimmer(false);
      } else {
        let obj = allProductList && allProductList.medicinesResponsesList ? allProductList : { medicinesResponsesList: [] };

        if (res.data.data.medicinesResponsesList.length > 0) {
          obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
          setDataLength(obj.medicinesResponsesList.length);
        }

        setAllProductList(obj);

        if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0 && page !== 0) {
          fetchMoreData();
        }

        if (page === 0 && res.data.data.size > 0 && res.data.data.medicinesResponsesList.length < 6) {
          fetchMoreData();
        }

        if (res.data.data.size === 0) {
          setHasMore(false);
        }
      }
    } catch (error) {
      setAllShimer(false);

      setErrorHandler({
        show: true,
        screen: "silvercross_screen",

        errorList: [{ error: error, code: "" }],
        fn: function () {
          getAllProducts(params);
        },
      });
    }
  };

  const fetchMoreData = () => {
    setPage(+page + 1);
  };

  //   Silvercross live gamification data
  const getSilverCrossCampaigns = async () => {
    setCampShimmer(true);
    const params = {
      campaignState: "LIVE",
      retailerCode: userData?.retailerCode,
    };

    try {
      let res = await common_product_widget("home/showCampaignForSilverCross", params);

      let arrTemp = res.data.data.filter((ele) => ele.silverCrossTargetProgressesList.length > 0);
      let scArr = [];

      for (let i = 0; i < arrTemp.length; i++) {
        let tempArr = arrTemp[i];
        for (let j = 0; j < arrTemp[i].silverCrossTargetProgressesList.length; j++) {
          if (!arrTemp[i].silverCrossTargetProgressesList[j].showTargetAsWonInMilestone && arrTemp[i].silverCrossTargetProgressesList[j].subTargetFulfilled) {
            tempArr.silverCrossTargetProgressesList.splice(j, 1);
          } else {
            continue;
          }
        }
        scArr.push(tempArr);
      }

      setCampaignData(scArr);
      setCampIndex(0);
      setShowTarget(scArr[0].silverCrossTargetProgressesList[0]);

      setCampShimmer(false);
    } catch (error) {
      setCampShimmer(false);

      setErrorHandler({
        show: true,
        screen: "silvercross_screen",

        errorList: [{ error: error, code: "" }],
        fn: function () {
          getSilverCrossCampaigns();
        },
      });
    }
  };

  const campaignNav = (type) => {
    clearInterval(targetInterval);
    if (type === "next" && campIndex + 1 <= campaignData.length - 1) {
      setShowTarget(campaignData[campIndex + 1]?.silverCrossTargetProgressesList[0]);
      setCampIndex(campIndex + 1);
    } else if (type === "prev" && campaignData.length > 0 && campIndex > 0) {
      setShowTarget(campaignData[campIndex - 1]?.silverCrossTargetProgressesList[0]);
      setCampIndex(campIndex - 1);
    }
  };

  // Get Banner Images
  const getBannerImages = async () => {
    try {
      const response = await banner_widget("home/getBanners?pageId=silvercross");
      setBannerImages(response.data.data);
    } catch (error) {
      setErrorHandler({
        show: true,
        screen: "silvercross_screen",

        errorList: [{ error: error, code: "" }],
        fn: function () {
          getSilverCrossCampaigns();
        },
      });
    }
  };

  const homeBannerSlide = (event) => {
    localStorage.setItem("currentScreenBannerData", JSON.stringify({ index: +event + 1, data: bannerImages[event] }));
  };

  useEffect(() => {
    userData = JSON.parse(localStorage.getItem("userData"));

    localStorage.setItem("currentScreen", "silvercross_screen");
    localStorage.removeItem("currentScreenBannerData");

    if (userData) {
      getSilverCrossCampaigns();
      getBannerImages();
    } else {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    getAllProducts({
      manufacturer: "SILVERCROSS MEDISCIENCES",
      filterCondition: "all",
      page: page,
    });

    setTopSellShimmer(true);
    getAllProducts({
      manufacturer: "SILVERCROSS MEDISCIENCES",
      filterCondition: "popular",
      page: page,
    });
  }, [page]);

  useEffect(() => {
    if (showRewards) {
      document.body.className = "overflow-hidden";
    } else {
      document.body.className = "";
    }
  }, [showRewards]);

  useEffect(() => {
    let ind = 0;

    if (campaignData && campaignData[campIndex]?.silverCrossTargetProgressesList.length > 1) {
      targetInterval = setInterval(() => {
        if (+ind === campaignData[campIndex]?.silverCrossTargetProgressesList.length - 1) {
          ind = 0;
          setShowTarget(campaignData[campIndex]?.silverCrossTargetProgressesList[0]);
        } else {
          ind += 1;
          setShowTarget(campaignData[campIndex]?.silverCrossTargetProgressesList[+ind]);
        }
      }, 5000);
    }

    return () => {
      clearInterval(targetInterval);
    };
  }, [showTarget]);

  return (
    <Fragment>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Container fluid className="sc_parent_cntr">
        <Container fluid className="sc_cntr">
          {/* Shapes on top */}
          <div className="sc_bg_ele">
            <div className="sc_bg_ele_left">
              <div className="triangleShape triangle1"></div>
              <div className="triangleShape triangle2"></div>
              <div className="squareShape"></div>
            </div>

            <div className="sc_bg_ele_right">
              <div className="triangleShape triangle1"></div>
              <div className="triangleShape triangle2"></div>
              <div className="squareShape"></div>
            </div>
          </div>
          <Container fluid className="sc_header font_ubuntu display_flex">
            <FiArrowLeft className="cursor_pointer sc_goBack" onClick={() => navigate("/home")} />

            <img src={SilvercrossLogo} alt="Silvercross Logo" />

            <div></div>
          </Container>

          <div className="sc_banner_cntr">
            {campShimmer && <div className="skeletal_div animateShimmer sc_camp_cntr" style={{ minWidth: "450px" }}></div>}

            {!campShimmer && campaignData && campaignData?.length > 0 && (
              <div className="sc_camp_cntr">
                <div className="sc_camp_left">
                  <div className="sc_logo">
                    <img src={SilvercrossLogo} alt="Silvercross Logo" />
                    <p>Box pe Bangkok</p>
                  </div>
                </div>

                <div className="sc_camp_right">
                  <ul className="sc_campMilestone">
                    {campaignData && (
                      <li>
                        <div className="sc_campProgressCont">
                          <div className="sc_campProgressBar">
                            <div
                              className="sc_campProgress"
                              style={{
                                width: `${(showTarget.progressValue / showTarget.target) * 100}%`,
                              }}></div>
                          </div>

                          <div
                            className="sc_campProgressBox"
                            style={{
                              left: !showTarget.subTargetFulfilled ? `${(showTarget.progressValue / showTarget.target) * 100}%` : "100%",
                              transform: !showTarget.subTargetFulfilled ? `translate(-${(showTarget.progressValue / showTarget.target) * 100}%, -50%)` : "translate(-100%, -50%)",
                            }}>
                            <p className="sc_campProgressHeader">{showTarget.subTargetFulfilled ? "You've" : "Ordered"}</p>
                            <p className="sc_campProgressValue">
                              {showTarget.subTargetFulfilled ? "Won" : showTarget.targetType.toUpperCase() === "GMV" ? "₹ " + showTarget.progressValue : showTarget.progressValue}
                            </p>
                          </div>
                        </div>

                        <div className="sc_campDescCont">
                          <OverlayTrigger key={"bottom"} placement={"bottom"} overlay={<Tooltip id={`sc_tooltip`}>{showTarget.targetGiftDescription}</Tooltip>}>
                            <p className="sc_campDesc">{showTarget.targetGiftDescription}</p>
                          </OverlayTrigger>
                        </div>
                      </li>
                    )}
                  </ul>

                  <div className="sc_camp_bottom">
                    <button className="order-btn" onClick={(e) => e.stopPropagation()}>
                      Order Now
                    </button>

                    <div className="sc_navigation">
                      <FaChevronLeft onClick={() => campaignNav("prev")} />
                      <p>
                        {campIndex + 1} of {campaignData.length}
                      </p>
                      <FaChevronRight onClick={() => campaignNav("next")} />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!campShimmer && (
              <div className="sc_banner" onClick={() => setShowRewards(!showRewards)}>
                <img src="https://s3.ap-south-1.amazonaws.com/images.saveo.in/SC+Launch+Banner+03.gif" alt="box-bangkok-banner" />
              </div>
            )}
          </div>

          <div className="sc_rewards">
            <button onClick={() => setShowRewards(!showRewards)}>View Rewards</button>
          </div>

          {/* Carousel Banners */}
          {bannerImages && (
            <div className="sc_banner_carousel">
              <div className="sc_carousel">
                <Carousel onSlide={homeBannerSlide}>
                  {bannerImages.map((ele, ind) => (
                    <Carousel.Item key={ind}>
                      <img className="banner_img" src={ele.url} alt={ele.fileName} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>
          )}

          <Container>
            <>
              {topSellShimmer ? (
                <Container>
                  <br></br>
                  <p className="font_18 font_weight_lg font_ubuntu">Top Selling Products</p>
                  <Slider3 screen={"silvercross_screen"} shimmer={topSellShimmer} footerColor={"#F4F6F8"} id={"trendingProducts"} darkColor={"#4B4495"} lightColor={"#a49cf598"} />
                </Container>
              ) : (
                <>
                  {productListTrend && productListTrend?.medicinesResponsesList.length > 0 && (
                    <Container>
                      <p className="font_18 font_weight_lg font_ubuntu">Top Selling Products</p>
                      <Slider3
                        screen={"silvercross_screen"}
                        ProductRes={productListTrend}
                        widgetData={{ data: { pageId: "silvercross" } }}
                        shimmer={topSellShimmer}
                        footerColor={"#F4F6F8"}
                        id={"trendingProducts"}
                        darkColor={"#4B4495"}
                        lightColor={"#a49cf598"}
                      />
                    </Container>
                  )}
                </>
              )}

              {allShimmer && (
                <Fragment>
                  <Container>
                    <p className="font_18 font_weight_lg font_ubuntu">All Products</p>
                  </Container>

                  <div id="scrollableDiv" className="productList">
                    <ProductCard2 shimmer={allShimmer} /> <ProductCard2 shimmer={allShimmer} /> <ProductCard2 shimmer={allShimmer} /> <ProductCard2 shimmer={allShimmer} />
                  </div>
                </Fragment>
              )}

              {allProductList && allProductList?.medicinesResponsesList.length > 0 && (
                <Fragment>
                  <Container>
                    <p className="font_18 font_weight_lg font_ubuntu">All Products</p>
                  </Container>

                  <div id="scrollableDiv" className="sc_productList" style={{ paddingTop: "0rem" }}>
                    <InfiniteScroll
                      dataLength={dataLength}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      scrollableTarget="scrollableDiv"
                      scrollThreshold="90%"
                      className="infiniteScroll"
                      loader={<Spinner animation="border" className="color_secondary spinner" size="lg" />}>
                      {allProductList && allProductList.medicinesResponsesList.map((med, index) => <ProductCard2 position={index} screen={"silvercross_screen"} key={index} medData={med} />)}
                    </InfiniteScroll>
                  </div>
                </Fragment>
              )}
            </>

            {!topSellShimmer && !allShimmer && allProductList?.medicinesResponsesList?.length === 0 && (
              <Container className="no_data">
                <FiInfo fill="#592c82" stroke="#f4d8f8" />
                <p>No Silvercross Medicines are available at this moment</p>
              </Container>
            )}
          </Container>
        </Container>

        {showRewards && (
          <div className="sc_rewards_img" onClick={(e) => setShowRewards(!showRewards)}>
            <img src={RewardsFlyer} alt="Rewards Flyer" />
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default Silvercross;
