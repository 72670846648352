import React,{useState} from "react";
import { Col, Row } from "react-bootstrap";
import { BsFillPlayFill } from "react-icons/bs";
import { MdReplay } from "react-icons/md";
import {TiMediaPause} from 'react-icons/ti'
import ReactPlayer from "react-player/lazy";
import './OnboardingPlayer.css';

function OnboardingPlayer({url, preview}) {
  const PLAYERSTATE = {
    url: null,
    pip: false,
    playing: false,
    controls: true,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false
  }

  const [playerState, setPlayerState] = useState(PLAYERSTATE)

  const handlePlayPause = () => {
    setPlayerState({...playerState , playing: !playerState.playing})
  }

  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={url}
        playing ={playerState.playing}
        light={preview}
        // width="212px"
        // height="350px"
        // width='18.75rem'
        // height='31.25rem'
        onClick ={handlePlayPause}
        controls = {playerState.controls}
      />
      {/* <div>
        <Row>
          <Col>{!playerState.playing ? <BsFillPlayFill color="white" size='2rem' onClick = {handlePlayPause} /> : <TiMediaPause  color="white" size='2rem' onClick = {handlePlayPause} />}</Col>
          <Col>{<MdReplay color="white" size='2rem' />}</Col>
        </Row>
      </div> */}
    </div>
  );
}

export default OnboardingPlayer;
