import React, { useEffect, useState } from "react";
import "./CampaignGift.css";
import CampaignCompleted from "../../Images/svg/Group 22089_white.svg";
import InfoCampaign from "../../Images/svg/Group 22088.svg";
import { FiImage } from "react-icons/fi";
import conditionalClass from "classnames";

export const CampaignGift = ({ campaignData, currentNav }) => {
  const [remainingDays, setRemainingDays] = useState(0);
  const [remainingTime, setRemainingTime] = useState();

  useEffect(() => {
    let endDate = new Date(campaignData.campaignEndTime);
    let currDate = new Date();

    let timeLeft = Math.abs(endDate - currDate) / 1000;
    let daysLeft = Math.floor(timeLeft / 86400);

    if (currentNav === "LIVE" && daysLeft < 1) {
      let hrsLeft = Math.floor(timeLeft / 3600) % 24;
      let minLeft = Math.floor(timeLeft / 60) % 60;

      setRemainingTime(hrsLeft + "hrs " + minLeft + "mins");
    }

    setRemainingDays(daysLeft);
  }, []);
  return (
    <div className="campCont">
      <p className={conditionalClass("campStatus", { campLive: currentNav === "LIVE" }, { campCompleted: currentNav === "COMPLETED" }, { campExpired: currentNav === "EXPIRED" })}>
        {currentNav === "LIVE" ? (
          `Ending in ${+remainingDays < 1 ? remainingTime : remainingDays} ${remainingDays < 1 ? "" : remainingDays === "1" ? "Day" : "Days"}`
        ) : currentNav === "EXPIRED" ? (
          <>
            <span>
              <img src={InfoCampaign} alt="Expired Campaign" />
            </span>{" "}
            {currentNav}
          </>
        ) : (
          currentNav
        )}
      </p>

      <div className="campBanner">
        {campaignData?.campaignImageUrl ? (
          <img
            className={conditionalClass({
              disableBanner: currentNav === "EXPIRED",
            })}
            src={campaignData?.campaignImageUrl}
            alt="Campaign_Banner"
          />
        ) : (
          <div className="campBanner_default">
            <FiImage />
            <p>{campaignData?.campaignName}</p>
          </div>
        )}
      </div>

      <ul className="campMilestone">
        {campaignData?.targetProgressesList.map((target, indx) => {
          if (
            (currentNav === "LIVE" &&
              campaignData?.targetCompletionType === "MILESTONE" &&
              ((!target.showTargetAsWonInMilestone && !target.subTargetFulfilled) ||
                (target.showTargetAsWonInMilestone && !target.subTargetFulfilled) ||
                (target.showTargetAsWonInMilestone && target.subTargetFulfilled))) ||
            campaignData?.targetCompletionType !== "MILESTONE" ||
            currentNav !== "LIVE"
          ) {
            return (
              <li className={conditionalClass({ completedMilestone: target.progressValue >= target.target })} key={indx + "_campMilestone"}>
                <div className="campDescCont">
                  {target.progressValue >= target.target ? (
                    <span className="campCheck">
                      <img src={CampaignCompleted} alt="Campaign Completed" />
                    </span>
                  ) : (
                    <span className="campBullet">●</span>
                  )}

                  <p className="campDesc">{target.targetGiftDescription}</p>
                </div>

                <div className="campProgressCont">
                  <div className="campProgressBar">
                    <div
                      className="campProgress"
                      style={{
                        width: `${(target.progressValue / target.target) * 100}%`,
                      }}></div>
                  </div>

                  <div
                    className="campProgressBox"
                    style={{
                      left: target.progressValue <= target.target ? `${(target.progressValue / target.target) * 100}%` : "100%",
                      transform: target.progressValue <= target.target ? `translate(-${(target.progressValue / target.target) * 100}%, -50%)` : "translate(-100%, -50%)",
                    }}>
                    <p className="campProgressHeader">{target.progressValue >= target.target ? "You've" : "Ordered"}</p>
                    <p className="campProgressValue">
                      {target.progressValue >= target.target
                        ? "Won"
                        : target?.targetType.toUpperCase() === "GMV"
                        ? "₹ " + target.progressValue
                        : target.targetGiftDescription.toLowerCase().includes("box")
                        ? target.progressValue + " Boxes"
                        : target.progressValue + " Strips"}
                    </p>
                  </div>
                </div>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};
