import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "../../App.css";
import "../HomePage/HomePage.css";
import "../../animate.css";
import Slider from "../Sliders/Slider";
import { common_sale } from "../../Actions/Widgets";

const TrendingProducts = ({ widgetData,renderWidget,widgetIndex }) => {
  const [shimmer, setShimmer] = useState(false);
  const [productsData, setProductsData] = useState(null);

  const getData = async () => {
    //update the shimmer state
    setShimmer(true);
    try {
      const res = await common_sale(widgetData?.data?.apiEndPoint);
      //update the products state
      setProductsData(res?.data?.data);
      //update the shimmer state
      renderWidget(widgetData,widgetIndex,false,null)
      setShimmer(false);
    } catch (error) {
      //update the shimmer state  if any error
      setShimmer(false);
      renderWidget(widgetData,widgetIndex,true,error)
      // renderErrorWidget(widgetIndex)
      // console.log(error);
      // if (
      //   error?.response?.status == 502 ||
      //   error?.response?.status == 503 ||
      //   error?.response?.status == 504
      // ) {
      //   setShowMaintenance({
      //     status: true,
      //     apiData: error?.response,
      //   });
      // } else if (error?.response?.status == 500) {
      //   setErrorModal({
      //     show: true,
      //     apiData: error?.response,
      //     content: "Oops something went wrong !",
      //     tryAgain: function () {
      //       getData();
      //     },
      //   });
      // } else if (error?.response?.status == 400) {
      //   setOrderMessage({
      //     show: true,
      //     apiData: error?.response,
      //     content: error?.response?.data?.error?.error,
      //     header: `Info`,
      //   });
      // } else {
      //   setErrorModal({
      //     show: true,
      //     apiData: error?.response,
      //     content: error?.response?.data.error?.error
      //       ? error.response.data.error.error
      //       : "Something went wrong!",
      //     tryAgain: function () {
      //       getData();
      //     },
      //   });
      // }
    }
  };

  useEffect(() => {
    //hit the api to fetch the products
    if (widgetData && !productsData) getData();
  }, [widgetData,widgetData.renderStatus]);

  return (
    widgetData &&
    productsData?.medicinesResponsesList?.length > 0 && (
      <Container
        fluid
        className="commonSlider"
        style={{ backgroundColor: widgetData?.data?.backgroundColor }}
      >
        <div
          className=" font_weight_md product_name "
          style={{ color: widgetData?.data?.titleColor, marginLeft: "1%" }}
        >
          {widgetData?.data?.title}
        </div>

        <Slider
          shimmer={shimmer}
          screen={"home_screen"}
          widgetData={widgetData}
          ProductRes={productsData}
          footerColor={"#50409700"}
          id={widgetData?.data?.title}
          darkColor={widgetData?.data?.titleColor}
          lightColor={widgetData?.data?.titleColor}
          flagBg={"#FFD600"}
        />
      </Container>
    )
  );
};

export default React.memo(TrendingProducts);
