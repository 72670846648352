import React, { useContext, useEffect, useState } from "react";
import { FormControl, Modal } from "react-bootstrap";
import { refreshToken } from "../../Actions/Auth";
import { generateEmailInvoice } from "../../Actions/DownloadCsv";
import "./SendEmailModal.css";
import { usersData } from "../../App";
import ErrorModal from "./ErrorModal";
import ButtonLoading1 from "../Spinners/ButtonLoading1";
import SuccessModal from "./SuccessModal";

function SendEmailModal(props) {
  const { userData, setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [showSuccessDialog, setshowSuccessDialog] = useState({
    show: false,
    content: "",
  });

  const [email, setEmail] = useState("");

  const handleEmail = (e) => {
    let newEmail = e.target.value;
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(newEmail)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    setEmail(e.target.value);
  };

  const handleGenerateEmail = async () => {
    console.log("button click");
    if (loading) return;
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let params = {
      retailerCode: data?.retailerCode,
      idList: props.selectionModel,
      email: email,
    };
    try {
      setLoading(true);
      const res = await generateEmailInvoice(params);
      console.log(res);
      setLoading(false);
      if (res?.data?.data === "SUCCESS") {
        console.log("calling success modal");
        setshowSuccessDialog({
          content: `invoices have been successfully sent to ${email}`,
          show: true,
        });
      }
        props.onHide()
    } catch (error) {
      setLoading(false);
      if (error?.response?.status == 401) {
        refreshToken().then((res) => {
          handleGenerateEmail();
        });
      } else if (
        error?.response?.status == 502 ||
        error?.response?.status == 503 ||
        error?.response?.status == 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status == 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            handleGenerateEmail();
          },
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            handleGenerateEmail();
          },
        });
      }
    }
  };

  //onMount
  useEffect(() => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    setEmail(data?.vemailId || data?.emailId || '');
  }, []);


  return (
    <>
      <SuccessModal
        show={showSuccessDialog.show}
        content={showSuccessDialog.content}
      />
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Modal {...props} centered >
        <Modal.Body>
          <p className="email-header">Enter Mail Id</p>
          <FormControl
            placeholder="enter mail id"
            aria-label="email"
            aria-describedby="basic-addon1"
            type="text"
            value={email}
            onChange={handleEmail}
            //   onKeyUp={passInput}
            className={
              emailError
                ? "padding_10 email-input-placeholder is-invalid"
                : "padding_10 email-input-placeholder"
            }
            //   disabled={!showPasswordField}
          />
          <div className="invalid-feedback">invalid email format</div>
          <button
            className="continue-button"
            onClick={handleGenerateEmail}
            disabled={emailError || email?.length === 0}
          >
            {loading ? <ButtonLoading1 /> : "continue"}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SendEmailModal;
