import React, { useContext, useEffect, useState, useRef } from "react";
import { Container, Row } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router";
import { refreshToken } from "../../Actions/Auth";
import { Common_Event_Hit } from "../../Actions/Events";
import { getIssueWithOrderData } from "../../Actions/Support";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";

function LiveOrderIssueScreen() {
  const [searchTerm, setSearchTerm] = useState("");
  const [liveOrderIssueData, setLiveOrderIssueData] = useState([]);
  const { userData, setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const location = useLocation();
  const orderType = location.state;
  const navigate = useNavigate();
  const [modifiedLiveOrderData, setModifiedLiveOrderData] = useState([]);
  const [shimmer, setShimmer] = useState(false);
  const searchBarRef = useRef();

  const { orderId } = useParams();
  //updates searchTerm and resets the modified data
  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  //async call to get the order data to display.
  const getLiveOrderIssueData = () => {
    let params = {
      category: 1,
    };
    if (orderType) {
      params = {
        category: orderType === "LIVE_ORDERS" ? 1 : 2,
      };
    }
    setShimmer(true);
    getIssueWithOrderData({}, params)
      .then((res) => {
        console.log(res.data.data);
        setLiveOrderIssueData(res.data.data);
        setModifiedLiveOrderData(res.data.data);
        setShimmer(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getLiveOrderIssueData();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong!",
            tryAgain: function () {
              getLiveOrderIssueData();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getLiveOrderIssueData();
            },
          });
        }
      });
  };

  //highlights the search term in the displayed text
  const highlightSearchText = (text, highlight) => {
    let data = JSON.parse(text);
    data.map((item, index) => {
      item.subIssue.map((sIssue, idx) => {
        if (
          sIssue.subIssue.toLowerCase().includes(highlight.trim().toLowerCase())
        ) {
          sIssue.subIssue = sIssue.subIssue.replace(
            new RegExp(highlight, "gi"),
            (match) => `<mark style="background:yellow">${match}</mark>`
          );
        }
      });
    });
    setModifiedLiveOrderData(data);
  };

  //just a call back to get updated data
  const searchLiveOrderIssueData = () => {
    highlightSearchText(JSON.stringify(liveOrderIssueData), searchTerm);
  };

  const handleRaiseIssue = (id, orderId) => {
    //renders report issue form
    navigate("/help-support/ReportIssue/" + id + "/" + orderId, {
      state: liveOrderIssueData,
      replace: true,
    });
  };

  const callEventsApi = (eventName) => {
    let props = {
      eventName: eventName,
    };
    if (eventName === "back_tap") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    }
  };

  const handleBack = () => {
    //call back_tap event
    callEventsApi("back_tap");
    //navigate to the previous screen
    navigate(-1);
  };

  //initial api hit /onMount
  useEffect(() => {
    window.scrollTo(0, 0);
    //update screen name for events hit
    localStorage.setItem("currentScreen", "select_issue_with_order_screen");
    getLiveOrderIssueData();
  }, []);

  //search for the search term and reset modified data on demand
  useEffect(() => {
    if (searchTerm === "") {
      setModifiedLiveOrderData(liveOrderIssueData);
      return;
    }
    searchLiveOrderIssueData();
  }, [searchTerm]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Container fluid className="live-order-issue-parent-container">
        <div
          className="nav-container live-order-nav-container"
         
        >
          <BiArrowBack size="1.5rem" color="#ffffff" onClick={handleBack}  style={{ cursor: "pointer" }}/>
          <span style={{ color: "#ffffff" }} className="page-title">
            Report An Issue
          </span>
        </div>
        <Container fluid className="live-order-issue-main-container">
          <Row
            style={{ background: "#ffffff" }}
            className="search-container issue-search"
          >
            <div className="qa-search-wrapper">
              <div className="qa-search-icon">
                <BsSearch
                  onClick={() => searchBarRef.current.focus()}
                  style={{ cursor: "pointer" }}
                  color="#97ABB8"
                />
              </div>
              <div className="qa-input-container">
                <input
                  className="qa-search"
                  placeholder="Search Issues"
                  onChange={handleSearchTerm}
                  value={searchTerm}
                  ref={searchBarRef}
                />
              </div>
            </div>
          </Row>
          <div className="live-order-issue-list-wrapper">
            <div className="live-order-list-header">
              <p
                style={{ fontWeight: "bold", color: "#FFFFFF", margin: "0px" }}
              >
                {modifiedLiveOrderData[0]?.issue.toUpperCase()}
              </p>
            </div>
            {shimmer && (
              <>
                {[...Array(5)].map((_, index) => {
                  return (
                    <div
                      className="live-order-list-item animateShimmer"
                      key={index}
                    ></div>
                  );
                })}
              </>
            )}
            {!shimmer &&
              modifiedLiveOrderData[0]?.subIssue.map((item, index) => {
                return (
                  <div
                    className="live-order-list-item"
                    key={item.id}
                    onClick={() => handleRaiseIssue(item.id, orderId)}
                  >
                    <div
                      style={{ margin: "0px" }}
                      dangerouslySetInnerHTML={{ __html: `${item.subIssue}` }}
                    ></div>
                    <span style={{ fontWeight: "bold" }}>+</span>
                  </div>
                );
              })}
          </div>
        </Container>
      </Container>
    </>
  );
}

export default LiveOrderIssueScreen;
