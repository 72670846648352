import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { BsDashLg, BsPlusLg } from "react-icons/bs";
import { useNavigate } from "react-router";
import { refreshToken } from "../../../Actions/Auth";
import {
  deleteItemFromCart,
  editCartMedicineQuantity,
} from "../../../Actions/WholeSale/WSCart";
import { WSCartDataContext, usersData } from "../../../App";
import { WHOLESALE_IMAGES } from "../../../Images/svg";
import ErrorModal from "../../Modals/ErrorModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import WSDeleteItemModal from "../Modals/WSDeleteItemModal";

function WSCartCard({ data, ...props }) {
  const quantityInputRef = useRef();
  const [errorData, setErrorData] = useState({ error: false, message: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const [productQuantity, setProductQuantity] = useState(0);
  const prevQuantityRef = useRef();
  const { WSCartData: cartData, setWSCartData: setCartData } =
    useContext(WSCartDataContext);
  const [shimmer, setShimmer] = useState(false);
  const [deleteItem, setDeleteItem] = useState({ show: false, data: data });
  const navigate = useNavigate();

  //api to change the cart quantity
  const editCartQuantity = async () => {
    let userData = JSON.parse(localStorage.getItem("userData"));

    //build the payload
    let body = {
      androidMap: {},
      batchId: data.batchId,
      medicineId: data.medicineId,
      quantity: productQuantity,
      retailerCode: userData.retailerCode,
      slapId: 0,
    };

    //update the shimmer state
    setShimmer(true);
    try {
      const res = await editCartMedicineQuantity(body);
      //udpate the cartData context
      setCartData(res?.data?.data);
      //update the shimmer state
      setShimmer(false);
      // focus back on the quantity input
      quantityInputRef && quantityInputRef.current.focus()
    } catch (error) {
      //update the shimmer state
      setShimmer(false);
      //reset the quantity to the last accepted state
      setProductQuantity(data.orderedQuantity);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          editCartQuantity();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            editCartQuantity();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            editCartQuantity();
          },
        });
      }
    }
  };

  //handler to update the poroduct quantity state
  const handleProductQuantityChange = (e) => {
    //reset the errorData if any
    setErrorData({ error: false, message: "" });
    //hold the previous state data in ref before state update
    prevQuantityRef.current = productQuantity;

    let { multipleOrderQuantity, minOrderQuantity } = data;

    if (e === "increment") {
      if (multipleOrderQuantity > 0)
        setProductQuantity(
          (prevVal) =>
            (prevVal / multipleOrderQuantity + 1) * multipleOrderQuantity
        );
      else setProductQuantity((prevVal) => prevVal + 1);
    } else if (e === "decrement") {
      //return if the value is already <= minOQ
      if (productQuantity === minOrderQuantity || productQuantity === 0) return;
      if (multipleOrderQuantity > 0)
        setProductQuantity(
          (prevVal) =>
            (prevVal / multipleOrderQuantity - 1) * multipleOrderQuantity
        );
      else setProductQuantity((prevVal) => prevVal - 1);
    } else {
      //check if the value is actually
      setProductQuantity(e.target.value);
    }
  };

  //check the updated quantity
  const checkQuantity = () => {
    //quantity can't be greater than min(availableStock, maxOrderQuantity)
    //quantity should be a multiple of moq
    if (!(data && Object.keys(data).length === 0)) return;
    const {
      maxOrderQuantity,
      multipleOrderQuantity,
      inStockQuantity: availableStock,
    } = data;

    if (productQuantity > availableStock) {
      setErrorData({
        error: true,
        message: `quantity cannot be greater than available stock ${availableStock}`,
      });
      //reset the quantity to the last accepted value
      setProductQuantity(prevQuantityRef.current);
      return true;
    } else if (productQuantity > maxOrderQuantity) {
      setErrorData({
        error: true,
        message: `quantity cannot be greater than maximum order quantity ${maxOrderQuantity}`,
      });
      //reset the quantity to the last accepted value
      setProductQuantity(prevQuantityRef.current);
      return true;
    } else if (
      multipleOrderQuantity > 0 &&
      productQuantity % multipleOrderQuantity !== 0
    ) {
      setErrorData({
        error: true,
        message: `quantity should be a multiple of ${multipleOrderQuantity}`,
      });
      //reset the quantity to the last accepted value
      setProductQuantity(prevQuantityRef.current);
      return true;
    }
    return false;
  };

  //api to delete medicine from cart
  const deleteMedicineFromCart = async () => {
    //build the payload
    let body = {
      androidMap: {},
      cartId: cartData.id,
      cartMedicineId: data.id,
      retailerCode: cartData.retailerCode,
    };
    try {
      const res = await deleteItemFromCart(body);
      //update the res with cartData context
      setCartData(res?.data?.data);
    } catch (error) {
      //update the shimmer state
      setShimmer(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          deleteMedicineFromCart();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            deleteMedicineFromCart();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            deleteMedicineFromCart();
          },
        });
      }
    }
  };

  //handler to remove item from cart
  const handleDeleteItem = () => {
    setDeleteItem({ show: true, data: data });
  };

  //update the quantity data when available
  useEffect(() => {
    if (data && Object.keys(data).length === 0) return;
    setProductQuantity(data?.orderedQuantity);
  }, [data]);

  //hit the edit cart quantity api everytime the product qyuanity changes
  useEffect(() => {
    let debounce;
    //donot hit the edit cart api on initial mount and initial state update
    if (productQuantity === data.orderedQuantity || productQuantity === 0)
      return;
    if (productQuantity > 0 && !checkQuantity()) {
      //hit the product price api
      debounce = setTimeout(() => {
        editCartQuantity();
      }, 500);
    }

    return () => {
      clearTimeout(debounce);
    };
  }, [productQuantity]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      <WSDeleteItemModal
        show={deleteItem.show}
        data={deleteItem.data}
        onHide={() => {
          setDeleteItem({ show: false, data: "" });
        }}
        onConfirm={() => {
          deleteMedicineFromCart();
        }}
      />
      {!shimmer ? (
        <div className="ws-cart-card-main-container" {...props}>
          <div
            className="ws-cart-card-details cursor_pointer"
            onClick={() => navigate(`/wholesale/product/${data.medicineId}`)}
          >
            <div className="ws-cart-card-details-sub-section-1">
              <p>{data.medicineName}</p>
              <p>
                PTR : ₹ <span>{data.ptr}</span>{" "}
              </p>
            </div>
            <div className="ws-cart-details-sub-section-2">
              <p>
                Delivery : <span>{data.selectedSlotName}</span>
              </p>
            </div>
          </div>
          <div className="ws-cart-card-price-details">
            <div className="ws-cart-card-price-details-sub-section-1">
              <p>TOTAL</p>
              <p>
                ₹{" "}
                {data.netValue.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
              <div className="ws-cart-card-cd-inline">
                <p>
                  ₹{" "}
                  {data.totalAmountUnDiscounted.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
                <p>{data.cashDiscount}% CD</p>
              </div>
            </div>
            <div className="ws-cart-card-price-details-sub-section-2">
              <img
                src={WHOLESALE_IMAGES.itemDelete}
                alt="delete icon"
                className="ws-prod-delete"
                style={{ marginRight: "1rem" }}
                onClick={handleDeleteItem}
              />
              <div className="quantity-input-wrapper">
                <p>
                  <BsDashLg
                    size="1rem"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleProductQuantityChange("decrement")}
                  />
                </p>
                <input
                  type="number"
                  min={0}
                  className={
                    errorData.error
                      ? "ws-quantity-input-error"
                      : "ws-quantity-input"
                  }
                  ref={quantityInputRef}
                  onChange={handleProductQuantityChange}
                  value={productQuantity}
                />
                <p>
                  <BsPlusLg
                    size="1rem"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleProductQuantityChange("increment")}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="ws-cart-card-main-container" {...props}>
          <div className="ws-cart-card-details">
            <div className="ws-cart-card-details-sub-section-1">
              <p
                className="animateShimmer"
                style={{
                  width: "10rem",
                  height: ".5rem",
                  marginBottom: ".5rem",
                }}
              ></p>
              <p
                className="animateShimmer"
                style={{ width: "4rem", height: ".5rem", marginBottom: "1rem" }}
              ></p>
            </div>
            <div className="ws-cart-details-sub-section-2">
              <p
                className="animateShimmer"
                style={{ width: "4rem", height: ".3srem" }}
              ></p>
            </div>
          </div>
          <div className="ws-cart-card-price-details">
            <div className="ws-cart-card-price-details-sub-section-1">
              <p
                className="animateShimmer"
                style={{
                  width: "3rem",
                  height: ".5rem",
                  marginBottom: ".5rem",
                }}
              ></p>
              <p
                className="animateShimmer"
                style={{
                  width: "5rem",
                  height: ".5rem",
                  marginBottom: ".5rem",
                }}
              ></p>
              <div className="ws-cart-card-cd-inline">
                <p
                  className="animateShimmer"
                  style={{
                    width: "4rem",
                    height: ".5rem",
                    marginBottom: "1rem",
                  }}
                ></p>
                <p
                  className="animateShimmer2"
                  style={{
                    width: "4rem",
                    height: "1.5rem",
                    marginBottom: "1rem",
                  }}
                ></p>
              </div>
            </div>
            <div className="ws-cart-card-price-details-sub-section-2"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(WSCartCard);
