import React, { useEffect, useRef, useContext, useState } from "react";
import { Button, Container, FormControl, InputGroup } from "react-bootstrap";
import Saveo from "../../Images/png/login_saveo_logo.png";
import Store from "../../Images/svg/store.svg";
import Onboard from "../../Images/svg/Onboarding.svg";
import "./Onboarding.css";
import "../../App.css";
import { setOnboardingData } from "../../Actions/Onboarding";
import { checkAuth, refreshToken } from "../../Actions/Auth";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import ButtonLoading1 from "../Spinners/ButtonLoading1";

function OnboardingZipCodeScreen({ handleNext }) {
  const ZIPCODE = {
    val1: "",
    val2: "",
    val3: "",
    val4: "",
    val5: "",
    val6: "",
  };
  const { setShowMaintenance, userData } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [zipCode, setZipCode] = useState(ZIPCODE);
  const [zip, setZip] = useState("");
  const [showZipCodeScreen, setShowZipCodeScreen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const { mobileNo } = userData;

  const zipCodeRef = useRef();
  zipCodeRef.current = zip;

  // useEffect(() => {
  //   handelZipCode();
  //   console.log("zipCodeRef", zipCodeRef.current);
  // }, [zipCode]);

  const handelZipCode = () => {
    let str = "";
    // console.log(zipCode);
    Object.values(zipCode).forEach((item) => (str += item.toString()));
    // console.log('zipstr',str)
    setZip(str);
  };

  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();
  const inputRef6 = useRef();

  const arr = [
    inputRef1,
    inputRef2,
    inputRef3,
    inputRef4,
    inputRef5,
    inputRef6,
  ];

  const handleZipNew = (e) => {
    if (e.keyCode === 13) {
      postZipCode();
    }
    let maxlen = 1;
    const { value, id } = e.target;
    if (value.length > 1) {
      let x = 0;
      for (let i = +id; i < 6; i++) {
        let temp = value.substr(x, 1);
        arr[i].current.value = temp;
        setFocus(arr[i]);
        x++;
      }
    }
    if (e.keyCode === 8 || e.keyCode == 46 || e.keyCode === 37) {
      if (+id > 0) {
        setFocus(arr[+id - 1]);
      }
    } else {
      if (arr.length - 1 > +id) {
        setFocus(arr[+id + 1]);
      }
    }
    constructZip();
  };

  const constructZip = () => {
    let str1 = "";
    arr.map((ref, index) => {
      if (ref.current.value) {
        str1 += ref.current.value;
      }
    });
    zipCodeRef.current = str1;
    setZip(str1);
  };

  const setFocus = (inp) => {
    inp.current.focus();
  };

  // const handleZip = (e) => {
  //   const { value: val, id, name } = e.target;
  //   if (val.length === 1) setZipCode({ ...zipCode, [name]: val });
  //   if (val.length === 1) {
  //     if (id === "6") return;
  //     arr[id].current.focus();
  //   } else if (val.length < 1) {
  //     if (id === "1") {
  //       setZipCode({ ...zipCode, [name]: "" });
  //     }
  //     setZipCode({ ...zipCode, [name]: "" });
  //     arr[id - 2].current.focus();
  //   }
  // };

  const postZipCode = () => {
    // handelZipCode();
    const params = {
      pincode: zipCodeRef.current,
    };
    setShowSpinner(true);
    setOnboardingData("setPincode", params, {})
      .then((res) => {
        console.log(res);
        setShowSpinner(false);
        const body = {
          mobileNumber: mobileNo,
        };
        checkAuth(body).then((res) => {
          console.log("inside check auth in zip screen");
          localStorage.setItem("userData", JSON.stringify(res.data.data.user));
        });
        handleNext();
      })
      .catch((error) => {
        setShowSpinner(false);
        console.log(error.response.status == 500);
        if (error?.response?.status == 401) {
          refreshToken().then((res) => {
            postZipCode();
          });
        } else if (
          error?.response?.status == 502 ||
          error?.response?.status == 503 ||
          error?.response?.status == 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status == 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong!",
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
          });
        }
      });
  };

  useEffect(() => {
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    console.log("userData zip", userData);
    if (userData.zip === null) {
      setShowZipCodeScreen(true);
    } else {
      setShowZipCodeScreen(false);
      handleNext();
    }
  }, []);

  if (setShowZipCodeScreen) {
    return (
      <>
        <ErrorModal
          show={errorModal.show}
          content={errorModal.content}
          apiData={errorModal?.apiData}
          onHide={() => {
            setErrorModal({ show: false, content: "" });
          }}
        />
        {showZipCodeScreen && (
          <Container fluid className="onboarding">
            <Container fluid className="parentDiv_onboarding">
              <div className="logo-container">
                <img src={Saveo} className="saveoLogo" />
              </div>
              <div className="onboarding_section_2">
                <img src={Store} className="storeLogo" />
                <img src={Onboard} className="storeLogo2" />
                <Container className="onboarding_section">
                  <div className="inputs_div">
                    <p className="color_secondary font_weight_md">
                      Enter your store PINCODE{" "}
                    </p>
                    <div className="inputs-zipcode">
                      <InputGroup>
                        <FormControl
                          ref={inputRef1}
                          name="val1"
                          type="number"
                          className="padding_10 zip-item"
                          onKeyUp={handleZipNew}
                          // value={zipCode.val1}
                          id="0"
                          max="1"
                          autoFocus
                        />
                      </InputGroup>
                      <InputGroup>
                        <FormControl
                          ref={inputRef2}
                          name="val2"
                          type="number"
                          className="padding_10 zip-item"
                          onKeyUp={handleZipNew}
                          // value={zipCode.val2}
                          id="1"
                          max="1"
                        />
                      </InputGroup>
                      <InputGroup>
                        <FormControl
                          ref={inputRef3}
                          name="val3"
                          type="number"
                          className="padding_10 zip-item"
                          onKeyUp={handleZipNew}
                          // value={zipCode.val3}
                          id="2"
                          max="1"
                        />
                      </InputGroup>
                      <InputGroup>
                        <FormControl
                          ref={inputRef4}
                          name="val4"
                          type="number"
                          className="padding_10 zip-item"
                          onKeyUp={handleZipNew}
                          // value={zipCode.val4}
                          id="3"
                          max="1"
                        />
                      </InputGroup>
                      <InputGroup>
                        <FormControl
                          ref={inputRef5}
                          name="val5"
                          type="number"
                          className="padding_10 zip-item"
                          onKeyUp={handleZipNew}
                          // value={zipCode.val5}
                          id="4"
                          max="1"
                        />
                      </InputGroup>
                      <InputGroup>
                        <FormControl
                          ref={inputRef6}
                          name="val6"
                          type="number"
                          className="padding_10 zip-item"
                          onKeyUp={handleZipNew}
                          // value={zipCode.val6}
                          id="5"
                          max="1"
                        />
                      </InputGroup>
                    </div>

                    <Button
                      disabled={zip.length < 6}
                      className="bg_secondary button border_none "
                      onClick={postZipCode}
                    >
                      {showSpinner ? <ButtonLoading1 /> : "Submit"}
                    </Button>
                  </div>
                </Container>
              </div>
            </Container>
          </Container>
        )}
        {/* {showZipCodeScreen === false ? handleNext() : ''} */}
      </>
    );
  } else {
    return <></>;
  }
}

export default OnboardingZipCodeScreen;
