import React, { useEffect } from "react";
import "../Sliders/Slider.css";
import "../HomePage/HomePage.css";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import CompanyCard from "../ProductCard/CompanyCard";
import { Link } from "react-router-dom";

const CompaniesSlider = ({
  getProductListHeader,
  scheme,
  footerColor,
  id,
  darkColor,
  lightColor,
  flagBg,
  data,
  shimmer,
}) => {
  const rightSroll = () => {
    let scrollStep = 200;
    let divId = id ? id : "products_slider";
    let scrollDiv = document.getElementById(divId);
    let sl = scrollDiv.scrollLeft;
    let cw = scrollDiv.scrollWidth;

    if (sl + scrollStep >= cw) {
      scrollDiv.scrollTo(cw, 0);
    } else {
      scrollDiv.scrollTo(sl + scrollStep, 0);
    }
  };
  const leftScroll = () => {
    let scrollStep = 200;
    let divId = id ? id : "products_slider";
    let scrollDiv = document.getElementById(divId);
    let sl = scrollDiv.scrollLeft;

    if (sl - scrollStep <= 0) {
      scrollDiv.scrollTo(0, 0);
    } else {
      scrollDiv.scrollTo(sl - scrollStep, 0);
    }
  };

  const autoSlideRight = (clear) => {
    let time;
    if (!clear) {
      for (let i = 0; i < 300; i++) {
        let scrollStep = 200;
        let divId = id ? id : "products_slider";
        let scrollDiv = document.getElementById(divId);
        let sl = scrollDiv.scrollLeft;
        let cw = scrollDiv.scrollWidth;

        if (sl + scrollStep >= cw) {
          scrollDiv.scrollTo(cw, 0);
        } else {
          scrollDiv.scrollTo(sl + scrollStep, 0);
        }
        i++;
      }
    }
  };
  const autoSlideLeft = (clear) => {
    let time;
    if (!clear) {
      for (let i = 0; i < 300; i++) {
        let scrollStep = 400;
        let divId = id ? id : "products_slider";
        let scrollDiv = document.getElementById(divId);
        let sl = scrollDiv.scrollLeft;

        if (sl - scrollStep <= 0) {
          scrollDiv.scrollTo(0, 0);
        } else {
          scrollDiv.scrollTo(sl - scrollStep, 0);
        }
        i++;
      }
    }
  };

  return (
    <>
      {shimmer ? (
        <div id={id ? id : "products_slider"} className="products_slide_view">
          <Col className="padding_5">
            {" "}
            <CompanyCard shimmer={shimmer} flagBg={flagBg} />{" "}
          </Col>
          <Col className="padding_5">
            {" "}
            <CompanyCard shimmer={shimmer} flagBg={flagBg} />{" "}
          </Col>
          <Col className="padding_5">
            {" "}
            <CompanyCard shimmer={shimmer} flagBg={flagBg} />{" "}
          </Col>
          <Col className="padding_5">
            {" "}
            <CompanyCard shimmer={shimmer} flagBg={flagBg} />{" "}
          </Col>
          <Col className="padding_5">
            {" "}
            <CompanyCard shimmer={shimmer} flagBg={flagBg} />{" "}
          </Col>
          <Col className="padding_5">
            {" "}
            <CompanyCard shimmer={shimmer} flagBg={flagBg} />{" "}
          </Col>
        </div>
      ) : (
        <div>
          <div id={id ? id : "products_slider"} className="products_slide_view">
            {data &&
              data.length > 0 &&
              data.map((manufacturer) => {
                return (
                  <div className="padding_5">
                    {" "}
                    <CompanyCard
                      scheme={scheme}
                      manufacturer={manufacturer}
                      getProductListHeader={getProductListHeader}
                      flagBg={flagBg}
                    />{" "}
                  </div>
                );
              })}
          </div>
          <div
            className="center-con slider_footer"
          >
            <div className="center-con">
              <div className="round" onClick={leftScroll}>
                <div id="cta">
                  <span className="arrowLeft primera next ">
                    {" "}
                    <FaChevronLeft
                      className="arrow_dark"
                      style={{ paddingRight: "5px", color: darkColor }}
                    />
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="arrowLeft segunda next ">
                    <FaChevronLeft
                      className="arrow_light"
                      style={{ color: lightColor }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <Link
              to={{ pathname: `/categories/companies/${scheme}` }}
              className="text_decoration_none"
            >
              <div
                className="arrow_dark font_ubuntu see_all_btn cursor_pointer font_weight_md"
                style={{
                  padding: "0px 60px",
                  marginTop: "-2px",
                  color: darkColor,
                }}
              >
                SEE ALL DETAILS
              </div>
            </Link>

            <div className="center-con">
              <div className="round" onClick={rightSroll}>
                <div id="cta">
                  <span className="arrow primera next ">
                    {" "}
                    <FaChevronRight
                      className="arrow_light"
                      style={{ paddingRight: "5px", color: lightColor }}
                    />
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="arrow segunda next ">
                    <FaChevronRight
                      className="arrow_dark"
                      style={{ color: darkColor }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(CompaniesSlider);
