import React, { useContext, useEffect, useState } from "react";
import { BsArrowLeft, BsFillCaretRightFill } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router";
import { usersData } from "../../../App";
import ErrorModal from "../../Modals/ErrorModal";
import LogoutModal from "../../Modals/LogoutModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import "./Menu.css";

function Menu() {
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [customerDetails, setCustomerDetails] = useState({
    customerId: "",
    mobile: "",
  });
  const navigate = useNavigate();
  const SECTIONS = {
    section1: [
      "My Profile",
      "About Saveo Wholesale",
      "Terms & Conditions",
      "FAQs",
    ],
    section2: ["Try Saveo Retail App", "Become a seller on Saveo"],
    section3: ["Logout"],
  };
  const disabledSectionItems = [
    "FAQs",
    "About Saveo Wholesale",
    "Try Saveo Retail App",
    "Become a seller on Saveo",
  ];
  const [showLogoutModal, setLogoutModal] = useState(false);
  const logOut = async () => {
    localStorage.setItem("screenCount", 0);
    localStorage.removeItem("currentScreen");
    localStorage.removeItem("currentScreenStartTime");
    localStorage.removeItem("userData");
    localStorage.removeItem("userAuth");
    localStorage.removeItem("userObject");
    localStorage.removeItem("onBehalfRetCode");
    localStorage.removeItem("notificationData");
    navigate("/login");
  };

  const handleSectionClick = (sectionName) => {
    if (sectionName === "My Profile") navigate("/wholesale/home/profile");
    else if (sectionName === "Logout") setLogoutModal(true);
    else if (sectionName === 'Terms & Conditions') {
      window.open('../../../terms-and-conditions.html', '_blank');
    }
  };

  useEffect(() => {
    //scroll to the top on mount
    window.scrollTo(0, 0);
    let userData = localStorage.getItem("userData");
    if (userData) {
      userData = JSON.parse(userData);
      userData.businessType &&
        userData.businessType[0] === "PHARMACY" &&
        navigate("/home");
      let { retailerCode, mobileNo } = userData;
      setCustomerDetails({ customerId: retailerCode, mobile: mobileNo });
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      <LogoutModal
        show={showLogoutModal}
        content={errorModal.content}
        logout={logOut}
        onHide={() => {
          setLogoutModal(false);
        }}
      />

      <div className="more-menu-main-container">
        <div className="more-menu-header-wrapper">
          <div className="ws-menu-nav-container">
            <BsArrowLeft
              size="1.5rem"
              className=""
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="more-menu-header">
            <div>
              <p>Customer Id</p>
              <p>{customerDetails.customerId}</p>
            </div>
            <div>
              <p>Mobile Number</p>
              <p>{customerDetails.mobile}</p>
            </div>
          </div>
        </div>
        <div className="more-menu-body-container">
          {Object.keys(SECTIONS).map((section, idx) => {
            return (
              <div
                className="ws-menu-section-container"
                key={idx}
                style={{
                  marginTop: window.innerWidth <= 750 && idx === 0 && "2rem",
                }}
              >
                {SECTIONS[section].map((item, index) => {
                  return (
                    <div
                      className={disabledSectionItems.includes(item) ? 'ws-menu-section-card ws-menu-section-card-disabled' : 'ws-menu-section-card'}
                      key={index}
                      onClick={() => handleSectionClick(item)}
                    >
                      <p style={{ margin: "0px" }}>{item}</p>
                      {item !== "Logout" ? (
                        <BsFillCaretRightFill color="#CAD1DF" />
                      ) : (
                        <MdLogout size="1.5rem" color="#040921" />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Menu;
