import React, { useContext, useEffect, useState } from "react";
import { refreshToken } from "../../Actions/Auth";
import {
  downloadBuyerInvoice,
  getPastInvoice,
} from "../../Actions/DownloadCsv";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import { DataGrid } from "@mui/x-data-grid";
import "./Csv.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router";
import ButtonLoading1 from "../Spinners/ButtonLoading1";
import moment from "moment";
import SendEmailModal from "../Modals/SendEmailModal";
import { Common_Event_Hit } from "../../Actions/Events";
import { TextField, Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TiDelete } from "react-icons/ti";
import { MdOutlineFilterList } from "react-icons/md";

function Csv() {
  const [invoiceData, setInvoiceData] = useState([]);
  const [formattedInvoiceData, setFormattedInvoiceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { userData, setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const navigate = useNavigate();
  const [selectionModel, setSelectionModel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  //table header coloumns
  const columns = [
    {
      field: "invoiceNumber",
      headerName: "Invoices",
      sortable: false,
      cellClassName: "invoice-data-cell",
      headerClassName: "table-header",
      filterable: false,
      disableColumnMenu: true,
      minWidth:'200',

    },
    {
      field: "createdOn",
      headerName: "Created On",
      sortable: false,
      cellClassName: "format-date-cell",
      fontWeight: "bold",
      headerClassName: "table-header",
      filterable: false,
      disableColumnMenu: true,
      minWidth:window.innerWidth < 750 ? '200': '300',
      renderHeader: () => (
        <p
          className="table-header cursor_pointer"
          style={{ margin: "0px", fontWeight: "500" }}
        >
          {"Created On"}

          <Tooltip title="Add Filter" arrow TransitionComponent={Zoom}>
            <span
              style={{ marginLeft: "1rem" }}
              onClick={() => {
                setShowDatePicker((val) => !val);
              }}
              className='header-filter'
            >
              <MdOutlineFilterList size="1.3rem" color="#4B4495" />
            </span>
          </Tooltip>
        </p>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      sortable: false,
      cellClassName: "invoice-data-cell",
      headerClassName: "table-header",
      filterable: false,
      disableColumnMenu: true,
      minWidth:'200',
    },
  ];

  //update the selected invoices state
  const handleDataSelection = (val) => {
    console.log(val);
    setSelectionModel(val);
  };

  //initial api call to get the invoice data
  const getPastInvoiceData = async () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let params = {
      retailerCode: data?.retailerCode,
    };
    //actual api call
    try {
      const res = await getPastInvoice(params);
      console.log(res?.data?.data);
      setInvoiceData(res?.data?.data);
      setFilteredData(res?.data?.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getPastInvoiceData();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getPastInvoiceData();
          },
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getPastInvoiceData();
          },
        });
      }
    }
  };

  //neat trick to create a tag, attach csv and click on it
  const downloadFile = (data, fileName) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  //trigger download API
  const handleDownloadInvoice = async () => {
    //call download tap event
    callApiEvents("download_tap");
    //guard check to prevent multiple api calls while the other is loading
    if (loading) return;

    //build params for the api call
    let params = {
      idList: selectionModel,
    };

    //async await
    try {
      setLoading(true);
      const res = await downloadBuyerInvoice(params);
      //   console.log(res?.data);
      let fileName = res?.headers["content-disposition"]
        .replace("attachment;", "")
        .trim();
      downloadFile(res?.data, fileName);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getPastInvoiceData();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getPastInvoiceData();
          },
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getPastInvoiceData();
          },
        });
      }
    }
  };

  const handleEmail = (e) => {
    //call email_tap event
    callApiEvents("email_tap");
    //update modal state
    setShowEmailModal(true);
  };

  const callApiEvents = (eventName) => {
    let props = {
      eventName: eventName,
    };
    if (eventName === "download_tap" || eventName === "email_tap") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    }
  };

  //formats the epoch timestamp to human readable date
  const formatInvoiceData = () => {
    let data = JSON.parse(JSON.stringify(filteredData));
    data.sort((item1, item2) => {
      let keyA = item1.createdOn;
      let keyB = item2.createdOn;
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
    data.forEach((item, _) => {
      item.createdOn = moment(item.createdOn).format("ll [\t\n] LT");
      item.amount = item.amount.toLocaleString("en-IN", {
        maximumFractionDigits: 0,
        style: "currency",
        currency: "INR",
      });
    });
    //update the state
    setFormattedInvoiceData(data);
  };

  const filterData = (from, to) => {
    let data = JSON.parse(JSON.stringify(invoiceData));
    let filteredData = [];

    if (from && !to) {
      data.forEach((invoice, _) => {
        if (invoice.createdOn >= from) {
          filteredData.push(invoice);
        }
      });
    } else if (from === to) {
      to = +to + 24 * 60 * 60 * 1000;
      console.log("from", from, "to", to);
      data.forEach((invoice, _) => {
        if (invoice.createdOn >= from && invoice.createdOn <= to) {
          filteredData.push(invoice);
        }
      });
    } else if (from && to) {
      data.forEach((invoice, _) => {
        if (invoice.createdOn >= from && invoice.createdOn <= to) {
          filteredData.push(invoice);
        }
      });
    } else if (to && !from) {
      return;
    }
    setFilteredData(filteredData);
  };

  //onMount
  useEffect(() => {
    //set the screen name
    localStorage.setItem("currentScreen", "download_csv_screen");
    window.scrollTo(0, 0);
    //get the past invoice data
    getPastInvoiceData();
  }, []);

  useEffect(() => {
    //handle epoch time stamp
    formatInvoiceData();
  }, [filteredData]);

  // side effect to filter the invoices
  useEffect(() => {
    //if both the filters are null, reset the data
    if (!fromDate && !toDate) {
      let data = JSON.parse(JSON.stringify(invoiceData));
      setFilteredData(data);
    } else if (fromDate && !toDate) {
      //convert the dates into unix epoch timestamp
      let fDay, fMonth, fYear;
      let fDate = new Date(fromDate);
      fDay = fDate.getDate() < 10 ? "0" + fDate.getDate() : fDate.getDate();
      fMonth =
        fDate.getMonth() + 1 < 9
          ? "0" + (fDate.getMonth() + 1)
          : fDate.getMonth() + 1;
      fYear = fDate.getFullYear();
      let fromTimeStamp = moment(
        `${fMonth}/${fDay}/${fYear} 0:00`,
        "M/D/YYYY H:mm"
      ).valueOf();

      //filter the date formats
      filterData(fromTimeStamp, false);
    } else if (fromDate && toDate) {
      //hide the date pickers
      setShowDatePicker((val) => !val);
      //convert the dates into unix epoch timestamp
      let fDay, fMonth, fYear, tDay, tMonth, tYear;
      let fDate = new Date(fromDate);
      fDay = fDate.getDate() < 10 ? "0" + fDate.getDate() : fDate.getDate();
      fMonth =
        fDate.getMonth() + 1 < 9
          ? "0" + (fDate.getMonth() + 1)
          : fDate.getMonth() + 1;
      fYear = fDate.getFullYear();
      let fromTimeStamp = moment(
        `${fMonth}/${fDay}/${fYear} 0:00`,
        "M/D/YYYY H:mm"
      ).valueOf();

      let tDate = new Date(toDate);
      tDay = tDate.getDate();
      tMonth = tDate.getMonth() + 1;
      tYear = tDate.getFullYear();
      let toTimeStamp = moment(
        `${tMonth}/${tDay}/${tYear} 0:00`,
        "M/D/YYYY H:mm"
      ).valueOf();
      
      //filter the date formats
      filterData(fromTimeStamp, toTimeStamp);
    } else if (!toDate) {
      return;
    }
  }, [fromDate, toDate]);

  return (
    <>
      <SendEmailModal
        show={showEmailModal}
        onHide={() => setShowEmailModal(false)}
        selectionModel={selectionModel}
      />
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      {/* <Container fluid> */}
      <div className="nav-container csv-nav-container">
        <BiArrowBack
          size="1.5rem"
          color="#4b4495"
          onClick={() => navigate(-1)}
        />
        <span className="page-title" style={{ cursor: "default" }}>
          Download CSV
        </span>
      </div>
      <div className="download-csv-parent-container">
        {invoiceData && (
          <DataGrid
            disableColumnSelector
            onSelectionModelChange={handleDataSelection}
            selectionModel={selectionModel}
            density="standard"
            rows={formattedInvoiceData}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            checkboxSelection
            resizable
            sx={{ overflowX: 'scroll' }}
          />
        )}
      </div>
      {selectionModel?.length > 0 && (
        <div className="download-container flex-align-center-row">
          <button className="download-button" onClick={handleDownloadInvoice}>
            {loading ? <ButtonLoading1 /> : "Download"}
          </button>
          <span></span>
          <button className="email-button" onClick={handleEmail}>
            Email
          </button>
        </div>
      )}
      {showDatePicker && (
        <div className="date-range-wrapper  " style={{ marginLeft: ".5rem" }}>
          <div className="date-range-container elevation_3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className="date-picker"
                inputFormat="dd-MM-yyyy"
                label="from"
                value={fromDate}
                onChange={(val) => setFromDate(val)}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="filled" style={{width: '2rem !important'}} />
                )}
              />
              <DatePicker
                minDate={new Date(fromDate)}
                inputFormat="dd-MM-yyyy"
                label="to"
                value={toDate}
                onChange={(val) => setToDate(val)}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="filled" />
                )}
              />
            </LocalizationProvider>
          </div>
          <span
            className="cursor_pointer remove-filter"
            onClick={() => {
              setFromDate(null);
              setToDate(null);
              setShowDatePicker(false);
            }}
          >
            <TiDelete size="1.5rem" color="#4B4495" />
          </span>
        </div>
      )}
    </>
  );
}

export default Csv;
