import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { Event_Actions } from "./Actions/Events";
import { variables } from "./environments";
import { ServiceWorkerProvider } from "./useServiceWorker";

let userData = localStorage.getItem("userData");
userData = JSON.parse(userData);
let onBehalfStatus = false;
let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");

if (
  onBehalfRetCode &&
  onBehalfRetCode.length > 0 &&
  onBehalfRetCode !== "undefined" &&
  userData?.admin
) {
  onBehalfStatus = true;
}

axios.interceptors.request.use(
  (req) => {
    // Add configurations here
    req.metadata = { startTime: new Date() };
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    // Add configurations here
    res.config.metadata.endTime = new Date();
    res.duration = res.config.metadata.endTime - res.config.metadata.startTime;
    let urlArray = res.request.responseURL
      .split("?")[0]
      .split("/")
      .slice(1, res.request.responseURL.split("?")[0].length);
    let paramsArray = res.request.responseURL.split("?")[1]?.split("&");
    let arr = [];
    paramsArray &&
      paramsArray.map((ele) => {
        let string = ele.split("=");
        arr.push(...string);
      });
    let userData = JSON.parse(localStorage.getItem("userData"));
    const body = {
      deviceId: "",
      eventName: "api_success_time",
      eventProperties: {
        load_time: res.duration,
        request: {
          c: true,
          d: "https",
          e: "",
          f: "",
          g: urlArray[1],
          h: 443,
          i: urlArray.slice(2, urlArray.length),
          j: arr,
          l: res.request.responseURL,
        },
        enabled: userData?.enabled,
      },
      mobileNo: userData?.mobileNo,
      source:onBehalfStatus?"pharmacyWebOnbehalf": "pharmacyWeb",
      userId: userData?.id,
      version: variables.version,
    };

    const MoengageBody = {
      deviceId: "",
      eventName: "api_success_time",
      load_time: res.duration,
      i: urlArray.slice(2, urlArray.length),
      params: arr,
      url: res.request.responseURL,
      enabled: userData?.enabled,
      mobileNo: userData?.mobileNo,
      source:onBehalfStatus?"pharmacyWebOnbehalf": "pharmacyWeb",
      userId: userData?.id,
      version: variables.version,
    };

    let endpoint = res.config.url.split("/").pop();

    endpoint !== "app_events" && Event_Actions("EVENT", {}, body, MoengageBody);
    return res;
  },
  (error) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let body1; let body2;
    let Moengagebody1; let Moengagebody2;
    // if (error?.response?.data?.error?.error) {
      body1 = {
        deviceId: "",
        eventName:error?.response?.status>=400 && error?.response?.status<499?'alert_shown':"error_shown",
        eventProperties: {
          screen_name: localStorage.getItem("currentScreen"),
          error_message: error?.response?.data?.error?.error?error?.response?.data?.error?.error:'Something went wrong',
          enabled: userData?.enabled,
          phoneNumber: userData?.mobileNo,
          locale: "en",
          userId: userData?.id,
          url: error?.response?.request.responseURL,
          error_info: {
            "url": error?.response?.request?.responseURL,
            "code": error?.response?.status,
            "method": error?.response?.config?.method,
            "message": error?.message,
            "userMessage": error?.response?.data?.error?.error?error?.response?.data?.error?.error:'Something went wrong',
          },
        },
        mobileNo: userData?.mobileNo,
        source:onBehalfStatus?"pharmacyWebOnbehalf": "pharmacyWeb",
        userId: userData?.id,
        version: variables.version,
      };
      Moengagebody1 = {
        deviceId: "",
        eventName:error?.response?.status>=400 && error?.response?.status<499?'alert_shown':"error_shown",
        screen_name: localStorage.getItem("currentScreen"),
        error_message: error?.response?.data?.error?.error?error?.response?.data?.error?.error:'Something went wrong',
        url: error.request.responseURL,
        enabled: userData?.enabled,
        phoneNumber: userData?.mobileNo,
        locale: "en",
        mobileNo: userData?.mobileNo,
        source:onBehalfStatus?"pharmacyWebOnbehalf": "pharmacyWeb",
        userId: userData?.id,
        version: variables.version,
        error_info: {
          "url": error?.response?.request?.responseURL,
          "code": error?.response?.status,
          "method": error?.response?.config?.method,
          "message": error?.message,
          "userMessage": error?.response?.data?.error?.error?error?.response?.data?.error?.error:'Something went wrong',
        }
      };
    // } else {
      body2 = {
        deviceId: "",
        eventName:error?.response?.status>=400 && error?.response?.status<499?'alert':"error",
        eventProperties: {
          screen_name: localStorage.getItem("currentScreen"),
          raw_error: JSON.stringify(error.response),
          raw_request: JSON.stringify(error.response.config),
          enabled: userData?.enabled,
          phoneNumber: userData?.mobileNo,
          locale: "en",
          url: error.request.responseURL,
          userId: userData?.id,
          error_info: {
            "url": error?.response?.request?.responseURL,
            "code": error?.response?.status,
            "method": error?.response?.config?.method,
            "message": error?.message,
            "userMessage": error?.response?.data?.error?.error?error?.response?.data?.error?.error:'Something went wrong',
          }
        },
        mobileNo: userData?.mobileNo,
        source:onBehalfStatus?"pharmacyWebOnbehalf": "pharmacyWeb",
        userId: userData?.id,
        version: variables.version,
      };
      Moengagebody2={
        deviceId: "",
        eventName:error?.response?.status>=400 && error?.response?.status<499?'alert':"error",
        screen_name: localStorage.getItem("currentScreen"),
        raw_error: JSON.stringify(error.response),
        raw_request: JSON.stringify(error.response.config),
        url: error.request.responseURL,
        enabled: userData?.enabled,
        phoneNumber: userData?.mobileNo,
        locale: "en",
        mobileNo: userData?.mobileNo,
        source:onBehalfStatus?"pharmacyWebOnbehalf": "pharmacyWeb",
        userId: userData?.id,
        version: variables.version,
        error_info: {
          "url": error?.response?.request?.responseURL,
          "code": error?.response?.status,
          "method": error?.response?.config?.method,
          "message": error?.message,
          "userMessage": error?.response?.data?.error?.error?error?.response?.data?.error?.error:'Something went wrong',
        }
      }

    // }

    let endpoint = error.response.config.url.split("/").pop();

    endpoint !== "app_events" && error?.response?.status!==401 && Event_Actions("EVENT", {}, body1,Moengagebody1);
    endpoint !== "app_events" && error?.response?.status!==401 && Event_Actions("EVENT", {}, body2,Moengagebody2);

    return Promise.reject(error);
  }
);

window.onload = () => {
  let time = new Date().getTime();
  localStorage.setItem("sessionStartTime", time);
  let userData = JSON.parse(localStorage.getItem("userData"));
  const body = {
    deviceId: "",
    eventName: "session_start",
    eventProperties: {
      timestamp: time,
      session_number: localStorage.getItem("sessionCount"),
      enabled: userData?.enabled,
      deviceName: navigator.userAgent,
      phoneNumber: userData?.mobileNo,
      locale: "en",
      userId: userData?.id,
    },
    mobileNo: userData?.mobileNo,
    source:onBehalfStatus?"pharmacyWebOnbehalf": "pharmacyWeb",
    userId: userData?.id,
    version: variables.version,
  };
  let MoengageBody = {
    deviceId: "",
    eventName: "session_start",
    timestamp: time,
    session_number: localStorage.getItem("sessionCount"),
    enabled: userData?.enabled,
    deviceName: navigator.userAgent,
    phoneNumber: userData?.mobileNo,
    locale: "en",
    mobileNo: userData?.mobileNo,
    source:onBehalfStatus?"pharmacyWebOnbehalf": "pharmacyWeb",
    userId: userData?.id,
    version: variables.version,
  };
  Event_Actions("EVENT", {}, body, MoengageBody);

  if (localStorage.getItem("sessionCount") == null) {
    localStorage.setItem("sessionCount", 1);
  } else {
    let counter = parseInt(localStorage.getItem("sessionCount"));
    counter++;
    localStorage.setItem("sessionCount", counter);
  }
  localStorage.setItem("screenCount", 1);
};

window.onunload = () => {
  let time = new Date().getTime();
  let screenSessionTime = time - localStorage.getItem("currentScreenStartTime");
  let bannerData = JSON.parse(localStorage.getItem("currentScreenBannerData"));
  let userData = JSON.parse(localStorage.getItem("userData"));
  const screen_close_body = {
    deviceId: "",
    eventName: "screen_close",
    eventProperties: {
      timestamp: time,
      session_time: screenSessionTime,
      user_activity: {
        screen_name: localStorage.getItem("currentScreen"),
        source:onBehalfStatus?"pharmacyWebOnbehalf": "pharmacyWeb",
        locale: "en",
        banner_position: bannerData && bannerData.index,
        banner_file_name: bannerData && bannerData.data.fileName,
      },
      screen_name: localStorage.getItem("currentScreen"),
      screens_visited: localStorage.getItem("screenCount"),
      session_number: localStorage.getItem("sessionCount"),
      enabled: userData.enabled,
      deviceName: navigator.userAgent,
      phoneNumber: userData.mobileNo,
      locale: "en",
      userId: userData.id,
    },
    mobileNo: userData.mobileNo,
    source:onBehalfStatus?"pharmacyWebOnbehalf": "pharmacyWeb",
    userId: userData.id,
    version: variables.version,
  };

  const moengage_screen_close_body = {
    deviceId: "",
    eventName: "screen_close",
    timestamp: time,
    session_time: screenSessionTime,
    screen_name: localStorage.getItem("currentScreen"),
    banner_position: bannerData && bannerData.index,
    banner_file_name: bannerData && bannerData.data.fileName,
    screens_visited: localStorage.getItem("screenCount"),
    session_number: localStorage.getItem("sessionCount"),
    enabled: userData.enabled,
    deviceName: navigator.userAgent,
    phoneNumber: userData.mobileNo,
    locale: "en",
    mobileNo: userData.mobileNo,
    source:onBehalfStatus?"pharmacyWebOnbehalf": "pharmacyWeb",
    userId: userData.id,
    version: variables.version,
  };

  Event_Actions("EVENT", {}, screen_close_body, moengage_screen_close_body);

  localStorage.setItem("sessionEndTime", time);

  let sessionTime = time - localStorage.getItem("sessionStartTime");
  const body = {
    deviceId: "",
    eventName: "session_end",
    eventProperties: {
      timestamp: time,
      session_time: sessionTime,
      screens_visited: localStorage.getItem("screenCount"),
      session_number: localStorage.getItem("sessionCount"),
      enabled: userData.enabled,
      deviceName: navigator.userAgent,
      phoneNumber: userData.mobileNo,
      locale: "en",
      userId: userData.id,
    },
    mobileNo: userData.mobileNo,
    source:onBehalfStatus?"pharmacyWebOnbehalf": "pharmacyWeb",
    userId: userData.id,
    version: variables.version,
  };
  const moengage_body = {
    deviceId: "",
    eventName: "session_end",
    timestamp: time,
    session_time: sessionTime,
    screens_visited: localStorage.getItem("screenCount"),
    session_number: localStorage.getItem("sessionCount"),
    enabled: userData.enabled,
    deviceName: navigator.userAgent,
    phoneNumber: userData.mobileNo,
    locale: "en",
    mobileNo: userData.mobileNo,
    source:onBehalfStatus?"pharmacyWebOnbehalf": "pharmacyWeb",
    userId: userData.id,
    version: variables.version,
  };

  Event_Actions("EVENT", {}, body, moengage_body);

  localStorage.removeItem("screenCount");
  localStorage.removeItem("currentScreen");
  localStorage.removeItem("currentScreenStartTime");
  localStorage.removeItem("sessionCount");
};

ReactDOM.render(
  // <React.StrictMode>
    <ServiceWorkerProvider>
      <App />
    </ServiceWorkerProvider>
  // </React.StrictMode>,
  ,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   onSuccess: () => console.log("service worker is now in action"),
//   onUpdate: (reg) => localStorage.setItem("versionUpdate", JSON.stringify(reg)),
// });
