import React from 'react'
import moment from "moment";
import './Orders.css'
const WalletHistoryCard = ({data}) => {

  return (
    <div className='walletCard'>
          
       <div className='display_flex space_btw' style={{padding:'10px'}}>
             <div className='wallet_card_header'>
             {moment(data?.timeStamp).format("lll")}  
             </div>
             <div>

             </div>
       </div>   
       <div className='display_flex space_btw font_ubuntu font_weight_md' style={{padding:'10px'}}>
             <div className=''>
                <div className='font_12 color_grey'>Balance</div> 
             {data.closingBalance? <div>₹ {(data?.closingBalance+(-data?.amount)).toFixed(2)}</div>:0}  
                
             </div>
             <div>
                
                  <div className={data.amount>0?'positive_amount':'minus_amount'}>
                      ₹ {data?.amount.toFixed(2)}
                  </div>
             </div>

       </div>   
       <div className='wallet_reason'>
            {data?.displayReason.toUpperCase()}
       </div>

    </div>
  )
}

export default WalletHistoryCard