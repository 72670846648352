import React, { useContext, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import "./OnboardingScreen5.css";
import Saveo from "../../Images/png/saveo_doc_verification_logo.png";
import { IoCheckmarkDone } from "react-icons/io5";
import { AiTwotoneCheckCircle } from "react-icons/ai";
import { usersData } from "../../App";
import { useNavigate } from "react-router";

function OnboardingScreen5({ handlePrev, handleNext }) {
  const { userData } = useContext(usersData);
  const [isBusinessVerified, setIsBusinessVerified] = useState(false);
  const [isPharmacyUser, setIsPharmacyUser] = useState(false);
  const [enabled, setEnabled] = useState(false)
//   const { enabled, businessType } = userData;
  const navigate = useNavigate()

  //check for business verified status and update the necessary states
  useEffect(() => {
      let userData  = localStorage.getItem('userData')
      userData = JSON.parse(userData)

      const {enabled, businessType} = userData;
      setEnabled(enabled)
    //   console.log('userData ONBS5',userData)

    if (enabled) {
      setIsBusinessVerified(true);
      // console.log(businessType)
      handleNext()
    }
    else setIsBusinessVerified(false);

    if (businessType && businessType[0] === "PHARMACY") setIsPharmacyUser(true);
    else setIsPharmacyUser(false);
  }, []);


  return (
    <>
      {!isBusinessVerified && (
        <div fluid className="onboarding-container">
          {/* <Container fluid className='header-section-container'> */}
          <div className="header-section">
            <div>
              {!isPharmacyUser && <BiArrowBack size="1.5rem" color="white" onClick={handlePrev} />}
              {isPharmacyUser && <BiArrowBack size="1.5rem" color="white" onClick={() => navigate('/home')} style={{cursor: 'pointer'}} />}

            </div>
            {!isPharmacyUser && <p className="header-title-os5">Business under verification</p>}
            {isPharmacyUser && <p className="header-title-os5">Go Home</p>}
            
          </div>
          <div className="hero-section">
            <img src={Saveo} alt="saveo wholesale" className="saveo-logo-OS5" />
            <h1 className="greeting-header">THANK YOU</h1>
            {enabled && (
              <p className="info-text">Your documents are verified.</p>
            )}
            {!enabled && (
              <p className="info-text">Your documents are being verified.</p>
            )}
          </div>
          <div className="verification-details-container">
            <h1 className="details-header">
              Business/Store under verification
            </h1>
            <div className="items-container">
              <div className="item-container">
                <IoCheckmarkDone size="1.5rem" color="#48D1CC" />
                <p className="item-text">Business details added</p>
                {/* <p className="view-button">view</p> */}
              </div>
              <div className="item-container">
                <IoCheckmarkDone size="1.5rem" color="#48D1CC" />
                <p className="item-text">Store loaction added</p>
                {/* <p className="view-button">view</p> */}
              </div>
              <div className="item-container">
                {enabled && <IoCheckmarkDone size="1.5rem" color="#48D1CC" />}
                {!enabled && (
                  <AiTwotoneCheckCircle size="1.5rem" color="#F7B737" />
                )}
                <p className="item-text">KYC documents under verification</p>
                {/* <p className="view-button">view</p> */}
              </div>
            </div>
          </div>

          {/* <Button onClick={handleNext}>GO NEXT</Button> */}
        </div>
      )}

      {/* </Container> */}
    </>
  );
}

export default OnboardingScreen5;
