import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { BsArrowLeft, BsDashLg, BsPlusLg } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { useLocation, useNavigate, useParams } from "react-router";
import { refreshToken } from "../../../Actions/Auth";
import { getMedicine } from "../../../Actions/WholeSale/HomePage";
import { getWSProductPrice } from "../../../Actions/WholeSale/ProductPage";
import { addMedicineToCart } from "../../../Actions/WholeSale/WSCart";
import { usersData, WSCartDataContext } from "../../../App";
import { WHOLESALE_IMAGES } from "../../../Images/svg";
import ErrorModal from "../../Modals/ErrorModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import ButtonLoading1 from "../../Spinners/ButtonLoading1";
import "./Products.css";

function WSProductPage() {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const { userData, setShowMaintenance } = useContext(usersData);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [medicineData, setMedicineData] = useState({});
  const [shimmer, setShimmer] = useState(false);
  const [productQuantity, setProductQuantity] = useState({ quantity: "" });
  const quantityInputRef = useRef();
  const [productPriceShimmer, setProductPriceShimmer] = useState(false);
  const [productPriceData, setProductPriceData] = useState({});
  const prevQuantityRef = useRef();
  const [errorData, setErrorData] = useState({ error: false, message: "" });
  const [otherSellersActiveItem, setOtherSellersActiveItem] = useState(0);
  const [othersTabData, setOthersTabData] = useState({});
  const [othersTabShimmer, setOthersTabShimmer] = useState(false);
  const [loader, setLoader] = useState(false);
  const { WSCartData: cartData, setWSCartData: setCartData } =
    useContext(WSCartDataContext);
  const [bestDelivery, setBestDelivery] = useState(null);
  const [bestRate, setBestRate] = useState(null);
  const [selectedSlabId, setSelectedSlabId] = useState(null);
  const [itemAddedToCart, setItemAddedToCart] = useState(false);

  const navigate = useNavigate();

  const TABS = ["Best Rate", "Best Delivery", "Others"];

  //handler to change the active tab
  const handleActiveTab = (tab) => {
    setActiveTab(tab);
    //reset the existing medicine data before api call
    // setMedicineData([]);
  };

  //api to get medicine data
  const getMedicineData = async () => {
    let params = {
      medicineId: id,
      flag: activeTab === 0 ? "BR" : activeTab === 1 ? "BD" : "OTHERS",
    };
    //update the shimmer state
    if (Object.keys(medicineData)?.length === 0) setShimmer(true);
    //others tab shimmer state update
    activeTab === 2 && setOthersTabShimmer(true);
    try {
      const res = await getMedicine(params);
      //show a popup if no data is found
      //prevents the page from crashing
      if (res.data.data.medicineResponseList === null) {
        setInfoMessage({
          show: true,
          content: "no medicine data",
          header: "INFO",
        });
        return;
      }
      //update the respective state
      if (activeTab === 2) {
        setOthersTabData(res?.data?.data);
      } else if (activeTab === 1) {
        setBestDelivery(res?.data?.data?.medicineResponseList[0]?.deliverySlot);
        setMedicineData(res?.data?.data);
      } else if (activeTab === 0) {
        setBestRate(res?.data?.data?.medicineResponseList[0]?.cashDiscount);
        setMedicineData(res?.data?.data);
      }
      //update the shimmer state
      // updation of shimmer state to be handled in the product price api
      // setShimmer(false);
      //others tab shimmer state update
      activeTab === 2 && setOthersTabShimmer(false);
      //update the input quantity to the minOrderQuantity if available
      if (
        res?.data?.data?.medicineResponseList &&
        res?.data?.data?.medicineResponseList[0]?.minOrderQuantity > 0
      ) {
        setProductQuantity({
          quantity: res.data.data.medicineResponseList[0].minOrderQuantity,
        });
      } else if (
        res?.data?.data?.medicineResponseList[0]?.multipleOrderQuantity > 0
      ) {
        setProductQuantity({
          quantity:
            res?.data?.data?.medicineResponseList[0]?.multipleOrderQuantity,
        });
      } else {
        setProductQuantity({ quantity: 1 });
      }

      // update the selected slab id state
      setSelectedSlabId(
        res?.data?.data?.medicineResponseList[0]?.slabPrices[0].id
      );
    } catch (error) {
      //update the shimmer state if any error
      // setShimmer(false);
      //others tab shimmer state update
      activeTab === 2 && setOthersTabShimmer(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getMedicineData();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getMedicineData();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getMedicineData();
          },
        });
      }
    }
  };

  //api to get medicine price based on quantity
  const fetchProductPrice = async () => {
    //return if no item is active in otherSellers tab
    if (activeTab === 2 && otherSellersActiveItem === null) return;
    if (!productQuantity.quantity) return;
    //return if another api call is already underway
    if (productPriceShimmer) return;

    let params = {
      batchId:
        activeTab === 2
          ? othersTabData.medicineResponseList[otherSellersActiveItem].batchId
          : medicineData?.medicineResponseList[0]?.batchId,
      slabId: "0",
      requestedQuantity:
        productQuantity.quantity === 0
          ? productQuantity.quantity + 1
          : productQuantity.quantity,
    };

    //update the product price state
    setProductPriceShimmer(true);

    //hit the api endpoint
    try {
      const res = await getWSProductPrice(params);
      //update the product price shimmer state
      setProductPriceShimmer(false);
      // update the main shimmer state
      setShimmer(false);
      //update the productPriceDataState
      if (activeTab === 2) {
        let data = { ...othersTabData };
        data.medicineResponseList[otherSellersActiveItem].fetchProductPrice =
          res.data.data;
        //update the othersTabData state
        setOthersTabData(data);
      } else {
        setProductPriceData(res?.data?.data);
      }

      // update the slab id with the id returned from the fetproductprice api.
      // this ensures the slab selection is updated whenever the quantity changes or so
      setSelectedSlabId(res?.data?.data?.slapId);

      // focus back on the quantity editor
      quantityInputRef.current && quantityInputRef.current.focus();
    } catch (error) {
      //update the shimmer state if any error
      setProductPriceShimmer(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          fetchProductPrice();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            fetchProductPrice();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            fetchProductPrice();
          },
        });
      }
    }
  };

  //add medicine to cart api
  const addToCart = async () => {
    //retrun if a request is in progress
    if (loader) return;

    let userData = JSON.parse(localStorage.getItem("userData"));

    //buid the payload
    let body = {
      androidMap: {},
      batchId:
        activeTab === 2
          ? othersTabData.medicineResponseList[otherSellersActiveItem].batchId
          : medicineData.medicineResponseList[0].batchId,
      medicineId:
        activeTab === 2 ? othersTabData.medicineId : medicineData.medicineId,
      retailerCode: userData.retailerCode,
      quantity: productQuantity.quantity,
      slapId: 0,
    };
    //update the loader state
    setLoader(true);
    try {
      const res = await addMedicineToCart(body);
      //update the loader state
      setLoader(false);
      //update the cartData context after adding the medicine to the cart
      setCartData(res?.data?.data);
      // update the item added to cart state
      setItemAddedToCart(true);
    } catch (error) {
      //update the loader state
      setLoader(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          addToCart();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            addToCart();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            addToCart();
          },
        });
      }
    }
  };

  //update the product quantity state
  //onChange handler for the quanity input and increment/decrement buttons
  const handleProductQuantityChange = (e) => {
    if (typeof e !== "string") {
      e.stopPropagation();
      e.nativeEvent?.stopImmediatePropagation();
      e.preventDefault();
    }

    //reset the errorData if any
    setErrorData({ error: false, message: "" });
    //hold the previous state data in ref before state update
    prevQuantityRef.current = productQuantity.quantity;

    let multipleOrderQuantity =
      activeTab === 2 && otherSellersActiveItem >= 0
        ? parseInt(
            othersTabData.medicineResponseList[otherSellersActiveItem]
              .multipleOrderQuantity
          )
        : parseInt(medicineData.medicineResponseList[0].multipleOrderQuantity);
    let minOrderQuantity =
      activeTab === 2 && otherSellersActiveItem >= 0
        ? parseInt(
            othersTabData.medicineResponseList[otherSellersActiveItem]
              .minOrderQuantity
          )
        : parseInt(medicineData.medicineResponseList[0].minOrderQuantity);

    if (e === "increment") {
      if (multipleOrderQuantity > 0)
        //check if the current quantity is a multiple ; else round it to the close next multiple
        productQuantity.quantity % multipleOrderQuantity === 0
          ? setProductQuantity({
              quantity:
                (productQuantity.quantity / multipleOrderQuantity + 1) *
                multipleOrderQuantity,
            })
          : setProductQuantity({
              quantity:
                +productQuantity.quantity +
                (multipleOrderQuantity -
                  (productQuantity.quantity % multipleOrderQuantity)),
            });
      else setProductQuantity({ quantity: productQuantity.quantity + 1 });
    } else if (e === "decrement") {
      //return if the value is already <= minOQ
      if (
        productQuantity.quantity === minOrderQuantity ||
        productQuantity.quantity === 0
      )
        return;
      if (multipleOrderQuantity > 0)
        //check if the current quantity is a multiple ;  else round it to the left close multiple
        productQuantity.quantity % multipleOrderQuantity === 0
          ? setProductQuantity({
              quantity:
                (productQuantity.quantity / multipleOrderQuantity - 1) *
                multipleOrderQuantity,
            })
          : setProductQuantity({
              quantity:
                +productQuantity.quantity -
                (productQuantity.quantity % multipleOrderQuantity),
            });
      else setProductQuantity({ quantity: productQuantity.quantity - 1 });
    } else {
      //check if the value is actually
      setProductQuantity({ quantity: e.target.value });
    }
  };

  //check product quantity entered
  const checkQuantity = () => {
    //quantity can't be greater than min(availableStock, maxOrderQuantity)
    //quantity should be a multiple of moq
    if (!(medicineData && medicineData.medicineResponseList)) return;
    let availableStock, maxOrderQuantity, multipleOrderQuantity;

    if (activeTab === 2) {
      ({
        quantity: availableStock,
        maxOrderQuantity,
        multipleOrderQuantity,
      } = othersTabData.medicineResponseList[otherSellersActiveItem]);
    } else {
      ({
        quantity: availableStock,
        maxOrderQuantity,
        multipleOrderQuantity,
      } = medicineData.medicineResponseList[0]);
    }
    if (productQuantity.quantity > availableStock) {
      setErrorData({
        error: true,
        message: `quantity cannot be greater than available stock ${availableStock}`,
      });
      return true;
    } else if (
      maxOrderQuantity > 0 &&
      productQuantity.quantity > maxOrderQuantity
    ) {
      setErrorData({
        error: true,
        message: `quantity cannot be greater than maximum order quantity ${maxOrderQuantity}`,
      });
      return true;
    } else if (
      multipleOrderQuantity > 0 &&
      productQuantity.quantity % multipleOrderQuantity !== 0
    ) {
      setErrorData({
        error: true,
        message: `quantity should be a multiple of ${multipleOrderQuantity}`,
      });
      return true;
    }
    return false;
  };

  //handler to choose the seller for a certain product
  const handleOtherSellersActiveItem = (val) => {
    //reset the productQuantity everytime the others tab item changes
    //update it to the suitable initial value
    // setProductQuantity(0);
    let minOrderQuantity, multipleOrderQuantity;
    ({ minOrderQuantity, multipleOrderQuantity } =
      othersTabData.medicineResponseList[val]);

    //set quantity to min(moq, minOQ)||1
    if (minOrderQuantity > 0) {
      if (multipleOrderQuantity > 0) {
        setProductQuantity({ quantity: parseInt(multipleOrderQuantity) });
      } else {
        setProductQuantity({ quantity: parseInt(minOrderQuantity) });
      }
    } else {
      if (multipleOrderQuantity > 0) {
        setProductQuantity({ quantity: parseInt(multipleOrderQuantity) });
      } else {
        setProductQuantity({ quantity: 1 });
      }
    }

    //update the otherSellersActiveItemState
    setOtherSellersActiveItem(val);
    //handle the minimum order quantity
    //update the quantity input state to moq
    // update the selected slab id to the first item in the slab list
    handleSlabItemSelect(
      othersTabData.medicineResponseList[val]?.slabPrices[0]
    );
  };

  // handler for the slab item select
  const handleSlabItemSelect = (slabObj) => {
    let availableQuantity =
      activeTab === 2 && otherSellersActiveItem >= 0
        ? parseInt(
            othersTabData.medicineResponseList[otherSellersActiveItem].quantity
          )
        : parseInt(medicineData.medicineResponseList[0].quantity);
    // donot allow select if the available quanitty less than the min order quantity
    if (availableQuantity < slabObj.minQuantityRange) return;
    setSelectedSlabId(slabObj.id);
    // enforce checks on minOQ, mulOQ, maxOQ before updating the product quantity state
    let multipleOrderQuantity =
      activeTab === 2 && otherSellersActiveItem >= 0
        ? parseInt(
            othersTabData.medicineResponseList[otherSellersActiveItem]
              .multipleOrderQuantity
          )
        : parseInt(medicineData.medicineResponseList[0].multipleOrderQuantity);
    let minOrderQuantity =
      activeTab === 2 && otherSellersActiveItem >= 0
        ? parseInt(
            othersTabData.medicineResponseList[otherSellersActiveItem]
              .minOrderQuantity
          )
        : parseInt(medicineData.medicineResponseList[0].minOrderQuantity);

    if (multipleOrderQuantity > 0)
      //check if the current quantity is a multiple ; else round it to the close next multiple
      slabObj.minQuantityRange % multipleOrderQuantity === 0
        ? setProductQuantity({ quantity: slabObj.minQuantityRange })
        : setProductQuantity({
            quantity:
              +slabObj.minQuantityRange +
              (multipleOrderQuantity -
                (slabObj.minQuantityRange % multipleOrderQuantity)),
          });
    else if (minOrderQuantity > 0) {
      slabObj.minQuantityRange >= minOrderQuantity
        ? setProductQuantity({ quantity: slabObj.minQuantityRange })
        : setProductQuantity({ quantity: minOrderQuantity });
    }
    // update the quantity input to the minQuantityRange of the slab item when explicitly clicked on the slab item
    else setProductQuantity({ quantity: slabObj.minQuantityRange });
  };

  //hit the getMedicineData api on tab change
  useEffect(() => {
    //initial api call
    if (!id) return;
    getMedicineData();
  }, [id, activeTab]);

  //fetch the product price everytime the quantity changes
  useEffect(() => {
    let debounce;
    //0 might also return true if !productQuantity is used
    //don't be a dumass again
    if (
      productQuantity.quantity === null ||
      productQuantity.quantity === undefined ||
      errorData.error
    )
      return;

    if (Object.keys(medicineData).length === 0) return;
    if (
      Object.keys(medicineData).length > 0 &&
      (medicineData.medicineResponseList === null ||
        medicineData.batchId === null)
    )
      return;
    if (productQuantity.quantity > 0) {
      //hit the product price api
      debounce = setTimeout(() => {
        fetchProductPrice();
      }, 1000);
    }

    return () => {
      clearTimeout(debounce);
    };
  }, [productQuantity]);

  //reset the error animation state
  useEffect(() => {
    let timer;
    if (errorData.error) {
      timer = setInterval(() => {
        setErrorData({ error: false, message: "" });
      }, 5000);
    }

    //cleanup
    return () => {
      clearInterval(timer);
    };
  }, [errorData.error]);

  //onMount logic
  useEffect(() => {
    let userData = localStorage.getItem("userData");
    if (userData) {
      userData = JSON.parse(userData);
      userData.businessType &&
        userData.businessType[0] === "PHARMACY" &&
        navigate("/home");
    } else {
      navigate("/login");
    }
  }, []);

  // side effects whence item added to cart
  useEffect(() => {
    let successTimeout;
    if (itemAddedToCart) {
      successTimeout = setTimeout(() => {
        setItemAddedToCart(false);
      }, [2000]);
    }

    return () => {
      clearTimeout(successTimeout);
    };
  }, [itemAddedToCart]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      {!shimmer && medicineData && Object.keys(productPriceData).length > 0 && (
        <div className="ws-product-page-parent-container">
          <div className="ws-product-image-wrapper">
            <BsArrowLeft
              size="2rem"
              color="#4b4495"
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
              className="ws-back-nav ws-back-nav-product-page"
            />
            <div className="ws-product-image-container">
              <img
                src={medicineData?.medicineImage || WHOLESALE_IMAGES.image1}
                className="wholesale-product-image"
              />
            </div>
          </div>
          <div className="ws-product-details-main-container">
            <div className="ws-product-header-container">
              <p>{medicineData?.batchInfo?.dealType}</p>
              <h5>{medicineData?.name}</h5>
              <div className="ws-product-man-details">
                <p>{medicineData?.manufacturer}</p>
                <p>{medicineData?.pack} Tablets</p>
              </div>
            </div>

            <div className="ws-product-header-container-min">
              <div className="ws-product-image-container-min">
                <img
                  src={medicineData?.medicineImage || WHOLESALE_IMAGES.image1}
                  className="wholesale-product-image"
                />
              </div>
              <div className="ws-product-header-details-min">
                <p>{medicineData?.batchInfo?.dealType}</p>
                <h5>{medicineData?.name}</h5>
                <div className="ws-product-man-details">
                  <p>{medicineData?.manufacturer}</p>
                  <p>{medicineData?.pack}</p>
                </div>
              </div>
            </div>

            <div className="ws-product-tabs">
              {TABS.map((tab, index) => {
                return (
                  <div
                    className={
                      activeTab === index
                        ? "ws-product-tab-active box-shadow-1"
                        : "ws-product-tab"
                    }
                    key={index}
                    onClick={() => handleActiveTab(index)}
                    style={{
                      borderTopRightRadius: index === 2 && ".625rem",
                      borderTopLeftRadius: index === 0 && ".625rem",
                    }}
                  >
                    <p>{tab}</p>
                    {index === 0 && <p>upto {bestRate}% CD</p>}
                    {index === 1 && <p>{bestDelivery}</p>}
                    {index === 2 &&
                      !othersTabShimmer &&
                      othersTabData?.medicineResponseList?.length && (
                        <p>
                          {othersTabData?.medicineResponseList?.length} more
                          sellers{" "}
                        </p>
                      )}
                    {index === 2 && othersTabShimmer && (
                      <p
                        className="animateShimmer2"
                        style={{
                          height: ".5rem",
                          width: "60%",
                          marginTop: ".2rem",
                        }}
                      ></p>
                    )}
                  </div>
                );
              })}
            </div>
            {activeTab !== 2 && (
              <>
                <div className="ws-product-tab-content">
                  <div className="ws-tab-content-summary">
                    <div>
                      <p>
                        MRP
                        <span>
                          ₹{" "}
                          {medicineData?.medicineResponseList[0]?.mrp.toFixed(
                            2
                          ) || "NA"}
                        </span>
                      </p>
                      <p>
                        Scheme
                        {medicineData.medicineResponseList[0]?.slabPrices.map(
                          (slab) => {
                            if (slab.id === selectedSlabId) {
                              if (slab.slabSchemesObject.length === 0)
                                return <span>NA</span>;
                              return (
                                <div className="ws-scheme-container">
                                  {slab.slabSchemesObject.map((scheme) => {
                                    return (
                                      <span key={scheme.id}>
                                        {scheme.scheme || "NA"}
                                      </span>
                                    );
                                  })}
                                </div>
                              );
                            }
                          }
                        )}
                      </p>
                      <p>
                        Expiry
                        <span>
                          {medicineData?.medicineResponseList[0]?.expiry ||
                            "NA"}
                        </span>
                      </p>
                      <p>
                        Sold By
                        <span>
                          {medicineData?.medicineResponseList[0]?.soldBy ||
                            "NA"}
                        </span>
                      </p>
                      <p>
                        Available Stock
                        <span>
                          {medicineData?.medicineResponseList[0]?.quantity}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="ws-tab-content-breakup">
                    {Object.keys(productPriceData).length > 0 &&
                      productPriceData.breakUpList.map((item, index) => {
                        return (
                          <p
                            key={index}
                            className={item?.focus && "ws-item-focus"}
                          >
                            {item?.key}
                            <span>₹ {item.value.toFixed(2)}</span>
                          </p>
                        );
                      })}
                    <p>
                      Net Rate{" "}
                      <span>
                        ₹{" "}
                        {medicineData?.medicineResponseList[0]?.netRate.toFixed(
                          2
                        )}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="ws-slab-container">
                  <div
                    className="ws-slab-header flex-start-column"
                    style={{ alignItems: "start" }}
                  >
                    <p style={{ color: "#fff" }}>Select a Slab ?&nbsp;</p>
                    <p style={{ color: "#043833", fontSize: ".9rem" }}>
                      More you Buy, More CD you get
                    </p>
                  </div>
                  <div className="ws-slab-carousel ">
                    {medicineData.medicineResponseList[0].slabPrices.map(
                      (slabItem, index) => {
                        return (
                          <div
                            className={
                              selectedSlabId === slabItem.id
                                ? "ws-slab-card flex-align-center-column"
                                : "ws-slab-card ws-slab-card-default flex-align-center-column"
                            }
                            key={index}
                            onClick={() => handleSlabItemSelect(slabItem)}
                            style={{
                              opacity:
                                medicineData.medicineResponseList[0].quantity <
                                  slabItem.minQuantityRange && ".5",
                              cursor:
                                medicineData.medicineResponseList[0].quantity <
                                  slabItem.minQuantityRange && "not-allowed",
                            }}
                          >
                            <p style={{ fontSize: ".8rem" }}>
                              Buy{" "}
                              {(slabItem.maxQuantityRange === 0 ||
                                slabItem.maxQuantityRange === 1) &&
                                ">"}{" "}
                              {slabItem.minQuantityRange}{" "}
                              {slabItem.maxQuantityRange > 1 &&
                                " - " + slabItem.maxQuantityRange}
                            </p>
                            <p style={{ fontSize: "1rem", fontWeight: "bold" }}>
                              @{slabItem.cashDiscount}% CD
                            </p>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </>
            )}

            {activeTab === 2 && (
              <>
                {othersTabShimmer &&
                  [...Array(3)].map((_, index) => {
                    return (
                      <div
                        className="ws-product-other-tab-content animateShimmer"
                        style={{ height: index === 0 ? "15rem" : "7rem" }}
                      ></div>
                    );
                  })}
                {!othersTabShimmer &&
                  othersTabData?.medicineResponseList?.map((item, index) => {
                    return (
                      <>
                        {otherSellersActiveItem === index && (
                          <>
                            <div className="ws-product-other-tab-content ">
                              <div className="ws-tab-content-summary">
                                <div className="ws-tab-content-summary">
                                  <div>
                                    <p>
                                      MRP
                                      <span>
                                        ₹ {item?.mrp.toFixed(2) || "NA"}
                                      </span>
                                    </p>
                                    <p>
                                      Scheme
                                      {item?.slabPrices.map(
                                        (slab) => {
                                          if (slab.id === selectedSlabId) {
                                            if (
                                              slab.slabSchemesObject.length ===
                                              0
                                            )
                                              return <span>NA</span>;
                                            return (
                                              <div className="ws-scheme-container">
                                                {slab.slabSchemesObject.map(
                                                  (scheme) => {
                                                    return (
                                                      <span key={scheme.id}>
                                                        {scheme.scheme || "NA"}
                                                      </span>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                    </p>
                                    <p>
                                      Expiry
                                      <span>{item?.expiry || "NA"}</span>
                                    </p>
                                    <p>
                                      Sold By
                                      <span>{item?.soldBy || "NA"}</span>
                                    </p>
                                    <p>
                                      Available Stock
                                      <span>{item?.quantity}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="ws-other-tab-separator"></div>
                              <div className="ws-tab-content-breakup">
                                {Object.keys(item.fetchProductPrice.breakUpList)
                                  .length > 0 &&
                                  item.fetchProductPrice.breakUpList.map(
                                    (item, index) => {
                                      return (
                                        <p
                                          key={index}
                                          className={
                                            item?.focus && "ws-item-focus"
                                          }
                                        >
                                          {item?.key}
                                          <span>₹ {item.value.toFixed(2)}</span>
                                        </p>
                                      );
                                    }
                                  )}
                                <p>
                                  Net Rate{" "}
                                  <span>₹ {item?.netRate?.toFixed(2)}</span>
                                </p>
                              </div>
                              {/* slab container */}
                              <div className="ws-slab-container">
                                <div
                                  className="ws-slab-header flex-start-column"
                                  style={{ alignItems: "start" }}
                                >
                                  <p style={{ color: "#fff" }}>
                                    Select a Slab ?&nbsp;
                                  </p>
                                  <p
                                    style={{
                                      color: "#043833",
                                      fontSize: ".9rem",
                                    }}
                                  >
                                    More you Buy, More CD you get
                                  </p>
                                </div>
                                <div className="ws-slab-carousel ">
                                  {item.slabPrices.map((slabItem, idx) => {
                                    return (
                                      <div
                                        className={
                                          selectedSlabId === slabItem.id
                                            ? "ws-slab-card flex-align-center-column"
                                            : "ws-slab-card ws-slab-card-default flex-align-center-column"
                                        }
                                        key={idx}
                                        onClick={() =>
                                          handleSlabItemSelect(slabItem)
                                        }
                                        style={{
                                          opacity:
                                            item.quantity <
                                              slabItem.minQuantityRange && ".5",
                                          cursor:
                                            item.quantity <
                                              slabItem.minQuantityRange &&
                                            "not-allowed",
                                        }}
                                      >
                                        <p style={{ fontSize: ".8rem" }}>
                                          Buy{" "}
                                          {(slabItem.maxQuantityRange === 0 ||
                                            slabItem.maxQuantityRange === 1) &&
                                            ">"}{" "}
                                          {slabItem.minQuantityRange}{" "}
                                          {slabItem.maxQuantityRange > 1 &&
                                            " - " + slabItem.maxQuantityRange}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: "1rem",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          @{slabItem.cashDiscount}% CD
                                        </p>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              {!othersTabShimmer &&
                                otherSellersActiveItem !== null && (
                                  <div className="ws-product-items-wrapper">
                                    <div className="ws-product-quantity-container">
                                      <div>
                                        <p>Delivery</p>
                                        <p>
                                          {activeTab === 2
                                            ? othersTabData &&
                                              othersTabData.medicineResponseList &&
                                              othersTabData
                                                .medicineResponseList[
                                                otherSellersActiveItem
                                              ].deliverySlot
                                            : medicineData
                                                ?.medicineResponseList[0]
                                                ?.deliverySlot}
                                        </p>
                                      </div>
                                      <div>
                                        <img
                                          src={WHOLESALE_IMAGES.itemDelete}
                                          alt="delete icon"
                                          className="ws-prod-delete"
                                          style={{ visibility: "hidden" }}
                                        />
                                        <div className="quantity-input-wrapper">
                                          <p>
                                            <BsDashLg
                                              size="1rem"
                                              onClick={() =>
                                                handleProductQuantityChange(
                                                  "decrement"
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                            />
                                          </p>
                                          <input
                                            type="number"
                                            min={0}
                                            className={
                                              errorData.error
                                                ? "ws-quantity-input-error"
                                                : "ws-quantity-input"
                                            }
                                            ref={quantityInputRef}
                                            value={productQuantity.quantity}
                                            onChange={
                                              handleProductQuantityChange
                                            }
                                          />
                                          <p>
                                            <BsPlusLg
                                              size="1rem"
                                              onClick={() =>
                                                handleProductQuantityChange(
                                                  "increment"
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                            />
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    {errorData?.error && (
                                      <p className="ws-quantity-error-message">
                                        {errorData.message}
                                      </p>
                                    )}
                                    <div className="ws-add-quantity-container box-shadow-1">
                                      <div>
                                        {otherSellersActiveItem !== null ? (
                                          <>
                                            <p className="user-select-none">
                                              Total |{" "}
                                              {activeTab === 2
                                                ? othersTabData &&
                                                  othersTabData.medicineResponseList &&
                                                  othersTabData
                                                    .medicineResponseList[
                                                    otherSellersActiveItem
                                                  ].fetchProductPrice
                                                    .requestedQuantity
                                                : productPriceData?.requestedQuantity}{" "}
                                              items
                                            </p>
                                            <p className="user-select-none">
                                              ₹{" "}
                                              {activeTab === 2
                                                ? othersTabData &&
                                                  othersTabData.medicineResponseList &&
                                                  othersTabData.medicineResponseList[
                                                    otherSellersActiveItem
                                                  ].fetchProductPrice.totalNetValue.toLocaleString(
                                                    "en-IN",
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    }
                                                  )
                                                : productPriceData?.totalNetValue.toLocaleString(
                                                    "en-IN",
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    }
                                                  )}
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            <p className="user-select-none">
                                              Total | 0 items
                                            </p>
                                            <p className="user-select-none">
                                              ₹ 0
                                            </p>
                                          </>
                                        )}
                                      </div>
                                      <div className="user-select-none">
                                        <button
                                          className="confirm-button"
                                          onClick={addToCart}
                                        >
                                          {loader ? (
                                            <ButtonLoading1 color="linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%)" />
                                          ) : itemAddedToCart ? (
                                            " Added to cart "
                                          ) : (
                                            "CONFIRM"
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                            {/* <div className="ws-product-buy-now-container">
                              <p
                                className="ws-product-see-more ws-product-see-more-alt"
                                onClick={() =>
                                  handleOtherSellersActiveItem(null)
                                }
                              >
                                see less
                              </p>
                              <button onClick={handleBuyNowHit}>BUY NOW</button>
                            </div> */}
                          </>
                        )}

                        {otherSellersActiveItem !== index && (
                          <div className="ws-product-other-tab-content-min">
                            <div className="ws-tab-content-summary-min">
                              <div>
                                <p>
                                  Sold by{" "}
                                  <span>
                                    {othersTabData.medicineResponseList[index]
                                      .soldBy || "NA"}
                                  </span>{" "}
                                </p>
                                <p>
                                  Available Stock{" "}
                                  <span>
                                    {
                                      othersTabData.medicineResponseList[index]
                                        .quantity
                                    }
                                  </span>
                                </p>
                              </div>
                              <div className="ws-product-separator-min"></div>
                              <div>
                                <div className="ws-product-net-rate-container">
                                  <p>
                                    Net Rate{" "}
                                    <span>
                                      ₹{" "}
                                      {othersTabData.medicineResponseList[
                                        index
                                      ].netRate.toFixed(2)}
                                    </span>
                                  </p>
                                  <p>
                                    Delivery{" "}
                                    <span>
                                      {
                                        othersTabData.medicineResponseList[
                                          index
                                        ].deliverySlot
                                      }
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p
                              className="ws-product-see-more"
                              onClick={() =>
                                handleOtherSellersActiveItem(index)
                              }
                            >
                              see more
                            </p>
                          </div>
                        )}
                      </>
                    );
                  })}
              </>
            )}

            {!othersTabShimmer &&
              otherSellersActiveItem !== null &&
              activeTab !== 2 && (
                <div className="ws-product-items-wrapper">
                  <div className="ws-product-quantity-container">
                    <div>
                      <p>Delivery</p>
                      <p>
                        {activeTab === 2
                          ? othersTabData &&
                            othersTabData.medicineResponseList &&
                            othersTabData.medicineResponseList[
                              otherSellersActiveItem
                            ].deliverySlot
                          : medicineData?.medicineResponseList[0]?.deliverySlot}
                      </p>
                    </div>
                    <div>
                      <img
                        src={WHOLESALE_IMAGES.itemDelete}
                        alt="delete icon"
                        className="ws-prod-delete"
                        style={{ visibility: "hidden" }}
                      />
                      {/* show quantity input only when product shimmer is not active */}
                      {!productPriceShimmer && (
                        <div className="quantity-input-wrapper">
                          <p>
                            <BsDashLg
                              size="1rem"
                              onClick={() =>
                                handleProductQuantityChange("decrement")
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </p>
                          <input
                            type="number"
                            min={0}
                            className={
                              errorData.error
                                ? "ws-quantity-input-error"
                                : "ws-quantity-input"
                            }
                            ref={quantityInputRef}
                            value={productQuantity.quantity}
                            onChange={handleProductQuantityChange}
                          />
                          <p>
                            <BsPlusLg
                              size="1rem"
                              onClick={() =>
                                handleProductQuantityChange("increment")
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </p>
                        </div>
                      )}
                      {productPriceShimmer && (
                        <div className="quantity-input-wrapper">
                          <div
                            style={{ width: "8rem", height: "2rem" }}
                            className="animateShimmer"
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                  {errorData?.error && (
                    <p className="ws-quantity-error-message">
                      {errorData.message}
                    </p>
                  )}
                  <div className="ws-add-quantity-container box-shadow-1">
                    <div className="user-select-none">
                      {otherSellersActiveItem !== null ? (
                        <>
                          <p>
                            Total |{" "}
                            {activeTab === 2
                              ? othersTabData &&
                                othersTabData.medicineResponseList &&
                                othersTabData.medicineResponseList[
                                  otherSellersActiveItem
                                ].fetchProductPrice.requestedQuantity
                              : productPriceData?.requestedQuantity}{" "}
                            items
                          </p>
                          <p>
                            ₹{" "}
                            {activeTab === 2
                              ? othersTabData &&
                                othersTabData.medicineResponseList &&
                                othersTabData.medicineResponseList[
                                  otherSellersActiveItem
                                ].fetchProductPrice.totalNetValue.toLocaleString(
                                  "en-IN",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : productPriceData?.totalNetValue.toLocaleString(
                                  "en-IN",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                          </p>
                        </>
                      ) : (
                        <>
                          <p>Total | 0 items</p>
                          <p>₹ 0</p>
                        </>
                      )}
                    </div>
                    <div className="user-select-none">
                      {!itemAddedToCart ? (
                        <button className="confirm-button" onClick={addToCart}>
                          {loader ? (
                            <ButtonLoading1 color="linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%)" />
                          ) : (
                            "CONFIRM"
                          )}
                        </button>
                      ) : (
                        <div style={{ color: "#fff" }}>
                          <TiTick color="linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%)" />
                          Added Successfully
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}

      {shimmer && (
        <div className="ws-product-page-parent-container">
          <div className="ws-product-image-container">
            <div
              className="animateShimmer"
              style={{
                height: "18rem",
                width: "20rem",
                marginTop: "5rem",
                borderRadius: "1rem",
              }}
            ></div>
          </div>
          <div className="ws-product-details-main-container">
            <p
              className="animateShimmer2"
              style={{ height: "2rem", width: "10rem" }}
            ></p>
            <h5
              className="animateShimmer"
              style={{ height: ".5rem", width: "10rem" }}
            >
              {" "}
            </h5>
            <div className="ws-product-man-details">
              <p
                className="animateShimmer"
                style={{ height: ".6rem", width: "20rem", marginLeft: "0px" }}
              ></p>
            </div>
            <div className="ws-product-tabs animateShimmer"></div>
            <div className="ws-product-tab-content" style={{ border: "none" }}>
              <div
                className="ws-tab-content-summary"
                style={{ paddingTop: "16rem" }}
              >
                <div>
                  <p
                    className="animateShimmer"
                    style={{ height: ".5rem", width: "10rem" }}
                  ></p>
                  <p
                    className="animateShimmer"
                    style={{ height: ".5rem", width: "10rem" }}
                  ></p>
                  <p
                    className="animateShimmer"
                    style={{ height: ".5rem", width: "5rem" }}
                  ></p>
                  <p
                    className="animateShimmer"
                    style={{ height: ".5rem", width: "5rem" }}
                  ></p>
                </div>
              </div>
              <div
                className="ws-tab-content-breakup"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p
                  className="animateShimmer"
                  style={{ height: ".5rem", width: "90%" }}
                ></p>
                <p
                  className="animateShimmer"
                  style={{ height: ".5rem", width: "90%" }}
                ></p>
                <p
                  className="animateShimmer"
                  style={{ height: ".5rem", width: "90%" }}
                ></p>
                <p
                  className="animateShimmer"
                  style={{ height: ".5rem", width: "90%" }}
                ></p>
              </div>
            </div>
            <div className="ws-product-quantity-container">
              <div style={{ width: "100%" }}>
                <p
                  className="animateShimmer"
                  style={{ height: ".5rem", width: "90%" }}
                ></p>
                <p
                  className="animateShimmer"
                  style={{ height: ".5rem", width: "90%", marginTop: "1rem" }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default WSProductPage;
