import React, { useEffect } from "react";
import "./ButtonLoading1.css";

function ButtonLoading1({color}) {
  useEffect(() => {
    console.log({color})
  },[color])
  return (
    <div className="loaderRectangle">
      <div style={{ background: `${color}` }}></div>
      <div style={{ background: `${color}` }}></div>
      <div style={{ background: `${color}` }}></div>
      <div style={{ background: `${color}` }}></div>
      <div style={{ background: `${color}` }}></div>
    </div>
  );
}

export default ButtonLoading1;
