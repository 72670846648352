import React from "react";
import { Button, Container, OverlayTrigger, Popover } from "react-bootstrap";
import "./OnboardingScreen3.css";
import "../../App.css";
import Saveo from "../../Images/svg/wholesale_saveo.svg";
import { BiArrowBack } from "react-icons/bi";
import OnboardingPlayer from "./OnboardingPlayer";
import { FaArrowRight } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { userObjectContext } from "../../App";
import { useContext } from "react";
import { IoIosMail, IoMdCall } from "react-icons/io";

function OnboardingScreen3({
  handlePrev,
  handleNext,
  videoUrl,
  isBusinessSelected,
  setChangeBusinessType,
  selectBusinessTypeProp,
  allowForceBackNav,
  setAllowForceBackNav
}) {
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const navigate = useNavigate();
  // const [userObject, setUserObject] = useState(localStorage.getItem('userObject'))
  const { userObject, setUserObject } = useContext(userObjectContext);

  const handleNotBusiness = () => {
    //disallow the pharmacy user to change business type
    if(selectBusinessTypeProp.toLowerCase() === 'pharmacy' || selectedBusiness.toLowerCase() === 'pharmacy') return;
    setChangeBusinessType(true);   // enable this to enable change business feature
    handlePrev();
  };

  useEffect(() => {
    console.log("videoUrl from screen3", videoUrl);
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    const { businessType } = userData;
    if(businessType) setSelectedBusiness(businessType[0]);
    //donot show the video player for pharmacy
    // if(businessType[0] === 'PHARMACY') handleNext()

    let userObject = localStorage.getItem('userObject')
    userObject = JSON.parse(userObject)
    if (userObject) {
      let userData = localStorage.getItem("userData");
      userData = JSON.parse(userData);
      const { businessType } = userData; 
      console.log("businessType", businessType);
      setSelectedBusiness(businessType[0]);

      
      


      const { gstUrls, panUrl, scheduleHUrls } = userObject;

      //move to the next screen logic
      if(userObject.businessName && userObject.businessName?.length > 0 && !allowForceBackNav) handleNext()
      //reset the force back nav state
      if(allowForceBackNav) setAllowForceBackNav(false)

      //move to the next screen logic
      // if(businessType[0]!== 'PHARMACY'){
      //   if( gstUrls.length === 0 ||
      //     panUrl === null ||
      //     scheduleHUrls.length === 0){
      //       handleNext()
      //     }
      // } else {
      //   if(scheduleHUrls.length > 0){
      //     handleNext()
      //   }
      // }

      // if (businessType && businessType[0]?.length > 0) {
      //   if (
      //     gstUrls.length === 0 ||
      //     panUrl === null ||
      //     scheduleHUrls.length === 0
      //   ) {
      //   } else {
      //     if (businessType[0] === "PHARMACY") {
      //       navigate("/home");
      //     } else {
      //       handleNext();
      //     }
      //   }
      // }
    }
  }, []);

  return (
    <>
      <Container fluid className="onboarding">
        <Container fluid className="parentDiv_onboarding">
          <div className="header-onboarding-screen3">
            {!(selectBusinessTypeProp?.toLowerCase() === 'pharmacy' || selectedBusiness?.toLowerCase() === 'pharmacy') && (
              <BiArrowBack
                size="2rem"
                color="white"
                className="header-back-icon"
                onClick={handleNotBusiness}
              />
            )}
            <img src={Saveo} className="saveo-logo" /> <br />
            {/* <p className="help-link">HELP</p> */}
            <OverlayTrigger
              trigger="click"
              key={"bottom"}
              placement={"bottom"}
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <Popover.Header as="h3">
                    <a
                      href="tel:+918035018025"
                      className="text_decoration_none color_primary"
                    >
                      <IoMdCall className="color_secondary" /> &nbsp;&nbsp;
                      8035018025
                    </a>
                  </Popover.Header>
                  <Popover.Body>
                    <a
                      href="mailTo:support@saveo.in"
                      className="text_decoration_none color_primary"
                    >
                      <IoIosMail className="color_secondary" /> &nbsp;&nbsp;
                      support@saveo.in
                    </a>
                  </Popover.Body>
                </Popover>
              }
            >
              <div className="help-link">HELP</div>
            </OverlayTrigger>
          </div>
          <Container fluid className="welcome-onboard-section">
            <div className="welcome-header">WELCOME</div>
            <div className="selected-profile">
              {selectBusinessTypeProp || selectedBusiness}
            </div>
            <div className="video-container">
              <p className="video-desc">
                Watch how you can start/grow/buy/sell your{" "}
                {selectBusinessTypeProp || selectedBusiness} business with Saveo
                ?
              </p>
              <div className="video-player">
                {/* add videoUrl below as prop to enabled server side video */}
                <OnboardingPlayer
                  url={videoUrl?.videoUrl || 'https://s3.ap-south-1.amazonaws.com/images.saveo.in/1T58GPcwtowce6APS1CYlFF2RNUbP3Ap1.mp4'}
                  preview = {videoUrl?.previewUrl || 'https://s3.ap-south-1.amazonaws.com/images.saveo.in/Wholesale-Thumbnail.jpg'}
                />
              </div>
            </div>
            <div className="setup-button-container">
              <span className="btn-text">SET UP YOUR BUSINESS</span>
              <Button onClick={handleNext} className="icon-container">
                <FaArrowRight size={"1.5rem"} className="next-btn-icon" />
              </Button>
            </div>
            {!(selectBusinessTypeProp.toLowerCase() === "pharmacy" || selectedBusiness.toLowerCase() === "pharmacy") && (
              
                <p className="nav-back-link" onClick={handleNotBusiness}>
                  NOT {selectBusinessTypeProp || selectedBusiness}?
                </p>
              )}
          </Container>
        </Container>
      </Container>
    </>
  );
}

export default OnboardingScreen3;
