import React, { useContext, useEffect, useState } from "react";
import "./DigitalPaymentModal.css";
import Sample_QR from "../../Images/jpg/sample_qr_code.jpg";
import Lottie from "react-lottie-player";
import iconTimer from "../../Images/json/iconTimer.json";
import failed from "../../Images/json/failed.json";
import { Digital_Payment_Actions } from "../../Actions/DigitalPayment";
import { refreshToken } from "../../Actions/Auth";
import { usersData, searchContext } from "../../App";
import ErrorModal from "./ErrorModal";
import AlertModal from "./AlertModal";
import QRCode from "react-qr-code";
import SuccessGIF from "../../Images/gifs/success.gif";

export const DigitalPaymentModal = ({
  show,
  orderData,
  handleDigitalPayment,
  activePaymentModes,
  setInfoMsg,
  setShowInfo,
  setResetPastOrder
}) => {
  const { setShowMaintenance, userData } = useContext(usersData);
  const { setShowRecent } = useContext(searchContext);
  const [amount, setAmount] = useState(null);
  const [payingAmount, setPayingAmount] = useState(null);
  const [phnNumber, setPhnNumber] = useState(userData.mobileNo);
  const [editAmount, setEditAmount] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [sendLink, setSendLink] = useState(false);
  const [expiryTime, setExpiryTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [timerPayment, setTimerPayment] = useState({
    endTime: 1,
    stopWatch: "00:00",
  });
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [alertMsg, setAlertMsg] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [pollStart, setPollStart] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [warning, setWarning] = useState("");
  const [failMsg, setFailMsg] = useState(
    "Please try making the payment again after sometime"
  );
  let statusInfo;

  const handleClose = (reloadData) => {
    setEditAmount(false);
    setShowQR(false);
    setSendLink(false);
    setShowAlert(false);
    if (reloadData) {
      handleDigitalPayment(true);
    } else {
      handleDigitalPayment(false);
    }
  };

  // Handle Cancel payment in click
  const handleCancel = () => {
    setShowAlert(true);
    setAlertMsg("Are you sure that you want to cancel the transaction?");
  };

  // Cancel payment API
  const cancelPayment = () => {
    const params = {
      referenceId: paymentData.transactionId,
    };

    Digital_Payment_Actions("CANCEL_PAYMENT", params)
      .then((res) => {
        clearInterval(statusInfo);
        setPollStart(false);
        setExpiryTime(null);
        setTimerPayment({
          endTime: 1,
          stopWatch: "00:00",
        });
        handleClose(true);
        setInfoMsg(res.data.data.message);
        setShowInfo(true);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            cancelPayment();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance(true);
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            content: "Oops something went wrong !",
            tryAgain: function () {
              cancelPayment();
            },
          });
          // setShowAlert(true);
          // setAlertMsg(error?.response?.data?.error?.error);
        } else {
          setErrorModal({
            show: true,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              cancelPayment();
            },
          });
          // setShowAlert(true);
          // setAlertMsg(error?.response?.data?.error?.error);
        }
      });
  };

  // Initiate Payment
  const initiatePayment = (paymentMode) => {
    const params = {
      retailerCode: clientData.retailerCode,
    };

    const request = {
      paymentMode: paymentMode,
      paymentRequest: {
        amount: payingAmount < 1 ? amount : payingAmount,
        invoiceNumber: orderData.invoiceNumber,
        mobileNumber: phnNumber,
      },
      source: "WEB",
    };

    Digital_Payment_Actions("INITIATE_PAYMENT", params, request)
      .then((res) => {
        setPaymentData(res.data.data);
        setExpiryTime(res.data.data.expiryTime);

        if (paymentMode === "UPI_DYNAMIC_QR_PHONEPE") {
          setShowQR(true);
        } else {
          setSendLink(true);
        }
        setPollStart(true);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            initiatePayment(paymentMode);
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance(true);
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            content: "Oops something went wrong !",
            tryAgain: function () {
              initiatePayment(paymentMode);
            },
          });
        } else {
          setErrorModal({
            show: true,
            content: error?.response?.data?.data?.message
              ? error.response.data.data.message
              : "Something went wrong!",
            tryAgain: function () {
              initiatePayment(paymentMode);
            },
          });
        }
      });
  };

  // Polling the payment status
  const paymentStatus = () => {
    const params = {
      referenceId: paymentData?.transactionId,
    };

    Digital_Payment_Actions("PAYMENT_STATUS", params)
      .then((res) => {
        if (res.data.data.status === "SUCCESS") {
          setPollStart(false);
          setPaymentSuccess(true);
          setTimeout(() => {
            setPaymentSuccess(false);
            setExpiryTime(null);
            handleClose(true);
          }, 5000);
        } else if (
          res.data.data.status === "DECLINED" ||
          res.data.data.status === "ERROR" ||
          res.data.data.status === "CANCELLED"
        ) {
          setPollStart(false);
          setPaymentFailed(true);
          setFailMsg(res?.data?.data?.message);
          setTimeout(() => {
            setPaymentFailed(false);
            setExpiryTime(null);
            handleClose(true);
          }, 5000);
        } else if (res.data.data.status === "EXCEPTION") {
          clearInterval(statusInfo);
          setResetPastOrder(true);
          handleClose(false);
          setExpiryTime(null);
          setInfoMsg(res?.data?.data?.message);
          setShowInfo(true);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            paymentStatus();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance(true);
          clearInterval(statusInfo);
          handleClose(true);
          openInfo();
        } else if (error?.response?.status === 500) {
          // setErrorModal({
          //   show: true,
          //   content: "Oops something went wrong !",
          //   tryAgain: function () {
          //     paymentStatus();
          //   },
          // });
          clearInterval(statusInfo);
          handleClose(true);
          openInfo();
        } else if (error?.response?.data?.error?.error === 'PAYMENT_ERROR' || error?.response?.data?.error?.error === 'FAILED') {
          setPollStart(false);
          setPaymentFailed(true);
          setTimeout(() => {
            setPaymentFailed(false);
            setExpiryTime(null);
            handleClose(true);
          }, 5000);
        } else {
          // setErrorModal({
          //   show: true,
          //   content: error?.response?.data.error?.error
          //     ? error.response.data.error.error
          //     : "Something went wrong!",
          //   tryAgain: function () {
          //     paymentStatus();
          //   },
          // });
          // clearInterval(statusInfo);
          // handleClose(true);
          // openInfo();
          setPollStart(false);
          setPaymentFailed(true);
          setTimeout(() => {
            setPaymentFailed(false);
            setExpiryTime(null);
            handleClose(true);
          }, 5000);
        }
      });
  };

  const handleEditAmount = (e) => {
    e.preventDefault();
    setEditAmount(!editAmount);
    setPayingAmount(amount);
  };

  // Editing the Input Amount
  const handleInputAmt = (e) => {
    if (+e.target.value > amount) {
      setPayingAmount(amount);
    } else if (+e.target.value === 0) {
      setPayingAmount("");
    } else if (e.target.validity.valid) {
      setPayingAmount(+e.target.value);
    }
  };

  const handlePhnNo = (e) => {
    if (e.target.value.length <= 10 && e.target.validity.valid) {
      setPhnNumber(e.target.value);
    }
  };

  const handleQR = (e) => {
    initiatePayment(activePaymentModes.paymentModeList[0]);
  };

  const handleLink = (e) => {
    if (phnNumber.length === 10) {
      initiatePayment(activePaymentModes.paymentModeList[1]);
    } else {
      setWarning("Please check the phone number");

      setTimeout(() => {
        setWarning("");
      }, [5000]);
    }
  };

  // Show info Modal on time out
  const openInfo = () => {
    setExpiryTime(null);
    setInfoMsg("Payment is in progress. Please check the status in sometime.");
    setShowInfo(true);
  };

  useEffect(() => {
    let timer;
    if (expiryTime) {
      if (currentTime < expiryTime) {
        timer = setInterval(() => {
          setCurrentTime(new Date().getTime());
        }, 1000);

        function msToTime(s) {
          var ms = s % 1000;
          s = (s - ms) / 1000;
          var secs = s % 60;
          s = (s - secs) / 60;
          var mins = s % 60;

          if (mins < 10) {
            mins = "0" + mins;
          }
          if (secs < 10) {
            secs = "0" + secs;
          }
          let obj = { ...timerPayment };
          obj.stopWatch = mins + ":" + secs;
          setTimerPayment(obj);
        }
        msToTime(expiryTime - currentTime);
      } else {
        return () => {
          clearInterval(timer);
          if (timerPayment.stopWatch === "00:01") {
            setPaymentSuccess(true);
            handleClose(true);
          }
        };
      }
    }

    return () => {
      if (timer) clearInterval(timer);
      if (timerPayment.stopWatch === "00:01") {
        setExpiryTime(null);
        handleClose(true);
        openInfo();
      }
    };
  }, [expiryTime, currentTime, showQR, sendLink]);

  useEffect(() => {
    setAmount(activePaymentModes?.pendingAmount);
    setPayingAmount(activePaymentModes?.pendingAmount);
  }, [activePaymentModes]);

  useEffect(() => {
    if (pollStart) {
      statusInfo = setInterval(() => {
        paymentStatus();
      }, 3000);
    }

    return () => {
      if (pollStart) clearInterval(statusInfo);
    };
  }, [pollStart]);

  // Disabling search when modal is open
  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.keyCode === 27) {
      event.stopPropagation();
      setShowRecent(false);
    }
  };

  useEffect(() => {}, [handleKeyPress, show]);

  useEffect(() => {
    setTimerPayment({ endTime: 1, stopWatch: "00:00" });
    if (JSON.stringify(userData) !== "{}") {
      setClientData(userData);
    } else {
      setClientData(JSON.parse(localStorage.getItem("userData")));
    }
  }, []);
  return (
    <>
      {show ? (
        <div
          className="digitalPaymentModal"
          onKeyDown={handleKeyPress}
          tabIndex="1"
        >
          <ErrorModal
            show={errorModal.show}
            content={errorModal.content}
            apiData={errorModal?.apiData}
            tryAgain={() => {
              errorModal?.tryAgain();
              setErrorModal({ show: false, content: "" });
            }}
            onHide={() => {
              setErrorModal({ show: false, content: "" });
            }}
          />

          {showAlert && (
            <AlertModal
              show={showAlert}
              setShowAlert={setShowAlert}
              message={alertMsg}
              closeAlert={cancelPayment}
            />
          )}
          <div className="dp_modal_cntr">
            {!paymentSuccess && !paymentFailed && (
              <>
                <div className="dp_modal_header">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() =>
                      expiryTime ? handleCancel() : handleClose(false)
                    }
                    viewBox="0 0 24 24"
                    className="feather feather-x-square dp_modal_close"
                  >
                    <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                    <line x1="9" y1="9" x2="15" y2="15" />
                    <line x1="15" y1="9" x2="9" y2="15" />
                  </svg>
                </div>

                <div className="dp_modal_details">
                  <div>
                    <div className="dp_modal_info">
                      <p>Invoice Number</p> <span>:</span>{" "}
                      <p>{orderData.invoiceNumber}</p>
                    </div>
                    <div className="dp_modal_info">
                      <p>Order Id</p> <span>:</span> <p>{orderData.orderId}</p>
                    </div>
                  </div>

                  <div>
                    <div className="dp_modal_info dp_amount_info">
                      <p>Payable Amount</p> <span>:</span>{" "}
                      <p className="dp_amount">
                        ₹ {amount?.toLocaleString("en-US")}
                      </p>
                      {activePaymentModes.credit && !expiryTime && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={handleEditAmount}
                          viewBox="0 0 24 24"
                          className="feather feather-edit dp_edit_btn"
                        >
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                        </svg>
                      )}
                    </div>

                    {editAmount && (
                      <div className="dp_modal_info dp_edit_amount">
                        <p>
                          <label>Paying Amount</label>
                        </p>{" "}
                        <span>:</span>
                        <p>
                          <input
                            type="number"
                            placeholder="0"
                            pattern="[0-9]*"
                            value={payingAmount}
                            onChange={(e) => handleInputAmt(e)}
                            autoFocus
                            disabled={expiryTime}
                          />
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                {!showQR && !sendLink && (
                  <>
                    <div className="dp_payment_details">
                      <div className="dp_qr_code dp_sample_qr">
                        <img src={Sample_QR} alt="sample_QR" />
                        <button onClick={handleQR}>Show QR</button>
                      </div>

                      <p className="dp_qr_header">Scan & Pay</p>
                    </div>

                    <div className="dp_payment_details">
                      <p className="dp_or">OR</p>
                    </div>

                    <div className="dp_payment_details">
                      <div className="dp_phn">
                        {warning && <p className="dp_warning_msg">{warning}</p>}
                        <div>
                          <p>+91</p>
                          <input
                            type="number"
                            pattern="[0-9]*"
                            value={phnNumber}
                            onChange={handlePhnNo}
                          />
                        </div>
                        <button className="dp_link_btn" onClick={handleLink}>
                          Send Link
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {showQR && (
                  <div className="dp_payment_details">
                    <div className="dp_qr_code">
                      <QRCode value={paymentData.paymentLink} />
                    </div>

                    <p className="dp_payment_info">
                      Scan the above QR with any UPI app to make the payment
                    </p>

                    <p className="dp_timer">
                      <span>Time Left : </span>
                      {timerPayment.stopWatch}
                    </p>
                  </div>
                )}

                {sendLink && (
                  <div className="dp_payment_details">
                    <div className="dp_Loader">
                      <Lottie
                        loop
                        animationData={iconTimer}
                        play
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>

                    <p className="dp_payment_info">
                      A message with the link has been sent to +91{phnNumber}.
                    </p>
                    <p className="dp_payment_info">
                      Please check the link to make the payment
                    </p>

                    <p className="dp_timer">
                      <span>Time Left : </span>
                      {timerPayment.stopWatch}
                    </p>
                  </div>
                )}
              </>
            )}

            {/* Success Message */}
            {paymentSuccess && (
              <div className="dp_msg_cntr">
                <div className="dp_msg dp_success">
                  <img src={SuccessGIF} alt="Success_Payment" />
                  <h4>PAYMENT SUCCESS</h4>
                </div>
              </div>
            )}

            {/* Failed Message */}
            {paymentFailed && (
              <div className="dp_msg_cntr">
                <div className="dp_msg dp_fail">
                  <Lottie
                    loop
                    animationData={failed}
                    play
                    className="dp_failed_svg"
                    style={{ width: "150px" }}
                  />
                  <h4>PAYMENT FAILED</h4>
                  <p>{failMsg}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
