import React, { useContext, useEffect, useState } from "react";
import { GiReceiveMoney } from "react-icons/gi";
import { refreshToken } from "../../../Actions/Auth";
import { getSpecificWidget } from "../../../Actions/WholeSale/HomePage";
import ErrorModal from "../../Modals/ErrorModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import SeeAllButton from "../Buttons/SeeAllButton";
import WholeSaleProductCard from "../WholeSaleProductCard/WholeSaleProductCard";
import "./Home.css";
import { usersData } from "../../../App";
import { useNavigate } from "react-router";

function TopSellingProducts({ data }) {
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const [tspData, setTspData] = useState([]);
  const [shimmer, setShimmer] = useState(false)
  const navigate = useNavigate();

  //api to get the top selling products data
  const getTSPData = async (url) => {
    //update the shimmer state
    setShimmer(true)
    //page : 0 comes directly from the banner widget
    try {
      const res = await getSpecificWidget(url);
      setTspData(res?.data?.data?.medicineResponseList);
      //update the shimmer state
      setShimmer(false)
    } catch (error) {
      //update the shimmer state if error
      setShimmer(false)
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getTSPData(data?.data?.apiEndPoint);
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getTSPData(data?.data?.apiEndPoint);
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getTSPData(data?.data?.apiEndPoint);
          },
        });
      }
    }
  };

  const handleSeeAllProducts = () => {
    navigate('/wholesale/topSellingProducts');
  }

  //onMount
  useEffect(() => {
    // if(!data) return;
    getTSPData(data?.data?.apiEndPoint);
  }, []);
  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      <div className="tsp-main-container">
        <div className="tsp-header-wrapper">
          <div className="tsp-header font_ubuntu">
            <GiReceiveMoney size="1.5rem" color="#1A4059" />
            <span
              className="font_weight_md"
              style={{ fontSize: ".9rem", color: "#1A4059" }}
            >
              {data?.data?.title}
            </span>
          </div>
          <SeeAllButton onClick = {handleSeeAllProducts} />
        </div>
        <div className="wholesale-product-card-wrapper">
          {!shimmer && tspData?.map((item, index) => {
            return <WholeSaleProductCard data={item} key={index}/>;
          })}
          {shimmer && (
            <>
            {[...Array(5)].map((_, index) => {
              return (
                <WholeSaleProductCard shimmer={shimmer} key={index} />
              )
            })}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default TopSellingProducts;
