import React, { useState, useEffect, useContext } from "react";
import { Accordion, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { IoStorefront } from "react-icons/io5";
import { userObjectContext, usersData } from "../../App";
import { IoDocumentTextOutline } from "react-icons/io5";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { common_product_widget } from "../../Actions/Widgets";
import ErrorModal from "../Modals/ErrorModal";
import { refreshToken, checkAuth } from "../../Actions/Auth";
import { Common_Event_Hit } from "../../Actions/Events";
import LogoutModal from "../Modals/LogoutModal";
import { getOnboardingData } from "../../Actions/Onboarding";
import OrderMessageModal from "../Modals/orderMessageModal";
import { Moengage } from "../../environments";

const Profile = () => {
  const KYCDATA = {
    SCHEDULE_H: null,
    SCHEDULE_X: null,
    GST: null,
    PAN: null,
    BANK_DETAILS : null,
  };

  let navigate = useNavigate();
  const [userData, setUserData] = useState();
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [showLogoutModal, setLogoutModal] = useState(false);
  const logOut = async () => {
    await Common_Event_Hit("COMMON_EVENT", "logout_tap");
    await Common_Event_Hit("COMMON_EVENT", "logged_out");
    await Common_Event_Hit("SESSION_END", "session_end");
    localStorage.setItem("screenCount", 0);
    localStorage.removeItem("currentScreen");
    localStorage.removeItem("currentScreenStartTime");
    localStorage.removeItem("userData");
    localStorage.removeItem("userAuth");
    localStorage.removeItem("userObject");
    localStorage.removeItem("onBehalfRetCode");
    localStorage.removeItem("notificationData");
    navigate("/login");
    Moengage.destroy_session();
  };
  const { userObject, setUserObject } = useContext(userObjectContext);
  const [userDocuments, setUserDocuments] = useState([]);
  const [documentsShimmer, setDocumentsShimmer] = useState(false);
  const [kycData, setKycData] = useState(() => KYCDATA);
  const [kycDocuments, setKycDocuments] = useState(() => KYCDATA);
  const [orderMessage, setOrderMessage] = useState({
    show: false,
    content: "",
    header: "",
  });
  const getOnBehalfRetData = (retCode) => {
    common_product_widget("admin/getUserInfoByRetailerCode", {
      retailerCode: retCode,
    })
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getOnBehalfRetData(retCode);
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getOnBehalfRetData(retCode);
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getOnBehalfRetData(retCode);
            },
          });
        }
      });
  };
  const getRetData = (mobile) => {
    const body = {
      mobileNumber: mobile,
    };
    checkAuth(body)
      .then(async (res) => {
        localStorage.setItem("userData", JSON.stringify(res.data.data.user));
        setUserData(res.data.data.user);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getRetData(mobile);
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getRetData(mobile);
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getRetData(mobile);
            },
          });
        }
      });
  };


  const editDocCheck=()=>{
    const body = {
      mobileNumber: userData.mobileNo,
    };

    checkAuth(body)
      .then(async (res) => {
        localStorage.setItem("userData", JSON.stringify(res.data.data.user));
        setUserData(res.data.data.user);
        if(res.data.data.user?.enabled){
          setOrderMessage({
            show: true,
            content: 'Verification is done, Please contact customer support to edit documents',
            header: `Info`,
          });
        }else{
          navigate('/onboarding')
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            editDocCheck();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              editDocCheck();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              editDocCheck();
            },
          });
        }
      });
  }
  //api to fetch userDocuments
  const getUserDocuments = async () => {
    //donot hit the api when mobileNo is not present
    if (
      !userData?.mobileNo ||
      (userData?.mobileNo && userData?.mobileNo?.length === 0)
    )
      return;
    let params = {
      mobileNo: userData?.mobileNo,
    };
    //update the shimmer state
    setDocumentsShimmer(true);
    try {
      const res = await getOnboardingData("getUserDocument", params);
      console.log(res?.data?.data);
      setUserDocuments(res?.data?.data);
      //update the shimmer state
      setDocumentsShimmer(false);
      //update the kycData, kycDocuments state -> this avoids multiple loops per render
      let data = { ...KYCDATA };
      let docsData = { ...KYCDATA };
      res?.data?.data?.map((item, _) => {
        if (data[item.documentType] === null) {
          data[item.documentType] = item.identificationNumber;
        }
        try {
          if (docsData[item.documentType] === null) {
            docsData[item.documentType] = [item.imageUrl];
          } else if (docsData[item.documentType].length > 0) {
            docsData[item.documentType].push(item.imageUrl);
          }
        } catch (error) {
          console.warn(error)
        }
      });
      setKycData(data);
      setKycDocuments(docsData);
    } catch (error) {
      //update the shimmer state
      setDocumentsShimmer(false);
      console.log(error);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getUserDocuments();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance({ status: true, apiData: error?.response });
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: "Oops something went wrong !",
          tryAgain: function () {
            getUserDocuments();
          },
        });
      } else {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getUserDocuments();
          },
        });
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("currentScreen", "profile_screen");
    localStorage.removeItem("currentScreenBannerData");

    let data = localStorage.getItem("userData");
     let parsedData=JSON.parse(data)
    if (data && data.length && ("businessType" in parsedData)) {
      let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");

      if (
        onBehalfRetCode &&
        onBehalfRetCode.length > 0 &&
        onBehalfRetCode !== "undefined"
      ) {
        getOnBehalfRetData(onBehalfRetCode);
      } else {
        getRetData(JSON.parse(data).mobileNo);
      }
    } else {
      localStorage.setItem("screenCount", 0);
      localStorage.removeItem("currentScreen");
      localStorage.removeItem("currentScreenStartTime");
      localStorage.removeItem("userData");
      localStorage.removeItem("userAuth");
      localStorage.removeItem("userObject");
      localStorage.removeItem("onBehalfRetCode");
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      getUserDocuments();
      // console.log({userData})
    }
  }, [userData]);

  return (
    <Container fluid className="bg_profile">
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
       <OrderMessageModal
        show={orderMessage.show}
        content={orderMessage.content}
        header={orderMessage.header}
        onHide={() => {
          setOrderMessage({ show: false, content: "", header: "" });
        }}
      />
      <LogoutModal
        show={showLogoutModal}
        content={errorModal.content}
        logout={logOut}
        onHide={() => {
          setLogoutModal(false);
        }}
      />
      {userData && userObject && (
        <>
          <Container
            fluid
            className="productListHeader font_ubuntu display_flex profile_header"
          >
            <HiOutlineArrowNarrowLeft
              className="cursor_pointer goBack"
              onClick={() => navigate(-1)}
            />{" "}
            &nbsp;&nbsp;&nbsp;{" "}
            <div className="head_specialOffers">My Profile</div>
          </Container>

          <Container className="profile_body">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div>
                    <IoStorefront className="profile_store header_icons cursor_pointer" />{" "}
                    Store Details
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {!userData?.enabled && (
                    <div className="display_flex space_btw">
                      <div></div>
                      <Button
                        className="bg_secondary color_white brdr_none margin_10"
                        onClick={() => editDocCheck()}
                      >
                        Edit Documents
                      </Button>
                    </div>
                  )}
                  <div className="store_details">
                    <div className="basic font_ubuntu ">
                      <div className="font_weight_md basic_header">
                        Basic Details
                      </div>
                      <div className="display_flex space_btw data_sec">
                        <div>
                          <span className="color_primary font_weight_md font_12">
                            Shop Name
                          </span>
                          <br></br>
                          <span className="font_16">{userData.vshopName || userData.shopName}</span>
                        </div>
                        <div>
                          <span className="color_primary font_weight_md font_12">
                            Email
                          </span>
                          <br></br>
                          <span className="font_16">{userData.vemailId || userData.emailId}</span>
                        </div>
                        <div>
                          <span className="color_primary font_weight_md font_12">
                            Phone Number
                          </span>
                          <br></br>
                          <span className="font_16">{userData.vmobile || userData.mobileNo}</span>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="basic font_ubuntu ">
                      <div className="font_weight_md basic_header">
                        Pharmacy Details
                      </div>
                      <div className="display_flex space_btw data_sec">
                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            Address Line 1
                          </span>
                          <br></br>
                          <span className="font_16">
                            {userData.vaddress1 || userData.addressLine1}
                          </span>
                        </div>
                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            Address Line 2
                          </span>
                          <br></br>
                          <span className="font_16">
                            {userData.vaddress2 || userData.addressLine2}
                          </span>
                        </div>
                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            City
                          </span>
                          <br></br>
                          <span className="font_16">{userData.city}</span>
                        </div>

                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            Pincode
                          </span>
                          <br></br>
                          <span className="font_16">{userData.zip}</span>
                        </div>
                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            District
                          </span>
                          <br></br>
                          <span className="font_16">{userData.district}</span>
                        </div>
                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            State
                          </span>
                          <br></br>
                          <span className="font_16">{userData.vstate || userData.state}</span>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="basic font_ubuntu ">
                      <div className="font_weight_md basic_header">KYC</div>
                      <div className="display_flex space_btw data_sec">
                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            Drug License (Schedule H)
                          </span>
                          <br></br>
                          <span className="font_16">
                            {kycData.SCHEDULE_H || "NA"}
                          </span>
                        </div>
                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            Drug License (Schedule X)
                          </span>
                          <br></br>
                          <span className="font_16">
                            {kycData.SCHEDULE_X || "NA"}
                          </span>
                        </div>
                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            GST Number
                          </span>
                          <br></br>
                          <span className="font_16">{kycData.GST || "NA"}</span>
                        </div>
                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            PAN Number
                          </span>
                          <br></br>
                          <span className="font_16">{kycData.PAN || "NA"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div>
                    <IoDocumentTextOutline className="profile_store header_icons cursor_pointer" />{" "}
                    View Documents
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {!userData?.enabled && (
                    <div className="display_flex space_btw">
                      <div></div>
                      <Button
                        className="bg_secondary color_white brdr_none margin_10"
                        onClick={() => navigate("/onboarding")}
                      >
                        Edit Documents
                      </Button>
                    </div>
                  )}

                  <div className="store_details">
                    <div className="basic font_ubuntu ">
                      <div className="display_flex space_btw data_sec" style={{flexDirection : 'column'}}>
                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            Drug License (Schedule H)
                          </span>
                          <br></br>
                          <span className="font_16">
                            {kycDocuments.SCHEDULE_H
                              ? kycDocuments.SCHEDULE_H.map((item, index) => {
                                  return (
                                    <a href={item} target="_blank" rel="noreferrer" key={index}>
                                      <img className="doc_img" src={item} alt="document" />
                                    </a>
                                  );
                                })
                              : "N/A"}
                          </span>
                        </div>
                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            Drug License (Schedule X)
                          </span>
                          <br></br>
                          <span className="font_16">
                            {kycDocuments.SCHEDULE_X
                              ? kycDocuments.SCHEDULE_X.map((item, index) => {
                                  return (
                                    <a href={item} target="_blank" rel="noreferrer" key={index}>
                                      <img className="doc_img" src={item} alt="document" />
                                    </a>
                                  );
                                })
                              : "N/A"}
                          </span>
                        </div>
                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            GST Number
                          </span>
                          <br></br>
                          <span className="font_16">
                            {kycDocuments.GST
                              ? kycDocuments.GST.map((item, index) => {
                                  return (
                                    <a href={item} target="_blank" rel="noreferrer" key={index}>
                                      <img className="doc_img" src={item} alt="document" />
                                    </a>
                                  );
                                })
                              : "N/A"}
                          </span>
                        </div>
                        <div className="detail">
                          <span className="color_primary font_weight_md font_12">
                            PAN Number
                          </span>
                          <br></br>
                          <span className="font_16">
                            {kycDocuments.PAN
                              ? kycDocuments.PAN.map((item, index) => {
                                  return (
                                    <a href={item} target="_blank" rel="noreferrer" key={index}>
                                      <img className="doc_img" src={item} alt="document" />
                                    </a>
                                  );
                                })
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Container className="log_out_sec display_flex space_btw">
              <div></div>
              <div
                className="font_ubuntu font_weight_md log_out_btn cursor_pointer"
                onClick={() => setLogoutModal(true)}
              >
                <RiLogoutCircleRLine /> &nbsp;Log Out
              </div>
            </Container>
          </Container>
        </>
      )}
    </Container>
  );
};

export default Profile;
