import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'

function WSTrackOrderModal({show, data, ...props}) {
  return (
   <>
      <Modal show={show} centered className="ws-track-order-modal" size='l' {...props}>
      <Modal.Body className='ws-track-order-modal-body'>
            <iframe className='ws-track-order-modal-iframe' src={data} style={{overflow: 'scroll'}}></iframe>
        </Modal.Body>
      </Modal>
   </>
  )
}

export default WSTrackOrderModal