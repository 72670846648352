import { Modal, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { variables } from "../../environments";
import "../../App.css";
import "./dialog.css";
import { Common_Event_Hit } from "../../Actions/Events";

const OrderMessageModal = (props) => {
  const [onBehalfStatus, setOnBehalfStatus] = useState(false);

  useEffect(() => {
    let onBehalfCode = localStorage.getItem("onBehalfRetCode");
    if (onBehalfCode?.length) {
      setOnBehalfStatus(true);
    } else {
      setOnBehalfStatus(false);
    }
  }, []);
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className="del_modal"
    >
      <Modal.Header className="bg_primary">
        <Modal.Title id="contained-modal-title-vcenter" className="">
          <p className="font_ubuntu color_white font_weight_md font_18">
            <b>{props.header}</b>
          </p>
          <div
            className="font_ubuntu color_white font_14 "
            style={{ width: "100%" }}
          >
            {props.content}{" "}
            {props?.codes?.length && (<span className="codes_message">{"("}{props.codes.join(", ")}{")"}</span>)
            }
          </div>
          {props.subContent && (
            <div className="font_ubuntu color_white font_14 ">
              {props.subContent}
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer
        className="display_flex space_btw bg_primary brdr_none"
        style={{ padding: "0px 10px" }}
      >
        <Button
          className=" width_100 brdr_grey bg_primary got_it color_white font_weight_md"
          onClick={() => {
            Common_Event_Hit("DYNAMIC_EVENT", {
              eventName: "got_it_click",
            });
            props.onHide();
          }}
        >
          GOT IT
        </Button>

        <div className="retDataOrderMessageModel">
          <p className="">
            {onBehalfStatus
              ? localStorage.getItem("onBehalfRetCode")
              : JSON.parse(localStorage.getItem("userData"))?.retailerCode}{" "}
          </p>
          <p className="">v{variables.version} </p>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderMessageModal;
