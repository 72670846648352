import { Modal, Button, NavItem } from "react-bootstrap";
import React, { Fragment,useState } from "react";
import '../../App.css';
import './dialog.css'
const ReasonInput = (props) => {
   const [reason,setReason]=useState('') 
    return (
        <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="del_modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="padding_10 text_center">

        <p className="font_ubuntu color_primary font_weight_md font_18">
           {props.content}
        </p>

        <input onChange={(e)=>setReason(e.target.value)} type="text" className="reasonInput"/>
        
       
        </Modal.Title>
      </Modal.Header>
      {/* <Modal.Body>
       
      </Modal.Body> */}
      <Modal.Footer className="display_flex space_btw">
      <Button onClick={props.onHide} className="bg_white brdr_grey cancel_btn color_grey font_weight_md">CANCEL</Button>
        <Button disabled={reason.length<1} onClick={()=>{props.reasonSelect(reason,props.data.item,props.data.index)}}  className="bg_secondary brdr_none font_weight_md delete_btn">SUBMIT</Button>
      </Modal.Footer>
     
    </Modal>
    )
}

export default ReasonInput
