import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./Expiry.css";
import ExpiryList from "./ExpiryList";
import AllExpiry from "./AllExpiry";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

function Expiry() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("expirylist");

  //onMount
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div fluid className="expiry-parent-container">
        <div className="orders_filter display_flex font_ubuntu">
          <div className="padding_5">
            <HiOutlineArrowNarrowLeft
              className="cursor_pointer goBack"
              onClick={() => navigate(-1)}
              style={{ marginTop: "0.5rem" }}
            />
          </div>

          <div
            className="color_primary font_ubuntu font_weight_md no_wrap  padding_10"
            onClick={() => navigate(-1)}
          >
            Your&nbsp;Expiry
          </div>
          <div
            className={
              filter === "expirylist"
                ? "live_menu color_primary font_weight_md activeClass"
                : "live_menu color_primary font_weight_md"
            }
            onClick={() => {
              setFilter("expirylist");
            }}
          >
            Expiry&nbsp;List
          </div>
          <div
            className={
              filter === "allexpiry"
                ? "past_menu color_primary font_weight_md activeClass"
                : "past_menu color_primary font_weight_md"
            }
            onClick={() => {
              setFilter("allexpiry");
            }}
          >
            All&nbsp;Expiry
          </div>
        </div>
        <div className="expiry-main-container"></div>
        {filter === "expirylist" ? <ExpiryList /> : <AllExpiry />}
      </div>
    </>
  );
}

export default Expiry;
