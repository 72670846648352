import React from "react";
import "./AlertModal.css";
import "./InfoModal.css";

const InfoModal = ({ show, message, handleInfoReset }) => {
  return (
    <>
      {show && (
        <div className="alertCntr">
          <div className="alertModal">
            <div className="info_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-info"
              >
                <circle
                  xmlns="http://www.w3.org/2000/svg"
                  cx="12"
                  cy="12"
                  r="10"
                />
                <line x1="12" y1="9" x2="12" y2="13" />
                <line x1="12" y1="17" x2="12.01" y2="17" />
              </svg>
            </div>
            <div className="info_header">
              <div className="info_message">
                {message
                  .split(".")
                  .filter((el) => el !== "")
                  .map((msg, indx) => {
                    return <p key={indx + "info_message"}>{msg}.</p>;
                  })}
              </div>
            </div>

            <div className="infoActions">
              <button className="info_confirm_btn" onClick={handleInfoReset}>
                Okay
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoModal;
