import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import ProductCard2 from "../ProductCard/ProductCard2";
import "./ProductList.css";
import "../../App.css";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate, useLocation } from "react-router-dom";
import { refreshToken } from "../../Actions/Auth";
import { common_product_widget } from "../../Actions/Widgets";
import InfiniteScroll from "react-infinite-scroll-component";
import NoData from "../../Images/png/no_data.png";
import { My_Account } from "../../Actions/MyAccount";
import { usersData } from "../../App";

const ProductList = (props) => {
  let obj = null;
  const [screenData, setScreenData] = useState();
  // const { userLoggedIn} = React.useContext(userStatus);
  const [productRes, setProductRes] = useState(obj);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const { userData } = useContext(usersData);
  const [homeShimmer, setHomerShimmer] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();

  const getData = () => {
    page === 0 && setHomerShimmer(true);
    if (location.pathname.split("/")[2].substring(location.pathname.split("/")[2].length - 9) === "FlashSale") {
      const params = {
        page: page,
        dealType: "FlashSale",
      };
      setScreenData("Flash Sale");
      let api = common_product_widget("home/v2/getBulkByProducts", params);
      api
        .then((res) => {
          setHomerShimmer(false);
          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }

          setProductRes(obj);

          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "dealOfTheDayHome") {
      const params = {
        page: page,
        dealType: "DealOfTheDay",
      };
      setScreenData("Deal of the Day");
      let api = common_product_widget("home/v2/getBulkByProducts", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);

          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "specialDiscountHome") {
      const params = {
        page: page,
      };
      setScreenData("Special Discount");
      let api = common_product_widget("home/v2/getSpecialDiscountMedicines", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }

          setProductRes(obj);

          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "trendingHome") {
      const params = {
        size: 20 * (page + 1),
        screenName: "homePage",
      };
      setScreenData("Trending Products");
      let api = common_product_widget("home/trendingproduct", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList = res.data.data.medicinesResponsesList;

            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "dealOfTheDay") {
      const params = {
        page: page,
        dealType: "DealOfTheDay",
      };
      setScreenData("Deal of the Day");
      let api = common_product_widget("home/v2/getBulkByProducts", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "covidEssentialsHome") {
      const params = {
        page: page,
        filterCondition: "popular",
      };
      setScreenData("Covid Essentials");
      let api = common_product_widget("home/v2/getCoronaSafetyMedicines", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);

            setProductRes(obj);
          }

          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "orderAgainHome") {
      const params = {
        size: 20 * (page + 1),
      };
      setScreenData("Order Again");
      let api = common_product_widget("home/orderproducts/recent", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList = res.data.data.medicinesResponsesList;

            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "specialOfferTrending") {
      const params = {
        category: "SPECIAL_OFFERS",
        filterCondition: "popular",
        page: page,
      };
      setScreenData("Most Popular");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "regularSchemeTrending") {
      const params = {
        filterCondition: "popular",
        page: page,
      };
      setScreenData("Most Popular");
      let api = common_product_widget("home/v2/getRegularSchemeMedicines", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }
          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "covidTrending") {
      const params = {
        filterCondition: "popular",
        page: page,
      };
      setScreenData("Most Popular");
      let api = common_product_widget("home/v2/getCoronaSafetyMedicines", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "covidNewlyAdded") {
      const params = {
        filterCondition: "new",
        page: page,
      };
      setScreenData("Newly Added");
      let api = common_product_widget("home/v2/getCoronaSafetyMedicines", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "covidNewlyAdded") {
      const params = {
        filterCondition: "new",
        page: page,
      };
      setScreenData("Newly Added");
      let api = common_product_widget("home/v2/getCoronaSafetyMedicines", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }
          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "exclusiveTrending") {
      const params = {
        category: "OTC",
        filterCondition: "popular",
        page: page,
      };
      setScreenData("Most Popular");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "exclusiveNewlyAdded") {
      const params = {
        category: "OTC",
        filterCondition: "new",
        page: page,
      };
      setScreenData("Newly Added");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "surgicalTrending") {
      const params = {
        category: "SURGICAL",
        filterCondition: "popular",
        page: page,
      };
      setScreenData("Most Popular");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "surgicalNewlyAdded") {
      const params = {
        category: "SURGICAL",
        filterCondition: "new",
        page: page,
      };
      setScreenData("Newly Added");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }
          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "genericTrending") {
      const params = {
        category: "GENERIC",
        filterCondition: "popular",
        page: page,
      };
      setScreenData("Most Popular");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "genericNewlyAdded") {
      const params = {
        category: "GENERIC",
        filterCondition: "new",
        page: page,
      };
      setScreenData("Newly Added");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "ayurvedicTrending") {
      const params = {
        category: "AYURVEDIC",
        filterCondition: "popular",
        page: page,
      };
      setScreenData("Most Popular");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "ayurvedicNewlyAdded") {
      const params = {
        category: "AYURVEDIC",
        filterCondition: "new",
        page: page,
      };
      setScreenData("Newly Added");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "allopathyTrending") {
      const params = {
        category: "ALLOPATHY",
        filterCondition: "popular",
        page: page,
      };
      setScreenData("Most Popular");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }
          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "allopathyNewlyAdded") {
      const params = {
        category: "ALLOPATHY",
        filterCondition: "new",
        page: page,
      };
      setScreenData("Newly Added");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "specialtyTrending") {
      const params = {
        category: "SPECIALTY",
        filterCondition: "popular",
        page: page,
      };
      setScreenData("Most Popular");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "specialtyNewlyAdded") {
      const params = {
        category: "SPECIALTY",
        filterCondition: "new",
        page: page,
      };
      setScreenData("Newly Added");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "factoryToPharmacyTrending") {
      const params = {
        dealType: "FactoryToPharmacy",
        page: page,
      };
      setScreenData("Most Popular");
      let api = common_product_widget("home/v2/getBulkByProducts", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "factoryToPharmacyNewlyAdded") {
      const params = {
        dealType: "FactoryToPharmacy",
        page: page,
      };
      setScreenData("Newly Added");
      let api = common_product_widget("home/v2/getMedicineByCategory", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "home_order_again") {
      const params = {
        size: 10 * (page + 1),
      };
      setScreenData("Order Again");
      let api = common_product_widget("home/orderproducts/recent", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList = res.data.data.medicinesResponsesList;

            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }
          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "yourBounces") {
      const params = {
        page: page,
        retailerId: userData.id,
      };
      setScreenData("Your Bounces");
      let api = My_Account("BOUNCE_ITEMS", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "ayurvedaSale") {
      const params = {
        dealType: "AyurvedaSale",
        page: page,
      };
      setScreenData("Ayurveda Mega Sale");
      let api = common_product_widget("home/v2/getBulkByProducts", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }

          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "surgicalSale") {
      const params = {
        dealType: "SurgicalSale",
        page: page,
      };
      setScreenData("Surgical Mega Sale");
      let api = common_product_widget("home/v2/getBulkByProducts", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }
          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "AllopathySale") {
      const params = {
        dealType: "Special_Rate",
        page: page,
      };
      setScreenData("Allopathy Mega Sale");
      let api = common_product_widget("home/v2/getBulkByProducts", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }
          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    } else if (location.pathname.split("/")[2] === "silvercrossTrending") {
      const params = {
        manufacturer: "SILVERCROSS MEDISCIENCES",
        filterCondition: "popular",
        page: page,
      };
      setScreenData("Most Popular");
      let api = common_product_widget("home/v2/getMedicineBymanufacturer", params);
      api
        .then((res) => {
          setHomerShimmer(false);

          let obj = productRes && productRes.medicinesResponsesList ? productRes : { medicinesResponsesList: [] };
          if (res.data.data.medicinesResponsesList.length > 0) {
            obj.medicinesResponsesList.push(...res.data.data.medicinesResponsesList);
            setDataLength(obj.medicinesResponsesList.length);
          }
          setProductRes(obj);
          if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
            fetchMoreData();
          } else if (res.data.data.size > 0 && res.data.data.medicinesResponsesList.length === 0) {
            fetchMoreData();
          }
          if (res.data.data.size === 0) {
            setHasMore(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              getData();
            });
          }
        });
    }
  };

  let fetchMoreData = () => {
    setPage(+page + 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("currentScreen", "see_all_screen");
    localStorage.removeItem("currentScreenBannerData");

    let userData = localStorage.getItem("userData");

    if (userData && userData.length) {
      console.log(location);
      getData();

      console.log(page);
    } else {
      navigate("/login");
    }
    console.log(props);
  }, [location, page]);

  return (
    <Fragment>
      <Container fluid className="productList_cont">
        <Container fluid className="productListHeader font_ubuntu">
          {" "}
          <HiOutlineArrowNarrowLeft className="cursor_pointer goBack" onClick={() => navigate(-1)} /> &nbsp;&nbsp;&nbsp;{" "}
          {props.productListHeader && props.productListHeader.length > 0 ? props.productListHeader : screenData}
        </Container>

        {!homeShimmer && productRes && productRes.medicinesResponsesList.length > 0 && (
          <div id="scrollableDiv" className="productListDiv" style={{ paddingTop: "0rem" }}>
            <InfiniteScroll
              dataLength={dataLength}
              next={fetchMoreData}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
              scrollThreshold="90%"
              className="infiniteScroll"
              loader={<Spinner animation="border" className="color_secondary spinner" size="lg" />}>
              {productRes && productRes.medicinesResponsesList.map((med, index) => <ProductCard2 position={index} screen={location?.state?.data?.pageId} key={index} medData={med} />)}
            </InfiniteScroll>
          </div>
        )}

        {!homeShimmer && productRes && productRes.medicinesResponsesList.length === 0 && (
          <div id="scrollableDiv" className="productList " style={{ paddingTop: "50px" }}>
            <div className="no_data_parent">
              <img src={NoData} alt="No Data" />
              <br /> <br />
              <p className="font_ubuntu font_16 font_weight_md">No Products Found !</p>
            </div>
          </div>
        )}
        {homeShimmer && (
          <div className="productList">
            {[...Array(5)].map((_, index) => {
              return (
                <div className="padding_5" key={index}>
                  <ProductCard2 shimmer={homeShimmer} />
                </div>
              );
            })}
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default React.memo(ProductList);

// {
/* <InfiniteScroll
    dataLength={productRes.medicinesResponsesList.length}
    next={fetchMoreData}
    //To put endMessage and loader to the top.
    inverse={true} //
    hasMore={true}
    height={'52vh'}
    loader={<h4>Loading...</h4>}
    scrollableTarget="scrollableDiv"
  endMessage={
    <p style={{ textAlign: 'center' }}>
      <b>Yay! You have seen it all</b>
    </p>
  }
  // below props only if you need pull down functionality
  
  >
    {productRes.medicinesResponsesList.map((med, index) => (
        <ProductCard2 key={index} medData={med}/>
    ))}
  </InfiniteScroll> */
// }
