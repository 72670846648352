import React, { useEffect } from "react";
import AddToCartButton from "../Buttons/AddToCartButton";
import "./WholeSaleProductCard.css";

function WholeSaleProductCard2({ data, customCss, shimmer }) {
  
  return (
    <>
      {!shimmer && (
        <div
          className={
            customCss
              ? `ws-card2-main-container ${customCss}`
              : "ws-card2-main-container"
          }
        >
          <div className="ws-card2-header">
            <div className="ws-card2-content">{data?.name}</div>
            <div className="ws-card2-cd-tag">
              {data?.batchInfo?.cashDiscount}% CD
            </div>
          </div>
          <div className="ws-card2-body">
            <div style={{ width: "40%" }}>
              <p>Delivery</p>
              <p>Net Price</p>
              <p>Scheme</p>
            </div>
            <div style={{ width: "2%" }}>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div style={{ width: "58%" }}>
              <p>{data?.batchInfo?.deliverySlot}</p>
              <p>
                <span>₹{data?.batchInfo?.ptr?.toFixed(2)}</span>
                <span> ₹ {data?.batchInfo?.netRate?.toFixed(2)}</span>
              </p>
              <p className="ws-scheme-container">
                {data?.batchInfo?.schemes?.length === 0 ? (
                  'NA'
                ) : (
                  data.batchInfo.schemes.map((scheme, index) => (
                      <span key={index}>{scheme}</span>
                  ))
                )}
              </p>
            </div>
          </div>
          <div className="ws-card2-add-to-cart">
            <AddToCartButton
              moq={data?.batchInfo?.minOrderQuantity}
              disableButtonMargin={true}
            />
          </div>
        </div>
      )}
      {shimmer && (
        <div
          className={
            customCss
              ? `ws-card2-main-container ${customCss}`
              : "ws-card2-main-container"
          }
        >
          <div className="ws-card2-header">
            <div className="ws-card2-content">
              <p
                className="animateShimmer"
                style={{ height: ".5rem", width: "10rem" }}
              ></p>
              <p
                className="animateShimmer"
                style={{ height: ".5rem", width: "5rem" }}
              ></p>
            </div>
            {/* <div className="ws-card2-wrapper"> */}
            <div className="ws-card2-cd-tag animateShimmer2"></div>
            {/* </div> */}
          </div>
          <div className="ws-card2-body">
            <div style={{ width: "40%" }}>
              <p>Delivery</p>
              <p>Net Price</p>
            </div>
            <div style={{ width: "2%" }}>
              <p>:</p>
              <p>:</p>
            </div>
            <div style={{ width: "58%" }}>
              <p
                className="animateShimmer"
                style={{ height: ".5rem", width: "5rem", marginTop: ".5rem" }}
              ></p>
              <p
                className="animateShimmer"
                style={{ height: ".5rem", width: "5rem", marginTop: ".5rem" }}
              ></p>
            </div>
          </div>
          <div className="animateShimmer2" style={{ height: "5rem" }}></div>
        </div>
      )}
    </>
  );
}

export default WholeSaleProductCard2;
