import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import "./Orders.css";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { usersData } from "../../App";
import { refreshToken } from "../../Actions/Auth";
import { My_Account } from "../../Actions/MyAccount";
import EmptyOrders from "../../Images/png/empty_orders.jpg";
import ReturnCard from "./ReturnCard";
import ErrorModal from "../Modals/ErrorModal";
import InfiniteScroll from "react-infinite-scroll-component";

const Returns = () => {
  const [selectedFilter, setFilter] = useState("live");
  const [ordersData, setOrdersData] = useState([]);
  const { userData } = useContext(usersData);
  const [homeShimmer, setHomeShimmer] = useState(false);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [page, setPage] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  let navigate = useNavigate();

  const getOrdersList = (filter) => {
    const params = {
      retailerId: userData.id,
      page: page,
    };
    localStorage.removeItem("currentScreenBannerData");

    page === 0 && setHomeShimmer(true);
    if (filter === "live") {
      localStorage.setItem("currentScreen", "live_returns_screen");

      My_Account("LIVE_RETURNS", params)
        .then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            let data = ordersData;
            data.push(...res.data.data);
            setOrdersData(data);
          } else {
            setHasMore(false);
          }
          setDataLength(res.data.data.length);
          setHomeShimmer(false);
        })
        .catch((error) => {
          setHomeShimmer(false);
          if (error.response) {
            if (error.response.status === 401) {
              refreshToken().then((res) => {
                getOrdersList(filter);
              });
            } else if (
              error.response.status === 502 ||
              error.response.status === 503 ||
              error.response.status === 504
            ) {
              setShowMaintenance({ status: true, apiData: error?.response });
            } else if (error.response.status === 500) {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: "Oops something went wrong !",
                tryAgain: function () {
                  getOrdersList(filter);
                },
              });
            } else {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: error.response.data.error.error,
                tryAgain: function () {
                  getOrdersList(filter);
                },
              });
            }
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Something went wrong !",
              tryAgain: function () {
                getOrdersList(filter);
              },
            });
          }
        });
    } else {
      localStorage.setItem("currentScreen", "past_returns_screen");

      My_Account("PAST_RETURNS", params)
        .then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            let data = ordersData;
            data.push(...res.data.data);
            setOrdersData(data);
          } else {
            setHasMore(false);
          }
          setDataLength(res.data.data.length);
          setHomeShimmer(false);
        })
        .catch((error) => {
          setHomeShimmer(false);
          if (error?.response?.status === 401) {
            refreshToken().then((res) => {
              getOrdersList(filter);
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
              tryAgain: function () {
                getOrdersList(filter);
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                getOrdersList(filter);
              },
            });
          }
        });
    }
  };

  const refresh = () => {
    setOrdersData([]);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let fetchMoreData = () => {
    setPage(+page + 1);
  };

  const filterChange = (filter) => {
    setOrdersData([]);
    setFilter(filter);
    setPage(0);
    setHasMore(true);
  };
  useEffect(() => {
    let data = localStorage.getItem("userData");

    if (data && data.length) {
      console.log(userData);
      return userData && userData.id && getOrdersList(selectedFilter);
    } else {
      navigate("/login");
    }
  }, [userData, selectedFilter, page, ordersData]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
          refresh();
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Container fluid className="orders_parent">
        <div className="orders_filter display_flex font_ubuntu">
          <div className="padding_5">
            <HiOutlineArrowNarrowLeft
              className="cursor_pointer goBack"
              onClick={() => navigate(-1)}
              style={{ marginTop: "0.5rem" }}
            />
          </div>

          <div
            className="color_primary font_ubuntu font_weight_md no_wrap  padding_10"
            onClick={() => navigate(-1)}
          >
            Go&nbsp;Back
          </div>
          <div
            className={
              selectedFilter === "live"
                ? "live_menu color_primary font_weight_md activeClass"
                : "live_menu color_primary font_weight_md"
            }
            onClick={() => filterChange("live")}
          >
            Live Returns
          </div>
          <div
            className={
              selectedFilter === "past"
                ? "past_menu color_primary font_weight_md activeClass"
                : "past_menu color_primary font_weight_md"
            }
            onClick={() => filterChange("past")}
          >
            Past Returns
          </div>
        </div>
        <div className="orders_data_div">
          {homeShimmer ? (
            <>
              <Container>
                <div className="skeletal_div_sm animateShimmer"></div>
              </Container>
              <Container>
                <div className="skeletal_div_sm animateShimmer"></div>
              </Container>
              <Container>
                <div className="skeletal_div_sm animateShimmer"></div>
              </Container>
              <Container>
                <div className="skeletal_div_sm animateShimmer"></div>
              </Container>
            </>
          ) : (
            ordersData &&
            ordersData.length > 0 && (
              <div id="scrollableDiv" className="productList" style={{paddingTop:'0rem'}}>
                <InfiniteScroll
                  dataLength={dataLength}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  scrollableTarget="scrollableDiv"
                  scrollThreshold="90%"
                  className="infiniteScroll"
                  loader={
                    <Spinner
                      animation="border"
                      className="color_secondary spinner"
                      size="lg"
                    />
                  }
                >
                  {ordersData &&
                    ordersData.length > 0 &&
                    ordersData.map((order, index) => (
                      <ReturnCard
                        medData={order}
                        refresh={refresh}
                        filter={selectedFilter}
                      />
                    ))}
                </InfiniteScroll>
              </div>
            )
          )}

          {!homeShimmer && ordersData && ordersData.length === 0 && (
            <div className="empty_list_sec">
              <img src={EmptyOrders} alt="Empty Orders" />
              <br />
              <span className="font_ubuntu font_weight_md color_grey">
                There are no returns
              </span>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default Returns;
