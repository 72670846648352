import { Modal, Button } from "react-bootstrap";
import React from "react";
import "../../App.css";
import "./dialog.css";
import Delete from "../../Images/svg/delete.svg";
import { Common_Event_Hit } from "../../Actions/Events";

const DeleteProductModal = (props) => {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="del_modal"
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="padding_10 text_center"
        >
          <img src={Delete} className="delete_modal_svg" />

          {props.uploadToCart ? (
            <p className="font_ubuntu color_primary_lt font_weight_md font_18">
              Do you really want to remove{" "}
              <span className="color_primary font_weight_lg">
                {props.itemName}
              </span>{" "}
              from {props.return ? "return items" : "the list"}?
            </p>
          ) : (
            <p className="font_ubuntu color_primary_lt font_weight_md font_18">
              Do you really want to remove{" "}
              <span className="color_primary font_weight_lg">
                {props.itemName}
              </span>{" "}
              from {props.return ? "return items" : "cart"}?
            </p>
          )}

          {props?.action == "clear_cart" && (
            <Button className="modal_dwnlad_csv_btn" onClick={props.download}>
              Download CSV
            </Button>
          )}
        </Modal.Title>
      </Modal.Header>
      {/* <Modal.Body>
       
      </Modal.Body> */}
      <Modal.Footer className="display_flex space_btw">
        <Button
          onClick={() => {
            Common_Event_Hit("DYNAMIC_EVENT", {
              eventName: "negative_tap",
              dialog_name: "delete_cart_product_dialog",
            });
            props.onHide();
          }}
          id="confirm_delete_cancel"
          className="bg_white brdr_grey cancel_btn color_grey font_weight_md"
        >
          CANCEL
        </Button>
        <Button
         id="confirm_delete_product"
          onClick={props.removeFromCart}
          className="bg_secondary brdr_none font_weight_md delete_btn"
        >
          DELETE
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteProductModal;
