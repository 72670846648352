import React, { useContext, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router";
import { checkAuth, refreshToken } from "../../../Actions/Auth";
import { getOnboardingData } from "../../../Actions/Onboarding";
import { usersData } from "../../../App";
import { WSProfileJson } from "../../../Images/json";
import ErrorModal from "../../Modals/ErrorModal";
import LogoutModal from "../../Modals/LogoutModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import "./WSProfile.css";

function WSProfile() {
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const [userData, setUserData] = useState({});
  const [userDocuments, setUserDocuments] = useState([]);
  const navigate = useNavigate();
  const { setShowMaintenance } = useContext(usersData);

  const SECTIONS = {
    businessDetails: {
      name: "Business Details",
      details: {
        businessName: {
          key: "Business Name",
          value: "",
        },
        ownerName: {
          key: "Owner's Name",
          value: "",
        },
        businessEmail: {
          key: "Business Email",
          value: "",
        },
      },
    },
    storeLocation: {
      name: "Store Location",
      details: {
        line1: {
          key: "Address Line 1",
          value: "",
        },
        line2: {
          key: "Address Line 2",
          value: "",
        },
        landmark: {
          key: "Landmark",
          value: "",
        },
        city: {
          key: "City",
          value: "",
        },
        pinCode: {
          key: "Pincode",
          value: "",
        },
        district: {
          key: "District",
          value: "",
        },
        state: {
          key: "State",
          value: "",
        },
      },
    },
    businessDocuments: {
      name: "Business Documents",
      details: {
        scheduleH: {
          key: "Schedule H",
          value: "",
        },
        scheduleX: {
          key: "Schedule X",
          value: "",
        },
        gst: {
          key: "GST",
          value: "",
        },
        pan: {
          key: "PAN",
          value: "",
        },
      },
    },
  };

  const [profileData, setProfileData] = useState(SECTIONS);

  //hit checkAuth api and update the userData in the local storage
  const getUserData = async (mobile) => {
    //build the payload
    let body = {
      mobileNumber: mobile,
    };
    try {
      const res = await checkAuth(body);
      localStorage.setItem("userData", JSON.stringify(res.data.data.user));
      setUserData(res.data.data.user);
    } catch (error) {
      if (error?.response?.status == 401) {
        refreshToken().then((res) => {
          getUserData(mobile);
        });
      } else if (
        error?.response?.status == 502 ||
        error?.response?.status == 503 ||
        error?.response?.status == 504
      ) {
        setShowMaintenance({ status: true, apiData: error?.response });
      } else if (error?.response?.status == 500) {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: "Oops something went wrong !",
          tryAgain: function () {
            getUserData(mobile);
          },
        });
      } else {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getUserData(mobile);
          },
        });
      }
    }
  };

  //api to get the userObject and update the localStorage
  const getUserObject = async () => {
    //donot hit the api when mobileNo is not present
    if (
      !userData?.mobileNo ||
      (userData?.mobileNo && userData?.mobileNo?.length === 0)
    )
      return;
    let params = {
      mobileNo: userData?.mobileNo,
    };
    try {
      const res = await getOnboardingData("getUserDocument", params);
      setUserDocuments(res?.data?.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        refreshToken().then((res) => {
          getUserObject();
        });
      } else if (
        error?.response?.status == 502 ||
        error?.response?.status == 503 ||
        error?.response?.status == 504
      ) {
        setShowMaintenance({ status: true, apiData: error?.response });
      } else if (error?.response?.status == 500) {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: "Oops something went wrong !",
          tryAgain: function () {
            getUserObject();
          },
        });
      } else {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getUserObject();
          },
        });
      }
    }
  };

  //onMount -> update the local storage userData
  useEffect(() => {
    window.scrollTo(0, 0);
    let data = JSON.parse(localStorage.getItem("userData"));
    if (data && "businessType" in data) {
      data.businessType &&
        data.businessType[0] === "PHARMACY" &&
        navigate("/home");
      //get mobile number and hit the checkAuth
      const { mobileNo } = data;
      getUserData(mobileNo);
    } else {
      localStorage.removeItem("userData");
      localStorage.removeItem("userAuth");
      localStorage.removeItem("userObject");
      navigate("/login");
    }
  }, []);

  //get user documents
  useEffect(() => {
    if (Object.keys(userData).length === 0) return;
    getUserObject();
  }, [userData]);

  useEffect(() => {
    // guardian check to return if the objects are empty
    if (
      Object.keys(userData).length === 0 ||
      Object.keys(userDocuments).length === 0
    )
      return;

    let userObject = JSON.parse(localStorage.getItem("userObject"));

    let data = { ...profileData };
    //update the business details
    data.businessDetails.details.businessName.value =
      userData.vshopName ||
      userData.shopName ||
      userObject.businessName ||
      "NA";
    data.businessDetails.details.ownerName.value =
      userData.ownerName || userObject.ownerName || "NA";
    data.businessDetails.details.businessEmail.value =
      userData.vemailId || userData.emailId || userObject.emailId || "NA";
    //update the store location details
    data.storeLocation.details.line1.value =
      userData.vaddress1 ||
      userData.addressLine1 ||
      userObject.addressLine1 ||
      "NA";
    data.storeLocation.details.line2.value =
      userData.vaddress2 ||
      userData.addressLine2 ||
      userObject.addressLine2 ||
      "NA";
    data.storeLocation.details.landmark.value = userObject.landmark || "NA";
    data.storeLocation.details.city.value =
      userData.city || userObject.city || "NA";
    data.storeLocation.details.district.value = userData.district || "NA";
    data.storeLocation.details.state.value =
      userData.vstate || userData.state || "NA";
    data.storeLocation.details.pinCode.value =
      userData.vpinCode || userData.zip || userObject.pincode || "NA";
    //update the business documents
    userDocuments.forEach((item, _) => {
      if (item.documentType === "SCHEDULE_H")
        data.businessDocuments.details.scheduleH.value += ";;;" + item.imageUrl;
      else if (item.documentType === "SCHEDULE_X")
        data.businessDocuments.details.scheduleX.value += ";;;" + item.imageUrl;
      else if (item.documentType === "GST")
        data.businessDocuments.details.gst.value += ";;;" + item.imageUrl;
      else if (item.documentType === "PAN")
        data.businessDocuments.details.pan.value += ";;;" + item.imageUrl;
    });

    //update the profileData state
    setProfileData(data);
  }, [userData, userDocuments]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />

      <div className="more-menu-main-container">
        <div className="more-menu-header-wrapper">
          <div className="ws-menu-nav-container">
            <BsArrowLeft
              size="1.5rem"
              className="ws-nav-back"
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="more-menu-header">
            <div>
              <p>Customer Id</p>
              <p>{userData.retailerCode}</p>
            </div>
            <div>
              <p>Mobile Number</p>
              <p>{userData.mobileNo}</p>
            </div>
          </div>
        </div>
        <div className="ws-profile-body-container">
          <Accordion defaultActiveKey={0}>
            {Object.keys(profileData).map((item1, index) => {
              return (
                <Accordion.Item
                  eventKey={index}
                  className="ws-profile-accordion-item"
                >
                  <Accordion.Header className="ws-profile-accordion-header">
                    <Lottie
                      loop={false}
                      animationData={
                        index === 0
                          ? WSProfileJson.businessDetails
                          : index === 1
                          ? WSProfileJson.storeLocation
                          : WSProfileJson.businessDocuments
                      }
                      style={{
                        width: "7rem",
                        height:
                          index === 2 ? "4rem" : index === 1 ? "8rem" : "7rem",
                      }}
                      play
                    />

                    {profileData[item1].name}
                  </Accordion.Header>
                  <Accordion.Body className="ws-profile-accordion-body">
                    {item1 === "businessDocuments"
                      ? Object.keys(profileData[item1]?.details).map(
                          (item2, index) => {
                            return (
                              <div
                                className="ws-profile-docs-details-sub-section"
                                key={index}
                              >
                                <p>{profileData[item1].details[item2].key}</p>
                                {profileData[item1].details[item2].value
                                  .split(";;;")
                                  .map((image, imgIdx) => {
                                    return image.length > 1 && imgIdx > 0 ? (
                                      <a href={image} target="_blank">
                                        <img
                                          src={image}
                                          alt={item2.key + " image"}
                                          className="ws-profile-doc-image"
                                        />
                                      </a>
                                    ) : (
                                      profileData[item1].details[
                                        item2
                                      ].value.split(";;;").length === 1 && "NA"
                                    );
                                  })}
                              </div>
                            );
                          }
                        )
                      : Object.keys(profileData[item1].details).map(
                          (item2, index) => {
                            return (
                              <div
                                className="ws-profile-details-sub-section"
                                key={index}
                              >
                                <p>{profileData[item1].details[item2].key}</p>
                                <p>{profileData[item1].details[item2].value}</p>
                              </div>
                            );
                          }
                        )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default WSProfile;
