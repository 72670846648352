const WSProfileJson = {
    businessDetails : require('./WSBusinessDetails.json'),
    businessDocuments : require('./WSBusinessDocuments.json'),
    businessDocumentsSecure : require('./WSBusinessDocumentsSecure.json'),
    storeLocation : require('./WSStoreLocation.json'),

}

export {
    WSProfileJson
}