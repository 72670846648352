import React, { useContext, useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { refreshToken } from "../../Actions/Auth";
import { category_widget, common_product_widget } from "../../Actions/Widgets";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import { BsPiggyBank } from "react-icons/bs";
import { IoWalletOutline } from "react-icons/io5";
import { AiOutlineRight } from "react-icons/ai";
import Generic from "../../Images/svg/generic.svg";
import { Common_Event_Hit } from "../../Actions/Events";
import { MY_ACCOUNT_IMAGES } from "../../Images/svg";

const Store = () => {
  let navigate = useNavigate();
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [homeShimmer, setHomeShimmer] = useState(false);
  const [categoryRes, setCategoryRes] = useState();
  const [userData, setUserData] = useState();
  const fetchAccounts = () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    setHomeShimmer(true);
    const obj = category_widget("home/fetchAccountSectionV2", data?.id);

    obj
      .then((res) => {
        setHomeShimmer(false);
        setCategoryRes(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            fetchAccounts();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              fetchAccounts();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              fetchAccounts();
            },
          });
        }
      });
  };

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  const getOnBehalfRetData = (retCode) => {
    common_product_widget("admin/getUserInfoByRetailerCode", {
      retailerCode: retCode,
    })
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getOnBehalfRetData(retCode);
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getOnBehalfRetData(retCode);
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getOnBehalfRetData(retCode);
            },
          });
        }
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("currentScreen", "account_screen");
    localStorage.removeItem("currentScreenBannerData");

    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data) {
      let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");

      if (
        onBehalfRetCode &&
        onBehalfRetCode.length > 0 &&
        onBehalfRetCode !== "undefined" &&
        data?.admin
      ) {
        getOnBehalfRetData(onBehalfRetCode);
      } else {
        setUserData(data);
      }

      data?.id && fetchAccounts();
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <div className="store ">
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <div className="header_store bg_primary header_outer_div">
        <Container className="header_sub display_flex space_btw">
          <div className="font_ubuntu font_weight_md user_data_store">
            <p className="color_secondary">{userData?.retailerCode}</p>
            <p className="color_white font_18">{userData?.vshopName}</p>
            <p className="color_primary_lt2">{userData?.mobileNo}</p>
          </div>
          <div>
            <div className="piggy_bank  font_ubunut font_weight_md color_white display_flex space_btw">
              <div>
                <BsPiggyBank className="piggy_img color_primary_lt2" />
                &nbsp;&nbsp;&nbsp;
              </div>
              <div>
                <span>
                  {" "}
                  ₹ {userData?.totalSavings} <br></br>
                  <span className="color_primary_lt2 font_10">
                    My Savings
                  </span>{" "}
                </span>
              </div>
            </div>
            <div>&nbsp;</div>
            <div
              className="wallet_bank cursor_pointer font_ubunut font_weight_md color_white display_flex space_btw"
              onClick={async () => {
                await Common_Event_Hit("DYNAMIC_EVENT", {
                  eventName: "wallet_history_tap",
                });
                navigate("/store/walletHistory");
              }}
            >
              <div>
                <IoWalletOutline className="piggy_img color_primary_lt2" />
                &nbsp;&nbsp;&nbsp;
              </div>
              <div>
                <span>
                  {" "}
                  ₹ {userData?.creditAmount} <br></br>
                  <span className="color_primary_lt2 font_10">
                    Wallet History
                  </span>{" "}
                </span>
              </div>
              <div className="color_secondary ">
                &nbsp;&nbsp;&nbsp; <AiOutlineRight className="margin_10" />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <br></br>
      <div>
        <Container className="store_account_list">
          {!homeShimmer &&
            categoryRes &&
            categoryRes.map((cat) => {
              if (cat.widgetName === "account_screen") {
                  return (
                    <>
                      {(cat.iconName === "yourOrders" ||
                        cat.iconName === "profile" ||
                        cat.iconName === "yourReturns" ||
                        cat.iconName === "yourBounces" ||
                        cat.iconName === "downloadCsv" ||
                        cat.iconName === "applyCredit" ||
                        cat.iconName === "qna" ||
                        cat.iconName === "reportIssue" ||
                        cat.iconName === 'yourExpiry') && (
                        <div
                          onClick={() => {
                            if (cat.iconName === "yourBounces") {
                              navigate(`/products/yourBounces`);
                            } else if (
                              cat.iconName === "applyCredit" ||
                              cat.iconName === "downloadCsv" ||
                              cat.iconName === 'yourExpiry'
                            ) {
                              navigate(`/${cat.iconName}`);
                            } else if (cat.iconName === "qna") {
                              navigate("/help-support/QandA");
                            } else if (cat.iconName === "reportIssue") {
                              navigate("/help-support/ReportIssue");
                            }else if (cat.iconName === "yourOrders") {
                            navigate("/account/yourOrders?type=live");
                            }  else {
                              navigate(`/account/${cat.iconName}`, {
                                state: {
                                  data: { pageId: cat.sectionName },
                                },
                              });
                            }
                          }}
                        >
                          <div className="account_item bg_white store_account_item display_flex space_btw">
                            {cat.iconName==='yourOrders' && userData?.onlinePaymentEnabled &&   <div className="target_dot_store"></div>}
                            <div>
                              <img
                                className="store_account_icon"
                                src={
                                  cat.iconName === "yourOrders"
                                    ? MY_ACCOUNT_IMAGES.orders
                                    : cat.iconName === "applyCredit"
                                    ? MY_ACCOUNT_IMAGES.credit
                                    : cat.iconName === "profile"
                                    ? MY_ACCOUNT_IMAGES.profile
                                    : cat.iconName === "yourReturns"
                                    ? MY_ACCOUNT_IMAGES.returns
                                    : cat.iconName === "yourBounces"
                                    ? MY_ACCOUNT_IMAGES.expiry
                                    : cat.iconName === "downloadCsv"
                                    ? MY_ACCOUNT_IMAGES.csv
                                    : cat.iconName === "qna"
                                    ? MY_ACCOUNT_IMAGES.qna
                                    : cat.iconName === "reportIssue"
                                    ? MY_ACCOUNT_IMAGES.issue
                                    : cat.iconName === 'yourExpiry' 
                                    ? MY_ACCOUNT_IMAGES.expiry
                                    :''
                                  //  : cat.iconName==="changeLanguage"?
                                  // Lang:''
                                }
                                alt={cat.iconName}
                              />{" "}
                              <span>&nbsp;&nbsp;{cat.sectionName}</span>
                            </div>
                            <div className="display_flex store-new-tag-container">
                              {cat.newSection && (
                                <>
                                  <span
                                    className="new_tag font_ubuntu"
                                    style={{ height: "fit-content" }}
                                  >
                                    NEW
                                  </span>
                                </>
                              )}
                              <AiOutlineRight className="margin_10 color_secondary" />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
              } else if (cat.widgetName === "link_widget") {
                return (
                  <>
                    <div className="your_store_link">
                      <div className="display_flex color_primary  font_weight_md">
                        <img src={Generic} className="store_link_icon" alt="storeIcon" />
                        &nbsp;&nbsp; <span className="font_16">Your Store</span>
                      </div>
                      <span className="color_grey2 font_12 font_weight_lt">
                        {cat.data.shareLink}
                      </span>

                      <div className="display_flex space_around">
                        <a href={cat.data.viewLink} target="_blank" rel="noreferrer">
                          <Button
                            variant="outline-primary"
                            className="edit_btn_return font_ubuntu font_14 link_view_btn"
                          >
                            {/* <BsFillEyeFill/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  View */}
                            View
                          </Button>
                        </a>
                        <Button
                          className="bg_secondary link_view_btn font_14 color_white brdr_none font_ubuntu"
                          onClick={() => copyText(cat.data.shareLink)}
                        >
                          COPY LINK
                        </Button>
                      </div>
                    </div>
                  </>
                );
              }
            })}
        </Container>
      </div>
      {homeShimmer && (
        <>
          <Container>
            <div className="skeletal_div_sm animateShimmer"></div>
          </Container>
          <Container>
            <div className="skeletal_div_sm animateShimmer"></div>
          </Container>
          <Container>
            <div className="skeletal_div_sm animateShimmer"></div>
          </Container>
          <Container>
            <div className="skeletal_div_sm animateShimmer"></div>
          </Container>
        </>
      )}
    </div>
  );
};

export default Store;
