import React, { Fragment, useEffect, useState, useContext } from "react";
import { Container, Spinner } from "react-bootstrap";
import ProductCard2 from "../ProductCard/ProductCard2";
import "../../App.css";
import "../ProductList/ProductList.css";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./categories.css";
import { common_product_widget } from "../../Actions/Widgets";
import InfiniteScroll from "react-infinite-scroll-component";
import { refreshToken } from "../../Actions/Auth";
import NoData from "../../Images/png/no_data.png";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";

const CoronaCategories = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [allProductList, setAllProductList] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [shimmer, setShimmer] = useState(false);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState(false);
  const getAllProducts = (locationChange) => {
    let pageId = location.pathname.split("/")[3];
    let params = {
      supergroupId: 11,
      mappingIds: 95,
      filterCondition: "COVID_ESSENTIALS",
      page: page,
    };
    let apiEndPoint = "home/getMedicinesBySupergroupGroup";

    if (pageId === "mask_glove") {
      params.mappingIds = 95;
    } else if (pageId === "sanatizers") {
      params.mappingIds = 96;
    } else if (pageId === "device_testing_kits") {
      params.mappingIds = 97;
    } else if (pageId === "syrup") {
      params.mappingIds = 98;
    } else if (pageId === "tablets_injections") {
      params.mappingIds = 99;
    } else if (pageId === "immunity_boosters") {
      params.mappingIds = 100;
    }

    if (locationChange) {
      setPage(0);
      params.page = 0;
    }

    page === 0 && setShimmer(true);

    let api = common_product_widget(apiEndPoint, params);
    api
      .then((res) => {
        setShimmer(false);
        let obj =
          allProductList && allProductList.medicinesResponsesList
            ? allProductList
            : { medicinesResponsesList: [] };
        if (locationChange) {
          obj = { medicinesResponsesList: [] };
        }
        if (res.data.data.medicinesResponsesList.length > 0) {
          obj.medicinesResponsesList.push(
            ...res.data.data.medicinesResponsesList
          );
          setDataLength(obj.medicinesResponsesList.length);
        }

        setAllProductList(obj);

        if (
          res.data.data.size > 0 &&
          res.data.data.medicinesResponsesList.length === 0 &&
          page !== 0
        ) {
          fetchMoreData();
        }

        if (
          page === 0 &&
          res.data.data.size > 0 &&
          res.data.data.medicinesResponsesList.length < 6
        ) {
          fetchMoreData();
        }

        if (res.data.data.size === 0) {
          setHasMore(false);
        }
       
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getAllProducts();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getAllProducts();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getAllProducts();
            },
          });
        }
      });
  };

  const fetchMoreData = () => {
    setPage(+page + 1);
  };
  useEffect(() => {
    let userData = localStorage.getItem("userData");

    if (userData) {
      getAllProducts();
    } else {
      navigate("/home");
    }
  }, [page]);

  useEffect(() => {
    localStorage.setItem("currentScreen", "covid_essentials_screen");
    setAllProductList({});
    setHasMore(true);
    setPage(0);
    getAllProducts(true);
  }, [location]);
  return (
    <Fragment>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Container fluid className="productList">
        <Container fluid className="productListHeader font_ubuntu display_flex">
          <HiOutlineArrowNarrowLeft
            className="cursor_pointer goBack"
            onClick={() => navigate("/categories/coronaEssentials")}
          />
          &nbsp;&nbsp;&nbsp;
          <div className="head_specialOffers">
            <HiOutlineArrowNarrowLeft
              className="cursor_pointer goBack mobile_display"
              onClick={() => navigate("/categories/coronaEssentials")}
            />{" "}
            EXPLORE CATEGORIES <br />
            <div className="corona_cat_navbar_mobile">
              <Link
                to="/categories/coronaEssentials/mask_glove"
                className="text_decoration_none cursor_pointer navLink"
              >
                <p
                  className={
                    ("font_14 nowrap",
                    location.pathname ===
                      "/categories/coronaEssentials/mask_glove" &&
                      "activeClass")
                  }
                >
                  Mask and Gloves
                </p>
              </Link>
              <Link
                to="/categories/coronaEssentials/sanatizers"
                className="text_decoration_none cursor_pointer navLink"
              >
                <p
                  className={
                    ("font_14 nowrap",
                    location.pathname ===
                      "/categories/coronaEssentials/sanatizers" &&
                      "activeClass")
                  }
                >
                  Sanitizers
                </p>
              </Link>

              <Link
                to="/categories/coronaEssentials/device_testing_kits"
                className="text_decoration_none cursor_pointer navLink"
              >
                <p
                  className={
                    ("font_14 nowrap",
                    location.pathname ===
                      "/categories/coronaEssentials/device_testing_kits" &&
                      "activeClass")
                  }
                >
                  Devices and Testing Kits
                </p>
              </Link>

              <Link
                to="/categories/coronaEssentials/syrup"
                className="text_decoration_none cursor_pointer navLink"
              >
                <p
                  className={
                    ("font_14 nowrap",
                    location.pathname ===
                      "/categories/coronaEssentials/syrup" && "activeClass")
                  }
                >
                  {" "}
                  Syrups
                </p>
              </Link>
              <Link
                to="/categories/coronaEssentials/tablets_injections"
                className="text_decoration_none cursor_pointer navLink"
              >
                <p
                  className={
                    ("font_14 nowrap",
                    location.pathname ===
                      "/categories/coronaEssentials/tablets_injections" &&
                      "activeClass")
                  }
                >
                  Tablets and Injections
                </p>
              </Link>
              <Link
                to="/categories/coronaEssentials/immunity_boosters"
                className="text_decoration_none cursor_pointer navLink"
              >
                <p
                  className={
                    ("font_14 nowrap",
                    location.pathname ===
                      "/categories/coronaEssentials/immunity_boosters" &&
                      "activeClass")
                  }
                >
                  Immunity Boosters
                </p>
              </Link>
            </div>
          </div>
          <div className="corona_cat_navbar">
            <Link
              to="/categories/coronaEssentials/mask_glove"
              className="text_decoration_none cursor_pointer navLink"
            >
              <p
                className={
                  ("font_14",
                  location.pathname ===
                    "/categories/coronaEssentials/mask_glove" && "activeClass")
                }
              >
                Mask and Gloves
              </p>
            </Link>
            <Link
              to="/categories/coronaEssentials/sanatizers"
              className="text_decoration_none cursor_pointer navLink"
            >
              <p
                className={
                  ("font_14",
                  location.pathname ===
                    "/categories/coronaEssentials/sanatizers" && "activeClass")
                }
              >
                Sanitizers
              </p>
            </Link>

            <Link
              to="/categories/coronaEssentials/device_testing_kits"
              className="text_decoration_none cursor_pointer navLink"
            >
              <p
                className={
                  ("font_14",
                  location.pathname ===
                    "/categories/coronaEssentials/device_testing_kits" &&
                    "activeClass")
                }
              >
                Devices and Testing Kits
              </p>
            </Link>

            <Link
              to="/categories/coronaEssentials/syrup"
              className="text_decoration_none cursor_pointer navLink"
            >
              <p
                className={
                  ("font_14",
                  location.pathname === "/categories/coronaEssentials/syrup" &&
                    "activeClass")
                }
              >
                {" "}
                Syrups
              </p>
            </Link>
            <Link
              to="/categories/coronaEssentials/tablets_injections"
              className="text_decoration_none cursor_pointer navLink"
            >
              <p
                className={
                  ("font_14",
                  location.pathname ===
                    "/categories/coronaEssentials/tablets_injections" &&
                    "activeClass")
                }
              >
                {" "}
                Tablets and Injections
              </p>
            </Link>
            <Link
              to="/categories/coronaEssentials/immunity_boosters"
              className="text_decoration_none cursor_pointer navLink"
            >
              <p
                className={
                  ("font_14",
                  location.pathname ===
                    "/categories/coronaEssentials/immunity_boosters" &&
                    "activeClass")
                }
              >
                {" "}
                Immunity Boosters
              </p>
            </Link>
          </div>
        </Container>
        {shimmer && (
          <Fragment>
            <div className="padding_5">
              <ProductCard2 shimmer={shimmer} />
            </div>
            <div className="padding_5">
              <ProductCard2 shimmer={shimmer} />
            </div>
            <div className="padding_5">
              <ProductCard2 shimmer={shimmer} />
            </div>
            <div className="padding_5">
              <ProductCard2 shimmer={shimmer} />
            </div>
            <div className="padding_5">
              <ProductCard2 shimmer={shimmer} />
            </div>
          </Fragment>
        )}
        {allProductList && allProductList.medicinesResponsesList?.length > 0 ? (
          <div id="scrollableDiv" className="productList" style={{paddingTop: '0rem'}}>
            <InfiniteScroll
              dataLength={dataLength}
              next={fetchMoreData}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
              scrollThreshold="90%"
              className="infiniteScroll"
              loader={
                <Spinner
                  animation="border"
                  className="color_secondary spinner"
                  size="lg"
                />
              }
            >
              {allProductList &&
                allProductList.medicinesResponsesList.map((med, index) => (
                  <ProductCard2
                    position={index}
                    screen={"covid_essentials_screen"}
                    key={index}
                    medData={med}
                  />
                ))}
            </InfiniteScroll>
          </div>
        ) : (
          !shimmer && (
            <div
              id="scrollableDiv"
              className="productList "
              style={{ paddingTop: "50px" }}
            >
              <div className="no_data_parent">
                <img src={NoData} alt="No Data" />
                <br /> <br />
                <p className="font_ubuntu font_16 font_weight_md">
                  No Products Found !
                </p>
              </div>
            </div>
          )
        )}
      </Container>
    </Fragment>
  );
};

export default React.memo(CoronaCategories);
