import zIndex from "@mui/material/styles/zIndex";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineInstagram } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { GrLinkedinOption } from "react-icons/gr";
import { ImFacebook } from "react-icons/im";
import { useLocation } from "react-router";
import { usersData } from "../../../App";
import PlayStore from "../../../Images/png/play_store.png";
import { WHOLESALE_HEADER } from "../../../Images/svg/index.js";
import "./WSFooter.css";

function WSFooter() {
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();
  const { userData } = useContext(usersData);

  useEffect(() => {
    let locationBlocks = location.pathname.split("/");
    if (locationBlocks[1] === "wholesale") {
      if (locationBlocks[2] === "home" || locationBlocks[2] === "product") {
        if (locationBlocks[3] === "orders" || locationBlocks[3] === "cart") {
          setShowHeader(false);
        } else {
          setShowHeader(true);
        }
      } else {
        setShowHeader(false);
      }
    } else {
      setShowHeader(false);
    }
  }, [location]);
  return (
    <>
      {
        <Container
          fluid
          className={userData && showHeader ? "bg_white footer-container" : "bg-hidden"}
          style={{
            // background: bgColor,
            borderTop: "1px solid rgba(128, 128, 128, 0.308)",
          }}
        >
          <Row className="display_flex space_btw footer_parent">
            <Col
              lg={5}
              md={5}
              sm={12}
              xs={12}
              className="font_ubuntu footer_first_sec"
            >
              <p className="font_weight_md ws-footer-color-primary">
                Join the fastest growing digital pharmaceutical distribution
                platform.
              </p>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.saveo.saveomedical"
                  target="_blank"
                >
                  <img src={PlayStore} className="play_store_img" />
                </a>
              </div>
            </Col>

            <Col
              lg={3}
              md={3}
              sm={12}
              xs={12}
              className="font_ubuntu contact_footer"
            >
              <div className="line_height_8">
                <p
                  className="ws-footer-color-primary  font_weight_md"
                  style={{ color: "#517187 !important" }}
                >
                  Email
                </p>
                <a href="mailTo:support@saveo.in">support@saveo.in</a>
              </div>
              <br />
              <div className="line_height_8">
                <p className="ws-footer-color-primary font_weight_md">
                  Contact
                </p>
                <a href="tel:08035018025">08035018025</a>
              </div>
              <br />
              <div className="line_height_8">
                <span>
                  <a
                    href="../privacy-policy.html"
                    target="_blank"
                    className="font_12 text_decoration_none ws-footer-color-primary font_weight_lg"
                  >
                    Privacy Policy
                  </a>
                </span>
                &nbsp; &nbsp; &nbsp;
                <span>
                  <a
                    href="../terms-and-conditions.html"
                    target="_blank"
                    className="font_12 text_decoration_none ws-footer-color-primary font_weight_lg"
                  >
                    Terms and Conditions
                  </a>
                </span>
              </div>
            </Col>

            <Col lg={3} md={3} sm={12} xs={12} className="social_sec">
              <div className="ws-footer-logo-backdrop">
                <img src={WHOLESALE_HEADER.wholesaleLogo} />
              </div>
              <div className="display_flex social_logos">
                <br />
                <a
                  href="https://www.facebook.com/HeySaveo/"
                  target="_blank"
                  className="ws-footer-color-primary ws-footer-icon"
                >
                  <ImFacebook className="ws-footer-color-primary " />
                </a>
                <a
                  href="https://www.instagram.com/heysaveo/"
                  target="_blank"
                  className="ws-footer-color-primary ws-footer-icon"
                >
                  <AiOutlineInstagram />
                </a>
                <a
                  href="https://www.linkedin.com/company/heysaveo"
                  target="_blank"
                  className="ws-footer-color-primary ws-footer-icon"
                >
                  <GrLinkedinOption />
                </a>
                <a
                  href="https://twitter.com/heysaveo"
                  target="_blank"
                  className="ws-footer-color-primary ws-footer-icon"
                >
                  <BsTwitter />
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text_center font_12 ws-footer-color-primary bg_light_blue padding_10">
              Saveo is a trademark of Saveo Healthtech Pvt. Ltd. &copy;2023
              Saveo. All rights reserved.
            </Col>
          </Row>
        </Container>
      }
    </>
  );
}

export default WSFooter;
