import axios from "axios";
import { variables } from "../environments";
import qs from "qs";


let config = {
  headers: {
    "Content-Type": "application/json",
    "source":"pharmacyWeb",
    appVersionCode: variables.appVersionCode,
  },
};
const BASE = variables.ADMIN_URL;

const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);

  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config.headers.Authorization = "Bearer " + token;
  
  if (
    onBehalfRetCode &&
    onBehalfRetCode.length > 0 &&
    onBehalfRetCode !== "undefined" &&
    userData.admin
  ) {
    config.headers.adminRetailerCode = onBehalfRetCode;
    config.headers.source="pharmacyWebOnbehalf"
  }
  return true;
};

export const getPastInvoice = async (params) => {
    const data = getToken().then(() => {
        return axios.get(`${BASE}/home/getPastInvoiceOfRetailer`, {
            params: params,
            headers: config.headers,
        })
    })
    return data;
}

export const downloadBuyerInvoice = (params) => {
    const data = getToken().then(() => {
        return axios.get(`${BASE}/home/downloadBuyerInvoiceForRetailer` , {
            params: params,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
              },
            headers: config.headers,
        })
    })
    return data;
}

export const generateEmailInvoice = async (params) => {
    const data = getToken().then(() => {
        return axios.get(`${BASE}/home/generateEmailOfBuyerInvoice`, {
            params: params,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
              },
            headers: config.headers,
        })
    })
    return data;
}