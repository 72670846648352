import React, { useContext, useEffect } from "react";
import { GiReceiveMoney } from "react-icons/gi";
import SeeAllButton from "../Buttons/SeeAllButton";
import CDimage from "../../../Images/png/CDimage.png";
import { WHOLESALE_IMAGES } from "../../../Images/svg";
import { useNavigate } from "react-router";
import { useServiceWorker } from "../../../useServiceWorker";
import { networkStatusContext } from "../../../App";

function ShopByCD({ data }) {
  const navigate = useNavigate();
  



  const handleSeeAllProducts = (index) => {
    let filter = ''
    if(index === 0){
      filter = '20-100'
    } else if(index === 1){
      filter = '10-20'
    } else if(index === 2){
      filter = '0-10'
    }
    navigate("/wholesale/shopByCashDiscount/"+filter,{state: {data : data?.data?.discountTypes}});
  };

  return (
    <>
      <div className="tsp-main-container ws-cd-main">
        <div className="tsp-header-wrapper">
          <div className="tsp-header font_ubuntu">
            <GiReceiveMoney size="1.5rem" color="#1A4059" />
            <span className="font_weight_md" style={{ fontSize: ".9rem" }}>
              SHOP BY CASH DISCOUNT
            </span>
          </div>
          <SeeAllButton onClick={() => handleSeeAllProducts(0)} />
        </div>
        <div className="ws-cd-card-wrapper">
          {data?.data?.discountTypes?.map((item, index) => {
            return (
              
              <div className="ws-cd-card" key={index} onClick={() => handleSeeAllProducts(index)} style={{cursor: 'pointer'}}>
                <img
                  src={CDimage}
                  alt="cash discount image"
                  className="wholesale-cd-image"
                />

                {Object.keys(item).includes("minDiscount") ? (
                  Object.keys(item).includes("maxDiscount") ? (
                    <div>
                      <span>{item?.minDiscount}%</span>&nbsp;to&nbsp;
                      <span>{item?.maxDiscount}%</span>
                    </div>
                  ) : (
                    <div>
                      More Than&nbsp;<span>{item?.minDiscount}%</span>
                    </div>
                  )
                ) : (
                  <div>
                    Less Than&nbsp;<span>{item?.maxDiscount}%</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ShopByCD;
