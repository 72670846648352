import React, { useContext, useEffect, useState } from "react";
import { addExpiryItem, getExpiryData, placeOrder } from "../../Actions/Expiry";
import { YOUR_EXPIRY_IMAGES } from "../../Images/svg";
import { IoIosAdd } from "react-icons/io";
import { useNavigate } from "react-router";
import AddMedicineForm from "../Modals/AddMedicineForm";
import { Card, ListGroup } from "react-bootstrap";
import OrderMessageModal from "../Modals/orderMessageModal";
import { refreshToken } from "../../Actions/Auth";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import ButtonLoading1 from "../Spinners/ButtonLoading1";
import SuccessModal from "../Modals/SuccessModal";
import { Skeleton } from "@mui/material";

function ExpiryList() {
  const [expiryData, setExpiryData] = useState({});
  const [addMedicineForm, setAddMedicineForm] = useState({
    show: false,
    editForm: false,
    expiryCartResponseList: [],
  });
  const [expiryCartResponseList, setExpiryCartResponseList] = useState([]);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [loading, setLoading] = useState(false)
  const [successModal, setSuccessModal] = useState({show: false, content: ''})
  const [isFetching, setIsFetching] = useState(false)

  //POST delete item api call
  const handleDeleteItem = async (index) => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let body = {
      expiryCartRequestList: [
        {
          batchNumber: expiryCartResponseList[index]?.batchNumber,
          expiryDate: expiryCartResponseList[index]?.expiryDate,
          itemName: expiryCartResponseList[index]?.medicineName,
          medicineId: expiryCartResponseList[index]?.medicineId,
          mrp: parseInt(expiryCartResponseList[index]?.mrp).toFixed(1),
          quantity:0,
          cartFlag: 'DELETE_CART'
        },
      ],
      retailerCode: data.retailerCode,
      retailerId: data.id,
      size: 1,
    };
    try {
      const res = await addExpiryItem({}, body)
      console.log(res)
      //refresh the data
      getRetailerExpiryData()
    } catch (error) {
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          handleDeleteItem();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            handleDeleteItem();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            handleDeleteItem();
          },
        });
      }
    }
  };

  //api call to get the expiry data
  const getRetailerExpiryData = async () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let { retailerCode: retailerId } = data;
    setIsFetching(true)
    try {
      const res = await getExpiryData(retailerId);
      setExpiryData(res?.data?.data);
      setExpiryCartResponseList(res?.data?.data?.expiryCartResponseList);
      setIsFetching(false)
    } catch (error) {
      setIsFetching(false)
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getRetailerExpiryData();
        })
        .catch((error) => {
          if(error?.response?.status === 480)
            getRetailerExpiryData()
        })
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getRetailerExpiryData();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getRetailerExpiryData();
          },
        });
      }
    }
  };

  //update add medicine form modal state
  const handleAddMedicineForm = () => {
    setAddMedicineForm({
      show: true,
      editForm: false,
      expiryCartResponseList: [],
    });
  };

  const handleDetails = () => {
    setInfoMessage({
      show: true,
      content: `Total Expected Return : ₹ ${expiryData?.netValue}`,
      subContent: `Total Original Value  : ₹ ${expiryData?.subTotal}`,
      header: "Price Detail",
    });
  };

  const handleEdit = (index) => {
    setAddMedicineForm({
      show: true,
      editForm: true,
      expiryCartResponseList: expiryCartResponseList[index],
    });
  };

  //request pickup api call
  const handleRequestPickup = async () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let params = {
      retailerCode: data?.retailerCode,
    }

    try {
      //update the button spinner state
      setLoading(true)
      //api call
      const res = await placeOrder(params)
      setSuccessModal({
        show : true,
        content : res.data.data,
      })
      //update the button spinner state
      setLoading(false)
      // reload the expiry data api
      getRetailerExpiryData()
    } catch (error) {
      //update the button spinner state
      setLoading(false)
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          handleRequestPickup();
        })
        .catch((error) => {
          if(error?.response?.status === 480)
            handleRequestPickup()
        })
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            handleRequestPickup();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            handleRequestPickup();
          },
        });
      }
    }
  }

  //onMount
  useEffect(() => {
    //get the initial expiry data
    getRetailerExpiryData();
  }, []);

  useEffect(() => {
    let successModalTimeout;
    if(successModal.show){
      successModalTimeout = setTimeout(() => {
        setSuccessModal({
          show : false,
          content : '',
        })
      },[2000])
    }
  },[successModal.show])
  return (
    <>
     <SuccessModal
        show={successModal.show}
        content={successModal.content}
      />
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        subContent={infoMessage.subContent}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({
            show: false,
            content: "",
            subContent: "",
            header: "",
          });
        }}
      />
      <AddMedicineForm
        show={addMedicineForm.show}
        onHide={() => {
          setAddMedicineForm({
            show: false,
            editForm: false,
            expiryCartResponseList: [],
          });
          //refresh the expiry data
          getRetailerExpiryData()
        }}
        editForm={addMedicineForm.editForm}
        setExpiryCartResponseList={setExpiryCartResponseList}
        expiryCartResponseList={addMedicineForm.expiryCartResponseList}
      />
      <div className="expiry-list-main-container">
        <div className="header-banner flex-align-center-row">
          Remaining Expiry Limit :&nbsp;
          <span style={{ color: "black" }}>
            {expiryData?.availableExpiryLimit}
          </span>
        </div>

        {expiryData?.expiryCartResponseList?.length === 0 && !isFetching &&
          expiryCartResponseList?.length === 0 && (
            <div className="expiry-item-container flex-align-center-column" style={{height:'65vh'}}>
              <img
                src={YOUR_EXPIRY_IMAGES.noOrders}
                className="no-orders-image"
              />
              <h4 className="no-items-text">Empty</h4>
            </div>
          )}

          {isFetching &&
          expiryCartResponseList?.length === 0 && (
            <div className="expiry-item-container flex-align-center-column">
            <Skeleton
                className="expiry-card"
                variant="rectangular"
                sx={{ height: "10rem", bgcolor: "grey.100" }}
              />
              <Skeleton
                className="expiry-card"
                variant="rectangular"
                sx={{ height: "10rem", bgcolor: "grey.100" }}
              />
              <Skeleton
                className="expiry-card"
                variant="rectangular"
                sx={{ height: "10rem", bgcolor: "grey.100" }}
              />
            </div>
          )}

        <div className="expiry-item-container flex-align-center-column">
          {expiryCartResponseList?.map((item, index) => {
            return (
              <>
                <Card
                  className="expiry-card shadow-sm"
                  key={index}
                >
                  <Card.Body>
                    <ListGroup.Item>
                      <div>
                        <p>{item?.itemName}</p>
                        <p>
                          <span className="oreder-list-item-header-text">
                            Expiry
                          </span>{" "}
                          {item?.expiryDate}
                        </p>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className="section2">
                        <p>
                          <span className="oreder-list-item-header-text">
                            Quantity{" "}
                          </span>{" "}
                          {item?.quantity}
                        </p>
                        <p>
                          <span className="oreder-list-item-header-text">
                            MRP{" "}
                          </span>
                          ₹{item?.mrp}
                        </p>
                        <p>
                          <span className="oreder-list-item-header-text">
                            Batch{" "}
                          </span>{" "}
                          {item?.batchNumber}
                        </p>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className="section3">
                        <p>
                          {" "}
                          <span className="oreder-list-item-header-text">
                            {" "}
                            Total
                          </span>{" "}
                          ₹ {item?.subTotal}
                        </p>
                        <div className="expiry-item-button-container">
                          <button onClick={() => handleDeleteItem(index)}>DELETE</button>
                          <button onClick={() => handleEdit(index)}>
                            EDIT
                          </button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  </Card.Body>
                </Card>
              </>
            );
          })}

          {expiryCartResponseList?.length > 0 && (
            <div className="pickup-button-container">
              <ListGroup.Item>
                <p className="oreder-list-item-header-text">
                  {expiryCartResponseList.length} Items
                </p>
                <div className="pickup-section2">
                  <p>
                    Total Expected Return{" "}
                    <span style={{ fontWeight: "bold" }}>
                      ₹ {expiryData?.netValue}
                    </span>
                  </p>
                  <p onClick={handleDetails}>See Details</p>
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <button className="pickup-button" onClick={handleRequestPickup}>{loading ? <ButtonLoading1 /> : 'REQUEST PICKUP'}</button>
              </ListGroup.Item>
            </div>
          )}

          {parseInt(expiryData?.availableExpiryLimit) > 0 && (
            <div className="add-expiry-item-container flex-align-center-row">
              <button onClick={handleAddMedicineForm} type="button">
                <IoIosAdd size="1.5rem" />
                ADD
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ExpiryList;
