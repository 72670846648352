import React, { useEffect, useState, useContext, useRef } from "react";
import Saveo from "../../Images/png/login_saveo_logo.png";
import Store from "../../Images/svg/store.svg";
import Onboard from "../../Images/svg/Onboarding.svg";
import "./Onboarding.css";
import "../../App.css";
import {
  Button,
  Container,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { setOnboardingData } from "../../Actions/Onboarding";
import ErrorModal from "../Modals/ErrorModal";
import { checkAuth, refreshToken } from "../../Actions/Auth";
import { usersData } from "../../App";
import ButtonLoading1 from "../Spinners/ButtonLoading1";

function OnboardingScreen1({
  password,
  confirmPassword,
  handlePassInput,
  handleConfirmPassInput,
  handleNext,
}) {
  const { setShowMaintenance, userData } = useContext(usersData);
  const [passMismatchError, setpassMismatchError] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [showSetPasswordScreen, setShowSetPasswordScreen] = useState(false);
  const { mobileNo } = userData;
  const confirmPasswordRef = useRef();
  const passwordRef = useRef();

  const postPassword = () => {
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    const params = {
      mobileNo: userData.mobileNo,
      password: password,
    };
    setShowSpinner(true);
    setOnboardingData("addUserCredentials", params, {})
      .then((res) => {
        localStorage.setItem("isPasswordSet", JSON.stringify(true));
        console.log(res);
        setShowSpinner(false);
        handleNext();
        const body = {
          mobileNumber: mobileNo,
        };
        checkAuth(body).then((res) => {
          console.log("checkAuth res after setPassword", res);
          localStorage.setItem("userData", JSON.stringify(res.data.data.user));
        });
      })
      .catch((error) => {
        setShowSpinner(false);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            postPassword();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              postPassword();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              postPassword();
            },
          });
        }
      });
  };

  //on key down handler for the mobile number input field
  const handleTabNavigation = (e) => {
    //handle on key down for the input field
    //mac -> tab -> keycode : 9
    if (e.keyCode === 9 && document.activeElement === passwordRef.current) {
      //orevent event bubbling when ref is focussed
      e.preventDefault();
      e.stopPropagation();
      //only focus if the length of the password > 0
      if (e.target.value.length > 0) {
        confirmPasswordRef.current.focus();
        confirmPasswordRef.current.select();
      } else if (e.target.value.length < 10) {
        //handle error handshake when the number is less than 10 charater\s
      }
    }
  };

  const handleEnterClick = (e) => {
    if (
      e.keyCode === 13 &&
      document.activeElement === confirmPasswordRef.current
    ) {
      //prevent event bubbling
      e.preventDefault();
      e.stopPropagation();
      //handle password submit
      if (passMismatchError || confirmPassword.length === 0 || showSpinner) {
        return;
      } else {
        postPassword();
      }
    }
  };

  //onMount
  useEffect(() => {
    let userAuthData = localStorage.getItem("userAuth");
    userAuthData = JSON.parse(userAuthData);
    let isPasswordSet = localStorage.getItem("isPasswordSet");
    isPasswordSet = JSON.parse(isPasswordSet);
    if (userAuthData.data.isPasswordSet || isPasswordSet) {
      setShowSetPasswordScreen(false);
      console.log("before calling handle next in OS1");
      handleNext();
    } else {
      setShowSetPasswordScreen(true);
    }
  }, []);

  //handle password mismatch error
  useEffect(() => {
    !(password === confirmPassword) &&
    !(password.length === 0 || confirmPassword.length === 0)
      ? setpassMismatchError(true)
      : setpassMismatchError(false);
  }, [password, confirmPassword]);

  if (showSetPasswordScreen) {
    return (
      <>
        <ErrorModal
          show={errorModal.show}
          content={errorModal.content}
          apiData={errorModal?.apiData}
          onHide={() => {
            setErrorModal({ show: false, content: "" });
          }}
          tryAgain={() => {
            errorModal?.tryAgain();
            setErrorModal({ show: false, content: "" });
          }}
        />
        {showSetPasswordScreen && (
          <Container fluid className="onboarding">
            <Container fluid className="parentDiv_onboarding">
              <div className="logo-container">
                <img src={Saveo} className="saveoLogo" alt="saveoLogo" />
              </div>
              <div className="onboarding_section_2">
                <img src={Store} className="storeLogo" alt="storeLogo" />
                <img src={Onboard} className="storeLogo2" alt="storeLogo2" />
                <Container className="onboarding_section">
                  <div className="inputs_div">
                    <p className="color_secondary font_weight_md">
                      Secure Your Digital Store
                    </p>
                    <div className="password_div input_password">
                      <span className="password1">Enter Password</span>

                      <span className="password1">8 Characters</span>
                    </div>
                    <InputGroup className="input_password">
                      <FormControl
                        placeholder="Create password (8 characters)"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        type="password"
                        className="padding_10"
                        onChange={handlePassInput}
                        value={password}
                        ref={passwordRef}
                        onKeyDown={handleTabNavigation}
                        autoFocus
                      />
                    </InputGroup>
                    <InputGroup className="input_password">
                      <FormControl
                        placeholder="Re-enter password"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        type="text"
                        className="padding_10"
                        onChange={handleConfirmPassInput}
                        value={confirmPassword}
                        ref={confirmPasswordRef}
                        onKeyDown={handleEnterClick}
                      />
                    </InputGroup>
                    {passMismatchError && (
                      <p className="password-error text-danger">
                        Password incorrect, please try again
                      </p>
                    )}

                    <Button
                      disabled={
                        passMismatchError ||
                        confirmPassword.length === 0 ||
                        showSpinner
                      }
                      className="bg_secondary button border_none "
                      onClick={postPassword}
                    >
                      {showSpinner && (
                        <ButtonLoading1 />
                      )}
                      {!showSpinner && `Start Business`}
                    </Button>
                    <p className="skip-password" onClick={handleNext}>
                      Skip, add later
                    </p>
                  </div>
                </Container>
              </div>
            </Container>
          </Container>
        )}
      </>
    );
  } else {
    return (
      <>
        {
          console.log("handle Next from screen 1 called")
          // handleNext()
        }
      </>
    );
  }
}

export default OnboardingScreen1;
