import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { refreshToken } from "../../Actions/Auth";
import OrderMessageModal from "../Modals/orderMessageModal";
import "../ErrorHandler/ErrorHandler.css";
import { MdOutlineError } from "react-icons/md";
import SessionExpiryModal from "../Modals/SessionExpiry";
import { IoMdRefresh } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
const ErrorHandler = ({ data }) => {
  let navigate = useNavigate();
  const [onBehalfStatus, setOnBehalfStatus] = useState(false);
  const [sessionExp, setSessionExp] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    content: "",
    tryAgain: true,
  });
  const [orderMessage, setOrderMessage] = useState({
    show: false,
    content: "",
    header: "",
  });

  const tryAgain = () => {
    data.fn();
    setToast({ show: false, content: "", tryAgain: false });
  };

  useEffect(() => {
    let onBehalfCode = localStorage.getItem("onBehalfRetCode");
    if (onBehalfCode?.length) {
      setOnBehalfStatus(true);
    } else {
      setOnBehalfStatus(false);
    }
  }, []);

  useEffect(() => {
    let statuses = [];
    if (data.errorList) {
      // combining errors based on status codes
      data.errorList.forEach((error) => {
        let existStatus = statuses.findIndex(
          (stat) =>{
          if(error?.error?.response?.status===401){
            return stat?.status===401
          }else if(error?.error?.response?.status>=400 && error?.error?.response?.status<500){
              return stat?.status>=400 && stat?.status<500
          }else if(error?.error?.response?.status>500){
            return stat?.status>500
          }else{
            return stat?.status === error?.error?.response?.status
          }} 
        );

        if (existStatus < 0) {
          statuses.push({
            codes: [error?.code],
            error: error?.error,
            status: error?.error?.response?.status,
            message: error?.error?.response?.data?.error?.error
              ? [error?.error?.response?.data?.error?.error]
              : [],
          });
        } else {
          error?.response?.data?.error?.error &&
            statuses[existStatus].message.push(
              error?.error?.response?.data?.error?.error
            );
          statuses[existStatus].codes.push(error?.code);
        }
      });
      statuses.forEach((error) => {
        if (error.status === 401) {
          // setSessionExp(true)
          refreshExpiryToken();
          // window.location.reload(true)
        } else if (error.status > 500) {
          setToast({
            show: true,
            content: "Request timed out",
            tryAgain: true,
            codes: error?.codes,
          });
        } else if (error.status === 500) {
          setToast({
            show: true,
            content: "Something went wrong",
            tryAgain: false,
            codes: error?.codes,
          });

          setTimeout(() => {
            setToast({
              show: false,
              content: "",
              tryAgain: false,
              codes: [],
            });
          }, [10000]);
        } else if (error.status >= 400 && error.status < 500) {
          setOrderMessage({
            show: true,
            codes: error?.codes,
            apiData: error?.error?.response,
            content: error?.message?.length
              ? error.message.join(" ")
              : "Bad Request",
            header: `Info`,
          });
        } else if (data.show) {
          setToast({
            show: true,
            content: "Something went wrong",
            tryAgain: true,
            codes: error?.codes,
          });
        }
      });
    }
  }, [data]);

  const refreshExpiryToken = () => {
    refreshToken()
      .then((res) => {
        setSessionExp(false);
        localStorage.removeItem("UPIBanner")
        window.location.reload(true);
      })
      .catch((error) => {
        setSessionExp(false);
        console.log(error);
        localStorage.removeItem("userData");
        localStorage.removeItem("userAuth");
        localStorage.removeItem("refreshTokenStatus");
        navigate("/login");
      });
  };

  return (
    <>
      {/* <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          data.fn();
          setErrorModal({ show: false, content: "" });
        }}
      /> */}
      <SessionExpiryModal
        show={sessionExp}
        refresh={refreshExpiryToken}
        onHide={() => {
          setSessionExp(false);
        }}
      />

      <OrderMessageModal
        show={orderMessage.show}
        content={orderMessage.content}
        header={orderMessage.header}
        codes={orderMessage?.codes}
        onHide={() => {
          setOrderMessage({ show: false, content: "", header: "" });
        }}
      />

      {/* <MaintenanceModal
        show={showMaintenance?.status}
        apiData={showMaintenance?.apiData}
        onHide={() => setShowMaintenance(false)}
      /> */}

      {toast.show && (
        <div className="bottom_toast">
          <div className="toast_div">
            <div className="toast_content">
              <div>
                {" "}
                <MdOutlineError className="error_icon" /> &nbsp;&nbsp;&nbsp;
                {toast.content}
              </div>
              <div className="display_flex">
                {toast.tryAgain && (
                  <OverlayTrigger
                    key={"bottom"}
                    placement={"bottom"}
                    overlay={
                      <Tooltip  id={`tooltip-bottom`}>
                        <strong>Try Again</strong>
                      </Tooltip>
                    }
                  >
                    <div>
                    <IoMdRefresh className="tryAgain_btn" onClick={tryAgain}>
                      Try Again
                    </IoMdRefresh>
                    </div>
                   
                  </OverlayTrigger>

                 
                  
                )}

                {toast.tryAgain && (
                  <div
                    className="cursor_pointer cancel"
                    onClick={() => setToast({ show: false })}
                  >
                    x
                  </div>
                )}
              </div>
            </div>
            <div className="codes">
            <div>
            {toast.codes.join(",")}
            </div>
             
            
             (
              {onBehalfStatus
                ? localStorage.getItem("onBehalfRetCode")
                : JSON.parse(localStorage.getItem("userData"))
                    ?.retailerCode}{" "}
              )
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorHandler;
