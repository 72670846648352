import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { WHOLESALE_HEADER } from "../../../Images/svg";
import "./WSHeader.css";
import { appCategoriesDataContext, networkStatusContext, usersData } from "../../../App";
import ErrorModal from "../../Modals/ErrorModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import { refreshToken } from "../../../Actions/Auth";
import { getCategories } from "../../../Actions/WholeSale/HomePage";
import { useLocation } from "react-router";
import { useServiceWorker } from "../../../useServiceWorker";

function AppCategories(props) {
  // const [appCategoriesData, setAppCategoriesData] = useState([]);
  // const [activeTab, setActiveTab] = useState(0);
  const {appCategoriesData, setAppCategoriesData, activeTab, setActiveTab} = useContext(appCategoriesDataContext)
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const location = useLocation()
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const {networkStatus, showNetworkStatus} = useContext(networkStatusContext);

  //app categories data api
  const getAppCategories = async () => {
    try {
      const res = await getCategories();
      setAppCategoriesData(res?.data?.data);
      //update local Storage key
      localStorage.setItem("medCategory", res?.data?.data[activeTab]?.name);
    } catch (error) {
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getAppCategories();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getAppCategories();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getAppCategories();
          },
        });
      }
    }
  };

  //onMount fetch the app categories
  useEffect(() => {
    // localStorage.setItem('medCategory', 'all')
    // donot 
    if(location.pathname.split('/')[1] === 'wholesale' && location.pathname.split('/')[2] === 'home' ) return
    if(appCategoriesData.length === 0) getAppCategories();

  }, [location, appCategoriesData]);

  //update localStorage key when activeTab changes
  useEffect(() => {
    if (!appCategoriesData) return;
    localStorage.setItem("medCategory", appCategoriesData[activeTab]?.name);
  }, [activeTab]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      <div>
        <Row className={(!networkStatus || showNetworkStatus || isUpdateAvailable) ? "ws-update-present wholesale-sub-header" : 'wholesale-sub-header'}>
          {appCategoriesData?.map((item, index) => {
            return (
              <Col key={index}>
                <div
                  className="tab-content flex-align-center-column"
                  onClick={() => {setActiveTab(index); props.setHasCategoryChanged((val) => !val)}}
                  style={{ cursor: "pointer" }}
                >
                  {item.iconUrl && (
                    <img
                      src={item?.iconUrl}
                      alt={item?.name}
                      className="tab-logo"
                    />
                  )}
                  {!item.iconUrl && (
                    <span
                      style={
                        item?.name == "All"
                          ? {
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                              marginTop: ".2rem",
                            }
                          : {}
                      }
                    >
                      {item?.name}
                    </span>
                  )}
                  {item?.name === "All" ? (
                    <span style={{ minHeight: ".5rem" }}></span>
                  ) : (
                    <span>{item?.name}</span>
                  )}

                  <div
                    className={
                      activeTab === index
                        ? "tab-active flex-align-center-row"
                        : "tab flex-align-center-row"
                    }
                  ></div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
}

export default AppCategories;
