import { Modal, Button } from "react-bootstrap";
import React from "react";
import "../../App.css";
import "./dialog.css";
import SessionImage from "../../Images/png/sessionExpiry.png";

const SessionExpiryModal = (props) => {

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className="del_modal"
      
    >
      <Modal.Header  style={{}}>
          <div style={{width:'100%',textAlign:'center',backgroundColor:'#F7FAFC',margin:'0px',padding:'10px'}} >
           <div>
            <img src={SessionImage} width="100" alt="Session Expiry"/>
           </div>
          <div className="sessionTitle">Your session has expired</div> 
           <div className="sessionSubTitle">Please refresh the page</div>
           <div>
           <Button variant="primary" className="refreshBtn" onClick={props.refresh}>Refresh</Button>
           </div>
          </div>
          
      </Modal.Header>
      
    </Modal>
  );
};

export default SessionExpiryModal;
