import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./ProductCard.css";
import "../../App.css";
const CompanyCard = ({
  getProductListHeader,
  manufacturer,
  scheme,
  shimmer,
}) => {
  useEffect(() => {}, [manufacturer]);
  return (
    <>
      {shimmer ? (
        <div className="CompanyCard color_primary font_weight_md font_ubuntu nowrap animateShimmer2" style={{width:'120px'}}>
        </div>
      ) : (
        <Link
          to={{
            pathname: `/companies/${scheme}/${
              manufacturer && manufacturer.manufacturer
            }`,
          }}
          className="text_decoration_none"
        >
          <div className="CompanyCard color_primary font_weight_md font_ubuntu nowrap">
            {manufacturer && manufacturer.manufacturer.split(" ")[0]}
          </div>
        </Link>
      )}
    </>
  );
};

export default React.memo(CompanyCard);
