import { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import "../Cart/CartToolBar.css";
import { ImPlus, ImMinus } from "react-icons/im";
import { MdDelete } from "react-icons/md";
import { AiFillCloseSquare } from "react-icons/ai";
import DeleteProductModal from "../Modals/DeleteProductModal";
import { Common_Event_Hit } from "../../Actions/Events";
import { Cart_Actions } from "../../Actions/Cart";
import { cartDataContext } from "../../App";
import { refreshToken } from "../../Actions/Auth";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import ErrorModal from "../Modals/ErrorModal";

const ProductCard6 = ({ cartMed, userData }) => {
  const { setShowMaintenance } = userData;
  const [cartSpinner, setCartSpinner] = useState(false);
  const { cartData, setCartData } = useContext(cartDataContext);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [quantity, setQuantity] = useState(cartMed.quantity);
  const [error, setError] = useState(false);
  const [errorTimer, setErrorTimer] = useState(false);

  const addQuantity = (e) => {
    e.stopPropagation();
    setCartSpinner(true);
    let qty;
    cartMed.multiplyFactorForOrderQuantity > 0
      ? (qty = quantity + cartMed.multiplyFactorForOrderQuantity)
      : (qty = quantity + 1);

    setQuantity(qty);
    editCart(qty);
  };

  const minusQuantity = (e) => {
    e.stopPropagation();
    setCartSpinner(true);
    let qty;
    cartMed.multiplyFactorForOrderQuantity > 0
      ? (qty = quantity - cartMed.multiplyFactorForOrderQuantity)
      : (qty = quantity - 1);

    qty > -1 && setQuantity(qty);
    editCart(qty);
  };

  const editCart = (updatedQty) => {
    if (+updatedQty > 0) {
      let condition1 = true;

      if (
        cartMed.maxOrderQuantity > 0 &&
        +updatedQty > cartMed.maxOrderQuantity
      ) {
        condition1 = false;
        setError(`Maximum Order Quantity ${cartMed.maxOrderQuantity}`);
        setCartSpinner(false);
        setErrorTimer(true);
      }
      let condition2 = true;
      if (
        cartMed.minOrderQuantity > 0 &&
        +updatedQty > 0 &&
        +updatedQty < cartMed.minOrderQuantity
      ) {
        condition2 = false;
        setError(`Minimum Order Quantity ${cartMed.minOrderQuantity}`);
        setCartSpinner(false);
        setErrorTimer(true);
      }

      let condition3 = true;
      if (
        cartMed.multiplyFactorForOrderQuantity > 0 &&
        +updatedQty % cartMed.multiplyFactorForOrderQuantity !== 0
      ) {
        condition3 = false;
        setError(
          `Quantity Should be multiple of ${cartMed.multiplyFactorForOrderQuantity}`
        );
        setCartSpinner(false);
        setErrorTimer(true);
      }

      if (condition1 && condition2 && condition3) {
        const params = {};
        const body = {
          androidMap: {
            locale: "en",
            source: "pharmacyWeb",
            deviceId: null,
            appVersion: navigator.userAgent,
            deviceName: null,
            screen_name: "cart_screen",
            appVersionCode: "156",
          },
          medicineId: cartMed.medicineId,
          quantity: +updatedQty,
          retailerCode: userData.retailerCode,
          retailerId: userData.id,
          selectedSlotName: cartMed.selectedSlotName,
        };
        Cart_Actions("EDIT_CART", params, body)
          .then((res) => {
            setCartSpinner(false);
            setCartData(res.data.data);
          })
          .catch((error) => {
            setQuantity(cartMed.quantity);
            setCartSpinner(false);
            if (error?.response?.status === 401) {
              refreshToken().then((res) => {
                editCart(updatedQty);
              });
            } else if (
              error?.response?.status === 502 ||
              error?.response?.status === 503 ||
              error?.response?.status === 504
            ) {
              setShowMaintenance({ status: true, apiData: error?.response });
            } else if (error?.response?.status === 500) {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: "Oops something went wrong !",
                tryAgain: function () {
                  editCart(updatedQty);
                },
              });
            } else {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: error?.response?.data?.error?.error
                  ? error.response.data.error.error
                  : "Something went wrong!",
                tryAgain: function () {
                  editCart(updatedQty);
                },
              });
            }
          });
      } else {
        setQuantity(cartMed.quantity);
      }
    } else if (updatedQty.toString().length && +updatedQty < 1) {
      setShowDeleteModal(true);
      setQuantity(cartMed.quantity);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const editCartOptimised = useCallback(debounce(editCart), []);

  const removeFromCart = () => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "delete_from_cart",
      medicineId: cartMed.medicineId,
    });
    setShowDeleteModal(false);
    const params = {};
    const body = {
      androidMap: {},
      medicineId: cartMed.medicineId,
      retailerCode: userData.retailerCode,
      retailerId: userData.id,
    };

    Cart_Actions("REMOVE_FROM_CART", params, body)
      .then((res) => {
        setCartSpinner(false);
        setCartData(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            removeFromCart();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              removeFromCart();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error,
            tryAgain: function () {
              removeFromCart();
            },
          });
        }
      });
  };

  // Cancel Delete
  const cancelDelete = () => {
    setCartSpinner(false);
    setShowDeleteModal(false);
  };

  useEffect(() => {
    setQuantity(cartMed.quantity);
  }, [cartMed]);

  useEffect(() => {
    setTimeout(() => {
      setErrorTimer(false);
    }, 8000);
  }, [errorTimer]);
  return (
    <div className="r_cart_card">
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal.apiData}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
      />

      <DeleteProductModal
        show={showDeleteModal}
        itemName={cartMed?.medicineName}
        removeFromCart={removeFromCart}
        onHide={cancelDelete}
      />

      <div className="rc_card_top display_flex space_btw">
        <OverlayTrigger
          placement={"top"}
          overlay={
            <Tooltip id={`tooltip-bottom`}>{cartMed.medicineName}</Tooltip>
          }
        >
          <h6>{cartMed.medicineName}</h6>
        </OverlayTrigger>

        <p>
          <span>PTR</span>₹ {cartMed.ptr}
        </p>
      </div>

      <p className="">{cartMed.packaging}</p>

      <div className="rc_card_bottom  display_flex space_btw">
        <div className="r_cart_margin p_8">
          ₹ {cartMed.actualPrice.toFixed(2)} <span>TOTAL</span>
        </div>

        <div className="rc_card_actions display_flex space_btw p_8">
          <MdDelete
            className="rc_delete_btn"
            onClick={(e) => {
              e.stopPropagation();
              setCartSpinner(true);
              setShowDeleteModal(true);
            }}
          />

          <div
            className="product_add_btn"
            onClick={(e) => {
              minusQuantity(e);
            }}
          >
            <ImMinus />
          </div>

          <div className="qty font_ubuntu" style={{ textAlign: "center" }}>
            {cartSpinner ? (
              <Spinner
                animation="border"
                className="color_secondary spinner"
                size="sm"
                style={{ marginLeft: "-2px" }}
              />
            ) : (
              quantity
            )}
          </div>

          <div
            className="product_add_btn"
            onClick={(e) => {
              addQuantity(e);
            }}
          >
            <ImPlus />
          </div>
        </div>
      </div>

      {errorTimer && (
        <div className="cart_tb_error">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default ProductCard6;
