import { Modal, Button, Spinner } from "react-bootstrap";
import React, { useEffect, useState, useContext } from "react";
import { Dropdown } from "react-bootstrap";
import "../../App.css";
import "./dialog.css";
import "./OnBehalf.css";
import {
  getRetailerCodesOnBehalf,
  trackVisitType,
} from "../../Actions/OnBehalf";
import { Common_Event_Hit } from "../../Actions/Events";
import { usersData } from "../../App";
import { refreshToken } from "../../Actions/Auth";
import ErrorModal from "./ErrorModal";
import OrderMessageModal from "./orderMessageModal";

const OnBehalf = (props) => {
  const [retailerCode, inputRetailerCode] = useState("");
  const [shopName, setShopName] = useState();
  const [retailList, setRetailList] = useState([]);
  const [showRetailList, setShowRetailList] = useState(false);
  const [localRetCode, setLocalRetCode] = useState();
  const [coordinates, setCoordinates] = useState();
  const [visitType, setVisitType] = useState();
  const [confirmPhn, setConfirmPhn] = useState();
  const [error, setError] = useState({ type: [] });
  const [locationProgress, setLocationProgress] = useState();
  const { setShowMaintenance } = useContext(usersData);
  const { setErrorHandler } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [orderMessage, setOrderMessage] = useState({
    show: false,
    content: "",
    header: "",
  });
  const [loader, setLoader] = useState();
  const setRetailerCode = async () => {
   
    if (!coordinates?.cords) {
      getLocation();
    }
    if (!visitType) {
      let type = error.type;
      type.push("visitType");
      setError({ type });
    }
    if (!confirmPhn) {
      let type = error.type;
      type.push("confirmPhn");
      setError({ type });
    }

    if (coordinates?.errCode == 1) {
      let type = error.type;
      type.push("location");
      setError({ type });
    }


    setTimeout(() => {
      setError({ type: [] });
    }, 2000);

    if (!error.type?.length && coordinates?.cords) {
      let userData = JSON.parse(localStorage.getItem("userData"));
      let body = {
        action: "SALES_ORDERS_LOG",
        retailerCode: retailerCode,
        adminRetailerCode: userData?.retailerCode,
        visitType: visitType,
        latitude: coordinates?.cords?.lat,
        longitude: coordinates?.cords?.lng,
        name: userData?.shopName || '',
        phone: userData?.vmobile || userData?.mobileNo,
        shopName: shopName || '',
        source: "pharmacyWeb",
      };
      setLoader(true);
      trackVisitType(body)
        .then((res) => {
          setLoader(false);
          localStorage.setItem("onBehalfRetCode", retailerCode);
          props.onHide();
          window.location.reload();
        })
        .catch((error) => {
          setLoader(false);
          if (error?.response?.status === 401) {
            refreshToken().then((res) => {
              setRetailerCode();
            });
          } else if (
            error?.response?.status >= 400 &&
            error?.response?.status < 500
          ) {
            setOrderMessage({
              show: true,
              apiData: error?.response,
              content: error?.response?.data,
              header: `Info`,
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance(true);
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              content: "Oops something went wrong !",
              tryAgain: function () {
                setRetailerCode();
              },
            });
          } else {
            setErrorModal({
              show: true,
              content: error?.response?.data?.message
                ? error.response.data?.message
                : "Something went wrong!",
              tryAgain: function () {
                setRetailerCode();
              },
            });
          }
        });
    }
  };

  const removeRetCode = () => {
    localStorage.removeItem("onBehalfRetCode");
    localStorage.removeItem("enableStatus");
    props.onHide();
    window.location.reload();
  };

  const searchRetailer = async (search) => {
    inputRetailerCode(search.toUpperCase());
    if (search.length > 3) {
      const params = { searchKeyword: search };
      const res = await getRetailerCodesOnBehalf(params);

      if (res?.data?.data && res.data.data.length > 0) {
        setRetailList(res.data.data);
        setShowRetailList(true);
      } else if (res?.data?.data && res.data.data.length === 0) {
        setShowRetailList(false);
        setRetailList([]);
      }
    } else {
      if (showRetailList) {
        setShowRetailList(false);
        setRetailList([]);
      }
    }
  };

  const selectRetailer = async (retCode) => {
    setCoordinates();
    await Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "onBehalf_ret_select",
      screen_name: localStorage.getItem("currentScreen"),
    });
    setShopName(retCode.shopName);
    inputRetailerCode(retCode.retailerCode);
    setShowRetailList(false);
    setRetailList([]);
    getLocation();
    localStorage.setItem("enableStatus",retCode?.enabled)
  };

  

  const getLocation = () => {
    //get the coords from the user and update the respective state
    setLocationProgress({ status: "in_progress", progress: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let obj = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCoordinates({ cords: obj, errCode: null });
          setLocationProgress({ status: "finished", progress: false });
        },
        (err) => {
          setCoordinates({ cords: null, errCode: err.code });
          setLocationProgress({ status: "finished", progress: false });
          console.log("finished");
        }
      );
    }
  };

  const cofirmMobile = (e) => {
    setConfirmPhn(e.target.checked);
  };

  const visitTypeAction = (type) => {
    setVisitType(type);
  };



  useEffect(() => {
    let retCode = localStorage.getItem("onBehalfRetCode");
    setLocalRetCode(retCode);
    retCode &&
      retCode.length > 0 &&
      retCode !== "undefined" &&
      inputRetailerCode(retCode);
  }, []);

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="del_modal"
    >
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={orderMessage.show}
        content={orderMessage.content}
        header={orderMessage.header}
        onHide={() => {
          setOrderMessage({ show: false, content: "", header: "" });
        }}
      />
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="margin_auto text_center"
        >
          <p className="font_ubuntu color_primary font_weight_md font_18">
            {props.content}
          </p>

          <input
            placeholder="Enter RetCode"
            value={retailerCode}
            onChange={(e) => searchRetailer(e.target.value)}
            type="text"
            className="reasonInput"
          />

          <div className="onBehalf_ret_list">
            <Dropdown.Menu show={showRetailList}>
              {retailList.map((retCode, indx) => {
                return (
                  <div
                    class="dropdown_item"
                    key={indx}
                    onClick={() => selectRetailer(retCode)}
                  >
                    {retCode.retailerCode}
                    <span>{retCode.shopName}</span>
                  </div>
                );
              })}
            </Dropdown.Menu>
          </div>

          <div className="confirmNmbr">
            <p
              className={
                confirmPhn ? "confirmLabel" : "confirmLabel color_warn"
              }
            >
              Confirm Salesman Mobile No &#x2a;
            </p>
            <input
              onChange={cofirmMobile}
              className={
                error?.type?.includes("confirmPhn")
                  ? "errorAnime form-check-input cursor_pointer"
                  : "form-check-input cursor_pointer"
              }
              type="checkbox"
              id="confirmMobile"
            />
            <label className="font_18 cursor_pointer" for="confirmMobile">
              &nbsp; &nbsp; &nbsp;
              {JSON.parse(localStorage.getItem("userData"))?.vmobile ||
                JSON.parse(localStorage.getItem("userData"))?.mobileNo}
            </label>
          </div>
          <div className="vistiDiv">
            <div
              className={
                visitType === "Physical"
                  ? "activeVisit phyVisit"
                  : error?.type?.includes("visitType")
                  ? "errorAnime phyVisit"
                  : "phyVisit"
              }
              onClick={() => visitTypeAction("Physical")}
            >
              Physical Visit
            </div>
            <div style={{ width: "50px" }}></div>
            <div
              className={
                visitType === "Phone"
                  ? "activeVisit phnVisit"
                  : error?.type?.includes("visitType")
                  ? "errorAnime phnVisit"
                  : "phnVisit"
              }
              onClick={() => visitTypeAction("Phone")}
            >
              Phone Visit
            </div>
          </div>

          {error?.type?.includes("location") && !locationProgress?.progress && (
            <p className="errorAnime color_warn margin_10px font_16 ">
              Please enable location access
            </p>
          )}

        

          {locationProgress?.status === "in_progress" && (
            <p className="errorAnime color_secondary  margin_10px font_16 ">
              Fetching geolocation ...
            </p>
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Footer className="display_flex space_btw onbehalf_modal_footer">
        {localRetCode &&
        localRetCode.length > 0 &&
        localRetCode !== "undefined" ? (
          <Button
            onClick={removeRetCode}
            className="bg_white remove_btn   font_weight_md"
          >
            REMOVE
          </Button>
        ) : (
          <Button
            onClick={props.onHide}
            className="bg_white brdr_grey cancel_btn color_grey font_weight_md"
          >
            CANCEL
          </Button>
        )}
        <Button
          disabled={
            retailerCode.length < 1 ||
            locationProgress?.progress ||
            loader === true
          }
          onClick={setRetailerCode}
          className="bg_secondary brdr_none font_weight_md delete_btn"
        >
          {loader ? (
           'Loading...'
          ) : (
            "SUBMIT"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OnBehalf;
