import axios from "axios";
import { variables } from "../environments";

let config = {
  headers: {
    "Content-Type": "application/json",
    "source":"pharmacyWeb",
    appVersionCode: variables.appVersionCode,
  },
};
const ADMIN_URL = variables.ADMIN_URL;

const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);
  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config.headers.Authorization = "Bearer " + token;
  if (
    onBehalfRetCode &&
    onBehalfRetCode.length > 0 &&
    onBehalfRetCode !== "undefined" &&
    userData.admin
  ) {
    config.headers.adminRetailerCode = onBehalfRetCode;
    config.headers.source="pharmacyWebOnbehalf"
  }
  return true;
};

export const getData = async (type, params, body) => {
  const widgets = getToken().then(() => {
    switch (type) {
      case "PRODUCT_BY_ID":
        return axios.get(`${ADMIN_URL}/home/v2/getMedicinePage`, {
          params: params,
          headers: config.headers,
        });

      case "PRODUCTPRICE_BY_ID":
        return axios.get(`${ADMIN_URL}/home/v3/FetchProductPrice`, {
          params: params,
          headers: config.headers,
        });

      case "PRODUCTS_BY_COMPANIES":
        return axios.get(`${ADMIN_URL}/home/company/viewMedicinesPaginated`, {
          params: params,
          headers: config.headers,
        });

      case "PRODUCTS_BY_COMPANIES_REGULARSCHEMES":
        return axios.get(
          `${ADMIN_URL}/home/v2/company/getRegularSchemeMedicinesByCompany`,
          {
            params: params,
            headers: config.headers,
          }
        );
      case "PRODUCTS_BY_COMPANIES_CATEGORY":
        return axios.get(
          `${ADMIN_URL}/home/v2/company/getMedicinesByCategoryAndCompany`,
          {
            params: params,
            headers: config.headers,
          }
        );
      case "PRODUCTS_BY_SEARCH":
        config.headers.Accept = "application/json, text/plain, */*";
        config.headers["Content-Type"] = "application/json;charset=utf8";
        return axios.post(`${ADMIN_URL}/home/search/medicine`, body, {
          params: params,
          headers: config.headers,
        });
      case "REQUEST_PRODUCT":
        return axios.post(`${ADMIN_URL}/home/medicine/request`, body, {
          params: params,
          headers: config.headers,
        });
    }
  });
  return widgets;
};
