import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import ProductCard2 from "../ProductCard/ProductCard2";
import "./ProductList.css";
import "../../App.css";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate, useLocation } from "react-router-dom";
import NoData from "../../Images/png/no_data.png";
import { usersData } from "../../App";
import { navWidget } from "./SharedNavWidget";
import InfiniteScroll from "react-infinite-scroll-component";
import { refreshToken } from "../../Actions/Auth";
import ErrorModal from "../Modals/ErrorModal";

const SharedProductList = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  let navPath = location.pathname.split("/");
  let obj = null;
  const [productRes, setProductRes] = useState(obj);
  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const { userData } = useContext(usersData);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState(false);
  const [homeShimmer, setHomerShimmer] = useState(false);
  const [screenName, setScreenName] = useState(null);
  const [subHead, setSubHead] = useState(null);

  let fetchMoreData = () => {
    setPage(+page + 1);
  };

  const getData = async () => {
    let selectedObj = navWidget.filter((item) => {
      return item.pageId === navPath[2];
    })[0];
    let api = selectedObj.api;
    let apiService = selectedObj.apiService;
    let params = {};
    setScreenName(selectedObj.screenName);

    if (selectedObj.pageId === "substitute") {
      params.medicineId = queryParams.get("medId");
      setSubHead(selectedObj.subHeading + queryParams.get("medName").split("%20").join(" "));
    } else if (selectedObj.pageId === "composition") {
      params.compositionId = queryParams.get("compId");
      params.page = page;
      setSubHead(queryParams.get("compHead").split("%20").join(" "));
    }

    try {
      let res = await apiService(api, params);
      setHomerShimmer(false);
      let obj =
        productRes && productRes.medicinesResponsesList
          ? productRes
          : { medicinesResponsesList: [] };
      if (
        res?.data?.data?.medicinesResponsesList !== null &&
        res?.data?.data?.medicinesResponsesList.length > 0
      ) {
        obj.medicinesResponsesList.push(
          ...res.data.data.medicinesResponsesList
        );
        setDataLength(obj.medicinesResponsesList.length);
      }

      setProductRes(obj);
      if (
        selectedObj.pageId !== "substitute" &&
        res.data.data.size > 0 &&
        res.data.data.medicinesResponsesList.length === 0 &&
        page !== 0
      ) {
        fetchMoreData();
      }

      if (
        selectedObj.pageId !== "substitute" &&
        page === 0 &&
        res.data.data.size > 0 &&
        res.data.data.medicinesResponsesList.length < 7
      ) {
        fetchMoreData();
      }

      if (selectedObj.pageId !== "substitute" && res.data.data.size === 0) {
        setHasMore(false);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getData();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance({ status: true, apiData: error?.response });
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: "Oops something went wrong !",
          tryAgain: function () {
            getData();
          },
        });
      } else {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getData();
          },
        });
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setHomerShimmer(true);
    localStorage.setItem("currentScreen", "see_all_screen");
    localStorage.removeItem("currentScreenBannerData");

    let userData = localStorage.getItem("userData");

    if (userData && userData.length > 0) {
      // getData();
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    navWidget && getData();
  }, [page]);
  return (
    <Fragment>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />

      <Container fluid className="productList_cont">
        <Container fluid className="productListHeader font_ubuntu">
          <HiOutlineArrowNarrowLeft
            className="cursor_pointer goBack"
            onClick={() => navigate(-1)}
          />
          &nbsp;&nbsp;&nbsp;
          {screenName}
          {subHead && 
          <OverlayTrigger placement={"bottom"} overlay={<Tooltip id={`tooltip-bottom`}>{subHead}</Tooltip>}>
            <span className="sub_head">{subHead}</span>
          </OverlayTrigger>}
        </Container>

        {!homeShimmer &&
          productRes &&
          productRes.medicinesResponsesList.length > 0 && (
            <div
              id="scrollableDiv"
              className="productListDiv"
              style={{ paddingTop: "0rem" }}
            >
              <InfiniteScroll
                dataLength={dataLength}
                next={navPath[2] !== 'substitute' && fetchMoreData}
                hasMore={navPath[2] !== 'substitute' ? hasMore : false}
                scrollableTarget="scrollableDiv"
                scrollThreshold="90%"
                className="infiniteScroll"
                loader={
                  <Spinner
                    animation="border"
                    className="color_secondary spinner"
                    size="lg"
                  />
                }
              >
                {productRes &&
                  productRes.medicinesResponsesList.map((med, index) => (
                    <ProductCard2
                      position={index}
                      screen={location?.state?.data?.pageId}
                      key={index}
                      medData={med}
                    />
                  ))}
              </InfiniteScroll>
            </div>
          )}

        {!homeShimmer &&
          productRes &&
          productRes.medicinesResponsesList.length === 0 && (
            <div
              id="scrollableDiv"
              className="productList "
              style={{ paddingTop: "50px" }}
            >
              <div className="no_data_parent">
                <img src={NoData} alt="No Data" />
                <br /> <br />
                <p className="font_ubuntu font_16 font_weight_md">
                  No Products Found !
                </p>
              </div>
            </div>
          )}
        {homeShimmer && (
          <div className="productList">
            {[...Array(5)].map((_, index) => {
              return (
                <div className="padding_5" key={index}>
                  <ProductCard2 shimmer={homeShimmer} />
                </div>
              );
            })}
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default React.memo(SharedProductList);
