import React from "react";
import { MdNavigateNext, MdOutlineNavigateNext } from "react-icons/md";
import './Buttons.css';

function SeeAllButton(props) {
  return (
    <div className="see-all-icon" onClick={props.onClick}>
      <MdOutlineNavigateNext color="#9099AB" size='1.4rem' />
    </div>
  );
}

export default SeeAllButton;
