import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import "../Header/Header.css";
import Logo from "../../Images/svg/logo.svg";
import Name from "../../Images/svg/brandName.svg";
import {
  Row,
  Col,
  Container,
  Tooltip,
  Popover,
  Dropdown,
  OverlayTrigger,
  Card,
  Spinner,
} from "react-bootstrap";
import "../../App.css";
import {
  BiSearch,
  BiChevronDown,
  BiChevronUp,
  BiCategory,
  BiChevronRight,
} from "react-icons/bi";
import { GiSevenPointedStar } from "react-icons/gi";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { SiWechat } from "react-icons/si";
import { IoMdCart, IoIosWifi, IoMdNotifications } from "react-icons/io";
import { IoStorefront, IoStorefrontOutline } from "react-icons/io5";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { AiFillSetting } from "react-icons/ai";
import {
  cartDataContext,
  searchContext,
  networkStatusContext,
  usersData,
} from "../../App";
import { AiOutlineHome, AiFillHome, AiOutlineSearch } from "react-icons/ai";
import {
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdDelete,
  MdLocalOffer,
  MdNavigateNext,
  MdOutlineLocalOffer,
} from "react-icons/md";
import { FaUserShield } from "react-icons/fa";
import { RiUpload2Fill } from "react-icons/ri";
import OnBehalf from "../Modals/OnBehalf";
import { Common_Event_Hit } from "../../Actions/Events";
import ErrorModal from "../Modals/ErrorModal";
import UploadToCartModal from "../Modals/UploadToCartModal";
import SearchBarComponent from "./SearchBarComponent";
import { useServiceWorker } from "../../useServiceWorker";
import cx from "classnames";
import Lottie from "react-lottie-player";
import xmas_logo from "../../Images/json/xmas_logo.json";
import { getScreen } from "../../Actions/Screens";
import { refreshToken } from "../../Actions/Auth";
import { common_product_widget } from "../../Actions/Widgets";
import { GrHistory } from "react-icons/gr";
import { getData } from "../../Actions/Product";
import { Cart_Actions } from "../../Actions/Cart";
import ButtonLoading1 from "../Spinners/ButtonLoading1";
import NotificationsComponent from "./NotificationsComponent";
import { BsWhatsapp } from "react-icons/bs";
import { FiChevronRight } from "react-icons/fi";
import wtsp_qr from "../../Images/svg/Saveo_Whatsapp.svg";
import whatspp_logo from "../../Images/svg/wtsapp_logo.svg";

const Header = ({ getSearchString, userLoggedIn, categories }) => {
  const [showCategories, setShowCategories] = useState(false);
  const [showOffers, setShowOffers] = useState(false);
  const [showHelp, setShowHelp] = useState(false); //change this back to false
  const [searchString, setSearchString] = useState("");
  const [cartShake, setCartShake] = useState(false);
  const [userData, setUserData] = useState();
  const { cartData, setCartData } = useContext(cartDataContext);
  const [showHeader, setShowHeader] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("");
  const [onBehalf, setShowOnBehalf] = useState(false);
  const [onBehalfExist, setOnBehalfExist] = useState(false);
  const { showRecent, setShowRecent } = useContext(searchContext);
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const [uploadToCart, setUploadToCart] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  let navigate = useNavigate();
  let location = useLocation();
  const activeItemRef = useRef();
  const searchRef = useRef();
  const quantityInputRef = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const [widgetData, setWidgetData] = useState([]);
  const [promisesData, setPromisesData] = useState([]);
  const [promises, setPromises] = useState([]);
  const [searchProducts, setSearchProducts] = useState([]);
  const [showRecentShimmer, setShowRecentShimmer] = useState(false);
  const [showProductsShimmer, setShowProductsShimmer] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const [secondaryState, setSecondaryState] = useState(false);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [isRemovingFromCart, setIsRemovingFromCart] = useState(false);
  const [currentCartItem, setCurrentCartItem] = useState(null);
  const [hideSearchCart, setHideSearchCart] = useState(false);
  const [quantityErrorAnimation, setQuantityErrorAnimation] = useState(false);
  const [clearCartSpinner, setClearCartSpinner] = useState(false);
  const [productPrice, setProductPrice] = useState({});
  const [isFetchingProductPrice, setIsFetchingProductPrice] = useState(false);
  const [activeSection, setActiveSection] = useState("product");
  const [quantityInputErrorMessage, setQuantityInputErrorMessage] =
    useState("");
  const [cartItemEditQuantity, setCartItemEditQuantity] = useState(false);
  const [editCartLoader, setEditCartLoader] = useState(false);
  const { setShowMaintenance } = useContext(usersData);
  const { networkStatus, showNetworkStatus } = useContext(networkStatusContext);

  //get search screen widgets and update the respective states
  const getSearchWidgets = async () => {
    //update the shimmer before the api call
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "search_bar_tap",
    });
    setShowRecentShimmer(true);
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    let params = {
      retailerId: userData?.id,
      screenName: "search_screen",
    };
    try {
      const res = await getScreen("HOME_SCREEN", params, {});
      //donot update shimmer state here. only update after promise.all resolves
      //update widget state
      setWidgetData(res?.data?.data);
      //push the api calls to a promises array
      let promises = [];
      res?.data?.data?.map((item, index) => {
        let params = {};
        if (
          item.widgetName === "recent_widget" &&
          item.data.title === "Recent Search"
        ) {
          params = {
            size: 6,
          };
        } else if (
          item.widgetName === "recent_widget" &&
          item.data.title === "Top Searches"
        ) {
          params = {
            size: 6,
          };
        } else if (
          item.widgetName === "product_list_widget" &&
          (item.data.title === "Trending Products" ||
            item.data.title === "Recommended Products")
        ) {
          params = {
            size: 20,
            screenName: "searchPage",
          };
        } else if (
          item.widgetName === "product_list_widget" &&
          item.data.title === "Order Again"
        ) {
          params = {
            size: 6,
          };
        }
        console.log(params);
        // push all promises into the promise array
        promises.push({
          func: common_product_widget,
          endPoint: item?.data?.apiEndPoint,
          params: params,
          name: item?.widgetName,
          title: item?.data?.title,
        });
      });
      //update the promises state
      setPromises(promises);

      //make all widget calls async
      Promise.all(
        promises.map((item, _) => item.func(item.endPoint, item.params))
      )
        .then((res) => {
          console.log(res);
          setPromisesData(res);
          //update the shimmer state
          setShowRecentShimmer(false);
        })
        .catch((error) => {
          console.log(error);
          //update the shimmer state if any error
          setShowRecentShimmer(false);
        });
    } catch (error) {
      console.error(error);
      //update the shimmer if there's an error
      setShowRecentShimmer(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getSearchWidgets();
        });
      }
    }
  };

  const handleHideAutoComplete = (e) => {
    console.log("handleHideAutoComplete");
    setShowRecent(false);
    //make the page scrollable after closing the search autocomplete
    //remove the classname
    document.body.className = "";
  };

  const handleSelectAutoComplete = (e, search) => {
    //prevent event bubbling to the parent element
    e.nativeEvent.stopImmediatePropagation();

    //remove overlay layer
    document.body.className = "";
    setShowRecent(false);
    searchRef.current.blur();
    //if last character is space, pop it from the string and add encoded %20 -> equivalent to space
    let pop = search.charAt(search.length - 1);
    if (pop === " ") {
      let val = search.trim();
      navigate(`/search/${val}%20`);
    } else {
      navigate(`/search/${search}`);
    }
  };

  // const searchMeds = (event) => {
  //   if (window.innerWidth <= 1000) {
  //     setSearchString(event.target.value);
  //     // getSearchString(event.target.value);
  //     let pop = event.target.value.charAt(event.target.value.length - 1);

  //     if (pop === " ") {
  //       let val = event.target.value.trim();
  //       navigate(`/search/${val}%20`);
  //     } else {
  //       navigate(`/search/${event.target.value}`);
  //     }
  //   } else {
  //     if (location.pathname.split("/")[1] === "search") return;

  //     event.preventDefault();
  //     //prevent api hit when pressing down arrow, up arrow
  //     if (event.keyCode === 40 || event.keyCode === 38) return;

  //     //onfocus make the body non scrollable
  //     document.body.className = "overflow-hidden";

  //     console.log("on focus event hit");

  //     event.preventDefault();
  //     //show the recent items panel on focus
  //     setShowRecent(true);
  //     //hit recent searches
  //     console.log("hitting search widgets...");
  //     if (!event.target.value && event.keyCode !== 13) getSearchWidgets();

  //     return;
  //     if (event.keyCode === 40) return;

  //     setSearchString(event.target.value);
  //     // getSearchString(event.target.value);
  //     console.log(event.target.value);
  //     let pop = event.target.value.charAt(event.target.value.length - 1);
  //     if (pop === " ") {
  //       let val = event.target.value.trim();
  //       navigate(`/search/${val}%20`);
  //     } else {
  //       navigate(`/search/${event.target.value}`);
  //     }
  //   }
  // };

  //api to get the search products and updates the respective state
  const getSearchProducts = async (search) => {
    //update the shimmer state
    setShowProductsShimmer(true);
    //reset the search products
    setSearchProducts([]);

    let params = {
      page: 0,
    };

    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "search_products",
      search_string: search,
    });

    try {
      const res = await getData("PRODUCTS_BY_SEARCH", params, search);
      // console.log({ res });
      //only update the res if it matches the payload from the response to search string
      //this avoids async state update for the previous network calls showing wrong results
      if (
        res.config.data.toLowerCase() === searchRef.current.value.toLowerCase()
      ) {
        setSearchProducts(res?.data?.medicinesResponsesList);
        //update the shimmer state
        setShowProductsShimmer(false);
        //update the search string with the first name in the search list
        if (location.pathname.split("/")[1] !== "search") {
          console.log(searchRef.current.value);
          setSearchQuery(searchRef.current.value);
        }
        // setSearchString(res?.data?.medicinesResponsesList[0].name);
      } else {
        setShowProductsShimmer(true);
      }

      if (searchRef.current.value.length === 0) setShowProductsShimmer(false);

      //reset the active item to 0 to enable navigation
      setActiveItem(0);
    } catch (error) {
      console.log(error);
      //update the shimer state if any error
      setShowProductsShimmer(false);
      if (error.response.status === 401) {
        refreshToken().then((res) => {
          getSearchProducts(search);
        });
      }
    }
  };

  //search string state update
  // const handleSearchString = (e) => {
  //   //reset the searchProducts state when input is changed
  //   // setSearchProducts([])
  //   if (location.pathname.split("/")[1] === "search") {
  //     let pop = e.target.value.charAt(e.target.value.length - 1);

  //     if (pop === " ") {
  //       let val = e.target.value.trim();
  //       navigate(`/search/${val}%20`);
  //     } else {
  //       navigate(`/search/${e.target.value}`);
  //     }
  //     return;
  //   }
  //   //if down arrow, up arrow, enter is pressed return
  //   if (
  //     e.keyCode === 40 ||
  //     e.keyCode === 38 ||
  //     e.keyCode === 13 ||
  //     e.keyCode === 9
  //   )
  //     return;
  //   //empty the search products state when strinfg length < 3
  //   // if (e?.target?.value?.length !== searchString?.length) {
  //   //   setSearchProducts([]);
  //   // }

  //   // if(e?.target?.value?.length !== searchString?.length && e?.targe)

  //   setSearchString(e.target.value);

  //   //guardian check to exit if the search string is empty
  //   if (!e.target.value) return;
  //   //call debouncing on search string state
  //   // debouncedSearch(e.target.value);
  //   setShowProductsShimmer(true);
  //   search(e.target.value);
  //   // getSearchProducts(e.target.value);
  //   console.log({ searchString });
  // };

  const debounce = (func) => {
    console.log("debouncing hit");
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };
  const search = useCallback(debounce(getSearchProducts), []);

  const handleKeyboardNav = (e) => {
    //if backspace(mac)/delete(windows) is hit -> check is cart item is active -> remove cart item
    // mac -> delete -> keycode : 8 //also adds backspace to delete on windows
    // windows -> delete -> keycode : 46
    if (
      ((e.keyCode === 8 && navigator.userAgent.indexOf("Mac") !== -1) ||
        e.keyCode === 46) &&
      e.shiftKey
    ) {
      if (activeSection === "cart") {
        //also prevent the key hit to trigger the onChange of the search string
        e.preventDefault();
        //return if already a delete operation is in process
        if (isRemovingFromCart) return;
        //call delete cart with the item here
        // console.log('cart item',activeItemRef.current, cartData?.cartMedicineDetailResponseList[activeItem].medicineName)
        handleRemoveFromCart(
          cartData?.cartMedicineDetailResponseList[activeItem],
          activeItem
        );
      }
    }

    //handle down arrow navigation
    if (e.keyCode === 40) {
      e.preventDefault();
      e.stopPropagation();

      //donot handle navigation when clear cart in progrees
      // if(clearCartSpinner) return;

      //disable navigation on recents screen
      if (
        searchString?.length === 0 ||
        !searchProducts ||
        showProductsShimmer
      ) {
        // setActiveItem(0);
        // setActiveSection("product");
        return;
      }
      if (
        activeSection === "product" &&
        activeItem === searchProducts.length - 1
      )
        return;
      if (
        activeSection === "cart" &&
        activeItem === cartData?.cartMedicineDetailResponseList?.length - 1
      )
        return;

      if (activeSection === "product")
        setSearchString(searchProducts[activeItem + 1]?.name);
      if (activeItem === null) setActiveItem(0);
      else setActiveItem((prevItem) => prevItem + 1);
    }

    if (e.keyCode === 27) {
      document.body.className = "";
      setShowRecent(false);
      searchRef.current.blur();
      setSearchString("");
      setSearchProducts([]);
    }

    //handle up arrow navigation
    if (e.keyCode === 38 && e.target.value) {
      e.preventDefault();
      e.stopPropagation();
      if (
        searchString?.length === 0 ||
        !searchProducts ||
        showProductsShimmer
      ) {
        setActiveItem(0);
        setActiveSection("product");
        return;
      }
      //if initial item, return
      if (activeItem === 0) return;

      if (activeSection === "product" && activeItem > 0)
        setSearchString(searchProducts[activeItem - 1]?.name);
      if (activeItem === null) setActiveItem(0);
      else setActiveItem((prevItem) => prevItem - 1);
    }

    //handle secndary item state on enter only when product section is active
    if (
      e.keyCode === 13 &&
      !e.ctrlKey &&
      activeSection === "product" &&
      activeItemRef.current
    ) {
      //update the secondary state of item
      setSecondaryState((prevState) => !prevState);
      e.preventDefault();
      e.stopPropagation();
    } else if (
      e.keyCode === 13 &&
      !e.ctrlKey &&
      activeSection === "cart" &&
      activeItemRef.current
    ) {
      console.log("enter click on medicine in cart");
      e.preventDefault();
      e.stopPropagation();
      setCartItemEditQuantity((prevState) => !prevState);
      console.log(quantityInputRef.current);
      quantityInputRef.current.value =
        cartData.cartMedicineDetailResponseList[e.target.id]?.quantity;
    }

    //right arrow -> open cart if closed
    // if (e.key === "ArrowRight") {
    //   e.stopPropagation();
    //   e.preventDefault();
    //   //reset secondary state if active
    //   setSecondaryState(false);
    //   //return if cart data is unavailable
    //   // if (!cartData?.cartMedicineDetailResponseList) return;
    //   //expand the cart if hide cart is active and the user presses right arrow
    //   if (hideSearchCart) setHideSearchCart((prevVal) => !prevVal);
    //   //update the cart section active state
    //   setActiveSection("cart");
    //   //reset the active item
    //   setActiveItem(0);
    // }

    // if (e.ctrlKey && e.keyCode === 'Enter') {
    //   e.preventDefault();
    //   e.stopPropagation();
    //   document.body.className = "";
    //   searchRef.current.blur();
    //   setShowRecent(false);
    //   navigate("/cart");
    // }
  };

  //api to fetch the individual product price
  const getProductPrice = async (id, quantity) => {
    //update the loader state
    setIsFetchingProductPrice(true);
    let params = {
      medicineId: id,
      quantity: quantity,
    };
    try {
      const res = await getData("PRODUCTPRICE_BY_ID", params, {});
      setProductPrice(res?.data?.data);
      //update the loader state
      setIsFetchingProductPrice(false);
      // console.log(res)
    } catch (error) {
      //update the loader state
      setIsFetchingProductPrice(false);
      //update the loading state if error
      setIsAddingToCart(false);
      if (error.response.status === 401) {
        refreshToken().then((res) => {
          getProductPrice(id, quantity);
        });
      } else if (
        error.response.status === 502 ||
        error.response.status === 503 ||
        error.response.status === 504
      ) {
        setShowMaintenance({ status: true, apiData: error?.response });
      } else if (error.response.status === 500) {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: "Oops something went wrong !",
          tryAgain: function () {
            getProductPrice(id, quantity);
          },
        });
      } else {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getProductPrice(id, quantity);
          },
        });
      }
    }
  };

  //api to edit cart item quantity
  const editCartQuantity = async () => {
    if (quantityInputRef.current) {
      //update the loader state
      setEditCartLoader(true);

      let item =
        cartData.cartMedicineDetailResponseList[quantityInputRef.current.id];
      console.log({ item });
      let data = localStorage.getItem("userData");
      data = JSON.parse(data);
      const params = {};
      const body = {
        androidMap: {
          locale: "en",
          source: "pharmacyWeb",
          deviceId: null,
          appVersion: navigator.userAgent,
          deviceName: null,
          screen_name: "cart_screen",
          appVersionCode: "156",
        },
        medicineId: item.medicineId,
        quantity: quantityInputRef.current.value,
        retailerCode: data.retailerCode,
        retailerId: data.id,
        selectedSlotName: item.selectedSlotName,
      };

      try {
        const res = await Cart_Actions("EDIT_CART", params, body);
        //update the cart data
        setCartData(res.data.data);
        //focus back on the main search ref
        searchRef.current.focus();
        searchRef.current.select();
        //reset the cart input quantity focus state
        setCartItemEditQuantity(false);
        //update the loader state
        setEditCartLoader(false);
      } catch (error) {
        //update the loader state if any error
        setEditCartLoader(false);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            editCartQuantity();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              editCartQuantity();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              editCartQuantity();
            },
          });
        }
      }
    }
  };

  //onChange handler for quantity input change
  const handleQuanityChange = (e, name, index) => {
    //reset error message if the item quantity has changed
    if (quantityInputErrorMessage) setQuantityInputErrorMessage("");
    //reset error animation if any
    if (quantityErrorAnimation) setQuantityErrorAnimation(false);

    console.log("focus is inside quantity input", e);
    //enter -> keycode : 13
    //escape -> keycode : 27
    if (e.keyCode === 13 || e.keyCode === 27) return;
    //handle add to cart when enter is pressed
    console.log(e?.target?.value, name, index);

    //if quanitity greater than available stock return and throw a message
    if (e?.target?.value && quantityInputRef.current) {
      let message = "";
      if (e?.target?.value > searchProducts[index]?.availableQuantity) {
        message = `quantity entered [${quantityInputRef.current.value}] can't be greater than available stock ${searchProducts[index]?.availableQuantity}`;
      } else if (
        searchProducts[index]?.minOrderQuantity > 0 &&
        e?.target?.value < searchProducts[index]?.minOrderQuantity
      ) {
        message = `quantity entered [${quantityInputRef.current.value}] can't be less than minimum order quantity ${searchProducts[index]?.minOrderQuantity}`;
      } else if (
        searchProducts[index]?.maxOrderQuantity > 0 &&
        e?.target?.value > searchProducts[index]?.maxOrderQuantity
      ) {
        message = `quantity entered [${quantityInputRef.current.value}] can't be greater than maximum order quantity ${searchProducts[index]?.maxOrderQuantity}`;
      } else if (
        searchProducts[index]?.multiplyFactorForOrderQuantity > 0 &&
        parseInt(e?.target?.value) %
          parseInt(searchProducts[index]?.multiplyFactorForOrderQuantity) >
          0
      ) {
        console.log(
          parseInt(e?.target?.value) %
            parseInt(searchProducts[index]?.multiplyFactorForOrderQuantity)
        );
        message = `quantity entered [${quantityInputRef.current.value}] should be a multiple of ${searchProducts[index]?.multiplyFactorForOrderQuantity}`;
        quantityInputRef.current.value =
          Math.ceil(
            parseFloat(e?.target?.value) /
              parseFloat(searchProducts[index]?.multiplyFactorForOrderQuantity)
          ) * parseInt(searchProducts[index]?.multiplyFactorForOrderQuantity);
      }
      if (message) {
        setQuantityErrorAnimation(true);
      }
      setQuantityInputErrorMessage(message);
    }

    //get the price for the product
    if (e?.target?.value && !quantityErrorAnimation)
      getProductPrice(searchProducts[index].medicineId, e?.target?.value);
    else setProductPrice({});
  };

  //keydown handler for quantity input
  const handleQuantityInputShortcut = (e, index) => {
    //hit add to cart when enter is clicked
    if (
      e.keyCode === 13 &&
      activeSection === "product" &&
      quantityInputRef.current
    ) {
      e.preventDefault();
      e.stopPropagation();
      if (e?.target?.value > searchProducts[index]?.availableQuantity) {
        setQuantityErrorAnimation(true);
        return;
      }
      if (e?.target?.value > searchProducts[index]?.availableQuantity) {
        let message = `quantity entered [${quantityInputRef.current.value}] can't be greater than available stock ${searchProducts[index]?.availableQuantity}`;
        setQuantityErrorAnimation(true);
        setQuantityErrorAnimation(message);
        return;
      } else if (
        searchProducts[index]?.minOrderQuantity > 0 &&
        e?.target?.value < searchProducts[index]?.minOrderQuantity
      ) {
        let message = `quantity entered [${quantityInputRef.current.value}] can't be less than minimum order quantity ${searchProducts[index]?.minOrderQuantity}`;
        setQuantityErrorAnimation(true);
        setQuantityErrorAnimation(message);
        return;
      } else if (
        searchProducts[index]?.maxOrderQuantity > 0 &&
        e?.target?.value > searchProducts[index]?.maxOrderQuantity
      ) {
        let message = `quantity entered [${quantityInputRef.current.value}] can't be greater than maximum order quantity ${searchProducts[index]?.maxOrderQuantity}`;
        setQuantityErrorAnimation(true);
        setQuantityErrorAnimation(message);
        return;
      } else if (
        searchProducts[index]?.multiplyFactorForOrderQuantity > 0 &&
        parseInt(e?.target?.value) %
          parseInt(searchProducts[index]?.multiplyFactorForOrderQuantity) >
          0
      ) {
        let message = `quantity entered [${quantityInputRef.current.value}] should be a multiple of ${searchProducts[index]?.multiplyFactorForOrderQuantity}`;
        setQuantityErrorAnimation(true);
        setQuantityErrorAnimation(message);
        console.log(
          Math.ceil(
            parseFloat(e?.target?.value) /
              parseFloat(searchProducts[index]?.multiplyFactorForOrderQuantity)
          ) * parseInt(searchProducts[index]?.multiplyFactorForOrderQuantity)
        );
        quantityInputRef.current.value =
          Math.ceil(
            parseFloat(e?.target?.value) /
              parseFloat(searchProducts[index]?.multiplyFactorForOrderQuantity)
          ) *
          parseInt(
            searchProducts[index]?.multiplyFactorForOrderQuantity
          ).toString();
        return;
      }
      //if quantity entered is empty -> donot hit the add to cart apo
      if (!e?.target?.value) {
        //set error quantity animation
        setQuantityErrorAnimation(true);
        return;
      }
      //call add to cart api
      addToCart(e?.target?.value, index);
    } else if (
      e.keyCode === 13 &&
      activeSection === "cart" &&
      quantityInputRef.current
    ) {
      console.log("inside else if on enter");
      //handle edit cart quantity here
      if (!e?.target?.value) {
        //set error quantity animation
        setQuantityErrorAnimation(true);
        return;
      }

      console.log(e?.target?.value, index);
      editCartQuantity();
    }

    console.log(e.keyCode, activeSection);

    //focus back on the main search when escape is pressed
    if (e.keyCode === 27 && activeSection === "product") {
      //prevent event bubbling so escape doesn't trigger on searchRef when focussing back on it
      e.stopPropagation();
      //focus is not handled in a useEffect below
      setSecondaryState(false);
      setCartItemEditQuantity(false);
      searchRef.current.focus();
      searchRef.current.select();
      //reset quantity error if any
      if (quantityErrorAnimation) setQuantityErrorAnimation(false);
    } else if (e.keyCode === 27 && activeSection === "cart") {
      console.log("inside escpe in quantity editor");
      //prevent event bubbling so escape doesn't trigger on searchRef when focussing back on it
      e.stopPropagation();
      //focus is handled in a useEffect below
      setCartItemEditQuantity(false);
    }
  };

  //handler to call the add to cart api
  const handleAddToCart = (e, index) => {
    //stop event bubbling
    e.stopPropagation();
    //show error animation if quantity entered is empty
    if (!quantityInputRef.current.value) setQuantityErrorAnimation(true);

    if (quantityInputRef.current.value) {
      addToCart(quantityInputRef.current.value, index);
    }
  };

  const addToCart = async (qty, index) => {
    //update the loading state
    setIsAddingToCart(true);
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    if (searchProducts[index].deliverySlot) {
      const params = {};
      const body = {
        androidMap: {
          locale: "en",
          source: "pharmacyWeb",
          deviceId: null,
          appVersion: navigator.userAgent,
          deviceName: null,
          screen_name: "search_screen",
          appVersionCode: "156",
        },
        medicineId: searchProducts[index].medicineId,
        quantity: qty,
        retailerCode: data.retailerCode,
        retailerId: data.id,
      };

      //focus on main search bar after item added to cart
      searchRef.current.focus();
      //select the text that is already there in the search bar
      searchRef.current.select();

      try {
        const res = await Cart_Actions("ADD_TO_CART", params, body);
        //update the cart data context
        setCartData(res?.data?.data);
        //update the loading state
        setIsAddingToCart(false);

        //update the secondary state of the card
        setSecondaryState(false);

        //focus on main search bar after item added to cart
        searchRef.current.focus();
        //select the text that is already there in the search bar
        searchRef.current.select();
      } catch (error) {
        //update the loading state if error
        setIsAddingToCart(false);
        //update the secondary state of the card
        setSecondaryState(false);
        if (error.response.status === 401) {
          refreshToken().then((res) => {
            addToCart(qty, index);
          });
        } else if (
          error.response.status === 502 ||
          error.response.status === 503 ||
          error.response.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error.response.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              addToCart(qty, index);
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              addToCart(qty, index);
            },
          });
        }
      }
    } else {
      window.alert("You cannot add product to cart without Delivery Slot");
    }
  };

  //handler to remove item from cart
  const handleRemoveFromCart = (item, index) => {
    //call the delete from cart API
    removeFromCart(item, index);
    //update current cart loader item state
    setCurrentCartItem(index);
  };

  //maps through cart data and removes all the items
  const handleEmptyCart = (e) => {
    console.log("called handle empty cart");
    //prevent event bubbling
    e && e.stopPropagation();
    e && e.preventDefault();
    //update empty cart spinner
    setClearCartSpinner(true);
    //async calls to remove medicines from the cart
    let promises = [];
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);

    cartData?.cartMedicineDetailResponseList?.forEach((item, _) => {
      let params = {};
      let body = {
        androidMap: {},
        medicineId: item.medicineId,
        retailerCode: data.retailerCode,
        retailerId: data.id,
      };
      promises.push({
        func: Cart_Actions,
        type: "REMOVE_FROM_CART",
        params: params,
        body: body,
      });
    });
    console.log({ promises });
    //update the loader state
    setClearCartSpinner(true);
    //make all remove cart calls async
    Promise.all(
      promises.map((item, _) => item.func(item.type, item.params, item.body))
    )
      .then((res) => {
        //update the loader state
        setClearCartSpinner(false);
        console.log({ res });
        res.map((item, _) => {
          if (item?.data?.data?.cartMedicineDetailResponseList?.length === 0) {
            setCartData(item?.data?.data);
            return;
          }
        });
        // setCartData(res[res.length - 1]?.data?.data);
        //set the navigation back on the product section
        setActiveSection("product");
        setActiveItem(0);
        //scroll back to the top of the div
        document.getElementById("scrollableDiv").scrollTo(0, 0);
      })
      .catch((error) => {
        //update the loader state
        setClearCartSpinner(false);
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
        });
      });
    //set the focus back on main search after clearing cart
    searchRef.current.focus();
    searchRef.current.select();
  };

  //api call to remove item from cart
  const removeFromCart = async (item, index) => {
    //donot make the api call if the item is unedefined
    if (!item) return;
    //update the loader state
    setIsRemovingFromCart(true);
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    const params = {};
    const body = {
      androidMap: {},
      medicineId: item.medicineId,
      retailerCode: data.retailerCode,
      retailerId: data.id,
    };

    //handle the active item in the cart
    if (index === 0 && cartData?.cartMedicineDetailResponseList?.length === 1) {
      //removing the last element in cart -> set active item back to the product section
      setActiveItem(index);
      setActiveSection("product");
      //scroll to the top on product section
      document.getElementById("scrollableDiv").scrollTo(0, 0);
    } else if (
      index === 0 &&
      cartData?.cartMedicineDetailResponseList?.length > 0
    ) {
      setActiveItem(0);
      setActiveSection("cart");
    } else {
      setActiveItem(index - 1);
    }

    try {
      const res = await Cart_Actions("REMOVE_FROM_CART", params, body);
      //update the cartData post delete item
      setCartData(res?.data?.data);
      //update the loader states
      setIsRemovingFromCart(false);
      setClearCartSpinner(false);
    } catch (error) {
      console.error(error);
      //update the loader states
      setIsRemovingFromCart(false);
      setClearCartSpinner(false);
      if (error.response.status === 401) {
        refreshToken().then((res) => {
          removeFromCart(item);
        });
      } else if (
        error.response.status === 502 ||
        error.response.status === 503 ||
        error.response.status === 504
      ) {
        setShowMaintenance({ status: true, apiData: error?.response });
      } else if (error.response.status === 500) {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: "Oops something went wrong !",
          tryAgain: function () {
            removeFromCart(item);
          },
        });
      } else {
        setErrorModal({
          show: true,
          apiData: error?.response,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            removeFromCart(item);
          },
        });
      }
    }
  };

  const handleHideSearchCart = (e) => {
    //prevent event bubbling
    e.stopPropagation();
    //handle hide cart state
    console.log(hideSearchCart);
    setHideSearchCart((prevVal) => !prevVal);
    if (!hideSearchCart) {
      setActiveSection("product");
      setActiveItem(0);
      searchRef.current.focus();
      searchRef.current.select();
    } else if (hideSearchCart) {
      setActiveSection("cart");
      setActiveItem(0);
      searchRef.current.focus();
      searchRef.current.select();
    }
  };

  //view all items handler
  const handleViewAll = (e) => {
    console.log("handle view all called");
    //prevent event bubbling
    e.stopPropagation();
    //remover the overlay and hide the panel
    document.body.className = "";
    setShowRecent(false);
    //navigate to the search screen
    navigate("/search/" + searchString);
  };

  const handleProductClick = (e, index) => {
    console.log("product clicked bitch");
    //prevent event bubbling
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    //handle search layer
    document.body.className = "";
    setShowRecent(false);
    searchRef.current.blur();
    //navigate to product page
    navigate("/product/" + searchProducts[index].medicineId);
  };

  const handleUploadModal = () => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "upload_medicines_click",
    });
    setUploadToCart(!uploadToCart);
    document.body.className = "overflow-hidden";
  };

  const handleCheckOut = (e) => {
    //prevent event bubbling
    e.stopPropagation();
    //make the page scrollable after closing the search autocomplete
    //remove the classname
    document.body.className = "";
    //disable the search overlay before going to cart screen
    setShowRecent(false);
    navigate("/cart");
  };
  useEffect(() => {
    let userData = localStorage.getItem("userData");
    if (userData && userData.length) {
      setUserData(JSON.parse(userData));

      let retCode = localStorage.getItem("onBehalfRetCode");
      JSON.parse(userData)?.enabled &&
        retCode &&
        retCode.length > 0 &&
        retCode !== "undefined" &&
        setOnBehalfExist(true);
    } else {
      setOnBehalfExist(false);
    }
  }, [userLoggedIn]);

  useEffect(() => {
    if (cartData && cartData.size) {
      setCartShake(true);
      setTimeout(() => {
        setCartShake(false);
      }, [1500]);
    }
  }, [cartData]);

  useEffect(() => {
    if (location.pathname.split("/")[1] !== "search") {
      setSearchString("");
    }

    if (location.pathname.split("/")[1] === "home") {
      setCurrentRoute("home");
    } else if (location.pathname.split("/")[1] === "categories") {
      setCurrentRoute("categories");
    } else if (location.pathname.split("/")[1] === "store") {
      setCurrentRoute("store");
    } else {
      setCurrentRoute("");
    }

    if (location.pathname.split("/")[2] === "specialOffers") {
      setCurrentRoute("offers");
    }

    if (location.pathname.split("/")[1] === "search") {
      // setShowRecent(false)
      let string = location.pathname.split("/")[2].split("%20").join(" ");
      setSearchString(string);
    } else if (
      location.pathname.split("/")[1] === "onboarding" ||
      location.pathname.split("/")[1] === "login" ||
      location.pathname.split("/")[1] === "wholesale"
    ) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location, userLoggedIn]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
      />

      {/* Internet StatusBar */}
      {!networkStatus ? (
        <div className="network-panel">
          <p className="no-network">
            <BsFillExclamationTriangleFill /> No Internet Connection
          </p>
        </div>
      ) : showNetworkStatus ? (
        <div className="network-panel">
          <p className="network">
            <IoIosWifi /> Internet Connection is back online
          </p>
        </div>
      ) : isUpdateAvailable ? (
        isUpdateAvailable && (
          <div className="update-available-banner flex-align-center-row">
            <p>
              New update is available.&nbsp;&nbsp;
              <span
                style={{ marginRight: "0.4rem" }}
                onClick={() => updateAssets()}
              >
                click to update
              </span>
              <AiFillSetting
                className="settingIcon"
                size="1rem"
                color="#4b4495"
              />
            </p>
          </div>
        )
      ) : (
        showHeader && (
          <a
            className="contact_snackbar flex-align-center-row"
            href="https://api.whatsapp.com/send?phone=8390088888"
            target="_blank"
            onClick={() =>
                          Common_Event_Hit("DYNAMIC_EVENT", {
                            eventName: "whatsapp_scrollbar_tap",
                          })
                        }
          >
            <div className="marquee">
              <div>
                <span className="firstSpan">
                  <span className="firstSpan_innerDiv">
                    Say <b>'Hi'</b> on Whats App:{" "}
                    <img
                      src={whatspp_logo}
                      className="menu_wtsapp_mini"
                      alt="WhatsApp"
                    />{" "}
                    <b>83900 88888</b>
                  </span>
                  <span className="firstSpan_innerDiv2">
                    <b className="text_underline">Click here</b>
                    &nbsp; to connect with support team{" "}
                  </span>
                </span>

                <span>
                  <span className="secondSpan_innerDiv">
                    Say <b>'Hi'</b> on Whats App:{" "}
                    <img
                      src={whatspp_logo}
                      className="menu_wtsapp_mini"
                      alt="WhatsApp"
                    />{" "}
                    <b>83900 88888</b>
                  </span>
                  <span className="secondSpan_innerDiv2">
                    <b className="text_underline">Click here</b>
                    &nbsp; to connect with support team{" "}
                  </span>
                </span>
              </div>
            </div>
          </a>
        )
      )}

      <header
        className={cx(
          { header_outer_div: !onBehalfExist },
          { header_outer_div_ob: onBehalfExist },
          { "bg-hidden": !userData || !showHeader }
        )}
        style={{ position: "fixed", top: "28px" }}
      >
        <OnBehalf
          show={onBehalf}
          content={"Add Retailer Code"}
          onHide={() => {
            setShowOnBehalf(false);
          }}
        />
        {/* mobile footer  */}
        <div className="mobile_footer">
          <div className="display_flex space_around font_ubuntu font_weight_md font_16 color_primary">
            <div
              className="text_center footer_sec"
              onClick={async () => {
                Common_Event_Hit("DYNAMIC_EVENT", {
                  eventName: "nav_tap",
                  navItem: "home",
                  screen_name: localStorage.getItem("currentScreen"),
                });
                navigate("/home");
              }}
            >
              {currentRoute === "home" ? (
                <span className="color_secondary">
                  <AiFillHome className="footer_icons" /> <br></br>
                  Home
                </span>
              ) : (
                <>
                  <AiOutlineHome className="footer_icons" /> <br></br>
                  Home
                </>
              )}
            </div>
            <div
              className="text_center footer_sec"
              onClick={async () => {
                Common_Event_Hit("DYNAMIC_EVENT", {
                  eventName: "nav_tap",
                  navItem: "offers",
                  screen_name: localStorage.getItem("currentScreen"),
                });
                navigate("/categories/specialOffers");
              }}
            >
              {currentRoute === "offers" ? (
                <span className="color_secondary">
                  <MdLocalOffer className="footer_icons" /> <br></br>
                  Offers
                </span>
              ) : (
                <>
                  <MdOutlineLocalOffer className="footer_icons" /> <br></br>
                  Offers
                </>
              )}
            </div>
            <div
              className="text_center footer_sec"
              onClick={async () => {
                Common_Event_Hit("DYNAMIC_EVENT", {
                  eventName: "nav_tap",
                  navItem: "account",
                  screen_name: localStorage.getItem("currentScreen"),
                });
                navigate("/store");
              }}
            >
              {currentRoute === "store" ? (
                <span className="color_secondary">
                {userData?.onlinePaymentEnabled && <div className="target_dot_footer"></div>}
                  <IoStorefront className="footer_icons" /> <br></br>
                  Store
                </span>
              ) : (
                <>
                {userData?.onlinePaymentEnabled && <div className="target_dot_footer"></div>}
                  <IoStorefrontOutline className="footer_icons" /> <br></br>
                  Store
                </>
              )}
            </div>
          </div>
        </div>

        {/* web header  */}
        <Container
          fluid
          className={cx(
            "header",
            "font_ubuntu",
            "pos_fixed",
            { bg_behalf: onBehalfExist },
            { header_outer_div: showRecent === true && !onBehalfExist },
            { header_outer_div_ob: showRecent === true && onBehalfExist }
          )}
          // style={{
          //   backgroundColor:
          //     location.pathname.split("/")[1] === "search"
          //       ? "#2f2963"
          //       : "#4B4495",
          // }}
        >
          <Row
            className="row flex-align-center-row"
            style={{ alignItems: "center" }}
          >
            <Col className="web_menu" style={{ maxWidth: "250px"}}>
              <Row>
                <Col md={7}>
                  <Link
                    to="/home"
                    onClick={() => {
                      document.body.className = "";
                      setShowRecent(false);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        marginTop: "-5px",
                      }}
                    >
                      <img src={Logo} className="saveo_logo" alt="saveo logo" />{" "}
                      <img src={Name} className="saveo_name" alt="saveo name" />
                    </div>{" "}
                  </Link>
                </Col>
              </Row>
            </Col>

            <Col className="mob_menu padding_top_12">
              <Row>
                <Col className="flex-start-center-row" style={{ flex: "1" }}>
                  {" "}
                  <Link
                    to="/home"
                    onClick={() => {
                      document.body.className = "";
                      setShowRecent(false);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img src={Logo} className="saveo_logo" alt="saveo logo" />{" "}
                      <img src={Name} className="saveo_name" alt="saveo name" />
                    </div>{" "}
                  </Link>
                </Col>
                
              
                  {userData && userData?.enabled && (
                    <>
                      {userData?.admin || userData?.salesPerson ? (
                        <Col className="cart_icon_cntr menu_icons_min cart_icon_min">
                        <span onClick={() => setShowOnBehalf(true)}>
                          {" "}
                          {onBehalfExist ? (
                            <FaUserShield className="color_white  cursor_pointer" />
                          ) : (
                            <FaUserShield className="color_white cursor_pointer" />
                          )}
                        </span>
                        </Col>
                      ) : (
                        ""
                      )}
                    </>
                  )}
               
                <Col className="cart_icon_cntr menu_icons_min cart_icon_min">
                  {/* help & support */}
                  <Fragment>
                    {" "}
                    <OverlayTrigger
                      trigger="hover"
                      key={"bottom"}
                      placement={"bottom"}
                      overlay={
                        <Popover id={`popover-positioned-bottom`}>
                          <Popover.Header as="h3">
                            <div
                              className="report-issue-container"
                              onClick={() =>
                                navigate("/help-support/ReportIssue")
                              }
                            >
                              <Link to="/help-support/ReportIssue">
                                Report Issue
                              </Link>
                            </div>
                          </Popover.Header>
                          <Popover.Body>
                            <div
                              className="q-and-a-container"
                              onClick={() => navigate("/help-support/QandA")}
                            >
                              <Link to="/help-support/QandA">
                                Question and Answers
                              </Link>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div
                        onClick={() =>
                          Common_Event_Hit("DYNAMIC_EVENT", {
                            eventName: "support_icon_tap",
                          })
                        }
                      >
                        <SiWechat className="color_white cursor_pointer" />{" "}
                      </div>
                    </OverlayTrigger>
                  </Fragment>
                </Col>
                {/* <Col className=" menu_icons_min">
                {" "}
                <Fragment>
                  {" "}
                  <MdNotifications className="color_white  cursor_pointer" />{" "}
                </Fragment>{" "}
              </Col> */}
                <Col
                  className={cx({cart_empty: !cartData || cartData?.size < 1}, "resp_cart", "menu_icons_min", "text_center", "cart_icon_min", "cursor_pointer")}
                  onClick={async () => {
                    Common_Event_Hit("DYNAMIC_EVENT", {
                      eventName: "cart_icon_tap",
                      screen_name: localStorage.getItem("currentScreen"),
                    });
                    navigate("/cart");
                  }}
                  style={{borderColor: onBehalfExist ? "transparent" : "#3d377ec2"}}
                >
                  {" "}
                  <Fragment>
                    {" "}
                    <div className={cartShake && "errorAnime"}>
                      {cartData && cartData.size > 0 && (
                        <div
                          className="cart_notification font_ubuntu"
                        >
                          {cartData.size}
                        </div>
                      )}
                      {/* {
                        cartShake &&  <img src={Celebrate} style={{width:'40px'}}/>
                      } */}
                      <IoMdCart />{" "}
                    </div>
                  </Fragment>{" "}
                </Col>
                <Col className="  cart_icon_cntr m_r_16 menu_icons_min cart_icon_min" style={{borderColor: onBehalfExist ? "white" : "#3d377ec2"}}>
                  <NotificationsComponent userLoggedIn={userLoggedIn} />
                </Col>
              </Row>
            </Col>

            <Col lg={6} className="searchHeader">
              <div className="searchBarDiv">
                <SearchBarComponent headerType={"RETAIL"} />
              </div>
            </Col>

            {/* <Col className="dummy_div padding_top_12 menu_icons_max"></Col> */}

            {/* {onBehalfExist && ( */}
            <Col className="menu_icons_max text_center icons_div">
              <div style={{borderColor: onBehalfExist ? "transparent" : "#3d377ec2"}}>
                <button
                  className="upload_cart_modal_btn"
                  onClick={handleUploadModal}
                >
                  Upload &nbsp;
                  <RiUpload2Fill
                    className="header_icons cursor_pointer"
                    style={{ fontSize: "1.1rem" }}
                  />
                </button>
              </div>
              {userData && userData?.enabled && (userData?.admin || userData?.salesPerson) && (
                <div style={{ padding: "2px 5px",height:'35px', borderColor: onBehalfExist ? "white" : "#3d377ec2" }}>
                  {userData && userData?.enabled && (
                    <>
                      {userData?.admin || userData?.salesPerson ? (
                        <div onClick={() => setShowOnBehalf(true)}>
                          {" "}
                          <OverlayTrigger
                            key={"bottom"}
                            placement={"bottom"}
                            overlay={
                              <Tooltip
                                id={`tooltip-bottom`}
                                style={{ marginTop: "0px" }}
                              >
                                On Behalf
                              </Tooltip>
                            }
                          >
                            <div>
                              {onBehalfExist ? (
                                <FaUserShield className="color_white header_icons cursor_pointer" />
                              ) : (
                                <FaUserShield className="color_white header_icons cursor_pointer" />
                              )}
                            </div>
                          </OverlayTrigger>{" "}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              )}

              <div style={{ padding: "3px 5px",height:'35px', borderColor: onBehalfExist ? "white" : "#3d377ec2" }}>
                <OverlayTrigger
                  key={"bottom"}
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>Notifications</Tooltip>
                  }
                >
                  <NotificationsComponent userLoggedIn={userLoggedIn} />
                </OverlayTrigger>
              </div>

              <div
                className={cx({cart_empty_max: !cartData || cartData?.size < 1}, "cart_icon", "menu_icons_max", "text_center", "text_center", "cursor_pointer")}
                onClick={async () => {
                  Common_Event_Hit("DYNAMIC_EVENT", {
                    eventName: "cart_icon_tap",
                    screen_name: localStorage.getItem("currentScreen"),
                  });
                  navigate("/cart");
                }}
                style={{border: onBehalfExist ? "none" : "1px solid #3d377ec2"}}
              >
                {" "}
                <Fragment>
                  {" "}
                  <OverlayTrigger
                    key={"bottom"}
                    placement={"bottom"}
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom`}
                        style={{ marginTop: "0px" }}
                      >
                        Cart
                      </Tooltip>
                    }
                  >
                    <div
                      id="cartIcon"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "cart_icon_tap",
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/cart");
                      }}
                      style={{border: onBehalfExist ? "none" : "1px solid #3D377E"}}
                    >
                      {cartData && cartData.size > 0 && (
                        <div
                          className="cart_notification font_ubuntu"
                        >
                          {cartData.size}
                        </div>
                      )}
                      {/* {
                        cartShake &&  <img src={Celebrate} style={{width:'40px'}}/>
                      } */}
                      <IoMdCart style={{ fontSize: "18px" }} />{" "}
                    </div>
                  </OverlayTrigger>
                </Fragment>{" "}
              </div>

              <div
                className="menu_icons_max text_center"
                style={{ padding: "2px 5px",height:'35px', borderColor: onBehalfExist ? "white" : "#3d377ec2" }}
              >
                {" "}
                <Fragment>
                  {" "}
                  <OverlayTrigger
                    key={"bottom"}
                    placement={"bottom"}
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom`}
                        style={{ marginTop: "0px" }}
                      >
                        Store
                      </Tooltip>
                    }
                  >
                    <div>
                      {userData?.onlinePaymentEnabled && <div className="target_dot"></div>} 
                      <IoStorefront
                        onClick={async () => {
                          Common_Event_Hit("DYNAMIC_EVENT", {
                            eventName: "nav_tap",
                            navItem: "account",
                            screen_name: localStorage.getItem("currentScreen"),
                          });
                          navigate("/store");
                        }}
                        className="color_white header_icons cursor_pointer"
                      />{" "}
                    </div>
                  </OverlayTrigger>
                </Fragment>{" "}
              </div>
            </Col>

            {/* )} */}

            {/* <Col className="menu_icons_max text_center"></Col> */}

            {/* notification icon large screen */}
            {/* <Col className="menu_icons_max text_center">
              
            </Col> */}
          </Row>
        </Container>

        {uploadToCart ? (
          <UploadToCartModal
            onBehalfExist={onBehalfExist}
            uploadToCart={uploadToCart}
            setUploadToCart={setUploadToCart}
            userData={userData}
          />
        ) : (
          <></>
        )}

        {!showRecent && (
          <>
            <div className="dup_div"></div>
            <Container
              fluid
              className={
                onBehalfExist
                  ? "header header_2 shadow font_ubuntu sub_menu bg_behalf"
                  : "header header_2 shadow font_ubuntu sub_menu"
              }
            >
              <div className="menu_bar shadow_btm flex-align-center-row">
                <div
                  className="cursor_pointer font_14"
                  onClick={async () => {
                    Common_Event_Hit("DYNAMIC_EVENT", {
                      eventName: "nav_tap",
                      navItem: "home",
                      screen_name: localStorage.getItem("currentScreen"),
                    });
                    navigate("/home");
                  }}
                >
                  {" "}
                  {onBehalfExist ? (
                    <span
                      className={
                        currentRoute === "home" && "color_primary p-8-1"
                      }
                    >
                      <AiFillHome />
                      Home
                    </span>
                  ) : (
                    <span
                      className={
                        currentRoute === "home" &&
                        "color_tertiory active_nav p-8-1"
                      }
                    >
                      <AiFillHome />
                      Home
                    </span>
                  )}
                </div>
                <div
                  className="cursor_pointer font_14"
                  onMouseEnter={() => setShowCategories(true)}
                  onMouseLeave={() => setShowCategories(false)}
                >
                  {onBehalfExist ? (
                    <span
                      className={
                        currentRoute === "categories" && "color_primary p-8-1"
                      }
                    >
                      <BiCategory />
                      Categories
                    </span>
                  ) : (
                    <span
                      className={
                        currentRoute === "categories" &&
                        "color_tertiory active_nav p-8-1"
                      }
                    >
                      <BiCategory />
                      Categories
                    </span>
                  )}{" "}
                  {!showCategories && <BiChevronDown />}{" "}
                  {showCategories && <BiChevronUp />}
                  <Dropdown.Menu className="padding_10" show={showCategories}>
                    {/* Silvercross dropdown category */}
                    <Dropdown.Item
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "nav_tap",
                          navItem: "silvercross",
                          screen_name:
                            localStorage.getItem("currentScreen"),
                        });
                        navigate("/silvercross");
                      }}
                      style={{color: "#592C82"}}
                    >
                      SilverCross
                    </Dropdown.Item>

                    {categories &&
                      categories.map((cat, i) => {
                        if (cat.active) {
                          if (cat.section_name === "500+ Companies") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/companies");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Special Offers") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/specialOffers");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Regular Schemes") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/regularSchemes");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Corona Essentials") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/coronaEssentials");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Surgical") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/Surgicals");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Generic") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/generic");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Ayurvedic") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/Ayurvedic");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Speciality") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/Speciality");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Allopathy") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/Allopathy");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (
                            cat.section_name === "Factory to Pharmacy"
                          ) {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/factorytopharmacy");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "OTC") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/OTC");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          }
                        }
                      })}
                  </Dropdown.Menu>
                </div>
                <div
                  className="cursor_pointer font_14"
                  onMouseEnter={() => setShowOffers(true)}
                  onMouseLeave={() => setShowOffers(false)}
                  onClick={async () => {
                    Common_Event_Hit("DYNAMIC_EVENT", {
                      eventName: "nav_tap",
                      navItem: "offers",
                      screen_name: localStorage.getItem("currentScreen"),
                    });
                    navigate("/categories/specialOffers");
                  }}
                >
                  {onBehalfExist ? (
                    <span
                      className={
                        currentRoute === "offers" && "color_primary p-8-1"
                      }
                    >
                      <GiSevenPointedStar />
                      Offers
                    </span>
                  ) : (
                    <span
                      className={
                        currentRoute === "offers" &&
                        "color_tertiory active_nav p-8-1"
                      }
                    >
                      <GiSevenPointedStar />
                      Offers
                    </span>
                  )}

                  {/* {!showOffers && <BiChevronDown/>} {showOffers && <BiChevronUp/>}  */}
                  {/* <Dropdown.Menu className="padding_10" show={showOffers}>
       <Dropdown.Item eventKey="1">Deal of the day</Dropdown.Item>
       <Dropdown.Item eventKey="2">Flash Sale</Dropdown.Item>
       <Dropdown.Item eventKey="3">Special Offers</Dropdown.Item>
       <Dropdown.Item eventKey="4">Bulk Rate</Dropdown.Item>
       </Dropdown.Menu> */}
                </div>
                <section
                  className="color_white font_weight_md cursor_pointer font_14 no_wrap chat_header_menu"
                  onMouseEnter={() => setShowHelp(true)}
                  onMouseLeave={() => setShowHelp(false)}
                  onClick={() =>
                    Common_Event_Hit("DYNAMIC_EVENT", {
                      eventName: "whatsapp_icon_tap",
                    })
                  }
                >
                  <a
                    style={{ textDecoration: "none", color: "#075E54" }}
                    href="https://api.whatsapp.com/send?phone=8390088888"
                    target="_blank"
                  >
                    <img
                      src={whatspp_logo}
                      className="menu_wtsapp"
                      alt="WhatsApp"
                    />{" "}
                    Chat with us
                    <Dropdown.Menu
                      className="padding_10 chat_dropdown"
                      show={showHelp}
                    >
                      <a
                        style={{ textDecoration: "none" }}
                        href="https://api.whatsapp.com/send?phone=8390088888"
                        target="_blank"
                      >
                        <div className="qr_nmbr">83900 88888</div>
                        <img
                          className="wtsapp_qr"
                          src={wtsp_qr}
                          alt="whatsapp qr"
                        />
                        <div className="qr_redirect_btn">
                          {" "}
                          {/* <BsWhatsapp
                          style={{
                            marginTop: "-3px",
                            fontWeight: "bolder",
                            fontSize: "15px",
                          }}
                        />{" "} */}
                          <img
                            src={whatspp_logo}
                            className="menu_wtsapp"
                            alt="WhatsApp"
                          />
                          <span style={{ fontWeight: "normal" }}>
                            &nbsp;&nbsp;Say
                          </span>{" "}
                          'Hi' <FiChevronRight />
                        </div>
                      </a>
                    </Dropdown.Menu>
                  </a>
                </section>
              </div>
            </Container>
          </>
        )}

        <div className="header_backLayer"></div>
      </header>
    </>
  );
};

export default React.memo(Header);
