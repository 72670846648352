import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import "./MapComponent.css";
import { Maps } from "../../../environments";
import ButtonLoading1 from "../../Spinners/ButtonLoading1";
import Lottie from "react-lottie-player";
import mapLoading from "../../../Images/json/map-loading.json";
import { useRef } from "react";
import { useGoogleMap } from "@react-google-maps/api";

function MapComponent({
  setAddress,
  initialCoords,
  locationFetchInProgress,
  setGeocodeShimmer,
}) {
  //map instance hook
  const googleMap = useRef();

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  let geocoder;

  const [center, setCenter] = useState({ lat: null, lng: null });
  const [locationError, setlocationError] = useState("");
  const [addressLookupData, setAddressLookupData] = useState([]);
  const [obtainedUserLocation, setObtainedUserLocation] = useState(false);
  const latlngRef = useRef();
  //reverse geocode on every marker drop
  const handleMarkerDrop = (e) => {
    console.log("map click event object", e.latLng.lat(), e.latLng.lng());
    setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    latlngRef.current = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    reverseGeocode();
  };

  //reverse address lookup based on the coords obtained
  const reverseGeocode = () => {
    //updadte the geoCode shimmer state
    setGeocodeShimmer(true);
    console.log("#############reversegeocoding################");
    geocoder = new window.google.maps.Geocoder();
    const params = {
      location: latlngRef.current,
      // googleMapsApiKey: "AIzaSyBvySo5-O1Ka4pZ6dV4cX529hLCOgRG9sU",
    };
    geocoder
      .geocode(params)
      .then((res) => {
        setAddressLookupData(res?.results);
        console.log("res.results", res?.results);
        setAddress(res?.results[0]);
        //updadte the geoCode shimmer state
        setGeocodeShimmer(false);
      })
      .catch((error) => {
        //updadte the geoCode shimmer state
        setGeocodeShimmer(false);
      });
  };

  //render the map to the center when user provides the location access
  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       setCenter({
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       });
  //       reverseGeocode();
  //     });
  //   } else {
  //     setlocationError(
  //       "Location Support isn't available. Please proceed to enter the location manually"
  //     );
  //   }
  // }, []);

  useEffect(() => {
    if (!(initialCoords.lat === 0 || initialCoords.lng === 0)) {
      setTimeout(() => {
        setCenter(initialCoords);
      }, 0);
      setCenter(initialCoords);
      console.log("initialCoords", initialCoords);
      setObtainedUserLocation(true);
    }
  }, [initialCoords]);

  useEffect(() => {
    console.log("center updated", center);
    //update the latlng ref value here to geocode
    if (center.lat && center.lng) latlngRef.current = center;
  }, [center]);

  // useEffect(() => {
  //   if(googleMap && googleMap.current && center){
  //     googleMap.current.panTo(center)
  //   }
  // },[googleMap, center])

  return (
    <>
      {obtainedUserLocation && !locationFetchInProgress && (
        <LoadScript
          googleMapsApiKey={Maps.MAPS_API_KEY}
          loadingElement={
            <div className="enable-location-info">
              <Lottie
                loop
                animationData={mapLoading}
                play
                style={{ width: 350, height: 350 }}
              />
            </div>
          }
        >
          <GoogleMap
            mapContainerClassName="map-container"
            center={center}
            zoom={15}
            onClick={handleMarkerDrop}
            ref={googleMap}
          >
            {/* Child components, such as markers, info windows, etc. */}
            <>
              <Marker
                position={center}
                animation="BOUNCE"
                draggable={true}
                onDragEnd={handleMarkerDrop}
                onLoad={reverseGeocode}
              ></Marker>
              {/* <InfoWindow
            position={center}
          >

          </InfoWindow> */}
            </>
          </GoogleMap>
        </LoadScript>
      )}
      {!obtainedUserLocation && !locationFetchInProgress && (
        <div className="enable-location-info">
          Please enable location services to help us serve you better
        </div>
      )}

      {locationFetchInProgress && (
        <div className="enable-location-info">
          <Lottie
            loop
            animationData={mapLoading}
            play
            style={{ width: 350, height: 350 }}
          />
        </div>
      )}

      {/* {awaitingUserPermissionApproval && (
      <div className="enable-location-info">Please click allow to proceed further</div>
    )} */}
    </>
  );
}

export default React.memo(MapComponent);
