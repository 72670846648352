import React from "react";
import { Button, Modal } from "react-bootstrap";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router";
import WSOrderPlaced from "../../../Images/json/WSOrderPlaced.json";
import "./Modals.css";

function WSOrderPlacedModal({ show, data, ...props }) {
  const navigate = useNavigate();

  return (
    <>
      <Modal show={show} centered className="ws-place-order-modal" {...props}>
        <Modal.Body>
          <div className="ws-order-placed-main-container">
            <Lottie
              loop={false}
              animationData={WSOrderPlaced}
              style={{ width: "60%", height: "60%" }}
              play
            />
            <div className="ws-order-message">
              <p>Order placed Successfully</p>
              <p>Thank you for shopping with Saveo</p>
            </div>
            <div className="ws-order-price-details">
              <p>
                Amount to be paid :{" "}
                <span>
                  ₹{" "}
                  {data?.orderAmount?.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </p>
              <p>via Cash on Delivery</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="ws-product-price-details-confirm-button"
            onClick={() => navigate("/wholesale/home/orders")}
          >
            Check Order Details
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WSOrderPlacedModal;
