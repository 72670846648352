import React, { useContext, useEffect, useState } from "react";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import "./WSOrders.css";
import {
  getLiveOrders,
  getOrderTrackingStatus,
  getPastOrders,
} from "../../../Actions/WholeSale/WSOrders";
import { refreshToken } from "../../../Actions/Auth";
import { networkStatusContext, usersData } from "../../../App";
import ErrorModal from "../../Modals/ErrorModal";
import OrderMessageModal from "../../Modals/orderMessageModal";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "react-lottie-player";
import WSCartEmpty from "../../../Images/json/WSCartEmpty.json";
import { useNavigate } from "react-router";
import WSTrackOrderModal from "../Modals/WSTrackOrderModal";
import { BsArrowLeft } from "react-icons/bs";
import { useServiceWorker } from "../../../useServiceWorker";

export default function WSOrders() {
  const appCategoriesData = [
    { name: "Live Orders", tag: "live_orders", type: "live" },
    { name: "Past Orders", tag: "past_orders", type: "past" },
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    subContent: "",
    header: "",
  });
  const [orderList, setOrderList] = useState([]);
  const [medId, setMedId] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [shimmer, setShimmer] = useState(false);
  const navigate = useNavigate();
  const [trackOrderModal, setTrackOrderModal] = useState({
    show: false,
    data: "",
  });
  const [orderTrackFetching, setOrderTrackFetching] = useState(false);
  const [orderTrackLink, setOrderTrackLink] = useState("");
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const {networkStatus, showNetworkStatus} = useContext(networkStatusContext);

  const handleMedList = (chooseMedId) => {
    if (medId > 0) {
      if (medId === chooseMedId) {
        setMedId(0);
      } else {
        setMedId(chooseMedId);
      }
    } else {
      setMedId(chooseMedId);
    }
  };

  const fetchMoreData = () => {
    setPageNo(+pageNo + 1);
  };

  const getOrders = async () => {
    let params = {
      page: pageNo,
    };

    //update the shimmer state only for the initial fetch
    if (pageNo === 0) setShimmer(true);

    try {
      let res;
      if (activeTab === 0) res = await getLiveOrders(params, {});
      else if (activeTab === 1) res = await getPastOrders(params);

      if (res?.data?.data.length > 0) {
        setOrderList([...orderList, ...res.data.data]);
      } else {
        setHasMore(false);
      }
      //update the shimmer state
      if (pageNo === 0) setShimmer(false);
    } catch (error) {
      //update the shimmer state
      if (pageNo === 0) setShimmer(false);
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getOrders();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getOrders();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getOrders();
          },
        });
      }
    }
  };

  const getOrderTrack = async (id) => {
    //build the payload
    let params = {
      orderId: id,
    };
    //update the loading state
    setOrderTrackFetching(true);
    try {
      const res = await getOrderTrackingStatus(params);
      setOrderTrackLink(res.data.data);
      setOrderTrackFetching(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          getOrderTrack();
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            getOrderTrack();
          },
        });
      } else if (error?.response?.status === 400) {
        setInfoMessage({
          show: true,
          content: error?.response?.data?.error?.localizedMessage,
          header: "INFO",
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            getOrderTrack();
          },
        });
      }
    }
  };

  //handler to trigger the track order modal
  const handleTrackOrder = (id) => {
    //call the orderTrack api
    getOrderTrack(id);
    //launch the modal
    setTrackOrderModal((prevObj) => ({ ...prevObj, show: true }));
  };

  useEffect(() => {
    if (pageNo === 0) return;
    getOrders();
  }, [pageNo]);

  useEffect(() => {
    //reset the pageNO
    setPageNo(0);
    //reset the ordersList state
    setOrderList([]);
    //hit the fetch orders list api
    getOrders();
  }, [activeTab]);

  //onMount logic
  useEffect(() => {
    window.scrollTo(0, 0);

    let userData = localStorage.getItem("userData");
    if (userData) {
      userData = JSON.parse(userData);
      userData.businessType &&
        userData.businessType[0] === "PHARMACY" &&
        navigate("/home");
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />

      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      <WSTrackOrderModal
        show={trackOrderModal.show}
        data={orderTrackLink}
        onHide={() => {
          setTrackOrderModal({ show: false, data: "" });
        }}
      />

      <Container
        className="wholesale-container"
        fluid
        style={{ minHeight: "100vh" }}
      >
        <Row className={(!networkStatus || showNetworkStatus || isUpdateAvailable) ? "wholesale-sub-header ws-orders-sub-header-update": 'wholesale-sub-header'}>
          <Col className="flex-align-center-row ws-orders-header-wrapper">
            <BsArrowLeft
              size="1.5rem"
              className="cursor_pointer"
              onClick={() => navigate(-1)}
            />
            <p style={{ margin: "0px", marginLeft: "1rem" }}>Go Back</p>
          </Col>
          {appCategoriesData.map((orderList, index) => {
            return (
              <Col
                key={index}
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  className="tab-content flex-align-center-column"
                  onClick={() => setActiveTab(index)}
                >
                  <span>{orderList.name}</span>

                  <span style={{ minHeight: ".5rem" }}></span>

                  <div
                    className={
                      activeTab === index
                        ? "tab-active flex-align-center-row"
                        : "tab flex-align-center-row"
                    }
                  ></div>
                </div>
              </Col>
            );
          })}
        </Row>

        {!shimmer && orderList && orderList.length > 0 && (
          <div className="worders-main-container">
            <InfiniteScroll
              dataLength={orderList.length}
              next={fetchMoreData}
              hasMore={hasMore}
              scrollThreshold="90%"
              loader={
                <div
                  className="flex-align-center-row"
                  style={{ width: "100%", marginTop: "1rem", height: "3rem" }}
                >
                  <Spinner
                    animation="border"
                    className="color_secondary"
                    size="lg"
                  />
                </div>
              }
              endMessage={<p className="listEnd-msg">⁌ End of order list ⁍</p>}
            >
              {orderList.map((list) => {
                return (
                  <div className="worders-sub-container" key={list.orderId}>
                    <div className="worder-header">
                      <p>
                        <span>Order id# </span>
                        {list.orderId}
                      </p>
                      <p>
                        <span>Delivered </span>
                        {moment(list.orderTimestamp).format("hh:mm A") +
                          ", " +
                          moment(list.orderTimestamp).format("DD MMM YYYY")}
                      </p>

                      <p>
                        Order Value &nbsp;&nbsp;₹{" "}
                        {list.orderValue.toLocaleString("en-IN")} (
                        {list.shipmentData.length}
                        {list.shipmentData.length > 1
                          ? " shipments"
                          : " shipment"}
                        )
                      </p>
                    </div>

                    {list.shipmentData.map((shipment) => {
                      return (
                        <div
                          className="worders-card"
                          key={shipment.shipmentNumber}
                        >
                          <div className="shipmentCont">
                            <div className="shipmentDetails">
                              <div className="detailsHeader">
                                <h6>SHIPMENT {shipment.shipmentNumber}</h6>
                                <p>
                                  {+shipment.totalItems > 1
                                    ? " Items "
                                    : " Item "}
                                  {shipment.totalItems}
                                </p>
                              </div>
                              <p className="shipSeller">
                                <span>Seller: </span>
                                {shipment.sellerName}
                              </p>
                              <p>
                                <span>Order id# </span> {shipment.orderId}
                              </p>
                              <p>
                                <span>Delivered </span>
                                {moment(list.orderTimestamp).format("hh:mm A") +
                                  ", " +
                                  moment(list.orderTimestamp).format(
                                    "DD MMM YYYY"
                                  )}
                              </p>
                            </div>
                            <div className="shipActions">
                              <p
                                style={{
                                  margin: "0px",
                                  color: "#02C3B0",
                                  fontWeight: "bold",
                                }}
                              >
                                {shipment.orderStatus}
                              </p>
                              <button
                                className="btn invoice"
                                onClick={() =>
                                  handleTrackOrder(shipment.orderId)
                                }
                              >
                                Track Order
                              </button>
                            </div>
                          </div>

                          <div className="totalPrice">
                            <p>Total Amount</p>
                            <p>
                              {shipment.amountSaved > 0 ? (
                                <span>
                                  {shipment.amountSaved.toLocaleString("en-IN")}{" "}
                                  SAVED
                                </span>
                              ) : (
                                <></>
                              )}
                              ₹ {shipment.netAmount.toLocaleString("en-IN")}
                            </p>
                          </div>

                          <div
                            className="totalPrice"
                            style={{padding : '0rem .5rem', paddingBottom: '1rem'}}
                          >
                            <p>Delivery</p>
                            <p>{shipment.delivery || "NA"}</p>
                          </div>

                          <div>
                            {shipment.medicineDetails.map((meds, index) => {
                              return (
                                index < 2 && (
                                  <div
                                    className="twoItems"
                                    key={"meds" + index}
                                  >
                                    <p>
                                      {meds.quantity.toLocaleString("en-IN")} x{" "}
                                      {meds.productName}
                                    </p>
                                    <p>
                                      ₹ {meds.price.toLocaleString("en-IN")}
                                    </p>
                                  </div>
                                )
                              );
                            })}
                          </div>

                          <p
                            className="seeAllBtn"
                            onClick={() => handleMedList(shipment.orderId)}
                          >
                            {shipment.totalItems > 1
                              ? ` See all ${shipment.totalItems} items`
                              : ` See ${shipment.totalItems} item`}

                            <span
                              className={
                                medId > 0 && medId === shipment.orderId
                                  ? "openSeeAll"
                                  : "closeSeeAll"
                              }
                            >
                              ▶︎
                            </span>
                          </p>

                          {shipment.medicineDetails.map(
                            (meds, index, medArr) => {
                              return (
                                <div
                                  className={
                                    medId === shipment.orderId
                                      ? "medList"
                                      : "medList-close"
                                  }
                                  key={"medsII" + index}
                                >
                                  <p>{meds.productName}</p>

                                  <div className="medAmount">
                                    <div className="medDetail">
                                      <p>
                                        <span>Nett Rate</span>₹{" "}
                                        {meds.netPrice.toLocaleString("en-IN")}
                                      </p>

                                      <p>
                                        <span>Quantity</span>
                                        {meds.quantity.toLocaleString("en-IN")}
                                      </p>
                                    </div>

                                    <p>
                                      <span>Total</span>₹{" "}
                                      {meds.price.toLocaleString("en-IN")}
                                    </p>
                                  </div>

                                  {index + 1 !== medArr.length ? <hr /> : <></>}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}

        {shimmer && orderList?.length === 0 && (
          <div className="worders-main-container">
            {[...Array(3)].map((_, index) => {
              return (
                <div
                  className="worders-sub-container"
                  style={{ width: window.innerWidth < 750 ? "100%" : "60%" }}
                  key={index}
                >
                  <div
                    className="worder-header animateShimmer"
                    style={{ height: "4rem" }}
                  ></div>
                  <div
                    className="worders-card animateShimmer2"
                    style={{
                      height: "10rem",
                      marginTop: "1rem",
                      padding: "1rem",
                    }}
                  >
                    <div
                      className="animateShimmer"
                      style={{ width: "70%", height: ".5rem" }}
                    ></div>
                    <div
                      className="animateShimmer"
                      style={{
                        width: "50%",
                        height: ".5rem",
                        marginTop: "1rem",
                      }}
                    ></div>
                    <div
                      className="animateShimmer"
                      style={{
                        width: "50%",
                        height: ".5rem",
                        marginTop: "1rem",
                      }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {!shimmer && orderList?.length === 0 && (
          <div
            style={{ height: "calc(100vh - 8rem)" }}
            className="flex-align-center-column"
          >
            <Lottie
              loop={false}
              animationData={WSCartEmpty}
              style={{ width: "60%", height: "60%" }}
              play
            />
            <p style={{ fontSize: "1.2rem" }}>
              Uh - Oh! Your {activeTab === 0 ? "Live" : "Past"} Orders are
              empty!
            </p>
            <p style={{ fontSize: "1rem" }}>Add items to it</p>
            <button
              style={{ width: "20rem", border: "1px solid #19C3B2" }}
              className="ws-product-price-details-confirm-button"
              onClick={() => navigate("/wholesale/home")}
            >
              Start Shopping
            </button>
          </div>
        )}
      </Container>
    </>
  );
}
